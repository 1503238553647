import ReceiptModal2 from 'components/ReceiptModal/ReceiptModal2/ReceiptModal2'
import ServiceBadge, { ServiceBadgeType } from 'components/StorePage/StoreList/StoreItem/ServiceBadge/ServiceBadge'
import dayjs from 'dayjs'
import { ServicePaymentType } from 'lib/api/service/types'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import addComma from 'utils/common/addComma'
import buildStoreSummary from 'utils/common/buildStoreSummary'
import { getDateFormatToYMD, getServicePeriodFormatter } from 'utils/common/calendar'
import { getServiceBadgeType } from 'utils/common/getServiceBadgeType'

type PaymentHistoryCardProp = {
  service: ServicePaymentType
}
export default function PaymentHistoryCard({ service }: PaymentHistoryCardProp) {
  const [openReceiptModal, setOpenReceiptModal] = useState<boolean>(false)

  const serviceType = getServiceBadgeType({
    service,
    payment: service.payment,
    startDate: service.first_date,
    endDate: service.end_date,
  })

  const handleOpenReceiptModal = () => {
    setOpenReceiptModal(true)
  }

  const handleCloseReceiptModal = () => {
    setOpenReceiptModal(false)
  }

  return (
    <Container $isCancel={serviceType === 'CANCEL_APPLICATION'}>
      <ServiceInfoWrap>
        {service.is_regular_payment && <ServiceBadge badgeType="SUBSCRIBE" />}
        <ServiceTitle>{service.store_name} </ServiceTitle>
        <ServiceText>{buildStoreSummary(service)}</ServiceText>
      </ServiceInfoWrap>

      <ServiceInfoDetailWrap>
        <ServicePeriod>
          <ServicePeriodTitle>서비스 기간</ServicePeriodTitle>
          <ServicePeriodContent>{getServicePeriodFormatter(service.first_date, service.end_date)}</ServicePeriodContent>
        </ServicePeriod>

        <DetailInfoWrap>
          <ItemWrap>
            <ItemTitle>결제 금액</ItemTitle>
            <ItemContent style={{ fontWeight: 700 }}>{addComma(service.payment?.price ?? 0)}원</ItemContent>
          </ItemWrap>
          {service.is_regular_payment && (
            <ItemWrap>
              <ItemTitle>결제차수</ItemTitle>
              <ItemContent>{service.regular_payment_round_n}회차</ItemContent>
            </ItemWrap>
          )}
          <ItemWrap>
            <ItemTitle>결제일</ItemTitle>
            <ContentWrap>
              <ItemContent>{getDateFormatToYMD(new Date(service.paid_at))}</ItemContent>
              {service.is_regular_payment && (
                <ItemSubContent>
                  최초 결제일 : {getDateFormatToYMD(new Date(service.first_regular_payment_paid_at))}
                </ItemSubContent>
              )}
            </ContentWrap>
          </ItemWrap>
          {!!service.payment?.point && (
            <ItemWrap>
              <ItemTitle>포인트 사용</ItemTitle>
              <ItemContent>{addComma(service.payment?.point)}P</ItemContent>
            </ItemWrap>
          )}
          <ItemWrap>
            <ItemTitle>상태</ItemTitle>
            <ItemContent>
              <ServiceBadge badgeType={serviceType} />
            </ItemContent>
          </ItemWrap>
        </DetailInfoWrap>

        {serviceType !== 'CANCEL_APPLICATION' &&
          !!service.paid_at &&
          !!service.payment?.id &&
          !(!!service.payment?.refund?.requested_at && !service.payment.refund.refunded_at) && (
            <ReceiptButtonWrap>
              <ReceiptButton onClick={handleOpenReceiptModal}>영수증 보기</ReceiptButton>
            </ReceiptButtonWrap>
          )}

        {serviceType === 'CANCEL_APPLICATION' && (
          <Notice>※ 해지 신청 시, 영업일 기준 2일 이내로 확인 연락을 드릴 예정입니다.</Notice>
        )}
      </ServiceInfoDetailWrap>

      <ReceiptModal2
        service={service}
        open={openReceiptModal}
        onClose={handleCloseReceiptModal}
        // onConfirm={handleCloseReceiptModal}
        // onClickedStatement={handleClickedStatement}
      />
    </Container>
  )
}

const Container = styled.div<{ $isCancel: boolean }>`
  width: 100%;
  padding: 24px 32px;

  display: flex;
  justify-content: space-between;

  gap: 24px;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-200, #e8e8e8);
  background: var(--white, #fff);

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
  `}

  ${(props) =>
    props.$isCancel &&
    css`
      border: 1px solid var(--red-red-400, #d32f2f);
    `}
`

const ServiceInfoWrap = styled.div``

const ServiceInfoDetailWrap = styled.div`
  width: 65%;
  ${({ theme }) => theme.media.mobile`
    width: 100%;
  `};
`

const ServiceTitle = styled.h5`
  color: var(--gray-gray-800, #212121);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 12px;
`
const ServiceText = styled.p`
  color: var(--gray-gray-600, #757575);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`

const ServicePeriod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
`

const ServicePeriodTitle = styled.p`
  color: var(--gray-gray-500, #a8a8a8);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

const ServicePeriodContent = styled.p`
  width: max-content;
  color: var(--gray-gray-800, #212121);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
`

const DetailInfoWrap = styled.div`
  width: 100%;
  margin-top: 16px;
`

const ItemWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  margin-bottom: 12px;

  & > * {
    &:first-child {
      width: 25%;
    }
    &:last-child {
      width: 75%;
    }

    ${({ theme }) => theme.media.mobile`
        &:first-child {
            width: 35%;
        }
        &:last-child {
            width: 65%;
        }
  `}
  }
`

const ItemTitle = styled.span`
  align-self: flex-start;
  color: var(--gray-gray-500, #a8a8a8);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

const ItemContent = styled.span`
  color: var(--gray-gray-700, #333);
  font-size: 16px;
  font-weight: 400;
`

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemSubContent = styled.span`
  width: max-content;
  color: var(--gray-gray-600, #757575);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

const ReceiptButtonWrap = styled.div`
  ${({ theme }) => theme.media.mobile`
    padding: 16px;
    margin: 0 auto;
    border-top: 1px solid #f5f5f5;
  `}
`

const ReceiptButton = styled.button`
  height: 40px;
  padding: 0px 24px;
  margin-left: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  background: var(--gray-gray-100, #f5f5f5);

  color: var(--gray-gray-800, #212121);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;

  cursor: pointer;

  ${({ theme }) => theme.media.mobile`
    color: var(--gray-gray-600, #757575);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    text-align: center;
    margin: 0 auto;
    background-color: white;
    text-underline-offset: 2px;
    text-decoration-color: var(--gray-gray-600, #757575);
  `}
`

const Notice = styled.p`
  color: var(--gray-gray-600, #757575);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
`
