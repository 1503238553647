export default function addComma(num: string | number) {
  if (!num || num === 0) return '0'

  let point, str

  num = num + ''
  point = num.length % 3
  const len = num.length

  str = num.substring(0, point)
  while (point < len) {
    if (str !== '') str += ','
    str += num.substring(point, point + 3)
    point += 3
  }

  return str
}
