export const getOrderId = (data: string) => {
  const orderId = (/name="orderId" value="(.*)"/g.exec(data) ?? [])[1]
  const oid = (/name="oid" value="(.*)"/g.exec(data) ?? [])[1]
  const mobileOrderId = (/name="P_OID" value="(.*)"/g.exec(data) ?? [])[1]

  if (orderId) return orderId
  if (oid) return oid
  if (mobileOrderId) return mobileOrderId

  return ''
}
