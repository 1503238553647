import { useAppSelector } from 'hooks/useReduxHook'
import useToast from 'hooks/useToast'
import { useEffect } from 'react'
import styled from 'styled-components'

export default function Toast() {
  const toast = useToast()
  const { open, title } = useAppSelector((state) => state.toast)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        toast.close()
      }, 6000)
    }
  }, [open, toast])

  return (
    <>
      {open && (
        <Container>
          <ToastContent>{title}</ToastContent>
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;

  border-radius: 12px;
  background: var(--gray-scale-gray-600, #1e1e2c);

  position: fixed;
  bottom: 24px;

  margin: 0px 28px;
  z-index: 999;
`

const ToastContent = styled.p`
  color: var(--gray-scale-gray-200, #eee);

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`
