import { fadeIn } from 'assets/styles/animation'
import Icon from 'components/Icon'
import NavMenuList from 'components/Landing/NavMenuList'
import useAuth from 'hooks/query/auth/useAuth'
import useLogout from 'hooks/query/auth/useLogout'
import { memo } from 'react'
import { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ROUTES } from 'utils/common/routes'
import NavServiceTypeListData from 'data/navServiceTypeListData.json'
import { useAppSelector } from 'hooks/useReduxHook'
import { getEventBannerStatus } from 'modules/features/eventBannerStatus/eventBannerStatusSlice'

export type LandingNavBarProps = {
  offsetY: number
  landingRef?: React.RefObject<HTMLDivElement>
  changeColor?: boolean
}

/**
 * @param changeColor 스크롤 이동시, 글자색이 변하면 true, 기본값은 false
 */

function LandingNavBar({ offsetY, landingRef, changeColor = false }: LandingNavBarProps) {
  const { isLogin, auth } = useAuth()
  const [myMenuListOpen, setMyMenuListOpen] = useState<boolean>(false)
  const [serviceMenuListOpen, setServiceMenuListOpen] = useState<boolean>(false)
  const [mobileMenuListOpen, setMobileMenuListOpen] = useState<boolean>(false)
  const [mobileSubMenuListOpen, setMobileSubMenuListOpen] = useState<boolean>(false)

  const isEventBannerStatus = useAppSelector(getEventBannerStatus)
  const history = useHistory()
  const location = history.location.pathname
  const { pathname } = useLocation()

  const { mutateAsync: mutateLogout } = useLogout()

  const onClickLogout = async () => {
    await mutateLogout()
    setMyMenuListOpen(false)
    setMobileMenuListOpen(false)
    history.push(ROUTES.ROOT)
  }

  /**
   *@function checkPathIsLanding 현재 페이지가 랜딩페이지면 스크롤을 최상단으로 초기화
   랜딩페이지가 아니면 랜딩페이지로 이동
   */

  const checkPathIsLanding = () => {
    localStorage.removeItem(`scrollData`)
    if (landingRef?.current && pathname === '/') {
      landingRef.current.scrollTo({ top: 0, behavior: 'auto' })
    } else {
      localStorage.setItem('scrollData', `0`)
      history.push(ROUTES.ROOT)
    }
  }

  return (
    <StyledLandingNavBar offsetY={offsetY} verticalLocation={isEventBannerStatus && pathname === '/'}>
      <LogoContainer offsetY={offsetY} changeColor={changeColor}>
        <Icon name="whiteNavLogo" onClick={checkPathIsLanding} />
        <Icon name="blackNavLogo" onClick={checkPathIsLanding} />
      </LogoContainer>
      {isLogin ? (
        <NavList offsetY={offsetY} changeColor={changeColor}>
          <ProfileTab
            onMouseOver={(e) => {
              setMyMenuListOpen(true)
              e.stopPropagation()
            }}
          >
            <Icon name="sheepProfile" />
            <span>{auth?.real_name}</span>
            <Icon name={changeColor && offsetY === 0 ? 'WhiteArrowDown' : 'blackArrowDown'} />
          </ProfileTab>
          <ProfileTab
            onMouseOver={(e) => {
              setServiceMenuListOpen(true)
              e.stopPropagation()
            }}
          >
            서비스 유형
            <Icon name={changeColor && offsetY === 0 ? 'WhiteArrowDown' : 'blackArrowDown'} />
          </ProfileTab>
          <li onClick={() => window.open('https://www.xn--v92bo5hlpggqr.com/home')}>무인상회</li>
          <li>
            <Link to={ROUTES.MAP}>무인매장 지도</Link>
          </li>
          <li onClick={() => window.open('https://higherx.notion.site/b9ccb239d9784e7692cf596c466ef795')}>크루 모집</li>
          <li>
            <Link to={ROUTES.FAQ}>FAQ</Link>
          </li>
        </NavList>
      ) : (
        <NavList offsetY={offsetY} changeColor={changeColor}>
          <ProfileTab
            onMouseOver={(e) => {
              setServiceMenuListOpen(true)
              e.stopPropagation()
            }}
          >
            서비스 유형
            <Icon name={changeColor && offsetY === 0 ? 'WhiteArrowDown' : 'blackArrowDown'} />
          </ProfileTab>
          <li onClick={() => window.open('https://www.xn--v92bo5hlpggqr.com/home')}>무인상회</li>
          <li>
            <Link to={ROUTES.MAP}>무인매장 지도</Link>
          </li>
          <li onClick={() => window.open('https://higherx.notion.site/b9ccb239d9784e7692cf596c466ef795')}>크루 모집</li>
          <li>
            <Link to={ROUTES.FAQ}>FAQ</Link>
          </li>

          {location === '/auth/login' || location === '/auth/social-option' || location === '/auth/sign-up' ? null : (
            <li>
              <Link to={ROUTES.AUTH_LOGIN} className="login">
                로그인
              </Link>
            </li>
          )}
        </NavList>
      )}

      {isLogin ? (
        <MobileSheepContainer>
          <Icon name="sheepProfile" onClick={() => setMobileMenuListOpen(true)} />
        </MobileSheepContainer>
      ) : (
        <MobileNavButton offsetY={offsetY} changeColor={changeColor} onClick={() => setMobileMenuListOpen(true)}>
          <Icon name="mobileNavList" />
          <Icon name="mobileNavListBlack" />
        </MobileNavButton>
      )}

      {serviceMenuListOpen && (
        <NavMenuList right={isLogin ? 440 : 580} setListOpen={setServiceMenuListOpen}>
          {NavServiceTypeListData.map(({ title, link }, index) => (
            <li key={index} onClick={() => setServiceMenuListOpen(false)}>
              <Link to={link}>{title}</Link>
            </li>
          ))}
        </NavMenuList>
      )}

      {myMenuListOpen && (
        <NavMenuList right={600} setListOpen={setMyMenuListOpen}>
          <li>
            <Link to={ROUTES.MYPAGE}>마이페이지</Link>
          </li>
          <li>
            <Link to={ROUTES.STORE}>서비스 신청 ・ 관리</Link>
          </li>
          <li>
            <Link to={ROUTES.PAYMENT}>결제 내역</Link>
          </li>
          <li onClick={onClickLogout}>로그아웃</li>
        </NavMenuList>
      )}

      {mobileMenuListOpen && (
        <MenuListContainer>
          <Icon
            name="whiteNavLogo"
            onClick={() => {
              setMobileMenuListOpen(false)
              history.push(ROUTES.ROOT)
            }}
          />
          <Icon name="mobileNavClose" onClick={() => setMobileMenuListOpen(false)} />

          <MobileMenuList>
            {isLogin && (
              <>
                <li>
                  <Link to={ROUTES.MYPAGE}>마이페이지</Link>
                </li>
                <li>
                  <Link to={ROUTES.STORE}>서비스 신청 ・ 관리</Link>
                </li>
                <li>
                  <Link to={ROUTES.PAYMENT}>결제 내역</Link>
                </li>
              </>
            )}
            <li onClick={() => setMobileSubMenuListOpen(!mobileSubMenuListOpen)}>
              서비스 유형
              {mobileSubMenuListOpen ? <Icon name="blackArrowUp" /> : <Icon name="WhiteArrowDown" />}
            </li>
            {mobileSubMenuListOpen && (
              <MobileServiceDetailUl>
                {NavServiceTypeListData.map(({ title, link }, index) => (
                  <li onClick={() => setMobileMenuListOpen(false)} key={index}>
                    <Link to={link}>{title}</Link>
                  </li>
                ))}
              </MobileServiceDetailUl>
            )}
            <li
              onClick={() => {
                setMobileMenuListOpen(false)
                window.open('https://www.xn--v92bo5hlpggqr.com/')
              }}
            >
              무인상회
            </li>

            <li>
              <Link to={ROUTES.MAP}>무인매장 지도</Link>
            </li>
            <li
              onClick={() => {
                setMobileMenuListOpen(false)
                window.open('https://higherx.notion.site/b9ccb239d9784e7692cf596c466ef795')
              }}
            >
              크루 모집
            </li>
            <li>
              <Link to={ROUTES.FAQ}>FAQ</Link>
            </li>

            {!isLogin && (
              <li>
                <Link to={ROUTES.AUTH_LOGIN} className="login">
                  로그인
                </Link>
              </li>
            )}

            {isLogin && <li onClick={onClickLogout}>로그아웃</li>}
          </MobileMenuList>
        </MenuListContainer>
      )}
    </StyledLandingNavBar>
  )
}

const StyledLandingNavBar = styled.nav<{ offsetY: number; verticalLocation: boolean }>`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: none;
  padding-left: 32px;
  padding-right: 56px;
  position: fixed;
  z-index: 10000002 !important;
  transition: background-color 0.15s ease-in-out;

  ${({ offsetY }) =>
    offsetY > 0 &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: 0 1px 1px 0 rgb(81 99 120 / 20%);
    `}

  @media only screen and (max-width: 768px) {
    padding: 0 16px;
    height: 56px;
    top: ${({ verticalLocation }) => (verticalLocation ? '48px' : '0px')};
  }
`

const LogoContainer = styled.div<{ offsetY: number; changeColor: boolean }>`
  & > svg {
    position: absolute;
    top: 50%;
    left: 32px;
    margin-top: -20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  & > svg:first-child {
    opacity: ${({ changeColor }) => (changeColor ? 1 : 0)};
  }

  & > svg:last-child {
    opacity: ${({ changeColor }) => (changeColor ? 0 : 1)};
  }

  ${({ offsetY }) =>
    offsetY > 0 &&
    css`
      & > svg:first-child {
        opacity: 0;
      }

      & > svg:last-child {
        opacity: 1;
      }
    `}

  @media only screen and (max-width: 768px) {
    & > svg {
      left: 16px;
    }
  }
`

const NavList = styled.ul<{ offsetY: number; changeColor: boolean }>`
  display: flex;
  align-items: center;
  height: 80px;

  & > li {
    color: ${({ theme, changeColor }) => (changeColor ? theme.colors.white : theme.colors.black)};
    font-weight: 700;
    font-size: 16px;
    line-height: 80px;
    margin-left: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &:nth-of-type(3) {
      margin-left: 32px;
    }

    svg:nth-of-type(1) {
      margin-right: 8px;
    }

    & > a {
      color: ${({ theme, changeColor }) => (changeColor ? theme.colors.white : theme.colors.black)};
      font-weight: 700;
      font-size: 16px;
      line-height: 80px;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
    }
  }

  & > li:first-child {
    margin-left: 0;
  }

  ${({ offsetY }) =>
    offsetY > 0 &&
    css`
      & > li {
        color: ${({ theme }) => theme.colors.black};

        & > a {
          color: ${({ theme }) => theme.colors.black};
        }
      }
    `}

  @media only screen and (max-width: 768px) {
    display: none;
  }

  .login {
    max-height: 36px;
    padding: 4px 16px;
    border-radius: 8px;
    border: 1px solid #dbdbdb;

    background: rgba(255, 255, 255, 0.2);
    line-height: normal;
  }
`

const MobileNavButton = styled.button<{ offsetY: number; changeColor: boolean }>`
  display: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  & > svg {
    position: absolute;
    top: calc(50% - 20px);
    right: 16px;
    transition: opacity 0.3s ease-in-out;
  }

  & > svg:first-child {
    opacity: ${({ changeColor }) => (changeColor ? 1 : 0)};
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
  }

  & > svg:last-child {
    opacity: ${({ changeColor }) => (changeColor ? 0 : 1)};
  }

  ${({ offsetY }) =>
    offsetY > 0 &&
    css`
      & > svg:first-child {
        opacity: 0;
      }

      & > svg:last-child {
        opacity: 1;
        filter: none;
      }
    `}

  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const MenuListContainer = styled.div`
  width: 100%;
  height: 100vh;
  animation: ${fadeIn} 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.main};
  z-index: 100000000;
  transform: translateZ(1000000px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    cursor: pointer;
    position: absolute;
    top: 8px;

    &:nth-of-type(1) {
      left: 16px;
    }

    &:nth-of-type(2) {
      right: 20px;
      top: 12px;
      width: 32px;
      height: 32px;
    }
  }
`

const MobileMenuList = styled.ul`
  position: absolute;
  top: 72px;
  padding: 32px 72px;
  overflow-y: scroll;
  max-height: 550px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & > li ~ li {
    margin-top: 24px;
  }

  & > li {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    & > svg {
      margin-left: 8px;
      width: 28px;
      height: 28px;

      & > path {
        stroke: ${({ theme }) => theme.colors.white};
      }
    }
  }

  & > li,
  & > li > a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    font-size: 20px;
    line-height: 28px;
  }

  & > li > a {
    text-decoration: none;
  }
`

const MobileSheepContainer = styled.div`
  display: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const ProfileTab = styled.li`
  height: 50px;

  & > span {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > svg {
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }
`

const MobileServiceDetailUl = styled.ul`
  width: 216px;
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  padding-left: 48px;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  transition: all 0.3s ease-in-out;

  & > li {
    padding: 8px 0px;

    & > a {
      text-decoration: none;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${({ theme }) => theme.colors.white};

      &:active {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }
  }
`

export default memo(LandingNavBar)
