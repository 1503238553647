import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'modules'

export type EventBannerStatusState = {
  bannerStatus: boolean
}

/**
 * bannerStatus: 초기 배너상태가 false로, 배너가 필요할 때 true로 변경 필요
 */
const initialState: EventBannerStatusState = {
  bannerStatus: false,
}

export const eventBannerStatusSlice = createSlice({
  name: 'eventBannerStatus',
  initialState,
  reducers: {
    setBannerStatus: (state, action: PayloadAction<boolean>) => {
      state.bannerStatus = action.payload
    },
    initEventBannerStatus: (state) => {
      state.bannerStatus = true
    },
  },
})

export const eventBannerStatusSelector = (state: RootState) => state.eventBannerStatus.bannerStatus

export const getEventBannerStatus = createSelector(eventBannerStatusSelector, (bannerStatus) => {
  return bannerStatus
})

export const { setBannerStatus } = eventBannerStatusSlice.actions
export default eventBannerStatusSlice.reducer
