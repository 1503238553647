import client from '../client'

export async function postRefundRequest(req: PostRefundRequestReq) {
  const response = await client.post<undefined>(`/payment-api/v1/refund/request`, req)
  return response
}

export type PostRefundRequestReq = {
  service_id: string
  refundAcctNum?: string
  refundBankCode?: string
  refundAcctName?: string
}
