import styled, { css } from 'styled-components'
import NavBar from '../NavBar'
import { Helmet } from 'react-helmet-async'
import { useEffect } from 'react'

export type HeaderProps = {
  title: string
  offsetY?: boolean
}

/**
 * 일반 웹 헤더와 모바일용 헤더 2개로 구성되어있으며 mobile 관련 props가 추가되면 주석을 풀 예정입니다.
 */

export default function Header({ title, offsetY = false }: HeaderProps) {
  useEffect(() => {}, [offsetY])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <StyledHeader offsetY={offsetY}>
        <ImageShadow />
        <img src="/image/auth-header.webp" alt="Header Background" />
        <NavBar offsetY={offsetY} />
        <Title>{title}</Title>
      </StyledHeader>
      <MobileHeader>
        <NavBar title={title} offsetY={offsetY} />
      </MobileHeader>
    </>
  )
}

const StyledHeader = styled.header<{ offsetY: boolean }>`
  & > img {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    z-index: -2;
    top: 0;

    ${({ theme }) => theme.media.mobile`
      display: none;
    `}
  }

  width: 100%;
  height: 24rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ offsetY }) =>
    offsetY &&
    css`
      nav {
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: 0 1px 1px 0 rgb(81 99 120 / 20%);
        padding-left: 32px;
        padding-right: 56px;
        height: 8rem;
      }

      li,
      span {
        color: black;
      }

      li > span {
        color: black;
      }

      li > a {
        color: black;
      }
    `}

  ${({ theme }) => theme.media.mobile`
display: none;
`}
`

const ImageShadow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  z-index: -1;
  top: 0;
  background-color: #1e1e2c;
  opacity: 0.4;

  ${({ theme }) => theme.media.mobile`
      display: none;
    `}
`

const MobileHeader = styled.header`
  display: none;
  background-color: ${({ theme }) => theme.colors.main};
  position: relative;
  align-items: center;
  justify-content: center;

  nav {
    background-color: white;
    padding: ${({ theme }) => theme.spacing(2)};
    padding-top: 13px;
    height: 56px;
  }

  nav > svg:nth-child(3) {
    border-radius: 50%;
  }

  ${({ theme }) => theme.media.mobile`
    display: flex;
  `}
`

const Title = styled.h2`
  font-size: 4rem;
  color: white;
  font-weight: 700;
`
