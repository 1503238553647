import styled from 'styled-components'

import { ReactComponent as BrwnieLogo } from './logo-brwnie.svg'
import { ReactComponent as MuinFirmLogo } from './logo-muin-firm.svg'
import { ReactComponent as BrwnieCheckIcon } from './interface-check-circle-brwnie.svg'
import { ReactComponent as MuinFirmCheckIcon } from './interface-check-circle-muin-firm.svg'

interface IntroduceProps {
  onCTAClick: () => void
}

export default function Introduce({ onCTAClick }: IntroduceProps) {
  return (
    <>
      <Footer>
        <FooterText>
          가장 잘하고 있는
          <br />
          <b>브라우니 X 무인상회</b>의 만남으로
          <br />
          고객들에게 최적화된 서비스를 제공합니다.
        </FooterText>

        <FooterText>
          소자본 창업에 믿을 수 있는
          <br />
          파트너가 되겠습니다.
        </FooterText>
      </Footer>

      <Infos>
        <InfoContainer>
          <ImageContainer>
            <img src="/image/muin-consulting/brwnieImage.png" alt="브라우니" width="100%" height="100%" />

            <BrwnieLogo />
          </ImageContainer>

          <SubTitle>무인매장 관리 전문 서비스</SubTitle>
          <Title>브라우니</Title>

          <DescriptionList>
            <DescriptionListItem>
              <BrwnieCheckIcon />
              <Description>
                현재 관리하고 있는
                <br />
                무인매장 브랜드 20개 이상
              </Description>
            </DescriptionListItem>
            <DescriptionListItem>
              <BrwnieCheckIcon />
              <Description>관리 횟수 15,000번 이상</Description>
            </DescriptionListItem>
            <DescriptionListItem>
              <BrwnieCheckIcon />
              <Description>상품 발주에서 위탁관리</Description>
            </DescriptionListItem>
          </DescriptionList>
        </InfoContainer>

        <InfoContainer>
          <ImageContainer>
            <img src="/image/muin-consulting/muinFirmImage.png" alt="무인상회" width="100%" height="100%" />

            <MuinFirmLogo />
          </ImageContainer>

          <SubTitle>AI BOX 24 개발 전문 회사</SubTitle>
          <Title>무인상회</Title>

          <DescriptionList>
            <DescriptionListItem>
              <MuinFirmCheckIcon />
              <Description>
                13여년간 무인 Platform
                <br />
                전문솔루션 개발
              </Description>
            </DescriptionListItem>
            <DescriptionListItem>
              <MuinFirmCheckIcon />
              <Description>
                특허 6건 등록
                <br />
                전국에 약 100개 설치
              </Description>
            </DescriptionListItem>
            <DescriptionListItem>
              <MuinFirmCheckIcon />
              <Description>수년간 R&D 끝에 최적화된 제품 출시</Description>
            </DescriptionListItem>
          </DescriptionList>
        </InfoContainer>
      </Infos>

      <CTAContainer>
        <CTAButton onClick={onCTAClick}>상담 신청하기</CTAButton>
      </CTAContainer>
    </>
  )
}

const Infos = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 76px;
  padding-bottom: 200px;

  ${({ theme }) => theme.media.mobile`
    padding-top: 70px;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  `}
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-left: 40px;
  }

  ${({ theme }) => theme.media.mobile`
    & + & {
      margin-left: 0px;
      margin-top: 40px;
    }
  `}
`

const ImageContainer = styled.div`
  width: 363px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 18px;

  & > svg {
    position: absolute;
  }

  ${({ theme }) => theme.media.mobile`
    width: 300px;
    height: 174px;
    margin-bottom: 16px;
  `}
`

const SubTitle = styled.h2`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #585858;

  ${({ theme }) => theme.media.mobile`
    font-size: 14px;
  `}
`

const Title = styled.h1`
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #212121;

  ${({ theme }) => theme.media.mobile`
    font-size: 24px;
  `}
`

const DescriptionList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  & > li + li {
    margin-top: 26px;
  }

  ${({ theme }) => theme.media.mobile`
    margin-top: 20px;

    & > li + li {
      margin-top: 16px;
    }
  `}
`

const DescriptionListItem = styled.li`
  display: flex;
  align-items: center;
`

const Description = styled.p`
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 28px;
  color: #212121;
  margin-left: 16px;

  ${({ theme }) => theme.media.mobile`
    font-size: 16px;
    line-height: 24px;
    margin-left: 12px;
  `}
`

const Footer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.media.mobile`
    padding-top: 50px;
    padding-bottom: 50px;
  `}
`

const FooterText = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #212121;

  & + & {
    margin-top: 24px;
  }

  ${({ theme }) => theme.media.mobile`
    font-size: 18px;
    line-height: 26px;
  `}
`

const CTAContainer = styled.div`
  position: fixed;
  bottom: 0%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 136px;
`

const CTAButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #083994;
  height: 56px;
  width: 200px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.064px;
  color: #fff;
  cursor: pointer;
`
