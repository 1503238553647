import styled from 'styled-components'

import { useHistory } from 'react-router-dom'

export function BottomCTA(): JSX.Element {
  const history = useHistory()
  const { push } = history

  const handleGoMainPage = () => {
    push('/')
  }

  return (
    <Container>
      <Button onClick={handleGoMainPage}>
        지금 바로 관리 시작 <span> &gt; </span>
      </Button>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 16px 16px 30px;
`

const Button = styled.button`
  width: 100%;
  height: 48px;

  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: -0.4px;

  border-radius: 12px;
  background: #039;
  box-shadow: 0px 6px 16px 0px rgba(95, 83, 39, 0.34);

  cursor: pointer;
`
