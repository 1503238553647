import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import usePostEmailLogin from '../../../hooks/query/auth/usePostEmailLogin'
import { useHistory } from 'react-router'
import Icon from 'components/Icon'
import Spinner from 'components/common/Spinner'
import { ROUTES } from 'utils/common/routes'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'

interface IFormInput {
  email: string
  password: string
}

export default function EmailForm() {
  const history = useHistory()
  const [passwordShown, setPasswordShown] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  const [focusInput, setFocusInput] = useState<boolean>(false)

  const { mutateAsync: mutatePostEmailLogin, error, isError, isLoading } = usePostEmailLogin()
  let errorMessage = error as any

  const onClickHandlerFindEmail = () => {
    history.push(ROUTES.AUTH_FIND_EMAIL)
  }

  const onClickHandlerPasswordReset = () => {
    history.push(ROUTES.AUTH_RESET_PASSWORD)
  }

  const onClickHandlerSignUp = () => {
    history.push(ROUTES.AUTH_SIGN_UP)
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  useEffect(() => {
    if (isError) {
      setLoginLoading(false)
    }
  }, [isError])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<IFormInput>({ mode: 'all' })

  const onSubmit = async (data: any) => {
    if (isLoading) return
    setLoginLoading(true)
    setFocusInput(false)
    await mutatePostEmailLogin({
      email: data.email,
      password: data.password,
    })
    LogManager.Instance.sendLog(
      'login',
      {
        method: 'email',
      },
      LOGGER.GTM
    )
  }

  return (
    <EmailWrapper>
      <InfoAndSignUpBtnWrapper>
        <EmailInfo>이메일 로그인</EmailInfo>
        <SignUpBtn onClick={onClickHandlerSignUp}>회원가입</SignUpBtn>
      </InfoAndSignUpBtnWrapper>

      {isError && errorMessage.response.status !== 405 && (
        <ServerErrorInfo>
          <WarningIcon name="redError" />
          {errorMessage.response.data.message}
        </ServerErrorInfo>
      )}
      <LoginFormWrapper>
        <FormWrapper>
          <FormInput
            placeholder="이메일 주소"
            type="email"
            {...register('email', {
              required: true,
              minLength: 10,
              pattern: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
            })}
            onFocus={() => setFocusInput(true)}
          />
          <span>{isError && !focusInput && errorMessage.response.status === 405 && <Icon name="redError" />}</span>
        </FormWrapper>
        {errors.email && errors.email.type === 'pattern' && <WarningText>이메일 형식이 올바르지 않습니다.</WarningText>}
        {isError && !focusInput && errorMessage.response.status === 405 && (
          <WarningText>{errorMessage.response.data.message}</WarningText>
        )}

        <FormWrapper>
          <FormInput
            placeholder="비밀번호"
            type={passwordShown ? 'text' : 'password'}
            {...register('password', {
              required: true,
              minLength: 1,
            })}
          />
          {watch('password') && watch('password').length !== 0 && (
            <span onClick={togglePasswordVisiblity}>{passwordShown ? '숨기기' : '표시'}</span>
          )}
        </FormWrapper>

        <LoginButton type="submit" disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          {loginLoading ? <Spinner color="white" /> : '로그인'}
        </LoginButton>
      </LoginFormWrapper>

      <ListAuthOptions>
        <AuthSetBtn>
          <FindEmail onClick={onClickHandlerFindEmail}>이메일 찾기</FindEmail>
          <SetPassword onClick={onClickHandlerPasswordReset}>비밀번호 재설정</SetPassword>
        </AuthSetBtn>
      </ListAuthOptions>
    </EmailWrapper>
  )
}

const EmailWrapper = styled.div`
  width: 100%;
  display: block;
  flex-direction: row;
`

const ServerErrorInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.warning};
  font-size: 1.6rem;
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
`

const LoginFormWrapper = styled.form`
  width: 100%;
  position: relative;
`

const InfoAndSignUpBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const EmailInfo = styled.div`
  text-align: left;
  color: ${({ theme }) => `${theme.colors.black}`};
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  margin-right: 16px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.1rem 1.6rem;
  margin-top: 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.gray};
  border-radius: 0.8rem;

  span {
    flex: 0 0 auto;
    font-size: 1.6rem;
    cursor: pointer;
    background-color: white;
  }

  :focus-within {
    ime-mode: disabled;
    -webkit-ime-mode: disabled;
    -moz-ime-mode: disabled;
    -ms-ime-mode: disabled;
    border: solid 0.1rem #003399;
  }
`

const FormInput = styled.input`
  width: 100%;
  border: 0;
  font-size: 1.6rem;
  background-color: ${({ theme }) => `${theme.colors.white}`};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0;

  ::placeholder {
    opacity: 0.3;
  }
`

const WarningIcon = styled(Icon)`
  margin-right: 0.8rem;
`

const WarningText = styled.span`
  margin-top: 0.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => `${theme.colors.warning}`};
`

const LoginButton = styled.button<{ disabled?: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 4.8rem;
  border: none;
  border-radius: 0.8rem;
  -webkit-appearance: none;
  background-color: ${({ theme }) => `${theme.colors.main}`};
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => `${theme.colors.white}`};
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;

  ${({ disabled }) =>
    disabled === true &&
    `
    background-color: #edeff1;
    color: #1e1e2c;
    opacity: 0.5;
    cursor: no-drop;

  `}
`
const ListAuthOptions = styled.div`
  display: flex;
  justify-content: space-between;
`

const AuthSetBtn = styled.div`
  display: flex;
  font-size: 1.6rem;
  font-weight: 400;
`
const SignUpBtn = styled.div`
  box-sizing: border-box;
  width: 61px;
  height: 32px;
  border-radius: 0.8rem;
  font-size: 12px;
  line-height: 16px;
  border: 0.1rem solid #b1b1b5;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px;
`

const FindEmail = styled.div`
  margin-right: 3rem;
  line-height: 2.4rem;
  cursor: pointer;
`

const SetPassword = styled.div`
  line-height: 2.4rem;
  cursor: pointer;
`
