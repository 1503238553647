import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type ToastProps = {
  title: string
  open: boolean
}

const initialState: ToastProps = {
  title: '',
  open: false,
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    closeToast: (state) => {
      state.title = ''
      state.open = false
    },
    openToast: (state, action: PayloadAction<string>) => {
      state.title = action.payload
      state.open = true
    },
  },
})

export const { closeToast, openToast } = toastSlice.actions

export default toastSlice.reducer
