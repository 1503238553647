import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { fadeIn } from '../../../assets/styles/animation'

const modalEl: HTMLElement = document.getElementById('modal') as HTMLElement

export type ModalHeaderColorType = 'black' | 'warning'

/**
 * @description 모달레이아웃 속성들
 * @param {boolean} open 모달 오픈 여부
 * @param {React.ReactNode} children 리액트 요소
 * @param {boolean} noPadding 모달에 패딩을 0으로 초기화 유무
 * @param {boolean} borderRadius 모달 둥글기 유무
 */
export type ModalLayoutProps = {
  open: boolean
  children: React.ReactNode
  onClose?: () => void | Promise<void>
  noPadding?: boolean
  borderRadius?: boolean
}

export type ModalHeaderProps = {
  color: ModalHeaderColorType
  children: React.ReactNode
}

export type ModalBodyProps = {
  children: React.ReactNode
}

export type ModalFooterProps = {
  children: React.ReactNode
}

export type ModalHeadlineProps = {
  children: React.ReactNode
}

export type ModalSublineProps = {
  children: React.ReactNode
}

export default function ModalLayout({
  open,
  children,
  onClose,
  borderRadius = true,
  noPadding = false,
}: ModalLayoutProps) {
  return createPortal(
    <>
      {open ? (
        <Dimmer onClick={onClose}>
          <StyledModal onClick={(e) => e.stopPropagation()} noPadding={noPadding} borderRadius={borderRadius}>
            {children}
          </StyledModal>
        </Dimmer>
      ) : null}
    </>,
    modalEl
  )
}

function ModalHeader({ children, color = 'black' }: ModalHeaderProps) {
  return <StyledModalHeader color={color}>{children}</StyledModalHeader>
}

function ModalBody({ children }: ModalBodyProps) {
  return <StyledModalBody>{children}</StyledModalBody>
}

function ModalFooter({ children }: ModalFooterProps) {
  return <StyledModalFooter>{children}</StyledModalFooter>
}

function ModalHeadline({ children }: ModalHeadlineProps) {
  return <Headline>{children}</Headline>
}

function ModalSubline({ children }: ModalSublineProps) {
  return <Subline>{children}</Subline>
}

ModalLayout.Header = ModalHeader
ModalLayout.Body = ModalBody
ModalLayout.Footer = ModalFooter
ModalLayout.Headline = ModalHeadline
ModalLayout.Subline = ModalSubline

const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(30, 30, 44, 0.4);
  z-index: 10000003 !important;
  animation: ${fadeIn} 0.3s ease-in-out;
`

const StyledModal = styled.div<{ noPadding?: boolean; borderRadius?: boolean }>`
  min-width: 46.4rem;
  border-radius: ${({ borderRadius }) => (borderRadius ? '1.6rem' : 0)};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme, noPadding }) => (noPadding ? '0' : theme.spacing(4))};
  position: relative;
  z-index: 10000004 !important;

  ${({ theme, noPadding }) => theme.media.mobile`
  width: 31.2rem;
  min-width: 31.2rem;
  padding: ${noPadding ? '0px' : '24px'}
  `}
`

const StyledModalHeader = styled.header<{ color: ModalHeaderColorType }>`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: ${({ theme, color }) => (color === 'black' ? theme.colors.black : theme.colors.warning)};

  ${({ theme }) => theme.media.mobile`
  font-size: 2rem;
  line-height: 2.8rem;
  `}
`

const StyledModalBody = styled.div`
  width: 100%;
  height: 100%;
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.media.mobile`
  margin: ${theme.spacing(2, 0, 3, 0)}
  
  `}
`

const StyledModalFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button ~ button {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }

  button {
    ${({ theme }) => theme.media.mobile`
    min-width: 10rem;
    min-height: 4rem;
    `}

    span {
      ${({ theme }) => theme.media.mobile`
      font-size: 1.4rem;
      `}
    }
  }
`

const Headline = styled.p`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
  word-break: keep-all;
`

const Subline = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.black};
  margin-top: ${({ theme }) => theme.spacing(1)};
`
