import { getStore, GetStoreParam } from 'lib/api/store/getStore'
import { QueryOptionsOf } from 'lib/utils/types'
import { useQuery } from 'react-query'

export default function useStoreQuery(value: GetStoreParam, options: QueryOptionsOf<typeof getStore> = {}) {
  return useQuery(createKey(value.id), () => getStore(value), options)
}

const createKey = (id: string) => ['store', id]
useStoreQuery.createKey = createKey
