import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { fadeIn } from '../../../assets/styles/animation'

const modalEl: HTMLElement = document.getElementById('modal') as HTMLElement

export type BrwnieCrewIntroduceModalProps = {
  open: boolean
  onClose: () => void
}

export default function BrwnieCrewIntroduceModal({ open, onClose }: BrwnieCrewIntroduceModalProps) {
  return createPortal(
    <>
      {open ? (
        <Dimmer onClick={onClose}>
          <ModalWrapper onClick={(e) => e.stopPropagation()}>
            <StyledModal>
              <p className="title">
                브라우니의 믿음직한 요원들,
                <br />
                크루(Crew)를 소개합니다.
              </p>

              <img
                className="titleImage"
                src={'/image/브라우니_무인매장_브라우니크루소개.png'}
                alt="무인매장 브라우니"
              />

              <p className="subtitle">
                <b>브라우니 크루는 5단계의 과정을 거쳐 채용된 인원들</b>로 실무를 기반으로 한&nbsp;
                <b>브라우니만의 전문적인 교육 프로세스를 통해 투입되는 무인매장 관리의 프로</b>들입니다.
              </p>

              <ProcessWrapper>
                <p className="title">크루 채용 Process</p>
                <ul>
                  <li>
                    <span>1단계</span>
                    <span>서류 전형, 심층 인터뷰</span>
                  </li>
                  <li>
                    <span>2단계</span>
                    <span>이론 교육</span>
                  </li>
                  <li>
                    <span>3단계</span>
                    <span>동행 실습</span>
                  </li>
                  <li>
                    <span>4단계</span>
                    <span>
                      <b>크루 합류</b>
                    </span>
                  </li>
                  <li>
                    <span>5단계</span>
                    <span>정기 교육</span>
                  </li>
                </ul>
              </ProcessWrapper>

              <FooterWrapper>
                <div onClick={onClose}>네, 확인했어요</div>
              </FooterWrapper>
            </StyledModal>
          </ModalWrapper>
        </Dimmer>
      ) : null}
    </>,
    modalEl
  )
}

const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(30, 30, 44, 0.4);
  z-index: 10000003 !important;
  animation: ${fadeIn} 0.3s ease-in-out;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ModalWrapper = styled.div`
  width: 480px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  z-index: 10000004 !important;
`

const StyledModal = styled.div`
  width: 100%;
  padding: 32px;
  padding-bottom: 48px;
  box-sizing: border-box;
  position: relative;

  .titleImage {
    margin: 24px 0px 12px;
    width: 416px;
    height: 160px;
  }

  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    color: var(--gray800);
  }

  .subtitle {
    font-size: 16px;
    line-height: 28px;
    color: var(--gray700);
  }
`

const ProcessWrapper = styled.div`
  margin-top: 32px;
  width: 416px;
  height: 252px;
  background-color: var(--gray100);
  border-radius: 20px;
  padding: 16px 16px 24px;
  box-sizing: border-box;

  .title {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: var(--gray800);
    margin-bottom: 12px;
  }

  & > ul {
    width: 384px;
    height: 172px;
    display: flex;
    flex-direction: column;

    & > li {
      width: inherit;
      height: 28px;
      display: flex;
      align-items: center;
      background-color: var(--gray0);
      border-radius: 99px;
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      span {
        display: flex;
        flex-direction: row;

        &:nth-of-type(1) {
          padding-left: 16px;
          padding-right: 12px;
          font-weight: 700;
          font-size: 12px;
          line-height: 21px;
          color: var(--brand400);
        }

        &:nth-of-type(2) {
          padding-left: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: var(--gray700);
        }
      }
    }
  }
`

const FooterWrapper = styled.div`
  position: absolute;
  z-index: 10000004 !important;
  border-top: 1px solid #e0e0e0;
  left: 0px;
  bottom: -49px;
  background-color: var(--gray0);
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 49px;
  border-radius: 0px 0px 16px 16px;

  & > div {
    border-radius: inherit;
    width: 240px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
    color: var(--gray800);
    height: 48px;
  }
`
