import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import { Modal, Top } from 'ohds-web'
import {
  selectholidaySchedulesCount,
  selectInvalidDaySchedules,
  ScheduleType,
  setInvalidDayFixSchedules,
  setInvalidTimeFixSchedules,
} from 'modules/features/service/serviceSlice'
import useQueryParamHook from 'hooks/useQueryParamHook'
import BrwnieMainContainer from 'components/common/BrwnieMainContainer'

import InvalidDayCalendar from 'components/ServicePostPage/InvalidDayPage/InvalidDayCalendar'
import InvalidDayBottomCTA from 'components/ServicePostPage/InvalidDayPage/InvalidDayBottomCTA'
import ActiveDateInfoButton from 'components/ServicePostPage/ActiveDateInfoButton'

export default function InvalidDayPage() {
  const query = useQueryParamHook()

  const invalidIndex = Number(query.get('index')) ?? 0

  const dispatch = useAppDispatch()
  const { invalidDayFixSchedules } = useAppSelector((state) => state.service)

  const invalidDaySchedules = useAppSelector(selectInvalidDaySchedules)
  const holidaySchedulesCount = useAppSelector(selectholidaySchedulesCount)

  const { month: invalidMonth, date: invalidDate } = invalidDaySchedules[invalidIndex]

  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleType>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true)

  useEffect(() => {
    dispatch(setInvalidTimeFixSchedules([]))
  }, [dispatch])

  useEffect(() => {
    if (invalidDayFixSchedules.length - 1 >= invalidIndex) {
      const copiedSchedules = [...invalidDayFixSchedules]
      copiedSchedules.splice(invalidIndex, 1)
      dispatch(setInvalidDayFixSchedules(copiedSchedules))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invalidIndex])

  const initSelect = () => {
    setSelectedSchedule(undefined)
  }

  return (
    <BrwnieMainContainer>
      <Top
        firstTitle={`${invalidMonth}월 ${invalidDate}일의 `}
        firstTitle2="변경일을 선택"
        firstTitle3="해 주세요"
        firstTitle2Brand
        titleFont={{
          fontSize: 'h3',
        }}
        paddingTop={40}
      />
      <ActiveDateInfoButton />

      <InvalidDayCalendar
        selectedSchedule={selectedSchedule}
        setSelectedSchedule={(schedule) => setSelectedSchedule(schedule)}
      />

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`${
          holidaySchedulesCount < invalidIndex + 1 ? '마감일' : '공휴일'
        }인 ${invalidMonth}월 ${invalidDate}일을 다른 날로 변경해 주세요`}
        description={
          holidaySchedulesCount < invalidIndex + 1
            ? undefined
            : '공휴일은 지속적인 품질 유지를 위해 브라우니도 충전합니다'
        }
        primaryProps={{
          label: '변경하기',
          onClick: () => setIsModalOpen(false),
          fill: 'brand',
        }}
      />

      {selectedSchedule && (
        <InvalidDayBottomCTA
          linear
          label={`${selectedSchedule.month}월 ${selectedSchedule.date}일 ${selectedSchedule.hour}시로 변경할게요`}
          selectedSchedule={selectedSchedule}
          onMoveToAnotherInvalidDay={() => {
            initSelect()
            setIsModalOpen(true)
          }}
        />
      )}
    </BrwnieMainContainer>
  )
}
