export enum ROUTES {
  ROOT = '/',
  AUTH_LOGIN = '/auth/login',
  AUTH_RESET_PASSWORD_INFO = '/auth/reset/password/info',
  AUTH_RESET_PASSWORD = '/auth/reset/password',
  AUTH_SOCIAL_OPTION = '/auth/social-option',
  AUTH_SIGN_UP = '/auth/sign-up',
  AUTH_FIND_EMAIL = '/auth/find/email/',
  AUTH_ERROR = '/auth/error',
  CHANGE = '/change',
  SERVICE = '/service',
  PAYMENT = '/payment',
  CHANGE_CHECKING = '/change-checking',
  MYPAGE = '/mypage',
  FAQ = '/faq',
  MAP = '/map',
  STORE = '/store',
  STORE_POST = '/store-post',
  SERVICE_SELF_LAUNDRY = '/service-detail/self-laundry',
  SERVICE_ICE_CREAM = '/service-detail/ice-cream',
  SERVICE_SMART_MACHINE = '/service-detail/smart-machine',
  SERVICE_STATIONERY = '/service-detail/stationery',
  SERVICE_PRINTER_MACHINE = '/service-detail/pinter-machine',
  SERVICE_CONVENIENCE = '/service-detail/convenience',
  SERVICE_STUDY_CAFE = '/service-detail/study-cafe',
  SERVICE_CAFE = '/service-detail/cafe',
  STUDIO = '/service-detail/studio',
  EASY_CONSULTING = '/easy-consulting',
}
