import client from '../client'

export async function postSendEmailForResetEmail({ email }: SendEmailForResetEmailReq) {
  const response = await client.post<undefined>('auth-api/v1/auth/send/reset/email', { email })

  return response.data
}

export type SendEmailForResetEmailReq = {
  email: string
}
