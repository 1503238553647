import AlertModal from 'components/common/AlertModal'
import { useState } from 'react'
import styled from 'styled-components'

export default function InAppAlert() {
  const [openAlert, setOpenAlert] = useState<boolean>(true)

  return (
    <>
      {navigator.userAgent.match(/Instagram|kakaotalk|daumapps|line/i) && (
        <AlertModal
          open={openAlert}
          onConfirm={() => {
            setOpenAlert(false)
          }}
          confirmText={'닫기'}
          buttonWidth={264}
        >
          <InAppText>
            브라우니 웹사이트는 사파리 브라우저에 <br />
            더 최적화되어 있어요😎 <br />
            <span>사파리 브라우저</span>로 접속하시면 <br />더 최적한 이용이 가능해요.
          </InAppText>
        </AlertModal>
      )}
    </>
  )
}

const InAppText = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  line-height: 24px;
  span {
    color: ${({ theme }) => theme.colors.blue};
  }
`
