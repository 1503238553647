import { useMutation, useQueryClient } from 'react-query'
import { logout } from 'lib/api/auth/logout'

export default function useLogout() {
  const queryClient = useQueryClient()
  return useMutation(() => logout(), {
    onSuccess: async () => {
      queryClient.removeQueries('authCheck')
      localStorage.removeItem('scrollData')
    },
  })
}
