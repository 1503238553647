import styled from 'styled-components'
import { useState } from 'react'

import Header from 'components/common/Header'
import Footer from 'components/common/Footer'
import Icon from 'components/Icon'
import Select from 'components/Select'
import Content from 'components/common/Content'

export default function NoticePage() {
  const [searchText, setSearchText] = useState('')
  const notices = [
    { title: '[공지] 공지사항 제목 입니다.', url: 'https://www.youtube.com/watch?v=XA2YEHn-A8Q', date: '2021.07.01' },
    { title: '[공지] 공지사항 제목 입니다.', url: 'https://www.youtube.com/watch?v=XA2YEHn-A8Q', date: '2021.07.01' },
    { title: '[공지] 공지사항 제목 입니다.', url: 'https://www.youtube.com/watch?v=XA2YEHn-A8Q', date: '2021.07.01' },
    { title: '[공지] 공지사항 제목 입니다.', url: 'https://www.youtube.com/watch?v=XA2YEHn-A8Q', date: '2021.07.01' },
    { title: '[공지] 공지사항 제목 입니다.', url: 'https://www.youtube.com/watch?v=XA2YEHn-A8Q', date: '2021.07.01' },
    { title: '[공지] 공지사항 제목 입니다.', url: 'https://www.youtube.com/watch?v=XA2YEHn-A8Q', date: '2021.07.01' },
  ]

  function onSearchTextChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value)
  }

  const selectOptions = ['지난달', '2020', '2019', '2018', '2017', '전체 기간']

  return (
    <>
      <Header title="공지사항" />
      <Content>
        <ListContainer>
          <ToolBox>
            <Select placeholder="기간" options={selectOptions} />
            <SearchTextFieldBox>
              <Icon name="greySearch" />
              <SearchTextField placeholder="검색" onChange={onSearchTextChanged} />
            </SearchTextFieldBox>
          </ToolBox>
          <BoardBox>
            {notices
              .filter((notice) => notice.title.includes(searchText))
              .map((notice, index) => {
                return (
                  <Board key={index} href={notice.url} target="_blank">
                    <h2>{notice.title}</h2>
                    <span>{notice.date}</span>
                  </Board>
                )
              })}
          </BoardBox>
        </ListContainer>
      </Content>
      <Footer />
    </>
  )
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 83.2rem;
  margin: auto 0;

  .coupons-box {
    margin-bottom: 10rem;
  }
  .expired-coupons-box .content {
    opacity: 0.5;
  }
`

const ToolBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 3.2rem;
`

const SearchTextFieldBox = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};
  margin-left: auto;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 0.8rem;

  svg {
    margin: ${({ theme }) => theme.spacing(0, 1, 0, 0)};
  }
`

const SearchTextField = styled.input`
  display: flex;
  align-items: center;
  border: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;

  &::placeholder {
    color: 1px solid ${({ theme }) => theme.colors.black};
    opacity: 0.5;
  }
`

const BoardBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`

const Board = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4, 0)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  color: inherit;
  text-decoration: none;

  span {
    margin-left: auto;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`
