import styled, { keyframes } from 'styled-components'
import React, { useRef, useState } from 'react'
import dayjs from 'dayjs'

import Header from 'components/common/Header'
import Footer from 'components/common/Footer'
import Content from 'components/common/Content'
import Spinner from 'components/common/Spinner'
import Icon from 'components/Icon'
import Card, { CardHeader, CardContent } from 'components/Card'

import usePointsQuery from 'hooks/query/points/usePointsQuery'
import useMyPointsQuery from 'hooks/query/points/useMyPointsQuery'
import Paginator from 'components/Paginator'
import { Points, RefundPoints, UsedPoints } from 'lib/api/points/types'
import addComma from 'utils/common/addComma'
import { useScrollGet } from 'hooks/useScrollGet'
import ApplyBanner from 'components/common/ApplyBanner'
import { isMobile } from 'react-device-detect'

export default function PointPage() {
  const [page, setPage] = useState(1)

  const mainRef = useRef<HTMLDivElement>(null)

  const { totalOffsetY } = useScrollGet({})
  const { data: pointsHistory, isLoading: pointsHistoryLoading } = usePointsQuery({ page })
  const { data: myPoints } = useMyPointsQuery(0)
  const perPage = 10
  const maxPage = Math.ceil((pointsHistory?.count || 0) / perPage)
  return (
    <>
      <Header title="포인트" offsetY={totalOffsetY} />
      <Content>
        <ListContainer ref={mainRef}>
          {/* 포인트 요약 */}
          <Box className="summary-box" direction="column">
            <Card className="summary-card">
              <CardHeader alignItems="center" justifyContent="center">
                <Icon name="purpleDiscount" />
                <h2>포인트</h2>
              </CardHeader>
              <PointCardContent padding={0}>
                <Box direction="column" alignItems="center">
                  <Box className="info-box" alignItems="center" direction="column">
                    <h3>{addComma(myPoints?.owned_points ?? 0)} P</h3>
                    <span>
                      30일 이내 소멸 예정 포인트 : <b>{addComma(myPoints?.will_expiry_points ?? 0)} P</b>
                    </span>
                  </Box>
                  <hr />
                  <Box className="policy-box" direction="column">
                    <h4> 사용안내 </h4>
                    <ul>
                      <li>브라우니 회원만 적립/사용이 가능합니다.</li>
                      <li>20,000 P 이상 적립되었을 때 사용 가능합니다.</li>
                      <li>포인트는 1,000 P 단위로 사용 가능합니다.</li>
                      <li>변경, 취소, 환불 시 위약금은 포인트로 결제가 불가능합니다.</li>
                      <li>결제 취소 시, 포인트 사용 기간이 남아있는 경우 자동 복원됩니다.</li>
                      <li>유효기간은 2년 입니다.</li>
                      <li>만료된 포인트는 자동으로 소멸됩니다.</li>
                      <li>회원을 탈퇴할 경우, 잔여 포인트는 모두 소멸됩니다.</li>
                      <li>포인트 정책은 브라우니 운영 정책에 따라 변경될 수 있습니다.</li>
                    </ul>
                  </Box>
                </Box>
              </PointCardContent>
            </Card>
          </Box>

          {isMobile && <ApplyBanner />}

          {/* 사용 내역 */}
          <Box className="points-use-list-box" direction="column">
            <Card className="points-use-list-card">
              <CardHeader alignItems="center" justifyContent="center">
                <Icon name="blackPaper" />
                <h2>포인트 내역</h2>
              </CardHeader>
              <CardContent>
                {pointsHistoryLoading ? (
                  <SpinnerWrapper>
                    <Spinner color="main" />
                  </SpinnerWrapper>
                ) : (
                  <></>
                )}
                {(pointsHistory?.points || []).map((data, index) => {
                  const usedPoints = data as UsedPoints
                  const refundPoints = data as RefundPoints
                  const points = data as Points
                  let type = '추가'
                  let pointsData = `+${addComma(points.points)}`
                  let className = 'state add'
                  if (usedPoints.used_points) {
                    type = '사용'
                    pointsData = `-${addComma(usedPoints.used_points)}`
                    className = 'state use'
                  } else if (refundPoints.refunded_points) {
                    type = '환불'
                    pointsData = `+${addComma(refundPoints.refunded_points)}`
                    className = 'state refund'
                  }
                  return (
                    <React.Fragment key={points.id || usedPoints.id}>
                      <MobilePointsUseHistoryCell>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                          <div className={className}>
                            {/* {usedPoints.used_points ? '사용' : '추가'} */}
                            {type}
                          </div>
                          <div className="used-date">
                            {dayjs(usedPoints.used_at || points.created_at).format('YYYY.MM.DD')}
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div className="use-reason">{usedPoints.reason || points.gained_reason}</div>
                          <div className="used-points">
                            <b>{pointsData}P</b>
                          </div>
                        </div>
                      </MobilePointsUseHistoryCell>
                      <PointsUseHistoryCell>
                        <span className={className}>{type}</span>
                        <span className="use-reason">{usedPoints.reason || points.gained_reason}</span>
                        <span className="used-points">
                          <b>{pointsData}P</b>
                        </span>
                        <span className="used-date">
                          {dayjs(usedPoints.used_at || points.created_at).format('YYYY.MM.DD')}
                        </span>
                      </PointsUseHistoryCell>
                      <hr />
                    </React.Fragment>
                  )
                })}
              </CardContent>
            </Card>
            {maxPage > 1 ? (
              <Paginator page={page} onChanged={(page: number) => setPage(page)} maxPage={maxPage} />
            ) : (
              <></>
            )}

            {!isMobile && <ApplyBanner pointPage />}
          </Box>
        </ListContainer>
      </Content>
      <Footer />
    </>
  )
}

const Box = styled.div<{ direction?: string; flex?: string; alignItems?: string; justifyContent?: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  flex: ${({ flex }) => flex ?? '1 1 auto'};
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};

  ${({ theme }) => theme.media.mobile`
  justify-content: center;
  display: grid;
  `}
`

const PointCardContent = styled(CardContent)`
  ${({ theme }) => theme.media.mobile`
  padding: 0;
`}
`

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 0;

  ${({ theme }) => theme.media.mobile`
      flex-direction: column;
    `}

  .coupons-box {
    margin-bottom: 10rem;
  }
  .expired-coupons-box .content {
    opacity: 0.5;
  }

  // 요약 카드
  .summary-card {
    width: 32rem;
    max-width: 32rem;
    margin-right: ${({ theme }) => theme.spacing(8)};

    ${({ theme }) => theme.media.mobile`
      margin-right: 0px;
      margin-bottom: 4rem;
      max-width: 31.2rem;
      justify-content: center;
    `}
    .info-box {
      width: 100%;
      padding: ${({ theme }) => theme.spacing(5.5, 0, 5.5, 0)};
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};

      ${({ theme }) => theme.media.mobile`
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      `}
      h3 {
        font-size: 3.6rem;
        line-height: 3.6rem;
        font-weight: 300;
        color: ${({ theme }) => theme.colors.main};
        margin-bottom: ${({ theme }) => theme.spacing(3)};

        ${({ theme }) => theme.media.mobile`
          margin-bottom: 1.6rem;
          text-align: center;
        `}
      }
      span {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${({ theme }) => theme.colors.gray};

        ${({ theme }) => theme.media.mobile`
          font-size: 1.4rem;
        `}
        b {
          color: ${({ theme }) => theme.colors.black};
        }
      }
    }
    .policy-box {
      padding: ${({ theme }) => theme.spacing(4)};

      ${({ theme }) => theme.media.mobile`
        padding: 0 1.6rem 1.6rem 1.6rem;
        margin-bottom: 16px;
      `}
      h4 {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: ${({ theme }) => theme.spacing(1)};

        ${({ theme }) => theme.media.mobile`
          margin-top: 1.6rem;
        `}
      }
      ul {
        padding-left: ${({ theme }) => theme.spacing(2)};
      }
      li {
        font-size: 1.2rem;
        line-height: 1.6rem;
        list-style-type: disc;
        word-break: keep-all;
        &:not(:last-child) {
          margin-bottom: ${({ theme }) => theme.spacing(1)};
        }
      }
    }
  }

  // 포인트 사용 리스트 박스
  .points-use-list-card {
    width: 65.2rem;
    max-width: 65.2rem;
    margin-bottom: ${({ theme }) => theme.spacing(7)};

    hr {
      width: 100%;
      border-top: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
      border-left: 0;
      margin: ${({ theme }) => theme.spacing(4, 0)};
      &:last-child {
        display: none;
      }

      ${({ theme }) => theme.media.mobile`
        margin-top: 16px;
        margin-bottom: 16px;
      `}
    }

    ${({ theme }) => theme.media.mobile`
      max-width: 31.2rem;
      margin-bottom:2.4rem;
      margin-top: 40px;
    `}
  }
`

const PointsUseHistoryCell = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  animation: ${FadeAnimation} 0.3s ease-in-out;
  span {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .state {
    flex: 0 0 auto;
    margin-right: ${({ theme }) => theme.spacing(5)};
    font-weight: bold;
    &.add {
      color: ${({ theme }) => theme.colors.orange};
    }
    &.use {
      color: ${({ theme }) => theme.colors.main};
    }
    &.refund {
      color: ${({ theme }) => theme.colors.warning};
    }
  }
  .use-reason {
    width: 100%;
    padding-right: ${({ theme }) => theme.spacing(5)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .used-points {
    flex: 0 0 auto;
    margin-left: auto;
  }
  .used-date {
    flex: 0 0 auto;
    margin-left: ${({ theme }) => theme.spacing(5)};
    color: ${({ theme }) => theme.colors.gray};
  }

  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`

const MobilePointsUseHistoryCell = styled.div`
  display: none;
  flex-direction: row;
  width: 100%;
  animation: ${FadeAnimation} 0.3s ease-in-out;
  font-size: 1.4rem;
  line-height: 2.4rem;
  .state {
    flex: 0 0 auto;
    font-weight: bold;
    &.add {
      color: ${({ theme }) => theme.colors.orange};
    }
    &.use {
      color: ${({ theme }) => theme.colors.main};
    }
    &.refund {
      color: ${({ theme }) => theme.colors.warning};
    }
  }
  .use-reason {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .used-points {
    flex: 0 0 auto;
  }
  .used-date {
    flex: 0 0 auto;
    color: ${({ theme }) => theme.colors.gray};
  }

  ${({ theme }) => theme.media.mobile`
    display: block;
  `}
`
