import client from '../client'

export async function postEventMailSend(req: PostEventMailSendReq) {
  const response = await client.post(`/auth-api/v1/auth/tester-email-send`, req)
  return response.data
}

export type PostEventMailSendReq = {
  email: string
}
