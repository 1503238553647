import React from 'react'

import styled from 'styled-components'
import AnimatedNumbers from 'react-animated-numbers'

function LandingCountSection() {
  return (
    <LandingCountSectionWrapper>
      <ContentWrapper>
        <h1>
          <span>IT 기술과 결합된</span>
          브라우니를 믿는 이유
        </h1>
      </ContentWrapper>
      <CountDetailUl>
        <CountDetailLi>
          <span>무인매장 관리</span>
          <Counter>
            <AnimatedNumbers includeComma animateToNumber={1} configs={[{ mass: 1, tension: 220, friction: 100 }]} />
            <span className="small">위</span>
          </Counter>
        </CountDetailLi>
        <CountDetailLi>
          <span>고객사</span>
          <Counter>
            <AnimatedNumbers includeComma animateToNumber={300} configs={[{ mass: 1, tension: 220, friction: 100 }]} />
            <span className="small">+</span>
          </Counter>
        </CountDetailLi>
        <CountDetailLi>
          <span>누적 관리 수</span>
          <Counter>
            <AnimatedNumbers
              includeComma
              animateToNumber={19300}
              configs={[{ mass: 1, tension: 220, friction: 100 }]}
            />
            <span className="small">+</span>
          </Counter>
        </CountDetailLi>
        <CountDetailLi>
          <span>서비스 유지율</span>
          <Counter>
            <AnimatedNumbers includeComma animateToNumber={95} configs={[{ mass: 1, tension: 220, friction: 100 }]} />
            <span className="small">%</span>
          </Counter>
        </CountDetailLi>
      </CountDetailUl>
    </LandingCountSectionWrapper>
  )
}

const LandingCountSectionWrapper = styled.section`
  width: 100%;
  padding: 200px 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
    url('/image/landing_count_section/background.webp') lightgray -181.471px 0px / 114.177% 121.789% no-repeat;

  background-size: cover;

  ${({ theme }) => theme.media.mobile`
    height: auto;
    padding: 60px 16px;
    background-size: cover;
    background-position: center;
  `};
`

const ContentWrapper = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    font-weight: bold;
    font-size: 56px;
    line-height: 125%;
    color: #ffffff;

    & > span {
      color: #ffec3d;
      font-size: 26px;
      font-weight: 700;
      line-height: 140%;

      margin-bottom: 16px;
    }

    ${({ theme }) => theme.media.mobile`
        & > span {
          margin-bottom: 8px;
        }
    `}
  }

  @media only screen and (max-width: 768px) {
    & > h1 {
      width: 300px;
      font-size: 30px;
      line-height: 40px;

      .mobile {
        display: block;
      }
    }
  }
`

const CountDetailUl = styled.ul`
  position: relative;

  display: flex;
  justify-content: space-between;
  width: 1100px;
  margin-top: 70px;

  border-radius: 40px;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);

  & > li:nth-of-type(2) {
    .iconWrapper {
      background: #d0fbff;
    }
  }

  ${({ theme }) => theme.media.mobile`
    width: 332px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    margin-top: 28px;

    background: transparent;
    border-radius: 0px;
    backdrop-filter: none;
    box-shadow: none;
  `}
`

const CountDetailLi = styled.li`
  width: 260px;
  height: 260px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > span {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.media.mobile`
    width: 160px;
    height: 140px;

    border-radius: 16px;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(6px);

    & > span {
      font-size: 16px;
    }
  `}
`

const Counter = styled.div`
  display: flex;
  font-size: 56px;
  font-weight: 800;
  line-height: 125%;
  color: #ffec3d;

  & > .small {
    transform: translateY(5px);
    font-size: 40px;
  }

  ${({ theme }) => theme.media.mobile`
    font-size: 36px;

    & > .small {
      font-size: 20px;
      transform: translateY(8px)
    }
  `}
`
export default React.memo(LandingCountSection)
