import { useQuery } from 'react-query'
import { getBrwnieBasePrice } from 'lib/api/admin/getBrwnieBasePrice'

export const createBrwnieBasePriceKey = ['admin-brwnie-base-price']

export function useGetBrwnieBasePriceQuery() {
  return useQuery(createBrwnieBasePriceKey, () => getBrwnieBasePrice(), {
    staleTime: Infinity,
  })
}
