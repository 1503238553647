import styled from 'styled-components'

export type SubTitleProps = {
  children: React.ReactNode
}

export default function SubTitle({ children }: SubTitleProps) {
  return <StyledSubTitle>{children}</StyledSubTitle>
}

const StyledSubTitle = styled.h4`
  margin: ${({ theme }) => theme.spacing(4, 0)};
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.media.mobile`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  `}
`
