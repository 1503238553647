import { getAuth } from 'lib/api/auth/getAuth'
import { getResetEmail, ResetEmailParams } from 'lib/api/authVerify/getResetEmail'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { ROUTES } from 'utils/common/routes'

export default function useGetResetEmailVerify() {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutation((value: ResetEmailParams) => getResetEmail(value), {
    onSuccess: async () => {
      await getAuth()
      queryClient.refetchQueries('authCheck')
      history.push(ROUTES.STORE)
    },
    onError: async () => {
      history.push(ROUTES.AUTH_ERROR)
    },
  })
}
