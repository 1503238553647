import { forwardRef, memo, useState } from 'react'
import styled, { css } from 'styled-components'

type InputProps = React.HTMLAttributes<HTMLInputElement> & {
  type?: 'text' | 'password' | 'code'
  disabled?: boolean
  label?: string
  errorText?: string
  correct?: boolean
  value?: string
  placeholder?: string
  id: string
  onReset?: () => void
  time?: string
}

export const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        type = 'text',
        id,
        label,
        placeholder,
        disabled,
        value,
        errorText,
        correct,
        onFocus,
        onBlur,
        onReset,
        time,
        ...props
      },
      ref
    ) => {
      const [show, setShow] = useState<boolean>(false)
      const [isFocus, setIsFocus] = useState<boolean>(false)
      const [inputType, setInputType] = useState<'text' | 'password' | 'code'>(type)

      return (
        <Container>
          {label && <InputLabel htmlFor={id}>{value && value.length > 0 && label}</InputLabel>}

          <InputContainer $isFocus={isFocus}>
            <StyledInput
              id={id}
              value={value}
              type={inputType}
              placeholder={placeholder}
              disabled={disabled}
              onFocus={(e) => {
                setIsFocus(true)
                if (onFocus) {
                  onFocus(e)
                }
              }}
              onBlur={(e) => {
                setIsFocus(false)
                if (onBlur) {
                  onBlur(e)
                }
              }}
              onReset={onReset}
              ref={ref}
              {...props}
            />
          </InputContainer>

          {errorText && <ErrorText>{errorText}</ErrorText>}
        </Container>
      )
    }
  )
)

const Container = styled.div`
  width: 100%;
  padding: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
`

const InputLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4;
  text-align: left;
  color: #a9afb3;

  margin: 0px;
  margin-bottom: 8px;
`

const InputContainer = styled.div<{ $isFocus?: boolean }>`
  width: 100%;

  padding: 7px 12px;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dde0e4;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ $isFocus }) =>
    $isFocus &&
    css`
      border: 1px solid #3c434d !important;
    `}
`

const StyledInput = styled.input`
  width: 100%;
  min-height: 28px;
  border: none;
  box-sizing: border-box;

  overflow: hidden;
  color: var(--Components-Input-Global-colorText, rgba(0, 0, 0, 0.88));
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &:focus + ${InputLabel} {
    transform: translateY(-33.5px) translateX(-45.5px) scale(0.54545455);
  }

  &::placeholder {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.25);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const ErrorText = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4;
  text-align: left;

  color: #ff3a36;

  margin-top: 4px;
`
