import styled from 'styled-components'

export function FreeCoupon() {
  return (
    <Section>
      <img src="/image/wooribank/free-coupon-background.webp" alt="배경" width={360} />

      <TitleContainer>
        <TitleFirstLine>우리은행 사장님만의</TitleFirstLine>
        <TitleSecondLine>무료 2회 추가 관리</TitleSecondLine>
        <TitleThirdLine>제공해요!</TitleThirdLine>
      </TitleContainer>

      <CouponAssetContainer>
        <img src="/image/wooribank/coupon.webp" alt="쿠폰 이미지" width={285} />
      </CouponAssetContainer>
    </Section>
  )
}

const Section = styled.section`
  width: 100vw;
  height: 404px;
  background-color: #5363ff;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: -1;
`

const TitleContainer = styled.div`
  position: absolute;
  top: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const TitleFirstLine = styled.span`
  font-family: GmarketSansBold;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -1.04px;
  color: white;
`

const TitleSecondLine = styled.h3`
  font-family: GmarketSansBold;
  font-size: 34px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1.36px;
  color: #fcff10;
  margin-top: 4px;
`

const TitleThirdLine = styled.span`
  font-family: GmarketSansBold;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1.12px;
  color: white;
  margin-top: 4px;
`

const CouponAssetContainer = styled.div`
  position: absolute;
  bottom: 21px;
`
