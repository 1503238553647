import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

export const DEFAULT_FONTSIZE = 10

const GlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    font-size: ${DEFAULT_FONTSIZE}px;
  }

  html,
  body {
    height: 100vh;
    overflow-y: scroll;
    overflow: overlay;

    font-display: optional;
    font-family: 'Spoqa Han Sans', --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



    @media only screen and (max-width: 768px) {
      height: 100%;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    @media only screen and (min-width: 768px) {
        &::-webkit-scrollbar {
          width: 6px;
        }
    
        &::-webkit-scrollbar-thumb {
        background: #B1B1B5;
        border-radius: 2px;
        }
    }
  }

  #root {
    height: 100vh;

    @media only screen and (max-width: 768px) {
      height: 100%;
    }
  }

  * {
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
  }

  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6, 
  p {
    margin: 0;
  }

  button {
    border: none;
  }

  input:focus, select:focus, button:focus {
    outline: none;
  }
  
  input:-webkit-autofill:disabled,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
	 transition: background-color 5000s ease-in-out 0s;
	 -webkit-transition: background-color 9999s ease-out;
  box-shadow: 0 0 0px 1000px white inset !important;
  }


@font-face {
  font-family: GmarketSansBold;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: GmarketSansMedium;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

`

export default GlobalStyle
