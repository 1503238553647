import Icon from 'components/Icon'
// import RegionalExpansionModal from 'components/Landing/RegionalExpansionModal'
import TesterEmailSendModal from 'components/Landing/TesterEmailSendModal'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import { getEventBannerStatus, setBannerStatus } from 'modules/features/eventBannerStatus/eventBannerStatusSlice'
import { useState } from 'react'
import styled from 'styled-components'

export default function EventBanner() {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const isEventBannerStatus = useAppSelector(getEventBannerStatus)

  if (!isEventBannerStatus) {
    return null
  }

  return (
    <>
      <BannerWrapper onClick={() => setOpenModal(true)}>
        <TextWrapper>
          <Icon name="EmojiCelebration" />
          <p>1회 체험권 받아보세요!</p>
        </TextWrapper>
        <Icon
          name="mobileNavClose"
          onClick={() => {
            dispatch(setBannerStatus(false))
          }}
        />
      </BannerWrapper>

      <TesterEmailSendModal open={openModal} onClose={() => setOpenModal(false)} />

      {/* 지역확장 안내 모달 */}
      {/* <RegionalExpansionModal open={openModal} onClose={() => setOpenModal(false)} /> */}
    </>
  )
}

const BannerWrapper = styled.div`
  display: none;
  width: 100%;
  height: 48px;
  position: fixed;
  top: 0px;
  left: 0px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  z-index: 10000002 !important;
  background-color: ${({ theme }) => theme.colors.indigoA700};

  & > svg {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.main};
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    display: flex;
  }
`

const TextWrapper = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};

  & > p {
    margin-left: 8px;
  }
`
