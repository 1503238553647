import styled from 'styled-components'
import { AirconService } from './AirconService'
import { FoodingMarketService } from './FoodingMarketService'
import { DryerService } from './DryerService'
import { MuinStoreService } from './MuinStoreService'
import { ElectronicCigaretteService } from './ElectronicCigaretteService'

export function LoadingPartnerServiceSection(): JSX.Element {
  return (
    <Container>
      {/* <MuinStoreService /> */}
      <ElectronicCigaretteService />
      <BottomContainer>
        <AirconService />
        <DryerService />
        <FoodingMarketService />
      </BottomContainer>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  padding: 150px 16px 150px 16px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ecf1f5;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    padding: 70px 16px;
    gap: 0px;
  }
`

const BottomContainer = styled.div`
  width: 960px;
  display: flex;
  flex-direction: row;
  background-color: #ecf1f5;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;

  @media only screen and (min-width: 768px) {
    & > :not(:last-child) {
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 312px;
    padding-top: 12px;
    background-size: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`
