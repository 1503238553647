import { useLocation } from 'react-router'
import styled from 'styled-components'
import Icon from '../../Icon'

export default function PasswordResetInfo() {
  const location = useLocation()
  const targetEmail = location.state as string //보내고자하는 이메일을 useHistory를 통해 받음
  return (
    <StyledPasswordReset>
      <Icon name="emojiClock" />
      <Info>
        <BlueBold>{targetEmail}</BlueBold>&nbsp;로
        <br />
        재설정 메일이 발송되었습니다.
        <br />
        재설정 버튼은 <BlueBold>2시간 동안</BlueBold> 유효합니다.
        <br />
        <br />
        메일을 받지 못한 경우,
        <br />
        스팸메일함을 확인해 주세요.
      </Info>
    </StyledPasswordReset>
  )
}

const StyledPasswordReset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(6)};

  svg {
    width: 8rem;
    height: 8rem;
  }

  ${({ theme }) => theme.media.mobile`
    padding-top: ${theme.spacing(3)}
  `}
`

const Info = styled.span`
  margin-top: ${({ theme }) => theme.spacing(3)};
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`
const BlueBold = styled.span`
  color: ${({ theme }) => theme.colors.indigoA700};
`
