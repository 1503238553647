import styled from 'styled-components'
import { ReactComponent as NaverLogo } from './naver-logo.svg'
import { ReactComponent as SearchIcon } from './search-icon.svg'

export function HowToSection() {
  return (
    <Section>
      <TSectionitleContainer>
        <SpeechBubble>
          <SpeechBubbleContent>무료 2회 추가 관리 받기</SpeechBubbleContent>
          <SpeechBubbleTriangle />
        </SpeechBubble>

        <Title>신청 방법</Title>
      </TSectionitleContainer>

      <Box>
        <NumSquare>
          <Num>1</Num>
        </NumSquare>

        <BoxTitle>
          <b>브라우니 홈페이지 접속(brwnie.kr)</b>
        </BoxTitle>

        <Box1Content>
          <SearchBar>
            <NaverLogo />
            브라우니 무인매장
            <SearchIcon />
          </SearchBar>
        </Box1Content>
      </Box>

      <Box>
        <NumSquare>
          <Num>2</Num>
        </NumSquare>

        <BoxTitle style={{ marginBottom: 16 }}>
          <b>회원가입 + 서비스 신청</b>
        </BoxTitle>
        <img
          src="/image/wooribank/service-confirm-example.webp"
          alt="회원가입 후 서비스 신청 예시 이미지"
          style={{ width: '100%', marginBottom: 20 }}
        />
      </Box>

      <Box>
        <NumSquare>
          <Num>3</Num>
        </NumSquare>

        <BoxTitle>
          <b>우리은행 사용하는 사장님인지 확인 후,</b>
        </BoxTitle>

        <Box3ContentBox>
          <img src="/image/wooribank/brwnie-kakao-plus-example.webp" alt="브라우니 카카오톡 플러스 친구" />

          <Box3ContentDescription>
            <b>브라우니 카카오톡 플러스친구</b>로 아래 정보를 전달해주세요!
            <br />
            <b>→ 아이디(핸드폰 번호), 매장명</b>
          </Box3ContentDescription>
        </Box3ContentBox>
      </Box>

      <Box4>
        <NumSquare style={{ top: 11 }}>
          <Num>4</Num>
        </NumSquare>

        <Box4ContentText>
          <b>
            <span
              style={{
                color: '#FCFF10',
              }}
            >
              무인매장 관리 무료 2회 추가 제공
            </span>
          </b>{' '}
        </Box4ContentText>
      </Box4>
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100vw;
  background-color: #d6e7fd;
`

const TSectionitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`

const SpeechBubble = styled.div`
  width: 144px;
  height: 28px;
  border-radius: 9999px;
  background-color: #fcff10;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SpeechBubbleContent = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -0.3px;
`

const SpeechBubbleTriangle = styled.span`
  position: absolute;
  bottom: -6px;
  left: 66px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #fcff10 transparent transparent transparent;
`

const Title = styled.span`
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -0.1px;
  margin-top: 12px;
`

const Box = styled.div`
  width: 308px;
  padding: 20px 24px 0;
  position: relative;
  border-radius: 14px;
  background-color: white;

  & + & {
    margin-top: 12px;
  }
`

const NumSquare = styled.div`
  border-radius: 10px;
  width: 36px;
  height: 36px;
  border: 2px solid #fff;
  background-color: #5363ff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -22px;
  top: 14px;
`

const Num = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.4px;
  color: white;
`

const BoxTitle = styled.span`
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.4px;

  & > b {
    font-weight: 700;
  }
`

const Box1Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 20px;
`

const SearchBar = styled.div`
  width: 100%;
  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;

  border-radius: 12px;
  border: 2px solid #5dcb6a;
  background: #fff;
`

const Box3ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 20px;
  background-color: #f1f5f9;
`

const Box3ContentDescription = styled.span`
  margin-top: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.4px;

  b {
    font-weight: 700;
  }
`

const Box4 = styled.div`
  width: 308px;
  height: 58px;
  position: relative;
  margin-top: 12px;
  padding: 14px 26px;
  border-radius: 14px;
  background-color: #5363ff;
  border: 1px solid #185aff;
  display: flex;
  align-items: center;
`

const Box4ContentText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.4px;
  color: white;

  b {
    font-weight: 700;
    font-size: 18px;
  }
`
