import React, { useState, memo, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { fadeIn } from 'assets/styles/animation'
import Switch from 'components/common/Toggle'
import Icon from 'components/Icon'
import { FilterButton, FilterStateType } from 'pages/MapPage/MapPage'
import { mapStoreData, MapStoreDataType } from 'utils/map/storeType'
import CustomCheckbox from 'components/common/CustomCheckbox'

export type CategoryInformType = {
  category: string
  enCategory: string
}

export type CategoryStatusType = {
  printCafe: boolean
  selfLaundry: boolean
  icecream: boolean
  studyCafe: boolean
  convenienceStore: boolean
  selfStudio: boolean
  cafe: boolean
  machineShop: boolean
  stationery: boolean
  ramen: boolean
  fruit: boolean
  pet: boolean
  mealKit: boolean
  etc: boolean
}

export const initialCategoryStatus: CategoryStatusType = {
  printCafe: true,
  selfLaundry: true,
  icecream: true,
  studyCafe: true,
  convenienceStore: true,
  selfStudio: true,
  cafe: true,
  machineShop: true,
  stationery: true,
  ramen: true,
  fruit: true,
  pet: true,
  mealKit: true,
  etc: true,
}

export type FilterViewProps = {
  filterOpen: boolean
  setOpen: (filterOpen: boolean) => void
  setFilterState: React.Dispatch<React.SetStateAction<FilterStateType>>
  filterState: FilterStateType
}

/**
 * @description 매장 필터 기능을 제공하는 모달형태의 페이지
 * @param filterOpen 핉터 열림 유무
 * @param setOpen 필터 열림 제어 함수
 * @param setFilterState 필터 상태 제어 함수
 * @param filterState 현재 필터 상태 정보 객체
 * @returns
 */
function FilterViewSection({ filterOpen, setOpen, setFilterState, filterState }: FilterViewProps) {
  const { brwnieState: brwnieFilter, ...storeTypeState } = filterState
  const [storeTypeFilter, setStoreTypeFilter] = useState<CategoryStatusType>(storeTypeState)
  const [brwnieYn, setBrwnieYn] = useState<boolean>(brwnieFilter)
  const [storeTypeWholeCheck, setStoreTypeWholeCheck] = useState<boolean>(
    Object.values(storeTypeState).every((item) => item === true)
  )

  const handleStoreTypeClick = (data: MapStoreDataType) => {
    setStoreTypeFilter((storeTypeFilter) => ({
      ...storeTypeFilter,
      [data.enCategory]: !storeTypeFilter[data.enCategory],
    }))
  }

  const handleFilterCheckClick = () => {
    //체크가 다 되어있으면
    if (storeTypeWholeCheck) {
      setStoreTypeFilter({
        printCafe: false,
        selfLaundry: false,
        icecream: false,
        studyCafe: false,
        convenienceStore: false,
        selfStudio: false,
        cafe: false,
        machineShop: false,
        stationery: false,
        ramen: false,
        fruit: false,
        pet: false,
        mealKit: false,
        etc: false,
      })
      setStoreTypeWholeCheck(false)
    } else {
      setStoreTypeFilter(initialCategoryStatus)
      setStoreTypeWholeCheck(true)
    }
  }

  const handleResetClick = () => {
    setBrwnieYn(false)
    setStoreTypeFilter(initialCategoryStatus)
  }

  const handleConfirmClick = () => {
    setFilterState({ ...storeTypeFilter, brwnieState: brwnieYn })
    setOpen(false)
  }

  const handleCloseClick = () => {
    if (brwnieYn !== brwnieFilter) {
      setBrwnieYn(brwnieFilter)
    }
    if (storeTypeFilter !== storeTypeState) {
      setStoreTypeFilter(storeTypeState)
    }
    setOpen(false)
  }

  useEffect(() => {
    const checkWholeFilter = Object.values(storeTypeFilter)
    if (checkWholeFilter.every((item) => item === true)) {
      setStoreTypeWholeCheck(true)
    } else if (checkWholeFilter.every((item) => item === false)) {
      setStoreTypeWholeCheck(false)
    } else {
      setStoreTypeWholeCheck(false)
    }
  }, [storeTypeFilter])

  return (
    <ContentWrapper visiable={filterOpen}>
      <div className="nav_container">
        <span>필터 적용하기</span>
        <FilterButton onClick={handleCloseClick} hover={false}>
          <div>닫기</div>
        </FilterButton>
      </div>
      <div className="brwnie_filter_container">
        <p>브라우니 이용매장만 보기</p>
        <Switch onChange={setBrwnieYn} defaultStatus={brwnieYn} />
      </div>
      <div className="store_filter_container">
        <div>
          <span>매장유형 선택</span>
          <div className="label_checkbox_container">
            <label htmlFor={`filter_check`}>
              <h5>전체선택</h5>
            </label>
            <CustomCheckbox>
              <input
                type="checkbox"
                id={'filter_check'}
                checked={storeTypeWholeCheck}
                onChange={handleFilterCheckClick}
              />
            </CustomCheckbox>
          </div>
        </div>

        <StoreTypeFilterUl>
          {mapStoreData.map((data, index) => (
            <StoreTypeFilterLi
              key={index}
              onClick={() => handleStoreTypeClick(data)}
              clickedStatus={storeTypeFilter[data.enCategory]}
            >
              {data.category}
            </StoreTypeFilterLi>
          ))}
        </StoreTypeFilterUl>
      </div>
      <div className="footer_container">
        <ResetButton onClick={handleResetClick}>
          <Icon name="mainRefresh" />
          초기화
        </ResetButton>
        <ConfirmButton onClick={handleConfirmClick}>적용</ConfirmButton>
      </div>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.section<{ visiable: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  width: 400px;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: 80px;
  right: 0px;
  flex-direction: column;

  z-index: 10000010 !important;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  ${({ visiable }) =>
    visiable &&
    css`
      display: flex;
      animation-name: ${fadeIn};
    `}

  .nav_container {
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      font-size: 24px;
      line-height: 42px;
    }
  }

  .brwnie_filter_container {
    padding: 20px 16px;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
    font-size: 16px;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
  }

  .store_filter_container {
    padding: 0px 16px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div {
        display: flex;
        align-items: center;

        label {
          & > h5 {
            font-size: 12px;
          }
          margin-right: 8px;
        }
      }

      & > span {
        padding: 20px 0px;
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }

  .footer_container {
    padding: 0px 16px;
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 0px;
    box-sizing: border-box;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 0px;
    height: 100%;
    width: 100vw;
  }
`

const StoreTypeFilterUl = styled.ul`
  width: 100%;
  height: 250px;
  display: grid;
  grid-template-columns: 126px 126px 126px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
`

const StoreTypeFilterLi = styled.li<{ clickedStatus: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  width: 116px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.main};
  border-radius: 8px;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.main};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ clickedStatus }) =>
    clickedStatus &&
    css`
      background-color: ${({ theme }) => theme.colors.main};
      color: ${({ theme }) => theme.colors.white};
    `}

  @media only screen and (max-width: 768px) {
    width: unset;
  }
`

const ResetButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  width: 120px;
  height: 56px;
  display: flex;
  color: ${({ theme }) => theme.colors.black};
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 28px;
  border: 1px solid ${({ theme }) => theme.colors.main};
  border-radius: 8px;
  cursor: pointer;

  svg {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }

  @media only screen and (max-width: 768px) {
    width: 35%;
    margin-right: 16px;
  }
`

const ConfirmButton = styled.button`
  background-color: ${({ theme }) => theme.colors.main};
  width: 240px;
  height: 56px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 70%;
  }
`

export default memo(FilterViewSection)
