import styled from 'styled-components'
import BeltSection from '../BeltSection'
import representationConsumer from 'data/representationConsumer.json'
import Slider from 'react-slick'
import { Button } from 'ohds-web'

const slideSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default function LandingRepresentiveCustomerSection() {
  return (
    <RepresentiveCustomerSectionWrapper>
      <TextWrapper>
        <h1>💌 실제 고객 후기</h1>
      </TextWrapper>
      <SliderWrapper>
        <Slider {...slideSettings}>
          {representationConsumer.map(({ writer, brandTypeMobileImage, description, title, href }, _i) => (
            <ExampleConsumerLi key={_i}>
              <div className="imageWrapper">
                <img className="backgroundImg" src={brandTypeMobileImage} alt="무인매장 브라우니" />
              </div>
              <div className="contentWrapper">
                <div className="textWrapper">
                  <h3>{title}</h3>
                  <p>{description}</p>
                </div>
                <div className="infoWrapper">
                  <span>{writer}</span>
                  <CustomButton
                    size="small"
                    label="더보기"
                    rightIconName="LineArrowRight"
                    onClick={() => window.open(href, '_blank')}
                  />
                </div>
              </div>
            </ExampleConsumerLi>
          ))}
        </Slider>
      </SliderWrapper>

      <ExampleConsumerUl>
        {representationConsumer.map(({ writer, brandTypePcImage, description, title, href }, _i) => (
          <ExampleConsumerLi key={_i}>
            <div className="imageWrapper">
              <img className="backgroundImg" src={brandTypePcImage} alt="무인매장 브라우니" />
            </div>
            <div className="contentWrapper">
              <div className="textWrapper">
                <h3>{title}</h3>
                <p>{description}</p>
              </div>
              <div className="infoWrapper">
                <span>{writer}</span>
                <CustomButton
                  size="large"
                  label="더보기"
                  rightIconName="LineArrowRight"
                  onClick={() => window.open(href, '_blank')}
                />
              </div>
            </div>
          </ExampleConsumerLi>
        ))}
      </ExampleConsumerUl>
      {/* <BeltSection /> */}
    </RepresentiveCustomerSectionWrapper>
  )
}

const RepresentiveCustomerSectionWrapper = styled.section`
  width: 100%;
  height: 1250px;
  position: relative;
  background-color: var(--gray50);

  @media only screen and (max-width: 768px) {
    height: 640px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;

  & > h1 {
    font-weight: 700;
    font-size: 52px;
    line-height: 65px;
    color: var(--gray800);
    margin-bottom: 20px;
  }

  & > h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--gray600);
  }

  @media only screen and (max-width: 768px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 28px;
    display: flex;

    & > h1 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 4px;
    }

    & > h3 {
      width: 240px;
      display: flex;
      font-size: 14px;
      line-height: 20px;
      word-break: keep-all;
      text-align: center;
    }
  }
`

const SliderWrapper = styled.div`
  display: none;
  width: 100%;
  height: 342px;
  margin: 0 auto;

  .slick-dots {
    position: relative;
    bottom: 0px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .slick-dots > li {
    position: relative;
    display: inline-block;
    background-color: var(--gray500);
    opacity: 0.5;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin: 0px 4px;

    & > button::before {
      all: unset;
    }
  }

  .slick-dots .slick-active {
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    background: var(--gray0);
    border: 2px solid var(--gray600);
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const ExampleConsumerUl = styled.ul`
  width: 1030px;
  margin: 0 auto;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 33.333%);
  column-gap: 23px;
  row-gap: 32px;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ExampleConsumerLi = styled.li`
  width: 100%;
  border-radius: 12px;
  position: relative;
  transition: background-color ease-out 0.1s, box-shadow ease-out 0.1s;
  background-color: var(--gray50);

  .imageWrapper {
    width: inherit;
    position: relative;

    .backgroundImg {
      width: inherit;
      border-radius: 12px;
    }

    .logoImg {
      z-index: 3;
      position: absolute;
      right: 16px;
      bottom: 16px;
    }
  }

  .contentWrapper {
    width: 100%;
    min-height: 130px;
    padding: 0px 24px;
    margin-top: 12px;
    padding-bottom: 24px;

    .textWrapper {
      min-height: 90px;
      margin-top: 8px;
      margin-bottom: 10px;

      & > h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        color: var(--gray800);
      }

      & > img {
        height: 28px;
      }

      & > p {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--gray600);
        white-space: pre-wrap;
      }
    }

    .tagWrapper {
      width: 100%;
      display: flex;

      .tag {
        padding: 4px 10px;
        border-radius: 999px;
        background: var(--blue-blue-100, #cce5ff);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        & > span {
          color: var(--blue-blue-500, #0063cc);
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .infoWrapper {
      width: 100;
      min-height: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        min-width: max-content;
        color: var(--gray-gray-600, #757575);
        font-style: normal;
      }

      & > button {
        display: flex;
        padding: 6px 12px 6px 16px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;

        background: var(--gray50);
        border: 1px solid var(--gray200);
        color: var(--gray600);
        font-size: 14px;
        font-weight: 500;

        & > svg {
          & > path {
            stroke: var(--gray600);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    height: max-content;
    padding: 0 16px;
    background-color: var(--gray50);

    .imageWrapper {
      .backgroundImg {
        width: inherit;
        object-fit: cover;
      }
    }

    .textWrapper {
      & > h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        color: var(--gray800);
      }

      & > p {
        font-size: 14px;
        line-height: 24px;
        word-break: keep-all;
      }
    }

    .contentWrapper {
      height: auto;
      min-height: max-content;
      padding: 0px;
      margin-bottom: 16px;
    }

    .infoWrapper button {
      height: 44px;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between;
    }
  }

  @media only screen and (min-width: 768px) {
    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
      background-color: var(--gray0);
    }
  }
`

const CustomButton = styled(Button)`
  width: fit-content;
  height: 40px !important;

  color: var(--gray600);
  font-size: 14px;
  font-weight: 500;

  display: flex !important;
  align-items: center;
  transition: background-color 0.1s ease-in-out;

  & > svg {
    & > path {
      stroke: var(--gray600);
    }
  }

  ${({ theme }) => theme.media.mobile`
    height: 36px !important;
  `}
`
