import client from '../client'
import { BVSType } from './types'

export async function getBvs({ id }: GetBvsParam) {
  const response = await client.get<BVSType>(`/service-api/v1/bvs/${id}`)

  return response.data
}

export type GetBvsParam = {
  id: number
}
