import { Top } from 'ohds-web'

import { useAppSelector } from 'hooks/useReduxHook'

import FirstVisitCalendar from 'components/ServicePostPage/FirstViistPage/FirstVisitCalendar'
import BrwnieMainContainer from 'components/common/BrwnieMainContainer'
import FirstVisitBottomCTA from 'components/ServicePostPage/FirstViistPage/FirstVIsitBottomCTA'

export default function FirstVisitPage() {
  const { schedules } = useAppSelector((state) => state.service)
  const firstSchedule = schedules ? schedules[0] : undefined

  return (
    <BrwnieMainContainer>
      <Top
        firstTitle="관리 시작일"
        firstTitle2="을 선택해 주세요"
        titleFont={{
          fontSize: 'h3',
        }}
        paddingTop={40}
        firstTitleBrand
        description="오늘 기준 3일 후부터 선택이 가능해요"
        descriptionFont={{
          fontSize: 'c2',
        }}
      />

      <FirstVisitCalendar />

      {firstSchedule && (
        <FirstVisitBottomCTA linear label={`${firstSchedule.month}월 ${firstSchedule.date}일부터 서비스 받기`} />
      )}
    </BrwnieMainContainer>
  )
}
