import styled from 'styled-components'
import Icon from '../../Icon'

export default function AuthErrorInfo() {
  return (
    <StyledAuthErrorInfo>
      <Icon name="emojiRobot" />
      <Info>
        문제가 계속 발생하면&nbsp;
        <a href="https://brwnie.channel.io/" target="_blank" rel="noreferrer">
          고객센터
        </a>
        로 문의해 주세요.
      </Info>
    </StyledAuthErrorInfo>
  )
}

const StyledAuthErrorInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(6)};
  ${({ theme }) => theme.media.mobile`padding-top: ${theme.spacing(5)}`}
`

const Info = styled.span`
  margin-top: ${({ theme }) => theme.spacing(3)};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  word-break: keep-all;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 0.1rem solid ${({ theme }) => `${theme.colors.black}`};
  }
`
