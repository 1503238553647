import { getContractedDate, GetContractedDateParam } from 'lib/api/contractedDate/getContractedDate'
import { QueryOptionsOf } from 'lib/utils/types'
import { useQuery } from 'react-query'

export default function useContractedDateQuery(
  value: GetContractedDateParam,
  options: QueryOptionsOf<typeof getContractedDate> = {}
) {
  return useQuery(createKey(value.year, value.month), () => getContractedDate(value), options)
}

const createKey = (year: number, month: number) => ['contracted_date', year, month]
useContractedDateQuery.createKey = createKey
