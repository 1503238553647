import React, { useEffect } from 'react'
import SocialForm from './SocialForm'
import styled from 'styled-components'
import EmailForm from './EmailForm'
import useAuthQuery from 'hooks/query/auth/useAuthQuery'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'utils/common/routes'
import SamsungAlert from 'components/SamsungAlert'

export default function LoginForm() {
  const history = useHistory()
  const { data: auth } = useAuthQuery(
    {},
    {
      retry: 0,
      refetchOnWindowFocus: true,
    }
  )
  useEffect(() => {
    if (!!(auth && auth.opt_check)) history.push(ROUTES.STORE)
    else if (!!(auth && !auth.opt_check)) {
      history.push(ROUTES.AUTH_SOCIAL_OPTION)
    }
  })

  // 유저 정보가 있고 옵션 체크가 되어있다면 홈으로 이동, 유저 정보는 있지만 옵션 체크가 되어있지 않으면 소셜 옵션페이지로

  return (
    <>
      <LoginWrapper>
        <SocialForm label={'SNS 간편 로그인 / 회원가입'} />
        <Divider />
        <EmailForm />
      </LoginWrapper>

      <SamsungAlert />
    </>
  )
}

const LoginWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(6)};
`

const Divider = styled.hr`
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => `${theme.colors.lightGrayishBlue}`};
  border: none;
  margin: ${({ theme }) => theme.spacing(6, 0)};
`
