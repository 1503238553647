import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import LandingNavBar from 'components/Landing/LandingNavBar'
import Footer from 'components/common/Footer'
import SnackBar from 'components/Landing/SnackBar'
import FloatingApplyButton from 'components/FloatingApplyButton'
import EventBanner from 'components/EventBanner'

import { useScrollGet } from 'hooks/useScrollGet'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import { closeSnackBar, getSnackBarStatus } from 'modules/features/snackBarStatus/snackBarStatusSlice'

import ServiceApplyTriggerSection from 'components/Landing/ServiceApplyTriggerSection'
import LandingCoworkSection from 'components/Landing/LandingCoworkSection'
import LandingStoreTypeSelectSection from 'components/Landing/LandingStoreTypeSelectSection'
import LandingImageSliderSection from 'components/Landing/LandingImageSlicerSection'
import LandingMapSection from 'components/Landing/LandingMapSection'
import LandingNewsSection from 'components/Landing/LandingNewsSection'
import LandingBrwnieStartSection from 'components/Landing/LandingBrwnieStartSection'
import LandingWorkeydokeySection from 'components/Landing/LandingWorkeydokeySection'
import LandingServiceFeeSection from 'components/Landing/LandingServiceFeeSection'
import LandingRepresentiveCustomerSection from 'components/Landing/LandingRepresentiveCustomerSection'
import AceCrewInterviewSection from 'components/Landing/LandingAceCrewInterviewSection'
import LandingQuestionListSection from '../../components/Landing/LandingQuestionListSection/LandingQuestionListSection'
import LandingBadgeSection from 'components/Landing/LandingBadgeSection'
import LandingCountSection from 'components/Landing/LandingCountSection'
import LandingAirConCareServiceSection from 'components/Landing/LandingAirConCareServiceSection/LandingAirConCareServiceSection'
import { LoadingPartnerServiceSection } from '../../components/Landing/LoadingPartnerServiceSection'
import LandingMuinStore from 'components/Landing/LandingMuinStore'
import LandingBrwnieCSService from 'components/Landing/LandingBrwnieCSService'
import LandingAlbaGoYoutube from 'components/Landing/LandingAlbaGoYoutube'
import LandingBrwnieIntroduce from 'components/Landing/LandingBrwnieIntroduce'

export default function LandingPage() {
  const [offsetHeight, setOffsetHeight] = useState<number>(0)
  const mainRef = useRef<HTMLDivElement>(null)

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { totalOffsetY, scrollPosition } = useScrollGet({})
  const snackBarStatus = useAppSelector(getSnackBarStatus)

  const scrollSave = useCallback(() => {
    return localStorage.setItem(`scrollData`, `${scrollPosition}`)
  }, [scrollPosition])

  useEffect(() => {
    return history.listen(() => {
      scrollSave()
    })
  }, [history, scrollSave])

  useEffect(() => {
    if (mainRef.current) {
      setOffsetHeight(mainRef.current.offsetHeight)
      mainRef.current.scrollTo(0, parseInt(localStorage.getItem('scrollData') || `0`, 0))
      localStorage.removeItem(`scrollData`)
    }
  }, [])

  useEffect(() => {
    if (!snackBarStatus?.isOpen) return

    const timerId = setTimeout(() => {
      dispatch(closeSnackBar())
    }, 4000)

    return () => {
      clearTimeout(timerId)
      dispatch(closeSnackBar())
    }
  }, [snackBarStatus, dispatch])

  return (
    <>
      <Helmet>
        <title>브라우니 - 무인매장 무인점포 관리 서비스</title>
      </Helmet>
      <StyledWrapper ref={mainRef} id="landingWrapper">
        <EventBanner />
        <LandingNavBar offsetY={totalOffsetY ? 1 : 0} landingRef={mainRef} changeColor />
        <SectionWrapper>
          <LandingImageSliderSection />
          <LandingStoreTypeSelectSection />
          <LandingCountSection />
          <LandingCoworkSection />
          <LandingServiceFeeSection />
          <LandingAlbaGoYoutube />
          <LandingMapSection />
          <LandingRepresentiveCustomerSection />
          <LandingBrwnieCSService />

          <LandingMuinStore />
          <LoadingPartnerServiceSection />

          {/* <LandingQuestionListSection /> */}
          {/* 운영상 임시 주석처리 (추후 풀 예정입니다)
          <MobileEventSection /> */}
          {/* <LandingBadgeSection /> */}
          {/* <LandingAirConCareServiceSection /> */}

          <LandingBrwnieStartSection />
          <AceCrewInterviewSection />

          {/* <LandingWorkeydokeySection /> */}
          <LandingBrwnieIntroduce />
          <LandingNewsSection />
          <ServiceApplyTriggerSection />
          <Footer />
        </SectionWrapper>
        <FloatingApplyButton scrollPosition={scrollPosition} offsetHeight={offsetHeight} />
        <SnackBar
          open={snackBarStatus.isOpen}
          onClose={() => dispatch(closeSnackBar())}
          innerText={snackBarStatus.message}
        />
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  position: relative;

  @media only screen and (max-width: 768px) {
    height: 100%;
  }
`

const SectionWrapper = styled.div`
  width: 100%;
`
