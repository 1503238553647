import { PaymentMethodType, paymentMethodArr } from 'lib/paymentMethod'
import styled, { css } from 'styled-components'

type PaymentMethodListProp = {
  paymentMethod: PaymentMethodType | null
  onListItemClick: (paymentMethod: PaymentMethodType | null) => void
}

export default function PaymentMethodList({ paymentMethod, onListItemClick }: PaymentMethodListProp) {
  return (
    <Container>
      {paymentMethodArr.map((method, index) => (
        <PaymentMethodListItem
          key={index}
          isClicked={paymentMethod === method}
          onClick={() => (paymentMethod === method ? onListItemClick(null) : onListItemClick(method))}
        >
          <span>
            <span />
          </span>
          <p>{method === 'BANK' ? '계좌이체' : method === 'VBANK' ? '무통장입금' : '카드결제'}</p>
        </PaymentMethodListItem>
      ))}
    </Container>
  )
}

const Container = styled.ul`
  width: 264px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  & > li ~ li {
    margin-top: 8px;
  }
`

const PaymentMethodListItem = styled.li<{ isClicked: boolean }>`
  width: 264px;
  height: 56px;
  border: 1px solid;
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding-left: 18px;

  & > span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid;
  }

  & > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.black};
    margin-left: 10px;
  }

  ${({ isClicked, theme }) =>
    isClicked
      ? css`
          border-color: ${theme.colors.main};

          & > span {
            border-color: ${theme.colors.main};
            display: flex;
            align-items: center;
            justify-content: center;

            & > span {
              display: block;
              width: 8px;
              height: 8px;
              background-color: ${theme.colors.main};
              border-radius: 50%;
            }
          }
        `
      : css`
          border-color: ${theme.colors.lightGrayishBlue};

          & > span {
            border-color: ${theme.colors.gray};
          }
        `}
`
