import client from '../client'
import { StoreType } from './types'

export async function postStore(req: PostStoreReq) {
  const response = await client.post<StoreType>(`/service-api/v1/store`, req)

  return response.data
}

export type PostStoreReq = {
  area: string
  big_machine_kind: string[]
  address_name: string
  detail_address: string
  sido: string
  sigungu: string
  store_name: string
  type: string
}
