import client from '../client'

export async function postBilling(req: PostBillingReq) {
  const response = await client.post(`/payment-api/v1/payment/kg-inicis/billing`, req)
  return response.data
}

export type PostBillingReq = {
  order_id: string // 주문 아이디
  price: number // 요청할 가격(쿠폰, 포인트를 제한)
  name: string // 구매자 이름
  phone: string // 구매자 전화번호 (010-xxxx-xxxx or 010xxxxxxxx)
  service_id: string // 구매할 서비스
  mail?: string // 구매자 이메일
  point?: number // 사용한 포인트
  coupon_id?: number // 사용한 쿠폰
}
