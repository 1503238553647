import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import serviceTypeData from 'data/serviceTypeData.json'

type DetailType = {
  src: string
  title: string
  detailList?: string[]
}
export default function ServiceDetailSecondSection() {
  const { type } = useParams<{ type: string }>()

  const typeData = serviceTypeData.find(({ param }) => param === type)

  const isSmartMachine = typeData?.type.includes('무인자판기')

  return (
    <SecondSectionWrapper>
      <ContentWrapper>
        <h4>맞춤 서비스</h4>
        <h2>
          꼭 필요했던
          <br />
          {`${
            typeData?.type === '아이스크림 할인점'
              ? '아·할 매장'
              : typeData?.type.includes('자판기')
              ? '무인 점포'
              : typeData?.type
          } 맞춤관리`}{' '}
        </h2>
        <h3>
          매장관리 필수앱 <b>워키도키</b>에 수집된 빅데이터 기반으로
          <br />
          무인 업종별 맞춤 서비스를 제공합니다.
        </h3>
        <VideoUl>
          {typeData?.detail.map(({ src, title, detailList }: DetailType) => (
            <VideoLi key={title}>
              <img className="videoWrapper" src={src} alt={'전용 서비스'} />

              <div className="textWrapper">
                <div className="titleWrapper">
                  <span>{title}</span>
                </div>

                <ul className="descriptionWrapper">
                  {detailList && detailList.map((detail: string, index: number) => <li key={index}>{detail}</li>)}
                </ul>
              </div>
            </VideoLi>
          ))}
        </VideoUl>
      </ContentWrapper>
    </SecondSectionWrapper>
  )
}

const SecondSectionWrapper = styled.section`
  width: 100%;
  height: 1076px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: 1550px;
    justify-content: center;
  }
`

const ContentWrapper = styled.div`
  width: 1030px;
  height: 716px;
  margin: 0 auto;
  position: relative;

  h4 {
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.indigoA700};
  }

  h2 {
    position: absolute;
    top: 44px;
    left: 0px;
    font-weight: 700;
    font-size: 64px;
    line-height: 90px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
  }

  h3 {
    position: absolute;
    top: 264px;
    left: 0px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.gray500};

    b {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  @media only screen and (max-width: 768px) {
    width: 360px;
    height: inherit;
    margin: 0px 16px;

    h2 {
      position: absolute;
      top: 108px;
      left: 0px;
      font-size: 30px;
      line-height: 40px;
    }

    h3 {
      position: absolute;
      top: 220px;
      left: 0px;
      font-size: 14px;
      line-height: 20px;
    }

    h4 {
      position: absolute;
      top: 80px;
      left: 0px;
      font-size: 14px;
      line-height: 24px;
    }
  }
`
const VideoUl = styled.ul`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 316px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: fit-content;
    bottom: 50px;
  }
`

const VideoLi = styled.li`
  width: 330px;
  height: fit-content;
  border-radius: 24px;
  display: grid;
  border: 1px solid var(--gray-gray-300, #dbdbdb);

  .videoWrapper {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 24px 24px 0px 0px;
  }

  .textWrapper {
    padding: 18px 24px;
    .titleWrapper {
      position: relative;
      width: fit-content;
      color: var(--gray-gray-800, #212121);
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 12px;
      span {
        box-shadow: inset 0 -10px 0 #cdf;
      }
    }

    .descriptionWrapper {
      color: var(--gray-gray-600, #757575);
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      list-style-type: disc;
      padding-inline-start: 2rem;
      & > li::marker {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: min-content;
    margin-bottom: 50px;

    .videoWrapper {
      object-fit: cover;
      height: 192px;
    }
  }
`
