// https://github.com/fraserxu/react-dropdown
import Dropdown from 'react-dropdown'
import './style.css' // 커스터마이징을 위해 따로 추가함

interface Option {
  label: React.ReactNode
  value: string
  className?: string
}
interface Group {
  type: 'group'
  name: string
  items: Option[]
}

export type DropdownProp = {
  options: (Group | Option | string)[]
  className?: string
  placeholder?: string
  value?: Option | string 
  onChange?: (option: Option) => void
}

export default function Select({ className, options, onChange, placeholder, value }: DropdownProp) {
  return <Dropdown className={className} options={options} onChange={onChange} value={value} placeholder={placeholder} />
}