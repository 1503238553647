import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import useGetFindEmail from 'hooks/query/auth/useGetFindEmail'
import { useState } from 'react'
import Icon from 'components/Icon'

interface IFormInput {
  phone: string
}

export default function EmailFindForm() {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<IFormInput>({ mode: 'onChange' })
  const [focusInput, setFocusInput] = useState(false)
  const { mutateAsync: mutateGetFindEmail, error, isError } = useGetFindEmail()
  let errorMessage = error as any

  const onSubmit = async (data: any) => {
    setFocusInput(false)
    await mutateGetFindEmail({ phone: data.phone })
  }
  return (
    <StyledEmailFindForm>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <FormInput
            placeholder="휴대폰 번호 (숫자만 입력)"
            type="tel"
            {...register('phone', {
              required: true,
              minLength: 10,
              pattern: /^[0-9]+$/g,
            })}
            onFocus={() => setFocusInput(true)}
          />
          <span>{isError && !focusInput && <WarningIcon name="redError" />}</span>
        </Wrapper>
        {isError && errorMessage.response.status === 405 && !focusInput && (
          <WarningText>{errorMessage.response.data.message}</WarningText>
        )}

        <LoginButton type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid} value={'찾기'} />
      </StyledForm>
    </StyledEmailFindForm>
  )
}

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`
const WarningIcon = styled(Icon)`
  margin-right: 1rem;
  position: absolute;
  top: 9%;
  right: 0.5rem;
`

const StyledEmailFindForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.media.mobile`
    padding-top: ${theme.spacing(5)}
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 1.1rem 1.6rem;
  width: 100%;
  border-radius: 0.8rem;
  border: solid 0.1rem #b1b1b5;

  span {
    flex: 0 0 auto;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  :focus-within {
    ime-mode: disabled;
    -webkit-ime-mode: disabled;
    -moz-ime-mode: disabled;
    -ms-ime-mode: disabled;
    border: solid 0.1rem #003399;
  }
`

const FormInput = styled.input<{ fill?: string; placeholder?: string }>`
  border: 0;
  width: 100%;
  margin: 0 auto 0;
  padding: 0;
  line-height: 2.4rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};

  ime-mode: disabled;
  -webkit-ime-mode: disabled;
  -moz-ime-mode: disabled;
  -ms-ime-mode: disabled;

  &:focus {
    ime-mode: disabled;
    -webkit-ime-mode: disabled;
    -moz-ime-mode: disabled;
    -ms-ime-mode: disabled;
  }

  ${({ fill }) =>
    fill === 'true' &&
    `
  border: solid 0.1rem #003399;
  `}
  ::placeholder {
    opacity: 0.3;
  }
`

const WarningText = styled.div`
  margin-top: 0.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => `${theme.colors.warning}`};
`

const LoginButton = styled.input<{ disabled?: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 4.8rem;
  border: none;
  border-radius: 0.8rem;
  margin-top: 2.4rem;
  -webkit-appearance: none;
  background-color: ${({ theme }) => `${theme.colors.main}`};
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => `${theme.colors.white}`};

  ${({ disabled }) =>
    disabled === true &&
    `
    background-color: #edeff1;
    color: #1e1e2c;
    opacity: 0.5;
    cursor: no-drop;

  `}

  ${({ theme }) => theme.media.mobile`
    margin-top: 1.6rem;
  `}
`
