import { useEffect, useState } from 'react'
import { Top } from 'ohds-web'
import BrwnieMainContainer from 'components/common/BrwnieMainContainer'
import useQueryParamHook from 'hooks/useQueryParamHook'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import {
  selectInvalidTimeSchedules,
  ScheduleType,
  setInvalidTimeFixSchedules,
} from 'modules/features/service/serviceSlice'

import InvalidTimeCalendar from 'components/ServicePostPage/InvalidTimePage/InvalidTimeCalendar'
import InvalidTimeBottomCTA from 'components/ServicePostPage/InvalidTimePage/InvalidTimeBottomCTA'
import ActiveDateInfoButton from 'components/ServicePostPage/ActiveDateInfoButton'

export default function InvalidTimePage() {
  const query = useQueryParamHook()
  const invalidIndex = Number(query.get('index')) ?? 0

  const dispatch = useAppDispatch()
  const { invalidTimeFixSchedules } = useAppSelector((state) => state.service)

  const invalidTimeSchedule = useAppSelector(selectInvalidTimeSchedules)

  const { month: invalidMonth, date: invalidDate } = invalidTimeSchedule[invalidIndex]

  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleType>()

  useEffect(() => {
    if (invalidTimeFixSchedules.length - 1 >= invalidIndex) {
      const copiedSchedules = [...invalidTimeFixSchedules]
      copiedSchedules.splice(invalidIndex, 1)
      dispatch(setInvalidTimeFixSchedules(copiedSchedules))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invalidIndex])

  const initSelect = () => {
    setSelectedSchedule(undefined)
  }

  return (
    <BrwnieMainContainer>
      <Top
        firstTitle={`${invalidMonth}월 ${invalidDate}일의 `}
        firstTitle2="변경일을 선택"
        firstTitle3="해 주세요"
        firstTitle2Brand
        titleFont={{
          fontSize: 'h3',
        }}
      />
      <ActiveDateInfoButton />

      <InvalidTimeCalendar
        selectedSchedule={selectedSchedule}
        setSelectedSchedule={(schedule) => setSelectedSchedule(schedule)}
      />

      {selectedSchedule && (
        <InvalidTimeBottomCTA
          linear
          label={`${selectedSchedule.month}월 ${selectedSchedule.date}일 ${selectedSchedule.hour}시로 변경할게요`}
          selectedSchedule={selectedSchedule}
          onMoveToAnotherInvalidTime={initSelect}
        />
      )}
    </BrwnieMainContainer>
  )
}
