import client from '../client'
import { FindEmailRes } from './types'

export async function getFindEmail({ phone }: FindEmailParams) {
  const response = await client.get<FindEmailRes>(`auth-api/v1/auth/find/email/${phone}`)

  return response.data
}

export type FindEmailParams = {
  phone: string
}
