import { useState } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import useVisitHourBottomSheet from 'hooks/service/useVisitHourBottomSheet'

import {
  selectServiceWeeklyCount,
  setSchedules,
  SchedulesType,
  selectCloseTime,
  ScheduleType,
} from 'modules/features/service/serviceSlice'

import CalendarForm from 'components/ServicePostPage/CalendarForm'

import { getNow } from 'utils/common/calendar'
import { ListRow, TypoP1 } from 'ohds-web'

const { presentYear, presentMonth } = getNow()

export type DatesType = {
  date: number
}[]

export default function FirstVisitCalendar() {
  const dispatch = useAppDispatch()
  const { serviceWeek, serviceDayValues, serviceHour } = useAppSelector((state) => state.service)

  const serviceWeeklyCount = useAppSelector(selectServiceWeeklyCount)
  const closeTimeSchedules = useAppSelector(selectCloseTime)

  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleType>()

  const [openVisitHourOptions] = useVisitHourBottomSheet()

  const createFirstWeekDates = (selectedDate: number, selectedDay: number) => {
    const firstWeekDates: DatesType = []

    for (let i = 0; i < serviceWeeklyCount; i++) {
      const day = serviceDayValues[i]
      const isBeforeFirstDay = selectedDay > day

      if (!isBeforeFirstDay) {
        firstWeekDates.push({
          date: selectedDate + (day - selectedDay),
        })
      }
    }

    return firstWeekDates
  }

  const createMiddleWeekDates = (selectedDate: number, selectedDay: number, weekIndex: number) => {
    const middleWeekDates: DatesType = []

    for (let i = 0; i < serviceWeeklyCount; i++) {
      const day = serviceDayValues[i]

      middleWeekDates.push({
        date: selectedDate + 7 * weekIndex + day - selectedDay,
      })
    }

    return middleWeekDates
  }

  const createLastWeekDates = (selectedDate: number, selectedDay: number, serviceWeekDisplayOnCalendar: number) => {
    const lastWeekDates: DatesType = []

    for (let i = 0; i < serviceWeeklyCount; i++) {
      const day = serviceDayValues[i]
      const isBeforeFirstDay = selectedDay > day

      if (isBeforeFirstDay) {
        lastWeekDates.push({
          date: selectedDate + 7 * (serviceWeekDisplayOnCalendar - 1) + day - selectedDay,
        })
      }
    }

    return lastWeekDates
  }

  const createAllWeekDates = (selectedDate: number, selectedDay: number) => {
    const dates: DatesType = []
    if (serviceWeek === undefined) return []

    dates.push(...createFirstWeekDates(selectedDate, selectedDay))

    for (let weekIndex = 1; weekIndex < serviceWeek; weekIndex++) {
      dates.push(...createMiddleWeekDates(selectedDate, selectedDay, weekIndex))
    }

    const isSelectFirstServiceDay = serviceDayValues[0] === selectedDay

    if (!isSelectFirstServiceDay) {
      const serviceWeekDisplayOnCalendar = isSelectFirstServiceDay ? serviceWeek : serviceWeek + 1

      dates.push(...createLastWeekDates(selectedDate, selectedDay, serviceWeekDisplayOnCalendar))
    }

    return dates
  }

  const createSchedules = (year: number, month: number, date: number, day: number) => {
    const dates = createAllWeekDates(date, day)

    const mySchedules = dates.map(({ date }) => {
      const degree = date - dates[0].date
      const firstDate = new Date(year, month - 1, dates[0].date)
      const currentDate = new Date(firstDate.setDate(firstDate.getDate() + degree))
      return {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
        date: currentDate.getDate(),
        hour: serviceHour,
      }
    })

    return mySchedules as SchedulesType
  }

  const handleDateButtonClick = async (year: number, month: number, date: number, day: number) => {
    const schedule = createSchedules(year, month, date, day)

    const isSelectCloseTime = !!closeTimeSchedules.find(
      (closeTime) =>
        closeTime.year === year &&
        closeTime.month === month &&
        closeTime.date === date &&
        closeTime.hour === serviceHour
    )

    if (isSelectCloseTime) {
      const selectedVisitHour = await openVisitHourOptions({
        title: `${month}월 ${date}일 ${serviceHour}시는 마감되었어요 시작일의 시간만 바꿔드릴게요`,
        year,
        month,
        date,
        OtherOption: (
          <ListRow UpTypo={<TypoP1 text="다른 날로 변경할래요" />} rightIconName="LineArrowRight" onClick={() => {}} />
        ),
      })

      if (selectedVisitHour !== undefined) {
        schedule[0].hour = selectedVisitHour
      }
    }

    setSelectedSchedule(schedule[0])
    dispatch(setSchedules(schedule))
  }

  return (
    <CalendarForm
      type="firstVisit"
      firstYear={presentYear}
      firstMonth={presentMonth}
      newSchedule={selectedSchedule}
      onDateClick={handleDateButtonClick}
    />
  )
}
