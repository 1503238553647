import { GTMEvents } from "./GTM/type";
import { KakaoEvents } from "./Kakao/type";
import { NaverADEvent } from "./NaverAD/type";

export enum LOGGER {
  KAKAO = 'KAKAO',
  NAVER_AD = 'NAVER_AD',
  GTM = 'GTM'
}

export interface LogEvent extends GTMEvents, KakaoEvents, NaverADEvent {
  // 추후 공통으로 들어가는 이벤트가 있다면 여기에 추가
}

export type LogName = keyof LogEvent
export type LogPayload<T extends LogName = LogName> = LogEvent[T]