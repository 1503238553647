import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import styled from 'styled-components'
import Icon from '../../Icon'

interface SocialFormProps {
  label: string
}

export default function SocialForm({ label }: SocialFormProps) {
  const onClickHandlerLoginNaver = async () => {
    window.open(`${process.env.REACT_APP_BASE_URL}auth-api/v1/auth/login/naver`, '_blank')
    LogManager.Instance.sendLog('login', {
      method: 'naver'
    }, LOGGER.GTM)
  }

  const onClickHandlerLoginKakao = async () => {
    window.open(`${process.env.REACT_APP_BASE_URL}auth-api/v1/auth/login/kakao`, '_blank')
    LogManager.Instance.sendLog('login', {
      method: 'kakao'
    }, LOGGER.GTM)
  }

  return (
    <SocialWrapper>
      <SocialInfo>{label}</SocialInfo>
      <SocialLoginButton>
        <div onClick={onClickHandlerLoginNaver}>
          <SocialIcon name="NaverButton" />
        </div>
        <div onClick={onClickHandlerLoginKakao}>
          <SocialIcon name="KakaoButton" />
        </div>
      </SocialLoginButton>
    </SocialWrapper>
  )
}

const SocialWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SocialInfo = styled.p`
  text-align: left;
  color: ${({ theme }) => `${theme.colors.black}`};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-bottom: 1.6rem;
`

const SocialLoginButton = styled.div`
  display: flex;
  justify-content: space-between;
`

const SocialIcon = styled(Icon)`
  cursor: pointer;
`
