import styled from 'styled-components'

export type CheckboxProps = {
  children: React.ReactNode
  isSunday: boolean
} & React.LabelHTMLAttributes<HTMLLabelElement>
export const Checkbox = ({ children, isSunday, ...props }: CheckboxProps) => {
  return (
    <StyledCheckBox {...props} $isSunday={isSunday}>
      {children}
      <span />
    </StyledCheckBox>
  )
}

const StyledCheckBox = styled.label<{ $isSunday: boolean }>`
  display: flex;
  cursor: pointer;

  order: ${({ $isSunday }) => $isSunday && 1};

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + div {
      color: #fff;
      background: #039;
      box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
    }
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    border-radius: 8px;
    background: #fff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: 0, 0, 0, 0.88px;
  }
`
