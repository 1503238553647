import styled from 'styled-components'

export type DiscountInfoProps = {
  remainText: string
}

export default function DiscountInfo({ remainText }: DiscountInfoProps) {
  return (
    <StyledDiscountInfo>
      <span>{remainText}</span>
      {/* 추후 개발 예정
      <p>
        30일 이내 소멸 예정 쿠폰 : <strong>2 개</strong>
      </p> */}
    </StyledDiscountInfo>
  )
}

const StyledDiscountInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  span {
    font-size: 36px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.main};
  }

  // 추후 개발 예정
  /* p {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray};
    line-height: 24px;

    strong {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.black};
    }
  } */

  // mobile
  ${({ theme }) => theme.media.mobile`
    height: 130px;
  `}
`
