import React from 'react'
import Icon from 'components/Icon'
import styled, { css } from 'styled-components'
import { FaqDescription } from './FaqDescription'

/**
 * @description 질문 박스 컴포넌트
 * @param {number} questionOrder 한 페이지 당, 질문 순서
 * @param {boolean[]} isOpen 답변 박스 열림 상태 배열
 * @param {React.Dispatch<React.SetStateAction<boolean[]>>} setIsOpen 열림 상태 set함수
 * @param {object} questionTitle 질문 제목 및 전체 순서
 */
export type QuestionBoxProps = {
  questionOrder: number
  isOpen: boolean[]
  setIsOpen: React.Dispatch<React.SetStateAction<boolean[]>>
  questionTitle: {
    title: string
    order: number
  }
}

export default function QuestionBox({ questionOrder, isOpen, setIsOpen, questionTitle }: QuestionBoxProps) {
  return (
    <QuestionBoxContainer
      onClick={() => setIsOpen(isOpen.map((item, index) => (index === questionOrder ? !item : item)))}
    >
      {/*모바일*/}
      <MobileWrapper>
        <Title>
          <SubTitle>
            <div>Q. </div>
            {isOpen[questionOrder] ? <Icon name="blackArrowUp" /> : <Icon name="blackArrowDown" />}
          </SubTitle>
        </Title>
        <MobileSubTitle>{questionTitle.title}</MobileSubTitle>
      </MobileWrapper>

      {/*Pc*/}
      <PcWrapper>
        <Title>
          <SubTitle longSubTitleYn={questionTitle.title.length >= 49}>
            <div>Q. </div>
          </SubTitle>
          <SubTitlePc>{questionTitle.title}</SubTitlePc>
          {isOpen[questionOrder] ? <Icon name="blackArrowUp" /> : <Icon name="blackArrowDown" />}
        </Title>
      </PcWrapper>

      <Policy isOpen={isOpen[questionOrder]} onClick={(e) => e.stopPropagation()}>
        <FaqDescription questionNumber={questionTitle.order} />
      </Policy>
    </QuestionBoxContainer>
  )
}

const QuestionBoxContainer = styled.section`
  width: 800px;
  padding: 36px;
  margin: 0 auto;
  background-color: white;
  margin-bottom: 16px;
  cursor: pointer;

  ${({ theme }) => theme.media.mobile`
    width: 296px;
    padding: 24px 16px;
  `}
`

const MobileWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const PcWrapper = styled.div`
  display: block;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const MobileSubTitle = styled.h4`
  display: none;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  padding-top: 8px;
  max-width: 244px;
  color: ${({ theme }) => theme.colors.black};
  word-break: keep-all;

  ${({ theme }) => theme.media.mobile`
    display: block;
  `}
`

const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  & > svg {
    transition: transform 0.3s ease-in-out;
  }
`

const SubTitle = styled.h4<{ longSubTitleYn?: boolean }>`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.colors.black};
  word-break: keep-all;

  div {
    padding-bottom: ${(props) => (props.longSubTitleYn ? '30px' : '0px')};
    color: ${({ theme }) => theme.colors.blue};
    padding-right: 4px;
  }

  ${({ theme }) => theme.media.mobile`
    display: flex;
    min-width: 264px;
    align-items: center;
    justify-content: space-between;
    padding-right: 0px;
  `}
`

const SubTitlePc = styled.h4`
  width: 682px;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.colors.black};
  word-break: keep-all;
  max-width: 682px;

  div {
    color: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme }) => theme.media.mobile`
    display: flex;
    min-width: 264px;
    align-items: center;
    justify-content: space-between;
    
  `}
`

const Policy = styled.article<{ isOpen: boolean }>`
  width: 728px;
  height: 0px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  transition: transform 0.3s ease-in-out;
  overflow-y: hidden;
  cursor: default;

  ul {
    list-style: inside;
  }

  & > p {
    font-size: 20px;
    line-height: 34px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.black};
    word-break: keep-all;
    white-space: pre-wrap;

    b {
      font-weight: bold;
    }
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          margin-top: 36px;
          height: auto;
        `
      : css`
          height: 0px;
        `}

  ${({ theme }) => theme.media.mobile`
    width: 264px;

    & > p {
      font-size: 16px;
      line-height: 28px;
    }

  `}
`
