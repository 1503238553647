import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'modules'

export type SnackBarStatusState = {
  message: string
  isOpen: boolean
}

const initialState: SnackBarStatusState = {
  message: '',
  isOpen: false,
}
export const snackBarStatusSlice = createSlice({
  name: 'snackBarStatus',
  initialState,
  reducers: {
    openSnackBar: (state, action: PayloadAction<string>) => {
      state.message = action.payload
      state.isOpen = true
    },
    closeSnackBar: (state) => {
      state.message = ''
      state.isOpen = false
    },
  },
})

export const snackBarStatusSelector = (state: RootState) => state.snackBarStatus

export const getSnackBarStatus = createSelector(snackBarStatusSelector, (snackBarStatus) => {
  return snackBarStatus
})

export const { openSnackBar, closeSnackBar } = snackBarStatusSlice.actions
export default snackBarStatusSlice.reducer
