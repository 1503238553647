import Icon from 'components/Icon'
import styled from 'styled-components'
import aceCrewData from 'data/aceCrewData.json'

export default function LandingAceCrewInterviewSection() {
  return (
    <AceCrewInterviewSectionWrapper>
      <TextWrapper>
        <h2>브라우니를 믿고 맡길 수 있는 이유, 두번째</h2>
        <h1>에이스 크루 인터뷰</h1>
      </TextWrapper>
      <CardUl>
        {aceCrewData.map(({ title, description, imgSrc, blogUrl }) => (
          <CardLi key={title} onClick={() => window.open(blogUrl, '_blank')}>
            <div className="imgWrapper">
              <DropShadowBox />
              <img className="backgroundImg" src={imgSrc} alt="크루 인터뷰" />
            </div>
            <div className="textWrapper">
              <h3>{title}</h3>
              <h2>{description}</h2>
              <span>자세히 보기</span>
            </div>
          </CardLi>
        ))}
      </CardUl>
      <CrewApplyWrapper
        onClick={() =>
          window.open(
            'https://higherx.notion.site/b9ccb239d9784e7692cf596c466ef795?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%ED%81%AC%EB%A3%A8_%EC%A7%80%EC%9B%90',
            '_blank'
          )
        }
      >
        <span>브라우니 크루 지원 바로가기</span>
        <Icon name="GrayRightArrow" />
      </CrewApplyWrapper>
    </AceCrewInterviewSectionWrapper>
  )
}

const AceCrewInterviewSectionWrapper = styled.section`
  width: 100%;
  padding: 160px 0px;

  background-color: var(--gray50);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    height: 1165px;
    padding-top: 80px;
  }
`

const TextWrapper = styled.div`
  & > h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 50px;
    color: var(--brand300);
  }

  & > h1 {
    text-align: center;
    font-weight: bold;
    font-size: 52px;
    line-height: 65px;
    color: var(--gray800);
    margin: 8px 0px 60px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
    & > h2 {
      font-size: 14px;
      line-height: 24px;
    }

    & > h1 {
      font-size: 30px;
      line-height: 40px;
      margin: 4px 0px 40px;
    }
  }
`

const CardUl = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 960px;
  margin-bottom: 32px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 328px;
    margin-bottom: 12px;

    & > li:nth-of-type(2) {
      margin: 12px 0px;
    }
  }
`

const CardLi = styled.li`
  position: relative;
  width: 312px;
  height: 260px;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  border-radius: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;

  .imgWrapper {
    display: flex;
    width: inherit;
    height: inherit;

    img {
      width: inherit;
      border-radius: 24px;
    }
  }

  .textWrapper {
    position: absolute;
    width: inherit;
    height: inherit;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    z-index: 3;

    & > h3 {
      font-weight: bold;
      color: var(--gray200);
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    & > h2 {
      font-weight: bold;
      color: var(--gray0);
      font-size: 24px;
      line-height: 32px;
      white-space: pre-wrap;
    }

    & > span {
      display: block;
      color: var(--gray200);
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
      margin-top: auto;
    }
  }

  @media only screen and (min-width: 768px) {
    &:hover {
      box-shadow: 0px 16px 16px -8px rgba(0, 0, 0, 0.12);
      transform: translateY(-24px);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 328px;
    height: 274px;

    .textWrapper {
      & > h2 {
        font-size: 20px;
        line-height: 28px;
      }

      & > span {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
`

const DropShadowBox = styled.div`
  position: absolute;
  z-index: 2;
  width: inherit;
  height: inherit;
  border-radius: 24px;

  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
`

const CrewApplyWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > span {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: var(--gray600);
    text-decoration: underline;
  }

  & > svg {
    margin-left: 4px;
  }
`
