import useQueryParamHook from 'hooks/useQueryParamHook'
import { useAppSelector } from 'hooks/useReduxHook'
import {
  selectCompletedSchedules,
  selectExceptInvalidTimeSchedules,
  selectInvalidDaySchedules,
  selectInvalidTimeSchedules,
  ScheduleType,
  selectServiceWeeklyCount,
} from 'modules/features/service/serviceSlice'
import { createExtraWeekSchedules, createWeekSchedules, findSchedulesIndex } from 'utils/common/service'
import CalendarDate from '../CalendarDate'

export type InvalidDateProps = {
  invalidType: 'day' | 'time'
  year: number
  month: number
  date: number
  day: number
  newSchedule?: ScheduleType
  onDateClick: (year: number, month: number, date: number, day: number) => void | Promise<void>
}

export default function InvalidDate(props: InvalidDateProps) {
  const { invalidType, year, month, date, day, newSchedule, onDateClick } = props

  const { schedules } = useAppSelector((state) => state.service)
  const serviceWeeklyCount = useAppSelector(selectServiceWeeklyCount)
  const exceptInvalidTimeSchedules = useAppSelector(selectExceptInvalidTimeSchedules)
  const completedSchedules = useAppSelector(selectCompletedSchedules)
  const invalidDaySchedules = useAppSelector(selectInvalidDaySchedules)
  const invalidTimeSchedules = useAppSelector(selectInvalidTimeSchedules)

  const schedulesForType = invalidType === 'day' ? exceptInvalidTimeSchedules : completedSchedules

  const query = useQueryParamHook()

  const invalidIndex = Number(query.get('index'))
  const {
    year: invalidYear,
    month: invalidMonth,
    date: invalidDate,
  } = invalidType === 'day' ? invalidDaySchedules[invalidIndex] : invalidTimeSchedules[invalidIndex]

  const activeWeekSchedules = [
    ...createWeekSchedules(
      schedules,
      {
        year: invalidYear,
        month: invalidMonth,
        date: invalidDate,
      },
      serviceWeeklyCount
    ),
    ...createExtraWeekSchedules(schedules, serviceWeeklyCount),
  ]

  const isNewSelected = !!(
    newSchedule &&
    newSchedule.year === year &&
    newSchedule.month === month &&
    newSchedule.date === date
  )

  const scheduleIndex = findSchedulesIndex(schedulesForType, year, month, date)

  const isIncludeInSchedules = scheduleIndex !== -1

  const isSelected = isIncludeInSchedules || isNewSelected

  const isActiveWeek = !!activeWeekSchedules.find(
    (schedule) => schedule.year === year && schedule.month === month && schedule.date === date
  )

  const isInactive = isIncludeInSchedules || !isActiveWeek

  const label =
    newSchedule && isNewSelected
      ? `${newSchedule.hour}시`
      : isSelected
      ? `${schedulesForType[scheduleIndex].hour}시`
      : undefined

  return (
    <CalendarDate
      {...props}
      key={day}
      selected={isSelected}
      inactive={isInactive}
      label={label}
      onDateClick={async () => {
        await onDateClick(year, month, date, day)
      }}
    />
  )
}
