import SmallButton from 'components/common/SmallButton'
import usePatchModifyBirthdate from 'hooks/query/auth/usePatchModifyBirthdate'
import useClickAwayListener from 'hooks/useClickAwayListener'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import twoDigitsNumConverter from 'utils/common/twoDigitsNumConverter'

export type BirthSelectorProps = {
  onOutsideClick: () => void
}

export default function BirthSelector({ onOutsideClick }: BirthSelectorProps) {
  const ref = useRef<HTMLLIElement>(null)

  const [selectedYear, setSelectedYear] = useState<number>(0)
  const [selectedMonth, setSelectedMonth] = useState<number>(0)
  const [selectedDate, setSelectedDate] = useState<number>(0)
  const [lastDate, setLastDate] = useState<number>(31)

  const currentYear = new Date().getFullYear()
  const isValid = selectedYear !== 0 && selectedMonth !== 0 && selectedDate !== 0

  const { mutateAsync: mutatePatchBirth, isError, error } = usePatchModifyBirthdate()
  let errorData = error as any

  useClickAwayListener({ ref, onOutsideClick })

  useEffect(() => {
    if (selectedYear === 0 || selectedMonth === 0) return

    const selectedLastDate = new Date(selectedYear, selectedMonth, 0).getDate()
    if (lastDate > selectedLastDate) setSelectedDate(1)
    setLastDate(selectedLastDate)
  }, [selectedYear, selectedMonth, lastDate, selectedDate])

  const handleSaveBirth = async () => {
    const year = selectedYear === 0 ? '' : selectedYear
    const month = selectedMonth === 0 ? '' : twoDigitsNumConverter(selectedMonth)
    const date = selectedDate === 0 ? '' : twoDigitsNumConverter(selectedDate)
    await mutatePatchBirth({ birth_year: year.toString(), birth_day: `${month}${date}` })
    onOutsideClick()
  }

  return (
    <StyledContainer ref={ref}>
      <div>
        <div>
          <select
            defaultValue="년"
            className={selectedYear === 0 ? 'no_select' : undefined}
            onChange={(e) => setSelectedYear(Number(e.currentTarget.value.split(' ')[0]))}
          >
            <option disabled value="년">
              년
            </option>
            {Array.from({ length: 82 }).map((_, i) => (
              <option key={i} value={currentYear - 19 - i}>
                {currentYear - 19 - i} 년
              </option>
            ))}
          </select>
          <select defaultValue="월" onChange={(e) => setSelectedMonth(Number(e.currentTarget.value.split(' ')[0]))}>
            <option disabled value="월">
              월
            </option>
            {Array.from({ length: 12 }).map((_, i) => (
              <option key={i} value={i + 1}>
                {i + 1} 월
              </option>
            ))}
          </select>
          <select defaultValue="일" onChange={(e) => setSelectedDate(Number(e.currentTarget.value.split(' ')[0]))}>
            <option disabled value="일">
              일
            </option>
            {Array.from({ length: lastDate }).map((_, i) => (
              <option key={i} value={i + 1}>
                {i + 1} 일
              </option>
            ))}
          </select>
          <SmallButton buttonTheme={isValid ? 'green' : 'disabled'} onClick={handleSaveBirth}>
            저장
          </SmallButton>
        </div>
        {isError && <WarningText>{errorData.response.data.message}</WarningText>}
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.li`
  margin-left: -16px;
  height: fit-content !important;

  & > div {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      align-items: center;

      select::-ms-expand {
        display: none;
      }
      select {
        width: 84px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.colors.gray};
        margin-right: 8px;
        -o-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 16px;
        line-height: 24px;
        color: ${({ theme }) => theme.colors.black};
        font-weight: 400;
        text-align: right;
        cursor: pointer;
      }

      select:first-child {
        padding: 12px;
      }

      select:not(select:first-child) {
        padding: 12px 16px;
      }
    }
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    & > div {
      & > div {
        select {
          min-width: 68px;
          width: 68px;
          padding: 0;
          text-align: right;
        }

        select:first-child {
          padding: 0;
          text-align: center;
        }

        select.no_select {
          padding-right: 6px;
          text-align: right;
        }

        select:not(select:first-child) {
          padding: 6px;
        }
      }
    }
  `}
`

const WarningText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.warning};
  margin-top: 8px;
`
