import AlertModal from 'components/common/AlertModal'
import SmallButton from 'components/common/SmallButton'
import usePatchPhoneModify from 'hooks/query/authVerify/usePatchPhoneModify'
import usePostSendPhone from 'hooks/query/authVerify/usePostSendPhone'
import useClickAwayListener from 'hooks/useClickAwayListener'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export type PhoneNumChangeInputProps = {
  onOutsideClick: () => void
}

const formattingTime = (time: number) => {
  const m = Math.floor(time / 60).toString()
  let s = (time % 60).toString()
  if (s.length === 1) s = `0${s}`
  return `${m}:${s}`
}

export default function PhoneNumChangeInput({ onOutsideClick }: PhoneNumChangeInputProps) {
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const verifyRef = useRef<HTMLInputElement>(null)

  const {
    mutateAsync: mutatePostSendPhone,
    data: sendData,
    isError: isSendError,
    error: sendError,
  } = usePostSendPhone()
  const {
    mutateAsync: mutatePatchVerifyPhone,
    data: verifyData,
    isError: isVerifyError,
    error: verifyError,
  } = usePatchPhoneModify()

  const [isSendSuccess, setIsSendSuccess] = useState<boolean>(false)
  const [isTrySend, setIsTrySend] = useState<boolean>(false)
  const [isTryVerify, setIsTryVerify] = useState<boolean>(false)
  const [sendSuccessOpen, setSendSuccessOpen] = useState<boolean>(false)
  const [remainTime, setRemainTime] = useState<number>(0)
  const [overTime, setOverTime] = useState<boolean>(false)
  const [startTime, setStartTime] = useState<number>(0)

  useClickAwayListener({ ref, onOutsideClick: !sendSuccessOpen ? () => onOutsideClick() : () => {} })

  useEffect(() => {
    if (!overTime && remainTime > 0 && startTime > 0) {
      const counter = setInterval(() => {
        const gap = Math.floor((new Date(startTime).getTime() - new Date().getTime()) / 1000)

        if (gap === 0) {
          setOverTime(true)
          setStartTime(0)
        }

        setRemainTime(gap)
      }, 1000)
      return () => clearInterval(counter)
    }
  }, [remainTime, startTime, overTime])

  const handleVerifySend = async () => {
    if (!inputRef.current || inputRef.current.value === '') return
    if (isSendError && sendError.response.status === 410) return

    setIsTrySend(true)
    setIsSendSuccess(false)
    await mutatePostSendPhone({ phone: inputRef.current.value })
    setIsSendSuccess(true)
    setSendSuccessOpen(true)
    setRemainTime(599)
    setOverTime(false)
    setStartTime(new Date().setMinutes(new Date().getMinutes() + 10))
    setIsTryVerify(false)
  }

  const handleVerifyCheck = async () => {
    if (!inputRef.current || inputRef.current.value === '' || !verifyRef.current || verifyRef.current.value === '')
      return
    if (overTime) return

    setIsTryVerify(true)
    await mutatePatchVerifyPhone({ phone: inputRef.current.value, user_input_code: verifyRef.current.value })
    onOutsideClick()
  }

  return (
    <StyledChange ref={ref}>
      <StyledContainer>
        <input
          ref={inputRef}
          type="text"
          autoFocus
          placeholder="휴대폰 번호 (숫자만 입력)"
          onChange={() => {
            setIsTryVerify(false)
            setIsTrySend(false)
            setIsSendSuccess(false)
            setOverTime(false)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleVerifySend()
            }
          }}
        />
        <SmallButton
          buttonTheme={sendData ? 'black' : isSendError && sendError.response.status === 410 ? 'disabled' : 'green'}
          onClick={handleVerifySend}
        >
          {sendData ? '재전송' : '인증'}
        </SmallButton>
      </StyledContainer>
      {isTrySend && !verifyData?.modify && isSendError && <WarningText>{sendError.response.data.message}</WarningText>}
      {isTrySend && !verifyData?.modify && isSendSuccess && (
        <WarningText>{`남은 인증횟수: ${sendData?.remaining_count || 0 - 1}회 (하루 5회)`}</WarningText>
      )}
      {isSendSuccess && (
        <>
          <StyledContainer style={{ marginTop: 8 }}>
            <input
              ref={verifyRef}
              type="text"
              placeholder="인증번호 입력"
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  await handleVerifyCheck()
                }
              }}
            />
            <p>{formattingTime(remainTime)}</p>
            <SmallButton buttonTheme={overTime ? 'disabled' : 'primary'} onClick={handleVerifyCheck}>
              확인
            </SmallButton>
          </StyledContainer>
          {isTryVerify && !overTime && isVerifyError && !verifyData?.modify && (
            <WarningText>{verifyError.response.data.message}</WarningText>
          )}
          {overTime && (
            <WarningText>
              입력시간이 초과되었습니다.
              <br />
              재전송하고 다시 입력해 주세요.
            </WarningText>
          )}
        </>
      )}

      <AlertModal
        title="인증 문자 발송"
        headline="발송된 문자의 인증 번호를 입력해 주세요."
        open={sendSuccessOpen}
        onConfirm={() => {
          verifyRef.current?.focus()
          setSendSuccessOpen(false)
        }}
      />
    </StyledChange>
  )
}

const StyledChange = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`

const StyledContainer = styled.div`
  width: 320px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};

  input {
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    padding: 0;
    padding-right: 8px;
    border: none;
  }

  input::placeholder {
    opacity: 0.5;
    color: ${({ theme }) => theme.colors.black};
  }

  p {
    font-size: 14px;
    margin-right: 12px;
    min-width: fit-content;
    color: ${({ theme }) => theme.colors.warning};
    letter-spacing: -0.4px;
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    width: 280px;
  `}
`

const WarningText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning};
  line-height: 24px;
  margin-top: 8px;
  word-break: keep-all;
`
