import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import useGetResetPassword from 'hooks/query/authVerify/useGetResetPassword'
import usePostResetPasswordComplete from 'hooks/query/authVerify/usePostResetPasswordComplete'

interface IFormInput {
  password: string
  passwordRepeat: string
}

export default function PwResetForm() {
  const [passwordShown, setPasswordShown] = useState<boolean>(false)
  const { email, token } = useParams<{ email: string; token: string }>()
  const { mutateAsync: mutateResetPassword } = useGetResetPassword()
  const { mutateAsync: mutateResetPasswordComplete } = usePostResetPasswordComplete()

  const checkFunc = useCallback(async () => {
    await mutateResetPassword({ email, token })
  }, [email, token, mutateResetPassword])

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  useEffect(() => {
    checkFunc()
  }, [checkFunc])

  const onSubmit = async (data: IFormInput) => {
    await mutateResetPasswordComplete({ email: email, password: data.password, password_repeat: data.passwordRepeat })
  }

  const {
    register,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm<IFormInput>({ mode: 'onChange' })

  const password = useRef<any>(null)
  password.current = watch('password')

  const passwordRepeat = useRef<any>(null)
  passwordRepeat.current = watch('passwordRepeat')

  const checkingPasswordSame = () => {
    if (
      watch('password') !== watch('passwordRepeat') &&
      watch('password') &&
      watch('password').length !== 0 &&
      watch('passwordRepeat') &&
      watch('passwordRepeat').length !== 0
    ) {
      return true
    }
    return false
  }

  return (
    <>
      <PasswordResetWrapper>
        <PasswordResetForm>
          <FormWrapper>
            <FormInput
              placeholder="비밀번호 (8자 이상 영문/숫자/특수문자)"
              type={passwordShown ? 'text' : 'password'}
              {...register('password', {
                required: true,
                pattern: new RegExp('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[!#$%&()*+,-./:;<=>?@^_`{|}~\\[\\]]).{8,}$'),
                validate: (value) => value === passwordRepeat.current,
              })}
            />
            {watch('password') && watch('password').length !== 0 && (
              <span onClick={togglePasswordVisiblity}>{passwordShown ? '숨기기' : '표시'}</span>
            )}
          </FormWrapper>
          {errors.password && (errors.password.type === 'pattern' || errors.password.type === 'minLength') && (
            <WarningText>비밀번호는 8자 이상, 영문/숫자/특수문자로 설정해야 합니다.</WarningText>
          )}
          <FormWrapper>
            <FormInput
              placeholder="비밀번호 확인"
              type="password"
              {...register('passwordRepeat', {
                required: true,
                validate: (value) => value === password.current,
              })}
            />
          </FormWrapper>

          {checkingPasswordSame() && <WarningText>비밀번호가 일치하지 않습니다.</WarningText>}
          <LoginButton
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={
              password.current !== passwordRepeat.current ||
              !watch('password') ||
              !watch('passwordRepeat') ||
              (errors.password && (errors.password.type === 'pattern' || errors.password.type === 'minLength'))
            }
            value={'확인'}
          />
        </PasswordResetForm>
      </PasswordResetWrapper>
    </>
  )
}

const PasswordResetWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(6)};
  overflow-y: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.media.mobile`padding-top: ${theme.spacing(3)}`}
`
const PasswordResetForm = styled.form`
  width: 100%;
  position: relative;
`

const FormWrapper = styled.div<{ block?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.1rem 1.6rem;
  margin-top: 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.gray};
  border-radius: 0.8rem;

  span {
    flex: 0 0 auto;
    font-size: 1.6rem;
    line-height: 2.4rem;
    cursor: pointer;
    background-color: white;
  }

  ${({ block }) =>
    block &&
    `
  background-color: #f8f8f8;
  `}
`

// const ErrorIcon = styled(Icon)`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   right: 1.4rem;
// `

const FormInput = styled.input<{ fill?: string; placeholder?: string }>`
  width: 100%;
  border: 0;
  font-size: 1.6rem;
  background-color: ${({ theme }) => `${theme.colors.white}`};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0;

  ::placeholder {
    opacity: 0.3;
  }

  :disabled {
    background-color: #f8f8f8;
  }
`
const WarningText = styled.div`
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-size: 1.6rem;
  color: ${({ theme }) => `${theme.colors.warning}`};
`

const LoginButton = styled.input<{ disabled?: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 4.8rem;
  border: none;
  border-radius: 0.8rem;
  margin-top: 1.6rem;
  -webkit-appearance: none;
  background-color: ${({ theme }) => `${theme.colors.main}`};
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => `${theme.colors.white}`};

  ${({ disabled }) =>
    disabled === true &&
    `
    background-color: #edeff1;
    color: #1e1e2c;
    opacity: 0.5;
    cursor: no-drop;

  `}
`
