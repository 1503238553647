import { useMutation, useQueryClient } from 'react-query'
import { deleteService, DeleteServiceParam } from 'lib/api/service/deleteService'
import useStoreServiceQuery from './useStoreServiceQuery'

export default function useDeleteService() {
  const queryClient = useQueryClient()
  const createStoreServiceKey = useStoreServiceQuery.createKey

  return useMutation((value: DeleteServiceParam) => deleteService(value), {
    onSuccess: (res) => {
      const storeServiceKey = createStoreServiceKey(res.store_id)
      queryClient.invalidateQueries(storeServiceKey)
      queryClient.removeQueries(['services'])
      queryClient.refetchQueries(['services'])
    },
  })
}
