import { useState } from 'react'
import { BottomSheet, Icon as OhdsIcon, SelectBox } from 'ohds-web'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import { setServiceWeek, ServiceWeekType } from 'modules/features/service/serviceSlice'
import styled from 'styled-components'
import { CustomServiceBadge } from 'components/StorePage/StoreList/StoreItem/ServiceBadge/ServiceBadge'

export type ServiceWeekSelectBoxProps = {
  autoOpen: boolean
  label: string
  value?: string
  serviceWeekOptions: Readonly<ServiceWeekType[]>
}

export default function ServiceWeekSelectBox({
  autoOpen,
  label,
  value,
  serviceWeekOptions,
}: ServiceWeekSelectBoxProps) {
  const dispatch = useAppDispatch()
  const { serviceWeek } = useAppSelector((state) => state.service)

  const [isWeekSelectOpen, setIsWeekSelectOpen] = useState<boolean>(autoOpen)

  const openOption = () => setIsWeekSelectOpen(true)
  const closeOption = () => setIsWeekSelectOpen(false)

  const handleSelectBoxClick = () => {
    openOption()
  }

  const handleBottomSheetClose = () => {
    if (serviceWeek === undefined) return

    closeOption()
  }

  const handleOptionClick = (selectedWeek: ServiceWeekType) => {
    closeOption()
    dispatch(setServiceWeek(selectedWeek))
  }

  return (
    <SelectBox
      label={label}
      value={value}
      onSelectBoxClick={handleSelectBoxClick}
      BottomSheet={
        <StyledBottomSheet open={isWeekSelectOpen} title="관리 기간을 선택해 주세요" onClose={handleBottomSheetClose}>
          <StyledUl>
            {serviceWeekOptions.map((weekOption, index) => (
              <StyledLi key={index} onClick={() => handleOptionClick(weekOption)}>
                {weekOption === 4 ? (
                  <WeekWrap>
                    <StyledP>{weekOption}주</StyledP>
                    <CustomServiceBadge name="자동 결제 가능" badgeType="SUBSCRIBE" />
                  </WeekWrap>
                ) : (
                  <TextBox>
                    <StyledP>{weekOption}주</StyledP>
                    <Discount>{weekOption === 12 ? '5% 할인' : weekOption === 24 ? '10% 할인' : null} </Discount>
                  </TextBox>
                )}
                {serviceWeek === weekOption && <OhdsIcon name="LineCheck" size="small" color={'brand300'} />}
              </StyledLi>
            ))}
          </StyledUl>
        </StyledBottomSheet>
      }
    />
  )
}

const StyledBottomSheet = styled(BottomSheet)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    position: fixed;
    left: 50%;
  }
`

const StyledUl = styled.ul``

const StyledLi = styled.li`
  width: 100%;
  padding: 16px;
  background-color: var(--gray0);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: var(--gray-gray-100, #f5f5f5);
  }
`

const WeekWrap = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin-right: 12px;
  }
`

const TextBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .tag {
    margin-left: 14px;
    align-items: center;
    & > p {
      width: 100%;
      font-size: 12px;
      color: white;
      font-weight: 400;
      background-color: var(--brand300);
      text-align: center;
      border-radius: 8px;
      padding: 2px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > svg {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
    }
  }
`

const StyledP = styled.p<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? 'var(--gray400)' : 'var(--gray800)')};
  display: block;
  font-weight: 400;
  font-size: var(--p1);
  line-height: var(--p1LineHeight);
`

const Discount = styled.span`
  color: #3d5afe;
  font-size: 14px;
  font-weight: 700;
  margin-left: 4px;
`
