import Icon from 'components/Icon'
import ServiceCalendar from 'components/ServiceComplete/ServiceCalendar'
import ServiceCancelBottomSheet from 'components/common/ServiceCancelBottomSheet/ServiceCancelBottomSheet'
import ServiceCancelModal from 'components/common/ServiceCancelModal/ServiceCancelModal'
import dayjs from 'dayjs'
import useAuth from 'hooks/query/auth/useAuth'
import useBvsQuery from 'hooks/query/bvs/useBvsQuery'
import usePaymentQuery from 'hooks/query/payment/usePaymentQuery'
import useServiceQuery from 'hooks/query/service/useServiceQuery'
import LogManager from 'lib/utils/logger'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import addComma from 'utils/common/addComma'
import { ROUTES } from 'utils/common/routes'
import { SelectedDateType, TimeSelectionType, timezoneToSelectedDate } from 'utils/common/time'

type VBankPaymentInfoProp = {
  serviceId: string
}
export default function VBankPaymentInfo({ serviceId }: VBankPaymentInfoProp) {
  const [openContractCancelModal, setOpenContractCancelModal] = useState<boolean>(false)
  const [openContractCancelBottomSheet, setOpenContractCancelBottomSheet] = useState<boolean>(false)

  const { auth } = useAuth()
  const { data: service } = useServiceQuery({ id: serviceId })
  const { data: payment } = usePaymentQuery(
    { id: service?.payment_id as number, userId: auth?.id as number },
    { enabled: !!auth && !!service }
  )
  const { data: bvs } = useBvsQuery({ id: service?.bvs_id || 0 }, { enabled: !!service })

  const firstDate = timezoneToSelectedDate(bvs?.reservation_dates[0] as string)
  const totalReservationDate: { year: number; month: number; date: number; time: TimeSelectionType }[] = []
  const bvsDates = bvs ? [...bvs?.reservation_dates].sort() : []
  bvsDates.map((date) => totalReservationDate.push(timezoneToSelectedDate(date)))

  const isPaymentFail = payment?.state === 'FAILED'

  const handleServiceDeleteClick = () => {
    if (isMobile) {
      setOpenContractCancelBottomSheet(true)
    } else {
      setOpenContractCancelModal(true)
    }

    LogManager.Instance.sendLog('store_service_payment_delete', {
      btn_name: '해지',
      btn_url: 'NULL',
    })
  }

  return (
    <Container>
      <InformationContainer>
        <Information>
          {!isPaymentFail ? (
            <>
              <Icon name="emojiBell" />
              <p>
                지금, 브라우니를 잡아주세요.
                <br /> 브라우니 방문 일정은 결제 순으로 확정됩니다.
              </p>
            </>
          ) : (
            <>
              <Icon name="LineInterfaceAttentionCircle" />
              <p>입금 기한 만료로 결제에 실패했어요!</p>
            </>
          )}
        </Information>
      </InformationContainer>

      <VbankNotice>
        <VbankNoticeTitle>무통장 입금 안내</VbankNoticeTitle>
        <VbankNoticeSubTitle>아래 가상계좌로 입금 시 결제가 완료됩니다.</VbankNoticeSubTitle>
      </VbankNotice>

      <ContractConfirmContainer>
        <TitleWrap>계약 확인서</TitleWrap>

        <VbankInfoWrap>
          <VbankInfoTitle>입금 기한</VbankInfoTitle>
          <VbankInfoContent>
            <InfoText>{dayjs(payment?.vbank?.expired_at).format('YYYY.MM.DD')}</InfoText>
            <DetailText>
              <span>{dayjs(payment?.vbank?.expired_at).format('YY년 M월 DD일 HH:mm')} 까지 </span>입금 확인이 되지 않을
              시 별도 안내 없이 자동 취소됩니다.
            </DetailText>
          </VbankInfoContent>
        </VbankInfoWrap>

        <VbankInfoWrap>
          <VbankInfoTitle>입금자명</VbankInfoTitle>
          <VbankInfoContent>
            <InfoText>{payment?.vbank?.sender_name} 님</InfoText>
            <DetailText>
              ∙ 반드시 입금자명을 동일하게 기재해 주세요.
              <br />∙ 다르게 입금했을 경우,{' '}
              <a href="https://brwnie.channel.io/" target="_blank" rel="noreferrer">
                고객센터
              </a>
              에 알려주세요.
              <br />
            </DetailText>
          </VbankInfoContent>
        </VbankInfoWrap>

        <VbankInfoWrap>
          <VbankInfoTitle>계좌 번호</VbankInfoTitle>
          <VbankInfoContent>
            <InfoText>
              {payment?.vbank?.bank_name} {payment?.vbank?.account_number}
            </InfoText>
          </VbankInfoContent>
        </VbankInfoWrap>

        <VbankInfoWrap>
          <VbankInfoTitle>예금주</VbankInfoTitle>
          <VbankInfoContent>
            <InfoText>{payment?.vbank?.account_holder_name}</InfoText>
          </VbankInfoContent>
        </VbankInfoWrap>

        <VbankInfoWrap>
          <VbankInfoTitle>결제 금액</VbankInfoTitle>
          <VbankInfoContent>
            <InfoText>{addComma(payment?.price as number) || 0} 원</InfoText>
          </VbankInfoContent>
        </VbankInfoWrap>

        <InfoWrap>
          <InfoBox>
            <InfoTitle>첫 출근일</InfoTitle>
            <InfoText>{firstDate.date}일</InfoText>
          </InfoBox>
          <InfoBox>
            <InfoTitle>서비스 유형</InfoTitle>
            <InfoText>일반 결제 상품</InfoText>
          </InfoBox>
        </InfoWrap>

        <ServiceCalendar firstDate={firstDate as SelectedDateType} totalReservationDate={totalReservationDate} />
      </ContractConfirmContainer>

      <ServiceDeleteButton onClick={handleServiceDeleteClick}>계약 해지</ServiceDeleteButton>

      <ToMyPage to={ROUTES.STORE}>관리페이지 가기</ToMyPage>

      <ServiceCancelModal
        open={openContractCancelModal}
        onClose={() => setOpenContractCancelModal(false)}
        serviceId={serviceId}
        isVbank
      />

      <ServiceCancelBottomSheet
        open={openContractCancelBottomSheet}
        onClose={() => setOpenContractCancelBottomSheet(false)}
        serviceId={serviceId}
        isVbank
      />
    </Container>
  )
}

const Container = styled.div`
  width: min-content;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const InformationContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.mobile`
    margin: 32px 0;
  `}

  border-radius: 16px;
  background: var(--gray-gray-50, #fafafa);
`

const Information = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: var(--gray-gray-700, #333);

    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 12px;
  }
`

const VbankNotice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 24px;
`

const VbankNoticeTitle = styled.p`
  color: var(--gray-gray-800, #212121);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`
const VbankNoticeSubTitle = styled.p`
  color: var(--gray-gray-700, #333);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;

  margin-top: 8px;
`

const ContractConfirmContainer = styled.div`
  width: 100%;
  margin-top: 24px;

  border-radius: 16px;
  border: 1px solid var(--primary-02, #edeff1);
`

const TitleWrap = styled.h3`
  width: 100%;
  padding: 16px;
  border-radius: 16px 16px 0px 0px;
  background: var(--gray-gray-50, #fafafa);
  color: var(--gray-gray-600, #757575);

  text-align: center;
  font-size: 12px;
  font-weight: 500;
`

const VbankInfoWrap = styled.div`
  padding: 16px;
  border-bottom: 1px solid #edeff1;
`

const VbankInfoTitle = styled.p`
  color: var(--gray-gray-600, #757575);

  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 12px;
`

const VbankInfoContent = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoText = styled.p`
  color: var(--gray-gray-800, #212121);
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
`
const DetailText = styled.p`
  margin-top: 8px;
  color: var(--gray-gray-700, #333);
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;

  & > span {
    color: #039;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  & > a {
    color: var(--gray-gray-700, #333);
    text-decoration: underline;
    cursor: pointer;
  }
`

const ToMyPage = styled(Link)`
  color: var(--gray-gray-600, #757575);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  margin-top: 8px;
`

const InfoWrap = styled(VbankInfoWrap)`
  width: 100%;
  display: flex;
`
const InfoBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 40px;
`

const InfoTitle = styled.p`
  color: var(--gray-gray-500, #a8a8a8);
  text-align: center;
  font-size: 11px;
`

const ServiceDeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--gray-gray-600, #757575);
  font-size: 14px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 24px;
`
