import client from '../client'
import { StoreType } from './types'

export async function getStore({ id }: GetStoreParam) {
  const response = await client.get<StoreType>(`/service-api/v1/store/${id}`)

  return response.data
}

export type GetStoreParam = {
  id: string
}
