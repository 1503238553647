/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios'
import useAuthQuery from 'hooks/query/auth/useAuthQuery'
import usePatchContractedDate from 'hooks/query/contractedDate/usePatchContractedDate'
import useGetCoupons from 'hooks/query/coupon/useGetCoupons'
import useMyPointsQuery from 'hooks/query/points/useMyPointsQuery'
import useServiceQuery from 'hooks/query/service/useServiceQuery'
import { getContractedDate } from 'lib/api/contractedDate/getContractedDate'
import { COUPON_STATE } from 'lib/api/coupon/getCoupons'
import { getPayment } from 'lib/api/payment/getPayment'
import { useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import addComma from '../../utils/common/addComma'
import { SelectedDateType, TimeSelectionType, timezoneToSelectedDate } from '../../utils/common/time'
import Button from '../common/Button'
import Icon from '../Icon'
import BenefitSection from './BenefitSection'
import BvsViewer from './BvsViewer'
import PolicySection from './PolicySection'
import SubTitle from './SubTitle'
import Spinner from 'components/common/Spinner'
import ConfirmModal from 'components/common/ConfirmModal'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { calcTotalDate, getDateFormat } from 'utils/common/calendar'
import AlertModal from 'components/common/AlertModal'
import { usePageVisibility } from 'hooks/usePageVisibility'
import queryString from 'query-string'
import { browserName, isMobile } from 'react-device-detect'
import useBvsQuery from 'hooks/query/bvs/useBvsQuery'
import useDeleteService from 'hooks/query/service/useDeleteService'
import usePatchServicePayment from 'hooks/query/service/usePatchServicePayment'
import useStoreQuery from 'hooks/query/store/useStoreQuery'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import { filteringOverlapDate, groupBys, isLimitOverlap } from 'utils/common/service'
import { initServiceState } from 'modules/features/service/serviceSlice'
import buildStoreSummary from 'utils/common/buildStoreSummary'
import { useDispatch } from 'react-redux'
import ServiceBadge from 'components/StorePage/StoreList/StoreItem/ServiceBadge/ServiceBadge'
import usePostBillingKeyMutation from 'hooks/query/service/userPostBillingKeyMutation'
import usePostBillingMutation from 'hooks/query/payment/usePostBillingMutation'
import useToast from 'hooks/useToast'
import { PaymentMethodType } from 'lib/paymentMethod'
import PaymentMethodList from './PaymentMethodList'
import { getOrderId } from 'utils/common/payment'
import ServiceCancelModal from 'components/common/ServiceCancelModal/ServiceCancelModal'
import ServiceCancelBottomSheet from 'components/common/ServiceCancelBottomSheet/ServiceCancelBottomSheet'

const DELAY = 1000

// TODO: z-index 문제 해결 / 어떤 애니메이션을 쓸까

export const IFrameKGInicis = ({ source }: { [index: string]: any }) => {
  if (!source) {
    return <></>
  }
  const src = source
  return (
    <iframe
      id="wrapperIFrame"
      title="test"
      srcDoc={src}
      frameBorder="0"
      style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%', zIndex: 10 }}
    ></iframe>
  )
}

export type PaymentFormProps = {
  userId: number
  userName: string
  userPhone: string
}

let orderIdParam = ''
const mobilePivotScreenWidth = 768

export default function PaymentForm({ userId, userName, userPhone }: PaymentFormProps) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const toast = useToast()

  const query: { oid?: string } = queryString.parse(location.search)
  const { serviceId } = useParams<{ serviceId: string }>()
  const { data: auth } = useAuthQuery({}, { retry: 0, refetchOnWindowFocus: true })
  const { data: service } = useServiceQuery({ id: serviceId })
  const { data: store } = useStoreQuery({ id: service?.store_id as string }, { enabled: !!service })

  const [isCCLOpen, setIsCCLOpen] = useState<boolean>(false)
  const [isPolicyOpen, setIsPolicyOpen] = useState<boolean>(false)
  const [isCouponOpen, setIsCouponOpen] = useState<boolean>(false)
  const [html, setHtml] = useState<string>('')
  const [point, setPoint] = useState<string>('0')
  const [selectedCouponId, setSelectedCouponId] = useState<number>(0)
  const [inicisLoading, setInicisLoading] = useState<boolean>(false)
  const [previousModalOpen, setPreviousModalOpen] = useState<boolean>(false)
  const [previousConfirmLoading, setPreviousConfirmLoading] = useState<boolean>(false)
  const [overlapModalOpen, setOverlapModalOpen] = useState<boolean>(false)
  const [overlapConfirmLoading, setOverlapConfirmLoading] = useState<boolean>(false)
  const [paymentMethodOpen, setPaymentMethodOpen] = useState<boolean>(false)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType | null>(null)
  const [openInvalidFirstDateModal, setOpenInvalidFirstDateModal] = useState<boolean>(false)
  const [openContractCancelModal, setOpenContractCancelModal] = useState<boolean>(false)
  const [openContractCancelBottomSheet, setOpenContractCancelBottomSheet] = useState<boolean>(false)
  const [subscribePaymentLoading, setSubscribePaymentLoading] = useState<boolean>(false)

  const isVisible = usePageVisibility()

  const { mutateAsync: mutatePatchContractedDate } = usePatchContractedDate()
  const { mutateAsync: mutateDeleteService } = useDeleteService()
  const { mutateAsync: mutatePatchService } = usePatchServicePayment()
  const { mutateAsync: mutateBillingKeyMutation } = usePostBillingKeyMutation()
  const { mutateAsync: mutateBillingMutation } = usePostBillingMutation()

  const { data: bvs } = useBvsQuery(
    {
      id: service?.bvs_id || 0,
    },
    {
      enabled: !!service,
    }
  )

  useEffect(() => {
    const { year, month, date } = timezoneToSelectedDate(bvs?.reservation_dates[0] as string)

    if (new Date(year, month - 1, date - 1).getTime() < Date.now()) {
      setOpenInvalidFirstDateModal(true)
    }
  }, [bvs?.reservation_dates])

  const { data: myPoints } = useMyPointsQuery(userId)
  const { data: coupons } = useGetCoupons({ state: COUPON_STATE.UN_USED })

  const firstDate = timezoneToSelectedDate(bvs?.reservation_dates[0] as string)
  const totalDateOfFirstDate = calcTotalDate(firstDate.year, firstDate.month)
  const nextYear = firstDate.month === 12 ? firstDate.year + 1 : firstDate.year
  const nextMonth = firstDate.month === 12 ? 1 : firstDate.month + 1
  const isTwoMonth = firstDate.date + Number(service?.total_week_count) * 7 - 1 > totalDateOfFirstDate

  const selectedCoupon = coupons?.coupons.find((coupon) => coupon.id === selectedCouponId)
  const couponPrice = selectedCoupon
    ? selectedCoupon.discount_price
      ? (selectedCoupon.discount_price as number)
      : (service!.service_price * Number(selectedCoupon.discount_rate)) / 100
    : 0
  const isPointAvailable =
    point !== '' && (myPoints?.owned_points as number) >= 10000 && parseInt(point, 10) % 1000 === 0

  const isSubscribePaymentOccurred = service?.is_regular_payment && service.regular_payment_round_n > 1

  const onPayment = useCallback(
    async (orderId: string) => {
      const payment = await getPayment({ id: orderId })
      orderIdParam = ''
      if (payment === undefined) return

      try {
        await mutatePatchService({
          id: service?.id as string,
          payment_id: payment.id,
          contracted_method: 'EMAIL',
        })

        if (bvs) {
          await saveSuccessfulPaymentSchedule()
        }

        if (payment.state !== 'PENDING') {
          history.push(`/service-complete/${service?.id}`)
        }
      } catch (err) {
        toast.open('결제에 실패했습니다')
      }
    },
    [bvs, mutatePatchContractedDate, mutatePatchService, service?.id, history]
  )

  const onSubscribePayment = async (orderId: string) => {
    try {
      if (subscribePaymentLoading) {
        throw new Error('이미 실결제 요청이 진행되었습니다.')
      }

      if (!totalPrice || !service?.service_price) {
        throw new Error('금액이 정확하지 않습니다.')
      }

      // 실결제 요청
      setSubscribePaymentLoading(true)

      await mutateBillingMutation({
        order_id: orderId,
        price: service?.service_price,
        name: userName,
        phone: userPhone,
        service_id: service?.id as string,
        point: isPointAvailable ? parseInt(point, 10) : 0,
        coupon_id: selectedCouponId === 0 ? undefined : selectedCouponId,
      })

      setSubscribePaymentLoading(false)

      const payment = await getPayment({ id: orderId })

      orderIdParam = ''
      if (payment === undefined) return

      await saveSuccessfulPaymentSchedule()

      if (payment.state !== 'PENDING') {
        history.push(`/service-complete/${service?.id}`)
      }
    } catch (err: any) {
      const error = err.response
      setSubscribePaymentLoading(false)

      if (!error) return

      if (error.status === 503) {
        return history.push(`/service-complete/${service?.id}`)
      } else {
        toast.open('결제에 실패했습니다.')
      }
    }
  }

  const saveSuccessfulPaymentSchedule = async () => {
    if (!bvs) return false

    const { reservation_dates } = bvs

    const reservationToDateFormat: object[] = reservation_dates.reduce((acc: object[], cur) => {
      const day = new Date(cur)
      acc.push({ ...getDateFormat(day), originalText: cur })
      return acc
    }, [])

    const groupingMonth = groupBys(reservationToDateFormat, 'month')
    const groupingYear: object[] = Object.entries(groupingMonth).map(([key, value]) => groupBys(value, 'year'))

    groupingYear.map(async (data) => {
      return Object.entries(data).map(async ([_, value]) => {
        const { year, month } = value[0]
        const contractedDate = await getContractedDate({ year, month })

        const userContractedDate: string[] = value.map(
          ({ originalText }: { year: number; month: number; date: number; originalText: string }) => originalText
        )
        const dates = contractedDate ? [...contractedDate.dates, ...userContractedDate].sort() : [...value].sort()

        return await mutatePatchContractedDate({
          year,
          month,
          dates,
        }).then((data) => {
          return data
        })
      })
    })
  }

  useEffect(() => {
    if (isVisible && orderIdParam !== '') {
      if (!service?.is_regular_payment) onPayment(orderIdParam)
      else onSubscribePayment(orderIdParam)
    }
  }, [isVisible, onPayment])

  useEffect(() => {
    if ((browserName === 'WebKit' || browserName === 'Chrome WebView') && query.oid) {
      if (!service?.is_regular_payment) onPayment(query.oid)
      else onSubscribePayment(orderIdParam)
    }
  }, [query.oid, onPayment])

  const totalReservationDate: { year: number; month: number; date: number; time: TimeSelectionType }[] = []
  const bvsDates = bvs ? [...bvs?.reservation_dates].sort() : []
  bvsDates.map((date) => totalReservationDate.push(timezoneToSelectedDate(date)))

  const extraReservationDate: { year: number; month: number; date: number; time: TimeSelectionType }[] = []
  const extraDates = bvs && bvs.cash_management_dates ? [...bvs?.cash_management_dates] : []
  extraDates.map((date) => extraReservationDate.push(timezoneToSelectedDate(date)))

  const serviceText = useMemo(() => {
    return `${service && buildStoreSummary(service)}`
  }, [service])

  if (!bvs) return null

  const totalPrice = service?.service_price
    ? isPointAvailable
      ? coupons && selectedCouponId !== 0
        ? addComma(service.service_price - parseInt(point, 10) - couponPrice)
        : addComma(service.service_price - parseInt(point, 10))
      : coupons && selectedCouponId !== 0
      ? addComma(service.service_price - couponPrice)
      : addComma(service.service_price)
    : 0

  const servicePriceBeforeLongTermDiscount =
    service?.total_week_count === 12 || service?.total_week_count === 24
      ? Math.floor(((service?.service_price ?? 0) * 100) / (service?.total_week_count === 12 ? 95 : 90) / 1000) * 1000
      : service?.service_price ?? 0

  const servicePriceAbout전담BeforeDiscount = Math.floor(((service?.service_price ?? 0) * 100) / 90 / 1000) * 1000
  const serviceDiscountPriceAbout전담 = Math.floor((servicePriceAbout전담BeforeDiscount * 10) / 100 / 1000) * 1000

  function checkInicisIsAppear() {
    const element = (
      document.getElementById('wrapperIFrame') as HTMLIFrameElement
    ).contentWindow?.document.getElementById('inicisModalDiv')
    if (!element) {
      setTimeout(checkInicisIsAppear, DELAY)
      return
    }
    checkIsPaymentEnd()
  }

  async function checkIsPaymentEnd() {
    const element = (
      document.getElementById('wrapperIFrame') as HTMLIFrameElement
    ).contentWindow?.document.getElementById('inicisModalDiv')

    if (!!element) {
      setTimeout(checkIsPaymentEnd, DELAY)
      return
    }

    setHtml('')
    if (service?.is_regular_payment) await onSubscribePayment(orderIdParam)
    else await onPayment(orderIdParam)
  }

  const handlePayment = () => {
    if (service?.is_regular_payment) callSubscribeKGInicis()
    else callKGInicis()
  }

  const callSubscribeKGInicis = () => {
    let popup: any
    let price = service?.service_price || 0

    if (point) {
      price -= parseInt(point.replace(/,/g, ''), 10)
    }
    let coupon
    if (selectedCouponId && coupons) {
      coupon = coupons.coupons.filter((coupon) => coupon.id === selectedCouponId)[0]
      if (coupon.type === 'DISCOUNT') {
        if (coupon.discount_rate) {
          const totalPrice = service!.service_price
          const discountRate = (totalPrice * coupon.discount_rate) / 100
          price -= discountRate
        } else if (coupon.discount_price) {
          price -= coupon.discount_price
        }
      }
    }

    if (totalPrice) {
      const priceNum = Number(totalPrice.replace(/,/g, ''))
      price = priceNum
    }

    LogManager.Instance.sendLog(
      'add_payment_info',
      {
        user_id: auth?.id.toString() || 'NULL',
        store_id: service?.store_id || 'NULL',
        service_id: service?.id || 'NULL',
        point: parseInt(point.replace(/,/g, ''), 10),
        coupon: coupon?.name || 'NULL',
        value: price,
        currency: 'KRW',
      },
      LOGGER.GTM
    )

    // 0원 결제시 팝업 띄우지 않게 수정
    if (window.screen.width <= mobilePivotScreenWidth && price > 0) {
      popup = window.open('about:blank')
    }

    callSubscribeKGInicisView().then((data) => {
      if (!data) return

      if (window.screen.width <= mobilePivotScreenWidth) {
        if (popup) {
          popup.document.write(data)
        }
      } else {
        setHtml(data)
        checkInicisIsAppear()
      }
    })
  }

  const callSubscribeKGInicisView = async () => {
    if (!bvs) return
    if (inicisLoading) return

    setInicisLoading(true)
    await checkOverlapContractDate()

    try {
      const data = await mutateBillingKeyMutation({
        name: userName,
        phone: userPhone,
        service_id: service?.id as string,
        type: 'ALL',
        point: isPointAvailable ? parseInt(point, 10) : 0,
        coupon_id: selectedCouponId === 0 ? undefined : selectedCouponId,
        mobile: window.screen.width < mobilePivotScreenWidth ? 'CARD' : undefined,
      })

      orderIdParam = getOrderId(data)

      setPaymentMethod(null)
      setInicisLoading(false)

      return data
    } catch (e) {
      toast.open('결제에 실패했습니다.')
    }
  }

  function callKGInicis() {
    let popup: any
    let price = service?.service_price || 0

    if (point) {
      price -= parseInt(point.replace(/,/g, ''), 10)
    }
    let coupon
    if (selectedCouponId && coupons) {
      coupon = coupons.coupons.filter((coupon) => coupon.id === selectedCouponId)[0]
      if (coupon.type === 'DISCOUNT') {
        if (coupon.discount_rate) {
          const totalPrice = service!.service_price
          const discountRate = (totalPrice * coupon.discount_rate) / 100
          price -= discountRate
        } else if (coupon.discount_price) {
          price -= coupon.discount_price
        }
      }
    }

    if (totalPrice) {
      const priceNum = Number(totalPrice.replace(/,/g, ''))
      price = priceNum
    }

    LogManager.Instance.sendLog(
      'add_payment_info',
      {
        user_id: auth?.id.toString() || 'NULL',
        store_id: service?.store_id || 'NULL',
        service_id: service?.id || 'NULL',
        point: parseInt(point.replace(/,/g, ''), 10),
        coupon: coupon?.name || 'NULL',
        value: price,
        currency: 'KRW',
      },
      LOGGER.GTM
    )

    if (paymentMethodOpen) {
      setPaymentMethodOpen(false)
    }

    // 0원 결제시 팝업 띄우지 않게 수정
    if (window.screen.width <= mobilePivotScreenWidth && price > 0) {
      popup = window.open('about:blank')
    }

    callKGInicisView().then((data) => {
      if (!data) return
      if (window.screen.width <= mobilePivotScreenWidth) {
        if (popup) {
          popup.document.write(data)
        }
      } else {
        setHtml(data)
        checkInicisIsAppear()
      }
    })
  }

  async function callKGInicisView() {
    if (!bvs) return
    if (inicisLoading) return

    setInicisLoading(true)
    await checkOverlapContractDate()

    const { data } = await axios.post('/payment-api/v1/payment/kg-inicis', {
      name: userName,
      phone: userPhone,
      service_id: service?.id,
      mail: null,
      point: isPointAvailable ? parseInt(point, 10) : 0,
      type: 'ALL',
      coupon_id: selectedCouponId === 0 ? undefined : selectedCouponId,
      mobile: window.screen.width < mobilePivotScreenWidth ? paymentMethod : '',
    })

    if (data.order_id) {
      if (service?.is_regular_payment) await onSubscribePayment(orderIdParam)
      else await onPayment(data.order_id)
      return
    }

    orderIdParam = getOrderId(data)

    setPaymentMethod(null)
    setInicisLoading(false)
    return data
  }

  const checkOverlapContractDate = async () => {
    const selectedDateArr = bvs.reservation_dates.map((date) => timezoneToSelectedDate(date))

    // 결제 시점에 겹치는 날이 없는지 체킹
    const firstContractedDate = await getContractedDate({ year: firstDate.year, month: firstDate.month })

    if (firstContractedDate) {
      const contractedDateArr = firstContractedDate.dates.map((date) => timezoneToSelectedDate(date))
      const overlapDateArr = filteringOverlapDate([...selectedDateArr], contractedDateArr)
      if (isLimitOverlap(overlapDateArr)) return setOverlapModalOpen(true)
    }

    if (isTwoMonth) {
      const secondContractedDate = await getContractedDate({ year: nextYear, month: nextMonth })

      if (secondContractedDate) {
        const contractedDateArr = secondContractedDate.dates.map((date) => timezoneToSelectedDate(date))
        const overlapDateArr = filteringOverlapDate([...selectedDateArr], contractedDateArr)
        if (isLimitOverlap(overlapDateArr)) return setOverlapModalOpen(true)
      }
    }
  }

  const onOverlapConfirm = async () => {
    setOverlapConfirmLoading(true)

    const deletedService = await mutateDeleteService({ id: service?.id as string })

    setOverlapConfirmLoading(false)
    setOverlapModalOpen(false)

    history.push(`/service-post/${deletedService.store_id}/basic`)
  }

  const onPreviousStepClick = async () => {
    setPreviousConfirmLoading(true)

    try {
      const deletedService = await mutateDeleteService({ id: service?.id as string })
      dispatch(initServiceState())

      setPreviousConfirmLoading(false)
      setPreviousModalOpen(false)

      history.push(`/service-post/${deletedService.store_id}/basic`)
    } catch (err) {}
  }

  const handleServiceDeleteClick = () => {
    if (isMobile) {
      setOpenContractCancelBottomSheet(true)
    } else {
      setOpenContractCancelModal(true)
    }

    LogManager.Instance.sendLog('store_service_payment_delete', {
      btn_name: '해지',
      btn_url: 'NULL',
    })
  }

  return (
    <StyledPaymentForm>
      <PaymentInfo>
        <PaymentInfoTitle>
          <h3>결제 정보</h3>
        </PaymentInfoTitle>

        <ConfirmSection>
          <div>
            <TitleWrap>
              <SubTitle>{serviceText}</SubTitle>
              {service?.is_regular_payment && <ServiceBadge badgeType="SUBSCRIBE" />}
            </TitleWrap>
            <BvsViewer
              isSubscribePayment={service?.is_regular_payment}
              firstDate={firstDate as SelectedDateType}
              totalReservationDate={totalReservationDate}
              extraDates={extraReservationDate}
            />
          </div>

          <div>
            <CCLViewController onClick={() => setIsCCLOpen(!isCCLOpen)} isCCLOpen={isCCLOpen}>
              <p>신청서 보기</p>
              <Icon name="blackDownArrow" />
            </CCLViewController>
            <CCLViewer isOpen={isCCLOpen} onClick={(e) => e.stopPropagation()}>
              <li>
                <p>1. 매장 주소</p>
                <p>
                  {store?.address_name} {store?.detail_address}
                </p>
              </li>
              <li>
                <p>2. 매장명</p>
                <p>{store?.store_name}</p>
              </li>
              <li>
                <p>3. 무인매장 유형</p>
                <p>{store?.type}</p>
              </li>
              <li>
                <p>4. 관리 일정</p>
                <p>{serviceText}</p>
              </li>
              <li>
                <p>5. 첫 관리 시작일</p>
                <p>
                  {firstDate.year}.{firstDate.month}.{firstDate.date}
                </p>
              </li>
              <li>
                <p>6. 첫 관리 시작 시간</p>
                <p>{firstDate.time}</p>
              </li>
            </CCLViewer>
          </div>
        </ConfirmSection>
        <FirstContainer isCCLOpen={isCCLOpen}>
          <BenefitSection
            total_week_count={service?.total_week_count}
            weekly_count={service?.weekly_count}
            address_name={store?.address_name}
            servicePrice={service?.service_price ?? 0}
            totalPoints={myPoints?.owned_points as number}
            point={point}
            setPoint={setPoint}
            isCouponOpen={isCouponOpen}
            setIsCouponOpen={setIsCouponOpen}
            coupons={coupons}
            selectedCouponId={selectedCouponId}
            setSelectedCouponId={setSelectedCouponId}
            limitPoint={service && (couponPrice ? service.service_price - couponPrice : service.service_price)}
          />

          <PolicySection
            isCouponOpen={isCouponOpen}
            isPolicyOpen={isPolicyOpen}
            setIsPolicyOpen={setIsPolicyOpen}
            couponCount={coupons?.count}
          />

          <SecondContainer isCouponOpen={isCouponOpen} isPolicyOpen={isPolicyOpen} couponCount={coupons?.count}>
            <MobilePayment>
              <SubTitle>결제 금액</SubTitle>
              <PaymentDetail>
                <span>
                  서비스 이용료 ({service?.service_time_minute ?? 60}분{store?.type === '탁구발전소24' && ' + 10분'})
                </span>
                {
                  <span>
                    {addComma(
                      store?.type === '전담게이트' || store?.type === '전담플레이'
                        ? servicePriceAbout전담BeforeDiscount
                        : servicePriceBeforeLongTermDiscount
                    )}{' '}
                    원
                  </span>
                }
              </PaymentDetail>

              {service && (store?.type === '전담게이트' || store?.type === '전담플레이') && (
                <PaymentDetail>
                  <span>{store?.type === '전담게이트' ? `전담 게이트` : `전담 플레이`} 10% 할인</span>
                  <span>-{addComma(serviceDiscountPriceAbout전담)}원</span>
                </PaymentDetail>
              )}

              {(service?.total_week_count === 12 || service?.total_week_count === 24) && (
                <PaymentDetail>
                  <span>{service?.total_week_count}주 결제 할인</span>
                  <span>
                    -
                    {service.total_week_count === 12
                      ? addComma(Math.floor((servicePriceBeforeLongTermDiscount * 5) / 100 / 1000) * 1000)
                      : addComma(Math.floor((servicePriceBeforeLongTermDiscount * 10) / 100 / 1000) * 1000)}{' '}
                    원
                  </span>
                </PaymentDetail>
              )}

              <PaymentDetail>
                <span>포인트 적용</span>
                <span>{isPointAvailable ? (point === '0' ? 0 : `-${addComma(point)}`) : 0} 원</span>
              </PaymentDetail>
              <PaymentDetail>
                <span>쿠폰 적용</span>
                <span>{coupons && selectedCouponId !== 0 ? `-${addComma(couponPrice)}` : 0} 원</span>
              </PaymentDetail>
              <hr />

              <TotalPayment>
                <span>총 금액</span>
                <span>{totalPrice} 원</span>
              </TotalPayment>

              <Button
                buttonTheme={!isSubscribePaymentOccurred ? 'primary' : 'disabled'}
                width={312}
                onClick={() => {
                  totalPrice === '0' || service?.is_regular_payment ? handlePayment() : setPaymentMethodOpen(true)
                }}
                disabled={isSubscribePaymentOccurred}
              >
                {inicisLoading ? <Spinner color="white" /> : '결제'}
              </Button>
              <Button buttonTheme="secondary" width={312} onClick={() => setPreviousModalOpen(true)}>
                신청 정보 변경
              </Button>
              <ServiceDeleteButton onClick={handleServiceDeleteClick}>계약 해지</ServiceDeleteButton>
            </MobilePayment>
          </SecondContainer>
        </FirstContainer>
      </PaymentInfo>

      <Payment>
        <SubTitle>결제 금액</SubTitle>
        <PaymentDetail>
          <span>
            서비스 이용료 ({service?.service_time_minute ?? 60}분{store?.type === '탁구발전소24' && ' + 10분'})
          </span>
          {
            <span>
              {addComma(
                store?.type === '전담게이트' || store?.type === '전담플레이'
                  ? servicePriceAbout전담BeforeDiscount
                  : servicePriceBeforeLongTermDiscount
              )}{' '}
              원
            </span>
          }
        </PaymentDetail>

        {service && (store?.type === '전담게이트' || store?.type === '전담플레이') && (
          <PaymentDetail>
            <span>{store?.type === '전담게이트' ? `전담 게이트` : `전담 플레이`} 10% 할인</span>
            <span>-{addComma(serviceDiscountPriceAbout전담)}원</span>
          </PaymentDetail>
        )}

        {(service?.total_week_count === 12 || service?.total_week_count === 24) && (
          <PaymentDetail>
            <span>{service?.total_week_count}주 결제 할인</span>
            <span>
              -
              {service.total_week_count === 12
                ? addComma(Math.floor((servicePriceBeforeLongTermDiscount * 5) / 100 / 1000) * 1000)
                : addComma(Math.floor((servicePriceBeforeLongTermDiscount * 10) / 100 / 1000) * 1000)}{' '}
              원
            </span>
          </PaymentDetail>
        )}

        <PaymentDetail>
          <span>포인트 적용</span>
          <span>{isPointAvailable ? (point === '0' ? 0 : `-${addComma(point)}`) : 0} 원</span>
        </PaymentDetail>
        <PaymentDetail>
          <span>쿠폰 적용</span>
          <span>{coupons && selectedCouponId !== 0 ? `-${addComma(couponPrice)}` : 0} 원</span>
        </PaymentDetail>
        <hr />

        <TotalPayment>
          <span>총 금액</span>
          <span>{totalPrice} 원</span>
        </TotalPayment>

        <Button
          buttonTheme={!isSubscribePaymentOccurred ? 'primary' : 'disabled'}
          width={340}
          onClick={handlePayment}
          disabled={isSubscribePaymentOccurred}
        >
          {inicisLoading ? <Spinner color="white" /> : '결제'}
        </Button>
        <ButtonContainer>
          <Button buttonTheme="secondary" width={340} onClick={() => setPreviousModalOpen(true)}>
            신청 정보 변경
          </Button>
        </ButtonContainer>
        <ServiceDeleteButton onClick={handleServiceDeleteClick}>계약 해지</ServiceDeleteButton>
      </Payment>

      <IFrameKGInicis source={html} />

      <ConfirmModal
        open={previousModalOpen}
        title="신청 정보 변경 안내"
        headerColor="warning"
        headline="정보를 변경하려면 초기화된 일정을 다시 선택해야 합니다."
        closeText="취소"
        confirmText="확인"
        onClose={() => setPreviousModalOpen(false)}
        onConfirm={onPreviousStepClick}
        isConfirmLoading={previousConfirmLoading}
      />

      <AlertModal
        open={overlapModalOpen}
        title="예약일 변경 요청"
        headerColor="warning"
        headline="일정을 선택하는 중에 이미 예약된 날짜가 있습니다."
        subline="일정을 다시 선택해 주세요."
        onConfirm={onOverlapConfirm}
        isConfirmLoading={overlapConfirmLoading}
      />

      <AlertModal
        open={paymentMethodOpen}
        title="결제 수단 선택"
        subline="선택하고 확인 버튼을 눌러 주세요"
        onConfirm={handlePayment}
        onClose={() => {
          setPaymentMethod(null)
          setPaymentMethodOpen(false)
        }}
        disabled={paymentMethod === null}
      >
        <PaymentMethodList paymentMethod={paymentMethod} onListItemClick={(data) => setPaymentMethod(data)} />
      </AlertModal>

      <AlertModal
        open={openInvalidFirstDateModal}
        title="첫 방문일 만료"
        subline="선택 하신 첫 방문일이 만료되었습니다. 일정을 다시 선택해 주세요."
        onConfirm={async () => {
          const deletedService = await mutateDeleteService({ id: service?.id as string })
          setOpenInvalidFirstDateModal(false)
          history.push(`/service-post/${deletedService.store_id}/basic`)
        }}
      />

      <ServiceCancelModal
        open={openContractCancelModal}
        onClose={() => setOpenContractCancelModal(false)}
        serviceId={serviceId}
      />
      <ServiceCancelBottomSheet
        open={openContractCancelBottomSheet}
        onClose={() => setOpenContractCancelBottomSheet(false)}
        serviceId={serviceId}
      />
    </StyledPaymentForm>
  )
}

const StyledPaymentForm = styled.div`
  width: 90.4rem;
  display: flex;
  justify-content: space-between;
  /* height: 400vh; */
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
    width: 312px;
    align-items: center;
    justify-content: flex-start;
  `}
`

const PaymentInfo = styled.div`
  width: 50rem;
  border-radius: 1.6rem;
  background-color: ${({ theme }) => theme.colors.white};
  height: fit-content;

  ${({ theme }) => theme.media.mobile`
    width: 312px;
  `}
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`

const PaymentInfoTitle = styled.div`
  width: 100%;
  height: 8rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-bottom: none;
  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem;

  & > h3 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.8rem;
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.media.mobile`
    height: 48px;
    
    & > h3 {
      font-size: 16px;
      line-height: 24px;
    }
  `}
`

const Payment = styled.div`
  width: 34rem;
  position: sticky;
  position: -webkit-sticky;
  top: 10rem;
  height: 50rem;

  & > hr {
    height: 0.1rem;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
    border: none;
    margin: ${({ theme }) => theme.spacing(2, 0)};
  }

  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`

const MobilePayment = styled(Payment)`
  display: none;
  width: 312px;

  ${({ theme }) => theme.media.mobile`
    display: block;

    & > button {
      margin-top: 16px;
    }
  `}
`

const PaymentDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & ~ & {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  & > span:first-child {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.5;
  }

  & > span:last-child {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme }) => theme.colors.black};

    &.down_payment {
      text-decoration: line-through;
      color: ${({ theme }) => theme.colors.gray};
    }
  }

  ${({ theme }) => theme.media.mobile`
    & > span:last-child {
      font-size: 14px;
      line-height: 20px;
    }
  `}
`

const TotalPayment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(7)};

  & > span:first-child {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.black};
  }

  & > span:last-child {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.8rem;
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.media.mobile`
    margin-bottom: 32px;

    & > span:last-child {
      font-size: 16px;
      line-height: 24px;
    }

    & ~ button {
      width: 312px;
    }
  `}
`

const ConfirmSection = styled.section`
  width: 50rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-top: none;
  border-bottom: none;
  padding-top: ${({ theme }) => theme.spacing(4)};

  h4 {
    margin-top: 0;
  }

  & > div {
    padding: ${({ theme }) => theme.spacing(0, 4)};
    background-color: white;
  }

  & > div:nth-child(2) {
    padding: 0;
    & > div {
      padding: ${({ theme }) => theme.spacing(0, 4)};
      padding-top: ${({ theme }) => theme.spacing(4)};
      padding-bottom: ${({ theme }) => theme.spacing(4)};
    }
  }

  ${({ theme }) => theme.media.mobile`
    width: 312px;
    & > div {
      padding: 0 16px;
    }

    & > div:nth-child(2) {
      padding: 0;
      & > div {
        padding: 16px;
      }
    }
  `}
`

export const CCLViewController = styled.div<{ isCCLOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4, 0)};
  cursor: pointer;

  & > p {
    font-weight: 400;
    line-height: 2rem;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.black};
  }

  & > svg {
    transition: transform 0.3s ease-in-out;
  }

  ${({ isCCLOpen }) =>
    isCCLOpen
      ? css`
          & > svg {
            transform: rotate(180deg);
          }
        `
      : css`
          & > svg {
            transform: rotate(0);
          }
        `}
`

export const CCLViewer = styled.ul<{ isOpen?: boolean }>`
  height: 0;
  background-color: ${({ theme }) => theme.colors.lightGray};
  transition: height 0.3s ease-in-out;
  overflow-y: auto;
  cursor: default;

  & > li {
    margin-bottom: ${({ theme }) => theme.spacing(2)};

    & > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
      word-break: keep-all;
    }

    & > p:last-child {
      margin-left: ${({ theme }) => theme.spacing(2)};
      font-weight: 700;
      color: var(--brand300);
    }
  }

  & > li:last-child {
    margin-bottom: 0;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          height: auto;
          padding: ${({ theme }) => theme.spacing(4)};
          ${({ theme }) => theme.media.mobile`padding: 3.2rem !important`};

          /* transform: translateY(33.6rem); */
        `
      : css`
          /* transform: translateY(0); */
        `}

  ${({ theme }) => theme.media.mobile`
    width: 312px;
    padding: 0;
    border-right: 1px solid;

    & > li {
      & > p {
        line-height: 20px;
      }
    }
  `}

  ${({ theme }) => theme.media.mobile`border-color: ${theme.colors.lightGrayishBlue}`}
`

const FirstContainer = styled.div<{ isCCLOpen: boolean }>`
  transition: transform 0.3s ease-in-out;

  ${({ isCCLOpen }) =>
    isCCLOpen
      ? css`
          /* transform: translateY(33.6rem); */
        `
      : css`
          /* transform: translateY(0); */
        `}
`

const SecondContainer = styled.div<{ isPolicyOpen: boolean; isCouponOpen: boolean; couponCount?: number }>`
  transition: transform 0.3s ease-in-out;

  ${({ isPolicyOpen, isCouponOpen, couponCount }) =>
    isPolicyOpen
      ? isCouponOpen
        ? couponCount &&
          (couponCount === 1
            ? css`
                /* transform: translateY(65.3rem); */
              `
            : css`
                /* transform: translateY(${51.1 + 6.1 + 8 * couponCount + (couponCount - 1) * 0.8}rem); */
              `)
        : css`
            /* transform: translateY(51.1rem); */
          `
      : isCouponOpen
      ? couponCount &&
        (couponCount === 1
          ? css`
              /* transform: translateY(14.1rem); */
            `
          : css`
              /* transform: translateY(${6.1 + 8 * couponCount + (couponCount - 1) * 0.8}rem); */
            `)
      : css`
          /* transform: translateY(-0.1rem); */
        `};

  @media only screen and (max-width: 768px) {
    ${({ isPolicyOpen, isCouponOpen, couponCount }) =>
      isPolicyOpen
        ? isCouponOpen
          ? couponCount &&
            (couponCount === 1
              ? css`
                  /* transform: translateY(62.1rem); */
                `
              : css`
                  /* transform: translateY(${51.2 + 2.9 + 8 * couponCount + (couponCount - 1) * 0.8}rem); */
                `)
          : css`
              /* transform: translateY(51.2rem); */
            `
        : isCouponOpen
        ? couponCount &&
          (couponCount === 1
            ? css`
                /* transform: translateY(10.9rem); */
              `
            : css`
                /* transform: translateY(${2.9 + 8 * couponCount + (couponCount - 1) * 0.8}rem); */
              `)
        : css`
            /* transform: translateY(0rem); */
          `};
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;

  & > h4 {
    margin-bottom: 0px;
    margin-right: 8px;
  }

  margin-bottom: 32px;
`

const ServiceDeleteButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--gray-gray-600, #757575);
  font-size: 14px;
  text-decoration-line: underline;
  margin-top: 24px;
  cursor: pointer;
`
