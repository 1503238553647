import styled from 'styled-components'
import Button from '../../common/Button'
import Icon from '../../Icon'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { ROUTES } from 'utils/common/routes'

export default function EmailFindInfo() {
  const { masking_email } = useParams<{ masking_email: string }>()
  const history = useHistory()
  const onClickButtonHandler = () => {
    history.push(ROUTES.AUTH_LOGIN)
  }
  return (
    <StyledPasswordReset>
      <Icon name="emojiLetter" />
      <Info>
        가입하신 메일은
        <br />
        <BlueBold>{masking_email}</BlueBold> 입니다.
      </Info>
      <MoveButton buttonTheme="primary" width={320} onClick={onClickButtonHandler}>
        로그인 하러가기
      </MoveButton>
    </StyledPasswordReset>
  )
}

const StyledPasswordReset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(6)};
`

const Info = styled.span`
  margin: ${({ theme }) => theme.spacing(3, 0)};
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`
const BlueBold = styled.span`
  color: ${({ theme }) => theme.colors.indigoA700};
`

const MoveButton = styled(Button)`
  ${({ theme }) => theme.media.mobile`
min-width: 26.4rem;
height: 4.8rem;
`}
`
