/**
 * 함수설명: 핸드폰번호에 자동으로 하이픈을 붙여주는 함수
 * params: phone(핸드폰번호)
 */

export const phoneHyphen = (phone: string) => {
  let formatNum = ''

  if (phone.length === 11) {
    formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
  } else if (phone.length === 8) {
    formatNum = phone.replace(/(\d{4})(\d{4})/, '$1-$2')
  } else {
    if (phone.indexOf('02') === 0) {
      formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
    } else {
      formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    }
  }

  return formatNum
}
