import { Top, TypoP1 } from 'ohds-web'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'

import ServiceWeekSelectBox from 'components/ServicePostPage/BasicInfoPage/ServiceWeekSelectBox'
import ServiceDaySelectBox from 'components/ServicePostPage/BasicInfoPage/ServiceDaySelectBox'
import ServiceHourSelectBox from 'components/ServicePostPage/BasicInfoPage/ServiceHourSelectBox'
import ServiceInfo from 'components/ServicePostPage/BasicInfoPage/ServiceInfo'
import BasicInfoBottomCTA from 'components/ServicePostPage/BasicInfoPage/BasicInfoBottomCTA'

import {
  dayArr,
  selectServiceDayDisplayString,
  serviceHourOptions,
  selectServiceWeeklyCount,
  serviceWeekOptions,
  selectTotalServiceCount,
  serviceTimeMinuteOptions,
  setServiceDayValues,
  selectServiceDayCount,
  serviceDayValueOptions,
  setServiceWeek,
  setServiceTimeMinute,
} from 'modules/features/service/serviceSlice'

import addComma from 'utils/common/addComma'
import styled from 'styled-components'
import BrwnieMainContainer from 'components/common/BrwnieMainContainer'
import { useEffect, useState } from 'react'
import { ServiceCountSelectBox } from 'components/ServicePostPage/BasicInfoPage/ServiceCountSelectBox'
import useStoreQuery from 'hooks/query/store/useStoreQuery'
import { useParams } from 'react-router-dom'
import ServiceTimeMinuteSelectBox from 'components/ServicePostPage/BasicInfoPage/ServiceTimeMinuteSelectBox'
import { useServicePrice } from 'hooks/useServicePrice'

const serviceCountOptionsFor1Week = [2, 3, 4, 5]
const serviceCountOptions = [1, 2, 3, 4, 5, 6, 7]
const serviceCountOptionsFor탁구발전소24 = [2, 3, 4, 5]

export default function BasicInfoPage() {
  const { storeId } = useParams<{ storeId: string }>()

  const { data: store } = useStoreQuery({ id: storeId })
  const { getServicePrice } = useServicePrice()

  const dispatch = useAppDispatch()

  const { serviceWeek, serviceHour, serviceTimeMinute, serviceDayValues } = useAppSelector((state) => state.service)

  const { weekdayCount, weekendCount } = useAppSelector(selectServiceDayCount)

  const serviceDayDisplayString = useAppSelector(selectServiceDayDisplayString)

  const serviceWeeklyCount = useAppSelector(selectServiceWeeklyCount)
  const totalServiceCount = useAppSelector(selectTotalServiceCount)

  const [serviceCount, setServiceCount] = useState<number>(serviceDayValues.length)

  const isServiceWeekSelected = serviceWeek !== undefined
  const isServiceCountSelected = serviceCount !== 0
  const isServiceDayValuesSelected = serviceWeeklyCount !== 0
  const isServiceHourSelected = serviceHour !== undefined
  const isServiceTimeMinuteSelected = serviceTimeMinute !== undefined

  const isAllSelected =
    isServiceWeekSelected &&
    isServiceCountSelected &&
    isServiceDayValuesSelected &&
    isServiceHourSelected &&
    (store?.type === '전담게이트' || store?.type === '전담플레이' || store?.type === '스터디카페' || store?.type === '탁구발전소24'
      ? true
      : isServiceTimeMinuteSelected)

  const [totalPrice, setTotalPrice] = useState<number>(0)

  useEffect(() => {
    if (isAllSelected && store) {
      const totalPrice = getServicePrice({
        serviceWeek,
        weekdayCount,
        weekendCount,
        serviceMinute: serviceTimeMinute as number,
        storeType: store?.type === '전담게이트'
                    ? '전담게이트'
                    : store?.type === '전담플레이'
                      ? '전담플레이'
                      : undefined,
      })

      setTotalPrice(totalPrice)
    }
  }, [isAllSelected, serviceTimeMinute, serviceWeek, store, weekdayCount, weekendCount, getServicePrice])

  useEffect(() => {
    if (store?.type === '전담게이트' || store?.type === '전담플레이' || store?.type === '탁구발전소24') {
      dispatch(setServiceWeek(4))
    }
  }, [dispatch, store])

  // 스터디 카페이거나 전담게이트, 전담플레이는 1주 패키지 선택 못하게
  const validServiceWeekOptions =
    store?.type === '스터디카페'
      ? serviceWeekOptions.filter((week) => week !== 1)
      : store?.type === '전담게이트' || store?.type === '전담플레이' || store?.type === '탁구발전소24'
        ? ([4] as const)
        : serviceWeekOptions

  const validServiceTimeMinuteOptions =
    store?.type === '스터디카페' ? serviceTimeMinuteOptions.filter((option) => option !== 60) : serviceTimeMinuteOptions

  if (!store) return null

  return (
    <StyledMainContainer>
      <Top firstTitle="관리 일정을" secondTitle="선택해 주세요" firstTitleBrand paddingTop={40} paddingBottom={32} />

      <ServiceWeekSelectBox
        autoOpen={store?.type !== '전담게이트' && store?.type !== '전담플레이' && store.type !== '탁구발전소24' && !isServiceWeekSelected}
        label="관리 기간"
        value={serviceWeek ? `${serviceWeek}주` : undefined}
        serviceWeekOptions={validServiceWeekOptions}
      />
      <ServiceCountSelectBox
        autoOpen={!isServiceCountSelected && isServiceWeekSelected}
        label="관리 주기"
        value={serviceCount}
        for1Week={serviceWeek === 1}
        for탁구발전소24={store.type === '탁구발전소24'}
        serviceCountOptions={
          serviceWeek === 1
            ? serviceCountOptionsFor1Week
            : // 스터디카페, 전담게이트, 전담플레이는 주 1회 불가능하게
            store?.type === '스터디카페' || store?.type === '전담게이트' || store?.type === '전담플레이'
            ? serviceCountOptions.filter((serviceCount) => serviceCount !== 1)
            : store.type === '탁구발전소24'
            ? serviceCountOptionsFor탁구발전소24
            : serviceCountOptions
        }
        storeType={store.type}
        onOptionClick={(serviceCount) => {
          setServiceCount(serviceCount)

          const serviceTimeMinuteFor전담 = serviceCount >= 5 ? 60 : 90
          if (store.type === '전담게이트' || store.type === '전담플레이') dispatch(setServiceTimeMinute(serviceTimeMinuteFor전담))
          if (store.type === '스터디카페') dispatch(setServiceTimeMinute(90))
          if (store.type === '탁구발전소24') dispatch(setServiceTimeMinute(40))

          if (serviceCount === 7) {
            dispatch(setServiceDayValues([...serviceDayValueOptions]))
          } else {
            dispatch(setServiceDayValues([]))
          }
        }}
      />
      {serviceCount !== 7 && (
        <ServiceDaySelectBox
          autoOpen={!isServiceDayValuesSelected && isServiceCountSelected}
          label="관리 요일"
          value={serviceDayDisplayString}
          for1Week={serviceWeek === 1}
          serviceWeek={serviceWeek}
          serviceCount={serviceCount}
          dayOptions={dayArr.slice(
            0,
            store.type === '탁구발전소24' || serviceWeek === 1 || serviceCount === 1 || serviceCount === 2 ? 5 : 7
          )}
        />
      )}
      <ServiceHourSelectBox
        autoOpen={
          serviceCount === 7
            ? !isServiceHourSelected && isServiceCountSelected
            : !isServiceHourSelected && isServiceDayValuesSelected
        }
        label="관리 시작 시간"
        value={serviceHour ? `${serviceHour}시` : undefined}
        serviceHourOptions={serviceHourOptions}
        storeType={store?.type as string}
      />

      {!(store.type === '전담게이트' || store.type === '전담플레이' || store.type === '스터디카페' || store.type === '탁구발전소24') && (
        <ServiceTimeMinuteSelectBox
          autoOpen={!isServiceTimeMinuteSelected && isServiceHourSelected}
          label="관리 시간"
          value={serviceTimeMinute ? `${serviceTimeMinute}분` : undefined}
          serviceTimeMinuteOptions={validServiceTimeMinuteOptions}
        />
      )}

      {isAllSelected && (
        <ServiceInfo
          InfoTypo={
            <>
              <div style={{ textAlign: 'center' }}>
                <TypoP1 fontWeight="medium" display="inline" text="관리 총 횟수는" />
                <TypoP1 fontWeight="bold" display="inline" text={` ${totalServiceCount}회,`} color="brand300" />
                <br />
                <TypoP1 fontWeight="medium" display="inline" text="이용료는" />
                <TypoP1 fontWeight="bold" display="inline" text={` ${addComma(totalPrice)}원`} color="brand300" />
                <TypoP1 fontWeight="medium" display="inline" text=" (부가세 포함) 입니다." />
              </div>

              {store?.type === '탁구발전소24' && <DiscountBadge>탁구발전소24 특별 할인 적용됨</DiscountBadge>}
              {store?.type === '전담게이트' && <DiscountBadge>전담게이트 특별 10% 할인 적용됨</DiscountBadge>}
              {store?.type === '전담플레이' && <DiscountBadge>전담플레이 특별 10% 할인 적용됨</DiscountBadge>}
              {serviceWeek === 12 && <DiscountBadge>{serviceWeek}주 결제 5% 할인</DiscountBadge>}
              {serviceWeek === 24 && <DiscountBadge>{serviceWeek}주 결제 10% 할인</DiscountBadge>}
            </>
          }
        />
      )}

      <BasicInfoBottomCTA linear label="다음" servicePrice={totalPrice} disabled={!isAllSelected || totalPrice === 0} />
    </StyledMainContainer>
  )
}

const StyledMainContainer = styled(BrwnieMainContainer)`
  height: calc(100vh + 16px);

  @media only screen and (min-width: 768px) {
    height: calc(100vh - 120px - 56px);
  }
`

const DiscountBadge = styled.span`
  display: block;
  padding: 0px 6px;
  border-radius: 4px;
  height: 22px;
  background-color: var(--brand100);
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: var(--brand400);
`
