import {
  ScheduleType,
  SchedulesType,
  ServiceDayValueType,
  ServiceHourType,
} from 'modules/features/service/serviceSlice'
import { calcTotalDate, checkIsHoliday, getDateFormat, getDay } from './calendar'
import twoDigitsNumConverter from './twoDigitsNumConverter'
import { TimeSelectionType } from './time'
import serviceData from 'data/serviceData.json'

export const convertScheduleToTimezone = ({
  year,
  month,
  date,
  hour,
}: {
  year: number
  month: number
  date: number
  hour: ServiceHourType
}) => {
  return `${year}-${twoDigitsNumConverter(month)}-${twoDigitsNumConverter(date)}T${twoDigitsNumConverter(
    hour - 9
  )}:00:00.000Z`
}

export const createDateArr = (year: number, month: number) => {
  const yearMonthString = `${year}-${twoDigitsNumConverter(month)}`
  const totalDate = calcTotalDate(year, month)

  const firstDateDay = new Date(yearMonthString).getDay()
  const lastDateDay = new Date(`${yearMonthString}-${totalDate}`).getDay()

  const firstLineEmptyCount = firstDateDay === 0 ? 6 : firstDateDay - 1
  const lastLineEmptyCount = lastDateDay === 0 ? 0 : 7 - lastDateDay

  const dateArr = []

  for (let i = 0; i < firstLineEmptyCount; i++) dateArr.push(0)
  for (let i = 1; i <= totalDate; i++) dateArr.push(i)
  for (let i = 0; i < lastLineEmptyCount; i++) dateArr.push(0)

  return dateArr
}

export const correctSchedule = (
  year: number,
  month: number,
  uncorrectSchedule:
    | SchedulesType
    | {
        year: number
        month: number
        date: number
      }[]
) => {
  return uncorrectSchedule.map((schedule) => {
    const totalDate = calcTotalDate(year, month)
    if (schedule.date > totalDate) {
      schedule.date -= totalDate

      if (schedule.month === 12) {
        schedule.month = 1
        schedule.year += 1
      } else {
        schedule.month += 1
      }
    }

    return schedule
  })
}

export const convertDateStringToSchedule = (dateString: string) => {
  const dateClass = new Date(dateString)

  return {
    year: dateClass.getFullYear(),
    month: dateClass.getMonth() + 1,
    date: dateClass.getDate(),
    hour: dateClass.getHours() as ServiceHourType,
  }
}

export const createWeekSchedules = (
  schedules: SchedulesType,
  scheduleInWeek: {
    year: number
    month: number
    date: number
  },
  serviceWeeklyCount: number
) => {
  const weekSchedules: {
    year: number
    month: number
    date: number
  }[] = []

  const { year: weekYear, month: weekMonth, date: weekDate } = scheduleInWeek

  const weekIndex = Math.floor(findSchedulesIndex(schedules, weekYear, weekMonth, weekDate) / serviceWeeklyCount)
  const { year, month, date } = schedules[serviceWeeklyCount * weekIndex]

  const { year: firstYear, month: firstMonth, date: firstDate } = schedules[0]

  const firstDay = getDay(firstYear, firstMonth, firstDate)
  const weekFirstDay = getDay(year, month, date)

  let startDate = date
  if (weekFirstDay >= firstDay) {
    startDate -= weekFirstDay - firstDay
  } else {
    startDate -= 7 - (firstDay - weekFirstDay)
  }

  for (let i = 0; i < 7; i++) {
    weekSchedules.push({
      year,
      month,
      date: startDate + i,
    })
  }

  return correctSchedule(year, month, weekSchedules)
}

export const createExtraWeekSchedules = (schedules: SchedulesType, serviceWeeklyCount: number) => {
  const extraWeekSchedules: {
    year: number
    month: number
    date: number
  }[] = []

  const { year, month, date } = schedules[schedules.length - serviceWeeklyCount]

  const { year: firstYear, month: firstMonth, date: firstDate } = schedules[0]

  const firstDay = getDay(firstYear, firstMonth, firstDate)
  const weekFirstDay = getDay(year, month, date)

  let startDate = date
  if (weekFirstDay >= firstDay) {
    startDate -= weekFirstDay - firstDay
  } else {
    startDate -= 7 - (firstDay - weekFirstDay)
  }

  for (let i = 0; i < 7; i++) {
    extraWeekSchedules.push({
      year,
      month,
      date: startDate + i + 7,
    })
  }

  return correctSchedule(year, month, extraWeekSchedules)
}

/**
 *
 */
export const addAlternateDay = (lastSchedule: ScheduleType, dayValues: ServiceDayValueType[], addDateCount: number) => {
  return Array.from({ length: addDateCount }, (_, index) => {
    const { year, month, date, hour } = lastSchedule
    const lastScheduleDate = new Date(year, month - 1, date)

    const currentSchedule = getNextDayWithWeekWithoutHoliday(lastScheduleDate, dayValues)
    lastSchedule = { ...currentSchedule, hour }

    return lastSchedule
  })
}

export const getNextDayWithWeekWithoutHoliday = (prevDate: Date, weekValue: ServiceDayValueType[]) => {
  let tmp = 1
  let isInvalideDate = true
  let currentDate = new Date(prevDate.setDate(prevDate.getDate() + tmp))

  while (isInvalideDate) {
    if (tmp !== 1) currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    const { year, month, date } = getDateFormat(currentDate)
    const day = (getDay(year, month, date) - 1) as ServiceDayValueType

    if (checkIsHoliday(year, month, date) || weekValue.indexOf(day) < 0) {
      tmp += 1
    } else {
      isInvalideDate = false
    }
  }

  return getDateFormat(currentDate)
}

export const findSchedulesIndex = (
  schedules:
    | SchedulesType
    | {
        year: number
        month: number
        date: number
      }[],
  year: number,
  month: number,
  date: number
) => {
  return schedules.findIndex((schedule) => schedule.year === year && schedule.month === month && schedule.date === date)
}

export const groupBys = (objectArray: { [key: string]: any }[], property: string): { [key: string]: any } => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    // Add object to list for given key's value
    acc[key].push(obj)

    return acc
  }, {})
}

export const filteringOverlapDate = (
  selectedDateArr: {
    year: number
    month: number
    date: number
    time: TimeSelectionType
  }[],
  contractedDateArr: {
    year: number
    month: number
    date: number
    time: TimeSelectionType
  }[]
) =>
  selectedDateArr?.filter(
    ({ year: selectedYear, month: selectedMonth, date: selectedDate, time: selectedTime }) =>
      !!contractedDateArr.find(
        ({ year: contractedYear, month: contractedMonth, date: contractedDate, time: contractedTime }) =>
          contractedYear === selectedYear &&
          contractedMonth === selectedMonth &&
          contractedDate === selectedDate &&
          contractedTime === selectedTime
      )
  )

export const isLimitOverlap = (
  overlapDateArr: {
    year: number
    month: number
    date: number
    time: TimeSelectionType
  }[]
) => {
  let overlapDateObj: any = {}

  overlapDateArr.forEach(({ year, month, date, time }) => {
    overlapDateObj[`${year} ${month} ${date} ${time}`] = (overlapDateObj[`${year} ${month} ${date} ${time}`] || 0) + 1
  })

  for (let key in overlapDateObj) {
    // 중복된 횟수
    const overlapCount = overlapDateObj[key]
    if (overlapCount === serviceData.dayContractLimit) return true
  }

  return false
}
