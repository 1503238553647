import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import GlobalStyle from './assets/styles/global-styles'
import { ThemeProvider } from 'styled-components'
import { theme } from './assets/styles/theme'
import { store } from 'modules'
import { Provider } from 'react-redux'

import LogManager from 'lib/utils/logger'
import KakaoLogger from 'lib/utils/logger/Kakao'
import NaverAdLogger from 'lib/utils/logger/NaverAD'
import GTMLogger from 'lib/utils/logger/GTM'

// Log Manager 설정
LogManager.Instance.attachLogger(new KakaoLogger())
LogManager.Instance.attachLogger(new NaverAdLogger())
LogManager.Instance.attachLogger(new GTMLogger())
localStorage.removeItem(`scrollData`)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
