import Icon from 'components/Icon'
import styled from 'styled-components'

export default function ServiceCancelNotice() {
  const SERVICE_NOTICE_URL = 'https://higherx.notion.site/2021-06-10-42f4b3c848ee4182978736e1aace21fd'

  return (
    <Container>
      <SubTitle>
        <Icon name="grayInfo" />꼭 읽어주세요!
      </SubTitle>
      <NoticeList>
        <NoticeItem>
          1. 해지하신 서비스는 복원되지 않아요. 같은 상품을 다시 계약하고 싶으시다면 처음부터 다시 신청해 주셔야 해요.
        </NoticeItem>
        <NoticeItem>
          2. 해지를 진행하면{' '}
          <a href={SERVICE_NOTICE_URL} target="_blank" rel="noreferrer">
            해지 약관
          </a>
          에 동의한 것으로 간주될 거예요.
        </NoticeItem>
        <NoticeItem>3. 이미 진행된 서비스는 정책에 따라 차액 환불 됩니다. </NoticeItem>
        <NoticeItem>4. 환불은 은행사 혹은 카드사의 상황에 따라 영업일 기준 1일 정도 소요될 수 있습니다. </NoticeItem>
      </NoticeList>
    </Container>
  )
}

const Container = styled.div``

const SubTitle = styled.p`
  display: flex;
  align-items: center;
  color: var(--gray-gray-600, #757575);
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;

  & > svg {
    margin-right: 4px;
  }
`

const NoticeList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  margin-top: 16px;
`

const NoticeItem = styled.li`
  color: var(--gray-gray-600, #757575);
  font-size: 16px;
  line-height: 28px;

  & > a {
    color: var(--gray-gray-600, #757575);
    cursor: pointer;
  }
`
