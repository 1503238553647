import React, { useRef, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<any>()
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay !== null) {
      let id = setInterval(() => savedCallback.current(), delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default function Slider() {
  const imageUrl = `/image/new_landing_first_section${isMobile ? '_mobile' : ''}`
  const items = [
    `${imageUrl}/first.webp`,
    `${imageUrl}/second.webp`,
    `${imageUrl}/third.webp`,
    `${imageUrl}/fourth.webp`,
    `${imageUrl}/fifth.webp`,
    `${imageUrl}/sixth.webp`,
    `${imageUrl}/seventh.webp`,
    `${imageUrl}/eighth.webp`,
  ]

  const itemSize = items.length
  const transitionStyle = `transform 1s ease-in-out 0s`
  const [currentIndex, setCurrentIndex] = useState(1)
  const [slideTransition, setTransition] = useState(transitionStyle)

  const createFirstEndItem = () => {
    let addedFront = []
    let addedLast = []
    let index = 0
    while (index < 1) {
      addedLast.push(items[index % items.length])
      addedFront.unshift(items[items.length - 1 - (index % items.length)])
      index++
    }
    return [...addedFront, ...items, ...addedLast]
  }

  let slides = createFirstEndItem()

  useInterval(() => {
    handleSlide(currentIndex + 1)
  }, 3000)

  //이미지 이동 속임수
  const goToFirstItem = (index: number) => {
    setTimeout(() => {
      setTransition('')
      setCurrentIndex(index)
    }, 1000)
  }

  function handleSlide(index: number) {
    setCurrentIndex(index)
    if (index - 1 < 0) {
      index += itemSize
      goToFirstItem(index)
    } else if (index - 1 >= itemSize) {
      index -= itemSize
      goToFirstItem(index)
    }
    setTransition(transitionStyle)
  }

  function getItemIndex(index: number) {
    index -= 1
    if (index < 0) {
      index += itemSize
    } else if (index >= itemSize) {
      index -= itemSize
    }
    return index
  }

  return (
    <ImageSliderWrapper>
      <div
        className="sliderTrack"
        style={{
          transform: `translateX(calc(${(-100 / slides.length) * currentIndex}% )`,
          transition: slideTransition,
        }}
      >
        {slides.map((slide, slideIndex) => {
          const itemIndex = getItemIndex(slideIndex)
          return <SliderItem className="test" key={slideIndex} src={items[itemIndex]} />
        })}
      </div>
    </ImageSliderWrapper>
  )
}

const ImageSliderWrapper = styled.div`
  position: absolute;
  height: 100vh;
  overflow: hidden;
  z-index: -1;

  .sliderTrack {
    height: 100vh;
    display: flex;
    flex-direction: row;
    text-align: left;
    width: fit-content;
  }

  @media only screen and (max-width: 768px) {
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
    height: calc(var(--aosvh, 1vh) * 100);

    .sliderTrack {
      height: 100%;
    }
  }
`

const SliderItem = styled.div<{ src: string }>`
  width: 100vw;
  height: 100vh;
  position: relative;

  background: linear-gradient(rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)), url(${({ src }) => src});
  background-size: cover;

  @media only screen and (max-width: 768px) {
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }

    height: calc(var(--aosvh, 1vh) * 100);

    background: linear-gradient(rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)), url(${({ src }) => src});
    background-size: cover;
  }
`
