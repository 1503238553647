import client from '../client'

export async function getResetPassword({ email, token }: ResetPasswordParams) {
  const response = await client.get<undefined>(`auth-api/v1/auth/reset/password/${email}/${token}`)

  return response.data
}

export type ResetPasswordParams = {
  email: string
  token: string
}
