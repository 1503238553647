import client from '../client'
import { ServiceType } from './types'

export async function getServices({ userId }: GetServicesParam) {
  const response = await client.get<ServiceType[]>(`/service-api/v1/service/user/${userId}`)

  return response.data
}

export type GetServicesParam = {
  userId: number
}
