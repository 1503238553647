import styled from 'styled-components'
import { ReactComponent as InterfaceCheckIcon } from './interface-check-icon.svg'
export function AlbagoSection() {
  return (
    <Section>
      <InterfaceCheckIcon />
      <TitleWrap>
        <SubTitle>아테네 레슬링 금메달리스트가 </SubTitle>
        <Title>직접 무인매장을 관리하는 방법</Title>
      </TitleWrap>

      <IframeContainer>
        <iframe
          src="https://www.youtube.com/embed/uxEUy9NIDgo?si=iYvzSV7lAb06Q9lx"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
      </IframeContainer>
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  padding: 30px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #d6e7fd;
`

const TitleWrap = styled.div`
  margin: 6px auto;
  margin-bottom: 32px;
`

const SubTitle = styled.p`
  color: #212121;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -0.1px;
`
const Title = styled.p`
  color: #185aff;
  font-size: 24px;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: -0.1px;
`

const IframeContainer = styled.div`
  position: relative;

  width: 328px;
  padding-top: calc(9 / 16 * 328px);
  height: 0;
  overflow: hidden;

  aspect-ratio: 16 / 9;

  & > iframe {
    width: 328px;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }
`
