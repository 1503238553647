import { useEffect, useCallback } from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'

export default function TempVerifyPage() {
  const location = useLocation()
  const { login, provider, email, same_provider } = queryString.parse(location.search)
  //login: 로그인 상태
  //optcheck: 소셜로그인시 선택사항유무
  //route: 서비스페이지에서 pass인증 유무
  /**
   * test()함수: browserName이 WebKit은 ios 카카오, Chrome WebView는 안드로이드 카카오이다.
   */

  const test = useCallback(() => {
    window.opener = null
    window.open('', '_self')
    if (login === 'false') {
      if (same_provider === 'true') {
        alert(
          `${provider}계정인 ${email}로 가입한 이력이 있습니다. \n${provider} 홈페이지에서 로그아웃 후, 위 계정으로 로그인해 주세요.`
        )
      } else if (same_provider === 'false') {
        alert(`${provider}계정인 ${email}로 가입한 이력이 있습니다.`)
      }
    }
    window.close()
  }, [login, provider, email, same_provider])

  useEffect(() => {
    test()
  }, [test])
  return <div></div>
}
