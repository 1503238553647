import styled from 'styled-components'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { MapStoreListType } from 'lib/api/map/types'
import { getStoreTypeImageUrl } from 'utils/map/storeType'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import Icon from 'components/Icon'
import { LocationType } from 'pages/MapPage/MapPage'
import { useHistory } from 'react-router'

export type ScrollBodyProps = {
  storeList: MapStoreListType[]
  setMapCenter: (location: LocationType, singleOption?: boolean) => void
}

/**
 * @description 스크롤형태로 보여주는 매장뷰 컴포넌트
 * @param storeList 지도 범위안에 있는 매장리스트 정보
 * @param setMapCenter 지도 위치를 바꿔주는 클릭 함수
 */
function ScrollBody({ storeList, setMapCenter }: ScrollBodyProps) {
  const history = useHistory()
  const PER_PAGE_ITEMS = 20

  const [itemList, setItemList] = useState<MapStoreListType[]>([])
  const [nowPage, setNowPage] = useState<number>(1)
  useEffect(() => {
    if (storeList.length !== 0 && nowPage === 1) {
      const data = storeList.filter(
        (item, index) => (nowPage - 1) * PER_PAGE_ITEMS <= index && index < nowPage * PER_PAGE_ITEMS
      )

      setItemList((itemList) => itemList.concat(data))
      setNowPage((nowPage) => nowPage + 1)
    }
  }, [storeList, nowPage])

  useEffect(() => {
    setItemList([])
    setNowPage(1)
  }, [storeList])

  const onIntersect: IntersectionObserverCallback = useCallback(
    async ([{ isIntersecting }]) => {
      if (isIntersecting) {
        const data = storeList.filter(
          (item, index) => (nowPage - 1) * PER_PAGE_ITEMS <= index && index < nowPage * PER_PAGE_ITEMS
        )
        setItemList((itemList) => itemList.concat(data))
        setNowPage((nowPage) => nowPage + 1)
      }
    },
    [nowPage, storeList]
  )

  const handleDetailClick = (data: MapStoreListType) => {
    setMapCenter({ latitude: data.latitude, longitude: data.longitude })
    history.push(`/map/${data.id}`)
  }

  const { setTarget } = useIntersectionObserver({ onIntersect })

  return (
    <>
      <BodyWrapper>
        {storeList.length === 0 && (
          <InformText>
            조건에 맞는 매장이 없습니다.
            <br />
            <span>지역 또는 필터를 확인해 주세요.</span>
          </InformText>
        )}
        {itemList.map((item, index) => (
          <StoreItem key={index} onClick={() => handleDetailClick(item)}>
            <div className="text_container">
              <h3>
                {item.naver_category}
                {item.brwnie_yn && <Icon name="landingBlueLogoS" />}
              </h3>

              <h2>{item.store_name}</h2>
              <h4>{item.address}</h4>
            </div>

            <ThumbnailImage url={getStoreTypeImageUrl(item.category)} />
          </StoreItem>
        ))}
        <div ref={setTarget} />
      </BodyWrapper>
    </>
  )
}

const BodyWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  top: 53px;
  height: calc(100vh - 266px);
  position: relative;
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  line-height: 30px;
  display: block;
  flex-direction: column;
  width: 400px;
  overflow-y: scroll;

  @media only screen and (max-width: 768px) {
    width: 100vw;
    position: absolute;
    height: calc(100vh - 292px);
    top: 64px;
  }
`

const InformText = styled.div`
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 336px;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  font-weight: bold;

  span {
    font-size: 14px;
    font-weight: normal;
  }

  @media only screen and (max-width: 768px) {
    top: calc(30% - 53px);
  }
`

const StoreItem = styled.div`
  background-color: white;
  flex-direction: row;
  padding: 14px 20px;
  height: 134px;
  width: 400px;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  word-break: keep-all;

  &:first-child {
    border-top: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
  }

  .text_container {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 108px;
    h2 {
      font-size: 18px;
      line-height: 26px;
      color: ${({ theme }) => theme.colors.black};
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* ellipsis line */
      -webkit-box-orient: vertical;
    }
    h3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 20px;
      font-weight: normal;

      color: ${({ theme }) => theme.colors.gray};

      svg {
        width: 42px;
        height: 16px;
      }
    }

    h4 {
      margin-top: auto;
      font-size: 13px;
      line-height: 26px;
      font-weight: normal;
      color: ${({ theme }) => theme.colors.black};
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* ellipsis line */
      -webkit-box-orient: vertical;
    }

    ${({ theme }) => theme.media.mobile`
      width: 50%;
    `}
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    .text_container {
      h2 {
        font-size: 16px;
        line-height: 22px;
      }
      h3 {
        font-size: 10px;
        line-height: 16px;
      }
      h4 {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`

const ThumbnailImage = styled.div<{ url: string }>`
  width: 140px;
  height: 105px;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 68px, rgba(0, 0, 0, 0.4) 105px),
    url(${({ url }) => url});
`

export default memo(ScrollBody)
