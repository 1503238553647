import axios from 'axios'

export async function postSlackMessageSend(req: PostSlackMessageSendReq) {
  const response = await axios.post<undefined>(`/message-api/v1/slack/template`, req)
  return response.data
}

export type PostSlackMessageSendReq = {
  template: string
  data: any
}
