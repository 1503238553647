import styled, { keyframes } from 'styled-components'
import { useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

import Header from 'components/common/Header'
import Footer from 'components/common/Footer'
import Content from 'components/common/Content'
import Spinner from 'components/common/Spinner'
import CouponModal from 'components/CouponModal'

import useGetCoupons from 'hooks/query/coupon/useGetCoupons'
import usePostCoupon from 'hooks/query/coupon/usePostCoupon'
import ListBox from './ListBox'
import Button from 'components/common/Button'
import { CouponType } from 'lib/api/coupon/types'
import { useScrollGet } from 'hooks/useScrollGet'
import ApplyBanner from 'components/common/ApplyBanner'

export default function CouponPage() {
  const [useablePage, setUseablePage] = useState(1)
  const [expiredPage, setExpiredPage] = useState(1)
  const [couponCode, setCouponCode] = useState('')
  const [errorCode, setErrorCode] = useState('')
  const [inputFocus, setInputFocus] = useState(false)

  const mainRef = useRef<HTMLDivElement>(null)

  const [couponModal, setCouponModal] = useState(false)
  const [selectedCoupon, setSelectedCoupon] = useState<CouponType>()

  const { totalOffsetY } = useScrollGet({})
  const { mutateAsync: mutatePostCoupon } = usePostCoupon()
  const useableResponse = useGetCoupons({ page: useablePage, expired: false, perPage: isMobile ? 5 : 10 })
  const expiredResponse = useGetCoupons({ page: expiredPage, expired: true, perPage: isMobile ? 5 : 10 })

  async function requestCoupon() {
    setInputFocus(false)
    try {
      await mutatePostCoupon({ code: couponCode })
      setErrorCode('')
      setCouponCode('')
      useableResponse.refetch()
      expiredResponse.refetch()
    } catch ({ response: { status, data } }) {
      switch (status) {
        case 404:
          setErrorCode('해당 코드에 맞는 쿠폰을 찾을 수 없습니다.')
          break
        case 409:
          if (data.message === 'already issued coupon') {
            setErrorCode('이미 사용한 코드입니다.')
          } else {
            setErrorCode('사용할 수 없는 코드입니다.')
          }
          break
        default:
          setErrorCode('유효한 코드 형식이 아닙니다.')
          break
      }
    }
  }

  return (
    <>
      {/* <ConfirmModal /> */}
      <CouponModal
        open={couponModal}
        data={selectedCoupon}
        onClose={() => {
          setCouponModal(false)
        }}
      />
      <Header title="쿠폰함" offsetY={totalOffsetY} />
      <Content>
        <ListContainer ref={mainRef}>
          <CouponRegistBox>
            <label>쿠폰 등록</label>
            <div style={{ flexDirection: 'column' }}>
              <div className="input-box">
                <input
                  value={couponCode}
                  placeholder="쿠폰 코드 입력"
                  onChange={(event) => setCouponCode(event.target.value)}
                  onFocus={() => setInputFocus(true)}
                />
                <InputInnerButton onClick={requestCoupon} couponCode={couponCode} disabled={couponCode.length === 0}>
                  <span>등록</span>
                </InputInnerButton>
              </div>
              {!inputFocus && (
                <ErrorText className={errorCode ? 'errored-text errored' : 'errored-text'}>{errorCode}</ErrorText>
              )}
            </div>
            <Button
              className="pc-button"
              width={80}
              buttonTheme={couponCode ? 'primary' : 'disabled'}
              onClick={requestCoupon}
            >
              <span>등록</span>
            </Button>
          </CouponRegistBox>

          <ListBox
            className="coupons-box"
            title="사용 가능한 쿠폰"
            noCouponText="사용 가능한 쿠폰이 없어요."
            iconType="greenTicket"
            countColor="#43A047"
            count={useableResponse.data?.count ?? 0}
            page={useablePage}
            perPage={isMobile ? 5 : 10}
            loading={useableResponse.isLoading}
            updatePage={setUseablePage}
            coupons={useableResponse.data?.coupons ?? []}
            onClick={(coupon) => {
              setCouponModal(true)
              setSelectedCoupon(coupon)
            }}
          >
            <Spinner color="main" />
          </ListBox>
          <ApplyBanner />
          <ListBox
            className="expired-coupons-box"
            title="만료 쿠폰"
            noCouponText="만료된 쿠폰이 없어요."
            iconType="greyTicket"
            count={expiredResponse.data?.count ?? 0}
            countColor="#EA5214"
            loading={expiredResponse.isLoading}
            page={expiredPage}
            perPage={isMobile ? 5 : 10}
            updatePage={setExpiredPage}
            coupons={expiredResponse.data?.coupons ?? []}
            onClick={(coupon) => {
              setCouponModal(true)
              setSelectedCoupon(coupon)
            }}
          >
            <Spinner color="main" />
          </ListBox>
        </ListContainer>
      </Content>
      <Footer />
    </>
  )
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 83.2rem;
  margin: auto 0;

  .coupons-box {
    margin-bottom: 80px;

    ${({ theme }) => theme.media.mobile`
      margin-bottom: 4rem;
    `}
  }

  .expired-coupons-box {
    margin-top: 80px;
  }

  .expired-coupons-box .content {
    opacity: 0.5;
  }

  ${({ theme }) => theme.media.mobile`
    max-width: 31.2rem;

    .expired-coupons-box {
      margin-top: 40px;
    }
  `}
`

const FadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const CouponRegistBox = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(10)};
  ${({ theme }) => theme.media.mobile`
    margin-bottom: 4rem;
  `}

  label {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-top: 1rem;
    margin-right: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => theme.media.mobile`
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-top: 1.2rem;
      `}
  }
  .input-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    input {
      min-width: 32rem;
      padding: ${({ theme }) => theme.spacing(1.4, 2)};
      border: 1px solid ${({ theme }) => theme.colors.gray};
      border-radius: 0.8rem;
      margin: ${({ theme }) => theme.spacing(0, 2, 1, 0)};
      font-size: 16px;
      line-height: 24px;

      ${({ theme }) => theme.media.mobile`
        min-width: 16.2rem;
        max-width: 16.2rem;
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;
        line-height: 2rem;
        justify-content: flex-start;
      `}
    }
    .errored-text {
      min-height: 2.4rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: ${({ theme }) => theme.colors.warning};
      &.errored {
        animation: ${FadeAnimation} 0.3s ease-in-out;
      }
    }

    ${({ theme }) => theme.media.mobile`
      border: 1px solid ${theme.colors.gray}
    `}

    ${({ theme }) => theme.media.mobile`
      flex-direction: row;
      align-items: center;
      width: 100%;
      max-width: 23.3rem;
      border-radius: 0.8rem;
      padding: 1.1rem 1.4rem;
    `}
  }
  .pc-button {
    display: flex;
    margin-bottom: auto;

    ${({ theme }) => theme.media.mobile`
    display: none;
    `}
  }
`

const InputInnerButton = styled.button<{ couponCode: string }>`
  display: none;
  width: 32px;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  margin-left: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  border-radius: 0.8rem;
  color: ${({ theme, couponCode }) => (couponCode.length === 0 ? theme.colors.gray : theme.colors.white)};
  background-color: ${({ theme, couponCode }) =>
    couponCode.length === 0 ? theme.colors.lightGray : theme.colors.main};

  ${({ theme }) => theme.media.mobile`
    display: flex;
  `}
`

const ErrorText = styled.div`
  &.errored {
    min-height: 2.4rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme }) => theme.colors.warning};
    animation: ${FadeAnimation} 0.3s ease-in-out;
  }
`
