import client from '../client'
import { SendEmailForResetPasswordRes } from './types'

export async function sendEmailForResetPassword({ email }: SendEmailForResetPasswordReq) {
  const response = await client.post<SendEmailForResetPasswordRes>('auth-api/v1/auth/send/reset/password', { email })

  return response.data
}

export type SendEmailForResetPasswordReq = {
  email: string
}
