import React from "react"

export function getBrowserVisibilityProp() {
  // if (typeof document.hidden !== "undefined") {
  //   // Opera 12.10 and Firefox 18 and later support
  //   return "visibilitychange"
  // } else
  if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange"
  }
  // Opera 12.10 and Firefox 18 and later support
  return "visibilitychange"
}

export function getBrowserDocumentHiddenProp() {
  // if (typeof document.hidden !== "undefined") {
  //   return "hidden"
  // } else
  if (typeof document.msHidden !== "undefined") {
    return "msHidden"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden"
  }
  return "hidden"
}

export function getIsDocumentHidden() {
  const hiddenProp = getBrowserDocumentHiddenProp()
  return !document[hiddenProp]
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden())
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden())
  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()
    document.addEventListener(visibilityChange, onVisibilityChange, false)
    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange)
    }
  })

  return isVisible
}