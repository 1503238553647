import useServicePostPush from 'hooks/service/useServicePostPush'
import useVisitHourBottomSheet from 'hooks/service/useVisitHourBottomSheet'
import useQueryParamHook from 'hooks/useQueryParamHook'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import {
  ScheduleType,
  selectInvalidTimeSchedules,
  selectInvalidTimeSchedulesCount,
  setInvalidTimeFixSchedules,
} from 'modules/features/service/serviceSlice'
import { BottomCTA, ListRow, TypoP1 } from 'ohds-web'
import styled from 'styled-components'

export type InvalidTimeBottomCTAProps = {
  linear: boolean
  label: string
  selectedSchedule: ScheduleType
  onMoveToAnotherInvalidTime: () => void
}

export default function InvalidTimeBottomCTA({
  linear,
  label,
  selectedSchedule,
  onMoveToAnotherInvalidTime,
}: InvalidTimeBottomCTAProps) {
  const query = useQueryParamHook()
  const invalidIndex = Number(query.get('index')) ?? 0

  const { pushToConfirm, pushToInvalidTime } = useServicePostPush()

  const dispatch = useAppDispatch()
  const { invalidTimeFixSchedules } = useAppSelector((state) => state.service)
  const invalidTimeSchedules = useAppSelector(selectInvalidTimeSchedules)
  const invalidTimeSchedulesCount = useAppSelector(selectInvalidTimeSchedulesCount)

  const [openVisitHourOptions] = useVisitHourBottomSheet()

  const resolveInvalidTime = async () => {
    for (let i = invalidIndex + 1; i < invalidTimeSchedulesCount; i++) {
      const { year, month, date, hour } = invalidTimeSchedules[i]

      const selectedVisitHour = await openVisitHourOptions({
        title: `${month}월 ${date}일 ${hour}시는 마감되었어요 다른 시간으로 바꿔드릴게요`,
        year,
        month,
        date,
        OtherOption: (
          <ListRow
            UpTypo={<TypoP1 text="다른 날로 변경할래요" />}
            rightIconName="LineArrowRight"
            onClick={() => {
              onMoveToAnotherInvalidTime()
              pushToInvalidTime(invalidIndex + 1)
            }}
          />
        ),
      })

      if (selectedVisitHour !== undefined) {
        dispatch(
          setInvalidTimeFixSchedules([
            ...invalidTimeFixSchedules,
            {
              year,
              month,
              date,
              hour: selectedVisitHour,
            },
          ])
        )
      }
    }
  }

  const handleChangeButtonClick = async () => {
    const copiedFixSchedule = [...invalidTimeFixSchedules]
    copiedFixSchedule.push(selectedSchedule)
    dispatch(setInvalidTimeFixSchedules(copiedFixSchedule))

    const hasMoreInvalidTime = invalidTimeSchedulesCount - 1 > invalidIndex

    if (hasMoreInvalidTime) {
      await resolveInvalidTime()

      pushToConfirm()
    } else {
      pushToConfirm()
    }
  }

  return (
    <StyledBottomCTA
      linear={linear}
      primaryProps={{
        label,
        onClick: handleChangeButtonClick,
      }}
    />
  )
}

const StyledBottomCTA = styled(BottomCTA)`
  @media only screen and (min-width: 768px) {
    width: 50vw !important;
    left: 50%;
  }
`
