import { getStoreService, GetStoreServiceParam } from 'lib/api/service/getStoreService'
import { QueryOptionsOf } from 'lib/utils/types'
import { useQuery } from 'react-query'

export default function useStoreServiceQuery(
  value: GetStoreServiceParam,
  options: QueryOptionsOf<typeof getStoreService> = {}
) {
  return useQuery(createKey(value.storeId), () => getStoreService(value), options)
}

const createKey = (storeId: string) => ['store_service', storeId]
useStoreServiceQuery.createKey = createKey
