import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

type SwitchProps = {
  defaultStatus?: boolean
  onChange: (status: boolean) => void
}
/**
 * 토글되는 스위치 컴포넌트
 * @param { boolean | undefined } defaultStatus 스위치 초기 상태
 * @param { (status: boolean) => void } onChange 스위치 상태 변화 함수
 * @author 정윤재
 */

export default function Switch({ defaultStatus, onChange }: SwitchProps) {
  const [status, setStatus] = useState<boolean>(defaultStatus ? defaultStatus : false)
  const handleSwitchClick = () => {
    setStatus((status) => (status = !status))
    onChange(!status)
  }

  useEffect(() => {
    setStatus((status) => (defaultStatus ? (status = defaultStatus) : (status = false)))
  }, [defaultStatus])
  return (
    <SwitchWrapper switchStat={status} onClick={handleSwitchClick}>
      <Circle />
    </SwitchWrapper>
  )
}

const SwitchWrapper = styled.div<{ switchStat: boolean }>`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
  background-color: #ccc;
  border-radius: 20px;
  cursor: pointer;

  ${({ switchStat }) =>
    switchStat &&
    css`
      background-color: ${({ theme }) => theme.colors.main};
      transition: 0.3s ease-in-out;
      & > div {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
    `}
`

const Circle = styled.div`
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 20px;
`
