import client from '../client'

export async function postBillingKey(req: PostBillingKeyReq) {
  const response = await client.post(`/payment-api/v1/payment/kg-inicis/billing/key`, req)
  return response.data
}

export type PostBillingKeyReq = {
  name: string // 구매자 이름
  phone: string // 구매자 전화번호 (010-xxxx-xxxx or 010xxxxxxxx)
  service_id: string // 구매할 서비스
  mail?: string // 구매자 이메일
  point?: number // 사용한 포인트
  coupon_id?: number // 사용한 쿠폰
  type: string // 결제 타입 [DOWN: 계약금, BALANCE: 잔금, ALL: 일괄] (현재는 ALL만 사용)
  mobile?: string // 모바일 결제 수단 (이 값을 보내면 모바일 결제로 연결됨) [CARD: 카드결제]
}
