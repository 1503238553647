import styled from 'styled-components'

export default function CoworkCompany() {
  return (
    <SectionWrapper>
      <h1>이미 브라우니로 관리중인</h1>
      <PcCoworkLogoImg src="/image/브라우니_무인매장_함께하는_기업들.png" alt="브라우니 무인매장" />
      <MobileCoworkLogoImg src="/image/브라우니_무인매장_함께하는_기업들_m.png" alt="브라우니 무인매장" />
      <hr />
      <h2>브라우니 협력사</h2>
      <PcPartnerLogoImg src="/image/브라우니_무인매장_파트너그룹사.png" alt="브라우니 무인매장" />
      <MobilePartnerLogoImg src="/image/브라우니_무인매장_파트너그룹사_m.png" alt="브라우니 무인매장" />
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  position: relative;

  width: calc(100% - 64px);
  max-width: 1280px;

  z-index: 1;

  background-color: var(--gray0);
  border-radius: 60px;

  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  & > h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--gray800);

    margin-top: 52px;
  }

  & > h2 {
    width: 100%;
    position: relative;

    font-weight: 700;
    font-size: 26px;
    line-height: 36px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--gray600);

    margin-top: 32px;
    margin-bottom: 24px;
  }

  & > hr {
    width: calc(100% - 16px);
    height: 2px;
    background-color: var(--gray100);
    border: none;
  }

  ${({ theme }) => theme.media.mobile`
    width: auto;

    padding-bottom: 52px;

    border-radius: 32px;

    & > h1 {
      width: 100%;

      margin-top: 32px;

      font-size: 20px;
      line-height: 28px;
      word-break: keep-all;
    }

    & > h2 {
      margin-bottom: 16px;
      margin-top: 16px;

      font-size: 14px;
      line-height: 24px;
    }
  `}
`

const PcCoworkLogoImg = styled.img`
  display: flex;
  object-fit: contain;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;

  ${({ theme }) => theme.media.mobile`
    display: none; 
  `}
`

const MobileCoworkLogoImg = styled.img`
  width: 100%;

  box-sizing: border-box;
  margin-top: 32px;

  display: none;
  object-fit: contain;

  ${({ theme }) => theme.media.mobile`
    display: flex;
    height: auto;

    margin-bottom: 56px;
  `}
`

const PcPartnerLogoImg = styled.img`
  width: 100%;
  margin: 0 auto;

  display: flex;
  object-fit: contain;
  justify-content: center;

  margin-bottom: 72px;

  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`

const MobilePartnerLogoImg = styled.img`
  width: 100%;
  height: 120px;

  margin: 0 auto;

  display: none;
  object-fit: contain;

  ${({ theme }) => theme.media.mobile`
    display: flex;
  `}
`
