import { fadeIn } from 'assets/styles/animation'
import CouponModal from 'components/CouponModal'
import { CouponsType } from 'lib/api/coupon/types'
import { memo, useCallback, useMemo, useState } from 'react'
import { useEffect } from 'react'
import { Dispatch, SetStateAction } from 'react'
import styled, { css } from 'styled-components'
import addComma from 'utils/common/addComma'
import Icon from '../Icon'
import SubTitle from './SubTitle'
import { FIRST_ANNIVERSARY_CODE_TEMPLATE_ID } from 'lib/api/coupon/getCoupons'

export type BenefitSectionProps = {
  // disabled: boolean
  servicePrice: number
  totalPoints: number
  point: string
  setPoint: Dispatch<SetStateAction<string>>
  isCouponOpen: boolean
  setIsCouponOpen: Dispatch<SetStateAction<boolean>>
  coupons?: CouponsType
  setSelectedCouponId: Dispatch<SetStateAction<number>>
  selectedCouponId: number
  limitPoint?: number
  // coupon 제한을 위한 prop
  total_week_count?: number
  weekly_count?: number
  address_name?: string
}

const BenefitSection = ({
  // disabled,
  servicePrice,
  point,
  setPoint,
  totalPoints,
  isCouponOpen,
  setIsCouponOpen,
  coupons,
  selectedCouponId,
  setSelectedCouponId,
  limitPoint,
  total_week_count,
  weekly_count,
  address_name,
}: BenefitSectionProps) => {
  const isInvalidCoupon = useMemo(() => {
    if (!total_week_count || !weekly_count || !address_name) return true
    return !(total_week_count === 4 && weekly_count > 1)
  }, [total_week_count, weekly_count, address_name])

  const [warningType, setWarningType] = useState<'max' | 'min' | 'unit' | null>(null)
  const [couponModalId, setCouponModalId] = useState<number | null>(null)
  const [blink, setBlink] = useState(false)
  const [isPointFocus, setIsPointFocus] = useState<boolean>(false)

  useEffect(() => {
    if (isPointFocus) {
      const timeout = setTimeout(() => {
        setBlink((prev) => !prev)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [blink, isPointFocus])

  const valueToPoint = (value: string) => {
    const valueToNumber = parseInt(value, 10)

    if (value === '') {
      setWarningType(null)
      return value
    }
    // 음수 막기
    if (valueToNumber < 0) {
      return point
    }

    if (totalPoints === 0 || totalPoints < 10000) return '0'

    if (limitPoint) {
      if (limitPoint > totalPoints) {
        if (valueToNumber > totalPoints) {
          setWarningType('max')
          return `${totalPoints}`
        }
      } else {
        if (limitPoint && valueToNumber > limitPoint) {
          if (limitPoint % 1000 !== 0) {
            setWarningType('unit')
          }
          setWarningType(null)
          return `${limitPoint}`
        }
      }
    }

    setWarningType(null)

    if (parseInt(value, 10) % 1000 !== 0) {
      setWarningType('unit')
    }

    if (point === '0' && value.length === 2 && !isNaN(parseInt(value[value.length - 1], 10))) return value[1]
    if (value === '0') return value

    // 글자 입력 막기
    if (isNaN(parseInt(value[value.length - 1], 10))) {
      return point
    }

    return value
  }

  useEffect(() => {
    if (limitPoint) {
      if (limitPoint < parseInt(point, 10)) {
        setPoint(`${limitPoint - (limitPoint % 1000)}`)
      }
    }
  }, [limitPoint, point, setPoint])

  const initSelectedCouponId = useCallback(() => {
    setSelectedCouponId(0)
  }, [setSelectedCouponId])

  const couponClick = useCallback(
    (id, template_id) => {
      if (!(isInvalidCoupon && template_id === FIRST_ANNIVERSARY_CODE_TEMPLATE_ID))
        selectedCouponId ? initSelectedCouponId() : setSelectedCouponId(id)
    },
    [initSelectedCouponId, isInvalidCoupon, selectedCouponId, setSelectedCouponId]
  )

  return (
    <StyledBenefitSection disabled={false}>
      <ForceBorder />
      <div>
        <BenefitTitle>
          <SubTitle>혜택 적용</SubTitle>
          {/* {disabled && <p>최종 계약 단계에서 사용하실 수 있습니다.</p>} */}
          {/* <p>최종 계약 단계에서 사용하실 수 있습니다.</p> */}
        </BenefitTitle>
        <BenefitContent>
          <BenefitCategory disabled={false}>
            <span>포인트</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span>({addComma(totalPoints)}P 보유)</span>
          </BenefitCategory>
          <PointInput disabled={false}>
            <input
              type="text"
              onChange={(e) =>
                totalPoints < 10000 ? e.preventDefault() : setPoint(valueToPoint(e.currentTarget.value))
              }
              value={point}
              onClick={() => totalPoints < 10000 && setWarningType('min')}
              onFocus={() => {
                if (point === '0') {
                  setPoint('')
                }
                setIsPointFocus(true)
              }}
              onBlur={() => {
                if (point === '') {
                  setPoint('0')
                }
                setIsPointFocus(false)
                setBlink(false)
              }}
              id="point"
              disabled={false}
            />
            <label htmlFor="point">
              {addComma(point)}
              {blink && <span />}
            </label>
            <span>P</span>
          </PointInput>
        </BenefitContent>
        <BenefitContent>
          <StyledSpanTextDeco onClick={() => setPoint(valueToPoint(totalPoints ? totalPoints.toString() : '0'))}>
            포인트 전액 사용
          </StyledSpanTextDeco>
        </BenefitContent>
        {/* {!disabled && warningType && (
          <WarningText>
            {warningType === 'max' && '보유한 포인트 보다 많이 입력하였습니다.'}
            {warningType === 'unit' && '1,000 P 단위로 사용 가능합니다.'}
            {warningType === 'min' && '10,000P 부터 사용 가능합니다.'}
          </WarningText>
        )} */}
        {warningType && (
          <WarningText>
            {warningType === 'max' && '보유한 포인트 보다 많이 입력하였습니다.'}
            {warningType === 'unit' && '1,000 P 단위로 사용 가능합니다.'}
            {warningType === 'min' && '10,000P 부터 사용 가능합니다.'}
          </WarningText>
        )}
        <BenefitContent
          disabled={false}
          // onClick={() => !disabled && coupons?.count !== 0 && setIsCouponOpen(!isCouponOpen)}
          onClick={() => coupons?.count !== 0 && setIsCouponOpen(!isCouponOpen)}
          isCouponOpen={isCouponOpen}
        >
          <BenefitCategory disabled={false}>
            <span>쿠폰</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>({coupons?.count ?? 0}장 보유)</span>
          </BenefitCategory>
          <Icon name="blackDownArrow" />
        </BenefitContent>
      </div>

      <CouponList isCouponOpen={isCouponOpen} couponCount={coupons?.count}>
        {coupons?.coupons.map(({ id, name, discount_price, discount_rate, template_id }) => (
          <CouponListItem
            key={id}
            onClick={() => couponClick(id, template_id)}
            disabled={isInvalidCoupon && template_id === FIRST_ANNIVERSARY_CODE_TEMPLATE_ID}
            isClicked={selectedCouponId === id}
          >
            <div>
              <span>
                <span />
              </span>
              <div>
                <p>{name}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    setCouponModalId(id)
                  }}
                >
                  자세히 보기
                </button>
              </div>
            </div>
            <p>
              {discount_price ? addComma(discount_price) : addComma((servicePrice * Number(discount_rate)) / 100)} 원
            </p>
          </CouponListItem>
        ))}
      </CouponList>

      <CouponModal
        open={!!couponModalId}
        data={coupons?.coupons.find(({ id }) => id === couponModalId)}
        onClose={() => setCouponModalId(null)}
      />
    </StyledBenefitSection>
  )
}

const StyledSpanTextDeco = styled.span`
  cursor: pointer;
  color: var(--gray300);
  text-decoration: underline;
`
const StyledBenefitSection = styled.section<{ disabled: boolean }>`
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  position: relative;
  z-index: 10;

  & > div {
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.spacing(0, 4, 4)};
    display: flex;
    flex-direction: column;

    & > div:last-child {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }
  }

  ${({ theme }) => theme.media.mobile`
    & > div {
      padding: 0 16px 32px;
    }
  `}
`

const BenefitTitle = styled.div`
  display: flex;
  align-items: center;

  & > p {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.5;
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;

    & > h4 {
      margin-bottom: 0;
    }

    & > p {
      margin: 0;
      line-height: 20px;
    }
  `}
`

const BenefitContent = styled.div<{ disabled?: boolean; isCouponOpen?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & ~ & {
    margin-top: ${({ theme }) => theme.spacing(4)};
  }

  & > svg {
    opacity: ${({ disabled }) => (disabled ? 0.35 : 1)};
    transition: transform 0.3s ease-in-out;
  }

  ${({ isCouponOpen }) =>
    isCouponOpen
      ? css`
          & > svg {
            transform: rotate(180deg);
          }
        `
      : css`
          & > svg {
            transform: rotate(0);
          }
        `}

  ${({ theme }) => theme.media.mobile`
    & ~ & {
      margin-top: 16px;
    }
  `}
`

const BenefitCategory = styled.div<{ disabled: boolean }>`
  & > span {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.black};
  }

  & > span:first-child {
    opacity: ${({ disabled }) => (disabled ? 0.35 : 1)};
  }

  & > span:last-child {
    opacity: ${({ disabled }) => (disabled ? 0.2 : 0.5)};
  }

  ${({ theme }) => theme.media.mobile`
    display: flex;
    align-items: center;

    & > span:last-child {
      font-size: 12px;
    }
  `}
`

const PointInput = styled.div<{ disabled: boolean }>`
  width: 14rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid rgba(30, 30, 44, 0.35);
  position: relative;

  & > input {
    width: 13rem;
    border: none;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: right;
    color: white;
    cursor: default;
    padding: 0;
    padding-right: 2px;

    &:disabled {
      background-color: white;
    }
  }

  & > label {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    position: absolute;
    top: 0;
    right: 12px;
    opacity: ${({ disabled }) => (disabled ? 0.35 : 1)};

    & > span {
      position: absolute;
      top: 2px;
      right: 0px;
      height: 18px;
      width: 1px;
      background-color: ${({ theme }) => theme.colors.black};
    }
  }

  & > span {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.black};
    opacity: ${({ disabled }) => (disabled ? 0.35 : 1)};
  }

  ${({ theme }) => theme.media.mobile`
    width: 128px;

    & > input {
      width: 120px;
    }
  `}
`

const WarningText = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.warning};
  align-self: flex-end;
  margin-top: ${({ theme }) => theme.spacing(1)};
  animation: ${fadeIn} 0.3s ease-in-out;
`

const CouponList = styled.ul<{ isCouponOpen: boolean; couponCount?: number }>`
  width: 50rem;
  height: 0px;
  display: none;
  background-color: ${({ theme }) => theme.colors.lightGray};
  /* position: absolute; */
  /* left: -0.1rem; */
  bottom: 0rem;
  /* z-index: -1; */
  transition: height 0.3s ease-in-out;
  border-left: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-right: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};

  ${({ couponCount }) =>
    couponCount &&
    (couponCount === 1
      ? css`
          /* bottom: -14.5rem; */
        `
      : css`
          /* bottom: ${couponCount * -8 - (couponCount - 1) * 0.8 - 6.2}rem; */
        `)}

  ${({ isCouponOpen, couponCount }) =>
    isCouponOpen
      ? css`
          display: block;
          height: auto;
          padding: ${({ theme }) => theme.spacing(4)};
          /* transform: translateY(0); */
        `
      : couponCount &&
        (couponCount === 1
          ? css`
              /* transform: translateY(-14.5rem); */
            `
          : css`
              /* transform: translateY(${couponCount * -8 - 6.1 - (couponCount - 1) * 0.8}rem); */
            `)}

  & > li ~ li {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  ${({ theme }) => theme.media.mobile`
    width: 312px;
    padding: 16px;
  `}

  @media only screen and (max-width: 768px) {
    ${({ couponCount }) =>
      couponCount &&
      (couponCount === 1
        ? css`
            bottom: -11rem;
          `
        : css`
            bottom: ${couponCount * -8 - (couponCount - 1) * 0.8 - 3}rem;
          `)}

    ${({ isCouponOpen, couponCount }) =>
      isCouponOpen
        ? css`
            /* transform: translateY(0); */
          `
        : couponCount &&
          (couponCount === 1
            ? css`
                /* transform: translateY(-10.9rem); */
              `
            : css`
                /* transform: translateY(${couponCount * -8 - 2.9 - (couponCount - 1) * 0.8}rem); */
              `)}
  }
`

const CouponListItem = styled.li<{ isClicked: boolean; disabled?: boolean }>`
  width: 43.6rem;
  height: 8rem;
  border: 0.1rem solid;
  border-radius: 0.8rem;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      & > p {
        color: var(--gray300) !important;
      }
      & > div > div > p {
        color: var(--gray300) !important;
      }
      & > div > span {
        border-color: var(--gray300) !important;
      }
    `}

  & > div {
    display: flex;

    & > span {
      width: 2.4rem;
      height: 2.4rem;
      border: 0.2rem solid;
      border-radius: 1.2rem;
      margin: ${({ theme }) => theme.spacing(0.25)};
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: ${({ theme }) => theme.spacing(1)};

      & > p {
        color: ${({ theme }) => theme.colors.black};
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;
        margin-top: ${({ theme }) => theme.spacing(0.5)};
      }

      & > button {
        width: 5.8rem;
        height: 1.6rem;
        font-size: 1.2rem;
        font-weight: 400;
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black};
        opacity: 0.5;
        background-color: ${({ theme }) => theme.colors.white};
        padding: 0;
        cursor: pointer;
      }
    }
  }

  & > p {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ isClicked }) =>
    isClicked
      ? css`
          border-color: ${({ theme }) => theme.colors.main};

          & > div > span {
            border-color: ${({ theme }) => theme.colors.main};
            display: flex;
            align-items: center;
            justify-content: center;

            & > span {
              width: 1rem;
              height: 1rem;
              background-color: ${({ theme }) => theme.colors.main};
              border-radius: 50%;
            }
          }
        `
      : css`
          border-color: ${({ theme }) => theme.colors.lightGrayishBlue};

          & > div > span {
            border-color: ${({ theme }) => theme.colors.gray};
          }
        `}

  ${({ theme }) => theme.media.mobile`
    width: 280px;
  `}
`

const ForceBorder = styled.span`
  width: 498px;
  position: absolute;
  top: -1px;
  left: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
  z-index: 100;

  ${({ theme }) => theme.media.mobile`
    width: 280px;
  `}
`

export default memo(BenefitSection)
