import { useState } from 'react'
import DaumPostcode, { Address } from 'react-daum-postcode'
import styled from 'styled-components'
import availableSeoulGu from 'data/availableSeoulGu.json'
import availableOtherGu from 'data/availableOtherGu.json'
import { FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import Icon from 'components/Icon'
import { useLocation } from 'react-router'
import useStoresQuery from 'hooks/query/store/useStoresQuery'
import { StoreType } from 'lib/api/store/types'
import { Link } from 'react-router-dom'

export type AddressSearchType = {
  register: UseFormRegister<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  getValues: UseFormGetValues<FieldValues>
}

export default function AddressSearch({ register, setValue, getValues }: AddressSearchType) {
  const location = useLocation()
  const { data: stores } = useStoresQuery()

  const [result, setResult] = useState<Address | undefined>(
    location.pathname.includes('local') ? getValues('search_address') : undefined
  )
  const [outOfService, setOutOfService] = useState<boolean>(false)
  const [sameAddressStore, setSameAddressStore] = useState<StoreType>()

  const handleReSearch = () => {
    setResult(undefined)
    setOutOfService(false)
    setSameAddressStore(undefined)
    setValue('search_address', '')
  }

  const handleSearchComplete = (address: Address) => {
    setResult(address)
    setValue('search_address', address)

    if (
      (address.sido === '인천' && address.sigungu === '중구') ||
      (availableSeoulGu.filter(({ name }) => name === address.sigungu).length === 0 &&
        availableOtherGu.filter(({ name }) => address.sigungu.includes(name)).length === 0)
    ) {
      if (address.sido !== '부산')
        setOutOfService(true)
    }

    stores?.forEach((store) => {
      if (store.address_name === address.address) return setSameAddressStore(store)
    })
  }

  return (
    <StyledAddressSearch>
      {result && (
        <div className="result">
          <p>{result.address}</p>
        </div>
      )}

      {!result && <StyledDaumPostcode onComplete={handleSearchComplete} />}

      <DetailInput
        type={result && !sameAddressStore ? 'text' : 'hidden'}
        placeholder="상세주소"
        {...register('detail_address', { required: true })}
      />

      {result && (
        <button className="research__button" type="button" onClick={handleReSearch}>
          <Icon name="mainRefresh" />
          <span>재검색</span>
        </button>
      )}

      {!sameAddressStore && outOfService && (
        <div className="warning">
          <div>
            <span>•</span>
            <p>현재 서비스 지역: 서울, 인천(강화 및 중구 일부 제외), 성남, 수원</p>
          </div>
          <div>
            <span>•</span>
            <p>
              서비스 지역 외 주소입니다. 매장 등록은 가능하지만 서비스 신청 시에는{' '}
              <a href="https://brwnie.channel.io/" target="_blank" rel="noreferrer">
                1:1 채팅 문의
              </a>{' '}
              바랍니다.
            </p>
          </div>
        </div>
      )}

      {sameAddressStore && (
        <div className="warning">
          <div>
            <span>•</span>
            <p>같은 주소의 [{sameAddressStore.store_name}] 매장이 있습니다.</p>
          </div>
          {!outOfService && (
            <div>
              <span>•</span>
              <p>
                [{sameAddressStore.store_name}] 매장{' '}
                <Link to={`/service-post/${sameAddressStore.id}/basic`}>서비스 신청하기</Link>
              </p>
            </div>
          )}
          <div>
            <span>•</span>
            <p>
              해당 주소에 2개 이상의 매장을 추가하려면{' '}
              <a href="https://brwnie.channel.io/" target="_blank" rel="noreferrer">
                1:1 채팅 문의
              </a>{' '}
              바랍니다.
            </p>
          </div>
        </div>
      )}
    </StyledAddressSearch>
  )
}

const StyledDaumPostcode = styled(DaumPostcode)`
  width: 310px !important;
  min-height: 470px !important;
`

const StyledAddressSearch = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .result {
    width: 304px;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.gray};

    p {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.black};
    }
  }

  & > .research__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    height: 24px;
    margin-top: 32px;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.main};
      text-decoration: underline;
      margin-left: 8px;
    }
  }

  & > .warning {
    margin-top: 32px;
    width: 400px;
    padding: 16px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.lightGray};

    & > div {
      display: flex;

      p,
      a {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.warning};
        word-break: keep-all;
      }

      a {
        font-weight: 700;
        white-space: pre;
      }

      span {
        padding: 4px;
        display: inline-block;
        color: ${({ theme }) => theme.colors.warning};
      }
    }
  }

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    & > .result, & > .warning {
      width: 280px;
    }
  `}
`

export const DetailInput = styled.input`
  height: 40px;
  width: 304px;
  margin-top: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.black};
  padding: 0 16px;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    opacity: 0.3;
    color: ${({ theme }) => theme.colors.black};
  }

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    width: 280px;
  `}
`
