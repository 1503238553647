import client from '../client'
import { ServiceType } from './types'

export async function deleteService({ id }: DeleteServiceParam) {
  const response = await client.delete<ServiceType>(`/service-api/v1/service/${id}`)
  return response.data
}

export type DeleteServiceParam = {
  id: string
}
