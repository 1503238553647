import ILogger from '../ILogger'
import { LOGGER } from '../types'
import { KakaoEventArguments, KakaoEventName } from './type'

class KakaoLogger implements ILogger {
  private trackId: string = '2655806443385240967'
  private pixelInstance: any = null
  type: LOGGER = LOGGER.KAKAO

  onAttached(): boolean {
    // @ts-ignore
    if (window.kakaoPixel) {
      // @ts-ignore
      this.pixelInstance = window.kakaoPixel(this.trackId)
    }
    return this.pixelInstance !== null
  }

  onDetached(): boolean {
    this.pixelInstance = null
    return true
  }

  onLog<T extends KakaoEventName = KakaoEventName>(name: T, payload: KakaoEventArguments<T>): void {
    if (!this.pixelInstance) {
      console.warn('[KAKAO LOGGER] PIXEL INSTANCE IS NULL')
      return
    }

    switch (name) {
      case '방문':
        this.pixelInstance.pageView(name)
        break
      case '회원가입':
        this.pixelInstance.pageView()
        this.pixelInstance.completeRegistration(name)
        break
      case '콘텐츠조회':
        this.pixelInstance.pageView()
        this.pixelInstance.viewContent()
        break
      case '서비스신청':
        this.pixelInstance.pageView()
        this.pixelInstance.signUp(name)
        break
      case '일정예약':
        this.pixelInstance.pageView()
        this.pixelInstance.viewCart(name)
        break
      case '결제완료':
        this.pixelInstance.pageView()
        this.pixelInstance.purchase({
          currency: 'KRW',
          ...payload as object
        })
        break
    }
  }
}

export default KakaoLogger