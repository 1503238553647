import styled from 'styled-components'

import { ReactComponent as BrwnieLogoMobile } from './brwnie-logo.svg'
import { ReactComponent as Rabbit } from './rabbit.svg'
import { NewCrewInfoForm } from 'components/NewCrew/NewCrewInfoForm'

export default function NewCrewPage() {
  return (
    <>
      <TopWrap>
        <div className="top-content">
          <BrwnieLogoMobile />
          <span>신규 크루 정보 입력</span>
        </div>
        <div className="bottom-content">
          <Rabbit />
          <div className="description-wrap">
            <p>
              안녕하세요! 브라우니는 크루 관리에
              <br />
              필수적인 정보만을 선별하여,
              <br />
              최소한의 데이터만 수집하려고 해요.
              <br />
            </p>

            <p>
              제공해주신 개인정보는 급여 계좌 확인 및 소득세 신고를 위해 필요하며, 급여 지급 이외의 목적으로는 절대
              사용되지 않아요.
            </p>

            <p>
              아래에 나열된 입력란들은 모두 필수적으로 <br />
              작성해주셔야 하니, 꼭 모든 정보를 알려주세요 :)
              <br />
            </p>

            <span>* 급여는 매주 월요일에 지급돼요.</span>
          </div>
        </div>
      </TopWrap>
      <NewCrewInfoForm />
    </>
  )
}
const TopWrap = styled.div`
  padding: 32px 16px 32px 16px;
  background: #039;

  .top-navigation {
    padding: 16px 0px;
    display: flex;
    height: 48px;
    justify-content: flex-end;
    align-items: center;
  }

  .top-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > svg {
      opacity: 0.6;
    }

    & > span {
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: -0.4px;
      margin-top: 10px;
    }
  }

  .bottom-content {
    position: relative;
    margin-top: 32px;

    & > svg {
      position: absolute;
      top: -80px;
      right: 12px;
      z-index: 1;
    }

    .description-wrap {
      position: relative;
      z-index: 2;
      padding: 16px;
      display: flex;
      border-radius: 12px;
      border: 1px solid #039;
      background: #fff;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;

      & > p {
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        font-weight: 400;
        line-height: 136%;
        letter-spacing: -0.4px;
      }

      & > span {
        color: #039;
        font-size: 14px;
        font-weight: 400;
        line-height: 136%;
        letter-spacing: -0.4px;
      }
    }
  }
`
