import client from '../client'
import { BVSType } from './types'

export async function postBvs(req: PostBvsReq) {
  const response = await client.post<BVSType>(`/service-api/v1/bvs`, req)

  return response.data
}

export type PostBvsReq = {
  // service_id: number
  reservation_dates: string[]
  bonus_dates: string[]
  cash_management_dates: string[] | null
}
