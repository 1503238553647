import { configureStore } from '@reduxjs/toolkit'
import serviceApplicationReducer from './features/serviceApplication/serviceApplicationSlice'
import eventBannerStatusReducer from './features/eventBannerStatus/eventBannerStatusSlice'
import snackBarStatusReducer from './features/snackBarStatus/snackBarStatusSlice'
import serviceReducer from './features/service/serviceSlice'
import toastReducer from './features/toast/toastSlice'

export const store = configureStore({
  reducer: {
    serviceApplication: serviceApplicationReducer,
    eventBannerStatus: eventBannerStatusReducer,
    snackBarStatus: snackBarStatusReducer,
    service: serviceReducer,
    toast: toastReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
