import Pagination from 'components/common/Pagination'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { paginate } from 'utils/common/paginate'
import NewsList from '../../../data/brwnieNewsData.json'

export type BrwnieNewsType = {
  imageSrc: string
  newsUrl: string
  title: string
  publishing: string
  date: string
}

export type BrwnieNewsPaginateType = {
  newsItems: BrwnieNewsType[]
  perPageMaxLen: number
  currentPage: number
}

export default function LandingNewsSection() {
  const [itemSplit, setItemSplit] = useState<BrwnieNewsPaginateType>({
    newsItems: NewsList,
    perPageMaxLen: isMobile ? 1 : 3,
    currentPage: 1,
  })

  const { newsItems: items, perPageMaxLen, currentPage } = itemSplit

  const pagedArrList = paginate({ items, perPageMaxLen, currentPage }) as BrwnieNewsType[]

  const handleChangePage = (page: number) => {
    setItemSplit({ ...itemSplit, currentPage: page })
  }

  return (
    <LandingNewsSectionWrpper>
      <ContentContainer>
        <Title>브라우니 소식</Title>
        <NewsUl>
          {pagedArrList.map((item, index) => (
            <NewsLi key={index} imageSrc={item.imageSrc} onClick={() => window.open(item.newsUrl, '_blank')}>
              <div className="newsImageWrapper" />
              <div className="explainWrapper">
                <p>{item.title}</p>
                <span>{`${item.publishing} ${item.date}`}</span>
              </div>
            </NewsLi>
          ))}
        </NewsUl>
        <Pagination
          perPageMaxLen={perPageMaxLen}
          itemsCount={items.length}
          currentPage={currentPage}
          onChangePage={handleChangePage}
        />
      </ContentContainer>
    </LandingNewsSectionWrpper>
  )
}

const LandingNewsSectionWrpper = styled.section`
  width: 100%;

  padding: 140px 125px 140px;

  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray200};
  position: relative;

  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 104px 16px;
  }
`

const ContentContainer = styled.div`
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    width: 360px;
    height: inherit;
    position: relative;
  }
`

const Title = styled.p`
  font-weight: 700;
  font-size: 52px;
  line-height: 90px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    font-size: 30px;
    line-height: 40px;
  }
`

const NewsUl = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin: 52px auto;

  & > li:nth-child(2) {
    margin: 0px 16px;
  }

  @media only screen and (max-width: 768px) {
    margin: 40px auto;
  }
`

const NewsLi = styled.li<{ imageSrc: string }>`
  cursor: pointer;
  width: 328px;
  height: 338px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: transform 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;

  &:hover {
    transform: scale(1.05);
  }

  .newsImageWrapper {
    background-image: url(${({ imageSrc }) => imageSrc});
    background-size: cover;
    width: 100%;
    height: 218px;
    border-radius: 24px 24px 0px 0px;
  }

  .explainWrapper {
    padding: 16px;
    width: 100%;
    height: 120px;

    & > p {
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      color: ${({ theme }) => theme.colors.black};
      height: 56px;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    & > span {
      margin-top: 4px;
      display: flex;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: ${({ theme }) => theme.colors.gray};
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
  }
`
