import { MainContainer } from 'ohds-web'
import styled from 'styled-components'

const BrwnieMainContainer = styled(MainContainer)`
  height: calc(100% - 56px);

  @media only screen and (min-width: 768px) {
    width: 360px;
    height: calc(100vh - 120px - 56px);
    margin: auto;
    background-color: var(--gray0);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
`

export default BrwnieMainContainer
