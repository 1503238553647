import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useAuth from 'hooks/query/auth/useAuth'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'

export default function PageLogger() {
  const { pathname } = useLocation()
  const { auth } = useAuth()

  const sendScrollLog = useCallback(() => {
    LogManager.Instance.sendLog('scroll', {
      depth: window.scrollY,
      page_url: window.location.href
    }, LOGGER.GTM)
  }, [])

  // 로그 수집을 위해 추가
  useEffect(() => {
    LogManager.Instance.sendLog('pageview', {
      user_id: auth ? auth?.id.toString() : 'NULL'
    }, LOGGER.GTM)
    LogManager.Instance.sendLog('tag', '방문', LOGGER.KAKAO)
    LogManager.Instance.sendLog('null', null, LOGGER.NAVER_AD)
    window.addEventListener('scroll', sendScrollLog)
    return () => {
      window.removeEventListener('scroll', sendScrollLog)
    }
  }, [pathname])

  return null
}
