import styled, { css } from 'styled-components'

type SwitcherType = {
  status: boolean
  setStatus: () => void
}

export default function Switcher({ status, setStatus }: SwitcherType) {
  return (
    <SwitcherWrapper onClick={setStatus} status={status}>
      <span className="first">정기 관리</span>
      <span className="second">단기 관리</span>
      <div className="contentBtn" />
    </SwitcherWrapper>
  )
}

const SwitcherWrapper = styled.div<{ status: boolean }>`
  box-sizing: border-box;
  padding: 6px 8px;

  width: 254px;
  height: 52px;
  min-height: 52px;

  border-radius: 24px;
  background-color: var(--gray100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;

  ${({ status }) =>
    status
      ? css`
          .first {
            z-index: 1;
            font-weight: 700;
            color: var(--gray800);
          }
        `
      : css`
          .contentBtn {
            transform: translateX(calc(100% - 2px));
          }

          .second {
            z-index: 1;
            font-weight: 700;
            color: var(--gray800);
          }
        `}

  & > span {
    position: relative;
    width: 50%;

    display: flex;
    justify-content: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: var(--gray600);
    transition: color 0.3s ease-in-out, z-index 0.3s ease-in-out;
  }

  .contentBtn {
    position: absolute;
    left: 8px;
    top: 6px;

    width: calc(50% - 8px);
    height: 40px;
    background-color: var(--gray0);
    border-radius: 24px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    & > span {
      font-weight: 700;
      font-size: 14px;
      line-height: 40px;
      color: var(--gray800);
    }
  }

  ${({ theme, status }) => theme.media.mobile`
    width: calc(166px + 16px);
    height: 40px;
    min-height: 40px;
    padding: 0px 4px;
  
    ${
      !status &&
      css`
        .contentBtn {
          width: calc(50% - 8px);
          transform: translateX(calc(100% + 5px)) !important;
        }
      `
    }
     .contentBtn {
      height: calc(40px - 8px);

      position: absolute;
      left: 4px;
      top: 4px;
     }
  `}
`
