import dayjs, { OpUnitType } from 'dayjs'

function calculatePeriod(amount: number, unit: OpUnitType) {
  return `${dayjs().add(amount, unit).startOf(unit).format('YYYY.MM.DD')}~${dayjs()
    .add(amount, unit)
    .endOf(unit)
    .format('YYYY.MM.DD')}`
}

export const periodFilters = [
  { value: ' ', label: '전체' },
  { value: calculatePeriod(-1, 'month'), label: '지난달' },
  { value: calculatePeriod(0, 'year'), label: dayjs().format('YYYY') },
  { value: calculatePeriod(-1, 'year'), label: dayjs().add(-1, 'year').format('YYYY') },
  { value: calculatePeriod(-2, 'year'), label: dayjs().add(-2, 'year').format('YYYY') },
  { value: calculatePeriod(-3, 'year'), label: dayjs().add(-3, 'year').format('YYYY') },
  { value: calculatePeriod(-4, 'year'), label: dayjs().add(-4, 'year').format('YYYY') },
]

export const productFilters = [
  { value: ' ', label: '전체' },
  { value: '1', label: '1주 패키지' },
  { value: '4', label: '4주 패키지' },
  { value: '5', label: '4주 패키지(자동 결제)', isSubscribe: true },
  { value: '12', label: '12주 패키지' },
  { value: '24', label: '24주 패키지' },
]
export const stateFilters = [
  { value: ' ', label: '전체' },
  { value: 'USING', label: '이용 중' },
  { value: 'COMPLETE', label: '이용완료' },
  { value: 'REFUND_REQUEST', label: '계약 해지 신청' },
  { value: 'REFUND_ACCEPTED', label: '계약 해지 완료' },
]
