import { useGetBrwnieBasePriceQuery } from 'hooks/query/admin/useGetBrwnieBasePriceQuery'
import { useAppSelector } from 'hooks/useReduxHook'
import { BasePriceType } from 'lib/api/admin/type'
import {
  ServiceTimeMinuteType,
  selectServiceDayCount,
  setServiceTimeMinute,
} from 'modules/features/service/serviceSlice'
import { BottomSheet, Icon, SelectBox } from 'ohds-web'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import addComma from 'utils/common/addComma'

export type ServiceHourSelectBoxProps = {
  autoOpen: boolean
  label: string
  value?: string
  serviceTimeMinuteOptions: Readonly<ServiceTimeMinuteType[]>
}

export default function ServiceTimeMinuteSelectBox({
  autoOpen,
  label,
  value,
  serviceTimeMinuteOptions,
}: ServiceHourSelectBoxProps) {
  const dispatch = useDispatch()
  const { serviceTimeMinute, serviceDayValues, serviceWeek } = useAppSelector((state) => state.service)
  const { weekdayCount, weekendCount } = useAppSelector(selectServiceDayCount)

  const { data: priceData } = useGetBrwnieBasePriceQuery()

  const price = useRef<BasePriceType[]>([])

  useEffect(() => {
    if (priceData !== undefined && serviceWeek !== undefined && serviceDayValues.length !== 0) {
      price.current = priceData.filter(
        ({ totalWeekCount, countWeekday, countWeekend }) =>
          totalWeekCount === serviceWeek && countWeekday === weekdayCount && countWeekend === weekendCount
      )
    }
  }, [serviceDayValues, serviceWeek, priceData, weekdayCount, weekendCount])

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const openOption = () => setIsOpen(true)
  const closeOption = () => setIsOpen(false)

  useEffect(() => {
    if (autoOpen) setIsOpen(true)
  }, [autoOpen])

  const handleSelectBoxClick = () => {
    openOption()
  }

  const handleBottomSheetClose = () => {
    closeOption()
  }

  const handleOptionClick = (usingHour: ServiceTimeMinuteType) => {
    closeOption()
    dispatch(setServiceTimeMinute(usingHour))
  }

  return (
    <SelectBox
      label={label}
      value={value}
      onSelectBoxClick={handleSelectBoxClick}
      BottomSheet={
        <StyledBottomSheet
          open={isOpen}
          title="관리 시간을 선택해 주세요"
          subTitle="시간 추가에 따른 이용료는 관리 횟수만큼 반영됩니다."
          onClose={handleBottomSheetClose}
        >
          <StyledUl>
            {serviceTimeMinuteOptions.map((option: ServiceTimeMinuteType, index) => {
              if (serviceWeek === undefined) {
                return null
              }

              const totalServiceCount = serviceDayValues.length * serviceWeek
              const defaultPrice = price.current.find(({ serviceTimeMinute }) => serviceTimeMinute === 60)?.servicePrice
              const currentPrice = price.current.find(
                ({ serviceTimeMinute }) => serviceTimeMinute === option
              )?.servicePrice

              if (defaultPrice === undefined || currentPrice === undefined) {
                return null
              }

              return (
                <StyledLi key={index} onClick={() => handleOptionClick(option)}>
                  <TextBox>
                    <StyledP defaultPrice={option === 60}>
                      {option}분{' '}
                      <span>
                        (
                        {option === 60
                          ? `${addComma(Math.floor(defaultPrice / totalServiceCount / 1000) * 1000)}원`
                          : `+${addComma(
                              Math.floor((currentPrice - defaultPrice) / totalServiceCount / 1000) * 1000
                            )}원`}
                        )
                      </span>
                    </StyledP>
                  </TextBox>

                  {serviceTimeMinute === option && <Icon name="LineCheck" size="small" color={'brand300'} />}
                </StyledLi>
              )
            })}
          </StyledUl>
        </StyledBottomSheet>
      }
    />
  )
}

const StyledBottomSheet = styled(BottomSheet)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    position: fixed;
    left: 50%;
  }
`

const StyledUl = styled.ul``

const StyledLi = styled.li`
  width: 100%;
  padding: 16px;
  background-color: var(--gray0);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const TextBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

const StyledP = styled.p<{ disabled?: boolean; defaultPrice?: boolean }>`
  color: ${({ disabled }) => (disabled ? 'var(--gray400)' : 'var(--gray800)')};
  display: block;
  font-weight: 400;
  font-size: var(--p1);
  line-height: var(--p1LineHeight);

  span {
    color: ${({ defaultPrice }) => (defaultPrice ? 'var(--brand400)' : 'var(--gray400)')};
  }
`
