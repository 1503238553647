import axios from 'axios'
import { CouponsType } from './types'

export enum COUPON_STATE {
  UN_USED = 'UN_USED', // 사용 안함
  USED = 'USED', // 이미 사용함
}

export const FIRST_ANNIVERSARY_CODE_TEMPLATE_ID = 7

export type GetCouponsParam = {
  page?: number
  expired?: boolean
  state?: COUPON_STATE
  perPage?: number
}

export async function getCoupons({ page, state, expired, perPage }: GetCouponsParam) {
  const response = await axios.get<CouponsType>(`/payment-api/v1/coupon`, {
    params: {
      page,
      state,
      expired,
      perPage,
    },
  })
  return response.data
}
