import client from '../client'
import { ContractedDateType } from './types'

export async function patchContractedDate({ year, month, dates }: PatchContractedDateParam & PatchContractedDateReq) {
  const response = await client.patch<ContractedDateType>(`/service-api/v1/contracted-date/date/${year}/${month}`, {
    dates,
  })

  return response.data
}

export type PatchContractedDateParam = {
  year: number
  month: number
}

export type PatchContractedDateReq = {
  dates: string[]
}
