import twoDigitsNumConverter from './twoDigitsNumConverter'

export const timeSelection = ['오전 9시', '오전 11시', '오후 2시', '오후 4시', '오후 7시', '오후 9시'] as const

export type TimeSelectionType = typeof timeSelection[number]
export type TimezoneHourType = '00' | '02' | '05' | '07' | '10' | '12'

export type SelectedDateType = {
  year: number
  month: number
  date: number
  time: TimeSelectionType
}

export const selectionTimeToTimezoneHour = (time: TimeSelectionType) => {
  switch (time) {
    case '오전 9시':
      return '00'
    case '오전 11시':
      return '02'
    case '오후 2시':
      return '05'
    case '오후 4시':
      return '07'
    case '오후 7시':
      return '10'
    default:
      return '12'
  }
}

export const timezoneHourToSelectionTime = (timezoneHour: TimezoneHourType) => {
  switch (timezoneHour) {
    case '00':
      return '오전 9시'
    case '02':
      return '오전 11시'
    case '05':
      return '오후 2시'
    case '07':
      return '오후 4시'
    case '10':
      return '오후 7시'
    default:
      return '오후 9시'
  }
}

export const twentyFourHourToTwelveHour = (hour: number) => {
  if (hour >= 12) return `오후 ${hour - 12 === 0 ? 12 : hour - 12}시`
  else return `오전 ${hour === 0 ? 12 : hour}시`
}

export const selectedDateToTimezone = ({ year, month, date, time }: SelectedDateType) => {
  return `${year}-${twoDigitsNumConverter(month)}-${twoDigitsNumConverter(date)}T${selectionTimeToTimezoneHour(
    time
  )}:00:00.000Z`
}

export const timezoneToSelectedDate = (timezone: string) => {
  const timezoneDate = new Date(timezone)

  return {
    year: timezoneDate.getFullYear(),
    month: timezoneDate.getMonth() + 1,
    date: timezoneDate.getDate(),
    time: twentyFourHourToTwelveHour(timezoneDate.getHours()) as TimeSelectionType,
  }
}
