import React, { useState } from 'react'
import styled from 'styled-components'
import serviceCommonData from 'data/serviceCommonData.json'
import Icon from 'components/Icon'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import serviceTypeData from 'data/serviceTypeData.json'

export default function ServiceDetailThirdSection() {
  const { type } = useParams<{ type: string }>()
  const typeData = serviceTypeData.find(({ param }) => param === type)

  const [viewMoreStatus, setViewMoreStatus] = useState<boolean>(false)

  const handleViewMoreClick = () => {
    setViewMoreStatus(!viewMoreStatus)
  }

  return (
    <ThirdSectionWrapper status={viewMoreStatus}>
      <ContentWrapper>
        <h3>공통 서비스</h3>
        <h2>
          {`${
            typeData?.type === '아이스크림 할인점'
              ? '아·할 매장'
              : typeData?.type.includes('자판기')
              ? '무인 점포'
              : typeData?.type
          }`}
          에 필요한
          <br />
          기본 관리 서비스
        </h2>
        <ServiceUl status={viewMoreStatus}>
          {isMobile && !viewMoreStatus}
          {serviceCommonData.map(({ title, src }, index) => {
            if (isMobile && index >= 6 && !viewMoreStatus) {
              return null
            } else {
              return (
                <li key={title}>
                  <img src={src} alt={title} />
                  <p>{title}</p>
                </li>
              )
            }
          })}
        </ServiceUl>
        {isMobile && (
          <ViewMoreButton onClick={handleViewMoreClick} status={viewMoreStatus}>
            {viewMoreStatus ? <Icon name="blackArrowUp" /> : <Icon name="blackArrowDown" />}
          </ViewMoreButton>
        )}
      </ContentWrapper>
    </ThirdSectionWrapper>
  )
}

const ThirdSectionWrapper = styled.section<{ status: boolean }>`
  width: 100%;
  height: 1249px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  align-items: center;
  transition: height 0.3s ease-in-out;

  @media only screen and (max-width: 768px) {
    height: ${({ status }) => (status ? '1476px' : '1010px')};
    justify-content: center;
  }
`

const ContentWrapper = styled.div`
  width: 1030px;
  height: 890px;
  margin: 0 auto;
  position: relative;

  h3 {
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.indigoA700};
  }

  h2 {
    position: absolute;
    top: 44px;
    left: 0px;
    font-weight: 700;
    font-size: 64px;
    line-height: 90px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
  }

  @media only screen and (max-width: 768px) {
    width: 360px;
    margin: 0px 16px;
    height: inherit;

    h2 {
      position: absolute;
      top: 108px;
      font-size: 30px;
      line-height: 40px;
    }

    h3 {
      position: absolute;
      top: 80px;
      font-size: 14px;
      line-height: 24px;
    }
  }
`

const ServiceUl = styled.ul<{ status: boolean }>`
  position: absolute;
  top: 313px;
  left: 0px;
  width: 1030px;
  height: 588px;
  display: grid;
  grid-template-columns: 190px 190px 190px 190px 190px;
  grid-template-rows: 298px;
  justify-content: space-between;
  transition: height 0.3s ease-in-out;

  li {
    width: 190px;
    height: 234px;

    &:last-child {
      height: 290px;
    }

    img {
      border-radius: 40px;
      width: 100%;
      height: 190px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: var(--gray800);
      margin-top: 16px;
      white-space: prew-wrap;
    }
  }

  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 220px;
    min-width: 328px;
    height: ${({ status }) => (status ? '1156px' : '660px')};
    overflow: hidden;
    width: 100%;
    left: 0px;
    grid-template-columns: 160px 160px;
    grid-template-rows: ${({ status }) => (status ? '220px 220px 220px 220px 220px' : '220px 220px 220px')};

    li {
      width: 160px;
      height: 160px;
      border-radius: 32px;

      img {
        width: 100%;
        height: 160px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 2px;
      }
    }
  }
`

const ViewMoreButton = styled.div<{ status: boolean }>`
  min-width: 328px;
  position: absolute;
  top: ${({ status }) => (status ? '1356px' : '916px')};
  transition: top 0.3s ease-in-out;
  height: 32px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`
