import styled from 'styled-components'
export default function LandingAlbaGoYoutube() {
  return (
    <LandingAlbaGoYoutubeWrapper>
      <TitleWrap>
        <h3>🏅 아테네 올림픽 금메달리스트의</h3>
        <h1>무인매장 관리 출동</h1>
      </TitleWrap>

      <IframeContainer>
        <iframe
          src="https://www.youtube.com/embed/uxEUy9NIDgo?si=iYvzSV7lAb06Q9lx"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
      </IframeContainer>
    </LandingAlbaGoYoutubeWrapper>
  )
}

const LandingAlbaGoYoutubeWrapper = styled.section`
  width: 100%;
  padding: 180px 125px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--gray100);

  ${({ theme }) => theme.media.mobile`
    height: auto;
    padding: 60px 16px 80px 16px;
  `}
`

const IframeContainer = styled.div`
  position: relative;

  width: 75%;
  height: 0;
  overflow: hidden;
  padding-top: calc(9 / 16 * 75%);

  aspect-ratio: 16 / 9;

  & > iframe {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ theme }) => theme.media.mobile`
    width: 100%;
    padding-top: calc(9 / 16 * 100%);
  `}
`

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  & > h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 140%;
    color: #3d5afe;

    margin-bottom: 8px;
  }

  & > h1 {
    font-size: 52px;
    font-weight: 700;
    color: #1e1e2c;
  }

  ${({ theme }) => theme.media.mobile`
    margin-bottom: 40px;
    & > h3 {
        font-size: 16px;
        line-height: 175%;
    }

    & > h1 {
        font-size: 30px;
        line-height: 40px;
    }
  `}
`
