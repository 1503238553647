import { useEffect, useState } from 'react'

interface AuthTimerProps {
  startDate: number
  getTimer: (time: boolean) => void
}

export default function AuthTimer({ startDate, getTimer }: AuthTimerProps) {
  const [time, setTime] = useState(599)
  useEffect(() => {
    if (time > 0) {
      const Counter = setInterval(() => {
        const gap = Math.floor((new Date(startDate).getTime() - new Date().getTime()) / 1000)
        setTime(gap)
      }, 1000)
      return () => clearInterval(Counter)
    }
    if (time === 0) {
      getTimer(true)
    }
  }, [time, getTimer, startDate])

  useEffect(() => {
    setTime(599)
  }, [startDate])
  const timeFormat = (time: number) => {
    const m = Math.floor(time / 60).toString()
    let s = (time % 60).toString()
    if (s.length === 1) s = `0${s}`
    return `${m}:${s}`
  }
  return (
    <>
      <p
        style={{
          textAlign: 'right',
          fontSize: '1.4rem',
          marginRight: '1.2rem',
          color: '#ff5252',
          letterSpacing: '-0.04rem',
        }}
      >
        {timeFormat(time)}
      </p>
    </>
  )
}
