import useServicePostPush from 'hooks/service/useServicePostPush'
import useVisitHourBottomSheet from 'hooks/service/useVisitHourBottomSheet'
import useQueryParamHook from 'hooks/useQueryParamHook'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import {
  ScheduleType,
  selectInvalidDaySchedulesCount,
  selectInvalidTimeSchedules,
  selectInvalidTimeSchedulesCount,
  setInvalidDayFixSchedules,
  setInvalidTimeFixSchedules,
} from 'modules/features/service/serviceSlice'
import { BottomCTA, ListRow, TypoP1 } from 'ohds-web'
import styled from 'styled-components'

export type InvalidDayBottomCTAProps = {
  linear: boolean
  label: string
  selectedSchedule: ScheduleType
  onMoveToAnotherInvalidDay: () => void
}

export default function InvalidDayBottomCTA({
  linear,
  label,
  selectedSchedule,
  onMoveToAnotherInvalidDay,
}: InvalidDayBottomCTAProps) {
  const query = useQueryParamHook()
  const invalidIndex = Number(query.get('index')) ?? 0

  const dispatch = useAppDispatch()
  const { invalidDayFixSchedules } = useAppSelector((state) => state.service)

  const invalidTimeSchedules = useAppSelector(selectInvalidTimeSchedules)

  const invalidDaySchedulesCount = useAppSelector(selectInvalidDaySchedulesCount)
  const invalidTimeSchedulesCount = useAppSelector(selectInvalidTimeSchedulesCount)

  const { pushToInvalidTime, pushToConfirm, pushToInvalidDay } = useServicePostPush()

  const [openVisitHourOptions] = useVisitHourBottomSheet()

  const resolveInvalidTimeSchedules = async () => {
    const timeDataArr = []

    for (let i = 0; i < invalidTimeSchedulesCount; i++) {
      const { year, month, date, hour } = invalidTimeSchedules[i]

      const selectedVisitHour = await openVisitHourOptions({
        title: `${month}월 ${date}일 ${hour}시는 마감되었어요 다른 시간으로 바꿔드릴게요`,
        year,
        month,
        date,
        OtherOption: (
          <ListRow
            UpTypo={<TypoP1 text="다른 날로 변경할래요" />}
            rightIconName="LineArrowRight"
            onClick={() => pushToInvalidTime(i)}
          />
        ),
      })

      if (selectedVisitHour !== undefined) {
        timeDataArr.push({
          year,
          month,
          date,
          hour: selectedVisitHour,
        })
      }
    }
    dispatch(setInvalidTimeFixSchedules([...timeDataArr]))
  }

  const handleChangeButtonClick = async () => {
    dispatch(setInvalidDayFixSchedules([...invalidDayFixSchedules, selectedSchedule]))

    const hasAnotherInvalidDay = invalidDaySchedulesCount - 1 > invalidIndex

    if (hasAnotherInvalidDay) {
      onMoveToAnotherInvalidDay()

      pushToInvalidDay(invalidIndex + 1)
    } else {
      const hasInvalidTime = invalidTimeSchedulesCount !== 0

      if (hasInvalidTime) {
        await resolveInvalidTimeSchedules()
      }

      pushToConfirm()
      LogManager.Instance.sendLog(
        'service_post_grade',
        {
          service_post_grade: '3_서비스세부일정',
        },
        LOGGER.GTM
      )
    }
  }

  return (
    <StyledBottomCTA
      linear={linear}
      primaryProps={{
        label,
        onClick: handleChangeButtonClick,
      }}
    />
  )
}

const StyledBottomCTA = styled(BottomCTA)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    left: 50%;
  }
`
