import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import PaymentForm from 'components/PaymentForm'
import VBankPaymentInfo from 'components/ServicePaymentPage/VBankPaymentInfo/VBankPaymentInfo'
import useAuth from 'hooks/query/auth/useAuth'
import useDeleteService from 'hooks/query/service/useDeleteService'
import useServiceQuery from 'hooks/query/service/useServiceQuery'
import { useScrollGet } from 'hooks/useScrollGet'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

export default function ServicePaymentPage() {
  const { auth } = useAuth()
  const mainRef = useRef<HTMLDivElement>(null)

  const { serviceId } = useParams<{ serviceId: string }>()
  const { data: service } = useServiceQuery({ id: serviceId })
  const { totalOffsetY } = useScrollGet({})
  const { mutateAsync: mutateDeleteService } = useDeleteService()

  const history = useHistory()
  const location = useLocation<{ isConfirm: boolean }>()

  const deleteServiceData = async () => {
    try {
      if (location && location.state && location.state.isConfirm) {
        await mutateDeleteService({ id: serviceId })
      }
      return history.goBack()
    } catch (err) {}
  }

  useEffect(() => {
    ;(() => {
      window.history.pushState(null, '', window.location.href)
      window.addEventListener('popstate', deleteServiceData)
    })()

    return () => {
      window.removeEventListener('popstate', deleteServiceData)
    }
  }, [])

  return (
    <>
      <Header title="서비스 신청" offsetY={totalOffsetY} />
      <StyledMain ref={mainRef}>
        {service?.payment_id && !service?.paid_at ? (
          <VBankPaymentInfo serviceId={serviceId} />
        ) : (
          <PaymentForm
            userId={auth?.id as number}
            userName={auth?.real_name as string}
            userPhone={auth?.phone as string}
          />
        )}
      </StyledMain>
      <Footer />
    </>
  )
}

const StyledMain = styled.main`
  margin: 0 auto;
  padding: 80px 0 104px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  // mobile
  ${({ theme }) => theme.media.mobile`
    padding-top: 88px;
  `}
`
