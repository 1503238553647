import _ from 'lodash'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { isMobile } from 'react-device-detect'

/**
 * @function Pagination
 * @description 페이지네이션 컴포넌트
 * @param {number} perPageMaxLen 한 페이지 당 최대 항목 수
 * @param {number} itemCount 전체 항목 개수
 * @param {number} currentPage 현재 페이지 위치
 * @param {function} onChangePage 페이지 이동시 실행 함수
 */
export type PaginationProps = {
  perPageMaxLen: number
  itemsCount: number
  currentPage: number
  onChangePage: (page: number) => void
} & React.HTMLAttributes<HTMLDivElement>

export default function Pagination({
  perPageMaxLen,
  itemsCount,
  currentPage,
  onChangePage,
  children,
  ...props
}: PaginationProps) {
  const pageCount = Math.ceil(itemsCount / perPageMaxLen) // 몇 페이지가 필요한지 계산

  if (pageCount === 1) return null // 1페이지 뿐이라면 페이지 수를 보여주지 않음

  const scrollToTop = () => {
    document.querySelector('body')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const pages = _.range(1, pageCount + 1) // 마지막 페이지에 보여줄 컨텐츠를 위해 +1

  return (
    <PaginationContainer {...props}>
      <PageButton
        isActive={currentPage !== 1}
        disabled={currentPage === 1}
        onClick={() => {
          onChangePage(currentPage - 1)
          currentPage !== 1 && scrollToTop()
        }}
      >
        <Icon name="blackLeftArrow" />
      </PageButton>
      <ul>
        {pages.map((page) => {
          const isFirstGroup = currentPage <= 5
          const isCurrentGroup = Math.ceil(currentPage / 5) === Math.ceil(page / 5)

          return (
            <PageListLi
              key={page}
              isActive={currentPage === page}
              onClick={() => {
                onChangePage(page)
                currentPage !== page && scrollToTop()
              }}
              hidden={(isFirstGroup && page > 5) || (!isFirstGroup && !isCurrentGroup)}
            >
              {page}
            </PageListLi>
          )
        })}
        {/* {pages.map((page) => (
          <PageListLi
            key={page}
            isActive={currentPage === page}
            onClick={() => {
              onChangePage(page)
              currentPage !== page && scrollToTop()
            }}
            hidden={(currentPage <= 4 && page > 4) || (currentPage >= 5 && page < 5)}
          >
            {page}
          </PageListLi>
        ))} */}
      </ul>
      <PageButton
        isActive={currentPage !== pageCount}
        disabled={currentPage === pageCount}
        onClick={() => {
          onChangePage(currentPage + 1)
          currentPage !== pageCount && scrollToTop()
        }}
      >
        <Icon name="blackRightArrow" />
      </PageButton>
    </PaginationContainer>
  )
}

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  button ~ button {
    margin-left: 8px;
  }
`

const PageListLi = styled.li<{ isActive: boolean; show?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.3)};
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
`

const PageButton = styled.button<{ isActive: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
`
