import { useHistory, useParams } from 'react-router-dom'

export default function useServicePostPush() {
  const history = useHistory()

  const { storeId } = useParams<{ storeId: string }>()

  const pushToBasic = () => history.push(`/service-post/${storeId}/basic`)

  const pushToFirstVisit = () => history.push(`/service-post/${storeId}/first-visit`)

  const pushToInvalidDay = (invalidIndex: number) =>
    history.push(`/service-post/${storeId}/invalid-day?index=${invalidIndex}`)

  const pushToInvalidTime = (invalidIndex: number) =>
    history.push(`/service-post/${storeId}/invalid-time?index=${invalidIndex}`)

  const pushToConfirm = () => history.push(`/service-post/${storeId}/confirm`)

  const pushToModify = () => history.push(`/service-post/${storeId}/modify`)

  return {
    pushToBasic,
    pushToFirstVisit,
    pushToInvalidDay,
    pushToInvalidTime,
    pushToConfirm,
    pushToModify,
  }
}
