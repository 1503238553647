import { useState } from 'react'
import styled from 'styled-components'
import { browserName } from 'react-device-detect'
import AlertModal from 'components/common/AlertModal'

export default function SamsungAlert() {
  const [openAlert, setOpenAlert] = useState<boolean>(true)

  const openChromeTab = () => {
    const pathname = document.location.href.replace(/https?:\/\//i, '')
    const url = pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname
    window.open('intent://' + url + '#Intent;scheme=https;package=com.android.chrome;end')
  }

  return (
    <>
      {browserName === 'Samsung Browser' && (
        <AlertModal
          open={openAlert}
          onConfirm={() => {
            setOpenAlert(false)
            openChromeTab()
          }}
          confirmText="크롬 브라우저로 열기"
          buttonWidth={160}
        >
          <SamsungDesc>
            브라우니 웹사이트는 크롬 브라우저에 <br />
            더 최적화되어 있어요😎 원활한 사용을 <br />
            위해 <span>크롬 브라우저</span>로 접속해 주세요.
          </SamsungDesc>
        </AlertModal>
      )}
    </>
  )
}

const SamsungDesc = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  line-height: 24px;
  span {
    color: ${({ theme }) => theme.colors.blue};
  }
`
