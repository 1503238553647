import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Icon, TypoC2, TypoH3 } from 'ohds-web'

import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import useStoreQuery from 'hooks/query/store/useStoreQuery'

import { setStoreAddressType } from 'modules/features/service/serviceSlice'

import BasicInfoPage from './BasicInfoPage'
import FirstVisitPage from './FirstVisitPage'
import InvalidDayPage from './InvalidDayPage'
import InvalidTimePage from './InvalidTimePage'
import ConfirmSchedulePage from './ConfirmSchedulePage'
import ModifyPage from './ModifyPage'

import availableSeoulGu from 'data/availableSeoulGu.json'
import BrwnieIcon from 'components/Icon'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import useAuth from 'hooks/query/auth/useAuth'

export default function ServicePostPage() {
  const history = useHistory()
  const { auth } = useAuth()
  const { storeId } = useParams<{ storeId: string }>()
  const dispatch = useAppDispatch()

  const { data: store } = useStoreQuery({ id: storeId })

  useEffect(() => {
    LogManager.Instance.sendLog(
      'service_post',
      {
        enter_url: document.referrer,
        user_id: auth ? auth.id.toString() : 'NULL',
      },
      LOGGER.GTM
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!store) return

    if (availableSeoulGu.filter(({ name }) => name === store.sigungu).length === 0) {
      dispatch(setStoreAddressType('other'))
    } else dispatch(setStoreAddressType('seoul'))
  }, [dispatch, store])

  const goBack = () => {
    if (history.location.pathname.includes('/basic')) {
      history.replace('/store')
    } else {
      history.goBack()
    }
  }

  return (
    <Container>
      <PCBox>
        <ImageDimmer />
        <LogoBox onClick={() => history.push('/')}>
          <BrwnieIcon name="whiteNavLogo" />
        </LogoBox>

        <TitleContainer>
          <Title>서비스 신청</Title>
          <TypoH3
            text="|"
            color="gray400"
            style={{
              marginLeft: 40,
              marginRight: 40,
            }}
          />
          <TypoH3 text="Apply for service" color="gray0" />
        </TitleContainer>

        <CopyRightContainer>
          <TypoC2 text="COPYRIGHT © HIGHERX. Proudly created" color="gray0" />
        </CopyRightContainer>
      </PCBox>
      <MainView>
        <BasicNav>
          <button onClick={goBack}>
            <Icon name="LineArrowLeft" />
          </button>
        </BasicNav>
        <Switch>
          <Route path="/service-post/:storeId/basic">
            <BasicInfoPage />
          </Route>
          <Route path="/service-post/:storeId/first-visit">
            <PageWrapper>
              <FirstVisitPage />
            </PageWrapper>
          </Route>
          <Route path="/service-post/:storeId/invalid-day">
            <PageWrapper>
              <InvalidDayPage />
            </PageWrapper>
          </Route>
          <Route path="/service-post/:storeId/invalid-time">
            <PageWrapper>
              <InvalidTimePage />
            </PageWrapper>
          </Route>
          <Route path="/service-post/:storeId/confirm">
            <PageWrapper>
              <ConfirmSchedulePage />
            </PageWrapper>
          </Route>
          <Route path="/service-post/:storeId/modify">
            <PageWrapper>
              <ModifyPage />
            </PageWrapper>
          </Route>
        </Switch>
      </MainView>
    </Container>
  )
}

function PageWrapper({ children }: { children: React.ReactNode }) {
  const { serviceWeek } = useAppSelector((state) => state.service)
  if (!serviceWeek) {
    return null
  }

  return <>{children}</>
}

const Container = styled.div`
  width: 100vw;
  height: 100%;
  background-color: var(--gray0);
  display: flex;
  position: relative;
  z-index: 501;

  @media only screen and (min-width: 768px) {
    background-color: var(--gray100);
  }
`

const BasicNav = styled.nav`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 4px;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: transparent;
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) {
    width: 360px;
    margin: 0 auto;
    background-color: var(--gray0);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
`

const PCBox = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
    width: 50vw;
    height: 100vh;
    position: relative;
    background-image: url('/image/serviceApplyPCBackground.jpg');
    background-size: cover;
  }
`

const ImageDimmer = styled.div`
  width: 50vw;
  height: 100vh;
  background-color: black;
  opacity: 0.36;
  position: absolute;
  top: 0;
  left: 0;
`

const LogoBox = styled.div`
  cursor: pointer;
  width: 160px;
  height: 160px;
  background-color: var(--brand400);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`

const MainView = styled.div`
  width: 100vw;

  @media only screen and (min-width: 768px) {
    width: 50vw;
    height: 100vh;
    padding-top: 48px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  height: 80px;
  top: 240px;
  left: 48px;
`

const Title = styled.h2`
  font-size: 64px;
  color: var(--gray0);
  font-weight: 700;
`

const CopyRightContainer = styled.div`
  position: absolute;
  left: 48px;
  bottom: 48px;
`
