import { Container, Content, Description, Title } from 'components/common/ApplyComponents/Layout/Layout'
import { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as BrwnieLogo } from './brwnie-logo.svg'
import { ReactComponent as BrwnieLogoMobile } from './brwnie-logo-mobile.svg'

import ProgressBar from 'components/common/ApplyComponents/ProgressBar'
import TextInput from 'components/common/ApplyComponents/TextInput'
import NextButton from 'components/common/ApplyComponents/NextButton'
import PersonalPolicyCheck from 'components/common/ApplyComponents/PersonalPolicyCheck'
import RadioButton from 'components/common/ApplyComponents/RadioButton'
import Textarea from 'components/common/ApplyComponents/Textarea'
import { AnswerModel } from './answerModel'
import { setRow } from 'lib/utils/googleSheetUtils'
import { useHistory } from 'react-router-dom'

const difficultiesValues = [
  '시도 때도 없이 오는 전화',
  'CS 대응을 자주 할 수 없는 상황',
  '계속 반복하는 질문의 진상 손님 대처',
  '현재 이용하는 CS 서비스에 대한 불만족',
]

export default function CsPrePage() {
  const history = useHistory()

  const [currentStep, setCurrentStep] = useState<number>(1)

  const [nameValue, setNameValue] = useState<string>('')
  const [phoneValue, setPhoneValue] = useState<string>('')
  const [storeNameValue, setStoreNameValue] = useState<string>('')
  const [policyCheck, setPolicyCheck] = useState<boolean>(false)

  const [difficulties, setDifficulties] = useState<string>('')

  const [wantValue, setWantValue] = useState<string>('')

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const handleSubmit = async () => {
    const currentTime = new Date()

    const answerData: AnswerModel = {
      이름: nameValue,
      연락처: phoneValue,
      매장명: storeNameValue,
      'CS 가장 어려운 점': difficulties,
      '추가적으로 원하는 부분': wantValue,
      '신청 시간': currentTime.toLocaleString(),
    }

    setSubmitLoading(true)

    await setRow('cs 서비스', answerData)

    setSubmitLoading(false)
    window.alert('사전 신청이 완료되었어요!')
    history.push('/')
  }

  return (
    <Container>
      <LogoContainer>
        <BrwnieLogo />

        <BrwnieLogoMobile />
      </LogoContainer>

      <ProgressBar
        totalStep={3}
        currentStep={currentStep}
        onPreviousClick={() => {
          if (currentStep === 1) return
          setCurrentStep(currentStep - 1)
        }}
      />

      {currentStep === 1 ? (
        <>
          <Title id="title">
            안녕하세요👋
            <br />
            간단한 정보 입력 부탁해요
          </Title>

          <Content>
            <TextInputContainer>
              <TextInput
                value={nameValue}
                onChange={(e) => setNameValue(e.currentTarget.value)}
                type="text"
                placeholder="성함"
              />
              <TextInput
                value={phoneValue}
                onChange={(e) => setPhoneValue(e.currentTarget.value)}
                type="text"
                placeholder="연락처"
              />
              <TextInput
                value={storeNameValue}
                onChange={(e) => setStoreNameValue(e.currentTarget.value)}
                type="text"
                placeholder="매장명"
              />

              <PersonalPolicyCheck check={policyCheck} onClick={() => setPolicyCheck(!policyCheck)} />
            </TextInputContainer>
          </Content>

          <NextButton
            disabled={nameValue === '' || phoneValue === '' || storeNameValue === '' || policyCheck === false}
            onClick={() => {
              setCurrentStep(currentStep + 1)
            }}
          />
        </>
      ) : currentStep === 2 ? (
        <>
          <Title>
            CS 대응에서 <b>가장 어려운 점은</b> 어떤건가요?
          </Title>

          <Content>
            <RadioButtonContainer>
              {difficultiesValues.map((value) => (
                <RadioButton
                  key={value}
                  active={difficulties === value}
                  onClick={() => setDifficulties(value)}
                  label={value}
                />
              ))}
            </RadioButtonContainer>
          </Content>

          <NextButton disabled={difficulties === ''} onClick={() => setCurrentStep(currentStep + 1)} />
        </>
      ) : (
        <>
          <Title>
            추가적으로 <b>원하는 부분있다면</b> 작성해주세요
          </Title>

          <Description>남겨주신 소중한 답변을 기반으로 연락드리겠습니다.</Description>

          <Content>
            <Textarea
              placeholder="자유롭게 작성해주세요"
              value={wantValue}
              onChange={(e) => setWantValue(e.currentTarget.value)}
            />
          </Content>

          <NextButton last onClick={handleSubmit} loading={submitLoading} />
        </>
      )}
    </Container>
  )
}

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 72px;

  & > :nth-child(2) {
    display: none;
  }

  ${({ theme }) => theme.media.mobile`
      margin-top: 40px;

      & > :nth-child(1) {
        width: 0;
      }

      & > :nth-child(2) {
        display: block;
      }
  `}
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: 12px;
  }
`

const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: 16px;
  }

  & > button {
    width: 400px;
  }

  ${({ theme }) => theme.media.mobile`
    & > button {
      width: 300px;
    }
  `}
`
