import client from '../client'

export async function deleteRefundRequest(req: DeleteRefundRequestReq) {
  const response = await client.delete<undefined>(`/payment-api/v1/refund/request/user?service_id=${req.service_id}`)
  return response
}

export type DeleteRefundRequestReq = {
  service_id: string
}
