import client from '../client'
import { GetGeocodingType } from './types'

export async function getGeocoding({ address }: GetGeocodingParam) {
  const response = await client.get<GetGeocodingType>(`service-api/v1/store-map/address-geocoding?address=${address}`)

  return response.data
}

export type GetGeocodingParam = {
  address: string
}
