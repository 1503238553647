import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import StoreModifyForm from 'components/Store/StoreModifyForm'
import { useScrollGet } from 'hooks/useScrollGet'
import { useRef } from 'react'
import styled from 'styled-components'

export default function StoreModifyPage() {
  const mainRef = useRef<HTMLDivElement>(null)

  const { totalOffsetY } = useScrollGet({})
  return (
    <>
      <Header title="매장 정보 변경" offsetY={totalOffsetY} />
      <StyledMain ref={mainRef}>
        <StoreModifyForm />
      </StyledMain>
      <Footer />
    </>
  )
}

const StyledMain = styled.main`
  margin: 0 auto;
  padding: 80px 0 104px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  // mobile
  ${({ theme }) => theme.media.mobile`
    padding-top: 88px;
  `}
`
