import { useEffect } from 'react'

export type useHoverAwayListenerProps = {
  ref: React.RefObject<HTMLElement>
  onOutsideHover: () => void
}

export default function useHoverAwayListener({ ref, onOutsideHover }: useHoverAwayListenerProps) {
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) onOutsideHover()
    }

    document.addEventListener('mouseover', handler as EventListener)

    return () => {
      document.removeEventListener('mouseover', handler as EventListener)
    }
  }, [ref, onOutsideHover])
}
