import Spinner from 'components/common/Spinner'
import styled from 'styled-components'

export default function PaymentHistoryLoading() {
  return (
    <Box alignItems="center" justifyContent="center" padding="14rem 0">
      <Spinner color="main"></Spinner>
    </Box>
  )
}

const Box = styled.div<{
  direction?: string
  flex?: string
  alignItems?: string
  justifyContent?: string
  padding?: string
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  flex: ${({ flex }) => flex ?? '1 1 auto'};
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
  padding: ${({ padding }) => padding ?? undefined};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
`
