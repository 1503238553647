import serviceData from 'data/serviceData.json'
import useContractedDateQuery from 'hooks/query/contractedDate/useContractedDateQuery'
import { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { calcServicePeriod, calcTotalDate, getDay, holidayArr, weekArr } from '../../../utils/common/calendar'
import {
  selectedDateToTimezone,
  timeSelection,
  TimeSelectionType,
  timezoneToSelectedDate,
} from '../../../utils/common/time'
import Icon from '../../Icon'

// TODO: 계약된 날짜 테스트 진행해야함

export type FirstDateSelectopProps = {
  firstDate: string
  setFirstDate: React.Dispatch<React.SetStateAction<string>>
}

export default function FirstDatePicker({ firstDate, setFirstDate }: FirstDateSelectopProps) {
  const { dayContractLimit } = serviceData

  const DateClass = new Date()
  const currentYear = DateClass.getFullYear()
  const currentMonth = DateClass.getMonth() + 1
  const currentDate = DateClass.getDate()
  const currentTotalDate =
    firstDate !== ''
      ? calcTotalDate(timezoneToSelectedDate(firstDate).year, timezoneToSelectedDate(firstDate).month)
      : calcTotalDate(currentYear, currentMonth)
  const [year, setYear] = useState<number>(firstDate !== '' ? timezoneToSelectedDate(firstDate).year : currentYear)
  const [month, setMonth] = useState<number>(
    firstDate !== ''
      ? timezoneToSelectedDate(firstDate).month
      : currentTotalDate === currentDate
      ? currentMonth + 1
      : currentMonth
  )
  const [totalDate, setTotalDate] = useState<number>(0)
  const [selection, setSelection] = useState<
    | {
        year: number
        month: number
        date: number
        time: TimeSelectionType | undefined
      }
    | undefined
  >(firstDate !== '' ? timezoneToSelectedDate(firstDate) : undefined)
  // 1일의 getDay값
  const firstDayNum = getDay(year, month, 1)
  // 첫 주의 달력에서의 고를 수 없는 빈공간
  const firstWeekBlankNum = firstDayNum === 0 ? 6 : firstDayNum - 1

  const { data: contractedDate, refetch: refetchContractedDate } = useContractedDateQuery({
    year,
    month,
  })

  useEffect(() => {
    setTotalDate(calcTotalDate(year, month))
  }, [month, year])

  useEffect(() => {
    if (selection && selection.time) setFirstDate(selectedDateToTimezone({ ...selection, time: selection.time }))
    else setFirstDate('')
  }, [selection, setFirstDate])

  useEffect(() => {
    refetchContractedDate()
  }, [year, month, refetchContractedDate])

  const onLeftButtonClick = useCallback(() => {
    if (
      year === currentYear &&
      (currentTotalDate === currentDate ? month === currentMonth + 1 : month === currentMonth)
    )
      return
    if (month === 1) {
      setYear(year - 1)
      setMonth(12)
    } else setMonth(month - 1)

    setSelection(undefined)
  }, [currentYear, currentMonth, year, month, currentDate, currentTotalDate])

  const onRightButtonClick = useCallback(() => {
    if (month === 12) {
      setYear(year + 1)
      setMonth(1)
    } else setMonth(month + 1)
    setSelection(undefined)
  }, [month, year])

  const onDateClick = useCallback(
    (date: number) => {
      if (selection !== undefined && selection.year === year && selection.month === month && date === selection.date)
        return setSelection(undefined)

      setSelection({
        year: year,
        month: month,
        date: date,
        time: undefined,
      })
    },
    [month, selection, year]
  )

  const onTimeClick = (time: TimeSelectionType) => {
    if (!selection) return
    if (selection.time === time) setSelection({ ...selection, time: undefined })
    else setSelection({ ...selection, time })
  }

  return (
    <PickerWrapper>
      <Calendar>
        <CalendarHeader>
          <button type="button" onClick={onLeftButtonClick}>
            <Icon name="grayLeftArrowS" />
          </button>
          <CalendarInfo>
            <p>
              {year}년 {month}월
            </p>
            <p>
              서비스 기간&nbsp;&nbsp;:&nbsp;&nbsp;
              {selection
                ? calcServicePeriod({ year: selection.year, date: selection.date, month: selection.month })
                : '-'}
            </p>
          </CalendarInfo>
          <button type="button" onClick={onRightButtonClick}>
            <Icon name="grayRightArrowS" />
          </button>
        </CalendarHeader>
        <CalendarBody>
          {weekArr.map((day, i) => (
            <div key={day}>
              <CalendarDateText isDay={true} isActive={i >= 5 ? false : true}>
                {day}
              </CalendarDateText>
            </div>
          ))}
          {Array.from({ length: firstWeekBlankNum }).map((_, i) => (
            <div key={i} />
          ))}
          {Array.from({ length: totalDate }).map((_, i) => {
            const isWeekend = (i + 2 + firstWeekBlankNum) % 7 === 0 || (i + 1 + firstWeekBlankNum) % 7 === 0
            const date = i + 1
            const isActiveDate =
              !(
                isWeekend ||
                !!holidayArr.find(
                  (holiday) =>
                    (holiday.year ? holiday.year === year : true) && holiday.month === month && holiday.date === i + 1
                )
              ) && (currentYear === year && currentMonth === month ? i >= currentDate : true)

            return (
              <div key={i}>
                <CalendarDateText
                  isDay={false}
                  isActive={isActiveDate}
                  onClick={() => (isActiveDate ? onDateClick(date) : undefined)}
                  isClicked={
                    selection && selection.year === year && selection.month === month && selection.date === date
                  }
                >
                  {date}
                </CalendarDateText>
              </div>
            )
          })}
        </CalendarBody>
      </Calendar>
      {selection && (
        <DateSelectionContainer>
          <ul>
            {timeSelection.filter((time) => {
              if (contractedDate) {
                const dateArr = contractedDate.dates.map((date) => timezoneToSelectedDate(date))

                if (
                  dateArr.filter(
                    (date) =>
                      date.year === selection.year &&
                      date.month === selection.month &&
                      date.date === selection.date &&
                      date.time === time
                  ).length === dayContractLimit
                )
                  return false
              }
              return true
            }).length !== 0 ? (
              timeSelection.map((time) => {
                if (contractedDate) {
                  const dateArr = contractedDate.dates.map((date) => timezoneToSelectedDate(date))

                  if (
                    dateArr.filter(
                      (date) =>
                        date.year === selection.year &&
                        date.month === selection.month &&
                        date.date === selection.date &&
                        date.time === time
                    ).length === dayContractLimit
                  )
                    return null
                }

                return (
                  <li key={time}>
                    <TimeSelectButton
                      type="button"
                      onClick={() => onTimeClick(time)}
                      isClicked={time === selection.time}
                    >
                      <span>{time}</span>
                    </TimeSelectButton>
                  </li>
                )
              })
            ) : (
              <NoSelection>
                선택 가능한 시간이 없습니다.
                <br />
                다른 날을 선택해 주세요.
              </NoSelection>
            )}
          </ul>
        </DateSelectionContainer>
      )}
    </PickerWrapper>
  )
}

export const PickerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
`

export const Info = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  & > span {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    opacity: 0.5;
  }

  & > div {
    display: flex;
    align-items: center;

    & > p {
      margin-right: ${({ theme }) => theme.spacing(2)};
    }
  }

  & > p,
  & > div > p {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme }) => theme.colors.black};
  }
`

export const StoreNameText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* ellipsis line */
  -webkit-box-orient: vertical;
`

export const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  width: 26rem;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
`

export const CalendarHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > button {
    cursor: pointer;
    width: 3.6rem;
    height: 2.4rem;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

export const CalendarInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p:first-child {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme }) => theme.colors.darkGray};
  }

  & > p:last-child {
    margin-top: ${({ theme }) => theme.spacing(1)};
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.black};
  }
`

export const CalendarBody = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(-0.75)};
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  & > div {
    width: 3.2rem;
    margin: ${({ theme }) => theme.spacing(0, 0.75, 0.75, 0)};

    &:nth-child(7n) {
      margin-right: 0;
    }
  }
`

export const CalendarDateText = styled.span<{ isActive: boolean; isDay: boolean; isClicked?: boolean }>`
  font-weight: 400;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.black};
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.6rem;
  cursor: ${({ isDay }) => (isDay ? 'default' : 'pointer')};

  ${({ isClicked, theme }) =>
    isClicked &&
    css`
      background-color: ${theme.colors.main};
      color: ${({ theme }) => theme.colors.white};
      font-weight: 700;
    `}

  ${({ isActive }) =>
    !isActive &&
    css`
      opacity: 0.3;
      cursor: default;
    `}
`

export const DateSelectionContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-radius: 1.6rem;
  margin-top: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;

  & > ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: ${({ theme }) => theme.spacing(-2)};
    width: 100%;
    display: flex;

    & > li {
      margin-right: ${({ theme }) => theme.spacing(7.5)};

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  ${({ theme }) => theme.media.mobile`background-color: ${theme.colors.lightGray}`}

  ${({ theme }) => theme.media.mobile`
    border: none;
    padding: 16px 45px;

    & > ul {
      & > li {
        margin-right: 0;

        &:nth-child(2n) {
          margin-left: 50px;
        }
      }
    }
  `}
`

export const TimeSelectButton = styled.button<{ isClicked: boolean }>`
  padding: 0;
  width: 6.8rem;
  height: 3.2rem;
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing(2)};
  border-radius: 1.6rem;

  & > span {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }

  ${({ isClicked, theme }) =>
    isClicked
      ? css`
          background-color: ${theme.colors.main};
          color: ${theme.colors.white};
        `
      : css`
          background-color: transparent;
          color: ${theme.colors.black};
        `}

  ${({ theme }) => theme.media.mobile`
    width: 64px;
    height: 32px;
    opacity: 1;
  `}
`

const NoSelection = styled.li`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  width: 100%;
  text-align: center;
  margin: 0 !important;
  padding-top: 16px;
`
