import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import usePostSendEmailForResetPassword from 'hooks/query/authVerify/usePostSendEmailForResetPassword'
import Spinner from 'components/common/Spinner'
import { useEffect, useState } from 'react'
import Icon from 'components/Icon'

interface IFormInput {
  email: string
}

export default function PwResetEmailGet() {
  const { mutateAsync: mutateSendEmailForResetPassword, isError, error } = usePostSendEmailForResetPassword()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [focusInput, setFocusInput] = useState<boolean>(false)
  let errorMessage = error as any

  useEffect(() => {
    if (isError) {
      setIsLoading(false)
    }
  }, [isError])

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<IFormInput>({ mode: 'onChange' })

  const onSubmit = async (data: IFormInput) => {
    setIsLoading(true)
    setFocusInput(false)
    await mutateSendEmailForResetPassword({ email: data.email })
    setIsLoading(false)
  }

  return (
    <PasswordResetWrapper>
      <StyledResetPwForm>
        <Wrapper>
          <FormInput
            placeholder="이메일 주소"
            type="email"
            {...register('email', {
              required: true,
              minLength: 10,
              pattern: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
            })}
            onFocus={() => setFocusInput(true)}
          />
          <span>
            {isError &&
              !focusInput &&
              watch('email') &&
              watch('email').length !== 0 &&
              errorMessage.response.status !== 405 && <WarningIcon name="redError" />}
          </span>
        </Wrapper>

        {isError && !focusInput && watch('email') && watch('email').length !== 0 && (
          <WarningText>{errorMessage.response.data.message}</WarningText>
        )}

        <LoginButton type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          {isLoading ? <Spinner color="white" /> : '재설정 메일 발송'}
        </LoginButton>
      </StyledResetPwForm>
    </PasswordResetWrapper>
  )
}

const PasswordResetWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.media.mobile`
  padding-top: ${theme.spacing(5)}
  `}
`

const StyledResetPwForm = styled.form`
  width: 100%;
  position: relative;
`

const Wrapper = styled.div<{ block?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.1rem 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.gray};
  border-radius: 0.8rem;
  background-color: white;

  span {
    flex: 0 0 auto;
    font-size: 1.6rem;
    cursor: pointer;
    background-color: white;
    color: ${({ theme }) => theme.colors.black};
  }
  :focus-within {
    ime-mode: disabled;
    -webkit-ime-mode: disabled;
    -moz-ime-mode: disabled;
    -ms-ime-mode: disabled;
    border: solid 0.1rem #003399;
  }
`

const FormInput = styled.input<{ fill?: string; placeholder?: string }>`
  width: 100%;
  border: 0;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2.4rem;
  padding: 0;

  ime-mode: disabled;
  -webkit-ime-mode: disabled;
  -moz-ime-mode: disabled;
  -ms-ime-mode: disabled;

  &:focus {
    ime-mode: disabled;
    -webkit-ime-mode: disabled;
    -moz-ime-mode: disabled;
    -ms-ime-mode: disabled;
  }

  ${({ fill }) =>
    fill === 'true' &&
    `
  border: solid 0.1rem #003399;
  `}
  ::placeholder {
    opacity: 0.3;
  }
`

const WarningIcon = styled(Icon)`
  cursor: default;
`

const WarningText = styled.div`
  line-height: 2.4rem;
  font-size: 1.6rem;
  color: ${({ theme }) => `${theme.colors.warning}`};
  margin-top: 0.8rem;
`
const LoginButton = styled.button<{ disabled?: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 4.8rem;
  border: none;
  margin-top: 2.4rem;
  border-radius: 0.8rem;
  -webkit-appearance: none;
  background-color: ${({ theme }) => `${theme.colors.main}`};
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => `${theme.colors.white}`};

  ${({ disabled }) =>
    disabled === true &&
    `
    background-color: #edeff1;
    color: #1e1e2c;
    opacity: 0.5;
    cursor: no-drop;
  `}

  ${({ theme }) => theme.media.mobile`
    margin-top: 1.6rem;
  `}
`
