import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import useServiceQuery from 'hooks/query/service/useServiceQuery'
import { Link } from 'react-router-dom'
import usePaymentQuery from 'hooks/query/payment/usePaymentQuery'
import useAuth from 'hooks/query/auth/useAuth'
import { SelectedDateType, TimeSelectionType, timezoneToSelectedDate } from '../../utils/common/time'
import useBvsQuery from 'hooks/query/bvs/useBvsQuery'
import { useEffect, useMemo, useState } from 'react'
import buildStoreSummary from 'utils/common/buildStoreSummary'
import ServiceCompleteLoading from './ServiceCompleteLoading/ServiceCompleteLoading'
import ServiceBadge from 'components/StorePage/StoreList/StoreItem/ServiceBadge/ServiceBadge'
import ServiceTopStateBox from './ServiceTopStateBox'
import ServicePaymentPrice from './ServicePaymentPrice/ServicePaymentPrice'
import LogManager from 'lib/utils/logger'
import ServiceCancelModal from 'components/common/ServiceCancelModal/ServiceCancelModal'
import { getServiceBadgeType } from 'utils/common/getServiceBadgeType'
import { isMobile } from 'react-device-detect'
import ServiceCancelBottomSheet from 'components/common/ServiceCancelBottomSheet/ServiceCancelBottomSheet'
import { ROUTES } from 'utils/common/routes'
import { CCLViewController, CCLViewer } from 'components/PaymentForm/PaymentForm'
import BvsViewer from 'components/PaymentForm/BvsViewer'
import SubTitle from 'components/PaymentForm/SubTitle'
import useStoreQuery from 'hooks/query/store/useStoreQuery'
import Icon from 'components/Icon'

export default function ServiceComplete() {
  const { serviceId } = useParams<{ serviceId: string }>()
  const { auth } = useAuth()
  const { data: service, isLoading } = useServiceQuery({ id: serviceId })
  const { data: store } = useStoreQuery({ id: service?.store_id as string }, { enabled: !!service })
  const { data: payment } = usePaymentQuery(
    { id: service?.payment_id as number, userId: auth?.id as number },
    { enabled: !!auth && !!service }
  )
  const { data: bvs } = useBvsQuery({ id: service?.bvs_id as number }, { enabled: !!service })

  const [openContractCancelModal, setOpenContractCancelModal] = useState<boolean>(false)
  const [openContractCancelBottomSheet, setOpenContractCancelBottomSheet] = useState<boolean>(false)

  const [isCCLOpen, setIsCCLOpen] = useState<boolean>(false)

  const firstDate = timezoneToSelectedDate(bvs?.reservation_dates[0] as string)

  const totalReservationDate: { year: number; month: number; date: number; time: TimeSelectionType }[] = []
  const bvsDates = bvs ? [...bvs?.reservation_dates].sort() : []

  useEffect(() => {
    bvsDates.map((date) => totalReservationDate.push(timezoneToSelectedDate(date)))
  }, [bvsDates.length])

  const handleServiceDeleteClick = () => {
    if (isMobile) {
      setOpenContractCancelBottomSheet(true)
    } else {
      setOpenContractCancelModal(true)
    }

    LogManager.Instance.sendLog('store_service_payment_delete', {
      btn_name: '해지',
      btn_url: 'NULL',
    })
  }

  const serviceText = useMemo(() => {
    return `${service && buildStoreSummary(service)}`
  }, [service])

  return (
    <StyledServiceComplete>
      {isLoading ? (
        <ServiceCompleteLoading />
      ) : (
        service && (
          <>
            <ServiceTopStateBox
              stateProp={getServiceBadgeType({
                service,
                payment,
                startDate: bvsDates[0],
                endDate: bvsDates[bvsDates.length - 1],
              })}
              isSubscribePayment={!!service?.is_regular_payment}
              serviceId={serviceId}
            />

            <PaymentInfo>
              <PaymentInfoTitle>
                <h3>계약 확인서</h3>
              </PaymentInfoTitle>
              <ConfirmSection>
                <div>
                  <TitleWrap>
                    <SubTitle>{serviceText}</SubTitle>
                    {service?.is_regular_payment && <ServiceBadge badgeType="SUBSCRIBE" />}
                  </TitleWrap>
                  <BvsViewer
                    isSubscribePayment={service?.is_regular_payment}
                    firstDate={firstDate as SelectedDateType}
                    totalReservationDate={totalReservationDate}
                    extraDates={[]}
                  />
                </div>

                <div>
                  <CCLViewController onClick={() => setIsCCLOpen(!isCCLOpen)} isCCLOpen={isCCLOpen}>
                    <p>신청서 보기</p>
                    <Icon name="blackDownArrow" />
                  </CCLViewController>
                  <CCLViewer isOpen={isCCLOpen} onClick={(e) => e.stopPropagation()}>
                    <li>
                      <p>1. 매장 주소</p>
                      <p>
                        {store?.address_name} {store?.detail_address}
                      </p>
                    </li>
                    <li>
                      <p>2. 매장명</p>
                      <p>{store?.store_name}</p>
                    </li>
                    <li>
                      <p>3. 무인매장 유형</p>
                      <p>{store?.type}</p>
                    </li>
                    <li>
                      <p>4. 관리 일정</p>
                      <p>{serviceText}</p>
                    </li>
                    <li>
                      <p>5. 첫 관리 시작일</p>
                      <p>
                        {firstDate.year}.{firstDate.month}.{firstDate.date}
                      </p>
                    </li>
                    <li>
                      <p>6. 첫 관리 시작 시간</p>
                      <p>{firstDate.time}</p>
                    </li>
                  </CCLViewer>
                </div>
              </ConfirmSection>
            </PaymentInfo>
            <ServicePaymentPrice serviceId={serviceId} />
          </>
        )
      )}
      {!isLoading && (
        <>
          {!service?.is_refund && (
            <ServiceDeleteButton onClick={handleServiceDeleteClick}>계약 해지</ServiceDeleteButton>
          )}
          <ToMyPage to={ROUTES.STORE}>관리페이지 가기</ToMyPage>
          <ServiceCancelModal
            open={openContractCancelModal}
            onClose={() => setOpenContractCancelModal(false)}
            serviceId={serviceId}
            isPaid={true}
            isVbank={payment?.payment_method === 'VBank'}
          />
          <ServiceCancelBottomSheet
            open={openContractCancelBottomSheet}
            onClose={() => setOpenContractCancelBottomSheet(false)}
            serviceId={serviceId}
            isPaid={true}
            isVbank={payment?.payment_method === 'VBank'}
          />
        </>
      )}
    </StyledServiceComplete>
  )
}

const StyledServiceComplete = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;

  & > h4 {
    margin-bottom: 0px;
    margin-right: 8px;
  }

  margin-bottom: 32px;
`

const ToMyPage = styled(Link)`
  color: var(--gray-gray-600, #757575);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  margin-top: 8px;
`

const ServiceDeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--gray-gray-600, #757575);
  font-size: 14px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 24px;
`

const ConfirmSection = styled.section`
  width: 50rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  padding-top: ${({ theme }) => theme.spacing(4)};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: hidden;

  h4 {
    margin-top: 0;
  }

  & > div {
    padding: ${({ theme }) => theme.spacing(0, 4)};
    background-color: white;
  }

  & > div:nth-child(2) {
    padding: 0;
    & > div {
      padding: ${({ theme }) => theme.spacing(0, 4)};
      padding-top: ${({ theme }) => theme.spacing(4)};
      padding-bottom: ${({ theme }) => theme.spacing(4)};
    }
  }

  ${({ theme }) => theme.media.mobile`
    width: 312px;
    & > div {
      padding: 0 16px;
    }

    & > div:nth-child(2) {
      padding: 0;
      & > div {
        padding: 16px;
      }
    }
  `}
`

const PaymentInfo = styled.div`
  width: 50rem;
  border-radius: 1.6rem;
  background-color: ${({ theme }) => theme.colors.white};
  height: fit-content;
  margin-top: 24px;

  ${({ theme }) => theme.media.mobile`
    width: 312px;
  `}
`

const PaymentInfoTitle = styled.div`
  width: 100%;
  height: 8rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-bottom: none;
  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem;

  & > h3 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.8rem;
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.media.mobile`
    height: 48px;
    
    & > h3 {
      font-size: 16px;
      line-height: 24px;
    }
  `}
`
