import styled from 'styled-components'

const FormTextInput = styled.input`
  width: 240px;
  height: 28px;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-left: 8px;
  border-color: ${({ theme }) => theme.colors.gray};
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  line-height: 24px;

  // mobile
  ${({ theme }) => theme.media.mobile`
    width: 200px;
    height: 24px;
    font-size: 12px;
    line-height: 16px;
  `}
`

export default FormTextInput
