import Button from 'components/common/Button'
import Footer from 'components/common/Footer'
import QuestionBox from 'components/Faq'
import LandingNavBar from 'components/Landing/LandingNavBar'
import { useState } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import ApplyBanner from 'components/common/ApplyBanner'
import QuestionList from '../../data/faqQuestionData.json'
import Pagination from 'components/common/Pagination'
import { paginate, PaginateType } from 'utils/common/paginate'
import { useScrollGet } from 'hooks/useScrollGet'

let wholeInitState: boolean[] = []
wholeInitState.length = QuestionList.length
wholeInitState.fill(false)
export default function FaqPage() {
  const { totalOffsetY } = useScrollGet({})
  const [isOpen, setIsOpen] = useState<boolean[]>(wholeInitState)

  //모바일에서 페이지네이션을 적용하기 위한 state
  const [itemSplit, setItemSplit] = useState<PaginateType>({
    items: QuestionList,
    perPageMaxLen: 6,
    currentPage: 1,
  })
  const { items, perPageMaxLen, currentPage } = itemSplit
  const pagedArrList = paginate({ items, perPageMaxLen, currentPage })

  const { length: itemsLength } = items

  const handleChangePage = (page: number) => {
    setIsOpen(isOpen.map((item) => (item = false)))
    setItemSplit({ ...itemSplit, currentPage: page })
  }

  return (
    <>
      <LandingNavBar offsetY={totalOffsetY ? 1 : 0} />
      <StyledMain>
        <StyledHeader />
        <DescriptionBox>
          <div className="bar" />
          <h2>FAQ</h2>
          <p>
            브라우니 서비스에 대해 자주 묻는 질문입니다.
            <br />더 궁금한 점이 있으시다면 아래 버튼을 눌러 문의주세요.
          </p>
          <a href="https://brwnie.channel.io/" target="_blank" rel="noreferrer">
            <Button buttonTheme="black_secondary" width={160}>
              1:1 문의하기
            </Button>
          </a>
        </DescriptionBox>
        <MainSection>
          {(isMobile ? pagedArrList : QuestionList).map((item, index) => (
            <QuestionBox key={index} questionOrder={index} questionTitle={item} setIsOpen={setIsOpen} isOpen={isOpen} />
          ))}
          <PaginationWrapper>
            <Pagination
              perPageMaxLen={perPageMaxLen}
              itemsCount={itemsLength}
              currentPage={currentPage}
              onChangePage={handleChangePage}
            />
          </PaginationWrapper>

          <ApplyBanner marginTop={96} mobileMarginTop={80} />
        </MainSection>
      </StyledMain>
      <Footer />
    </>
  )
}

const StyledMain = styled.main`
  position: relative;
`

const StyledHeader = styled.header`
  background: url('/image/faqBlurImage.webp');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 400px;

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    height: 240px;
  `}
`

const DescriptionBox = styled.article`
  width: 1173px;
  height: 376px;
  background: linear-gradient(180deg, #ffffff 9.38%, rgba(255, 255, 255, 0) 100%);
  -webkit-box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: calc(50% - 586.5px);
  top: 204px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  .bar {
    width: 100%;
    height: 16px;
    background-color: ${({ theme }) => theme.colors.blue};
  }

  h2 {
    margin-top: 64px;
    font-size: 64px;
    line-height: 64px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 700;
  }

  p {
    font-size: 24px;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 400;
    margin-top: 72px;
    margin-bottom: 24px;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    width: 320px;
    height: 336px;
    padding-bottom: 32px;
    top: 132px;
    left: calc(50% - 160px);

    .bar {
      height: 8px;
    }

    h2 {
      font-size: 38px;
      margin-top: 24px;
      line-height: 48px;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      width: 256px;
      margin-top: 52px;
      word-break: keep-all;
    }
  `}
`

const PaginationWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
  }
`

const MainSection = styled.section`
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.lightGray};
  position: relative;
  padding-top: 300px;
  padding-bottom: 216px;
  z-index: 1;
`
