import client from 'lib/api/client'
import { PostBrwnieSelfCrewReq } from './type'

export async function postBrwnieSelfCrew(value: PostBrwnieSelfCrewReq) {
  const response = await client.post(`/admin-api/v1/brwnie/selfCrew`, {
    ...value,
  })

  return response.data
}
