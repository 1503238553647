export enum POINTS_STATE {
  ACTIVE = 'ACTIVE',      // 활성화된 포인트
  INACTIVE = 'INACTIVE',  // 비활성화된 포인트
  EXPIRED = 'EXPIRED',    // 만료된 포인트
  EXHAUST = 'EXHAUST'     // 소진된 포인트
}

export type GetMyPointsResponse = {
  owned_points: number        // 현재 소유중인 총 포인트
  will_expiry_points: number  // 30일 안에 만료될 포인트
}

export type GetUsePointsRequest = {
  page: number,
  state?: POINTS_STATE
}

export type GetPointsRequest = {
  page: number,
  state?: POINTS_STATE
}

export type UsedPoints = {
  id: number,             // 포인트 사용 기록 id
  used_by: number,        // 포인트 사용 유저 id
  used_points: number,    // 사용한 포인트
  used_at: number,        // 포인트 사용시점
  reason: string,         // 포인트 사용 이유
  points: {
    id: number            // 포인트 획득 기록 id
    reason: string        // 포인트 획득 사유
    points: number,       // 획득한 포인트
    remaining_points: 0,  // 사용한 포인트
    state: string,        // 포인트 상태 [ACTIVE: 사용가능, INACTIVE: 사용 불가능, EXPIRED: 포인트 만료, EXHAUST: 포인트 소진]
    owned_by: number,     // 포인트 소유 유저 id
    expired_at: string,   // 포인트 만료 시간
    created_at: string    // 포인트 생성 시간
  }
}

export type RefundPoints = {
  id: number
  refunded_points: number
  refund_id: number
  use_history_id: number
  created_at: string
}

export type Points = {
  id: number                // 얻은 포인트 ID
  points: number            // 얻은 포인트
  remaining_points: number  // 남은 포인트
  state: string             // 포인트 상태 [ACTIVE: 사용가능, INACTIVE: 사용 불가능, EXPIRED: 포인트 만료, EXHAUST: 포인트 소진]
  owned_by: number          // 소유한 유저 ID
  gained_reason: string     // 획득한 사유
  expired_at: string        // 포인트 만료일
  created_at: string        // 포인트 생성일
}

export type GetUsePointsResponse = {
  points: UsedPoints[]  // 10개 단위로 잘린 포인트 목록
  count: number         // 총 포인트 개수
}

export type GetPointsResponse = {
  points: (UsedPoints | Points | RefundPoints)[]  // 10개 단위로 잘린 포인트 목록
  count: number         // 총 포인트 개수
}