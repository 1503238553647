import client from '../client'
export async function postSocialOption(req: SocialOptionReq) {
  const response = await client.post<undefined>('auth-api/v1/auth/sign-up/social-option', req)

  return response.data
}

export type SocialOptionReq = {
  real_name: string
  email: string
  referral_code?: string | null
  birth_year?: string
  birth_day?: string
  gender?: 'M' | 'F' | 'U'
  marketing_agree?: boolean
}
