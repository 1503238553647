import Footer from 'components/common/Footer'
import FloatingApplyButton from 'components/FloatingApplyButton'
import LandingNavBar from 'components/Landing/LandingNavBar'
import ServiceDetailFirstSection from 'components/NewServiceDetail/ServiceDetailFirstSection'
import ServiceDetailFourthSection from 'components/NewServiceDetail/ServiceDetailFourthSection'
import ServiceDetailSecondSection from 'components/NewServiceDetail/ServiceDetailSecondSection'
import ServiceDetailThirdSection from 'components/NewServiceDetail/ServiceDetailThirdSection'
import { useScrollGet } from 'hooks/useScrollGet'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'

export default function ServiceDetailPage() {
  const mainRef = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()

  const { totalOffsetY, scrollPosition } = useScrollGet({})
  const [offsetHeight, setOffsetHeight] = useState<number>(0)

  useEffect(() => {
    if (mainRef.current) {
      setOffsetHeight(mainRef.current.offsetHeight)
    }
  }, [])

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <ServiceDetailWrapper ref={mainRef}>
      <LandingNavBar offsetY={totalOffsetY ? 1 : 0} />
      <SectionWrapper>
        <ServiceDetailFirstSection />
        <ServiceDetailSecondSection />
        <ServiceDetailThirdSection />
        <ServiceDetailFourthSection />
        <Footer />
      </SectionWrapper>
      <FloatingApplyButton scrollPosition={scrollPosition} offsetHeight={offsetHeight} />
    </ServiceDetailWrapper>
  )
}

const ServiceDetailWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  position: relative;

  @media only screen and (max-width: 768px) {
    height: 100%;
  }
`

const SectionWrapper = styled.div`
  width: 100%;
`
