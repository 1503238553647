import useAuth from 'hooks/query/auth/useAuth'
import usePaymentQuery from 'hooks/query/payment/usePaymentQuery'
import useServiceQuery from 'hooks/query/service/useServiceQuery'
import useStoreQuery from 'hooks/query/store/useStoreQuery'
import styled from 'styled-components'
import addComma from 'utils/common/addComma'

type ServicePaymentPriceProp = {
  serviceId: string
}

export default function ServicePaymentPrice({ serviceId }: ServicePaymentPriceProp) {
  const { auth } = useAuth()

  const { data: service } = useServiceQuery({ id: serviceId })
  const { data: store } = useStoreQuery({ id: service?.store_id as string }, { enabled: !!service })

  const paymentId = service?.payment_id

  const { data: payment } = usePaymentQuery(
    {
      userId: auth?.id as number,
      id: paymentId as number,
    },
    {
      enabled: !!auth && !!service,
      retry: 3,
    }
  )

  const servicePriceBeforeLongTermDiscount =
    service?.total_week_count === 12 || service?.total_week_count === 24
      ? Math.floor(((service?.service_price ?? 0) * 100) / (service?.total_week_count === 12 ? 95 : 90) / 1000) * 1000
      : service?.service_price ?? 0

  const servicePriceAbout전담BeforeDiscount = Math.floor(((service?.service_price ?? 0) * 100) / 90 / 1000) * 1000
  const serviceDiscountPriceAbout전담 = Math.floor((servicePriceAbout전담BeforeDiscount * 10) / 100 / 1000) * 1000

  const handleOpenChannelTalk = () => {
    window.open('https://brwnie.channel.io/')
  }

  return (
    <Container>
      <Title>결제 금액 확인</Title>
      <PriceList>
        <PriceItem>
          <ItemTitle>
            서비스 이용료 ({service?.service_time_minute}분{store?.type === '탁구발전소24' && ' + 10분'})
          </ItemTitle>
          <ItemPrice>
            {addComma(
              store?.type === '전담게이트' || store?.type === '전담플레이'
                ? servicePriceAbout전담BeforeDiscount
                : servicePriceBeforeLongTermDiscount
            )}{' '}
            원
          </ItemPrice>
        </PriceItem>

        {service && (store?.type === '전담게이트' || store?.type === '전담플레이') && (
          <PriceItem>
            <ItemTitle>{store?.type === '전담게이트' ? `전담 게이트` : `전담 플레이`} 10% 할인</ItemTitle>
            <ItemPrice>-{addComma(serviceDiscountPriceAbout전담)}원</ItemPrice>
          </PriceItem>
        )}

        {(service?.total_week_count === 12 || service?.total_week_count === 24) && (
          <PriceItem>
            <ItemTitle>{service?.total_week_count}주 결제 할인</ItemTitle>
            <ItemPrice>
              -
              {service.total_week_count === 12
                ? addComma(Math.floor((servicePriceBeforeLongTermDiscount * 5) / 100 / 1000) * 1000)
                : addComma(Math.floor((servicePriceBeforeLongTermDiscount * 10) / 100 / 1000) * 1000)}{' '}
              원
            </ItemPrice>
          </PriceItem>
        )}

        <PriceItem>
          <ItemTitle>포인트 적용</ItemTitle>
          <ItemPrice>{!!payment?.point ? `-${addComma(payment?.point)}` : 0} 원</ItemPrice>
        </PriceItem>

        <PriceItem>
          <ItemTitle>쿠폰 적용</ItemTitle>
          <ItemPrice>
            {!!payment?.coupon
              ? payment.coupon.discount_price
                ? `-${addComma(payment.coupon.discount_price)}`
                : `-${addComma(
                    ((Number(service?.service_price) + Number(service?.cash_management_price)) *
                      Number(payment.coupon.discount_rate)) /
                      100
                  )}`
              : 0}{' '}
            원
          </ItemPrice>
        </PriceItem>
      </PriceList>
      <StyledLine />
      <TotalPrice>
        <ItemPrice>결제 총 금액</ItemPrice>
        <ItemPrice>
          {!!payment?.point
            ? !!payment.coupon
              ? !!payment.coupon.discount_price
                ? addComma(
                    (service?.service_price as number) +
                      Number(service?.cash_management_price) -
                      payment.point -
                      payment.coupon.discount_price
                  )
                : addComma(
                    (service?.service_price as number) +
                      Number(service?.cash_management_price) -
                      payment.point -
                      ((Number(service?.service_price) + Number(service?.cash_management_price)) *
                        Number(payment.coupon.discount_rate)) /
                        100
                  )
              : addComma((service?.service_price as number) + Number(service?.cash_management_price) - payment.point)
            : !!payment?.coupon
            ? !!payment.coupon.discount_price
              ? addComma(
                  (service?.service_price as number) +
                    Number(service?.cash_management_price) -
                    payment.coupon.discount_price
                )
              : addComma(
                  (service?.service_price as number) +
                    Number(service?.cash_management_price) -
                    ((Number(service?.service_price) + Number(service?.cash_management_price)) *
                      Number(payment.coupon.discount_rate)) /
                      100
                )
            : addComma((service?.service_price as number) + Number(service?.cash_management_price))}{' '}
          원
        </ItemPrice>
      </TotalPrice>
      <TaxBillApplyButton onClick={handleOpenChannelTalk}>세금계산서 발행</TaxBillApplyButton>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;

  display: flex;
  padding: 16px 16px 24px 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--gray200);
  background: var(--white, #fff);

  margin-top: 24px;
`

const Title = styled.h5`
  color: var(--black, #1e1e2c);
  font-size: 12px;
  font-weight: 700;
`

const PriceList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const PriceItem = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: #000;
  font-size: 14px;

  margin-top: 16px;
`

const ItemTitle = styled.span`
  opacity: 0.5;
`
const ItemPrice = styled.span`
  font-weight: 700;
`
const StyledLine = styled.hr`
  width: 296px;
  height: 1px;
  background: #edeff1;
  border: none;
  margin: 16px 0px;
`

const TotalPrice = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: #000;
  font-size: 14px;

  margin-top: 16px;
`

const TaxBillApplyButton = styled.button`
  display: flex;
  height: 40px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--navy-navy-100, #cdf);
  color: var(--navy-navy-500, #026);
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
  cursor: pointer;
`
