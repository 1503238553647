import React, { useMemo } from 'react'
import styled from 'styled-components'

import { ScheduleType } from 'modules/features/service/serviceSlice'
import { createDateArr } from 'utils/common/service'

import DayBar from '../DayBar'
import YearAndMonth from '../YearAndMonth'

import FirstVisitDate from './FirstVisitDate'
import InvalidDate from './InvalidDate'
import ModifyDate from './ModifyDate'
import ConfirmDate from './ConfirmDate'
import { useAppSelector } from 'hooks/useReduxHook'

export type CalendarFormProps = {
  type: 'firstVisit' | 'invalidDay' | 'invalidTime' | 'modify' | 'confirm' | 'cashManage'
  firstYear: number
  firstMonth: number
  newSchedule?: ScheduleType
  onDateClick: (year: number, month: number, date: number, day: number) => void | Promise<void>
  cashManageSchedules?: {
    year: number
    month: number
    date: number
  }[]
}

export default function CalendarForm({
  type,
  firstYear,
  firstMonth,
  newSchedule,
  onDateClick,
  cashManageSchedules,
}: CalendarFormProps) {
  const { schedules, serviceWeek } = useAppSelector((state) => state.service)

  const calendarLength = useMemo(() => {
    if (type === 'confirm' || type === 'modify') {
      if (serviceWeek && serviceWeek >= 12) {
        const lastSchedule = schedules[schedules.length - 1]
        const firstSchedule = schedules[0]

        const lastDate = new Date(lastSchedule.year, lastSchedule.month, lastSchedule.date)
        const firstDate = new Date(firstSchedule.year, firstSchedule.month, firstSchedule.date)

        const dif = lastDate.getTime() - firstDate.getTime()
        const cDay = 24 * 60 * 60 * 1000
        const cMonth = cDay * 30

        return dif / cMonth
      }
    }
    return 3
  }, [schedules, serviceWeek])

  return (
    <>
      <DayBar />
      <CalendarContainer>
        {Array.from({ length: calendarLength }).map((_, i) => {
          const monthPlusIndex = firstMonth + i
          const isMonthOver12 = monthPlusIndex > 12

          const year = isMonthOver12 ? firstYear + 1 : firstYear
          const month = isMonthOver12 ? monthPlusIndex - 12 : monthPlusIndex

          const dateArr = createDateArr(year, month)

          return (
            <React.Fragment key={i}>
              <YearAndMonth year={year} month={month} />

              {Array.from({ length: dateArr.length / 7 }).map((_, lineIndex) => (
                <WeekContainer key={lineIndex}>
                  {dateArr.slice(7 * lineIndex, 7 * lineIndex + 7).map((date, day) => {
                    if (type === 'firstVisit')
                      return (
                        <FirstVisitDate
                          key={day}
                          year={year}
                          month={month}
                          date={date}
                          day={day}
                          newSchedule={newSchedule}
                          onDateClick={onDateClick}
                        />
                      )

                    if (type === 'invalidDay' || type === 'invalidTime')
                      return (
                        <InvalidDate
                          key={day}
                          invalidType={type === 'invalidDay' ? 'day' : 'time'}
                          year={year}
                          month={month}
                          date={date}
                          day={day}
                          newSchedule={newSchedule}
                          onDateClick={onDateClick}
                        />
                      )

                    if (type === 'modify')
                      return (
                        <ModifyDate
                          key={day}
                          year={year}
                          month={month}
                          date={date}
                          day={day}
                          onDateClick={onDateClick}
                        />
                      )

                    if (type === 'confirm')
                      return (
                        <ConfirmDate
                          key={day}
                          year={year}
                          month={month}
                          date={date}
                          day={day}
                          onDateClick={onDateClick}
                        />
                      )

                    return null
                  })}
                </WeekContainer>
              ))}
            </React.Fragment>
          )
        })}
      </CalendarContainer>
    </>
  )
}

const CalendarContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 16px;
  height: calc(100% - 56px - 113px - 56px - 80px);

  @media only screen and (min-width: 768px) {
    height: calc(100% - 113px - 56px);

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const WeekContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
