import useAuthQuery from './useAuthQuery'

export default function useAuth() {
  const { data: auth, isLoading: isAuthLoading } = useAuthQuery(
    {},
    {
      retry: 0,
    }
  )

  const isLogin = !!(auth && auth.opt_check)

  return {
    auth,
    isLogin,
    isAuthLoading,
  }
}
