import { useQuery } from 'react-query'
import { getServicesPayments, GetServicesPayments } from 'lib/api/service/getServicesPayments'
import { QueryOptionsOf } from 'lib/utils/types'

export default function useServicesPaymentQuery(
  value: GetServicesPayments,
  options: QueryOptionsOf<typeof getServicesPayments> = {}
) {
  return useQuery(createKey(value), () => getServicesPayments(value), options)
}

const createKey = (value: GetServicesPayments) => [
  'services_payment',
  value.userId,
  value.state,
  value.product,
  value.totalWeekCount,
  value.isRegularPayment,
  value.period,
  value.page,
]
useServicesPaymentQuery.createKey = createKey
