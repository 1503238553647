export const bankCodes: { [index: string]: string } = {
  '02':	'한국산업은행',
  '03':	'기업은행',
  '04':	'국민은행',
  '05':	'하나은행(구.외환)',
  '06':	'국민은행(구.주택)',
  '07':	'수협중앙회',
  '11':	'농협중앙회',
  '12':	'단위농협',
  '16':	'축협중앙회',
  '20':	'우리은행',
  '21':	'구)조흥은행',
  '22':	'상업은행',
  '23':	'SC제일은행',
  '24':	'한일은행',
  '25':	'서울은행',
  '26':	'구)신한은행',
  '27':	'한국씨티은행',
  '31':	'대구은행',
  '32':	'부산은행',
  '34':	'광주은행',
  '35':	'제주은행',
  '37':	'전북은행',
  '38':	'강원은행',
  '39':	'경남은행',
  '41':	'비씨카드',
  '48':	'신용협동조합중앙회',
  '50':	'상호저축은행',
  '53':	'한국씨티은행',
  '54':	'홍콩상하이은행',
  '55':	'도이치은행',
  '56':	'ABN암로',
  '57':	'JP모건',
  '59':	'미쓰비시도쿄은행',
  '60':	'BOA',
  '64':	'산림조합',
  '70':	'신안상호저축은행',
  '71':	'우체국',
  '81':	'하나은행',
  '83':	'평화은행',
  '87':	'신세계',
  '88':	'신한은행',
  '89':	'케이뱅크',
  '90':	'카카오뱅크',
  '91':	'네이버포인트(포인트 100 % 사용)',
  '93':	'토스머니(포인트 100 % 사용)',
  '94':	'SSG머니(포인트 100 % 사용)',
  '96':	'엘포인트(포인트 100 % 사용)',
  '97':	'카카오머니',
  '98':	'페이코 (포인트 100% 사용)'
}

export const cardCodes: { [index: string]: string } = {
  '01': '외환카드',	
  '03':	'롯데카드',
  '04': '현대카드',
  '06': '국민카드',
  '11': '비씨카드',
  '12': '삼성카드',
  '14': '신한카드',
  '15': '한미카드',
  '16': 'NH농협카드',
  '17': '하나SK카드',
  '21': '해외비자',
  '22': '해외마스터',
  '23': '해외JCB',
  '24': '해외아멕스',
  '25': '해외다이너스',
  '91': '네이버포인트 (포인트 100% 사용)',
  '93': '토스머니 (포인트 100% 사용)',
  '94': 'SSG머니 (포인트 100% 사용)',
  '96': '엘포인트 (포인트 100% 사용)',
  '97': '카카오머니',
  '98': '페이코 (포인트 100% 사용)'
}