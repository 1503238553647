import React from 'react'
import styled, { css } from 'styled-components'

type ButtonThemeType =
  | 'primary'
  | 'secondary'
  | 'third'
  | 'disabled'
  | 'black_secondary'
  | 'black_primary'
  | 'red'
  | 'yellow'

type StyledButtonProps = {
  width: number
  mobileWidth?: number
  height?: 'default' | 'small'
  buttonTheme?: ButtonThemeType
}

export type ButtonProps = {
  onClick?: (value?: any) => void
  children: any
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  StyledButtonProps

export default function Button({ buttonTheme, children, height = 'default', ...props }: ButtonProps) {
  return (
    <StyledButton buttonTheme={buttonTheme} height={height} disabled={buttonTheme === 'disabled'} {...props}>
      {Array.isArray(children) ? (
        <>
          {children[0]}
          <span>{children[1]}</span>
        </>
      ) : (
        <span>{children}</span>
      )}
    </StyledButton>
  )
}

const StyledButton = styled.button<StyledButtonProps>`
  min-height: ${({ height }) => (height === 'default' ? 4.8 : 4)}rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }

  & > span {
    font-weight: 400;
    line-height: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ height }) => (height === 'default' ? 1.6 : 1.4)}rem;
  }

  ${({ width }) => css`
    width: ${width / 10}rem;
    min-width: ${width / 10}rem;
  `}

  ${({ buttonTheme }) => buttonTheme && themeToCss(buttonTheme)}

  ${({ theme }) => theme.media.mobile`
    min-height: 40px;

    & > span {
      font-size: 14px;
      line-height: 2px;
      height: 2px;
    }
  `}

  @media only screen and (max-width: 768px) {
    ${({ mobileWidth }) =>
      mobileWidth &&
      css`
        width: ${mobileWidth}px;
        min-width: ${mobileWidth}px;
      `}
  }
`

function themeToCss(buttonTheme: ButtonThemeType) {
  switch (buttonTheme) {
    case 'primary':
      return css`
        background-color: ${({ theme }) => theme.colors.main};

        & > span {
          color: ${({ theme }) => theme.colors.white};
        }
      `
    case 'secondary':
      return css`
        background-color: ${({ theme }) => theme.colors.white};
        border: 0.1rem solid ${({ theme }) => theme.colors.main};

        & > span {
          color: ${({ theme }) => theme.colors.main};
        }
      `
    case 'third':
      return css`
        background-color: ${({ theme }) => theme.colors.white};
        border: 0.1rem solid ${({ theme }) => theme.colors.gray};

        & > span {
          color: ${({ theme }) => theme.colors.black};
          opacity: 0.3;
        }
      `
    case 'disabled':
      return css`
        background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
        cursor: not-allowed;

        & > span {
          color: ${({ theme }) => theme.colors.black};
          opacity: 0.5;
        }
      `
    case 'black_secondary':
      return css`
        background-color: white;
        border: 0.1rem solid ${({ theme }) => theme.colors.black};

        span {
          color: ${({ theme }) => theme.colors.black};
        }
      `
    case 'black_primary':
      return css`
        background-color: ${({ theme }) => theme.colors.black};

        span {
          color: white;
        }
      `
    case 'red':
      return css`
        background-color: white;
        border: 1px solid ${({ theme }) => theme.colors.warning};

        span {
          color: ${({ theme }) => theme.colors.warning};
        }
      `
    case 'yellow':
      return css`
        background-color: ${({ theme }) => theme.colors.yellow};

        span {
          color: ${({ theme }) => theme.colors.black};
        }
      `
  }
}
