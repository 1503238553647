import client from '../client'

export async function postBillingReservation(req: PostBillingReservationReq) {
  const response = await client.post(`/payment-api/v1/payment/billing-reservation`, req)
  return response.data
}

export type PostBillingReservationReq = {
  service_id: string
}
