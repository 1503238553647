import { getBvs, GetBvsParam } from 'lib/api/bvs/getBvs'
import { QueryOptionsOf } from 'lib/utils/types'
import { useQuery } from 'react-query'

export default function useBvsQuery(value: GetBvsParam, options: QueryOptionsOf<typeof getBvs> = {}) {
  return useQuery(createKey(value.id), () => getBvs(value), options)
}

const createKey = (id: number) => ['bvs', id]
useBvsQuery.createKey = createKey
