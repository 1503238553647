import Icon from 'components/Icon'
import styled from 'styled-components'

export type CustomCheckboxProps = {
  children: React.ReactNode
} & React.LabelHTMLAttributes<HTMLLabelElement>

/**
 * @description input을 children으로 내려보내고 htmlFor로 연결시키면 됩니다.
 * @param children
 * @param htmlFor
 */

export default function CustomCheckbox({ children, htmlFor }: CustomCheckboxProps) {
  return (
    <StyledCheckbox htmlFor={htmlFor}>
      {children}
      <span>
        <Icon name="checked" />
      </span>
    </StyledCheckbox>
  )
}

const StyledCheckbox = styled.label`
  display: flex;
  cursor: pointer;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span > svg {
      display: block;
      position: absolute;
      top: -0.2rem;
      left: -0.2rem;
    }
  }

  & > span {
    position: relative;
    display: inline-grid;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    border: 0.2rem solid ${({ theme }) => theme.colors.gray};

    & > svg {
      display: none;
    }
  }
`
