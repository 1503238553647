import styled from 'styled-components'

export default function LandingBrwnieIntroduce() {
  return (
    <LandingBrwnieIntroduceWrapper>
      <Content>
        <IntroduceWrap>
          <h1>
            왜<br />
            <span>'브라우니'</span> 일까요?
          </h1>
          <h6>
            '브라우니'는 북부 영국과 스코틀랜드의 전설 속 가정부 요정입니다. 이들은 주인이 자거나 외출한 시간에 조용히
            집안일을 해결합니다, 마치 서양의 우렁각시와 같죠.
            <br />
            <br /> 이처럼, 사람이 없을 때 매장을 깨끗하고 쾌적하게 관리하는 '브라우니' 서비스가 생겨났습니다.
          </h6>
        </IntroduceWrap>

        <img src="/image/landing_brwnie_introduce/brownies.png" alt="브라우니 요정 사진" />
      </Content>
    </LandingBrwnieIntroduceWrapper>
  )
}

const LandingBrwnieIntroduceWrapper = styled.section`
  width: 100%;
  padding: 220px 125px 220px 120px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.media.mobile`
    padding: 80px 0px;
  `}
`

const Content = styled.div`
  max-width: 1030px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  & > img {
    width: 50%;
  }

  ${({ theme }) => theme.media.mobile`
    width: 100%;
    display: flex;
    flex-direction: column;

    & > img {
        width: calc(100% - 32px);
        margin: 0px 16px;
    }
  `}
`

const IntroduceWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 90px;

  & > h1 {
    min-width: max-content;
    font-size: 56px;
    font-weight: 700;
    line-height: 125%;
    color: #212121;

    & > span {
      color: #3d5afe;
    }

    margin-bottom: 40px;
  }

  & > h6 {
    color: #757575;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;

    text-align: left;
  }

  ${({ theme }) => theme.media.mobile`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 16px;

    & > h1 {
        width: 100%;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }

    & > h6 {
        font-size: 16px;
        line-height: 28px;
    }
  `}
`
