import { getPayment, GetPaymentParam } from 'lib/api/payment/getPayment'
import { QueryOptionsOf } from 'lib/utils/types'
import { useQuery } from 'react-query'

export default function usePaymentQuery(
  value: GetPaymentParam & { userId: number },
  options: QueryOptionsOf<typeof getPayment> = {}
) {
  return useQuery(createKey(value.userId), () => getPayment({ id: value.id }), options)
}

const createKey = (userId: number) => ['payment', userId]
usePaymentQuery.createKey = createKey
