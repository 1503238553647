import styled from 'styled-components'
import { ReactComponent as RightArrowIcon } from './arrow-arrow-right-2.svg'
import { ReactComponent as RightArrowIconDisabled } from './arrow-arrow-right-2-disabled.svg'

export default function NextButton({
  last,
  disabled,
  loading,
  onClick,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  last?: boolean
  loading?: boolean
}) {
  return (
    <Button
      disabled={loading || disabled}
      onClick={(e) => {
        if (!last) {
          document.getElementById('root')?.scrollIntoView()
        }

        if (onClick) {
          onClick(e)
        }
      }}
      {...props}
    >
      {disabled ? <RightArrowIconDisabled /> : <RightArrowIcon />}

      {last ? (loading ? '전송 중...' : '신청 완료') : '다음 질문'}
    </Button>
  )
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 56px;
  background: #083994;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.064px;
  color: #fff;
  padding: none;

  &:disabled {
    background-color: #f6f6f6;
    color: #c8c8c8;
  }
`
