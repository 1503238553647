import client from '../client'
import { ContractedDateType } from './types'

export async function getContractedDate({ year, month }: GetContractedDateParam) {
  const response = await client.get<ContractedDateType>(`/service-api/v1/contracted-date/date/${year}/${month}`)

  return response.data
}

export type GetContractedDateParam = {
  year: number
  month: number
}
