import { useState } from 'react'
import styled from 'styled-components'
import Dropdown from 'react-dropdown'
import { periodFilters, productFilters, stateFilters } from 'lib/utils/paymentFilter'
import { isMobile } from 'react-device-detect'
import { BottomSheet, Icon as OhdsIcon } from 'ohds-web'

interface PaymentHistoryFilterProp {
  product: string
  setProduct: (totalWeekCount: string) => void
  period: string
  setPeriod: (period: string) => void
  state: string
  setState: (state: string) => void
}

export default function PaymentHistoryFilter({
  product,
  setProduct,
  period,
  setPeriod,
  state,
  setState,
}: PaymentHistoryFilterProp) {
  const [openState, setOpenState] = useState<boolean>(false)
  const [openProduct, setOpenProduct] = useState<boolean>(false)
  const [openPeriod, setOpenPeriod] = useState<boolean>(false)

  const handleMobileClick = (type: 'STATE' | 'PRODUCT' | 'PERIOD') => {
    if (!isMobile) return false
    if (type === 'STATE') return setOpenState(true)
    if (type === 'PRODUCT') return setOpenProduct(true)
    if (type === 'PERIOD') return setOpenPeriod(true)
  }

  return (
    <Container>
      <DropdownWrap>
        <div
          onClick={() => {
            handleMobileClick('STATE')
          }}
        >
          <Dropdown
            placeholder="상태"
            options={stateFilters}
            onChange={(option) => setState(option.value)}
            value={state}
            disabled={isMobile}
          />
        </div>
        <div
          onClick={() => {
            handleMobileClick('PRODUCT')
          }}
        >
          <Dropdown
            placeholder="서비스 유형"
            options={productFilters}
            onChange={(option) => setProduct(option.value)}
            value={product}
            disabled={isMobile}
          />
        </div>
        <div
          onClick={() => {
            handleMobileClick('PERIOD')
          }}
        >
          <Dropdown
            placeholder="전체 기간"
            options={periodFilters}
            onChange={(option) => setPeriod(option.value)}
            value={period}
            disabled={isMobile}
          />
        </div>
      </DropdownWrap>

      <StyledLine />

      <BottomSheet open={openState} title="상태값" onClose={() => setOpenState(false)}>
        <StyledUl>
          {stateFilters.map((data, index) => (
            <StyledLi key={index} onClick={() => setState(data.value)}>
              <TextBox>
                <StyledP>{data.label}</StyledP>
              </TextBox>
              {state === data.value && <OhdsIcon name="LineCheck" size="small" color="brand300" />}
            </StyledLi>
          ))}
        </StyledUl>
      </BottomSheet>

      <BottomSheet open={openProduct} title="서비스 유형" onClose={() => setOpenProduct(false)}>
        <StyledUl>
          {productFilters.map((data, index) => (
            <StyledLi key={index} onClick={() => setProduct(data.value)}>
              <TextBox>
                <StyledP>{data.label}</StyledP>
              </TextBox>
              {product === data.value && <OhdsIcon name="LineCheck" size="small" color="brand300" />}
            </StyledLi>
          ))}
        </StyledUl>
      </BottomSheet>

      <BottomSheet open={openPeriod} title="기간" onClose={() => setOpenPeriod(false)}>
        <StyledUl>
          {periodFilters.map((data, index) => (
            <StyledLi key={index} onClick={() => setPeriod(data.value)}>
              <TextBox>
                <StyledP>{data.label}</StyledP>
              </TextBox>
              {period === data.value && <OhdsIcon name="LineCheck" size="small" color="brand300" />}
            </StyledLi>
          ))}
        </StyledUl>
      </BottomSheet>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  min-height: 100px;
`

const DropdownWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.media.mobile`
    padding: 10px 16px;
    overflow: auto;
    .Dropdown-control {
      pointer-events: none;
    }
  `}

  .Dropdown-placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    white-space: nowrap;
    color: #333333;
    margin-left: 4px;
  }

  .Dropdown-control {
    position: relative;
    overflow: hidden;

    width: max-content;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 9999px;

    margin-right: 8px;

    padding: 8px 12px;
  }

  .Dropdown-arrow-wrapper {
    width: 20px;
    height: 20px;
  }

  .Dropdown-arrow {
    border-color: #292929 transparent transparent;
    border-style: solid;
    border-width: 0.5rem 0.5rem 0;
    content: ' ';
    display: block;
    height: 0;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    width: 0;

    display: flex;
    align-items: center;
  }

  .Dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border: none;
    border-radius: 20px;
    z-index: 500;
    min-width: 180px;
    margin-top: 8px;
  }

  .Dropdown-menu::-webkit-scrollbar {
    width: 0.4rem;
  }

  .Dropdown-option {
    text-align: left;
    padding: 16px;
    color: #212121;
  }

  .Dropdown-option:hover {
    background-color: #f5f5f5;
    color: #212121;
  }

  .Dropdown-option.is-selected {
    background: #f5f5f5;
    color: #212121;
  }
`

const StyledLine = styled.hr`
  width: 100%;
  height: 8px;
  background: #f5f5f5;
  border: none;

  visibility: hidden;
  margin-bottom: 20px;

  ${({ theme }) => theme.media.mobile`
    visibility: visible;
    margin-bottom: 0px;
  `}
`

const StyledUl = styled.ul``

const StyledLi = styled.li`
  width: 100%;
  padding: 16px;
  background-color: var(--gray0);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: var(--gray-gray-100, #f5f5f5);
  }
`

const TextBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .tag {
    margin-left: 14px;
    align-items: center;
    & > p {
      width: 100%;
      font-size: 12px;
      color: white;
      font-weight: 400;
      background-color: var(--brand300);
      text-align: center;
      border-radius: 8px;
      padding: 2px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > svg {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
    }
  }
`

const StyledP = styled.p<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? 'var(--gray400)' : 'var(--gray800)')};
  display: block;
  font-weight: 400;
  font-size: var(--p1);
  line-height: var(--p1LineHeight);
`
