import styled from 'styled-components'
import Icon from '../../../Icon'

export function ElectronicCigaretteService(): JSX.Element {
  return (
    <Container>
      <Image src="/image/loading_partner_service/cigarette-service.png" />
      <Mask>
        <Content>
          <Title>
            '상품 소싱' 부터
            <br /> '자동 발주' 까지
            <br /> 24시 무인 전자담배
          </Title>
          <Description>
            한달 매출 2,630만 원!
            <br />
            원가는 50% 이하, 평균 마진율 53%!
          </Description>
        </Content>

        <BottomButton href="https://jdgate.modoo.at/" target="_blank">
          <span>전담게이트 바로가기</span>
          <Icon name="ArrowRight" />
        </BottomButton>
      </Mask>
    </Container>
  )
}

const Container = styled.article`
  position: relative;

  width: 960px;
  height: 420px;

  border-radius: 24px;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    width: 312px;
    margin: 0 auto;
    height: 240px;
    border-radius: 20px;
    margin-top: 12px;
  }
`

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 90px;

  background: linear-gradient(92deg, #bc4769 34.41%, rgba(178, 68, 107, 0) 72.38%);

  @media only screen and (max-width: 768px) {
    background: linear-gradient(131deg, #bc4769 31.03%, rgba(188, 71, 105, 0) 124.89%);
    padding: 26px 28px;
  }
`

const Title = styled.p`
  color: #ffffff;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 135.294% */
  margin-bottom: 14px;

  @media only screen and (max-width: 768px) {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 136.364% */
    margin-bottom: 12px;
  }

  padding: 0;
`

const Description = styled.p`
  color: #ffffff;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    margin-bottom: 16px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const BottomButton = styled.a`
  color: #bc4769;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;

  width: fit-content;
  display: flex;
  padding: 10px 16px 10px 18px;
  justify-content: flex-start;
  align-items: center;

  border-radius: 688px;
  background: #ffffff;
  cursor: pointer;

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 8px 10px 8px 12px;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 200px;
  @media only screen and (max-width: 768px) {
    object-position: center;
  }
`
