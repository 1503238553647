import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const float = keyframes`
	0% {
		box-shadow: 0 1.6rem 1.6rem -0.8rem rgba(30, 30, 44, 0.12);
		transform: translatey(0);
	}
	50% {
		box-shadow: 0 4.6rem 1.6rem -0.8rem rgba(30, 30, 44, 0.12);
		transform: translatey(-3rem);
	}
	100% {
		box-shadow: 0 1.6rem 1.6rem -0.8rem rgba(30, 30, 44, 0.12);
		transform: translatey(0);
	}
`

export const mobileFloat = keyframes`
  0% {
		box-shadow: 0 1.6rem 1.6rem -0.8rem rgba(30, 30, 44, 0.12);
		transform: translatey(0);
	}
	50% {
		box-shadow: 0 3.1rem 1.6rem -0.8rem rgba(30, 30, 44, 0.12);
		transform: translatey(-1.5rem);
	}
	100% {
		box-shadow: 0 1.6rem 1.6rem -0.8rem rgba(30, 30, 44, 0.12);
		transform: translatey(0);
	}
`
