import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import { SetStateAction } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import FooterButtonBox from '../FooterButtonBox'
import Spinner from '../Spinner'

export type StepFormProps = {
  currentStep: number
  totalLength: number
  question: string
  children: React.ReactNode
  onSubmit: React.FormEventHandler<HTMLFormElement>
  submitText: string
  setCurrentStep: React.Dispatch<SetStateAction<number>>
  isValid: boolean
  isSubmitLoading?: boolean
  needNextButton?: boolean
  onNextClick: () => void
}

export default function StepForm({
  currentStep,
  totalLength,
  question,
  children,
  onSubmit,
  submitText,
  setCurrentStep,
  isValid,
  isSubmitLoading,
  needNextButton = true,
  onNextClick,
}: StepFormProps) {
  const logEvents = ['1_매장주소', '2_매장명', '3_무인매장유형', '4_매장평형대', '5_관리기계종류']
  return (
    <StyledStepForm onSubmit={currentStep === totalLength ? onSubmit : (e) => e.preventDefault()}>
      <Step>
        {currentStep} / {totalLength}
      </Step>
      <ProgressBar>
        <Progress totalLength={totalLength} currentStep={currentStep} />
      </ProgressBar>
      <Question>{question}</Question>
      {children}
      <ButtonContainer>
        {currentStep !== 1 && (
          <Button type="button" buttonTheme="secondary" width={120} onClick={() => {
            LogManager.Instance.sendLog('store_post_grade', {
              store_post_grade: logEvents[currentStep - 1]
            }, LOGGER.GTM)
            setCurrentStep(currentStep - 1)
          }}>
            이전
          </Button>
        )}
        {currentStep === totalLength ? (
          <Button width={120} buttonTheme={isValid ? 'primary' : 'disabled'} type="submit">
            {isSubmitLoading ? <Spinner color="white" /> : submitText}
          </Button>
        ) : (
          needNextButton && (
            <Button
              type="button"
              width={120}
              buttonTheme={isValid ? 'primary' : 'disabled'}
              onClick={(e) => {
                e.preventDefault()
                onNextClick()
                LogManager.Instance.sendLog('store_post_grade', {
                  store_post_grade: logEvents[currentStep + 1]
                }, LOGGER.GTM)
                setCurrentStep(currentStep + 1)
              }}
            >
              다음
            </Button>
          )
        )}
      </ButtonContainer>
      <FooterButtonBox>
        {currentStep !== 1 && (
          <Button type="button" buttonTheme="secondary" width={120} onClick={() => setCurrentStep(currentStep - 1)}>
            이전
          </Button>
        )}
        {currentStep === totalLength ? (
          <Button width={120} buttonTheme={isValid ? 'primary' : 'disabled'} type="submit">
            {isSubmitLoading ? <Spinner color="white" /> : submitText}
          </Button>
        ) : (
          needNextButton && (
            <Button
              type="button"
              width={120}
              buttonTheme={isValid ? 'primary' : 'disabled'}
              onClick={(e) => {
                e.preventDefault()
                onNextClick()
                setCurrentStep(currentStep + 1)
              }}
            >
              다음
            </Button>
          )
        )}
      </FooterButtonBox>
    </StyledStepForm>
  )
}

const StyledStepForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 464px;
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-radius: 16px;

  // mobile
  ${({ theme }) => theme.media.mobile`
    padding: 32px 16px;
    width: ${theme.width.mobile}
  `}
`

const Step = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
`

const ProgressBar = styled.div`
  width: 400px;
  height: 2px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
  margin: 32px 0;

  // mobile
  ${({ theme }) => theme.media.mobile`
    width: 280px;
  `}
`

const Progress = styled.div<{
  totalLength: number
  currentStep: number
}>`
  width: ${({ totalLength, currentStep }) => ((currentStep - 1) / totalLength) * 400}px;
  height: 2px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.main};
  transition: width 0.3s ease-in-out;

  // mobile
  @media only screen and (max-width: 768px) {
    width: ${({ totalLength, currentStep }) => ((currentStep - 1) / totalLength) * 280}px;
  }
`

const Question = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  word-break: keep-all;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 32px;
  text-align: center;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;

  button ~ button {
    margin-left: 8px;
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`
