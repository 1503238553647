import { getAuth } from 'lib/api/auth/getAuth'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { ROUTES } from 'utils/common/routes'
import { postEmailSignIn, EmailSignInReq } from '../../../lib/api/auth/postEmailSignIn'

export default function usePostEmailLogin() {
  const history = useHistory()
  const queryClient = useQueryClient()
  const authKey = 'auth'

  return useMutation((value: EmailSignInReq) => postEmailSignIn(value), {
    onSuccess: async () => {
      await queryClient.prefetchQuery(authKey, getAuth)
      history.push(ROUTES.STORE)
    },
  })
}
