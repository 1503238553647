import styled, { keyframes } from 'styled-components'
import dayjs from 'dayjs'

import Icon from 'components/Icon'
import { IconType } from 'components/Icon/Icon'
import { CouponType } from 'lib/api/coupon/types'
import { COUPON_STATE } from 'lib/api/coupon/getCoupons'
import Paginator from 'components/Paginator'

function thousandComma(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getBenefit(coupon: CouponType) {
  switch (coupon.type) {
    case 'DISCOUNT':
      if (coupon.discount_price) {
        return `${thousandComma(coupon.discount_price)}원 할인`
      }
      if (coupon.discount_rate) {
        return `${coupon.discount_rate}% 할인`
      }
      break
  }
  return ''
}

// function getPolicy(coupon: CouponType) {
//   let result = ['중복 적용 불가']

//   const adressArray = [
//     coupon.region_1depth_name,
//     coupon.region_2depth_name,
//     coupon.region_3depth_name || coupon.region_3depth_h_name,
//   ]
//   const address = adressArray.filter((data) => data).join(' ')
//   if (address) {
//     result.push(`${address}만 사용가능`)
//   }
//   return result.join(' ')
// }

function getCellButtonText(coupon: CouponType) {
  if (dayjs(coupon.expired_at).isBefore(dayjs())) {
    return '기간 만료'
  } else if (coupon.state === COUPON_STATE.USED) {
    return '사용함'
  }
  return '자세히 보기'
}

const FadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export function ListCell({ coupon, onClick }: { coupon: CouponType; onClick: (coupon: CouponType) => void }) {
  const cellButtonText = getCellButtonText(coupon)
  return (
    <CellWrapper>
      <HorizontalBox className="cell-header">
        <h3>{ coupon.name }</h3>
        <TextButton
          className={cellButtonText === '자세히 보기' ? 'text-button' : 'text-button expired'}
          onClick={() => onClick(coupon)}
        >
          {cellButtonText}
        </TextButton>
      </HorizontalBox>
      <HorizontalBox>
        <VerticalBox className="placeholder-box">
          <span>혜택</span>
          <span>유효 기간</span>
        </VerticalBox>
        <VerticalBox className="separator-box">
          <span>:</span>
          <span>:</span>
        </VerticalBox>
        <VerticalBox>
          <span>{getBenefit(coupon)}</span>
          <span>{dayjs(coupon.expired_at).format('YYYY.MM.DD [까지]')}</span>
        </VerticalBox>
      </HorizontalBox>
    </CellWrapper>
  )
}

const CellWrapper = styled.div`
  width: calc(50% - 32px);
  padding: ${({ theme }) => theme.spacing(4)};
  margin: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-radius: 1.6rem;

  ${({ theme }) => theme.media.mobile`
    width: 100%;
    padding: 16px;
  `}

  .cell-header {
    margin-bottom: 1.6rem;
    .text-button {
      cursor: pointer;
      margin-left: auto;
      &.expired {
        border: 0;
        background: #1e1e2c;
        color: white;
      }
    }
  }

  .placeholder-box {
    flex: 0 0 auto;
  }

  .separator-box {
    margin: ${({ theme }) => theme.spacing(0, 2)};
  }

  .two-line {
    min-height: 4.8rem;
  }

  h3 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.8rem;

    ${({ theme }) => theme.media.mobile`
      font-size: 1.6rem;
      line-height: 2.4rem;
    `}
  }

  span {
    font-size: 1.6rem;
    line-height: 2.4rem;

    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }

    ${({ theme }) => theme.media.mobile`
      font-size: 14px;
      line-height: 20px;
    `}
  }
`

const HorizontalBox = styled.div`
  display: flex;
  flex-direction: row;
  animation: ${FadeAnimation} 0.5s ease-in;
`

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
`

const TextButton = styled.button<{ color?: string; borderColor?: string; background?: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: ${({ theme }) => theme.spacing(0.5)};
  margin: 0;
  border: 1px solid ${(props) => props.borderColor || '#B1B1B5'};
  border-radius: 0.8rem;
  color: ${(props) => props.color || 'black'};
  background: ${(props) => props.background || 'unset'};
  font-size: 1.2rem;
  line-height: 1.6rem;
`

export type ListBoxProp = {
  className?: string
  loading: boolean
  title: string
  count: number
  page: number
  perPage?: number
  color?: string
  noCouponText: string
  coupons: CouponType[]
  iconType: IconType
  countColor: string
  children?: React.ReactNode
  onClick: (coupon: CouponType) => void
  updatePage: React.Dispatch<React.SetStateAction<number>>
}

export default function ListBox({
  className,
  loading,
  children,
  title,
  count,
  page,
  perPage = 10,
  iconType,
  onClick,
  countColor,
  updatePage,
  noCouponText,
  coupons,
}: ListBoxProp) {
  const maxPage = Math.ceil(count / perPage)

  return (
    <ListWrapper className={className}>
      <Header>
        <Icon name={iconType} />
        <h2>{title}</h2>
        <span className="count" style={{ color: countColor }}>
          {count}
        </span>
        <h2>개</h2>
      </Header>
      <Content className="content">
        {loading ? <SpinnerWrapper>{children}</SpinnerWrapper> : <></>}
        {coupons.length === 0 && !loading ? <span className="no-store">{noCouponText}</span> : <></>}
        {coupons.map((coupon) => (
          <ListCell key={coupon.id} coupon={coupon} onClick={onClick} />
        ))}
      </Content>
      {maxPage > 1 ? <Paginator maxPage={maxPage} onChanged={(page: number) => updatePage(page)} page={page} /> : <></>}
    </ListWrapper>
  )
}

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-radius: 1.6rem;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(3, 4)};
  background-color: ${({ theme }) => theme.colors.lightGray};

  h2 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.8rem;
    margin-left: 1.6rem;

    ${({ theme }) => theme.media.mobile`
      font-size: 1.6rem;
      margin-left: 0.8rem;
    `}
  }
  .count {
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 4rem;
    margin-left: 1.6rem;

    ${({ theme }) => theme.media.mobile`
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-left: 0.8rem;
    `}
  }

  ${({ theme }) => theme.media.mobile`
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: center;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.media.mobile`
    padding-left: 0px;
    padding-right: 0px;
  `}

  .no-store {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 21.2rem;
    border: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
    border-radius: 1.6rem;
    margin: ${({ theme }) => theme.spacing(2)};
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    opacity: 0.5;
  }
`

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5rem;
`
