import client from '../client'
import { EmailSignInRes } from './types'

export async function postEmailSignIn({ email, password }: EmailSignInReq) {
  const response = await client.post<EmailSignInRes>(`auth-api/v1/auth/login/email`, {
    email,
    password,
  })

  return response.data
}

export type EmailSignInReq = {
  email: string
  password: string
}
