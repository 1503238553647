import { useCallback, useState } from 'react'
import { Icon, BottomCTA, SelectBox, BottomSheet, CheckOptionListRow, Modal, TypoC2, TypoH3 } from 'ohds-web'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import BrwnieIcon from 'components/Icon'

import { useEasyConsulting } from 'components/EasyConsulting/hooks/useEasyConsulting'
import EasyConsultingForm from 'components/EasyConsulting/EasyConsultingForm'
import CustomCheckbox from 'components/common/CustomCheckbox'
import { phoneHyphen } from 'utils/common/phoneHyphen'
import { useAppDispatch } from 'hooks/useReduxHook'
import { openSnackBar } from 'modules/features/snackBarStatus/snackBarStatusSlice'
import usePostSlackMessageSend from 'hooks/query/slackMessageSend/usePostSlackMessageSend'

export default function EasyConsultingPage() {
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const { mutateAsync: mutatePostEasyConsultingSlack, isLoading } = usePostSlackMessageSend()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const {
    name,
    storeName,
    phone,
    isCheck,
    hopeTime,
    isHopeTimeOpen,
    isModalOpen,
    hopeTimeList,
    setIsModalOpen,
    setIsHopeTimeOpen,
    handleNameChange,
    handleStoreNameChange,
    handlePhoneChange,
    handleCheckChange,
    handleOptionClick,
    handleErrorCheck,
  } = useEasyConsulting()

  const handleFormSubmit = () => {
    setIsSubmit(true)

    if (
      !(handleErrorCheck('name').isError || handleErrorCheck('storeName').isError || handleErrorCheck('phone').isError)
    ) {
      setIsModalOpen(true)
    }
  }

  const handleResetSubmitChange = useCallback(() => {
    setIsSubmit(false)
  }, [])

  const handleConsultingApply = async () => {
    if (isLoading) return

    await mutatePostEasyConsultingSlack({
      template: 'EASY_CONSULTING',
      data: {
        name: name,
        storeName: storeName,
        phone: phoneHyphen(phone),
        hopeTime: hopeTime.length === 0 ? 'ASAP' : hopeTime,
      },
    })

    setIsModalOpen(false)

    dispatch(openSnackBar('상담 신청 완료!\n신청하신 정보로 연락 드릴게요.'))
    history.push('/')
  }

  return (
    <Container>
      <PCBox>
        <ImageDimmer />
        <LogoBox onClick={() => history.push('/')}>
          <BrwnieIcon name="whiteNavLogo" />
        </LogoBox>

        <TitleContainer>
          <Title>상담 신청</Title>
          <TypoH3
            text="|"
            color="gray400"
            style={{
              marginLeft: 40,
              marginRight: 40,
            }}
          />
          <TypoH3 text="Apply for Consulting" color="gray0" />
        </TitleContainer>

        <CopyRightContainer>
          <TypoC2 text="COPYRIGHT © HIGHERX. Proudly created" color="gray0" />
        </CopyRightContainer>
      </PCBox>
      <MainView>
        <BasicNav>
          <button onClick={() => history.goBack()}>
            <Icon name="LineArrowLeft" />
          </button>
          <span>상담 신청</span>
        </BasicNav>
        <ContentWrapper>
          <DescriptionWrapper>
            <h3>궁금한 것이 있으신가요?</h3>
            <h2>
              무인매장 전문가가 <br />
              신속하게 상담해 드립니다.
            </h2>
          </DescriptionWrapper>
          <EasyConsultingForm
            type="name"
            label="성함"
            isError={handleErrorCheck('name').isError}
            errorText={handleErrorCheck('name').errorText}
            placeholder="성함을 입력해주세요."
            value={name}
            onChange={handleNameChange}
            isSubmit={isSubmit}
            resetSubmit={handleResetSubmitChange}
          />
          <EasyConsultingForm
            type="storeName"
            label="매장명"
            isError={handleErrorCheck('storeName').isError}
            errorText={handleErrorCheck('storeName').errorText}
            placeholder="매장명을 입력해주세요."
            value={storeName}
            onChange={handleStoreNameChange}
            isSubmit={isSubmit}
            resetSubmit={handleResetSubmitChange}
          />
          <EasyConsultingForm
            type="phone"
            label="전화번호 ('-'없이 입력)"
            isError={handleErrorCheck('phone').isError}
            errorText={handleErrorCheck('phone').errorText}
            placeholder="전화번호를 입력해주세요."
            value={phone}
            onChange={handlePhoneChange}
            isSubmit={isSubmit}
            resetSubmit={handleResetSubmitChange}
          />
          <TimeSelectBoxWrapper>
            <label>상담 희망 시간 (선택)</label>
            <StyledSelectBox
              label={'시간을 선택해주세요'}
              value={hopeTime}
              onSelectBoxClick={() => setIsHopeTimeOpen(true)}
              BottomSheet={
                <StyledBottomSheet
                  open={isHopeTimeOpen}
                  title="상담 희망 시간"
                  onClose={() => setIsHopeTimeOpen(false)}
                >
                  {hopeTimeList.map((optionTime) => (
                    <CheckOptionListRow
                      key={optionTime}
                      text={optionTime}
                      checked={hopeTime === optionTime}
                      onClick={() => handleOptionClick(optionTime)}
                    />
                  ))}
                </StyledBottomSheet>
              }
            />
          </TimeSelectBoxWrapper>

          <CheckBoxWrapper>
            <CustomCheckbox>
              <input type="checkbox" id={'policy-agree'} checked={isCheck} onChange={handleCheckChange} />
            </CustomCheckbox>
            <label htmlFor={'policy-agree'}>(필수) 개인정보 수집 이용에 동의합니다.</label>
          </CheckBoxWrapper>

          <PolicySection>
            <article>
              개인정보의 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 다만, 이러한 개인정보 수집 이용에 동의하지
              않을 경우 상담 서비스 제공이 불가능합니다.
            </article>
            <article>
              수집항목 : 고객명, 매장명, 전화번호
              <br />
              목적 : 서비스 상담 등 거래관계의 설정
              <br />
              이용 및 보관기간 : 수집된 개인 정보는 정보 기입 후 6개월 간 보관됩니다.
            </article>
          </PolicySection>
          <StyledBottomCTA
            linear
            primaryProps={{
              disabled: !isCheck,
              label: '상담 신청하기',
            }}
            onClick={handleFormSubmit}
          />
        </ContentWrapper>

        <StyledModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="상담을 신청하시겠어요?"
          description={`성함 : ${name}\n매장명 : ${storeName}\n전화번호 : ${phoneHyphen(phone)}${
            hopeTime.length !== 0 ? `\n상담 희망 시간 : ${hopeTime}` : ''
          }\n\n 위의 정보로 빠르게 연락드릴게요!`}
          primaryProps={{
            label: '신청하기',
            onClick: handleConsultingApply,
            fill: 'brand',
            loading: isLoading,
          }}
          secondaryProps={{
            label: '뒤로가기',
            onClick: () => setIsModalOpen(false),
            soft: 'mono',
          }}
        />
      </MainView>
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
  height: 100%;
  background-color: var(--gray0);
  display: flex;
  position: relative;
  z-index: 501;

  @media only screen and (min-width: 768px) {
    background-color: var(--gray100);
  }
`

const PCBox = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
    width: 50vw;
    height: 100vh;
    position: relative;
    background-image: url('/image/serviceApplyPCBackground.jpg');
    background-size: cover;
  }
`

const ImageDimmer = styled.div`
  width: 50vw;
  height: 100vh;
  background-color: black;
  opacity: 0.36;
  position: absolute;
  top: 0;
  left: 0;
`

const LogoBox = styled.div`
  cursor: pointer;
  width: 160px;
  height: 160px;
  background-color: var(--brand400);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  height: 80px;
  top: 240px;
  left: 48px;
`

const Title = styled.h2`
  font-size: 64px;
  color: var(--gray0);
  font-weight: 700;
`

const CopyRightContainer = styled.div`
  position: absolute;
  left: 48px;
  bottom: 48px;
`

const MainView = styled.main`
  width: 100vw;
  height: 100vh;

  @media only screen and (min-width: 768px) {
    width: 50vw;
    height: 100vh;
    padding-top: 48px;
  }
`

const BasicNav = styled.nav`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 4px;
  position: relative;

  & > span {
    position: absolute;
    left: 50%;
    margin-left: -104px;
    width: 208px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: transparent;
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) {
    width: 360px;
    margin: 0 auto;
    background-color: var(--gray0);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
`

const ContentWrapper = styled.section`
  position: relative;
  width: 100%;
  height: calc(100% - 56px - 80px);
  overflow-y: scroll;
  padding-bottom: 80px;

  @media only screen and (min-width: 768px) {
    width: 360px;
    height: calc(100vh - 120px - 56px);
    margin: auto;
    background-color: var(--gray0);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
`

const DescriptionWrapper = styled.div`
  padding: 16px;
  margin-bottom: 24px;
  & > h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: var(--gray800);
  }

  & > h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray600);
  }
`

const TimeSelectBoxWrapper = styled.div`
  & > label {
    display: flex;
    width: calc(100% - 32px);
    margin: 0 auto;
    padding-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray600);
  }
`

const StyledSelectBox = styled(SelectBox)`
  height: 52px;
  min-height: 52px;
  z-index: 0;
  position: relative;
`

const StyledBottomCTA = styled(BottomCTA)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    left: 50%;
  }
`

const StyledBottomSheet = styled(BottomSheet)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    position: fixed;
    left: 50%;
  }
`

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  height: 48px;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 12px;

  & > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray600);

    &:nth-of-type(2) {
      margin-left: 13px;
    }
  }
`

const PolicySection = styled.section`
  width: calc(100% - 32px);
  margin: 0 auto;
  padding-bottom: 32px;

  & > article {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray600);
    word-break: keep-all;
    &:first-child {
      padding-bottom: 8px;
    }
  }
`

const StyledModal = styled(Modal)`
  p {
    white-space: pre;
  }
`
