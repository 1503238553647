import { patchPhoneModify, PhoneModifyReq } from 'lib/api/authVerify/patchPhoneModify'
import { useMutation, useQueryClient } from 'react-query'

export default function usePatchPhoneModify() {
  const queryClient = useQueryClient()

  return useMutation((value: PhoneModifyReq) => patchPhoneModify(value), {
    onSuccess: async (data) => {
      queryClient.refetchQueries('authCheck')
    },
    onError: async (data: any) => {},
  })
}
