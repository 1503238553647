import styled, { css } from 'styled-components'

type DividerProps = {
  marginVertical?: string
  marginHorizontal?: string
  mobileMarginVertical?: string
  mobileMarginHorizontal?: string
}

export default function Divider({
  marginVertical = '48',
  marginHorizontal = '0',
  mobileMarginHorizontal = '0',
  mobileMarginVertical = '32',
}: DividerProps) {
  return (
    <DividerWrapper
      mobileMarginVertical={mobileMarginVertical}
      mobileMarginHorizontal={mobileMarginHorizontal}
      marginVertical={marginVertical}
      marginHorizontal={marginHorizontal}
    />
  )
}

const DividerWrapper = styled.hr<DividerProps>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => `${theme.colors.gray200}`};
  border: none;
  margin: 48px 0px;

  ${({ marginVertical, marginHorizontal }) => css`
    margin: ${marginVertical}px ${marginHorizontal}px;
  `}
  @media only screen and (max-width: 768px) {
    margin: 32px 0px;

    ${({ mobileMarginVertical, mobileMarginHorizontal }) => css`
      margin: ${mobileMarginVertical}px ${mobileMarginHorizontal}px;
    `}
  }
`
