import { dayArr } from 'modules/features/service/serviceSlice'
import { TypoC1 } from 'ohds-web'
import styled from 'styled-components'

export default function DayBar() {
  return (
    <StyledDayBar>
      {dayArr.map((day) => (
        <div key={day}>
          <TypoC1 text={day} />
        </div>
      ))}
    </StyledDayBar>
  )
}

const StyledDayBar = styled.div`
  width: 100%;
  padding: 16px 16px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray200);
  height: 56px;

  & > div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
