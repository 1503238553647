import client from '../client'
import { ServiceType } from './types'

export async function getStoreService({ storeId }: GetStoreServiceParam) {
  const response = await client.get<ServiceType[]>(`/service-api/v1/service/store/${storeId}`)

  return response.data
}

export type GetStoreServiceParam = {
  storeId: string
}
