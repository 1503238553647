import { useAppSelector } from 'hooks/useReduxHook'
import { ScheduleType, ServiceDayValueType } from 'modules/features/service/serviceSlice'
import CalendarDate from '../CalendarDate'

export type FirstVisitDateProps = {
  year: number
  month: number
  date: number
  day: number
  newSchedule?: ScheduleType
  onDateClick: (year: number, month: number, date: number, day: number) => void | Promise<void>
}

export default function FirstVisitDate(props: FirstVisitDateProps) {
  const { year, month, date, day, newSchedule, onDateClick } = props
  const { serviceDayValues } = useAppSelector((state) => state.service)

  const isNewSelected = !!(
    newSchedule &&
    newSchedule.year === year &&
    newSchedule.month === month &&
    newSchedule.date === date
  )

  const isInactive = !serviceDayValues.includes(day as ServiceDayValueType)

  const label = newSchedule && isNewSelected ? `${newSchedule.hour}시` : undefined

  return (
    <CalendarDate
      {...props}
      key={day}
      selected={isNewSelected}
      inactive={isInactive}
      label={label}
      onDateClick={async () => {
        await onDateClick(year, month, date, day)
      }}
    />
  )
}
