import { useHistory } from 'react-router-dom'
import { Top } from 'ohds-web'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import useVisitHourBottomSheet from 'hooks/service/useVisitHourBottomSheet'
import { modifySchedule, resetModifiedSchedule } from 'modules/features/service/serviceSlice'
import BrwnieMainContainer from 'components/common/BrwnieMainContainer'
import CalendarForm from 'components/ServicePostPage/CalendarForm'
import ActiveDateInfoButton from 'components/ServicePostPage/ActiveDateInfoButton'
import { useEffect } from 'react'

export default function ModifyPage() {
  const history = useHistory()

  const dispatch = useAppDispatch()
  const { schedules, scheduleForModify } = useAppSelector((state) => state.service)

  const { year: firstYear, month: firstMonth } = schedules[0]

  const [openVisitHourOptions] = useVisitHourBottomSheet()

  useEffect(() => {
    dispatch(resetModifiedSchedule())
  }, [dispatch])

  const handleDateClick = async (year: number, month: number, date: number) => {
    const selectedVisitHour = await openVisitHourOptions({
      title: `${month}월 ${date}일 몇 시가 좋을까요?`,
      year,
      month,
      date,
    })

    if (selectedVisitHour !== undefined) {
      if (!scheduleForModify) return

      dispatch(
        modifySchedule({
          year,
          month,
          date,
          hour: selectedVisitHour,
          type: scheduleForModify.type,
          fixedIndex: scheduleForModify.fixedIndex,
        })
      )
      history.goBack()
    }
  }

  return (
    <BrwnieMainContainer>
      <Top
        firstTitle={`${scheduleForModify?.month}월 ${scheduleForModify?.date}일의 `}
        firstTitle2="변경일을 선택"
        firstTitle3="해 주세요"
        firstTitle2Brand
        titleFont={{
          fontSize: 'h3',
        }}
        paddingTop={40}
      />
      <ActiveDateInfoButton />

      <CalendarForm type="modify" firstYear={firstYear} firstMonth={firstMonth} onDateClick={handleDateClick} />
    </BrwnieMainContainer>
  )
}
