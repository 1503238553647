import styled from 'styled-components'
import { BottomCTA } from 'ohds-web'

import { ReactComponent as CheckIcon } from './check.svg'

export default function NewCrewCompletePage() {
  return (
    <Container>
      <CheckIcon />
      <span>정보 입력 완료!</span>
      <span className="description">
        정상적으로 정보가 전송되었어요.
        <br />
        앞으로 활동 잘 부탁드려요!
      </span>
      <BottomCTA primaryProps={{ label: '확인', itemType: 'button', onClick: () => window.close() }} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > span {
    color: #212121;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: -0.1px;
    margin-top: 20px;

    &.description {
      color: #585858;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.4px;

      margin-top: 12px;
    }
  }
`
