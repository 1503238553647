import { useMutation, useQueryClient } from 'react-query'
import { postRefundRequest, PostRefundRequestReq } from 'lib/api/refundRequest/postRefundRequest'
// import useServicesPaymentQuery from '../service/useServicesPaymentsQuery'

export default function usePostRefundRequest() {
  const queryClient = useQueryClient()
  return useMutation((value: PostRefundRequestReq) => postRefundRequest(value), {
    onSuccess: (_, request) => {
      queryClient.invalidateQueries('services_payment')
      queryClient.refetchQueries(['services'])
    }
  })
}
