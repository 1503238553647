import { useAppSelector } from 'hooks/useReduxHook'
import { selectCompletedSchedules, selectServiceWeeklyCount } from 'modules/features/service/serviceSlice'
import { createExtraWeekSchedules, createWeekSchedules, findSchedulesIndex } from 'utils/common/service'
import CalendarDate from '../CalendarDate'

export type ModifyDateProps = {
  year: number
  month: number
  date: number
  day: number
  onDateClick: (year: number, month: number, date: number, day: number) => void | Promise<void>
}

export default function ModifyDate(props: ModifyDateProps) {
  const { year, month, date, day, onDateClick } = props

  const { scheduleForModify, schedules, invalidDayFixSchedules, invalidTimeFixSchedules } = useAppSelector(
    (state) => state.service
  )
  const completedSchedules = useAppSelector(selectCompletedSchedules)
  const serviceWeeklyCount = useAppSelector(selectServiceWeeklyCount)

  const createModifyActiveWeek = () => {
    if (!scheduleForModify) return []

    const { year: modifyYear, month: modifyMonth, date: modifyDate, type: modifyType, fixedIndex } = scheduleForModify

    const activeWeekSchedule: {
      year: number
      month: number
      date: number
    }[] = []

    const modifyWeekIndex = Math.floor(
      findSchedulesIndex(schedules, modifyYear, modifyMonth, modifyDate) / serviceWeeklyCount
    )

    if (schedules[serviceWeeklyCount * modifyWeekIndex]) {
      activeWeekSchedule.push(
        ...createWeekSchedules(
          completedSchedules,
          {
            year: modifyYear,
            month: modifyMonth,
            date: modifyDate,
          },
          serviceWeeklyCount
        )
      )
    } else {
      activeWeekSchedule.push(...createExtraWeekSchedules(completedSchedules, serviceWeeklyCount))
    }

    if (fixedIndex !== undefined) {
      const {
        year: originYear,
        month: originMonth,
        date: originDate,
      } = modifyType === 'fixedDay' ? invalidDayFixSchedules[fixedIndex] : invalidTimeFixSchedules[fixedIndex]

      activeWeekSchedule.push(
        ...createWeekSchedules(
          completedSchedules,
          {
            year: originYear,
            month: originMonth,
            date: originDate,
          },
          serviceWeeklyCount
        )
      )
    }

    return activeWeekSchedule
  }

  const activeWeekSchedules = createModifyActiveWeek()

  const isModifyingSchedule =
    scheduleForModify &&
    scheduleForModify.year === year &&
    scheduleForModify.month === month &&
    scheduleForModify.date === date

  const scheduleIndex = findSchedulesIndex(completedSchedules, year, month, date)

  const isIncludeInSchedules = scheduleIndex !== -1

  const isSelected = !isModifyingSchedule && isIncludeInSchedules

  const isActiveWeek = !!activeWeekSchedules.find(
    (schedule) => schedule.year === year && schedule.month === month && schedule.date === date
  )

  const isInactive = isSelected || !isActiveWeek

  const label = isSelected ? `${completedSchedules[scheduleIndex].hour}시` : undefined

  return (
    <CalendarDate
      key={day}
      {...props}
      selected={isSelected}
      inactive={isInactive}
      label={label}
      onDateClick={async () => {
        await onDateClick(year, month, date, day)
      }}
    />
  )
}
