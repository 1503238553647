import client from '../client'
import { GetSearchMapRes } from './types'

export async function getSearchMap({ keyword }: GetSearchMapParams) {
  const response = await client.get<GetSearchMapRes[]>(`service-api/v1/store-map/search?keyword=${keyword}`)
  return response.data
}

export type GetSearchMapParams = {
  keyword: string
}
