import Footer from 'components/common/Footer/Footer'
import Header from 'components/common/Header'
import ServiceNotice from 'components/StorePage/ServiceNotice/ServiceNotice'
import StoreList from 'components/StorePage/StoreList'
import useStoresQuery from 'hooks/query/store/useStoresQuery'
import { useScrollGet } from 'hooks/useScrollGet'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ROUTES } from 'utils/common/routes'

export default function StorePage() {
  const history = useHistory()
  const { totalOffsetY } = useScrollGet({})
  const { data: stores, isFetching } = useStoresQuery()

  useEffect(() => {
    LogManager.Instance.sendLog(
      'store',
      {
        enter_url: document.referrer,
      },
      LOGGER.GTM
    )
  }, [])

  useEffect(() => {
    if (!isFetching && (stores === undefined || stores.length === 0)) history.push(ROUTES.STORE_POST)
  }, [stores, history, isFetching])

  const goAddStore = () => {
    history.push(ROUTES.STORE_POST)
  }

  return (
    <>
      <Header title="서비스 신청・관리" offsetY={totalOffsetY} />
      <Container>
        <TitleWrap>
          <Title>내 매장 목록</Title>
          <AddStoreButton onClick={goAddStore}>매장 추가</AddStoreButton>
        </TitleWrap>
        <StoreList />
        <ServiceNotice />
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.main`
  max-width: 832px;
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h1`
  color: var(--gray-gray-800, #212121);
  font-size: 26px;
  font-weight: 700;
`

const AddStoreButton = styled.button`
  border-radius: 12px;
  display: flex;
  height: 40px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  background: var(--navy-navy-100, #cdf);
  color: var(--navy-navy-500, #026);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`
