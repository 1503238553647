import styled from 'styled-components'

export type ServiceInfoProps = {
  InfoTypo: React.ReactNode
}

export default function ServiceInfo({ InfoTypo }: ServiceInfoProps) {
  return (
    <>
      <InfoContainer>{InfoTypo}</InfoContainer>
    </>
  )
}

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;

  & > div {
    margin-bottom: 8px;
  }
`
