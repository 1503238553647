import styled from 'styled-components'
import { fadeIn } from 'assets/styles/animation'

import Button from 'components/common/Button'

import { CouponType } from 'lib/api/coupon/types'
import { createPortal } from 'react-dom'
import Icon from 'components/Icon'
import addComma from 'utils/common/addComma'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { FIRST_ANNIVERSARY_CODE_TEMPLATE_ID } from 'lib/api/coupon/getCoupons'

type ModalLayoutProps = {
  open: boolean
  children: React.ReactNode
  onClose: () => void
}
const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(30, 30, 44, 0.4);
  z-index: 100000;
  animation: ${fadeIn} 0.3s ease-in-out;
  ${({ theme }) => theme.media.mobile`
      margin-top: 56px;
  `}
`
const modalEl: HTMLElement = document.getElementById('modal') as HTMLElement
function ModalLayout({ open, children, onClose }: ModalLayoutProps) {
  return createPortal(<>{open ? <Dimmer onClick={onClose}>{children}</Dimmer> : null}</>, modalEl)
}

export type CouponModalProp = {
  open: boolean
  data?: CouponType
  onClose: () => void
}

function getBenefit(coupon: CouponType) {
  switch (coupon.type) {
    case 'DISCOUNT':
      if (coupon.discount_price) {
        return `${addComma(coupon.discount_price)}원 할인`
      }
      if (coupon.discount_rate) {
        return `${coupon.discount_rate}% 할인`
      }
      break
  }
  return ''
}

export default function CoupondModal({ open, data, onClose }: CouponModalProp) {
  const isBrwnie1stAnniversary = useMemo(() => data && data.template_id === FIRST_ANNIVERSARY_CODE_TEMPLATE_ID, [data])

  return (
    <ModalLayout open={open} onClose={onClose}>
      <Box
        direction="column"
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <CouponHead>
          <span>C O U P O N</span>
        </CouponHead>
        <CircleWrapper>
          <HalfCircleBottomLeft />
          <HalfCircleBottomRight />
        </CircleWrapper>
        <CircleWrapper>
          <HalfCircleTopLeft />
          <HalfCircleTopRight />
        </CircleWrapper>

        <CouponBody>
          <Box direction="column">
            <Icon name="receiptLogoGrey" />
            <CouponName>
              {data?.name ?? ''}
              <Icon name="emojiFireCracker" />
            </CouponName>
            <Box>
              <Box className="placeholder-box" direction="column">
                {data?.type && <SectionPlaceholder>혜택</SectionPlaceholder>}
                {isBrwnie1stAnniversary && <SectionPlaceholder>대상</SectionPlaceholder>}
                <SectionPlaceholder>유효 기간</SectionPlaceholder>
                <SectionPlaceholder>이용 정책</SectionPlaceholder>
              </Box>
              <Box className="separator-box" direction="column">
                <SectionSeparator>:</SectionSeparator>
                {isBrwnie1stAnniversary && <SectionSeparator>:</SectionSeparator>}
                <SectionSeparator>:</SectionSeparator>
                <SectionSeparator>:</SectionSeparator>
              </Box>
              <Box direction="column">
                {data?.type && <SectionData>{getBenefit(data)}</SectionData>}
                {isBrwnie1stAnniversary && <SectionData>브라우니 신규 이용 고객</SectionData>}
                <SectionData>
                  {data?.expired_at ? dayjs(data.expired_at).format('YYYY.MM.DD 까지') : '무제한'}
                </SectionData>
                <SectionData>
                  {isBrwnie1stAnniversary ? (
                    <>
                      본 상품은 특별 프로모션으로 진행되는 상품으로 서비스 개시 후에는 해지 및 환불이 불가합니다.
                      <br />
                      쿠폰 이용은 [4주-주2회 패키지] 이상 상품 이용 시 사용 가능합니다. (1주, 12주, 24주 패키지 사용
                      불가)
                    </>
                  ) : (
                    `결제 시 사용한 쿠폰은 계약해지(환불) 시 반환되지 않습니다.`
                  )}
                </SectionData>
              </Box>
            </Box>
          </Box>
          <Button width={120} buttonTheme="primary" onClick={onClose}>
            확인
          </Button>
        </CouponBody>
      </Box>
    </ModalLayout>
  )
}

const Box = styled.div<{ direction?: string; flex?: string; alignItems?: string; justifyContent?: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
`

const CouponHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41.6rem;
  height: 13.1rem;
  border-radius: 1.6rem 1.6rem 0 0;
  background-color: ${({ theme }) => theme.colors.main};

  span {
    font-size: 36px;
    font-weight: bold;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.white};

    ${({ theme }) => theme.media.mobile`
      font-size: 3.2rem;
    `}
  }

  ${({ theme }) => theme.media.mobile`
    width: 31.2rem;
    height: 9.1rem;
  `}
`

const CouponBody = styled.div`
  width: 41.6rem;
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: 0 0 1.6rem 1.6rem;
  background-color: ${({ theme }) => theme.colors.white};

  & > button {
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-left: auto;
    ${({ theme }) => theme.media.mobile`
      margin-top:  ${theme.spacing(4)};
    `}
  }

  ${({ theme }) => theme.media.mobile`
    width: 31.2rem;
  `}
`

const CouponName = styled.span`
  display: inline-flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(5, 0)};
  color: ${({ theme }) => theme.colors.black};
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;

  & > svg {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  ${({ theme }) => theme.media.mobile`
    margin: ${theme.spacing(4, 0)}
  `}
`

const SectionPlaceholder = styled.span`
  width: 6.4rem;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;

  ${({ theme }) => theme.media.mobile`
    font-size: 1.4rem;
    width: 5.6rem;
  `}
`

const SectionSeparator = styled.span`
  text-align: center;
  margin: ${({ theme }) => theme.spacing(0, 2, 2, 2)};
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
  opacity: 0.5;

  ${({ theme }) => theme.media.mobile`
    font-size: 1.4rem;
    margin: 0 8px 16px 8px;
  `}
`

const SectionData = styled.span`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  line-height: 24px;
  word-break: keep-all;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.mobile`
    font-size: 14px;
    max-height: 80px;
    overflow-y: scroll
  `}
`

const CircleWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: -webkit-fill-available;
  height: 13px;
  display: flex;
  justify-content: space-between;

  & > div {
    width: 50%;
    height: 13px;
  }
`

const HalfCircleBottomLeft = styled.div`
  background: radial-gradient(circle at bottom left, transparent 13px, #003399 13px);
`
const HalfCircleBottomRight = styled.div`
  background: radial-gradient(circle at bottom right, transparent 13px, #003399 13px);
`
const HalfCircleTopLeft = styled.div`
  background: radial-gradient(circle at top left, transparent 13px, white 13px);
`
const HalfCircleTopRight = styled.div`
  background: radial-gradient(circle at top right, transparent 13px, white 13px);
`
