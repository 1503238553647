import Icon from 'components/Icon'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export default function LandingBrwnieCSService() {
  const history = useHistory()
  const handleGoServiceApply = () => {
    history.push('cs-pre')
  }

  return (
    <LandingContainer>
      <Content>
        <LeftWrap>
          <TitleWrap>
            <h3>이제는 CS 관리까지!</h3>
            <h1>
              고객 연락 90% 이상
              <br />
              줄여 드립니다!
            </h1>
          </TitleWrap>
          <Description>
            무인매장 관리 및 고객 응대의 노하우로
            <br />
            무인매장에 특화된 CS 서비스를 제공해 드립니다.
          </Description>
          <ServiceWrap>
            <ServiceItem>
              <IconWrap>
                <Icon name="CommunicationChat" />
              </IconWrap>
              <p>1:1 채팅방</p>
            </ServiceItem>

            <ServiceItem>
              <IconWrap>
                <Icon name="GeneralAnnouncement" />
              </IconWrap>
              <p>사장님 전용보고</p>
            </ServiceItem>

            <ServiceItem>
              <IconWrap>
                <Icon name="GeneralApps" />
              </IconWrap>
              <p>챗봇 관리</p>
            </ServiceItem>

            <ServiceItem>
              <IconWrap>
                <Icon name="InterfaceChart01" />
              </IconWrap>
              <p>CS 리포트</p>
            </ServiceItem>
          </ServiceWrap>
        </LeftWrap>

        <RightWrap>
          <img src="/image/landing_cs_service/cs_report_screen.png" alt="브라우니 CS 리포트 화면" />
        </RightWrap>
      </Content>

      <ButtonWrap>
        <ApplyButton onClick={handleGoServiceApply}>사전신청 바로가기</ApplyButton>
      </ButtonWrap>
    </LandingContainer>
  )
}

const LandingContainer = styled.section`
  width: 100%;

  background-color: #039;
  padding: 180px 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.mobile`
    height: auto;
    padding: 70px 16px;
  `}
`

const Content = styled.div`
  width: 1030px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-bottom: 52px;

  ${({ theme }) => theme.media.mobile`
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 32px;
  `}
`

const LeftWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${({ theme }) => theme.media.mobile`
    align-items: center;
  `}
`

const RightWrap = styled.div`
  width: 65%;

  & > img {
    width: 100%;
  }

  ${({ theme }) => theme.media.mobile`
    width: 100%;

    & > img {
      width: 90%;
      margin: 0 calc(5%);
    }
  `}
`
const ServiceWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  ${({ theme }) => theme.media.mobile`
    margin-bottom: 32px;
  `}
`

const TitleWrap = styled.div`
  color: #fff;
  text-align: left;
  margin-bottom: 28px;

  h3 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 400;
    color: #cdf;
  }

  h1 {
    font-size: 52px;
    font-weight: 700;
  }

  ${({ theme }) => theme.media.mobile`
    text-align: center;
    margin-bottom: 32px;

    h3 {
      font-size: 16px;
      line-height: 28px; /* 175% */
      margin-bottom: 8px;
    }

    h1 {
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;
    }
  `}
`

const Description = styled.p`
  margin-bottom: 66px;

  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #cdf;

  @media only screen and (max-width: 768px) {
    text-align: center;

    margin-bottom: 32px;
    font-size: 14px;
    line-height: 20px;
  }
`

const ButtonWrap = styled.div`
  width: 100%;
  position: relative;
`

const ApplyButton = styled.button`
  margin: 0 auto;
  display: flex;
  width: 240px;
  height: 60px;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  background: #212121;

  color: #fff;

  font-size: 16px;
  font-weight: 500;
  line-height: 28px;

  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: calc(100% - 32px);
  }
`

const ServiceItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > p {
    min-width: max-content;
    color: var(--White, #fff);
    font-size: 18px;
    font-weight: 800px;
  }

  ${({ theme }) => theme.media.mobile`
    & > p {
      font-size: 16px;
    }
  `}
`

const IconWrap = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: #3d5afe;

  margin-right: 8px;
  padding: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  ${({ theme }) => theme.media.mobile`
    border-radius: 12px;
  `}
`
