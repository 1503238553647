import client from '../client'
import { ServiceType } from './types'

export async function getService({ id }: GetServiceParam) {
  const response = await client.get<ServiceType>(`/service-api/v1/service/${id}`)

  return response.data
}

export type GetServiceParam = {
  id: string
}
