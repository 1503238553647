import styled, { css } from 'styled-components'

interface RadioButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  active: boolean
  label: string
  children?: React.ReactNode
  width?: number | string
  forRegion?: boolean
}

export default function RadioButton({ children, label, active, width, forRegion, style, ...props }: RadioButtonProps) {
  return (
    <StyledButton
      active={active}
      style={{ width, paddingLeft: forRegion ? (active ? 8 : 10) : active ? 10 : 12, ...style }}
      {...props}
    >
      <Indicator active={active} forRegion={!!forRegion}>
        <InnderIndicator />
      </Indicator>

      {children ? children : <Label>{label}</Label>}
    </StyledButton>
  )
}

const StyledButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 16px;
  background-color: #f6f6f6;
  padding: 0;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
      border: 2px solid #083994;
    `}
`

const Label = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.072px;
  color: #212121;

  ${({ theme }) => theme.media.mobile`
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.056px;
  `}
`

const Indicator = styled.span<{ active: boolean; forRegion: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 9999px;
  background: #ffffff;

  ${({ active }) =>
    active &&
    `
      background-color: #083994;
    `}

  ${({ forRegion }) => `
    margin-right: ${forRegion ? 6 : 12}px;
  `}

  ${({ theme, forRegion }) => theme.media.mobile`
    width: 20px;
    min-width: 20px;
    height: 20px;

    ${css`
      margin-right: ${forRegion ? 6 : 8}px;
    `}
  `}
`

const InnderIndicator = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  background-color: white;

  ${({ theme }) => theme.media.mobile`
    width: 8px;
    height: 8px;
  `}
`
