import React, { useState } from 'react'
import Icon from 'components/Icon'
import useAuth from 'hooks/query/auth/useAuth'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { ROUTES } from 'utils/common/routes'
import ImageSlider from '../ImageSlider'
import TesterEmailSendModal from '../TesterEmailSendModal'
import { useAppSelector } from 'hooks/useReduxHook'
import { getEventBannerStatus } from 'modules/features/eventBannerStatus/eventBannerStatusSlice'
import LogManager from 'lib/utils/logger'
import { UTM_URL } from 'utils/common/utmUrl'
import { SubstractBlack } from '../../Icon/svg/index'

function LandingImageSliderSection() {
  const history = useHistory()
  const { isLogin } = useAuth()
  const { pathname } = useLocation()
  const [testerModalOpen, setTesterModalOpen] = useState<boolean>(false)

  const isEventBannerStatus = useAppSelector(getEventBannerStatus)

  const handleApplyClick = () => {
    const route = isLogin
      ? ROUTES.STORE + UTM_URL.LANDING_LOGIN_SERVICE_START_FIRST_SECTION
      : ROUTES.AUTH_LOGIN + UTM_URL.LANDING_LOGOUT_SERVICE_START_FIRST_SECTION
    history.push(route)
    LogManager.Instance.sendLog('main_service', {
      page_url: pathname,
      btn_url: route,
    })
  }

  const calcMaxHeight = () => {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    return (100 * h) / 100 - 80
  }

  const handleArrowDownClick = () => {
    document.getElementById('landingWrapper')?.scrollTo({ top: calcMaxHeight(), left: 0, behavior: 'smooth' })
  }

  return (
    <LandingImageSliderSectionWrpper>
      <ImageSlider />
      <MobileDescriptionText>
        <span>
          [ <span>대한민국 NO.1 무인매장 정기관리 서비스</span> ]
        </span>
      </MobileDescriptionText>

      <MobileTitleText>
        무인매장 토탈케어
        <br />
        브라우니
      </MobileTitleText>

      <img src="icon/brwnie-logo-m.png" alt="브라우니 로고" className="mobile_logo" />

      <SectionContainer verticalLocation={isEventBannerStatus}>
        <FirstTitle verticalLocation={isEventBannerStatus}>
          <p>
            <span>[</span>
            <span> 대한민국 NO.1 무인매장 정기관리 서비스 </span>
            <span>]</span>
          </p>
        </FirstTitle>
        <h2>무인매장 토탈케어 브라우니</h2>

        <img src="icon/brwnie-logo-xl.png" alt="브라우니 로고" />

        <FreeTestContainer>
          <div className="textWrapper">
            <div>
              <h4>고민된다면 무료체험</h4>
            </div>
            <div className="iconWrapper">
              <Icon name="TesterTicket" />
            </div>
          </div>
          <FreeTestConfirmBtn
            onClick={() => {
              setTesterModalOpen(true)
              LogManager.Instance.sendLog('main_test_service', {
                btn_url: pathname,
              })
            }}
          >
            1회 체험권 받기
          </FreeTestConfirmBtn>
        </FreeTestContainer>

        <ApplyButtonWrapper>
          <div className="service-apply" onClick={handleApplyClick}>
            <Icon name="whiteLocation" />
            서비스 신청
          </div>
          <div
            className="easy-consulting"
            onClick={() => history.push(ROUTES.EASY_CONSULTING + UTM_URL.LANDING_CONSULTING_FIRST_SECTION)}
          >
            <Icon name="SubstractBlack" />
            상담 신청
          </div>
        </ApplyButtonWrapper>

        <InformationContainer onClick={handleArrowDownClick}>
          <ScrollInformationIcon name="WhiteArrowDown" />
        </InformationContainer>
      </SectionContainer>
      {testerModalOpen && <TesterEmailSendModal open={testerModalOpen} onClose={() => setTesterModalOpen(false)} />}
    </LandingImageSliderSectionWrpper>
  )
}

const LandingImageSliderSectionWrpper = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;

  .mobile_logo {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
    height: calc(var(--aosvh, 1vh) * 100);

    .mobile_logo {
      display: flex;

      position: absolute;
      left: 50%;
      bottom: 40%;
      transform: translate(-50%, -40%);
      width: 160px;
    }
  }
`

const MobileDescriptionText = styled.p`
  span {
    color: #fede00;
    & > span {
      color: #ffffff;
    }
  }

  display: none;

  position: absolute;

  width: 100%;
  left: 50%;
  bottom: 58%;
  transform: translate(-50%, -58%);

  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  text-shadow: 0px 0px 20px rgba(30, 30, 44, 0.6);

  @media only screen and (max-width: 768px) {
    display: flex;

    justify-content: center;
    text-align: center;
  }
`

const MobileTitleText = styled.p`
  display: none;
  position: absolute;

  width: 100%;
  left: 50%;
  bottom: 44%;
  transform: translate(-50%, -44%);

  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  align-items: center;
  color: var(--gray0);

  @media only screen and (max-width: 768px) {
    display: flex;

    justify-content: center;
    text-align: center;
  }
`

const SectionContainer = styled.div<{ verticalLocation: boolean }>`
  margin: 0px 125px;
  height: 100vh;
  display: flex;
  padding-top: 80px;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;

  h2 {
    width: max-content;
    text-align: center;

    font-weight: 700;
    font-size: 72px;
    line-height: 90px;
    color: ${({ theme }) => theme.colors.white};
    text-shadow: 0px 0px 20px rgba(30, 30, 44, 0.6);
    z-index: 1;
    margin: 0 auto;
    margin-top: 28px;
  }

  img {
    height: fit-content;
    width: fit-content;
    margin: 0 auto;
    margin-top: 24px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const FirstTitle = styled.div<{ verticalLocation: boolean }>`
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  text-shadow: 0px 0px 20px rgba(30, 30, 44, 0.6);

  p > span {
    font-weight: 400;
    font-size: 30px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.white};

    &:first-child,
    &:last-child {
      opacity: 0.7;
      margin-right: 8px;
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
`

const FreeTestContainer = styled.div`
  /* 운영상 임시 none */
  display: none;

  margin-top: 40px;
  width: 340px;
  height: 172px;
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(24px);
  border-radius: 32px;
  position: relative;
  color: ${({ theme }) => theme.colors.white};

  .textWrapper {
    margin-top: 32px;
    margin-bottom: 12px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }

    span {
      opacity: 0.7;
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
    }

    .iconWrapper {
      & > svg {
        width: 105px;
        height: 54px;
      }
    }
  }
`

const FreeTestConfirmBtn = styled.div`
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.indigoA700};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: inherit;
  width: 100%;
  height: 64px;
  display: flex;
  position: absolute;
  bottom: 0px;

  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color ease-out 0.15s, color ease-out 0.15s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.black};
  }
`

const ApplyButtonWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 104px;

  div {
    display: flex;
    align-items: center;
    text-align: center;
    width: 170px;
    height: 64px;
    font-size: 16px;
    line-height: 28px;
    color: var(--gray0);
    box-sizing: border-box;

    transition: background-color 0.15s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: var(--brand400);
    }
  }

  .service-apply {
    width: max-content;
    height: 64px;
    font-weight: 700;
    justify-content: flex-end;
    padding: 0px 32px;
    border-radius: 12px;
    background: #3d5afe;
    margin-right: 16px;
  }

  .easy-consulting {
    width: max-content;
    height: 64px;
    font-weight: 700;
    justify-content: flex-end;
    padding: 0px 32px;
    border-radius: 12px;
    background: #fff;
    color: #212121;
    cursor: pointer;

    & > svg {
      fill: #212121;
    }

    &:hover {
      color: #fff;

      & > svg {
        & > path {
          fill: #fff !important;
        }
      }
    }
  }

  svg {
    margin-right: 6px;
  }
`

const InformationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: calc(50% - 24px);
  position: absolute;
  bottom: 24px;
  height: 58px;
  cursor: pointer;
`

const ScrollInformationIcon = styled(Icon)`
  display: flex;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  animation: motion 0.5s linear 0s infinite alternate;
  margin-top: 0;

  @keyframes motion {
    0% {
      margin-bottom: 0px;
    }
    100% {
      margin-bottom: 16px;
    }
  }
`

export default React.memo(LandingImageSliderSection)
