import { float, mobileFloat } from 'assets/styles/animation'
import styled, { css } from 'styled-components'

export type SnackBarProps = {
  open: boolean
  onClose: () => void
  innerText: string
}

export default function SnackBar({ open, onClose, innerText }: SnackBarProps) {
  return (
    <SnackBarWrapper onClick={onClose} open={open}>
      <h4>{innerText}</h4>
      <span>확인</span>
    </SnackBarWrapper>
  )
}

const SnackBarWrapper = styled.div<{ open: boolean }>`
  cursor: pointer;
  position: fixed;
  bottom: 32px;
  left: calc(50% - 217px);
  width: 434px;
  box-sizing: border-box;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: ${float} 0.3s ease-in-out;
  animation-fill-mode: forwards;
  z-index: 10000001;

  & > h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.gray200};
    white-space: pre;
  }

  & > span {
    margin-left: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.skyBlue};
  }

  ${({ open }) =>
    !open &&
    css`
      display: none;
    `}

  @media only screen and (max-width: 768px) {
    width: 328px;
    height: 80px;
    bottom: 16px;
    left: calc(50% - 164px);
    justify-content: space-between;
    animation: ${mobileFloat} 0.3s ease-in-out;

    & > h4 {
      font-size: 14px;
      line-height: 24px;
      width: 176px;
      word-break: keep-all;
    }

    & > span {
      margin-left: 0px;
      font-size: 12px;
      line-height: 20px;
    }
  }
`
