import Spinner from 'components/common/Spinner'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import useAuth from '../../hooks/query/auth/useAuth'

export type AuthRouteProps = {
  component?: any
  children?: any
} & RouteProps

export default function AuthRoute(props: AuthRouteProps) {
  const { component: Component, children, ...rest } = props
  const { isLogin, isAuthLoading } = useAuth()
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAuthLoading ? (
          <Spinner color="main" />
        ) : isLogin ? (
          Component ? (
            <Component {...routeProps} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  )
}
