import client from '../client'
import { SendPhoneRes } from './types'

export async function postSendPhone({ phone }: SendPhoneReq) {
  const response = await client.post<SendPhoneRes>('auth-api/v1/auth/send/phone', { phone })

  return response.data
}

export type SendPhoneReq = {
  phone: string
}
