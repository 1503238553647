import Icon from 'components/Icon/Icon'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ROUTES } from 'utils/common/routes'

export default function ServiceApplyBanner() {
  const history = useHistory()
  const handleBannerClick = () => history.push(ROUTES.STORE)

  return (
    <Container>
      <LeftWrap>
        <Icon name="BrwnieLogoSmall" />
        <span>브라우니를 부르고 싶다면?</span>
      </LeftWrap>
      <ServiceApplyButton onClick={handleBannerClick}>서비스 신청</ServiceApplyButton>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px;

  background: #f5f9fe;
  border-radius: 12px;

  margin-top: 18px;
  margin-bottom: 18px !important;

  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`
const LeftWrap = styled.div`
  display: flex;
  align-items: center;
  & > span {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    margin-left: 10px;
  }
`

const ServiceApplyButton = styled.button`
  background-color: transparent;
  text-decoration: underline;

  font-weight: 700;
  font-size: 16px;
  color: #3d5afe;

  cursor: pointer;
`
