import { useQuery } from 'react-query'
import { COUPON_STATE, getCoupons, GetCouponsParam } from 'lib/api/coupon/getCoupons'

export default function useGetCoupons(value: GetCouponsParam) {
  return useQuery(createKey(value.page, value.state, value.expired, value.perPage), () => getCoupons(value))
}

const createKey = (page?: number, state?: COUPON_STATE, expired?: boolean, perPage?: number) => [
  'get_coupons',
  page,
  state,
  expired,
  perPage,
]
useGetCoupons.createKey = createKey
