import { ChangeEvent, Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FieldErrors, useForm } from 'react-hook-form'
import { BottomCTA } from 'ohds-web'
import styled from 'styled-components'
import { AxiosError } from 'axios'

import { Input } from './Input'
import { Select } from './Select'
import { Checkbox } from './Checkbox'

import { useGetCommonCodeQuery } from 'hooks/query/admin/useGetCommonCodeQuery'

import { REGION_OBJ, SIGUNGU_OPTIONS, TIME_OPTIONS } from './constants'
import { usePostBrwnieSelfCrewMutation } from 'hooks/query/admin/usePostBrwnieCrewMutation'

import { ReactComponent as ArrowRightIcon } from './arrow-right.svg'
import { ReactComponent as ExclamationCircleFilledIcon } from './exclamation-circle-filled.svg'

const UNIFORM_SIZE_CODE = 'Z07'
const BANK_CODE = 'Z06'

const WEEK_LIST = ['일', '월', '화', '수', '목', '금', '토']

const TERM_URL = 'https://higherx.notion.site/25ecc1165f2748d28e7cb0c9fd93888c?pvs=4'
export interface NewCrewFormType {
  name: string
  storeName: string
  phone: string
  registrationNumber1: string
  registrationNumber2: string
  bankCode: string
  uniformSizeCode: string
  bankAccount: string
  actSido: string
  actSigungu: string
  actStartTime: string
  actEndTime: string
  actDayOfWeek: string
}

export function NewCrewInfoForm() {
  const history = useHistory()

  const [errorNode, setErrorNode] = useState<string>()

  const { data: codeData = [] } = useGetCommonCodeQuery()
  const { mutateAsync: postBrwnieSelfCrewMutation, isLoading: postLoading } = usePostBrwnieSelfCrewMutation()

  const UNIFORM_SIZE_OPTIONS = codeData
    .filter(({ codeKind }) => codeKind === UNIFORM_SIZE_CODE)
    .map(({ codeValue, codeDesc, codeCd }) => ({ value: codeValue, code: codeCd, label: codeDesc }))

  const BANK_OPTIONS = codeData
    .filter(({ codeKind }) => codeKind === BANK_CODE)
    .map(({ codeValue, codeDesc, codeCd }) => ({ value: codeValue, code: codeCd, label: codeDesc }))

  const getBankCodeData = (code: string) => {
    const find = BANK_OPTIONS.find(({ value }) => value === code)
    return { codeValue: find?.value ?? '', codeCd: find?.code ?? '' }
  }

  const getUniformCodeData = (code: string) => {
    const find = UNIFORM_SIZE_OPTIONS.find(({ value }) => value === code)
    return { codeValue: find?.value ?? '', codeCd: find?.code ?? '' }
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<NewCrewFormType>({
    defaultValues: {
      actSido: '서울특별시',
      actSigungu: REGION_OBJ['서울특별시'][0],
      // 초기값 9시
      actStartTime: TIME_OPTIONS[16].value,
      // 초기값 18시
      actEndTime: TIME_OPTIONS[34].value,
      bankCode: undefined,
    },
    mode: 'onBlur',
  })

  const [termCheck, setTermCheck] = useState(false)

  const onSubmit = async (form: NewCrewFormType) => {
    try {
      if (postLoading) {
        return null
      }

      if (!form.bankCode || form.bankCode === '은행 선택') {
        setErrorNode(`은행을 선택해주세요.`)
        const bankCodeElement = document.querySelector('#bankCode')

        // 해당 Element로 스크롤
        bankCodeElement?.scrollIntoView({
          behavior: 'smooth',
        })

        return
      }

      if (!form.uniformSizeCode) {
        setErrorNode(`유니폼 사이즈를 선택해주세요.`)
        const uniformSizeCodeElement = document.querySelector('#uniformSizeCode')

        // 해당 Element로 스크롤
        uniformSizeCodeElement?.scrollIntoView({
          behavior: 'smooth',
        })

        return
      }

      if (!form.actDayOfWeek || form.actDayOfWeek.length === 0) {
        setErrorNode(`활동 가능 요일을 선택해주세요.`)

        const actDayOfWeekElement = document.querySelector('#actDayOfWeek')

        // 해당 Element로 스크롤
        actDayOfWeekElement?.scrollIntoView({
          behavior: 'smooth',
        })

        return
      }

      await postBrwnieSelfCrewMutation({
        name: form.name,
        storeName: form.storeName,
        phone: form.phone,
        bankAccount: form.bankAccount,
        actSido: form.actSido,
        actSigungu: form.actSigungu,
        actDayOfWeek: form.actDayOfWeek,
        registrationNo: form.registrationNumber1 + form.registrationNumber2,
        bankCode: getBankCodeData(form.bankCode),
        uniformSizeCode: getUniformCodeData(form.uniformSizeCode),
        actStartTime: form.actStartTime + ':00',
        actEndTime: form.actEndTime + ':00',
      })

      history.push('/new-crew-complete')
    } catch (err) {
      const error = err as AxiosError

      if (error.response?.data.message === 'This user is already registered.') {
        setErrorNode(`이미 등록된 주민등록번호에요\n다시 한번 확인해주세요.`)

        const overlapErrorElement = document.querySelector('#overlapError')

        // 해당 Element로 스크롤
        overlapErrorElement?.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }

  const handleWeekCheckChange = (event: ChangeEvent<HTMLInputElement>, week_index: number) => {
    const tmpDayOfWeek = watch('actDayOfWeek') ?? ''
    const list = tmpDayOfWeek.split(',')

    const findIndex = list.findIndex((week) => week === week_index.toString())

    if (list.includes(week_index.toString())) {
      list.splice(findIndex, 1)
    } else {
      list.push(week_index.toString())
    }

    list.splice(0, 1)
    setValue('actDayOfWeek', list.join(','))
  }

  const handleGoTermInfo = () => {
    window.open(TERM_URL, '_blank')
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <InputWrap>
        <label htmlFor="store">
          근무 매장<span>*</span>
        </label>
        <Input
          id="store"
          placeholder="필수 입력"
          {...register('storeName', {
            required: '필수 입력 항목입니다.',
          })}
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="name">
          성함<span>*</span>
        </label>
        <Input
          id="name"
          placeholder="필수 입력"
          {...register('name', {
            required: '필수 입력 항목입니다.',
          })}
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="phone">
          연락처<span>*</span>
        </label>
        <Input
          id="phone"
          placeholder="'-' 없이 필수 입력"
          errorText={errors && errors.phone?.message}
          {...register('phone', {
            required: '필수 입력 항목입니다.',
            pattern: {
              value: new RegExp(/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/),
              message: '전화번호 형식을 확인해주세요.',
            },
          })}
          onChange={(e) => {
            const { value } = e.currentTarget
            if (value.length > 12) {
              return setValue('phone', value.substring(0, 12))
            }
            setValue('phone', value)
          }}
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="registrationNumber">
          주민등록번호<span>*</span>
        </label>
        <div className="wrap">
          <Input
            id="registrationNumber1"
            errorText={errors && errors.registrationNumber1?.message}
            {...register('registrationNumber1', {
              required: '필수 입력 항목입니다.',

              pattern: {
                value: new RegExp(/^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))$/),
                message: '생년월일을 입력해주세요.',
              },
              minLength: {
                value: 6,
                message: '6자리 생년월일을 입력해주세요.',
              },
              maxLength: {
                value: 6,
                message: '6자리 생년월일을 입력해주세요.',
              },
            })}
            onChange={(e) => {
              const { value } = e.currentTarget
              if (value.length > 6) {
                return setValue('registrationNumber1', value.substring(0, 5))
              }
              setValue('registrationNumber1', value)
            }}
          />
          <span>-</span>
          <Input
            id="registrationNumber2"
            errorText={errors && errors.registrationNumber2?.message}
            {...register('registrationNumber2', {
              required: '필수 입력 항목입니다.',
              minLength: {
                value: 7,
                message: '주민등록번호 뒷자리는 7자리를 입력해주세요.',
              },
              maxLength: {
                value: 7,
                message: '주민등록번호 뒷자리는 7자리를 입력해주세요.',
              },
            })}
            onChange={(e) => {
              const { value } = e.currentTarget
              if (value.length > 7) {
                return setValue('registrationNumber2', value.substring(0, 6))
              }
              setValue('registrationNumber2', value)
            }}
          />
        </div>
      </InputWrap>
      <InputWrap>
        <label htmlFor="bank">
          급여 계좌 번호<span>*</span>
        </label>
        <Select
          options={BANK_OPTIONS}
          id="bankCode"
          name="bankCode"
          placeholder="은행 선택"
          style={{ marginBottom: 12 }}
          value={watch('bankCode')}
          onChange={(e) =>
            register('bankCode', {
              required: '필수 입력 항목입니다.',
            }).onChange(e)
          }
          // {...register('bankCode', { required: '필수 입력 항목입니다.' })}
        />
        <Input
          id="bank-number"
          placeholder="'-' 없이 필수 입력"
          {...register('bankAccount', {
            required: '필수 입력 항목입니다.',
          })}
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="uniformSizeCode">
          유니폼 사이즈<span>*</span>
        </label>
        <Select
          id="uniformSizeCode"
          name="uniformSizeCode"
          placeholder="필수 선택"
          options={UNIFORM_SIZE_OPTIONS}
          value={watch('uniformSizeCode')}
          onChange={(e) =>
            register('uniformSizeCode', {
              required: '필수 입력 항목입니다.',
            }).onChange(e)
          }
          style={{ marginBottom: 12 }}
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="sido">
          활동 가능 지역<span>*</span>
        </label>
        <div className="wrap">
          <Select
            id="actSido"
            name="actSido"
            placeholder="필수 선택"
            options={Object.keys(REGION_OBJ).map((region) => ({ value: region, label: region }))}
            value={watch('actSido')}
            onChange={(e) =>
              register('actSido', {
                required: '필수 입력 항목입니다.',
              }).onChange(e)
            }
          />
          <Select
            id="actSigungu"
            name="actSigungu"
            placeholder="필수 선택"
            options={SIGUNGU_OPTIONS(watch('actSido') ?? '서울특별시')}
            value={watch('actSigungu')}
            onChange={(e) =>
              register('actSigungu', {
                required: '필수 입력 항목입니다.',
              }).onChange(e)
            }
          />
        </div>
      </InputWrap>
      <InputWrap>
        <label htmlFor="phone">
          활동 가능 시간<span>*</span>
        </label>
        <div className="wrap">
          <Select
            id="actStartTime"
            name="actStartTime"
            placeholder="시작 시간"
            options={TIME_OPTIONS}
            value={watch('actStartTime')}
            onChange={(e) =>
              register('actStartTime', {
                required: '필수 입력 항목입니다.',
              }).onChange(e)
            }
          />
          <Select
            id="actEndTime"
            name="actEndTime"
            placeholder="종료 시간"
            options={TIME_OPTIONS}
            value={watch('actEndTime')}
            onChange={(e) =>
              register('actEndTime', {
                required: '필수 입력 항목입니다.',
              }).onChange(e)
            }
          />
        </div>
      </InputWrap>
      <InputWrap id="actDayOfWeek">
        <label htmlFor="actDayOfWeek">
          활동 가능 요일<span>*</span>
        </label>
        <div className="wrap">
          {WEEK_LIST.map((label, index) => (
            <Fragment key={'week' + index}>
              <Checkbox isSunday={label === '일'}>
                <input type="checkbox" id={`${label}_id`} onChange={(e) => handleWeekCheckChange(e, index + 1)} />
                <div>{label}</div>
              </Checkbox>
            </Fragment>
          ))}
        </div>
      </InputWrap>

      <TermWrap onClick={handleGoTermInfo}>
        <label
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <input
            type="checkbox"
            checked={termCheck}
            onChange={(e) => {
              setTermCheck(e.currentTarget.checked as boolean)
            }}
          />
          개인정보 수집 동의<span> (필수)</span>
        </label>

        <ArrowRightIcon />
      </TermWrap>
      {errorNode && (
        <ErrorInfoWrap id="overlapError">
          <ExclamationCircleFilledIcon />
          <span>
            {errorNode}
            {/* 이미 등록된 주민등록번호에요.
            <br />
            다시 한번 확인해주세요. */}
          </span>
        </ErrorInfoWrap>
      )}

      <BottomCTA
        primaryProps={{ label: '작성 완료', itemType: 'submit', loading: postLoading, disabled: !termCheck }}
      />
    </Container>
  )
}

const Container = styled.form`
  padding: 28px 16px 108px 16px;
`

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > label {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    margin-bottom: 10px;

    & > span {
      color: #1677ff;
    }
  }

  &:not(:last-child) {
    margin-bottom: 28px;
  }

  .wrap {
    display: flex;
    align-items: center;
    gap: 8.5px;
  }
`

const TermWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > label {
    display: flex;
    align-items: center;

    & > input {
      width: 16px;
      height: 16px;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      margin-right: 8px;
    }

    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    & > span {
      color: #1677ff;
      margin-left: 4px;
    }
  }
`

const ErrorInfoWrap = styled.div`
  margin-top: 14px;

  display: flex;
  padding: 10px 16px;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  background: #f6f6f6;

  & > span {
    margin-left: 10px;
    color: #212121;

    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`
