import CustomCheckbox from 'components/common/CustomCheckbox'
import Icon from 'components/Icon'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { fadeIn } from '../../../assets/styles/animation'
import useTermData from 'data/useTermData.json'
import usePostEventMailSend from 'hooks/query/eventMailSend/usePostEventMailSend'
import Spinner from 'components/common/Spinner'
import { useAppDispatch } from 'hooks/useReduxHook'
import { openSnackBar } from 'modules/features/snackBarStatus/snackBarStatusSlice'

const modalEl: HTMLElement = document.getElementById('modal') as HTMLElement

export type TesterEmailSendModalProps = {
  open: boolean
  onClose: () => void
}

export default function TesterEmailSendModal({ open, onClose }: TesterEmailSendModalProps) {
  const [marketingStatus, setMarketingStatus] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)
  const [checkBoxError, setCheckBoxError] = useState<boolean>(false)
  const { mutateAsync: mutatePostEventMailSend, isLoading } = usePostEventMailSend()
  const dispatch = useAppDispatch()

  const handleMarketingAgreeClick = () => {
    if (marketingStatus) {
      setCheckBoxError(false)
    }
    setMarketingStatus(!marketingStatus)
  }

  const handleEmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail(value)
  }

  const handleSubmitClick = async () => {
    const reqEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/.test(email)

    !marketingStatus && setCheckBoxError(true)
    !reqEmail && setEmailError(true)

    if (marketingStatus && reqEmail) {
      await mutatePostEventMailSend({ email: email })
      dispatch(openSnackBar('체험권이 발송됐어요~ 이메일을 확인해주세요'))
      onClose()
    }
  }

  return createPortal(
    <>
      {open ? (
        <Dimmer onClick={onClose}>
          <ModalWrapper onClick={(e) => e.stopPropagation()}>
            <StyledModal>
              <TitleWrapper>
                <span>무료 체험권 신청</span>
              </TitleWrapper>
              <GrayRegionWrapper>
                <p>
                  <strong>서울, 성남, 수원, 인천(강화 및 중구 일부 제외) 지역만 신청 가능</strong>합니다. 다른 지역은
                  확장 예정입니다.
                </p>
              </GrayRegionWrapper>
              <FormWrapper>
                <FormInput
                  placeholder="이메일 주소를 입력해 주세요"
                  type="email"
                  onChange={handleEmailOnChange}
                  value={email}
                  onFocus={() => setEmailError(false)}
                />
                {email.length > 0 && (
                  <span>
                    <Icon name="SmallClose" onClick={() => setEmail('')} />
                  </span>
                )}
              </FormWrapper>
              {emailError && (
                <WarningText>
                  <Icon name="redError" />
                  {email.length === 0 ? '필수 입력 항목입니다.' : '이메일 형식이 맞지 않습니다.'}
                </WarningText>
              )}

              <CheckBoxWrapper>
                <CustomCheckbox>
                  <input
                    type="checkbox"
                    id={'marketing_id'}
                    onChange={handleMarketingAgreeClick}
                    checked={marketingStatus}
                    onFocus={() => setCheckBoxError(false)}
                  />
                </CustomCheckbox>

                <label htmlFor={'marketing_id'}>
                  <h5>마케팅 정보 이메일/SMS 수신 동의 (필수)</h5>
                </label>
                <span onClick={() => window.open(useTermData[3].link)}>보기</span>
              </CheckBoxWrapper>
              {checkBoxError && (
                <WarningText>
                  <Icon name="redError" /> 필수 입력 항목입니다.
                </WarningText>
              )}
              <FooterWrapper>
                <div onClick={onClose}>취소</div>
                <SubmitText onClick={handleSubmitClick} ready={marketingStatus && email.length >= 5}>
                  {isLoading ? <Spinner color="main" /> : '체험권 받기'}
                </SubmitText>
              </FooterWrapper>
            </StyledModal>
          </ModalWrapper>
        </Dimmer>
      ) : null}
    </>,
    modalEl
  )
}

const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(30, 30, 44, 0.4);
  z-index: 10000003 !important;
  animation: ${fadeIn} 0.3s ease-in-out;
`

const ModalWrapper = styled.div`
  width: 480px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  z-index: 10000004 !important;

  @media only screen and (max-width: 768px) {
    width: 328px;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  padding: 16px;
  padding-right: 4px;
  height: 64px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.white};

  & > span {
    display: flex;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }

  button {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.main};
    cursor: pointer;
  }

  :focus-within {
    ime-mode: disabled;
    -webkit-ime-mode: disabled;
    -moz-ime-mode: disabled;
    -ms-ime-mode: disabled;
    border: solid 1px ${({ theme }) => theme.colors.indigoA700};
  }
`

const FormInput = styled.input`
  width: 100%;
  border: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 24px;
  padding: 0;

  :disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray500};
  }
`

const StyledModal = styled.div`
  width: 100%;
  padding: 32px;
  padding-bottom: 48px;
  box-sizing: border-box;
  position: relative;
`

const WarningText = styled.div`
  margin-top: 8px;
  display: flex;
  height: 28px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.warning};

  & > svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`

const CheckBoxWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;

  & > label {
    &:nth-of-type(2) {
      width: 342px;
      margin-left: 10px;
    }

    & > h5 {
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.gray500};
      font-weight: normal;
    }
  }

  & > span {
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.gray500};
  }

  @media only screen and (max-width: 768px) {
    & > label {
      &:nth-of-type(2) {
        width: 190px;
      }
      & > h5 {
        word-break: keep-all;
      }
    }

    & > span {
      align-items: flex-start;
    }
  }
`

const TitleWrapper = styled.div`
  width: 284px;
  height: 56px;
  margin-bottom: 24px;

  display: flex;
  align-items: end;

  color: ${({ theme }) => theme.colors.black};

  & > span {
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    display: flex;
    align-items: center;
  }

  & > svg {
    margin-left: 24px;
    width: 80px;
    height: 56px;
  }

  @media only screen and (max-width: 768px) {
    height: 40px;
    & > span {
      font-size: 20px;
      line-height: 28px;
    }

    & > svg {
      width: 40px;
      height: 26px;
    }
  }
`

const GrayRegionWrapper = styled.div`
  width: 416px;
  height: 104px;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 24px;
  word-break: keep-all;
  background-color: ${({ theme }) => theme.colors.lightGray};

  & > p {
    font-size: 16px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.gray500};

    & > strong {
      color: ${({ theme }) => theme.colors.indigoA700};
    }

    & > span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.indigoA700};
    }
  }

  @media only screen and (max-width: 768px) {
    width: 264px;
    height: 116px;
    padding: 16px;
  }
`

const FooterWrapper = styled.div`
  position: absolute;
  z-index: 10000004 !important;
  border-top: 1px solid #e0e0e0;
  left: 0px;
  bottom: -49px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 49px;
  border-radius: 0px 0px 16px 16px;

  & > div {
    border-radius: inherit;
    width: 240px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.black};
    height: 48px;
  }
`

const SubmitText = styled.span<{ ready: boolean }>`
  transition: all 0.3 ease-in-out;
  border-radius: inherit;
  width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 28px;
  height: 48px;
  color: ${({ theme, ready }) => (ready ? theme.colors.indigoA700 : theme.colors.gray)};
  font-weight: 700;
`
