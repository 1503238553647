import Button from 'components/common/Button'
import useStoreQuery from 'hooks/query/store/useStoreQuery'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import storeQuestionData from 'data/storeQuestionData.json'
import CustomRadioButton from 'components/common/CustomRadioButton'
import SelectionLabel from 'components/common/SelectionLabel'
import { useForm } from 'react-hook-form'
import Icon from 'components/Icon'
import CustomCheckbox from 'components/common/CustomCheckbox'
import FormTextInput from 'components/common/FormTextInput'
import { useEffect, useState } from 'react'
import usePatchStore from 'hooks/query/store/usePatchStore'
import FooterButtonBox from 'components/common/FooterButtonBox'
import { DetailInput } from 'components/common/AddressSearch/AddressSearch'

const combineEtc = (array?: string[], etc?: string) => {
  if (array === undefined || etc === undefined) return
  return etc !== '' ? [...array, etc] : array
}

export default function StoreModifyForm() {
  const history = useHistory()
  const { storeId } = useParams<{ storeId: string }>()
  const { data: store } = useStoreQuery({ id: storeId })
  const { mutateAsync: mutatePatchStore } = usePatchStore()
  const [clickedSubject, setClickedSubject] = useState<string[]>([])

  const {
    register,
    setValue,
    setFocus,
    getValues,
    watch,
    handleSubmit,
    formState: { isDirty, dirtyFields, isValid },
  } = useForm({ mode: 'onChange' })

  useEffect(() => {
    setValue('store_name', store?.store_name)
    setValue('detail_address', store?.detail_address)
  }, [setValue, store])

  const handleClickQuestion = (subject: string) => {
    if (subject === 'store_name') return
    const index = clickedSubject.findIndex((item) => item === subject)

    if (index !== -1) {
      clickedSubject.splice(index, 1)
    } else {
      clickedSubject.push(subject)

      if (!store) return

      type ObjType = {
        [index: string]: any
      }
      const storeObj: ObjType = store

      setValue(subject, storeObj[subject])
    }

    setClickedSubject([...clickedSubject])
  }

  const checkEtcValid = () => {
    let result = true
    clickedSubject.forEach((subject) => {
      if (!!watch(subject) && watch(subject).includes('etc'))
        if (watch(`${subject}_etc_text`) === '') {
          result = false
          return
        }
    })

    return result
  }

  const handleClickEtcInput = (subject: string) => {
    const valueOfSubject = getValues(subject)

    if (typeof valueOfSubject === 'undefined') return

    if (Array.isArray(valueOfSubject)) {
      setValue(subject, [...valueOfSubject, 'etc'])
    } else {
      setValue(subject, 'etc')
    }
  }

  const onSubmit = async (data: {
    area?: string
    big_machine_count?: string
    big_machine_kind?: string[]
    big_machine_kind_etc_text?: string
    chair_count?: string
    cleaning_tool?: string[]
    cleaning_tool_etc_text?: string
    detail_address: string
    dong: string
    kakao_address: string
    refill_kind?: string[]
    refill_kind_etc_text?: string
    room_count?: string
    shelf_count?: string[]
    small_machine_count?: string
    small_machine_kind?: string[]
    small_machine_kind_etc_text?: string
    store_name: string
    table_count?: string
    toilet?: string
    trash_can_count?: string
    type?: string
    type_etc_text?: string
  }) => {
    const {
      area,
      big_machine_count,
      big_machine_kind,
      big_machine_kind_etc_text,
      chair_count,
      cleaning_tool,
      cleaning_tool_etc_text,
      detail_address,
      refill_kind,
      refill_kind_etc_text,
      room_count,
      shelf_count,
      small_machine_count,
      small_machine_kind,
      small_machine_kind_etc_text,
      store_name,
      table_count,
      toilet,
      trash_can_count,
      type,
      type_etc_text,
    } = data

    await mutatePatchStore({
      id: storeId,
      area,
      big_machine_count,
      big_machine_kind: combineEtc(big_machine_kind, big_machine_kind_etc_text),
      chair_count,
      cleaning_tool: combineEtc(cleaning_tool, cleaning_tool_etc_text),
      detail_address,
      refill_kind: combineEtc(refill_kind, refill_kind_etc_text),
      room_count,
      shelf_count,
      small_machine_count,
      small_machine_kind: combineEtc(small_machine_kind, small_machine_kind_etc_text),
      store_name,
      table_count,
      toilet,
      trash_can_count,
      type: type_etc_text || type,
    })
    history.goBack()
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <header>
          <h4>{store?.store_name}</h4>
        </header>
        <QuestionList>
          {storeQuestionData.map(({ subject, question, type, selection, etc }, i) => {
            if (!store) return null

            let answer = ''

            type ObjType = {
              [index: string]: any
            }
            const storeObj: ObjType = store

            answer = storeObj[subject]

            if (subject === 'address') {
              answer = `${store.address_name} ${store.detail_address}`
            }

            return (
              <li
                onClick={
                  !clickedSubject.includes(subject)
                    ? () => type !== 'text' && !dirtyFields[subject] && handleClickQuestion(subject)
                    : undefined
                }
                key={subject}
                className={type !== 'text' && !dirtyFields[subject] ? undefined : 'no_arrow'}
              >
                <p
                  onClick={
                    clickedSubject.includes(subject)
                      ? () => type !== 'text' && !dirtyFields[subject] && handleClickQuestion(subject)
                      : undefined
                  }
                >
                  {i + 1}. {question}
                  {type !== 'text' &&
                    (clickedSubject.includes(subject) ? (
                      !dirtyFields[subject] && <StyledIcon name="blackDownArrow" />
                    ) : (
                      <Icon name="blackDownArrow" />
                    ))}
                </p>
                {type === 'radio' &&
                  (clickedSubject.includes(subject) ? (
                    <SelectionList>
                      {selection?.map((item) => (
                        <li key={item}>
                          <CustomRadioButton htmlFor={`${subject}_${item}`} animationOn={true}>
                            <input
                              type="radio"
                              id={`${subject}_${item}`}
                              value={item}
                              {...register(subject, { required: true })}
                            />
                          </CustomRadioButton>
                          <SelectionLabel htmlFor={`${subject}_${item}`} label={item} />
                        </li>
                      ))}
                      {etc && (
                        <li>
                          <CustomRadioButton htmlFor="etc" animationOn={true}>
                            <input
                              type="radio"
                              id={`${subject}_etc`}
                              value="etc"
                              {...register(subject, { required: true })}
                              onClick={() => setFocus(`${subject}_etc_text`)}
                            />
                          </CustomRadioButton>
                          <SelectionLabel htmlFor={`${subject}_etc`} label="기타 :" />
                          <FormTextInput
                            type="text"
                            id={`${subject}_etc_text`}
                            onClick={() => handleClickEtcInput(subject)}
                            {...register(`${subject}_etc_text`)}
                          />
                        </li>
                      )}
                    </SelectionList>
                  ) : (
                    <div>
                      <CustomRadioButton htmlFor={subject}>
                        <input type="radio" id={subject} checked readOnly />
                      </CustomRadioButton>
                      <SelectionLabel htmlFor={subject} label={answer} />
                    </div>
                  ))}

                {type === 'check' &&
                  (clickedSubject.includes(subject) ? (
                    <SelectionList>
                      {selection?.map((item) => (
                        <li key={item}>
                          <CustomCheckbox htmlFor={`${subject}_${item}`}>
                            <input
                              type="checkbox"
                              id={`${subject}_${item}`}
                              value={item}
                              {...register(subject, { required: true })}
                            />
                          </CustomCheckbox>
                          <SelectionLabel htmlFor={`${subject}_${item}`} label={item} />
                        </li>
                      ))}
                      {etc && (
                        <li>
                          <CustomCheckbox>
                            <input
                              type="checkbox"
                              id={`${subject}_etc`}
                              value="etc"
                              {...register(subject, { required: true })}
                              onClick={() => setFocus(`${subject}_etc_text`)}
                            />
                          </CustomCheckbox>
                          <SelectionLabel htmlFor={`${subject}_etc`} label="기타 :" />
                          <FormTextInput
                            type="text"
                            id={`${subject}_etc_text`}
                            onClick={() => handleClickEtcInput(subject)}
                            {...register(`${subject}_etc_text`)}
                          />
                        </li>
                      )}
                    </SelectionList>
                  ) : (
                    storeObj[subject].map((item: string) => (
                      <div key={item}>
                        <CustomCheckbox htmlFor={subject}>
                          <input type="checkbox" id={subject} checked readOnly />
                        </CustomCheckbox>
                        <SelectionLabel htmlFor={subject} label={item} />
                      </div>
                    ))
                  ))}

                {type === 'text' && (
                  <div>
                    <SelectionLabel htmlFor={subject} label="내 답변 :" />
                    <FormTextInput type="text" id={subject} {...register(subject, { required: true })} />
                  </div>
                )}

                {type === 'address' &&
                  (clickedSubject.includes('address') ? (
                    <AddressContainer>
                      <div className="address_name">
                        <p>{store.address_name}</p>
                      </div>
                      <DetailInput autoFocus type="text" {...register('detail_address', { required: true })} />
                      <div className="info">
                        <span>•</span>
                        <p>상세 주소만 변경 가능합니다.</p>
                      </div>
                      <div className="info">
                        <span>•</span>
                        <p>
                          건물 주소의 변경이 필요하면{' '}
                          <a href="https://brwnie.channel.io/" target="_blank" rel="noreferrer">
                            1:1 채팅 문의
                          </a>{' '}
                          바랍니다.
                        </p>
                      </div>
                    </AddressContainer>
                  ) : (
                    <SelectionLabel htmlFor={subject} label={answer} />
                  ))}
              </li>
            )
          })}
        </QuestionList>
      </Container>

      <ButtonContainer>
        <Button width={160} buttonTheme="secondary" onClick={() => history.goBack()} type="button">
          취소
        </Button>
        <Button buttonTheme={checkEtcValid() && isValid && isDirty ? 'primary' : 'disabled'} width={160} type="submit">
          변경 저장
        </Button>
      </ButtonContainer>
      <FooterButtonBox>
        <Button width={120} buttonTheme="secondary" onClick={() => history.goBack()} type="button">
          취소
        </Button>
        <Button buttonTheme={checkEtcValid() && isValid && isDirty ? 'primary' : 'disabled'} width={120} type="submit">
          변경 저장
        </Button>
      </FooterButtonBox>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.section`
  width: 464px;
  border-radius: 16px;
  margin-bottom: 32px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};

  header {
    width: 464px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: ${({ theme }) => theme.colors.main};
    color: white;
    padding: 32px;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    p {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-top: 8px;
    }

    // mobile
    ${({ theme }) => theme.media.mobile`
      width: ${theme.width.mobile}
    `}
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    width: ${theme.width.mobile}
  `}
`

const QuestionList = styled.ul`
  height: 888px;
  width: 464px;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray};
    border-radius: 2px;
  }

  /* 사파리에서 스크롤처러 */
  -webkit-overflow-scrolling: auto;

  & > li {
    padding: 32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};

    &.no_arrow > p {
      cursor: default;
    }

    & > p {
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 16px;
      word-break: keep-all;

      svg {
        min-width: 24px;
      }
    }

    & > div {
      display: flex;
      align-items: center;
    }

    & > div ~ div {
      margin-top: 8px;
    }
  }

  & > li:last-child {
    border-bottom: none;
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    height: 440px;

        &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
      }

    & > li {
      padding: 16px;

      & > p {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }

    width: ${theme.width.mobile}
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  button ~ button {
    margin-left: 8px;
  }

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`

const SelectionList = styled.ul`
  & > li {
    display: flex;
    align-items: center;
    height: 24px;
  }

  & > li ~ li {
    margin-top: 16px;
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    & > li ~ li {
      margin-top: 8px;
    }
  `}
`

const StyledIcon = styled(Icon)`
  transform: rotate(180deg);
`

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .address_name {
    width: 304px;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.gray};

    p {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.black};
    }
  }

  & > .info {
    display: flex;
    width: 304px;
    justify-content: flex-start;
    margin-top: 8px;

    p,
    a {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.warning};
      word-break: keep-all;
    }

    a {
      font-weight: 700;
      white-space: pre;
    }

    span {
      color: ${({ theme }) => theme.colors.warning};
      padding: 4px;
      display: inline-block;
    }
  }

  .info ~ .info {
    margin-top: 0;
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    & > .address_name, & > .info {
      width: 280px;
    }
  `}
`
