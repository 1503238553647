import ConfirmModal from 'components/common/ConfirmModal'
import SmallButton from 'components/common/SmallButton'
import usePostSendEmailForResetEmail from 'hooks/query/authVerify/usePostSendEmailForResetEmail'
import useClickAwayListener from 'hooks/useClickAwayListener'
import { useRef, useState } from 'react'
import styled from 'styled-components'

export type EmailChangeInputProps = {
  onOutsideClick: () => void
}

export default function EmailChangeInput({ onOutsideClick }: EmailChangeInputProps) {
  const ref = useRef<HTMLLIElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [sendSuccessOpen, setSendSuccessOpen] = useState<boolean>(false)

  useClickAwayListener({ ref, onOutsideClick })

  const { mutateAsync: mutateSendEmail, isError, error } = usePostSendEmailForResetEmail()

  const handleClickAuthentication = async () => {
    if (!inputRef.current || inputRef.current.value === '') return
    await mutateSendEmail({ email: inputRef.current.value })
    setSendSuccessOpen(true)
  }

  return (
    <>
      <StyledInputContainer ref={ref}>
        <input
          ref={inputRef}
          type="email"
          autoFocus
          placeholder="이메일 주소 입력"
          onKeyDown={(e) => (e.key === 'Enter' ? handleClickAuthentication() : undefined)}
        />
        <SmallButton buttonTheme="green" onClick={handleClickAuthentication}>
          인증
        </SmallButton>
      </StyledInputContainer>
      {isError && <Warning>{error.response.data.message}</Warning>}

      <ConfirmModal
        open={sendSuccessOpen}
        title="인증 메일 발송"
        onConfirm={() => setSendSuccessOpen(false)}
        onSecondary={handleClickAuthentication}
        onClose={() => setSendSuccessOpen(false)}
        confirmText="확인"
        closeText="재전송"
      >
        <SuccessDesc>
          발송된 메일의 인증 버튼은 <span>2시간</span> 동안 유효합니다.
          <br /> 메일이 도착하지 않은 경우 스팸함을 확인해 주세요.
        </SuccessDesc>
      </ConfirmModal>
    </>
  )
}

const StyledInputContainer = styled.li`
  width: 320px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};

  input {
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    padding: 0;
    padding-right: 8px;
    border: none;
  }

  input::placeholder {
    opacity: 0.5;
    color: ${({ theme }) => theme.colors.black};
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    width: 280px;
  `}
`

const Warning = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning};
  line-height: 24px;
  margin-top: 8px;
`

const SuccessDesc = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  line-height: 24px;

  span {
    color: ${({ theme }) => theme.colors.blue};
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    br {
      display: none;
    }
  `}
`
