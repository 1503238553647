export const bankOptions = [
  { value: '04', label: 'KB국민' },
  { value: '03', label: 'IBK기업' },
  { value: '11', label: 'NH농협' },
  { value: '88', label: '신한' },
  { value: '53', label: '씨티' },
  { value: '23', label: 'SC제일' },
  { value: '20', label: '우리' },
  { value: '90', label: '카카오뱅크' },
  { value: '89', label: '케이뱅크' },
  { value: '81', label: '하나' },
  { value: '39', label: '경남' },
  { value: '34', label: '광주' },
  { value: '31', label: '대구' },
  { value: '32', label: '부산' },
  { value: '02', label: 'KDB산업' },
  { value: '07', label: '수협' },
  { value: '71', label: '우체국' },
  { value: '37', label: '전북' },
  { value: '35', label: '제주' },
  { value: '45', label: '새마을금고' },
  { value: '48', label: '신협' },
]
