import { ServiceType } from 'lib/api/service/types'
import styled from 'styled-components'
import ServiceBadge from '../ServiceBadge/ServiceBadge'
import useBvsQuery from 'hooks/query/bvs/useBvsQuery'
import buildStoreSummary from 'utils/common/buildStoreSummary'
import twoDigitsNumConverter from 'utils/common/twoDigitsNumConverter'
import LogManager from 'lib/utils/logger'
import { useHistory } from 'react-router-dom'
import { getServiceBadgeType } from 'utils/common/getServiceBadgeType'
import useAuth from 'hooks/query/auth/useAuth'
import usePaymentQuery from 'hooks/query/payment/usePaymentQuery'

interface StoreItemProp {
  service: ServiceType
}
export default function ServiceItem({ service }: StoreItemProp) {
  const { bvs_id, payment_id, paid_at, is_regular_payment, id } = service

  const history = useHistory()

  const { auth } = useAuth()
  const { data: bvs } = useBvsQuery({ id: bvs_id })
  const { data: payment } = usePaymentQuery(
    {
      userId: auth?.id as number,
      id: service?.payment_id as number,
    },
    { enabled: !!auth && !!service }
  )

  if (!bvs) return null

  const serviceBadgeType = getServiceBadgeType({
    service,
    payment,
    startDate: bvs.reservation_dates[0],
    endDate: service.end_date,
  })

  const firstVisitDate = bvs.reservation_dates[0]
  const lastVisitDate = bvs.reservation_dates[bvs.reservation_dates.length - 1]

  const FirstVisitDateClass = new Date(firstVisitDate)
  const periodFirstYear = FirstVisitDateClass.getFullYear()
  const periodFirstMonth = FirstVisitDateClass.getMonth() + 1
  const periodFirstDate = FirstVisitDateClass.getDate()

  const LastVisitDateClass = new Date(lastVisitDate)
  const periodLastYear = LastVisitDateClass.getFullYear()
  const periodLastMonth = LastVisitDateClass.getMonth() + 1
  const periodLastDate = LastVisitDateClass.getDate()

  const goDetail = () => {
    const linkTo = !payment_id || (payment_id && !paid_at) ? `/service-payment/${id}` : `/service-complete/${id}`
    if (!payment_id) {
      LogManager.Instance.sendLog('store_service_payment_continue', {
        btn_name: '결제 & 계약',
        btn_url: `/service-payment/${id}`,
      })
    }

    history.push(linkTo)
  }

  return (
    <Container>
      <ServiceInfoWrap>
        <BadgeWrap>
          <ServiceBadge badgeType={serviceBadgeType} />
          {is_regular_payment && <ServiceBadge badgeType="SUBSCRIBE" />}
        </BadgeWrap>
        <Title>
          {service.is_regular_payment && service.payment_id && !!service.regular_payment_round_n && (
            <SubscribeCount>{service.regular_payment_round_n}회차</SubscribeCount>
          )}
          {buildStoreSummary(service)} {service.service_time_minute && `(${service.service_time_minute}분)`}
        </Title>
        <UsingPeriod>
          {periodFirstYear}.{twoDigitsNumConverter(periodFirstMonth)}.{twoDigitsNumConverter(periodFirstDate)} ~{' '}
          {periodLastYear}.{twoDigitsNumConverter(periodLastMonth)}.{twoDigitsNumConverter(periodLastDate)}
        </UsingPeriod>
      </ServiceInfoWrap>
      <ButtonWrap>
        <DetailButton onClick={goDetail}>자세히 보기</DetailButton>
      </ButtonWrap>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px;

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
    align-items: flex-start;
  `}
`

const BadgeWrap = styled.div`
  display: flex;
  margin-bottom: 8px;
  & > * {
    margin-right: 8px;
  }
`

const ServiceInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 12px;
  margin-bottom: 8px;
`

const Title = styled.h4`
  width: max-content;
  font-weight: 500;
  font-size: 16px;
  margin-top: 8px;
  color: #1e1e2c;
`

const SubscribeCount = styled.span`
  color: var(--navy-navy-300, #3d5afe);
  font-weight: 700;
  margin-right: 4px;
`

const UsingPeriod = styled.h5`
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  width: max-content;
`

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button {
    width: 122px;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border-radius: 12px;

    font-weight: 500;
    font-size: 16px;
    line-height: 40px;

    cursor: pointer;

    margin-top: 8px;
  }

  ${({ theme }) => theme.media.mobile`
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      & > button {
        width: 100%;
        padding: 12px 24px;
      }
  `}
`

const PaymentButton = styled.button`
  background: #003399;
  color: #ffffff;
`
const DetailButton = styled.button`
  background: #f5f5f5;
  color: #212121;
`
