export type MapStoreDataType = {
  category: string
  enCategory:
    | 'printCafe'
    | 'selfLaundry'
    | 'icecream'
    | 'studyCafe'
    | 'convenienceStore'
    | 'selfStudio'
    | 'cafe'
    | 'machineShop'
    | 'stationery'
    | 'ramen'
    | 'fruit'
    | 'pet'
    | 'mealKit'
    | 'etc'
}

export const mapStoreData: MapStoreDataType[] = [
  {
    category: '프린트카페',
    enCategory: 'printCafe',
  },
  {
    category: '셀프빨래방',
    enCategory: 'selfLaundry',
  },
  {
    category: '아이스크림할인점',
    enCategory: 'icecream',
  },
  {
    category: '스터디카페',
    enCategory: 'studyCafe',
  },
  {
    category: '무인편의점',
    enCategory: 'convenienceStore',
  },
  {
    category: '셀프스튜디오',
    enCategory: 'selfStudio',
  },
  {
    category: '무인카페',
    enCategory: 'cafe',
  },
  {
    category: '스마트자판기',
    enCategory: 'machineShop',
  },
  {
    category: '무인문방구',
    enCategory: 'stationery',
  },
  {
    category: '무인라면',
    enCategory: 'ramen',
  },
  {
    category: '무인과일',
    enCategory: 'fruit',
  },
  {
    category: '무인펫샵',
    enCategory: 'pet',
  },
  {
    category: '밀키트',
    enCategory: 'mealKit',
  },
  {
    category: '기타',
    enCategory: 'etc',
  },
]

export const getStoreTypeImageUrl = (category: string) => {
  switch (category) {
    case '아이스크림할인점':
      return '/image/service_type_header/0.webp'
    case '셀프빨래방':
      return '/image/service_type_header/1.webp'
    case '스마트자판기':
      return '/image/service_type_header/2.webp'
    case '무인편의점':
      return '/image/service_type_header/3.webp'
    case '무인카페':
      return '/image/service_type_header/4.webp'
    case '스터디카페':
      return '/image/service_type_header/5.webp'
    case '프린트카페':
      return '/image/service_type_header/6.webp'
    case '셀프스튜디오':
      return '/image/service_type_header/7.webp'
    case '밀키트':
      return '/image/meal_kit.webp'
    case '무인문방구':
    case '무인라면':
    case '무인과일':
    case '무인펫샵':
    case '기타':
      return '/image/brwnieApplyPc.webp'
    default:
      return 'brwnie-marker'
  }
}
