import client from '../client'
import { StoreType } from './types'

export async function patchStore({ id, ...req }: PatchStoreParam & PatchStoreReq) {
  const response = await client.patch<StoreType>(`/service-api/v1/store/${id}`, req)

  return response.data
}

export type PatchStoreParam = {
  id: string
}

export type PatchStoreReq = {
  area?: string
  big_machine_count?: string
  big_machine_kind?: string[]
  chair_count?: string
  cleaning_tool?: string[]
  address_name?: string
  detail_address?: string
  region_1depth_name?: string
  region_2depth_name?: string
  region_3depth_h_name?: string
  region_3depth_name?: string
  refill_kind?: string[]
  room_count?: string
  shelf_count?: string[]
  small_machine_count?: string
  small_machine_kind?: string[]
  store_name?: string
  table_count?: string
  toilet?: string
  trash_can_count?: string
  type?: string
}
