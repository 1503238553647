import styled from 'styled-components'
import Header from 'components/common/Header'
import StepForm from 'components/common/StepForm'
import React, { useEffect, useRef, useState } from 'react'
import storeQuestionData from '../../data/storeQuestionData.json'
import CustomRadioButton from 'components/common/CustomRadioButton'
import CustomCheckbox from 'components/common/CustomCheckbox'
import Footer from 'components/common/Footer'
import { useForm } from 'react-hook-form'
import SelectionLabel from 'components/common/SelectionLabel'
import FormTextInput from 'components/common/FormTextInput'
import usePostStore from 'hooks/query/store/usePostStore'
import { useHistory, useLocation } from 'react-router'
import useStoresQuery from 'hooks/query/store/useStoresQuery'
import { useScrollGet } from 'hooks/useScrollGet'
import AddressSearch from 'components/common/AddressSearch'
import { Address } from 'react-daum-postcode'
import { ROUTES } from 'utils/common/routes'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import useAuth from 'hooks/query/auth/useAuth'
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'
import { StoreType } from 'lib/api/store/types'

import availableSeoulGu from 'data/availableSeoulGu.json'
import availableOtherGu from 'data/availableOtherGu.json'
import { useDispatch } from 'react-redux'
import { initServiceState } from 'modules/features/service/serviceSlice'

const ETC_VALUE = 'etc'

const combineEtc = (array: string[], etc: string) => {
  return etc !== '' ? (Array.isArray(array) ? [...array.filter((item) => item !== ETC_VALUE), etc] : [etc]) : array
}

export default function StorePostPage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const mainRef = useRef<HTMLDivElement>(null)

  const { data: stores } = useStoresQuery()
  const { auth } = useAuth()
  const { totalOffsetY } = useScrollGet({})
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [maxStep, setMaxStep] = useState<number>(1)

  const [store, setStore] = useState<StoreType>()
  const [openStorePostSuccessModal, setOpenStorePostSuccessModal] = useState<boolean>(false)
  const [outOfServiceModal, setOutOfServiceModal] = useState<boolean>(false)

  const { question, selection, type, etc, subject } = storeQuestionData[currentStep - 1]

  const {
    register,
    setFocus,
    handleSubmit,
    formState: { isValid, isSubmitting },
    setValue,
    getValues,
    watch,
  } = useForm({ mode: 'all' })

  const { mutateAsync: mutatePostStore } = usePostStore()

  const ETC_ID = `${subject}_etc`

  const isSelectEtc = !!watch(subject) && watch(subject).includes(ETC_VALUE)

  useEffect(() => {
    LogManager.Instance.sendLog(
      'store_post',
      {
        enter_url: document.referrer,
        user_id: auth?.id.toString() || 'NULL',
      },
      LOGGER.GTM
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentStep > maxStep) setMaxStep(currentStep)
  }, [currentStep, setMaxStep, maxStep])

  useEffect(() => {
    if (location.pathname.includes('local')) {
      const storeData = JSON.parse(localStorage.getItem('store_deokwon') || '{}')

      for (const key in storeData) {
        setValue(key, storeData[key])
      }

      setCurrentStep(storeData.questionNo)
    }
  }, [location.pathname, setValue])

  const onSubmit = async (data: {
    search_address: Address
    detail_address: string
    area: string
    big_machine_kind: string[]
    big_machine_kind_etc: string
    store_name: string
    type: string
    type_etc: string
  }) => {
    if (isSubmitting) return

    const { area, big_machine_kind, big_machine_kind_etc, search_address, detail_address, store_name, type, type_etc } =
      data

    const addressParts = search_address.address.split(search_address.sigungu)
    const addressAfterSigungu = addressParts[1]

    // 시군구 뒤에 나오는 (도로명/지번) 주소 + 사용자가 입력한 주소
    const custom_detail_address = addressAfterSigungu.trim() + ' ' + detail_address

    const address_name = search_address.sido + ' ' + search_address.sigungu

    const storeData = await mutatePostStore({
      area,
      big_machine_kind: combineEtc(big_machine_kind, big_machine_kind_etc),
      address_name,
      detail_address: custom_detail_address,
      sido: search_address.sido,
      sigungu: search_address.sigungu,
      store_name,
      type: type_etc || type,
    })

    // setStore(storeData)
    setOpenStorePostSuccessModal(true)

    localStorage.removeItem('store_deokwon')

    // history.push({
    //   pathname: ROUTES.STORE,
    //   state: {
    //     isFirst: stores?.length === 0,
    //   },
    // })
    LogManager.Instance.sendLog(
      'store_post_complete',
      {
        user_id: auth ? auth.id.toString() : 'NULL',
        store_type: type_etc || type,
        store_size: area,
        store_machine: combineEtc(big_machine_kind, big_machine_kind_etc).join(','),
      },
      LOGGER.GTM
    )
  }

  const handleStorePostModalClose = () => {
    setOpenStorePostSuccessModal(false)
    goStore()
  }

  const handleStorePostModalConfirm = () => {
    if (!store) {
      return goStore()
    }

    const { sigungu, sido } = store

    if (sigungu === null) return setOutOfServiceModal(true)

    if (
      (sido === '인천' && sigungu === '중구') ||
      (availableSeoulGu.filter(({ name }) => name === sigungu).length === 0 &&
        availableOtherGu.filter(({ name }) => sigungu.includes(name)).length === 0)
    ) {
      if (sido !== '부산')
        return setOutOfServiceModal(true)
    }

    dispatch(initServiceState())
    goServiceApply()
  }

  const goStore = () => {
    history.push({
      pathname: ROUTES.STORE,
      state: {
        isFirst: stores?.length === 0,
      },
    })
  }

  const goServiceApply = () => {
    if (!store) {
      return goStore()
    }

    const { id } = store
    LogManager.Instance.sendLog(
      'store_service_post',
      {
        page_url: location.pathname,
        btn_name: '서비스 신청',
        btn_url: `/service-post/${id}/basic`,
      },
      LOGGER.GTM
    )
    history.push(`/service-post/${id}/basic`)
  }

  const delayNextStep = (data: string) => {
    const timeout = setTimeout(() => {
      const storeData = JSON.parse(localStorage.getItem('store_deokwon') || '{}')

      localStorage.setItem(
        'store_deokwon',
        JSON.stringify({
          ...storeData,
          [subject]: data,
          questionNo: currentStep + 1,
        })
      )

      setCurrentStep(currentStep + 1)
    }, 500)

    return () => clearTimeout(timeout)
  }

  const handleClickEtcInput = () => {
    const valueOfSubject = getValues(subject)

    if (typeof valueOfSubject === 'undefined') return

    if (Array.isArray(valueOfSubject)) {
      setValue(subject, [...valueOfSubject, ETC_VALUE])
    } else {
      setValue(subject, ETC_VALUE)
    }
  }

  const handleCheckBoxClick = (e: React.MouseEvent<HTMLInputElement, globalThis.MouseEvent>, subject: string) => {
    const checkBoxValues = getValues(subject)
    const currentValue = e.currentTarget.value

    if (!!checkBoxValues) {
      if (currentValue === '없음' && checkBoxValues.filter((value: string) => value !== '없음').length !== 0) {
        setValue(subject, ['없음'])
      } else if (currentValue !== '없음' && checkBoxValues.includes('없음')) {
        setValue(subject, [currentValue])
      } else if (subject === 'shelf_count') {
        if (
          currentValue.includes('일자') &&
          checkBoxValues.find((value: string) => value.includes('일자') && value !== currentValue)
        ) {
          setValue(subject, [...checkBoxValues.filter((value: string) => !value.includes('일자')), currentValue])
        } else if (
          currentValue.includes('2단 이상 매대') &&
          checkBoxValues.find((value: string) => value.includes('2단 이상 매대') && value !== currentValue)
        ) {
          setValue(subject, [
            ...checkBoxValues.filter((value: string) => !value.includes('2단 이상 매대')),
            currentValue,
          ])
        }
      }
    }
  }

  const handleNextClick = () => {
    const storeData = JSON.parse(localStorage.getItem('store_deokwon') || '{}')
    const values = getValues(subject)
    const etcValue = getValues(ETC_ID)

    if (currentStep === 1) {
      const searchAddress = getValues('search_address')
      const detailAddress = getValues('detail_address')

      localStorage.setItem(
        'store_deokwon',
        JSON.stringify({
          ...storeData,
          search_address: searchAddress,
          detail_address: detailAddress,
          questionNo: currentStep + 1,
        })
      )
    } else if (currentStep === 2 && etcValue) {
      localStorage.setItem(
        'store_deokwon',
        JSON.stringify({
          ...storeData,
          [subject]: 'etc',
          [`${subject}_etc`]: etcValue,
          questionNo: currentStep + 1,
        })
      )
    } else {
      localStorage.setItem(
        'store_deokwon',
        JSON.stringify({
          ...storeData,
          [subject]: values,
          [`${subject}_etc`]: etcValue,
          questionNo: currentStep + 1,
        })
      )
    }
  }

  return (
    <>
      <Header title="매장 추가" offsetY={totalOffsetY} />
      <StyledMain ref={mainRef}>
        <StepForm
          onSubmit={handleSubmit(onSubmit)}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalLength={storeQuestionData.length}
          question={question}
          submitText="추가 완료"
          isValid={isSelectEtc ? watch(ETC_ID) !== '' : isValid}
          isSubmitLoading={isSubmitting}
          needNextButton={isSelectEtc || maxStep > currentStep || type !== 'radio'}
          onNextClick={handleNextClick}
        >
          {(type === 'radio' || type === 'check') && (
            <QuestionList>
              {/* 현재 질문과 다음 질문의 첫 선택지가 같을 경우 첫 선택지를 골랐을 때 다음 질문의 첫 선택지가 골라지는 버그를 방지 */}
              {type === 'radio' && (
                <li>
                  <input type="radio" style={{ display: 'none' }} {...register(subject)} />
                </li>
              )}
              {selection?.map((item) => (
                <li key={item} className="question_list_item">
                  {type === 'radio' ? (
                    <CustomRadioButton htmlFor={`${subject}_${item}`} animationOn={true}>
                      <input
                        type="radio"
                        id={`${subject}_${item}`}
                        value={item}
                        onClick={() => delayNextStep(item)}
                        {...register(subject, { required: true })}
                      />
                    </CustomRadioButton>
                  ) : (
                    <CustomCheckbox htmlFor={`${subject}_${item}`}>
                      <input
                        type="checkbox"
                        id={`${subject}_${item}`}
                        value={item}
                        onClick={(e) => handleCheckBoxClick(e, subject)}
                        {...register(subject, { required: true })}
                      />
                    </CustomCheckbox>
                  )}
                  <SelectionLabel htmlFor={`${subject}_${item}`} label={item} />
                </li>
              ))}
              {etc && (
                <li className="question_list_item">
                  {type === 'radio' ? (
                    <CustomRadioButton htmlFor={ETC_VALUE} animationOn={true}>
                      <input
                        type="radio"
                        id={ETC_VALUE}
                        value={ETC_VALUE}
                        {...register(subject, { required: true })}
                        onClick={() => setFocus(ETC_ID)}
                      />
                    </CustomRadioButton>
                  ) : (
                    <CustomCheckbox>
                      <input
                        type="checkbox"
                        id={ETC_VALUE}
                        value={ETC_VALUE}
                        {...register(subject, { required: true })}
                        onClick={() => setFocus(ETC_ID)}
                      />
                    </CustomCheckbox>
                  )}
                  <SelectionLabel htmlFor={ETC_VALUE} label="기타 :" />
                  <FormTextInput type="text" id={ETC_ID} onClick={handleClickEtcInput} {...register(ETC_ID)} />
                </li>
              )}
            </QuestionList>
          )}

          {type === 'text' && (
            <TextInputContainer>
              <SelectionLabel htmlFor="store_name" label="내 답변 :" />
              <FormTextInput type="text" id="store_name" {...register('store_name', { required: true })} />
            </TextInputContainer>
          )}

          {/* {type === 'address' && <AddressSelector register={register} setValue={setValue} watch={watch} />} */}
          {type === 'address' && <AddressSearch register={register} setValue={setValue} getValues={getValues} />}
        </StepForm>
      </StyledMain>
      <ConfirmModal
        open={openStorePostSuccessModal}
        title="매장 추가를 완료했어요!"
        subline="이제 브라우니 서비스를 신청할 수 있어요!"
        closeText="다음에"
        confirmText="서비스 신청"
        onConfirm={handleStorePostModalConfirm}
        onClose={handleStorePostModalClose}
      />
      <ConfirmModal
        open={outOfServiceModal}
        title="서비스 지역 안내"
        headerColor="warning"
        headline="서울, 인천(강화 및 중구 일부 제외), 성남, 수원 외 다른 지역의 서비스 신청은 문의 주세요."
        confirmText="1:1 문의하기"
        closeText="취소"
        onClose={() => setOutOfServiceModal(false)}
        onConfirm={() => {
          window.open('https://brwnie.channel.io/')
        }}
      />
      <Footer />
    </>
  )
}

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 266px;

  @media only screen and (max-width: 768px) {
    padding-top: 96px;
    position: relative;
    background-color: white;
    z-index: 501;
  }
`

const QuestionList = styled.ul`
  width: 100%;

  li.question_list_item {
    display: flex;
    align-items: center;
    height: 28px;
  }

  li.question_list_item ~ li {
    margin-top: 16px;
  }

  @media only screen and (max-width: 768px) {
    li.question_list_item {
      height: 24px;
    }
  }
`

const TextInputContainer = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-left: 0;
  }
`
