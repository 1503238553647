import styled from 'styled-components'
import Icon from 'components/Icon'
import { IconType } from 'components/Icon/Icon'

export type InfoBoxProps = {
  width?: number
  iconName?: IconType
  title: string
  children: React.ReactNode
  rightIconName?: IconType
  onRightIconClick?: () => void
}

export default function InfoBox({ width, iconName, title, children, rightIconName, onRightIconClick }: InfoBoxProps) {
  return (
    <StyledInfoBox width={width}>
      <header>
        {iconName && <Icon className="left__icon" name={iconName} />}
        <h3>{title}</h3>
        {rightIconName && <Icon className="right__icon" name={rightIconName} onClick={onRightIconClick} />}
      </header>
      {children}
    </StyledInfoBox>
  )
}

const StyledInfoBox = styled.article<{ width?: number }>`
  width: ${({ width }) => (width ? width : 384)}px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};

  & > header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;

    .left__icon {
      margin-right: 8px;
      max-width: 32px;
      max-height: 32px;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      color: ${({ theme }) => theme.colors.black};
    }

    .right__icon {
      position: absolute;
      right: 24px;
      top: calc(50% - 16px);
      cursor: pointer;
      z-index: 100;
    }
  }
  /* mobile */
  ${({ theme }) => theme.media.mobile`
    & > header {
      height: 64px;

      .right__icon {
        width: 24px;
        height: 24px;
        top: calc(50% - 12px);
      }
    }

    width: ${theme.width.mobile}
  `}
`
