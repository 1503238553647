import Icon from 'components/Icon'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ROUTES } from 'utils/common/routes'
import LogManager from 'lib/utils/logger'
import { memo } from 'react'

export default function Footer() {
  const location = useLocation()

  return (
    <>
      <MobileFooter hidden={!(location.pathname === '/' || location.pathname.includes('/service-detail'))}>
        <CompanyName>(주)하이어엑스</CompanyName>
        <br />
        <InfoContainer>
          <Info>
            사업자 등록번호 : 704-88-01152 &nbsp;|&nbsp; 대표이사 : 권민재
            <br />
            통신판매번호 : 2022-서울강남-06707
            <br />
            주소 : 서울 강남구 테헤란로55길 17,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;어반9 빌딩 5층 하이어엑스
          </Info>
          <br />
          <br />
          <br />
          <br />
          <Info>
            대표문의&nbsp;&nbsp; : support@higherx.co.kr
            <br />
            제휴/제안 : partnership@higherx.co.kr
            <br />
            전화문의&nbsp;&nbsp; : 070-7122-0913 (10:00 ~ 18:00)
          </Info>
        </InfoContainer>
        <InfoContainer>
          <br />
          <Policy />
          <Info>
            <br />
            <br />
            COPYRIGHT © HIGHERX. Proudly created
          </Info>
        </InfoContainer>
        <SnsIcons />
      </MobileFooter>
      {/* ---------------------------------------------------------------------- */}
      <StyledFooter>
        <TopContainer>
          <CompanyName>(주)하이어엑스</CompanyName>
          <SnsIcons />
        </TopContainer>
        <InfoContainer>
          <Info>
            사업자 등록번호 : 704-88-01152 &nbsp;|&nbsp; 대표이사 : 권민재
            <br />
            통신판매번호 : 2022-서울강남-06707
            <br />
            주소 : 서울 강남구 테헤란로55길 17, 어반9 5층
            <br />
          </Info>
          <Info style={{ marginBottom: 24 }}>
            대표문의&nbsp;&nbsp; : support@higherx.co.kr
            <br />
            제휴/제안 : partnership@higherx.co.kr
            <br />
            전화문의&nbsp;&nbsp; : 070-7122-0913(10:00 ~ 18:00)
          </Info>
        </InfoContainer>

        <InfoContainer>
          <Info>COPYRIGHT © HIGHERX. Proudly created</Info>
          <Policy />
        </InfoContainer>
      </StyledFooter>
    </>
  )
}

function SnsIcons() {
  const onClickHandlerSns = (type: 'kakao' | 'blog' | 'insta' | 'facebook') => {
    if (type === 'kakao') {
      window.open('http://pf.kakao.com/_aDlss', '_blank')
    } else if (type === 'blog') {
      window.open(
        'https://blog.naver.com/PostList.naver?blogId=higherx&from=postList&categoryNo=6&parentCategoryNo=6',
        '_blank'
      )
    } else if (type === 'insta') {
      window.open('https://www.instagram.com/brwniebrwnie/', '_blank')
    } else if (type === 'facebook') {
      window.open('https://www.facebook.com/brwniebrwnie', '_blank')
    }
    LogManager.Instance.sendLog('footer', {
      menu_name: type,
    })
  }
  return (
    <SnsWrapper>
      <SnsIcon name="whiteKakao" onClick={() => onClickHandlerSns('kakao')} />
      <SnsIcon name="whiteBlog" onClick={() => onClickHandlerSns('blog')} />
      <SnsIcon name="whiteFacebook" onClick={() => onClickHandlerSns('facebook')} />
      <SnsIcon name="whiteInsta" onClick={() => onClickHandlerSns('insta')} />
    </SnsWrapper>
  )
}

export const MemoizedSnsIcons = memo(SnsIcons)

const Policy: React.FC<any> = () => {
  return (
    <PolicyWrapper>
      <a
        href="https://higherx.notion.site/2021-06-10-0d9e5fc809104aef97a8679bf21510f8"
        target="_blank"
        rel="noreferrer"
      >
        개인정보처리방침
      </a>
      <a
        href="https://higherx.notion.site/2021-06-10-42f4b3c848ee4182978736e1aace21fd"
        target="_blank"
        rel="noreferrer"
      >
        서비스 이용약관
      </a>
      <Link to={ROUTES.FAQ}>FAQ</Link>
    </PolicyWrapper>
  )
}

const StyledFooter = styled.footer`
  width: 100%;
  height: 26.4rem;
  padding: ${({ theme }) => theme.spacing(6)};
  color: white;
  background-color: ${({ theme }) => theme.colors.darkGrayishBlue};
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  ${({ theme }) => theme.media.mobile`
  display: none;
  `}
`

const CompanyName = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;

  ${({ theme }) => theme.media.mobile`
  font-size: 1.4rem;
  padding-bottom: 2.4rem;
  `}
`

const InfoContainer = styled.div`
  display: flex;
  /* align-items: flex-end; */

  & ~ & {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }

  ${({ theme }) => theme.media.mobile`
  display: block;
  `}
`

const Info = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;

  & ~ & {
    margin-left: ${({ theme }) => theme.spacing(10)};

    ${({ theme }) => theme.media.mobile`
  margin-left: 0rem;
  `}
  }

  ${({ theme }) => theme.media.mobile`
    font-size: 1.2rem;
    line-height: 1.8rem;
  `}
`

const SnsWrapper = styled.div`
  float: right;
  display: flex;
  flex-direction: row;

  & > svg:nth-child(-n + 3) {
    margin-right: 3.2rem;
  }

  ${({ theme }) => theme.media.mobile`
  margin-top: 2.4rem;

    & > svg:nth-child(-n + 3) {
    margin-right: 1.6rem;
  }
  `}
`
const SnsIcon = styled(Icon)`
  cursor: pointer;
`

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const PolicyWrapper = styled.div`
  margin-left: 185px;
  & > a {
    height: 2.4rem;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.white};
    font-size: 1.6rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    ${({ theme }) => theme.media.mobile`font-size: 1.2rem;`}
  }
  a ~ a {
    margin-left: ${({ theme }) => theme.spacing(3)};
  }
  & > a:first-child {
    width: 9.2rem;
  }
  & > a:last-child {
    width: 11.1rem;
  }
  ${({ theme }) => theme.media.mobile`
   margin-left: 0rem;
   `}
`
const MobileFooter = styled.footer`
  display: none;
  width: 100%;
  height: 26.4rem;
  padding: 64px 24px;
  color: white;
  background-color: ${({ theme }) => theme.colors.darkGrayishBlue};
  flex-direction: column;
  position: relative;
  z-index: 498;

  ${({ theme }) => theme.media.mobile`
  height: 550px;
  display: flex;
  `}
`
