import client from '../client'
import { PaymentType } from './types'

export async function getPayment({ id }: GetPaymentParam) {
  const response = await client.get<PaymentType>(`/payment-api/v1/payment/${id}`)

  return response.data
}

// paymentId 혹은 orderId
export type GetPaymentParam = {
  id: string | number
}
