import { Button } from 'ohds-web'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { ROUTES } from 'utils/common/routes'
import LogManager from 'lib/utils/logger'
import useAuth from 'hooks/query/auth/useAuth'
import Switcher from '../LandingServiceFee/Switcher'
import { useState } from 'react'
import ServiceFeeTextWithSlider from '../ServiceFeeTextWithSlider'
import { UTM_URL } from 'utils/common/utmUrl'

const oneWeekWeeklyArr = [2, 3, 4, 5]
const fourWeekWeeklyArr = [1, 2, 3, 4, 5, 6, 7]

export default function LandingServiceFeeSection() {
  // 기본: 4주 5회
  const [totalWeekCount, setTotalWeekCount] = useState<1 | 4>(4)
  // slider와 함께 사용하기 때문에 실제 주 횟수는 +1이 필요
  const [currentWeek, setCurrentWeek] = useState<number>(4)

  const history = useHistory()

  const { isLogin } = useAuth()
  const { pathname } = useLocation()

  const totalServiceCountForOneWeek = totalWeekCount * (currentWeek === 0 ? 1 : currentWeek + 1) + 1
  const totalServiceCount = totalWeekCount * (currentWeek === 0 ? 1 : currentWeek + 1)

  const handleApplyClick = () => {
    const route = isLogin
      ? ROUTES.STORE + UTM_URL.LANDING_SERVICE_FEE_LOGIN
      : ROUTES.AUTH_LOGIN + UTM_URL.LANDING_SERVICE_FEE_LOGOUT

    history.push(route)

    if (pathname === '/') {
      LogManager.Instance.sendLog('main_service', {
        page_url: pathname,
        btn_url: route,
      })
    } else {
      LogManager.Instance.sendLog('main_test_service', {
        btn_url: route,
      })
    }
  }

  const handleToggleStatusChange = () => {
    setTotalWeekCount((prev) => (prev === 1 ? 4 : 1))
    setCurrentWeek(0)
  }

  const handleWeekChange = (index: any) => {
    setCurrentWeek(index)
  }

  return (
    <LandingServiceFeeSectionWrapper>
      <h1>브라우니 이용료</h1>

      <FeeWrapper>
        <Switcher status={totalWeekCount === 4} setStatus={handleToggleStatusChange} />
        <PackageTitleWrapper>
          <h2>{`${totalWeekCount}주 패키지`}</h2>
          <span className="tag">{`총 ${
            totalWeekCount === 4 ? totalServiceCount : totalServiceCountForOneWeek
          }회`}</span>
        </PackageTitleWrapper>
        <ServiceFeeTextWithSlider
          weekCountData={totalWeekCount === 4 ? fourWeekWeeklyArr : oneWeekWeeklyArr}
          currentWeekCount={currentWeek}
          weekType={totalWeekCount === 4 ? 'four' : 'one'}
          onWeekChange={handleWeekChange}
        />
        <TagList>
          {totalWeekCount === 4 && <span className="tag">✅ 정기 구독 결제 가능</span>}
          <span className="tag">✅ 카드결제 가능</span>
          <span className="tag">✅ 세금계산서 가능</span>
          <span className="tag">✅ 부가세 포함</span>
        </TagList>

        <Description>일부 업종의 경우, 기본 시간에 따라 서비스 비용이 상이할 수 있습니다.</Description>
        <StyledButton size="large" soft="brand" label="서비스 시작하기" onClick={handleApplyClick} />
      </FeeWrapper>
    </LandingServiceFeeSectionWrapper>
  )
}

const LandingServiceFeeSectionWrapper = styled.section`
  position: relative;

  width: 100%;
  padding: 180px 0px;

  background-color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1 {
    /* 28px */
    margin-bottom: 28px;

    font-size: 52px;
    font-weight: 700;
    line-height: 125%;

    color: #1e1e2c;
  }

  ${({ theme }) => theme.media.mobile`
    height: auto;
    padding: 70px 16px 60px 16px;
  
    & > h1 {
      margin-bottom: 16px;

      font-size: 30px;
      line-height: 40px;
    }  
  `}
`

const Description = styled.span`
  color: var(--gray600);
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  margin-top: 24px;

  ${({ theme }) => theme.media.mobile`
    font-size: 10px;
    line-height: 21px;

    margin-top: 12px;
  
  `}
`

const TagList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 24px;

  & > .tag {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: 1px solid var(--gray100);
    background: #f5f9fe;

    color: #00283f;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  ${({ theme }) => theme.media.mobile`

    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;

     & > .tag { 
      padding: 4px 12px;
      font-size: 12px;
      line-height: 21px;


      &:not(:last-child) {
        margin-right: 0px;
      }
     }
  `}
`

const FeeWrapper = styled.div`
  width: 880px;

  box-sizing: border-box;

  padding: 28px 50px 44px 50px;

  background-color: var(--gray0);
  border-radius: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px 0px 28px 0px;
  }
`

const PackageTitleWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 28px;
  margin-bottom: 24px;

  & > h2 {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    color: var(--gray900);

    margin-right: 12px;
  }

  .tag {
    height: 28px;

    padding: 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    background: var(--brand100);

    color: var(--brand500);
    font-size: 16px;
    font-weight: 500;
    line-height: 175%;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 24px;
    margin-bottom: 12px;

    & > h2 {
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: var(--gray800);
    }

    & > h4 {
      text-align: center;
      white-space: pre;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--gray600);
    }
  }
`

const StyledButton = styled(Button)`
  margin-top: 40px;
  color: #ffffff !important;
  background: var(--brand500) !important;
  border-radius: 16px !important;

  padding: 0px 40px;
  font-weight: 400;

  min-height: 48px !important;

  @media only screen and (max-width: 768px) {
    margin-top: 32px;
    min-height: 40px !important;
    height: 40px !important;
    padding: 0px 24px !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }
`
