import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === '/') return
    document.getElementById('root')?.scrollIntoView()
  }, [pathname])

  return null
}
