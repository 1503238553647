import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'

type StyledAuthContentProps = {
  paddingTop: '200' | '120'
  scroll?: boolean
  center?: boolean
}

export type AuthContentProps = {
  title: string
  children: React.ReactNode
} & StyledAuthContentProps

export default function AuthContent({ paddingTop, scroll = false, children, title, center = true }: AuthContentProps) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <StyledAuthContent paddingTop={paddingTop} scroll={scroll} center={center}>
        <img src="/image/auth-main.webp" alt="Header Background" />
        <StyledAuthFormWrapper center={center}>
          <MobileTitle>
            {title.split('\\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </MobileTitle>
          <Title>{title.replace('\\n', '')}</Title>
          {children}
        </StyledAuthFormWrapper>
      </StyledAuthContent>
    </>
  )
}

const StyledAuthContent = styled.div<StyledAuthContentProps>`
  & > img {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    z-index: -1;
    top: 0;

    ${({ theme }) => theme.media.mobile`
      display: none;
    `}
  }

  /* background-color: ${({ theme }) => theme.colors.main}; */
  height: calc(100% - ${({ theme }) => theme.spacing(7)});
  padding-bottom: ${({ theme }) => theme.spacing(15)};
  padding-top: ${({ theme, paddingTop }) => (paddingTop === '120' ? theme.spacing(15) : theme.spacing(25))};
  ${({ scroll }) => scroll && `height: auto`};
  ${({ theme, center }) => theme.media.mobile`
  height: 100%;
  background-color: white;
  padding-top: 1.6rem;
  padding-bottom: 0rem;
  ${center && 'display: flex; align-items: center; padding-top: 0rem;'}
  `}
`

const StyledAuthFormWrapper = styled.section<{ center?: boolean }>`
  width: 40rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1.6rem;
  padding: ${({ theme }) => theme.spacing(5)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  ${({ theme, center }) => theme.media.mobile`
  max-width: 31.2rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 10rem;
  ${center && 'padding-left: 3rem; padding-right: 3rem;'}
  `}
`

const Title = styled.p`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4rem;
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.media.mobile`display: none;`}
`

const MobileTitle = styled.p`
  display: none;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4rem;
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.media.mobile`display: block;`}
`
