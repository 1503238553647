import Icon from 'components/Icon'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { UTM_URL } from 'utils/common/utmUrl'

export default function LandingMapSection() {
  return (
    <LandingMapSectionWrpper>
      <ContentContainer>
        <TextWrapper>
          <h3>무인매장 관리,</h3>
          <h2>
            브라우니가
            <br />
            대신할게요
          </h2>
          <h4>
            서울을 중심으로 <br className="mobile_br" />
            수도권(일부 지역) 및 광역시 등
            <br />
            서비스 지역 확장 중
          </h4>
          <span>📣 주말은 서울, 수도권만 가능해요!</span>
          <Link to={`/map${UTM_URL.LANDING_GO_TO_MAP}`}>
            브라우니 관리매장 지도 확인하기
            <Icon name="LinkExternal" />
          </Link>
        </TextWrapper>
        <MapWrapper>
          <RabbitQuestionWrapper>
            <a href="https://brwnie.channel.io/" target="_blank" rel="noreferrer">
              <span>지역 확장 문의</span>가 필요하신가요?
              <div className="rectangle" />
            </a>
          </RabbitQuestionWrapper>
          <img src="/image/브라우니_무인매장_활동지역.png" alt="map" />
        </MapWrapper>
      </ContentContainer>
    </LandingMapSectionWrpper>
  )
}

const LandingMapSectionWrpper = styled.section`
  width: 100%;
  padding: 175px 160px;

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--gray0);
  z-index: 0;

  & .mobile_br {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    height: 820px;

    & .mobile_br {
      display: block;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1030px;
  height: 446px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    height: inherit;
    position: relative;
    flex-direction: column;
    justify-content: unset;
  }
`

const TextWrapper = styled.div`
  & > h2 {
    font-weight: 700;
    font-size: 52px;
    line-height: 65px;
    color: var(--gray800);
    margin-bottom: 20px;
  }

  & > h3 {
    font-weight: 500;
    font-size: 30px;
    color: #1e1e2c;
  }

  & > h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--gray600);
    margin-bottom: 20px;
  }

  & > span {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: 1px solid var(--gray100);
    background: #f5f9fe;

    color: var(---gray600);
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    line-height: 28px; /* 175% */

    margin-bottom: 52px;
  }

  & > a {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--brand400);
    text-decoration: none;
    display: flex;
    align-items: center;

    & > svg {
      margin-left: 4px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    & > h2 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 16px;
      text-align: center;
    }

    & > h3 {
      font-size: 20px;
      line-height: 50.4px;
    }

    & > h4 {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 16px;
    }

    & > a {
      width: 260px;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      justify-content: center;

      position: absolute;
      bottom: 58px;
      left: calc(50% - 130px);
    }

    & > span {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 40px;
    }
  }
`

const MapWrapper = styled.div`
  position: relative;

  & > img {
    width: 446px;
    height: 446px;
  }

  @media only screen and (max-width: 768px) {
    display: block;
    & > img {
      width: 328px;
      height: 328px;
    }
  }
`

const question = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(12px);
  }
  100% {
    transform: translateY(0px);
  }

`

const RabbitQuestionWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 78px;
  width: 216px;
  height: 32px;
  background-color: var(--gray800);
  border-radius: 99px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${question} 1.6s ease-in-out infinite;

  & > a {
    text-decoration: none;
    color: var(--gray0);

    & > span {
      text-decoration-line: underline;
    }
  }

  .rectangle {
    position: absolute;
    right: 26px;
    bottom: -4px;
    width: 12px;
    height: 12px;
    background-color: var(--gray800);
    transform: rotate(-45deg);
  }

  @media only screen and (max-width: 768px) {
    width: 182px;
    height: 29px;
    border-radius: 12px;

    font-size: 12px;
    line-height: 21px;

    top: -12px;
    right: 50px;

    .rectangle {
      width: 8px;
      height: 8px;
    }
  }
`
