import { useAppSelector } from 'hooks/useReduxHook'
import { selectCompletedSchedules } from 'modules/features/service/serviceSlice'
import { findSchedulesIndex } from 'utils/common/service'
import CalendarDate from '../CalendarDate'

export type ConfirmDateProps = {
  year: number
  month: number
  date: number
  day: number
  onDateClick: (year: number, month: number, date: number, day: number) => void | Promise<void>
}

export default function ConfirmDate(props: ConfirmDateProps) {
  const { year, month, date, day, onDateClick } = props

  const completedSchedules = useAppSelector(selectCompletedSchedules)

  const completedIndex = findSchedulesIndex(completedSchedules, year, month, date)

  const isSelected = completedIndex !== -1
  const isInactive = !isSelected

  const label = isSelected ? `${completedSchedules[completedIndex].hour}시` : undefined

  return (
    <CalendarDate
      {...props}
      key={day}
      selected={isSelected}
      inactive={isInactive}
      label={label}
      onDateClick={async () => {
        await onDateClick(year, month, date, day)
      }}
    />
  )
}
