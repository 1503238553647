import styled from 'styled-components'

export type InformationProps = {
  children: React.ReactNode
}

export default function Information({ children }: InformationProps) {
  return <StyledInformation>{children}</StyledInformation>
}

const StyledInformation = styled.div`
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)};
  width: fit-content;

  & > p {
    font-size: 1.4rem;
    font-weight: 400;
    ${({ theme }) => theme.colors.black};
    line-height: 2rem;
    margin-left: ${({ theme }) => theme.spacing(2)};
    word-break: keep-all;

    & > br.mobile_br {
      display: none;
    }

    strong {
      font-weight: 700;
    }
  }

  & > svg {
    min-width: 32px;
    min-height: 32px;
  }

  ${({ theme }) => theme.media.mobile`
    justify-content: center;

    & > p {
      font-size: 12px;
      line-height: 16px;
      flex-direction: column;
      
      & > br {
        display: none;
      }

      & > br.mobile_br {
        display: inline;
      }
    }

    width: ${theme.width.mobile}
  `}
`
