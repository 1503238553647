import { useEffect, useState } from 'react'
import styled from 'styled-components'

interface PopupProps {
  popupId: string
  image: string
  link?: string
}

export function Popup({ popupId, image, link }: PopupProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const storageValue = localStorage.getItem(popupId)
    if (!storageValue) {
      setOpen(true)
      return
    }
    const data = JSON.parse(storageValue)
    const hideTime = Number(data)

    const currentTime = new Date().getTime()
    const deltaTime = currentTime - hideTime

    // 팝업 노출 시간 24시간 (ms 단위)
    if (deltaTime >= 24 * 60 * 60 * 1000) {
      localStorage.removeItem(popupId)
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handle24HourHide = () => {
    const currentDate = new Date()
    localStorage.setItem(popupId, JSON.stringify(currentDate.getTime()))
    setOpen(false)
  }

  return (
    <>
      {open && (
        <Container>
          <a href={link ?? '#'} target="_blank">
            <Image src={image} />
          </a>
          <BottomMenu>
            <BottomButton onClick={handle24HourHide}>24시간 동안 다시 열람하지 않습니다.</BottomButton>
            <BottomButton onClick={() => setOpen(false)}>닫기</BottomButton>
          </BottomMenu>
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  position: fixed;
  top: 90px;
  left: 50%;
  width: 80%;
  max-width: 400px;

  overflow-y: auto;
  z-index: 9999;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  transform: translate(-50%, 0);
`

const Image = styled.img`
  width: 100%;
  height: 100%;
`

const BottomMenu = styled.div`
  height: 38px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: #333333;
`

const BottomButton = styled.button`
  color: #f6f6f6;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 15px */
  letter-spacing: -0.4px;

  width: fit-content;
  height: 100%;

  padding: 10px;
  box-sizing: border-box;

  background-color: transparent;
  cursor: pointer;
`
