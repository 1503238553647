import { closeToast, openToast } from 'modules/features/toast/toastSlice'
import { useAppDispatch } from './useReduxHook'

export default function useToast() {
  const dispatch = useAppDispatch()

  const open = (value: string) => {
    dispatch(openToast(value))
  }

  const close = () => {
    dispatch(closeToast())
  }

  return {
    open,
    close,
  }
}
