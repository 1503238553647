import useStoresQuery from 'hooks/query/store/useStoresQuery'
import styled from 'styled-components'
import StoreItem from './StoreItem/StoreItem'
import StoreListLoading from './StoreListLoading'

export default function StoreList() {
  const { data: storeList, isLoading } = useStoresQuery()
  return (
    <Container>
      {isLoading && <StoreListLoading />}
      {!isLoading && storeList && storeList.map((store) => <StoreItem key={store.id} store={store} />)}
    </Container>
  )
}

const Container = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-top: 25px;

  ${({ theme }) => theme.media.mobile`
    margin-top: 16px;
  `}
`
