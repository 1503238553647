import client from '../client'

export async function postResetPasswordComplete({ email, password, password_repeat }: ResetPasswordCompleteReq) {
  const response = await client.post<undefined>(`auth-api/v1/auth/reset/password/complete`, {
    email,
    password,
    password_repeat,
  })

  return response.data
}

export type ResetPasswordCompleteReq = {
  email: string
  password: string
  password_repeat: string
}
