import { getAuth } from 'lib/api/auth/getAuth'
import { EmailSignUpReq, postEmailSignUp } from 'lib/api/auth/postEmailSignUp'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { ROUTES } from 'utils/common/routes'

export default function usePostEmailSignUp() {
  const history = useHistory()
  const queryClient = useQueryClient()
  const authKey = 'auth'

  return useMutation((value: EmailSignUpReq) => postEmailSignUp(value), {
    onSuccess: async () => {
      await queryClient.prefetchQuery(authKey, getAuth)
      history.push(ROUTES.STORE)
    },
  })
}
