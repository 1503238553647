import client from '../client'
import { GetPointsRequest, GetPointsResponse } from './types'

export async function getPoints(request?: GetPointsRequest) {
  const response = await client.get<GetPointsResponse>('payment-api/v1/points', {
    params: {
      ...request
    }
  })
  return response.data
}
