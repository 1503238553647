import ILogger from '../ILogger'
import { LOGGER } from '../types'

class NaverAdLogger implements ILogger {
  private wcsId: string = 's_2bcdc6d6e025'
  type: LOGGER = LOGGER.NAVER_AD

  onAttached(): boolean {
    // @ts-ignore
    if (!window.wcs_add) {
      // @ts-ignore
      window.wcs_add = {}
    }
    // @ts-ignore
    window.nasa = {}
    // @ts-ignore
    window.wcs_add['wa'] = this.wcsId
    return true
  }

  onDetached(): boolean {
    return true
  }

  onLog(): void {
    // @ts-ignore
    if (!window.wcs_add || !window.nasa) {
      console.warn('[NAVER AD LOGGER] INSTANCE IS NULL')
      return
    }

    // @ts-ignore
    if (window.wcs) {
      // @ts-ignore
      window.wcs.inflow()
      // @ts-ignore
      window.wcs_do(window.nasa)
    }
  }
}

export default NaverAdLogger