import client from '../client'
import { SignUpRes } from './types'

export async function postEmailSignUp(req: EmailSignUpReq) {
  const response = await client.post<SignUpRes>(`auth-api/v1/auth/sign-up/email`, req)
  return response.data
}

export type EmailSignUpReq = {
  real_name: string
  email: string
  password: string
  phone: string
  referral_code?: string | null
  birth_year?: string
  birth_day?: string
  gender?: 'M' | 'F' | 'U'
  marketing_agree?: boolean
}
