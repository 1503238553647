import Icon from 'components/Icon'
import useAuth from 'hooks/query/auth/useAuth'
import LogManager from 'lib/utils/logger'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ROUTES } from 'utils/common/routes'
import { UTM_URL } from 'utils/common/utmUrl'

type FloatingApplyButtonProps = {
  scrollPosition: number
  offsetHeight: number
}

export default function FloatingApplyButton({ scrollPosition, offsetHeight }: FloatingApplyButtonProps) {
  const history = useHistory()
  const { isLogin } = useAuth()
  const { pathname } = useLocation()

  const handleApplyClick = () => {
    const route = isLogin
      ? ROUTES.STORE +
        (pathname === '/' ? UTM_URL.LANDING_FLOATING_SERVICE_LOGIN_START : UTM_URL.DETAIL_FLOATING_SERVICE_LOGIN_START)
      : ROUTES.AUTH_LOGIN +
        (pathname === '/'
          ? UTM_URL.LANDING_FLOATING_SERVICE_LOGOUT_START
          : UTM_URL.DETAIL_FLOATING_SERVICE_LOGOUT_START)
    history.push(route)
    if (pathname === '/') {
      LogManager.Instance.sendLog('main_service', {
        page_url: pathname,
        btn_url: route,
      })
    } else {
      LogManager.Instance.sendLog('main_test_service', {
        btn_url: route,
      })
    }
  }

  return (
    <>
      <ApplyMobileButtonWrapper>
        <div
          className="easy-consulting"
          onClick={() => history.push(ROUTES.EASY_CONSULTING + UTM_URL.FLOATING_CONSULTING)}
        >
          <Icon name="Substract" />
          상담 신청
        </div>
        <VerticalDivider />
        <a href={`tel:070-7122-0913`}>
          <div className="call-consulting">
            <Icon name="Call" />
            전화 상담
          </div>
        </a>
      </ApplyMobileButtonWrapper>
      <ApplyPcButtonWrapper scrollPosition={scrollPosition} offsetHeight={offsetHeight}>
        <div className="service-apply" onClick={handleApplyClick}>
          <Icon name="whiteLocation" />
          서비스 신청
        </div>
        <VerticalDivider />
        <div
          className="easy-consulting"
          onClick={() => history.push(ROUTES.EASY_CONSULTING + UTM_URL.FLOATING_CONSULTING)}
        >
          <Icon name="Substract" />
          상담 신청
        </div>
      </ApplyPcButtonWrapper>
    </>
  )
}

const ApplyMobileButtonWrapper = styled.div`
  display: none;
  position: fixed;
  bottom: 32px;
  right: 16px;
  // 채널톡 z-index가 500이기 때문에, 한단계 낮음 499로 설정
  z-index: 499;
  box-shadow: 0px 16px 16px -8px rgb(0 0 0 / 24%);
  background-color: var(--brand300);
  height: 56px;
  border-radius: 28px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    text-align: center;
    width: 123px;
    height: 56px;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray0);
    box-sizing: border-box;

    &:active {
      background-color: var(--brand400);
    }
  }

  .easy-consulting {
    font-weight: 500;
    justify-content: flex-end;
    padding-right: 16px;
    border-radius: 28px 0px 0px 28px;
  }

  .call-consulting {
    font-weight: 700;
    justify-content: flex-start;
    padding-left: 16px;
    border-radius: 0px 28px 28px 0px;
  }

  svg {
    margin-right: 4px;
  }

  & > a {
    display: flex;
    text-decoration: none;
    color: var(--gray0);
    border-radius: 0px 28px 28px 0px;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
  }
`

const VerticalDivider = styled.hr`
  border: none;
  width: 1px;
  height: 12px;
  display: flex;
  justify-content: center;
  background-color: #99bbff;
  opacity: 1;

  @media only screen and (max-width: 768px) {
    opacity: 0.5;
  }
`

const ApplyPcButtonWrapper = styled.div<{ scrollPosition: number; offsetHeight: number }>`
  box-shadow: 0px 16px 16px rgb(0 0 0 / 12%);
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 40px;
  border-radius: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--brand300);
  opacity: 0;
  visibility: hidden;
  z-index: 1004;
  cursor: pointer;

  transition: opacity 0.3s ease-in-out;

  div {
    display: flex;
    align-items: center;
    text-align: center;
    width: 170px;
    height: 64px;
    font-size: 16px;
    line-height: 28px;
    color: var(--gray0);
    box-sizing: border-box;

    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: var(--brand400);
    }
  }

  .service-apply {
    font-weight: 700;
    justify-content: flex-end;
    padding-right: 20px;
    border-radius: 32px 0px 0px 32px;
  }

  .easy-consulting {
    font-weight: 500;
    justify-content: start;
    padding-left: 20px;
    border-radius: 0px 32px 32px 0px;
  }

  svg {
    margin-right: 6px;
  }

  ${({ scrollPosition, offsetHeight }) =>
    scrollPosition > offsetHeight / 1.6 &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  @media only screen and (max-width: 768px) {
    display: none;
  }
`
