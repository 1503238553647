import styled from 'styled-components'
import { ReactComponent as CheckIcon } from './interface-check.svg'

interface CheckButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  active: boolean
  label: string
}

export default function CheckButton({ active, label, ...props }: CheckButtonProps) {
  return (
    <Button active={active} {...props}>
      <Indicator active={active}>{active && <CheckIcon />}</Indicator>

      <Label>{label}</Label>
    </Button>
  )
}

const Button = styled.button<{ active: boolean }>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f6f6f6;

  ${({ active }) =>
    active &&
    `
      border: 2px solid #083994;
      padding-left: 8px;
    `}
`

const Indicator = styled.span<{ active: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  margin-right: 6px;

  ${({ active }) =>
    active &&
    `
      background-color: #083994;
    `}
`

const Label = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.072px;
  color: #212121;

  ${({ theme }) => theme.media.mobile`
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.056px;
  `}
`
