import Icon from 'components/Icon'
import { useHistory, useParams } from 'react-router'
import serviceTypeData from 'data/serviceTypeData.json'
import styled from 'styled-components'
import { ROUTES } from 'utils/common/routes'
import { useLocation } from 'react-router-dom'
import LogManager from 'lib/utils/logger'

export default function ServiceDetailFirstSection() {
  const history = useHistory()
  const { pathname } = useLocation()

  const { type } = useParams<{ type: string }>()
  const typeData = serviceTypeData.find(({ param }) => param === type)

  const handleApplyClick = () => {
    const route = ROUTES.EASY_CONSULTING + typeData?.utm_url
    history.push(route)
    LogManager.Instance.sendLog('service_detail_service', {
      page_url: pathname,
      btn_url: route,
    })
  }

  const filterIcon = () => {
    if (typeData) {
      switch (typeData.icon) {
        case 'Cafe':
          return 'Cafe'
        case 'SelfLaundry':
          return 'SelfLaundry'
        case 'VendingMachine':
          return 'VendingMachine'
        case 'IceCream':
          return 'IceCream'
        case 'StudyCafe':
          return 'StudyCafe'
        case 'Convenience':
          return 'Convenience'
        case 'Studio':
          return 'Studio'
        case 'Stationery':
          return 'Stationery'
        case 'PrinterMachine':
          return 'PrinterMachine'
      }
    }
  }

  const storeName = typeData?.type.includes('무인 자판기')
    ? '무인 점포'
    : typeData?.type.includes('아이스크림')
    ? '아·할'
    : typeData?.type

  return (
    <FirstSectionWrapper>
      <DescriptionWrapper>
        <div className="contentWrapper">
          <IconWrap>
            <Icon name={filterIcon() || 'Cafe'} />
          </IconWrap>
          <h3>{typeData?.type}</h3>
          <h4>{typeData?.description}</h4>
          <ServiceApplyButton onClick={handleApplyClick}>
            <QuestionWrapper>
              번거로웠던 {storeName} 관리, 브라우니로 해결!
              <div className="rectangle" />
            </QuestionWrapper>
            정기관리 상담 신청
            <Icon name="WhiteArrowRight" />
          </ServiceApplyButton>
        </div>
      </DescriptionWrapper>

      <MobileImageWrapper>
        <BeforeAfterImage src={typeData?.before_after_src} alt={typeData?.type} />
      </MobileImageWrapper>
      <ImageWrapper src={typeData?.review_src || ''} />
    </FirstSectionWrapper>
  )
}

const FirstSectionWrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  background: linear-gradient(111.34deg, #e1f5fe 15.1%, rgba(225, 245, 254, 0) 78.65%),
    url('/image/new_service_type/main-blur.webp');
  background-size: cover;

  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 64px 0px;
    padding-top: 128px;

    background: linear-gradient(90deg, #e1f5fe 22.4%, rgba(225, 245, 254, 0) 100%);

    flex-direction: column;
    justify-content: space-between;
  }
`

const DescriptionWrapper = styled.div`
  width: 1280px;
  height: inherit;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;

  .contentWrapper {
    width: 540px;
    height: 544px;
    position: relative;

    h3 {
      position: absolute;
      top: 144px;
      left: 0px;
      font-weight: 700;
      font-size: 64px;
      line-height: 90px;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.black};
    }

    h4 {
      margin: 0;
      position: absolute;
      top: 258px;
      left: 0px;
      width: 380px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: ${({ theme }) => theme.colors.black};
      word-break: keep-all;
      white-space: pre-wrap;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 16px;

    .contentWrapper {
      position: relative;
      height: auto;

      h3 {
        position: static;
        font-size: 36px;
        line-height: 50px;

        margin-bottom: 44px;
      }

      h4 {
        position: static;
        font-size: 14px;
        line-height: 24px;
      }

      pre {
        position: static;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`

const IconWrap = styled.div`
  @media only screen and (max-width: 768px) {
    margin-bottom: 36px;
  }
`
const QuestionWrapper = styled.div`
  position: absolute;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  bottom: 54px;
  padding: 2px 8px;
  background-color: var(--brand700);
  color: var(--gray0);
  letter-spacing: -0.3px;
  z-index: 2;

  border-radius: 99px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .rectangle {
    position: absolute;
    bottom: -3px;
    width: 10px;
    height: 10px;
    background-color: var(--brand700);
    transform: rotate(-45deg);
    border-radius: 2px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ServiceApplyButton = styled.div`
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  width: 320px;
  height: 64px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 27px;
  box-shadow: 0px 16px 16px rgb(0 0 0 / 12%);
  transition: background-color ease-out 0.15s, color ease-out 0.15s;

  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.indigoA700};

  & > svg {
    margin-left: 8px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.black};

    & > svg > path {
      stroke: var(--gray800);
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const MobileReviewBoxWrapper = styled.div`
  width: 328px;
  height: 260px;
  position: absolute;
  padding: 24px;
  top: -208px;
  left: calc(50% - 164px);
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 16px 16px rgba(0, 83, 181, 0.1);
  backdrop-filter: blur(6px);
  border-radius: 24px;

  p {
    margin-top: 26px;
    width: 280px;
    height: 56px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
    white-space: pre-wrap;
  }

  & > svg {
    path {
      stroke: #6fa0ff;
      fill: none;
    }
  }

  .commentAuthorWrapper {
    margin-top: 36px;
    width: 280px;
    height: 48px;
    display: flex;
    flex-direction: row;
    text-align: start;

    .circleIcon {
      width: 48px;
      height: 48px;
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0px 4px 10px rgba(30, 30, 44, 0.1);
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }

    & > span {
      margin-left: 16px;
      font-size: 12px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.gray500};
    }
  }
`

const ImageWrapper = styled.img`
  position: absolute;
  bottom: 0px;
  right: 180px;
  width: 40%;
  object-fit: contain;
  margin-left: 96px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const MobileImageWrapper = styled.div`
  display: none;
  position: absolute;
  bottom: 0px;
  width: 100%;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    display: flex;
    position: relative;
    margin-bottom: 50px;
    margin-top: 44px;
  }
`

const BeforeAfterImage = styled.img`
  width: 100%;
  object-fit: contain;
  max-width: 460px;
`
