import { QueryOptionsOf } from '../../../lib/utils/types'
import { useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { getAuth } from '../../../lib/api/auth/getAuth'
import { GetAuthRes } from '../../../lib/api/auth/types'

export default function useAuthQuery(value?: any, options: QueryOptionsOf<typeof getAuth> = {}) {
  return useQuery(createKey(), () => getAuth(), options)
}

const createKey = () => 'authCheck'
useAuthQuery.createKey = createKey

export async function useAuthQueryGetter() {
  const queryClient = useQueryClient()
  await queryClient.prefetchQuery(createKey(), getAuth)

  const get = useCallback(() => {
    return queryClient.getQueryData<GetAuthRes>(createKey())
  }, [queryClient])

  return get
}
