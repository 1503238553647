import CalendarForm from 'components/ServicePostPage/CalendarForm'
import useVisitHourBottomSheet from 'hooks/service/useVisitHourBottomSheet'
import { useAppSelector } from 'hooks/useReduxHook'
import { ScheduleType } from 'modules/features/service/serviceSlice'

export type InvalidTimeCalendarProps = {
  selectedSchedule?: ScheduleType
  setSelectedSchedule: (schedule: ScheduleType) => void
}

export default function InvalidTimeCalendar({ selectedSchedule, setSelectedSchedule }: InvalidTimeCalendarProps) {
  const { schedules } = useAppSelector((state) => state.service)

  const { year: firstYear, month: firstMonth } = schedules[0]

  const [openVisitHourOptions] = useVisitHourBottomSheet()

  const handleDateClick = async (year: number, month: number, date: number) => {
    const selectedVisitHour = await openVisitHourOptions({
      title: `${month}월 ${date}일 몇 시가 좋을까요?`,
      year,
      month,
      date,
    })

    if (selectedVisitHour !== undefined) {
      setSelectedSchedule({ year, month, date, hour: selectedVisitHour })
    }
  }

  return (
    <CalendarForm
      type="invalidTime"
      firstYear={firstYear}
      firstMonth={firstMonth}
      newSchedule={selectedSchedule}
      onDateClick={handleDateClick}
    />
  )
}
