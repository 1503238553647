import { useState, useLayoutEffect, useEffect } from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import Button from '../../common/Button'
import { customStyles, DropdownIndicator, getDateYMD, IndicatorSeparator } from '../SignUpForm/utils'
import usePostSocialSignUp from 'hooks/query/auth/usePostSocialSignUp'
import Icon from 'components/Icon'
import useAuth from 'hooks/query/auth/useAuth'
import Spinner from 'components/common/Spinner'
import useDropUser from 'hooks/query/auth/useDropUser'
import { useHistory } from 'react-router'
import { ROUTES } from 'utils/common/routes'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import useTermData from '../../../data/useTermData.json'
import CustomCheckbox from 'components/common/CustomCheckbox'
import dayjs from 'dayjs'

export interface SignUpFormInput {
  referralCode?: string
  year?: { label: string; value: string }
  month?: { label: string; value: string }
  day?: { label: string; value: string }
  gender: 'M' | 'F' | 'U'
  termCheck?: boolean
}

export default function SocialOptionForm() {
  const [termCheck, setTermCheck] = useState<boolean>(false)
  const [day, setDay] = useState<string>('')
  const [month, setMonth] = useState<string>('')
  const [year, setYear] = useState<string>('')
  const [gender, setGender] = useState<'M' | 'F' | 'U'>('U')
  const [focusInput, setFocusInput] = useState<boolean>(false)
  const [signUpLoading, setSignUpLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [submitState, setSubmitState] = useState<boolean>(false)

  const [onChangeValueDay, setOnChangeValueDay] = useState<{ value: string | null; label: string | null }>({
    value: null,
    label: null,
  })
  const [onChangeValueMonth, setOnChangeValueMonth] = useState<{ value: string | null; label: string | null }>({
    value: null,
    label: null,
  })

  const { mutateAsync: mutateDropUser } = useDropUser()
  let { mutateAsync: mutatePostSocialSignUp, error, isError, isLoading } = usePostSocialSignUp()
  let errorMessage = error as any
  const { auth } = useAuth()
  const history = useHistory()

  useLayoutEffect(() => {
    if (auth) {
      auth.birth_year && setYear(auth.birth_year)
      if (auth.birth_day) {
        setMonth(auth.birth_day.substr(0, 2))
        setDay(auth.birth_day.substr(2, 2))
      }
      auth.gender && setGender(auth.gender)
      auth.email && auth.email.includes('@') && setEmail(auth.email)
    }
  }, [auth])

  useEffect(() => {
    if (isError) {
      setSignUpLoading(false)
    }
  }, [isError])

  const { register, handleSubmit, control, setValue, getValues } = useForm({ mode: 'all' })

  const onSubmit = async (data: SignUpFormInput) => {
    if (isLoading) return
    setSubmitState(true)
    setFocusInput(false)

    if (name === '') return

    let birthYear = year
    let birthDate = month + day
    if (data.day && data.month) {
      birthDate = data.month.value.replace(' 월', '') + data.day.value.replace(' 일', '')
    }
    if (data.year) {
      birthYear = data.year.value.replace(' 년', '')
    }
    if (termCheck) {
      setSignUpLoading(true)
      await mutatePostSocialSignUp({
        real_name: name,
        email: email,
        referral_code: null,
        birth_year: birthYear,
        birth_day: birthDate,
        gender: gender,
        marketing_agree: getValues(useTermData[3].subject),
      })
      LogManager.Instance.sendLog('tag', '회원가입', LOGGER.KAKAO)
      LogManager.Instance.sendLog(
        'sign_up_complete',
        {
          method: 'social',
          user_id: 'NULL',
          gender: gender,
          recommendation_check: data.referralCode?.length === 0,
          recommendation_code: data.referralCode,
          signup_date: dayjs().format('YYYY-MM-DD'),
          age: data.year ? dayjs().diff(dayjs(data.year.value.replace(' 년', '')), 'year') : 0,
          signup_mtk_agreement: getValues(useTermData[3].subject),
        },
        LOGGER.GTM
      )
    }
  }
  const onClickHandlerDropUser = async () => {
    await mutateDropUser()
    history.push(ROUTES.AUTH_SIGN_UP)
  }

  const onClickHandlerButtonM = () => {
    setGender('M')
  }

  const onClickHandlerButtonF = () => {
    setGender('F')
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const onChangeHandlerEmail = (e: any) => {
    const { value } = e.target
    setEmail(value)
  }

  const handleMonthChange = (e: { value: string; label: string }) => {
    if (e.value) {
      setOnChangeValueMonth({ label: e.label, value: e.value })
      setValue('month', { label: e.label, value: e.value })
    }
    const value = e.value
    if (value && onChangeValueDay.value !== null && onChangeValueDay.label !== null) {
      let month = parseInt(value.replace(' 월', ''))
      let day = parseInt(onChangeValueDay.label.replace(' 일', ''))
      if (month === 2 && (day === 29 || day === 30 || day === 31)) {
        setOnChangeValueDay({ value: '28 일', label: '28 일' })
        setValue('day', { value: '28 일', label: '28 일' })
      } else if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
        setOnChangeValueDay({ value: '30 일', label: '30 일' })
        setValue('day', { value: '30 일', label: '30 일' })
      }
    }
  }

  const handleDayChange = (e: { value: string; label: string }) => {
    if (e.value) {
      setOnChangeValueDay({ label: e.label, value: e.value })
      setValue('day', { value: e.value, label: e.label })
    }
  }

  const handleWholeTermCheck = () => {
    setSubmitState(false)
    //하나도 체크 안되있으면
    if (useTermData.every((item) => getValues(`${item.subject}`) === false)) {
      useTermData.map((item) => setValue(`${item.subject}`, true))
      setTermCheck(true)
      //모두 체크되어 있으면
    } else if (useTermData.every((item) => getValues(`${item.subject}`) === true)) {
      useTermData.map((item) => setValue(`${item.subject}`, false))
      setTermCheck(false)
      //하나라도 체크되어있으면
    } else {
      useTermData.map((item) => setValue(`${item.subject}`, true))
      setTermCheck(true)
    }
  }

  const handleDetailTermCheck = (itemSubject: string) => {
    setSubmitState(false)
    setValue(itemSubject, !getValues(itemSubject))
    const essentialTermData = useTermData.slice(0, 3)
    if (essentialTermData.every((item) => getValues(`${item.subject}`) === false)) {
      setTermCheck(false)
    } else if (essentialTermData.every((item) => getValues(`${item.subject}`) === true)) {
      setTermCheck(true)
    } else {
      setTermCheck(false)
    }
  }

  return (
    <SignUpWrapper>
      <SignUpFormWrapper onSubmit={handleSubmit(onSubmit)}>
        <RequireTitle>
          필수
          {/* <VerifyCompleteInfo onClick={onClickHandlerDropUser}>다시 가입</VerifyCompleteInfo> */}
        </RequireTitle>

        <Wrapper>
          <FormInput
            value={name}
            placeholder="이름"
            type="text"
            onChange={handleNameChange}
            onFocus={() => setFocusInput(true)}
          />
          <span>
            {isError && !focusInput && errorMessage.response.status !== 405 && <WarningIcon name="redError" />}
          </span>
        </Wrapper>

        <Wrapper block={!!(auth?.email && auth?.email.includes('@'))}>
          <FormInput
            value={email}
            placeholder="이메일 주소"
            type="email"
            onChange={onChangeHandlerEmail}
            disabled={!!(auth?.email && auth?.email.includes('@'))}
            onFocus={() => setFocusInput(true)}
            //이메일형식이 맞고 이메일이 존재하면
          />
          <span>
            {isError && !focusInput && errorMessage.response.status !== 405 && <WarningIcon name="redError" />}
          </span>
        </Wrapper>
        {isError && !focusInput && errorMessage.response.status !== 405 && (
          <WarningText>{errorMessage.response.data.message}</WarningText>
        )}
        <OptionTitle>선택</OptionTitle>

        <DateWrapper>
          <Controller
            name="year"
            control={control}
            render={({ field }) => (
              <CustomSelect
                {...field}
                isSearchable={false}
                styles={customStyles}
                options={getDateYMD('year')}
                placeholder={`${year} 년`}
                components={{ DropdownIndicator, IndicatorSeparator }}
                isDisabled={!!auth?.birth_year}
              />
            )}
          />

          <Controller
            name="month"
            control={control}
            render={({ field }) => (
              <CustomSelect
                {...field}
                onChange={handleMonthChange}
                value={onChangeValueMonth.label === null ? '' : onChangeValueMonth}
                isSearchable={false}
                styles={customStyles}
                options={getDateYMD('month')}
                placeholder={`${month} 월`}
                components={{ DropdownIndicator, IndicatorSeparator }}
                isDisabled={!!auth?.birth_day}
              />
            )}
          />
          <Controller
            name="day"
            control={control}
            render={({ field }) => (
              <CustomSelect
                {...field}
                onChange={handleDayChange}
                value={onChangeValueDay.label === null ? '' : onChangeValueDay}
                isSearchable={false}
                styles={customStyles}
                options={
                  onChangeValueMonth.value !== null ? getDateYMD('day', onChangeValueMonth.value) : getDateYMD('day')
                }
                placeholder={`${day} 일`}
                components={{ DropdownIndicator, IndicatorSeparator }}
                isDisabled={!!auth?.birth_day}
              />
            )}
          />
        </DateWrapper>
        <ButtonWrapper>
          <Button
            width={156}
            buttonTheme={gender === 'M' ? 'primary' : 'third'}
            type="button"
            onClick={onClickHandlerButtonM}
            disabled={auth?.gender !== 'U'}
          >
            남
          </Button>
          <Button
            width={156}
            buttonTheme={gender === 'F' ? 'primary' : 'third'}
            type="button"
            onClick={onClickHandlerButtonF}
            disabled={auth?.gender !== 'U'}
          >
            여
          </Button>
        </ButtonWrapper>
        <WarningText color="gray">선택 항목은 이벤트, 혜택을 드리는데 이용됩니다.</WarningText>
        <TermWrapper>
          <WholeAgreeLi>
            <CustomCheckbox>
              <input
                type="checkbox"
                id={'social_whole_check'}
                onClick={handleWholeTermCheck}
                defaultChecked={useTermData.every((item) => getValues(`${item.subject}`) === true)}
              />
            </CustomCheckbox>
            <label htmlFor={'social_whole_check'}>
              <h5>모든 약관에 동의합니다.</h5>
            </label>
          </WholeAgreeLi>
          <hr />
          {useTermData.map((item, index) => (
            <DetailAgreeLi key={index}>
              <CustomCheckbox>
                <input
                  type="checkbox"
                  id={`${item.subject}_id`}
                  {...register(item.subject, { required: index <= 2 ? true : false })}
                  onClick={() => handleDetailTermCheck(item.subject)}
                />
              </CustomCheckbox>
              <label htmlFor={`${item.subject}_id`}>
                <h5>{item.termName}</h5>
              </label>

              {item.subject !== 'adultTerm' && (
                <a href={item.link} target="_blank" rel="noreferrer">
                  보기
                </a>
              )}
            </DetailAgreeLi>
          ))}
        </TermWrapper>
        {name === '' && submitState && <WarningText style={{ textAlign: 'center' }}>이름을 입력해 주세요.</WarningText>}
        {!termCheck && submitState && <WarningText style={{ textAlign: 'center' }}>약관에 동의해 주세요.</WarningText>}
        <SignUpButton type="submit" onClick={() => setSubmitState(true)}>
          {signUpLoading ? <Spinner color="white" /> : '가입하기'}
        </SignUpButton>
      </SignUpFormWrapper>
    </SignUpWrapper>
  )
}

const SignUpWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(6)};
  overflow-y: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const SignUpFormWrapper = styled.form`
  width: 100%;
  position: relative;
`

const WarningText = styled.div<{ color?: string }>`
  margin-top: 0.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => `${theme.colors.warning}`};

  ${({ color }) =>
    color === 'gray' &&
    `
  color: #1e1e2c;
  opacity: 0.5;
  `}

  ${({ theme }) => theme.media.mobile`
  letter-spacing: -0.05rem;
  `}
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2, 0)};

  button {
    ${({ theme }) => theme.media.mobile`
    width: 15.2rem;
    min-width: 15.2rem;
    height: 4.8rem;
  `}
  }
`

const WarningIcon = styled(Icon)`
  cursor: default;
`

const RequireTitle = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => `${theme.colors.black}`};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-bottom: 0.8rem;
`

const DateWrapper = styled.div`
  display: flex;
  margin-top: 1.6rem;
  justify-content: space-between;
  & > div {
    & {
      width: 32%;
      height: 4.8rem;
    }
  }
`

const OptionTitle = styled.p`
  text-align: left;
  color: ${({ theme }) => `${theme.colors.black}`};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-bottom: 0.8rem;
  padding-top: 3.2rem;
`
const Wrapper = styled.div<{ block?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.1rem 1.6rem;
  margin-top: 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.gray};
  border-radius: 0.8rem;
  background-color: white;

  span {
    flex: 0 0 auto;
    font-size: 1.6rem;
    cursor: pointer;
    background-color: white;
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ block }) =>
    block &&
    `
  background-color: #f8f8f8;
  `}
`

const FormInput = styled.input<{ fill?: string; placeholder?: string }>`
  width: 100%;
  border: 0;
  font-size: 1.6rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2.4rem;
  padding: 0;

  ime-mode: disabled;
  -webkit-ime-mode: disabled;
  -moz-ime-mode: disabled;
  -ms-ime-mode: disabled;

  &:focus {
    ime-mode: disabled;
    -webkit-ime-mode: disabled;
    -moz-ime-mode: disabled;
    -ms-ime-mode: disabled;
  }

  ${({ fill }) =>
    fill === 'true' &&
    `
  border: solid 0.1rem #003399;
  `}

  :disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }

  ::placeholder {
    opacity: 0.3;
  }
`

const SignUpButton = styled.button<{ disabled?: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 4.8rem;
  margin-top: 5rem;
  border: none;
  border-radius: 0.8rem;
  -webkit-appearance: none;
  background-color: ${({ theme }) => `${theme.colors.main}`};
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => `${theme.colors.white}`};

  ${({ disabled }) =>
    disabled === true &&
    `
    background-color: #edeff1;
    color: #1e1e2c;
    opacity: 0.5;
    cursor: no-drop;

  `}
`

const CustomSelect = styled(Select)`
  [class*='menu'] {
    & > div {
      &:first-child {
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
      }
      &:last-child {
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
      }
    }
  }
`

const TermWrapper = styled.ul`
  width: 312px;
  height: 240px;
  margin-top: 48px;

  hr {
    border: none;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
    width: 312px;
    margin-top: 16px;
  }

  label {
    cursor: pointer;
  }
`

const WholeAgreeLi = styled.li`
  display: flex;
  align-items: center;

  h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    margin-left: 8px;
    width: 270px;
    color: ${({ theme }) => theme.colors.black};
  }
`

const DetailAgreeLi = styled.li`
  display: flex;
  align-items: flex-start;
  padding-top: 16px;
  h5 {
    width: 248px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.gray};
  }
  a {
    text-decoration: underline;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.gray};
  }
`
