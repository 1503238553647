import client from '../client'
import { MapStoreListType } from './types'

export async function getStoreData({ id }: GetStoreDataParam) {
  const response = await client.get<MapStoreListType>(`service-api/v1/store-map/map/${id}`)
  return response.data
}

export type GetStoreDataParam = {
  id: number
}
