import client from '../client'
import { PhoneVerifyRes } from './types'

export async function patchPhoneVerify({ user_input_code, phone }: PhoneVerifyReq) {
  const response = await client.patch<PhoneVerifyRes>('auth-api/v1/auth/verify/phone', { user_input_code, phone })

  return response.data
}

export type PhoneVerifyReq = {
  user_input_code: string
  phone: string
}
