import styled, { keyframes, css } from 'styled-components'

const spinner = keyframes`
  from {transform: rotate(0deg); }
  to {transform: rotate(360deg);}
`

const dash = keyframes`
 0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0rem;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -1.5rem;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -12.5rem;
  }
`

export type SpinnerProps = {
  size?: number
  color: 'main' | 'white'
}

export default function Spinner({ size = 16, color }: SpinnerProps) {
  return (
    <Wrapper color={color} role="progressbar" aria-valuemin={0} aria-valuemax={100} size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715"
        style={{ transform: 'rotate(0deg)' }}
      >
        <circle
          fill="transparent"
          cx="45.714285714285715"
          cy="45.714285714285715"
          r="20"
          strokeWidth="5.714285714285714"
          strokeDasharray="125.664"
          strokeDashoffset="12.566370614359172rem"
          className="v-progress-circular__overlay"
        ></circle>
      </svg>
    </Wrapper>
  )
}

const Wrapper = styled.div<SpinnerProps>`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  ${({ theme, color }) =>
    color === 'main'
      ? css`
          color: ${theme.colors.main};
          caret-color: ${theme.colors.main};
        `
      : css`
          color: ${theme.colors.white};
          caret-color: ${theme.colors.white};
        `}

  ${({ size }) =>
    size &&
    css`
      width: ${size / 10}rem;
      height: ${size / 10}rem;
    `}

  svg {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    animation: ${spinner} 1.4s linear infinite;
  }
  circle {
    stroke-linecap: round;
    stroke-dasharray: 80, 200;
    stroke-dashoffset: 0rem;
    stroke: currentColor;
    z-index: 2;
    transition: all 0.6s;
    animation: ${dash} 1.4s ease-in-out infinite;
  }
`
