import client from '../client'
import { ServiceType } from './types'

export async function patchServicePayment({ id, ...req }: PatchServicePaymentParam & PatchServicePaymentReq) {
  const response = await client.patch<ServiceType>(`/service-api/v1/service/payment/${id}`, req)

  return response.data
}

export type PatchServicePaymentParam = {
  id: string
}

export type PatchServicePaymentReq = {
  payment_id: number
  contracted_method: 'EMAIL' | 'KAKAO'
}
