import styled from 'styled-components'
import { ReactComponent as CollaboLogo } from './collabo-logo.svg'

export function HeroSection(): JSX.Element {
  return (
    <Section>
      <CollaboLogo />

      <img
        src="/image/wooribank/brwnie-info-text.webp"
        alt="무인매장 토탈케어 브라우니"
        width={250.85}
        height={45.38}
        className="brwnie-info-text-image"
      />

      <Title>
        더 이상 매장에
        <br /> 나오지 마세요!
      </Title>

      <img
        src="/image/wooribank/brwnie-main-picture.webp"
        alt="브라우니 크루가 청소하는 사진"
        width={290}
        height={290 - 47}
        className="brwnie-main-picture"
      />
    </Section>
  )
}

const Section = styled.section`
  position: relative;
  width: 100vw;
  background-color: #3d5afe;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  & > .brwnie-info-text-image {
    margin-top: 22px;
  }

  & > .brwnie-main-picture {
    object-fit: contain;
  }
`

const Title = styled.h2`
  color: #fff;
  text-align: center;
  font-family: GmarketSansBold;
  font-size: 34px;
  font-style: normal;
  line-height: 128%;
  letter-spacing: -0.109px;
  margin-top: 4px;
`
