import client from '../client'

export async function patchMarketingAgree({ marketing_agree }: MarketingAgreeReq) {
  const response = await client.patch<undefined>(`auth-api/v1/auth/modify/marketing-agree`, { marketing_agree })

  return response.data
}

export type MarketingAgreeReq = {
  marketing_agree: boolean
}
