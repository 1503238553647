import React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../Icon'
import SubTitle from './SubTitle'

const policy = `제 1 조 (목적)
이 약관은 무인매장 또는 사업장 관리에 대해 브라우니 서비스(이하 ‘서비스‘라 함)를 제공하는 주식회사 하이어엑스(이하 ‘을’ 또는 ‘회사‘라 함)과 이 서비스를 제공받는 이용자(이하 ‘갑’ 또는 ‘고객‘이라 함) 사이에 체결된 ‘브라우니 서비스 이용 계약서’ (이하 ‘본 계약‘이라고 함)에 따른 각 당사자의 권리와 의무를 규정함으로써 본 계약의 성실한 이행을 담보하는데 그 목적이 있습니다.

제 2 조 (용어의 정의)
이 약관에서 사용하는 용어의 의미는 다음 각 호와 같습니다.
① ‘브라우니 서비스‘란 ‘을’ 이 본 계약에서 약정한 바에 따라 고객에게 제공하는 업무로서 사업장 관리(협의한 미화 이외의 비품, 기기 관리 등 고객사 현장에서 필요한 기타 행위, 미화(청결과 미관을 위해 주변 환경을 깨끗이 가꾸는 행위) 업무를 말하며, ‘을’ 은 계약기간 동안 고객에게 서비스를 제공합니다.
② ‘이용료‘란 고객이 서비스를 이용한 대가로 ‘갑’ 과 ‘을’ 이 협의하여 본 계약서에 명시합니다.
③ ‘이용계약‘이란 서비스를 제공받기 위하여 ‘갑’ 과 ‘을’ 이 체결하는 계약 등을 말합니다
④ ‘해제‘란 ‘갑’ 또는 ‘을’ 이 서비스 제공개시 이전에 이용계약을 해약하는 것을 말합니다.
⑤ ‘해지‘란 ‘갑’ 또는 ‘을’ 이 서비스 제공개시 이후에 이용계약을 해약하는 것을 말합니다.

제 3 조 (계약기간)
① 본 계약은 계약체결일로부터 효력이 발생합니다.
② 본 계약의 계약기간은 4주 기간이 기본이며, 협의를 통해 12주, 24주 기간으로 계약이 가능합니다.
③ 본 계약 제12조에 따른 불가항력으로 서비스의 제공이 중단된 경우에는 그 기간만큼 계약기간이 연장됩니다.
④ ‘갑’ 또는 ‘을’이 본 계약기간의 만료일 14일 전까지 상대방에게 계약의 종료나 변경에 관하여 서면으로 이의를 제기하지 않는 경우에는 본 계약은 동일한 조건으로 4주간 자동으로 연장됩니다.

제 4 조 (양도금지 및 비밀유지)
① ‘갑’ 과 ‘을’ 은 상대방의 서면에 의한 사전 동의 없이 본 계약상의 권리와 의무를 제3자에게 양도하거나 이전할 수 없습니다.
② ‘을’ 은 본 계약의 체결 및 이행과정에서 알게 된 고객의 비밀을 타인 에게 누설하지 아니합니다.

제 5 조 (서비스의 제공)
① 매주 약정한 일자에 ‘을’이 서비스 대상물을 방문하여 무인매장 또는 사업장 관리를 실시함으로써 서비스를 제공합니다.
② ‘을’은 서비스 수행 시 고객의 시설물이나 비품에 손상이 없도록 필요한 주의를 다합니다. 다만, ‘갑’은 서비스 대상물에 대한 손상이 없도록 ‘을’에게 사전에 일반인이 알 수 없는 사안을 고지하여야 합니다.
③ 사전 안내된 표준 업무 및 범위 외 기타 관리 업무를 ‘을’에게 희망할 시 반드시 사전 협의를 거쳐 업무의 범위와 추가되는 금액을 정하여 계약합니다. 사전 협의되지 않은 업무의 지시 및 요구에는 ‘을’이  거부할 권리가 있으며 정상적인 업무가 불가능할 정도로 고객의 요구가 반복될 경우 회사는 계약을 해지할 수 있습니다.
④ 방문 예약 시각 60분 내 매장 도착은 정상 출근으로 관리 업무를 정상 진행합니다.
⑤ 사전 연락 없이 방문 예약 시각으로부터 60분 이후 매장 도착 시, 도착 시점에 지체없이 관리를 진행하고 추가 1회 관리를 보너스로 지급합니다.
⑥ 방문 예약일 담당 근무자 결근 시, 본사에서 긴급 출동하여 서비스를 진행합니다.
⑦ 이 외 서비스 이용과 관련한 내용은 홈페이지(www.brwnie.kr) 하단 이용약관 및 회원가입, 서비스 주문 시 동의한 이용약관에 따라 정합니다.

제 6 조 (사용 물품의 소유권, 비치 및 회수)
① 서비스 대상물에 방문하여 업무를 하기 위해 제공하는 물품의 소유권은 ‘을’에 있습니다.
② 고객은 ‘을’ 이 업무를 수행할 수 있도록, 가능 시 서비스 대상물에 업무 관련 물품을 비치할 수 있도록 협조하여야 합니다.
③ 서비스 해지 후, ‘을’은 7일 이내에 비치한 물품을 회수하고, 고객은 적시에 회수할 수 있도록 협조하여야 합니다.

제 7 조 (서비스 제공 계획의 변경)
① 고객은 서비스 대상물의 증축, 개축, 구조 및 용도 변경, 이전 등으로 인해 서비스 제공계획의 변경이 필요한 때에는 변경 14일 전까지 그 사실을 서면으로 ‘을’에 통지해야 합니다.
② 서비스 대상물의 변경에 따라 소요되는 추가 비용은 고객이 부담합니다.
③ 업무 범위의 변경은 고객과 ‘을’의 협의 하에 가능합니다.

제 8 조 (시설보완의 협조)
① ‘을’ 은 서비스 수행에 필요한 대상물 및 그 주변시설의 보완, 개선 및 필요한 조치를 고객에게 요청할 수 있습니다.
② 고객은 제1항에 따른 ‘을’의 요청에 신속히 협조하여야 합니다.

제 9 조 (결제 · 환불)
① 홈페이지를 통해 원하는 결제 방법으로 진행합니다.
② 고객이 서비스 개시일(브라우니 첫 방문일) 3일 전까지 계약 취소 시, ‘을’은 고객이 지불한 금액 모두를 고객에게 환불합니다.
③ 고객이 서비스 개시일(브라우니 첫 방문일) 2일 전까지 계약 취소 시, ‘을’은 고객이 지불한 금액 10%를 제외하고, 금액 모두를 고객에게 환불합니다
④ 고객이 서비스 개시일(브라우니 첫 방문일) 1일 전까지 계약 취소 시, ‘을’은 고객이 지불한 금액 10%를 제외하고, 금액 모두를 고객에게 환불합니다
⑤ 고객이 서비스 개시일(브라우니 첫 방문일) 당일, 서비스 개시 전 계약 취소는 불가하며 그럼에도 불구하고 서비스 당일 계약 취소가 강행될 시 ‘을’은 고객이 지불한 금액 10% 및 1회 출동비를 제외하고, 금액 모두를 ‘을’에게 환불합니다.
⑥ 서비스 개시 (브라우니 첫 방문) 후 고객이 계약을 해지할 시, 고객이 지불한 금액 10% 와 서비스 금액을 일할 계산하여 산출된 [1회 서비스 금액 X 서비스 일 수] 서비스 비용을 잔금 결제 금액에서 제외한 나머지 금액을 고객에게 환불합니다.

제 10 조 (고객의 해제 · 해지권)
① 고객은 해당 유료서비스 내지 상품을 전혀 사용하지 아니하였을 경우에 한하여 서비스 개시일(첫 방문일) 이전에 “회사“에 결제 취소(청약 철회)를 요청할 수 있습니다.
② 고객은 다음 각 호의 1에 해당하는 경우 계약을 해지할 수 있습니다.
1. ‘을’ 이 제공하는 서비스 내용이 약정한 내용과 다른 경우
2. ‘을’ 이 약정한 서비스를 소홀히 하고 있거나 소홀히 할 우려가 있어 30일의 기간을 정하여 약정한 서비스 제공 및 적합한 조치를 취 할 것을 서면으로 최고하였음에도 불구하고 ‘을’ 이 최고기간 내에 이행하지 아니한 경우
③ 고객은 제2항 이외의 경우에도 서비스 제공개시 후 임의로 계약을 해지할 수 있으며, 이 경우 고객은 서면 또는 ‘을’ 의 고객센터를 통하여 해지희망일 14일 전까지 ‘을’ 에게 계약 해지를 통보하여야 합니다.
④ 고객이 제1항의 청약 철회가 가능한 유료서비스 또는 상품에 대하여 청약 철회 가능한 기간을 경과하여 청약 철회를 신청하거나, 전자상거래 등에서의 소비자 보호에 관한 법률 등 기타 관계 법령에서 정한 청약 철회 제한 사유에 해당하는 경우에는 청약 철회가 제한됩니다.
⑤ 청약 철회는 고객이 서면, 전화, 전자우편 등의 방법으로 할 수 있으며, ‘을’에 의사를 표시하여 ‘을’에게 도달될 때 그 효력이 발생하고, ‘을’은 ‘갑’의 의사표시를 접수한 후 지체 없이 그 처리 결과를 ‘갑’에게 회신합니다.

제 11 조 (‘을’ 의 해제 · 해지권)
① ‘을’ 의 서비스 제공개시 예정일에 고객의 귀책사유로 서비스 제공 이 불가능하게 되는 때에는 ‘을’ 은 상당기간을 정하여 고객에게 협조를 최고하고, 이 기간이 경과한 후에도 고객이 불응하면 계약을 해제할 수 있습니다.
② 고객이 2개월 이상 이용료 등의 지급을 지체하는 경우, ‘을’ 은 상당한 기간을 정하여 서면으로 고객에게 그 이행을 최고할 수 있으며, 고객이 최고기간 내에 이를 이행하지 않으면 계약을 해지할 수 있습니다.
③ 고객의 부도, 파산, 법인의 해산 또는 청산, 사업장 폐쇄, 허가의 취소, 연락두절, 행방불명 등으로 고객이 본 계약에 따른 서비스의 대가를 지급할 수 없다고 인정되는 경우, ‘을’ 은 고객과 본 계약체결 시에 고지한 최종 연락처로 서면 또는 유선으로 통보함으로써 본 계약을 해제하거나 해지할 수 있습니다.
④ 제1항 내지 제3항의 경우에 고객이 ‘을’ 에게 사전통지 없이 부재상태에 있고, ‘을’ 이 통상적인 방법으로 고객의 소재를 알 수 없는 때에도 ‘을’ 은 계약을 해제 또는 해지할 수 있습니다.

제 12 조 (불가항력)
① 고객 또는 ‘을’ 은 다음 각 호의 사유가 발생하는 경우 즉시 상대방에 게 그 사유를 통보하여야 하며(이하, 각 호의 사유를 통보하는 당사 자를 ‘통보당사자‘, 통보를 받는 당사자를 ‘상대방‘이라 함), 당해 사유가 지속되는 기간 동안 본 계약에 따른 서비스가 정상적으로 제공되지 않더라도 ‘갑’ 또는 ‘을’ 은 상대방에 대하여 본 계약상의 의무불이행에 대한 책임을 지지 않습니다. 다만, 통보당사자는 상대방에게 즉시 통보하지 못할 불가피한 사유가 있는 경우에는 그 불가피한 사유가 종료한 후 즉시 상대방에게 통보해야 합니다.
1.     전쟁, 사변, 폭동, 교통두절, 통신마비, 천재지변 등 불가항력의 사유가 발생한 경우
2.     고객 또는 ‘을’ 의 파업, 태업, 직장 폐쇄 등의 쟁의 행위로 정상적인 서비스 제공이 불가능한 경우
3.     기타 서비스 대상 지역 및 서비스 수행에 중대한 영향을 주는 환경 등의 변화가 발생하여 ‘을’ 의 서비스 제공이 곤란한 경우
② 각 호의 사유가 해소된 경우 통보당사자는 즉시 상대방에게 서비스 재개 일시를 통보하거나 서비스 재개 요청을 하는 등의 조치를 취해 야 합니다.
③ 제1항 각 호의 사유가 1개월 이상 지속되는 경우, 통보당사자의 상대방 이 통보당사자에게 서비스의 재개와 관련하여 신속한 조치나 서비스 재개에 필요한 협조를 서면으로 최고하였음에도 불구하고 최고기간 내에 이행되지 않으면 서면 통지로써 본 계약을 해지할 수 있습니다.

제 13 조 (손해배상)
① ‘을’ 의 서비스 제공 시 고객의 시설이나 비품류에 손상을 주거나, 사용하는 소모품의 관리 부재로 인해 고객에 손해가 발생한 때에는 이를 배상해야 합니다. 다만, 고객 서비스 대상물의 시설 특성상 일반인이 알 수 없는 사안에 대해 고객이 ‘을’ 에 사전에 고지하지 않아 발생된 손해는 손해배상에서 제외됩니다.
② ‘을’ 은 서비스의 제공을 개시한 날로부터 계약기간의 만료일이나 해지일까지 전항 본문에 규정한 바와 같이 ‘을’ 의 계약불이행으로 인하여 고객에게 손해가 발생하면 ‘을’ 은 손해발생 건당 대인 1천 만원, 대물 1천 만원을 한도로 손해를 배상합니다. 다만, ‘을’ 의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.
③ 제2항에 따른 손해배상의 범위는 고객에게 직접 발생한 통상의 직접 손해에 국한되며, 특별한 사정에 의한 손해, 간접 손해, 징벌적 손해, 결과적 손해, 기대이익 등과 같거나 유사한 특별 손해는 포함되지 않습니다.
④ 본 계약 제10조에 따라 서비스의 제공이 중단된 경우에도 ‘을’ 은 고객의 손해에 대하여 배상책임을 지지 않습니다.
⑤ 본 계약 제11조에 따라 서비스가 해지된 상황에서 발생한 고객의 손해에 대하여 배상책임을 지지 않습니다.

제 14 조 (기타)
① 본 계약에서 규정하지 않은 사항은 신의성실의 원칙에 따라 회사와 고객이 합의하여 결정하되 합의되지 아니한 사항은 관계법령 및 공정 · 타당한 일반 거래 관행에 따릅니다
② 본 계약의 변경 및 수정은 ‘갑’ 과 ‘을’ 이 서면으로 합의하여야 합니다
③ 본 계약과 관련된 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.
④ 고객이 개인 맞춤 ∙ 연계 서비스 제공을 위한 개인정보의 결합 ∙ 분석 ∙ 활용에 동의하는 경우, 회사는 고객이 개인정보 및 회사, 제3자가 제공하는 다양한 서비스 이용과 관련된 정보를 결합 ∙ 분석 ∙ 활용하며, 이를 바탕으로 신상품이나 이벤트 정보안내, 설문조사 등 고객 맞춤 ∙ 연계 서비스(회사 또는 제3자의 서비스)를 제공하고, 이와 관련된 정보를 제공합니다.
⑤ 근무 시작 시간은 ‘을’ 또는 ‘근무자‘와 ‘갑’ 의 상호 협의하여 변경 가능합니다.
⑥ 근무자의 귀책사유로 인한 매장 기기, 비품 등 파손시 일상손해배상보험 처리됩니다
⑦ 매장 유형별 특화 서비스 요청 시 별도 요금이 발생할 수 있습니다.`

export type PolicySectionProps = {
  isPolicyOpen: boolean
  setIsPolicyOpen: React.Dispatch<React.SetStateAction<boolean>>
  isCouponOpen: boolean
  couponCount?: number
}

export default function PolicySection({
  isPolicyOpen,
  setIsPolicyOpen,
  isCouponOpen,
  couponCount,
}: PolicySectionProps) {
  return (
    <StyledPolicySection
      isCouponOpen={isCouponOpen}
      onClick={() => setIsPolicyOpen(!isPolicyOpen)}
      couponCount={couponCount}
    >
      <div>
        <Title isOpen={isPolicyOpen}>
          <SubTitle>서비스 계약 및 환불 정책</SubTitle>
          <Icon name="blackDownArrow" />
        </Title>
        <p>
          결제 진행 시 정책 사항에 모두 동의한 것으로 간주하여
          <br />
          정책 외 발생하는 사안에 대해서는 당사의 책임이 없음을
          <br className="mobile_br" /> 알려드립니다.
        </p>
      </div>
      <Policy isOpen={isPolicyOpen} onClick={(e) => e.stopPropagation()}>
        <pre>{policy}</pre>
      </Policy>
    </StyledPolicySection>
  )
}

const StyledPolicySection = styled.section<{ isCouponOpen: boolean; couponCount?: number }>`
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-bottom-left-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem;
  border-top: none;
  position: relative;
  cursor: pointer;
  /* transition: transform 0.3s ease-in-out; */
  /* z-index: -5; */

  ${({ isCouponOpen, couponCount }) =>
    isCouponOpen
      ? couponCount &&
        (couponCount === 1
          ? css`
              /* transform: translateY(14.4rem); */
            `
          : css`
              /* transform: translateY(${6.1 + 8 * couponCount + (couponCount - 1) * 0.8}rem); */
            `)
      : css`
          /* transform: translateY(-0.1rem); */
        `}

  @media only screen and (max-width: 768px) {
    ${({ isCouponOpen, couponCount }) =>
      isCouponOpen
        ? couponCount &&
          (couponCount === 1
            ? css`
                /* transform: translateY(10.9rem); */
              `
            : css`
                /* transform: translateY(${2.9 + 8 * couponCount + (couponCount - 1) * 0.8}rem); */
              `)
        : css`
            /* transform: translateY(0); */
          `}
  }

  & > div {
    padding: ${({ theme }) => theme.spacing(0, 4, 4)};
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom-left-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem;

    & > p {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: ${({ theme }) => theme.colors.black};
      opacity: 0.5;

      & > br.mobile_br {
        display: none;
      }
    }
  }

  ${({ theme }) => theme.media.mobile`
    & > div {
      padding: 0 16px 32px;

      & > p {
        & > br.mobile_br {
          display: inline;
        }
      }
    }
  `}
`
const Title = styled.div<{ isOpen: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > h4 {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & > svg {
    transition: transform 0.3s ease-in-out;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          & > svg {
            transform: rotate(180deg);
          }
        `
      : css`
          & > svg {
            transform: rotate(0);
          }
        `}

  ${({ theme }) => theme.media.mobile`
    & > svg {
      margin-top: 16px;
    }
  `}
`

const Policy = styled.article<{ isOpen: boolean }>`
  width: 50rem;
  height: 0;
  background-color: ${({ theme }) => theme.colors.lightGray};
  /* position: absolute; */
  /* bottom: 0; */
  /* left: -0.1rem; */
  /* z-index: -6; */
  transition: height 0.3s ease-in-out;
  overflow-y: scroll;
  cursor: default;
  border-left: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-bottom-left-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem;

  & > pre {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-family: 'Spoqa Han Sans', --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    width: 43.6rem;
    word-break: keep-all;
    white-space: pre-wrap;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          height: 51.2rem;
          padding: ${({ theme }) => theme.spacing(4)};
          ${({ theme }) =>
            theme.media.mobile`padding: 32px 16px !important`}/* transform: translateY(51.2rem) translateZ(-100px); */
        `
      : css`
          & > pre {
            color: #edeff1;
          }
          /* transform: translateY(0) translateZ(-100px); */
        `}

  ${({ theme }) => theme.media.mobile`
    width: 312px;
    padding: 0;
    border-right: 1px solid;

    & > pre {
      width: 280px;
    }
  `}

  ${({ theme }) => theme.media.mobile`border-color: ${theme.colors.lightGrayishBlue}`};
`
