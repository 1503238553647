import React from 'react'
import styled, { css } from 'styled-components'

type ButtonThemeType = 'primary' | 'secondary' | 'green' | 'disabled' | 'black' | 'warning'

export type SmallButtonProps = {
  children: React.ReactNode
  buttonTheme: ButtonThemeType
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export default function SmallButton({ children, buttonTheme, ...props }: SmallButtonProps) {
  return (
    <StyledSmallButton buttonTheme={buttonTheme} {...props}>
      {children}
    </StyledSmallButton>
  )
}

const themeToCss = (buttonTheme: ButtonThemeType) => {
  switch (buttonTheme) {
    case 'primary':
      return css`
        background-color: ${({ theme }) => theme.colors.main};
        color: ${({ theme }) => theme.colors.white};
      `
    case 'secondary':
      return css`
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.darkGrayishBlue};
        border: 0.1rem solid ${({ theme }) => theme.colors.gray};

        &:hover {
          background-color: ${({ theme }) => theme.colors.main};
          color: ${({ theme }) => theme.colors.white};
          border-color: ${({ theme }) => theme.colors.main};
        }
      `
    case 'black':
      return css`
        background-color: ${({ theme }) => theme.colors.black};
        color: ${({ theme }) => theme.colors.white};
        cursor: default;
      `
    case 'green':
      return css`
        background-color: ${({ theme }) => theme.colors.green600};
        color: ${({ theme }) => theme.colors.white};
      `
    case 'disabled':
      return css`
        background-color: ${({ theme }) => theme.colors.gray};
        color: ${({ theme }) => theme.colors.white};
        cursor: not-allowed;
      `
    case 'warning':
      return css`
        background-color: ${({ theme }) => theme.colors.warning};
        color: ${({ theme }) => theme.colors.white};
      `
  }
}

const StyledSmallButton = styled.button<{ buttonTheme: ButtonThemeType }>`
  /* padding: ${({ theme }) => theme.spacing(0, 0.5)}; */
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 0 8px;
  min-width: fit-content;

  ${({ buttonTheme }) => themeToCss(buttonTheme)}
`
