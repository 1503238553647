import React from 'react'
import styled from 'styled-components'

export type FooterButtonBoxProps = {
  children: React.ReactNode
  isWarning?: boolean
  warningText?: string
}

/**
 * 모바일에서 하단에 버튼을 고정 시 버튼들을 담는 컴포넌트
 * @param children 고정 시킬 버튼 들
 * @param isWarning 버튼에 경고가 있는지
 * @param warningText 경고 텍스트
 * @author 하규영
 */

export default function FooterButtonBox({ children, isWarning, warningText }: FooterButtonBoxProps) {
  return (
    <StyledFooterButtonBox>
      {isWarning && <p className="warning">{warningText}</p>}
      <div className="buttons">{children}</div>
    </StyledFooterButtonBox>
  )
}

const StyledFooterButtonBox = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background-color: white;
  width: 100%;
  padding: 8px 0;
  box-shadow: 0px -4px 16px rgba(30, 30, 44, 0.12);

  .buttons {
    display: flex;
    align-items: center;

    button ~ button {
      margin-left: 8px;
    }
  }

  .warning {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.warning};
    margin-bottom: 10px;
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    display: flex;
  `}
`
