import styled from 'styled-components'
import NavBar from '../../components/common/NavBar'
import AuthContent from '../../components/common/AuthContent'
import { Redirect, Route, Switch } from 'react-router'
import AuthErrorInfo from '../../components/Auth/AuthErrorInfo'
import { useRouteMatch } from 'react-router-dom'
import PwResetInfo from '../../components/Auth/PwResetInfo'
import PwResetForm from '../../components/Auth/PwResetForm'
import PwResetEmailGet from '../../components/Auth/PwResetEmailGet'
import LoginForm from '../../components/Auth/LoginForm'
import SignUpForm from '../../components/Auth/SignUpForm'
import EmailFindForm from '../../components/Auth/EmailFindForm'
import EmailFindInfo from '../../components/Auth/EmailFindInfo'
import SocialOptionForm from '../../components/Auth/SocialOptionForm'
import EmailResetInfo from 'components/Auth/EmailResetInfo'

export default function AuthPage() {
  const { url } = useRouteMatch()

  return (
    <>
      <NavBar />
      <Switch>
        <Route path={`${url}/login`} exact>
          <AuthContent paddingTop="120" title="로그인" scroll={true} center={false}>
            <LoginForm />
          </AuthContent>
        </Route>
        <Route path={`${url}/sign-up`} exact>
          <AuthContent paddingTop="120" title="회원가입" scroll={true} center={false}>
            <SignUpForm />
          </AuthContent>
        </Route>
        <Route path={`${url}/social-option`} exact>
          <AuthContent paddingTop="120" title="선택사항" scroll={true} center={false}>
            <SocialOptionForm />
          </AuthContent>
        </Route>
        <Route path={`${url}/error`} exact>
          <AuthContent paddingTop="200" title={'앗.. 오류가 \n발생했어요.'}>
            <AuthErrorInfo />
          </AuthContent>
        </Route>
        <Route path={`${url}/find/email`} exact>
          <AuthContent paddingTop="200" title="이메일 찾기">
            <EmailFindForm />
          </AuthContent>
        </Route>
        <Route path={`${url}/find/email/:masking_email`} exact>
          <AuthContent paddingTop="200" title="이메일 찾기">
            <EmailFindInfo />
          </AuthContent>
        </Route>
        <Route path={`${url}/reset/email/:email/:token`} exact>
          <AuthContent paddingTop="200" title="인증중입니다...">
            <EmailResetInfo />
          </AuthContent>
        </Route>
        <Route path={`${url}/reset/password`} exact>
          <AuthContent paddingTop="200" title="비밀번호 재설정">
            <PwResetEmailGet />
          </AuthContent>
        </Route>
        <Route path={`${url}/reset/password/info`} exact>
          <AuthContent paddingTop="200" title="비밀번호 재설정">
            <PwResetInfo />
          </AuthContent>
        </Route>
        <Route path={`${url}/reset/email/password/:email/:token`} exact>
          <AuthContent paddingTop="200" title={`재설정\n 비밀번호 입력`}>
            <PwResetForm />
          </AuthContent>
        </Route>
        <Redirect path="*" to="/auth/login" />
      </Switch>
      <AuthFooter>
        <span>COPYRIGHT © HIGHERX. Proudly created</span>
      </AuthFooter>
    </>
  )
}

const AuthFooter = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  text-align: center;

  & > span {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.media.mobile`
  display: none;
  `}
`
