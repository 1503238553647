import { fadeIn } from 'assets/styles/animation'
import useAuth from 'hooks/query/auth/useAuth'
import useLogout from 'hooks/query/auth/useLogout'
import React, { useLayoutEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ROUTES } from 'utils/common/routes'
import Icon from '../../Icon'
import Profile from '../Profile'
import NavMenuList from 'components/Landing/NavMenuList'
import NavServiceTypeListData from 'data/navServiceTypeListData.json'
import LogManager from 'lib/utils/logger'

/**
 * @param title 타이틀 제목
 * @param offsetY 스크롤 상태(0이면 최상단)
 */
type NavBarProps = {
  title?: string
  offsetY?: boolean
}

function NavBar({ title, offsetY = false }: NavBarProps) {
  const [serviceMenuListOpen, setServiceMenuListOpen] = useState<boolean>(false)
  const [myMenuListOpen, setMyMenuListOpen] = useState<boolean>(false)
  const [mobileMenuListOpen, setMobileMenuListOpen] = useState<boolean>(false)
  const [mobileSubMenuListOpen, setMobileSubMenuListOpen] = useState<boolean>(false)
  const [realName, setRealName] = useState<string>('')

  const history = useHistory()
  const { pathname } = useLocation()

  const { auth, isLogin } = useAuth()

  const { mutateAsync: mutateLogout } = useLogout()

  const handleLinkClick = (route: ROUTES, menuName: string) => {
    handleLogging(route, menuName)
    localStorage.removeItem('scrollData')
    history.push(route)
  }

  const handleLogging = (route: string, menuName: string) => {
    LogManager.Instance.sendLog('menubar', {
      menu_name: menuName,
      menu_url: route,
      page_url: pathname,
    })
  }

  const handleLogoutClick = async () => {
    await mutateLogout()
    setMyMenuListOpen(false)
    setMobileMenuListOpen(false)
    history.push(ROUTES.ROOT)
  }

  useLayoutEffect(() => {
    if (isLogin && auth?.real_name) {
      setRealName(auth.real_name)
    }
  }, [isLogin, auth])

  return (
    <>
      <MobileNavBar>
        <ButtonIcon name="mobileNavHome" onClick={() => handleLinkClick(ROUTES.ROOT, '모바일) 홈')} />
        {title && <NavTitle>{title}</NavTitle>}
        {isLogin ? (
          <MobileProfile name="sheepProfile" onClick={() => setMobileMenuListOpen(true)} />
        ) : (
          <>
            {pathname === '/auth/login' || pathname === '/auth/social-option' || pathname === '/auth/sign-up' ? null : (
              <MobileNavListIcon name="mobileNavListBlack" onClick={() => setMobileMenuListOpen(true)} />
            )}
          </>
        )}

        {mobileMenuListOpen && (
          <MenuListContainer>
            <Icon
              name="whiteNavLogo"
              onClick={() => {
                setMobileMenuListOpen(false)
                history.push(ROUTES.ROOT)
              }}
            />
            <Icon name="mobileNavClose" onClick={() => setMobileMenuListOpen(false)} />
            <MobileMenuList>
              {isLogin ? (
                <>
                  <li>
                    <Link to={ROUTES.MYPAGE} onClick={() => handleLogging(ROUTES.MYPAGE, '모바일) 마이페이지')}>
                      마이페이지
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTES.STORE} onClick={() => handleLogging(ROUTES.MYPAGE, '모바일) 서비스 신청 ・ 관리')}>
                      서비스 신청 ・ 관리
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTES.PAYMENT} onClick={() => handleLogging(ROUTES.MYPAGE, '모바일) 결제 내역')}>
                      결제 내역
                    </Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link to={ROUTES.AUTH_LOGIN} onClick={() => handleLogging(ROUTES.MYPAGE, '모바일) 로그인')}>
                    로그인
                  </Link>
                </li>
              )}
              <li onClick={() => setMobileSubMenuListOpen(!mobileSubMenuListOpen)}>
                서비스 유형
                {mobileSubMenuListOpen ? <Icon name="blackArrowUp" /> : <Icon name="WhiteArrowDown" />}
              </li>
              {mobileSubMenuListOpen && (
                <MobileServiceDetailUl>
                  {NavServiceTypeListData.map(({ title, link }, index) => (
                    <li onClick={() => setMobileMenuListOpen(false)} key={index}>
                      <Link to={link} onClick={() => handleLogging(link, `모바일) ${title}`)}>
                        {title}
                      </Link>
                    </li>
                  ))}
                </MobileServiceDetailUl>
              )}
              <li>
                <Link to={ROUTES.MAP} onClick={() => handleLogging(ROUTES.MYPAGE, '모바일) 무인매장 지도')}>
                  무인매장 지도
                </Link>
              </li>
              <li
                onClick={() => {
                  setMobileMenuListOpen(false)
                  window.open('https://higherx.notion.site/b9ccb239d9784e7692cf596c466ef795')
                }}
              >
                크루 모집
              </li>
              <li>
                <Link to={ROUTES.FAQ} onClick={() => handleLogging(ROUTES.MYPAGE, '모바일) FAQ')}>
                  FAQ
                </Link>
              </li>
              {isLogin && <li onClick={handleLogoutClick}>로그아웃</li>}
            </MobileMenuList>
          </MenuListContainer>
        )}
      </MobileNavBar>

      <StyledNavBar>
        <ButtonIcon
          name={offsetY === false ? 'whiteNavLogo' : 'MobileLandingBlueLogo'}
          onClick={() => handleLinkClick(ROUTES.ROOT, 'PC) 홈')}
        />
        <StyledNavList>
          {isLogin ? (
            <ProfileTab
              onMouseOver={(e) => {
                setMyMenuListOpen(true)
                e.stopPropagation()
              }}
            >
              <Profile />
              <span>{realName}</span>
              {offsetY ? <Icon name="blackArrowDown" /> : <Icon name="WhiteArrowDown" />}
            </ProfileTab>
          ) : (
            <>
              {pathname === '/auth/login' ||
              pathname === '/auth/social-option' ||
              pathname === '/auth/sign-up' ? null : (
                <LinkItem onClick={() => handleLinkClick(ROUTES.AUTH_LOGIN, 'PC) 로그인')}>로그인</LinkItem>
              )}
            </>
          )}
          <LinkItem
            onMouseOver={(e) => {
              setServiceMenuListOpen(true)
              e.stopPropagation()
            }}
          >
            서비스 유형 {offsetY ? <Icon name="blackArrowDown" /> : <Icon name="WhiteArrowDown" />}
          </LinkItem>
          <LinkItem onClick={() => window.open('https://www.xn--v92bo5hlpggqr.com/home')}>무인상회</LinkItem>
          <LinkItem onClick={() => handleLinkClick(ROUTES.MAP, 'PC) 무인매장 지도')}>무인매장 지도</LinkItem>
          <LinkItem onClick={() => window.open('https://higherx.notion.site/b9ccb239d9784e7692cf596c466ef795')}>
            크루 모집
          </LinkItem>
          <LinkItem onClick={() => handleLinkClick(ROUTES.FAQ, 'PC) FAQ')}>FAQ</LinkItem>
        </StyledNavList>

        {myMenuListOpen && (
          <NavMenuList right={580} setListOpen={setMyMenuListOpen}>
            <li>
              <Link to={ROUTES.MYPAGE} onClick={() => handleLogging(ROUTES.MYPAGE, 'PC) 마이페이지')}>
                마이페이지
              </Link>
            </li>
            <li>
              <Link to={ROUTES.STORE} onClick={() => handleLogging(ROUTES.STORE, 'PC) 서비스 신청 ・ 관리')}>
                서비스 신청 ・ 관리
              </Link>
            </li>
            <li>
              <Link to={ROUTES.PAYMENT} onClick={() => handleLogging(ROUTES.PAYMENT, 'PC) 결제 내역')}>
                결제 내역
              </Link>
            </li>
            <li onClick={handleLogoutClick}>로그아웃</li>
          </NavMenuList>
        )}

        {serviceMenuListOpen && (
          <NavMenuList right={440} setListOpen={setServiceMenuListOpen}>
            {NavServiceTypeListData.map(({ title, link }, index) => (
              <li key={index} onClick={() => setServiceMenuListOpen(false)}>
                <Link to={link} onClick={() => handleLogging(link, `PC) ${title}`)}>
                  {title}
                </Link>
              </li>
            ))}
          </NavMenuList>
        )}
      </StyledNavBar>
    </>
  )
}

const StyledNavBar = styled.nav`
  width: 100%;
  height: 80px;
  padding-left: 32px;
  padding-right: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000002 !important;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ProfileTab = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;

  & > span {
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > svg {
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }
`

const ButtonIcon = styled(Icon)`
  cursor: pointer;
`

const StyledNavList = styled.ul`
  display: flex;
  height: 80px;
  align-items: center;

  & > li ~ li {
    margin-left: ${({ theme }) => theme.spacing(5)};
  }
`

const MenuListContainer = styled.div`
  width: 100%;
  height: 100vh;
  animation: ${fadeIn} 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.main};
  z-index: 100000000;
  transform: translateZ(1000000px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    position: absolute;
    top: 20px;
    &:nth-of-type(1) {
      left: 16px;
    }

    &:nth-of-type(2) {
      right: 16px;
    }
  }

  @media only screen and (max-width: 768px) {
    & > svg {
      top: 8px;

      &:nth-of-type(2) {
        width: 32px;
        height: 32px;
      }
    }
  }
`

const MobileMenuList = styled.ul`
  position: absolute;
  top: 72px;
  padding: 32px 72px;
  overflow-y: scroll;
  max-height: 456px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & > li ~ li {
    margin-top: 24px;
  }

  & > li {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    & > svg {
      margin-left: 8px;
      width: 28px;
      height: 28px;

      & > path {
        stroke: ${({ theme }) => theme.colors.white};
      }
    }
  }

  & > li,
  & > li > a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    font-size: 20px;
    line-height: 28px;
    text-decoration: none;
  }
`

const LinkItem = styled.li`
  height: 50px;
  font-size: 1.6rem;
  color: white;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: white;
    text-decoration: none;
  }

  & > svg {
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
`

const MobileNavBar = styled.nav<{ backgroundColor?: string }>`
  display: none;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ backgroundColor }) => backgroundColor ?? ''};
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000001 !important;

  @media only screen and (max-width: 768px) {
    display: flex;
  }
`

const MobileNavListIcon = styled(Icon)`
  cursor: pointer;
  z-index: 20;
  top: 18px;
  right: 16px;
`

const MobileProfile = styled(Icon)`
  cursor: pointer;
`

const NavTitle = styled.span`
  color: ${({ theme }) => theme.colors.main};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`

const MobileServiceDetailUl = styled.ul`
  width: 216px;
  height: 296px;
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  padding-left: 48px;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  transition: all 0.3s ease-in-out;

  & > li {
    padding: 8px 0px;

    & > a {
      text-decoration: none;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${({ theme }) => theme.colors.white};

      &:active {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }
  }
`

export default React.memo(NavBar)
