import CalendarForm from 'components/ServicePostPage/CalendarForm'
import useServicePostPush from 'hooks/service/useServicePostPush'
import useVisitHourBottomSheet from 'hooks/service/useVisitHourBottomSheet'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import { setSchedules, setScheduleForModify } from 'modules/features/service/serviceSlice'
import { findSchedulesIndex } from 'utils/common/service'

export default function ConfirmCalendar() {
  const { pushToModify } = useServicePostPush()

  const dispatch = useAppDispatch()
  const { schedules, invalidDayFixSchedules, invalidTimeFixSchedules } = useAppSelector((state) => state.service)
  const { year: firstYear, month: firstMonth } = schedules && schedules[0]

  const [openVisitHourOptions] = useVisitHourBottomSheet()

  const fixFirstSchedule = async (year: number, month: number, date: number) => {
    const selectedVisitHour = await openVisitHourOptions({
      title: '변경할 시간을 선택해 주세요',
      year,
      month,
      date,
    })

    if (selectedVisitHour !== undefined) {
      const copiedSchedule = [...schedules]
      copiedSchedule.splice(0, 1, {
        year,
        month,
        date,
        hour: selectedVisitHour,
      })

      dispatch(setSchedules(copiedSchedule))
    }
  }

  const handleDateClick = async (year: number, month: number, date: number) => {
    const scheduleIndex = findSchedulesIndex(schedules, year, month, date)
    const fixedDayIndex = findSchedulesIndex(invalidDayFixSchedules, year, month, date)
    const fixedTimeIndex = findSchedulesIndex(invalidTimeFixSchedules, year, month, date)

    const isFixingSchedule = scheduleIndex !== -1
    const isFixingFixedDay = fixedDayIndex !== -1
    const isFixingFixedTime = fixedTimeIndex !== -1

    if (isFixingSchedule) {
      const isFirstSchedule = scheduleIndex === 0

      if (isFirstSchedule) {
        await fixFirstSchedule(year, month, date)
        return
      } else {
        dispatch(
          setScheduleForModify({
            year,
            month,
            date,
            hour: schedules[scheduleIndex].hour,
            type: 'default',
          })
        )
      }
    } else if (isFixingFixedDay)
      dispatch(
        setScheduleForModify({
          year,
          month,
          date,
          hour: invalidDayFixSchedules[fixedDayIndex].hour,
          type: 'fixedDay',
          fixedIndex: fixedDayIndex,
        })
      )
    else if (isFixingFixedTime)
      dispatch(
        setScheduleForModify({
          year,
          month,
          date,
          hour: invalidTimeFixSchedules[fixedTimeIndex].hour,
          type: 'fixedTime',
          fixedIndex: fixedTimeIndex,
        })
      )

    pushToModify()
  }

  return <CalendarForm type="confirm" firstYear={firstYear} firstMonth={firstMonth} onDateClick={handleDateClick} />
}
