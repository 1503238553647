import { FindEmailParams, getFindEmail } from 'lib/api/auth/getFindEmail'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

export default function useGetFindEmail() {
  const history = useHistory()

  return useMutation((value: FindEmailParams) => getFindEmail(value), {
    onSuccess: async (data) => {
      history.push(`/auth/find/email/${data.masking_email}`)
    },
  })
}
