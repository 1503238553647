import SmallButton from 'components/common/SmallButton'
import Toggle from 'components/common/Toggle'
import Icon from 'components/Icon'
import useAuthQuery from 'hooks/query/auth/useAuthQuery'
import usePatchMarketingAgree from 'hooks/query/auth/usePatchMarketingAgree'
import usePostSendEmailForResetPassword from 'hooks/query/authVerify/usePostSendEmailForResetPassword'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import BirthSelector from './BirthSelector'
import EmailChangeInput from './EmailChangeInput'
import RealNameChangeInput from './RealNameChangeInput'
import PhoneNumChangeInput from './PhoneNumChangeInput'

export default function MyInfo() {
  const { data: auth } = useAuthQuery({}, { refetchOnWindowFocus: true })
  const { mutateAsync: mutateSendPasswordEmail } = usePostSendEmailForResetPassword()
  const { mutateAsync: mutateMarketingAgree } = usePatchMarketingAgree()
  const [codeCopyOpen, setCodeCopyOpen] = useState<boolean>(false)
  const [emailChangeClick, setEmailChangeClick] = useState<boolean>(false)
  const [phoneChangeClick, setPhoneChangeClick] = useState<boolean>(false)
  const [birthChangeClick, setBirthChangeClick] = useState<boolean>(false)
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false)

  useEffect(() => {
    if (codeCopyOpen) {
      const timeout = setTimeout(() => (codeCopyOpen ? setCodeCopyOpen(false) : undefined), 5000)

      return () => clearTimeout(timeout)
    }
  }, [codeCopyOpen])

  const handleMarketingClick = async (marketingAgree: boolean) => {
    await mutateMarketingAgree({ marketing_agree: marketingAgree })
  }

  const handleClickResetPassword = async () => {
    if (!auth) return
    const provider = auth.provider
    if (provider === 'kakao') {
      window.open('https://accounts.kakao.com/login?continue=https%3A%2F%2Fcs.kakao.com%2F')
    } else if (provider === 'naver') {
      window.open('https://nid.naver.com/nidlogin.login?mode=form&url=https%3A%2F%2Fwww.naver.com')
    } else if (provider === 'email') {
      await mutateSendPasswordEmail({ email: auth.email })
    }
  }

  return (
    <>
      <StyledMyInfo>
        <ul>
          {emailChangeClick ? (
            <EmailChangeInput onOutsideClick={() => setEmailChangeClick(false)} />
          ) : (
            <li>
              <h5>이메일</h5>
              <span>:</span>
              <p>{auth?.email}</p>
              <SmallButton buttonTheme="primary" onClick={() => setEmailChangeClick(true)}>
                변경
              </SmallButton>
            </li>
          )}

          {(!isMobile || isInfoOpen) && (
            <>
              <RealNameChangeInput realName={auth?.real_name as string} />
              {birthChangeClick ? (
                <BirthSelector onOutsideClick={() => setBirthChangeClick(false)} />
              ) : (
                <li>
                  <h5>생년월일</h5>
                  <span>:</span>
                  {auth?.birth_year ? (
                    <p>
                      {auth?.birth_year}.{auth?.birth_day?.slice(0, 2)}.{auth?.birth_day?.slice(2)}
                    </p>
                  ) : (
                    <p />
                  )}
                  <SmallButton buttonTheme="primary" onClick={() => setBirthChangeClick(true)}>
                    변경
                  </SmallButton>
                </li>
              )}
              {phoneChangeClick ? (
                <PhoneNumChangeInput onOutsideClick={() => setPhoneChangeClick(false)} />
              ) : (
                <li>
                  <h5>휴대폰 번호</h5>
                  <span>:</span>
                  <p>
                    {auth?.phone.slice(0, 3)}-{auth?.phone.slice(3, 7)}-{auth?.phone.slice(7)}
                  </p>
                  <SmallButton buttonTheme="primary" onClick={() => setPhoneChangeClick(true)}>
                    변경
                  </SmallButton>
                </li>
              )}
              <li>
                <h5>비밀번호</h5>
                <span>:</span>
                <p>
                  {auth?.provider === 'kakao' ? 'Kakao 가입' : auth?.provider === 'naver' ? 'Naver 가입' : '********'}
                </p>
                <SmallButton buttonTheme="primary" onClick={handleClickResetPassword}>
                  재설정
                </SmallButton>
              </li>
              <li>
                <h5>성별</h5>
                <span>:</span>
                <p>{auth?.gender === 'M' ? '남성' : auth?.gender === 'F' ? '여성' : ''}</p>
              </li>
              <li className="marketing">
                <h5>
                  마케팅 정보 이메일/SMS
                  <br /> 수신 동의(이벤트/혜택 안내)
                </h5>
                <Toggle defaultStatus={auth?.marketing_agree !== null} onChange={handleMarketingClick} />
              </li>
            </>
          )}
          {isMobile && (
            <OpenInfoButton onClick={() => setIsInfoOpen(!isInfoOpen)}>
              <Icon name={isInfoOpen ? 'grayMinus' : 'grayOption'} />
            </OpenInfoButton>
          )}
        </ul>
      </StyledMyInfo>
    </>
  )
}

const StyledMyInfo = styled.section`
  width: 100%;

  ul {
    width: 100%;
    padding: 32px;

    li {
      width: 320px;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 16px;

      h5,
      & > span {
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.black};
        line-height: 24px;
        opacity: 0.5;
      }

      h5 {
        width: 86px;
        height: 24px;
      }

      & > p {
        margin-left: 8px;
        width: 149px;
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.black};
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    li ~ li {
      margin-top: 8px;
    }

    .verify {
      p {
        display: flex;
        align-items: center;

        svg {
          margin-left: 8px;
          width: 24px;
          height: 24px;
        }
      }
    }

    .marketing {
      height: 72px;
      display: flex;
      justify-content: space-between;
      h5 {
        width: 232px;
        height: 48px;
      }
    }
  }

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    ul {
      padding: 32px 16px;

      li {
        width: 280px;
        
        h5 {
          min-width: 82px;
          width: 82px;
        }

        & > p {
          width: 114px;
        }
      }

    .marketing {
      height: 72px;
      h5 {
        width: 192px;
        height: 48px;
      }
    }
    }
  `}
`

const OpenInfoButton = styled.button`
  width: 280px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  margin-top: 8px;
`
