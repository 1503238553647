import Icon from 'components/Icon/Icon'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

type TooltipProps = {
  triggerContent: React.ReactNode
  tooltipContent: React.ReactNode
}
export default function Tooltip({ triggerContent, tooltipContent }: TooltipProps) {
  const [open, setOpen] = useState<boolean>(true)

  useEffect(() => {
    const timeout = setTimeout(() => setOpen(false), 5000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Container>
      <Trigger onClick={() => setOpen(!open)}>
        {triggerContent}
        <Icon name="grayInfoLine" />
      </Trigger>
      <TooltipContainer open={open}>{tooltipContent}</TooltipContainer>
      <TooltipArrow open={open}>
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.31268 1.65151C6.09858 0.416512 7.90142 0.416509 8.68732 1.65151L14 10H0L5.31268 1.65151Z"
            fill="#212121"
          />
        </svg>
      </TooltipArrow>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
`

const Trigger = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > svg {
    margin-left: 8px;
    cursor: pointer;
  }

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
`

const TooltipContainer = styled.div<{ open: boolean }>`
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  min-width: 200px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 16px;
  color: #e8e8e8;
  background-color: #212121;
  transform: translateY(90px);
  border-radius: 8px;

  text-align: left;
  font-size: 14px;
  line-height: 24px;
  transition: all 0.2s ease-in-out;
`

const TooltipArrow = styled.div<{ open: boolean }>`
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  position: absolute;
  bottom: 0px;
  right: 10px;
  transform: translate3d(0px, 14px, 0px);
  transition: all 0.2s ease-in-out;
`
