import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { BottomCTA, BottomSheet, Snackbar, Top } from 'ohds-web'

import usePostBvs from 'hooks/query/bvs/usePostBvs'
import usePostService from 'hooks/query/service/usePostService'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import {
  resetModifiedSchedule,
  ScheduleType,
  selectCompletedSchedules,
  selectServiceWeeklyCount,
} from 'modules/features/service/serviceSlice'

import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'

import BrwnieMainContainer from 'components/common/BrwnieMainContainer'
import ConfirmCalendar from 'components/ServicePostPage/ConfirmPage/ConfirmCalendar'
import { convertScheduleToTimezone } from 'utils/common/service'
import useAuth from 'hooks/query/auth/useAuth'
import usePostBillingReservationMutation from 'hooks/query/service/usePostBillingReservationMutation'

let tempModifiedSchedule: ScheduleType | undefined

export default function ConfirmSchedulePage() {
  const history = useHistory()
  const { auth } = useAuth()
  const { storeId } = useParams<{ storeId: string }>()

  const dispatch = useAppDispatch()
  const { serviceWeek, modifiedSchedule, serviceHour, serviceDayValues, servicePrice, serviceTimeMinute } =
    useAppSelector((state) => state.service)

  if (modifiedSchedule) tempModifiedSchedule = modifiedSchedule

  const completedSchedules = useAppSelector(selectCompletedSchedules)
  const serviceWeeklyCount = useAppSelector(selectServiceWeeklyCount)

  const { mutateAsync: mutatePostService } = usePostService()
  const { mutateAsync: mutatePostBillingReservation } = usePostBillingReservationMutation()
  const { mutateAsync: mutatePostBvs } = usePostBvs()

  const [subscribePaymentModalOpen, setSubscribePaymentModalOpen] = useState<boolean>(false)

  const getTimeFormatter = (time: 9 | 10 | 11 | 19 | 20 | 21 | undefined) => {
    if (time && time < 10) return '0' + time + ':00:00'
    return (time ?? '00') + ':00:00'
  }

  const applyService = async (isSubscribePayment: boolean = false) => {
    if (!serviceWeek) return

    const bvs = await mutatePostBvs({
      reservation_dates: completedSchedules
        .map((schedule) => convertScheduleToTimezone(schedule))
        .sort((a, b) => +new Date(a) - +new Date(b)),
      bonus_dates: [],
      cash_management_dates: [],
    })

    const { year: lastYear, month: lastMonth, date: lastDate } = completedSchedules[completedSchedules.length - 1]

    if (servicePrice === undefined) {
      throw new Error('서비스 가격 값이 undefined 입니다.')
    }

    if (serviceTimeMinute === undefined) {
      throw new Error('서비스 이용 시간 값 중 분 값이 undefined 입니다.')
    }

    const service = await mutatePostService({
      total_week_count: serviceWeek || 0,
      service_price: servicePrice,
      bonus_count: 0,
      weekly_count: serviceWeeklyCount,
      bvs_id: bvs.id,
      store_id: storeId,
      end_date: convertScheduleToTimezone({ year: lastYear, month: lastMonth, date: lastDate, hour: 9 }),
      cash_management_price: 0,
      service_time_minute: serviceTimeMinute,
      // 프론트에서는 0을 월요일, 6을 일요일로 관리 중
      // 서버에서는 1일 일요일로하여 일월화수목금토를 1234567로 하고 있어서 아래와 같이 변환함
      day_of_week: serviceDayValues.map((dayNum) => (dayNum === 6 ? 1 : dayNum + 2)).join(''),
      service_start_time: getTimeFormatter(serviceHour),
      is_regular_payment: isSubscribePayment,
    })

    if (isSubscribePayment && service) {
      await mutatePostBillingReservation({ service_id: service.id })
    }

    LogManager.Instance.sendLog('tag', '일정예약', LOGGER.KAKAO)
    LogManager.Instance.sendLog(
      'service_post_complete',
      {
        user_id: auth ? auth.id.toString() : 'NULL',
        store_id: storeId,
        service_id: service.id,
        service_term: serviceWeek || 0,
        service_daysaweek: serviceWeeklyCount,
        service_totalnumber: completedSchedules.length,
        service_startdate: `${completedSchedules[0].year}-${completedSchedules[0].month}-${completedSchedules[0].date}`,
        service_enddate: `${lastYear}-${lastMonth}-${lastDate}`,
        service_dates: completedSchedules.join(','),
        service_price: servicePrice,
        price: servicePrice,
        currency: 'KRW',
      },
      LOGGER.GTM
    )

    // dispatch(initServiceState())

    history.push({
      pathname: `/service-payment/${service.id}`,
      state: { isConfirm: true },
    })
  }

  return (
    <BrwnieMainContainer>
      <Top
        firstTitle={
          serviceWeek === 12 || serviceWeek === 24 ? '서비스 신청 일정을 확인해주세요' : '이대로 서비스를 신청할까요?'
        }
        titleFont={{
          fontSize: 'h3',
        }}
        description={
          serviceWeek === 12 || serviceWeek === 24
            ? '관리일이 공휴일인 경우,\n선택하신 요일로 서비스 일정이 자동 연장됩니다.'
            : '선택된 날짜를 클릭하면 다른 날로 변경 할 수 있어요'
        }
        descriptionFont={{
          fontSize: 'c2',
        }}
        paddingTop={40}
      />

      <ConfirmCalendar />

      <StyledBottomSheet
        open={subscribePaymentModalOpen}
        title="📣 4주 패키지 상품은 자동 결제가 가능해요!"
        onClose={() => setSubscribePaymentModalOpen(false)}
        BottomCTA={
          <StyledBottomCTA
            primaryProps={{
              label: '자동 결제',
              onClick: async () => {
                await applyService(true)
              },
            }}
            secondaryProps={{
              label: '이번만 결제',
              soft: 'mono',
              onClick: async () => {
                await applyService()
              },
            }}
          />
        }
      >
        <SubscribePaymentContainer>
          <SubscribePaymentDescription>
            <Title>자동 결제란?</Title>
            <Description>4주에 한 번씩 동일한 스케줄로 자동 결제 되는 상품을 말해요!</Description>
          </SubscribePaymentDescription>
          <NoticeContainer>
            <NoticeList>
              <li>마지막 관리일 7일 전, 선택해주신 일정(요일과 시간)과 동일하게 스케줄이 생성돼요.</li>
              <li>자동 결제는 마지막 관리일 5일 전 이루어져요.</li>
              <li>결제에 실패할 경우, 마지막 출동일 3일 전 재결제 시도가 이루어져요.</li>
              <li>서비스 패키지 변경(일정)을 원하실 경우, 자동 결제일 하루 전까지 연락주시면 변경을 도와드려요.</li>
              <li>재결제 시도 실패, 자동 결제 해지 및 스케줄 수정은 고객센터에 문의주시면 진행을 도와드려요.</li>
            </NoticeList>
          </NoticeContainer>
        </SubscribePaymentContainer>
      </StyledBottomSheet>

      <Snackbar
        open={!!modifiedSchedule}
        firstLine={`${tempModifiedSchedule?.month}월 ${tempModifiedSchedule?.date}일 ${tempModifiedSchedule?.hour}시로 변경했어요`}
        onClose={() => dispatch(resetModifiedSchedule())}
        buttonLabel="확인"
        onButtonClick={() => dispatch(resetModifiedSchedule())}
      />

      <StyledBottomCTA
        linear
        primaryProps={{
          label: '이대로 신청할게요',
          onClick: async () => {
            if (serviceWeek === 4) {
              setSubscribePaymentModalOpen(true)
              return
            }

            await applyService()
          },
        }}
      />
    </BrwnieMainContainer>
  )
}

const StyledBottomSheet = styled(BottomSheet)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    position: fixed;
    left: 50%;
  }
`

const StyledBottomCTA = styled(BottomCTA)`
  @media only screen and (min-width: 768px) {
    width: 50vw !important;
    left: 50%;
  }
`

const SubscribePaymentContainer = styled.div`
  padding: 0px 24px;
  margin-top: 16px;
`

const SubscribePaymentDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  background: #eef4ff;
  border-radius: 12px;
`

const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #212121;
`
const Description = styled.p`
  margin-top: 12px;
  line-height: 28px;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
`

const NoticeContainer = styled.div`
  padding: 16px;
`
const NoticeText = styled.p`
  color: var(--gray-gray-600, #757575);
  font-size: 14px;
  font-weight: 700;

  line-height: 24px;
`

const NoticeList = styled.ul`
  margin: 16px auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
  list-style: disc;
  /* margin-inline-start: 22px; */
`
