import Icon from 'components/Icon'
import React, { useRef } from 'react'
import styled, { css } from 'styled-components'

export type StartStepAccordionProps = {
  title: string
  step: number
  isOpen: boolean
  setIsOpen: (step: number) => void
} & React.HTMLAttributes<HTMLDivElement>

export default function StartStepAccordion({ title, step, isOpen, setIsOpen, children }: StartStepAccordionProps) {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <AccordionWrapper open={isOpen}>
      <div onClick={() => setIsOpen(step)}>
        <div className="titleWrapper">
          <span className="step">{`${step}단계`}</span>
          <span className="title">{title}</span>
        </div>
        <Icon name="blackArrowDown" />
      </div>
      <div
        ref={ref}
        style={
          isOpen && ref.current
            ? {
                maxHeight: ref.current.scrollHeight + 24,
              }
            : undefined
        }
      >
        {children}
      </div>
    </AccordionWrapper>
  )
}

const AccordionWrapper = styled.div<{
  open: boolean
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  background-color: var(--gray0);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0px 16px;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    height: 56px;

    .step {
      width: 46px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 28px;
      font-weight: bold;
      color: var(--gray800);
      margin-right: 8px;
    }

    .title {
      font-size: 14px;
      line-height: 24px;
      color: var(--gray800);
      font-weight: 400;
    }
  }

  & > div:first-child {
    & > svg {
      transition: transform 0.2s ease-in-out;

      & > path {
        stroke: var(--gray500);
      }
    }
  }
  & > div:last-child {
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    & > img {
      display: flex;
      width: calc(100% - 32px);
      margin: 8px auto;
      min-height: 118px;
    }

    & > div {
      padding: 0px 16px;
      font-size: 13px;
      line-height: 22px;
      color: var(--gray700);
    }
  }
  ${({ open }) =>
    open &&
    css`
      & > div:first-child {
        & > svg {
          transform: rotate(180deg);
        }
      }
      & > div:last-child {
        max-height: unset;
        padding-bottom: 24px;
      }
    `}
`
