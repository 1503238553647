import Icon from 'components/Icon'
import { BottomCTA, BottomSheet } from 'ohds-web'
import { memo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import BrwnieCrewIntroduceModal from '../BrwnieCrewIntroduceModal'
import { Step } from './data/Step'
import StartStepAccordion from './StartStepAccordion'

type CardStateType = {
  step: number
  isDetailShow: boolean
}

const cardStatusArr: CardStateType[] = [
  {
    step: 1,
    isDetailShow: false,
  },
  {
    step: 2,
    isDetailShow: isMobile ? true : false,
  },
  {
    step: 3,
    isDetailShow: false,
  },
  {
    step: 4,
    isDetailShow: false,
  },
  {
    step: 5,
    isDetailShow: false,
  },
]

function LandingBrwnieStartSection() {
  const [cardStatus, setCardStatus] = useState<CardStateType[]>(cardStatusArr)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const handleMobileAccordionChange = (step: number) => {
    setCardStatus(
      cardStatus.map((data) =>
        data.step === step ? { ...data, isDetailShow: !data.isDetailShow } : { ...data, isDetailShow: false }
      )
    )
  }
  return (
    <LandingBrwnieStartSectionWrapper>
      <TitleWrapper>
        <h2>브라우니를 믿고 맡길 수 있는 이유, 첫번째</h2>
        <span>체계적인&nbsp;</span>
        <h1>관리 프로세스</h1>
      </TitleWrapper>
      <PcCardUl>
        {Step.map(({ step, title, pcImgSrc, description }, _i) => (
          <PcCardLi
            key={_i}
            onMouseOver={() =>
              setCardStatus(cardStatus.map((data) => (data.step === step ? { ...data, isDetailShow: true } : data)))
            }
            onMouseLeave={() =>
              setCardStatus(cardStatus.map((data) => (data.step === step ? { ...data, isDetailShow: false } : data)))
            }
          >
            <img className="coverImage" src={pcImgSrc} alt="무인매장 관리" />
            {cardStatus[_i].step === step && cardStatus[_i].isDetailShow && (
              <>
                <h3>{title}</h3>
                <h5>{description}</h5>
              </>
            )}
            {cardStatus[_i].step === step && !cardStatus[_i].isDetailShow && (
              <>
                <h4>{`${step}단계`}</h4>
                <h3>{title}</h3>
                <span className="detailShow">자세히 보기</span>
              </>
            )}
          </PcCardLi>
        ))}
      </PcCardUl>

      <MobileAccordionWrapper>
        {Step.map(({ step, title, mobileImgSrc, description }, _i) => (
          <StartStepAccordion
            key={_i}
            title={title}
            step={step}
            isOpen={cardStatus[_i].isDetailShow}
            setIsOpen={handleMobileAccordionChange}
          >
            <img src={mobileImgSrc} alt="무인매장 브라우니" />
            <div>{description}</div>
          </StartStepAccordion>
        ))}
      </MobileAccordionWrapper>

      <BrwnieCrewText onClick={() => setModalOpen(true)}>
        브라우니 크루는 어떻게 선정되나요?
        <Icon name="GrayRightArrow" />
      </BrwnieCrewText>
      {modalOpen && <BrwnieCrewIntroduceModal open={modalOpen} onClose={() => setModalOpen(false)} />}
      <StyledBottomSheet
        title={'브라우니의 믿음직한 요원들,\n크루(Crew)를 소개합니다'}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        BottomCTA={<BottomCTA primaryProps={{ label: '네, 확인했어요', onClick: () => setModalOpen(false) }} />}
      >
        <img className="titleImage" src={'/image/브라우니_무인매장_브라우니크루소개_m.png'} alt="무인매장 브라우니" />
        <p>
          <b>브라우니 크루는 5단계의 과정을 거쳐 채용된 인원들</b>로 실무를 기반으로 한&nbsp;
          <b>브라우니만의 전문적인 교육 프로세스를 통해 투입되는 무인매장 관리의 프로</b>들입니다.
        </p>
        <ProcessWrapper>
          <p className="title">크루 채용 Process</p>
          <ul>
            <li>
              <span>1단계</span>
              <span>서류 전형, 심층 인터뷰</span>
            </li>
            <li>
              <span>2단계</span>
              <span>이론 교육</span>
            </li>
            <li>
              <span>3단계</span>
              <span>동행 실습</span>
            </li>
            <li>
              <span>4단계</span>
              <span>
                <b>크루 합류</b>
              </span>
            </li>
            <li>
              <span>5단계</span>
              <span>정기 교육</span>
            </li>
          </ul>
        </ProcessWrapper>
      </StyledBottomSheet>
    </LandingBrwnieStartSectionWrapper>
  )
}

export default memo(LandingBrwnieStartSection)

const LandingBrwnieStartSectionWrapper = styled.section`
  width: 100%;
  padding: 120px 0px;

  background-color: var(--gray0);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    padding: 80px 0px;
    justify-content: flex-start;
  }
`

const TitleWrapper = styled.div`
  text-align: center;

  & > span {
    font-weight: 700;
    font-size: 52px;
    line-height: 65px;
    color: var(--gray800);
    display: inline-block;
  }

  & > h1 {
    display: inline-block;
    font-weight: 700;
    font-size: 52px;
    line-height: 65px;
    color: var(--gray800);
  }

  & > h2 {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    display: flex;
    justify-content: center;
    color: var(--brand300);
  }

  @media only screen and (max-width: 768px) {
    & > span {
      font-size: 30px;
      line-height: 40px;
      display: block;
    }

    & > h1 {
      font-size: 30px;
      line-height: 40px;
    }

    & > h2 {
      margin: 0px auto 4px;
      font-size: 14px;
      line-height: 24px;
    }
  }
`

const BrwnieCrewText = styled.h4`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  cursor: pointer;

  display: flex;
  align-items: center;
  text-align: center;
  color: var(--gray600);
  text-decoration: underline;

  & > svg {
    margin-left: 4px;
  }

  @media only screen and (max-width: 768px) {
    width: 230px;
    justify-content: center;
    margin-top: 24px;
    font-size: 13px;
    line-height: 22px;

    & > svg {
      margin-left: 0px;
    }
  }
`

const PcCardUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  width: 1200px;
  height: 750px;

  margin-top: 60px;
  margin-bottom: 40px;

  & > li:nth-of-type(2) {
    margin: 0px 12px;
  }

  & > li:nth-of-type(4) {
    margin-top: 12px;
    margin-right: 12px;
  }

  & > li:nth-of-type(5) {
    margin-top: 12px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const PcCardLi = styled.li`
  position: relative;
  width: 328px;
  height: 360px;
  border-radius: 24px;
  overflow: hidden;

  .coverImage {
    width: 100%;
    height: auto;
    object-fit: contain;
    transition: all ease-out 0.15s;
  }

  .detailShow {
    display: block;
    position: absolute;
    bottom: 32px;
    left: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    color: var(--gray600);
  }

  & > h5 {
    display: block;
    width: 264px;
    position: absolute;
    top: 108px;
    left: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: var(--gray700);
  }

  & > h4 {
    position: absolute;
    top: 32px;
    left: 24px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray600);
  }

  & > h3 {
    white-space: pre;
    position: absolute;
    top: 64px;
    left: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--gray800);
  }

  &:nth-of-type(3) {
    background-color: var(--gray800);
    & > h4 {
      color: var(--gray400);
    }

    & > h3 {
      color: var(--gray0);
    }

    .detailShow {
      color: var(--gray400);
    }

    & > h5 {
      color: var(--gray0);
    }
  }

  &:hover {
    .coverImage {
      opacity: 0.3;
      filter: blur(4px);
    }

    & > h3 {
      white-space: pre;
      position: absolute;
      top: 32px;
      left: 24px;
    }

    &:nth-of-type(3) {
      .coverImage {
        opacity: 0.5;
      }
    }
  }
`

const MobileAccordionWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
  }
`

const StyledBottomSheet = styled(BottomSheet)`
  display: none;
  header {
    white-space: pre;
  }

  & > div {
    max-height: 40vh;
    img {
      width: calc(100% - 32px);
      display: flex;
      margin: 12px auto 8px;
    }

    p {
      padding: 0px 16px;
      word-break: keep-all;
      font-size: 14px;
      line-height: 24px;
      color: var(--gray700);
    }
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const ProcessWrapper = styled.div`
  margin: 0 auto;
  margin-top: 24px;
  width: calc(100% - 32px);
  height: 228px;
  background-color: var(--gray100);
  border-radius: 20px;
  padding: 12px 16px 20px;
  box-sizing: border-box;

  .title {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray800);
    margin-bottom: 8px;
  }

  & > ul {
    width: 100%;
    margin: 0 auto;
    height: 164px;
    display: flex;
    flex-direction: column;

    & > li {
      width: inherit;
      height: 28px;
      display: flex;
      align-items: center;
      background-color: var(--gray0);
      border-radius: 99px;
      margin-bottom: 6px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      span {
        display: flex;
        flex-direction: row;

        &:nth-of-type(1) {
          padding-left: 16px;
          padding-right: 12px;
          font-weight: 700;
          font-size: 12px;
          line-height: 21px;
          color: var(--brand400);
        }

        &:nth-of-type(2) {
          padding-left: 4px;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: var(--gray700);
        }
      }
    }
  }
`
