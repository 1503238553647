import { DefaultTheme, css } from 'styled-components'
import { DEFAULT_FONTSIZE } from './global-styles'
import { Media } from './styled'

const SPACING_MULTIPLE = 8

const calcRem = (px: number) => `${px / DEFAULT_FONTSIZE}rem`

const spacing = (num1: number, num2?: number, num3?: number, num4?: number) => {
  const calcSpacing = (number: number) => number * SPACING_MULTIPLE

  if (typeof num2 === 'undefined') {
    return `${calcRem(calcSpacing(num1))}`
  } else if (typeof num3 === 'undefined') {
    return `${calcRem(calcSpacing(num1))} ${calcRem(calcSpacing(num2))}`
  } else if (typeof num4 === 'undefined') {
    return `${calcRem(calcSpacing(num1))} ${calcRem(calcSpacing(num2))} ${calcRem(calcSpacing(num3))}`
  } else {
    return `${calcRem(calcSpacing(num1))} ${calcRem(calcSpacing(num2))} ${calcRem(calcSpacing(num3))} ${calcRem(
      calcSpacing(num4)
    )}`
  }
}

const sizes: { [key: string]: number } = {
  mobile: 768,
}

type BackQuoteArgs = string[]

const media: Media = {
  mobile: (...args: BackQuoteArgs) => undefined,
}

Object.keys(sizes).reduce((acc: Media, label: string) => {
  switch (label) {
    case 'mobile':
      acc.mobile = (...args: BackQuoteArgs) =>
        css`
          @media only screen and (max-width: ${sizes.mobile}px) {
            ${args}
          }
        `
      break
    default:
      break
  }
  return acc
}, media)

const theme: DefaultTheme = {
  colors: {
    main: '#003399',
    warning: '#D32F2F',
    naver: '#57B04B',
    kakao: '#FFE812',
    white: '#FFFFFF',
    black: '#1E1E2C',
    lightGray: '#F8F8F8',
    gray: '#B1B1B5',
    gray200: '#EEEEEE',
    gray300: '#E0E0E0',
    gray500: '#787880',
    darkGray: '#333333',
    lightGrayishBlue: '#EDEFF1',
    darkGrayishBlue: '#343441',
    orange: '#F3AC13',
    softBlue: '#85C4E7',
    lightBlue50: '#E1F5FE',
    skyBlue: '#64D2FF',
    green600: '#43A047',
    indigoA700: '#3D5AFE',
    purple400: '#9C27B0',
    pink: '#F50057',
    blue: '#007CFF',
    yellow: '#FEDE00',
  },
  spacing: spacing,
  media,
  width: {
    mobile: '312px',
  },
}

export { theme }
