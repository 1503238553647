import { useState } from 'react'
import styled from 'styled-components'

import { AnswerModel } from './answerModel'
import { setRow } from '../../lib/utils/googleSheetUtils'
import { ReactComponent as BrwnieLogo } from './brwnie-logo.svg'
import { ReactComponent as BrwnieLogoMobile } from './brwnie-logo-mobile.svg'
import { ReactComponent as MuinFirmLogo } from './muin-firm-logo.svg'
import { ReactComponent as MuinFirmLogoMobile } from './muin-firm-logo-mobile.svg'
import { ReactComponent as CloseIcon } from './interface-close.svg'
import { ReactComponent as CloseIconMobile } from './interface-close-mobile.svg'

import useQueryParamHook from 'hooks/useQueryParamHook'
import ProgressBar from 'components/common/ApplyComponents/ProgressBar'
import NextButton from 'components/common/ApplyComponents/NextButton'
import RadioButton from 'components/common/ApplyComponents/RadioButton'
import CheckButton from 'components/common/ApplyComponents/CheckButton'
import PersonalPolicyCheck from 'components/common/ApplyComponents/PersonalPolicyCheck'
import Introduce from 'components/common/ApplyComponents/Introduce'
import TextInput from 'components/common/ApplyComponents/TextInput'
import { Container, Title, Description, Content } from 'components/common/ApplyComponents/Layout/Layout'
import Textarea from 'components/common/ApplyComponents/Textarea'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const funnelRadioValues = ['브라우니 홈페이지', '무인상회 홈페이지', '인스타', '유튜브', '블로그', '기타 (직접 입력)']
const investmentRadioValues = ['350만원 (냉장)', '500만원 (냉동)', '850만원 (냉장 + 냉동)', '1200만원 (맞춤제작)']
const spaceRadioValues = ['네', '아니오', '설치 가능한 공간인지 잘 모르겠어요']
const ageRadioValues = ['20대', '30대', '40대', '50대', '60대 이상']
const jobRadioValues = ['회사원', '전문직', '주부님', '건물주', '자영업자', '기타 (직접 입력)']
const timeRadioValues = ['9시', '10시', '11시', '1시', '2시', '3시', '4시', '5시']

const regionObj: {
  [region: string]: string[]
} = {
  서울특별시: [
    '강남구',
    '강동구',
    '강북구',
    '강서구',
    '관악구',
    '광진구',
    '구로구',
    '금천구',
    '노원구',
    '도봉구',
    '동대문구',
    '동작구',
    '마포구',
    '서대문구',
    '서초구',
    '성동구',
    '성북구',
    '송파구',
    '양천구',
    '영등포구',
    '용산구',
    '은평구',
    '종로구',
    '중구',
    '중랑구',
  ],
  인천광역시: ['강화군', '계양구', '남동구', '동구', '미추홀구', '부평구', '서구', '연수구', '옹진군', '중구'],
  대전광역시: ['대덕구', '동구', '서구', '유성구', '중구'],
  대구광역시: ['남구', '달서구', '달성군', '동구', '북구', '서구', '수성구', '중구'],
  광주광역시: ['광산구', '남구', '동구', '북구', '서구'],
  울산광역시: ['남구', '동구', '북구', '울주군', '중구'],
  부산광역시: [
    '강서구',
    '금정구',
    '기장군',
    '남구',
    '동구',
    '동래구',
    '부산진구',
    '북구',
    '사상구',
    '사하구',
    '서구',
    '수영구',
    '연제구',
    '영도구',
    '중구',
    '해운대구',
  ],
  세종특별자치시: ['세종특별자치시'],
  경기도: [
    '가평군',
    '고양시 덕양구',
    '고양시 일산동구',
    '고양시 일산서구',
    '과천시',
    '광명시',
    '광주시',
    '구리시',
    '군포시',
    '김포시',
    '남양주시',
    '동주천시',
    '부천시',
    '성남시 분당구',
    '성남시 수정구',
    '성남시 중원구',
    '수원시 권선구',
    '수원시 영통구',
    '수원시 장안구',
    '수원시 팔달구',
    '시흥시',
    '안산시 단원구',
    '안산시 상록구',
    '안성시',
    '안양시 동안구',
    '안양시 만안구',
    '양주시',
    '양평군',
    '여주시',
    '연천군',
    '오산시',
    '용인시 기흥구',
    '용인시 수지구',
    '용인시 처인구',
    '의왕시',
    '의정부시',
    '이천시',
    '파주시',
    '평택시',
    '포천시',
    '하남시',
    '화성시',
  ],
  충청북도: [
    '괴산군',
    '단양군',
    '보은군',
    '영동군',
    '옥천군',
    '음성군',
    '제천시',
    '증평군',
    '진천군',
    '청주시 상당구',
    '청주시 서원구',
    '청주시 청원구',
    '청주시 흥덕구',
    '충주시',
  ],
  충청남도: [
    '계룡시',
    '공주시',
    '금산군',
    '논산시',
    '당진시',
    '보령시',
    '부여군',
    '서산시',
    '서천군',
    '아산시',
    '예산군',
    '천안시 동남구',
    '천안시 서북구',
    '청양군',
    '태안군',
    '홍성군',
  ],
  전라남도: [
    '강진군',
    '고흥군',
    '곡성군',
    '광양시',
    '구례군',
    '나주시',
    '담양군',
    '목포시',
    '무안군',
    '보성군',
    '순천시',
    '신안군',
    '여수시',
    '영광군',
    '영암군',
    '완도군',
    '장성군',
    '장흥군',
    '진도군',
    '함평군',
    '해남군',
    '화순군',
  ],
  경상북도: [
    '경산시',
    '경주시',
    '고령군',
    '구미시',
    '군위군',
    '김천시',
    '문경시',
    '봉화군',
    '상주시',
    '성주군',
    '안동시',
    '영덕군',
    '영양군',
    '영주시',
    '영천시',
    '예천군',
    '울릉군',
    '울진군',
    '의성군',
    '청도군',
    '청송군',
    '칠곡군',
    '포항시 남구',
    '포항시 북구',
  ],
  경상남도: [
    '거제시',
    '거창군',
    '고성군',
    '김해시',
    '남해군',
    '밀양시',
    '사천시',
    '산청군',
    '양산시',
    '의령군',
    '진주시',
    '창녕군',
    '창원시 마산합포구',
    '창원시 마산회원구',
    '창원시 성산구',
    '창원시 의창구',
    '창원시 진해구',
    '통영시',
    '하동군',
    '함안군',
    '함양군',
    '합천군',
  ],
  강원특별자치도: [
    '강릉시',
    '고성군',
    '동해시',
    '삼척시',
    '속초시',
    '양구군',
    '양양군',
    '영월군',
    '원주시',
    '인제군',
    '정선군',
    '철원군',
    '춘천시',
    '태백시',
    '평창군',
    '홍천군',
    '화천군',
    '횡성궁',
  ],
  전북특별자치도: [
    '고창군',
    '군산시',
    '김제시',
    '남원시',
    '무주군',
    '부안군',
    '순창군',
    '완주군',
    '익산시',
    '임실군',
    '장수군',
    '전주시 덕진구',
    '전주시 완산구',
    '정읍시',
    '진안군',
  ],
  제주특별자치도: ['서귀포시', '제주시'],
}

export default function ConsultingPage() {
  const query = useQueryParamHook()
  const history = useHistory()
  const fromBrwnie = query.get('fromBrwnie')

  const [currentStep, setCurrentStep] = useState<number>(0)

  const [nameValue, setNameValue] = useState<string>('')
  const [funnel, setFunnel] = useState<string>(funnelRadioValues[0])
  const [funnelTextValue, setFunnelTextValue] = useState<string>('')
  const [investment, setInvestment] = useState<string>(investmentRadioValues[0])
  const [space, setSpace] = useState<string>('')
  const [spaceTextValue, setSpaceTextValue] = useState<string>('')
  const [storeRegion, setStoreRegion] = useState<string>('')
  const [storeSigungu, setStoreSigungu] = useState<string[]>([])
  const [livingRegion, setLivingRegion] = useState<string>('')
  const [livingSigungu, setLivingSigungu] = useState<string>('')
  const [age, setAge] = useState<string>(ageRadioValues[0])
  const [job, setJob] = useState<string>(jobRadioValues[0])
  const [jobTextValue, setJobTextValue] = useState<string>('')
  const [phoneValue, setPhoneValue] = useState<string>('010')
  const [time, setTime] = useState<string>('')
  const [policyCheck, setPolicyCheck] = useState<boolean>(false)

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const handleSubmit = async () => {
    const currentTime = new Date()

    const answerData: AnswerModel = {
      이름: nameValue,
      전화번호: phoneValue,
      '원하는 상담시간대': time,
      연령대: age,
      직업: job.includes('기타') ? jobTextValue : job,
      거주지: livingRegion + ' ' + livingSigungu,
      '설치 공간 보유 유무': space === '네' ? '있음' : space === '아니오' ? '없음' : '문의',
      '설치 가능한지 문의한 공간': space.includes('설치') ? spaceTextValue : '',
      '설치 공간 위치': space === '네' ? `지역: ${storeRegion} / 시군구: ${storeSigungu.join(',')}` : '',
      '추천 받고 싶은 위치': space === '아니오' ? `지역: ${storeRegion} / 시군구: ${storeSigungu.join(',')}` : '',
      '투자 금액': investment,
      '무인상회 접한 경로': funnel.includes('기타') ? funnelTextValue : funnel,
      '신청 시간': currentTime.toLocaleString(),
      '사이트 유입 경로': !!fromBrwnie ? '브라우니' : '무인상회',
    }

    setSubmitLoading(true)

    await setRow('무인상회', answerData)

    try {
      await axios.post(
        'https://hooks.slack.com/services/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',
        {
          text: `무인 상회 AI 박스 상담 신청이 들어왔어요!\n${JSON.stringify(answerData)
            .replace('{', '')
            .replace('}', '')
            .replace(/,/g, '\n')
            .replace(/"/g, '')
            .replace(/:/g, ': ')}`,
        },
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
        }
      )
    } catch (error) {
      console.log(error)
    }

    setSubmitLoading(false)
    window.alert('상담 신청이 완료되었어요!')

    history.push('/')
  }

  if (currentStep === 0) {
    return <Introduce onCTAClick={() => setCurrentStep(currentStep + 1)} />
  }

  return (
    <Container>
      <LogoContainer>
        <BrwnieLogo />
        <CloseIcon />
        <MuinFirmLogo />

        <BrwnieLogoMobile />
        <CloseIconMobile />
        <MuinFirmLogoMobile />
      </LogoContainer>

      <ProgressBar
        totalStep={9}
        currentStep={currentStep}
        onPreviousClick={() => {
          setCurrentStep(currentStep - 1)
        }}
      />

      {currentStep === 1 ? (
        <>
          <Title id="title">
            안녕하세요👋
            <br />
            상담 신청자님의 이름은 무엇인가요?
          </Title>

          <Content>
            <TextInput
              autoFocus
              value={nameValue}
              onChange={(e) => setNameValue(e.currentTarget.value)}
              type="text"
              placeholder="이름"
            />
          </Content>

          <NextButton
            disabled={nameValue === ''}
            onClick={() => {
              setCurrentStep(currentStep + 1)
            }}
          />
        </>
      ) : currentStep === 2 ? (
        <>
          <Title id="title">
            소자본 창업 브랜드 <MuinFirmTitle>무인상회</MuinFirmTitle>는<br /> 어떻게 접하셨나요?
          </Title>

          <Content>
            <FunnelRadioButtonContainer>
              {funnelRadioValues.map((value) => (
                <RadioButton key={value} active={funnel === value} onClick={() => setFunnel(value)} label={value}>
                  {funnel === value && value.includes('기타') && (
                    <RadioButtonTextInput
                      type="text"
                      autoFocus
                      placeholder="직접 입력해주세요"
                      value={funnelTextValue}
                      onChange={(e) => setFunnelTextValue(e.currentTarget.value)}
                    />
                  )}
                </RadioButton>
              ))}
            </FunnelRadioButtonContainer>
          </Content>

          <NextButton
            disabled={funnel.includes('기타') && funnelTextValue === ''}
            onClick={() => {
              setCurrentStep(currentStep + 1)
            }}
          />
        </>
      ) : currentStep === 3 ? (
        <>
          <Title id="title">
            소자본 창업 <MuinVendingTitle>무인자판기</MuinVendingTitle>
            <br />
            투자는 얼마나 가능하신가요?
          </Title>
          <Description>
            투자 비용에 따라 자판기 구성이 달라져요
            <br />
            상담을 위한 사전 질문이니 부담없이 선택해주세요
          </Description>

          <Content>
            <InvestmentRadioButtonContainer>
              {investmentRadioValues.map((value) => (
                <RadioButton
                  key={value}
                  active={investment === value}
                  onClick={() => setInvestment(value)}
                  label={value}
                />
              ))}
            </InvestmentRadioButtonContainer>
          </Content>

          <NextButton onClick={() => setCurrentStep(currentStep + 1)} />
        </>
      ) : currentStep === 4 ? (
        <>
          <Title id="title">
            무인 자판기를 설치할 공간이
            <br />
            있으신가요?
          </Title>
          <Description>
            탁구장, 헬스장, PC방, 찜질방, 무인 카페, 스터디 카페, 파티룸,
            <br />
            숙박업소, 노래방 등<br />
            공간만 있다면 어디든 설치가 가능해요!
          </Description>

          <Content>
            <SpaceRadioButtonContainer>
              {spaceRadioValues.map((value) => (
                <RadioButton key={value} active={space === value} onClick={() => setSpace(value)} label={value} />
              ))}
            </SpaceRadioButtonContainer>

            {space.includes('설치') && (
              <div
                style={{
                  marginTop: 16,
                }}
              >
                <Textarea
                  value={spaceTextValue}
                  onChange={(e) => setSpaceTextValue(e.currentTarget.value)}
                  placeholder="공간에 대해 자유롭게 적어주세요"
                />
              </div>
            )}
          </Content>

          <NextButton
            disabled={space === '' || (space.includes('설치') && spaceTextValue === '')}
            onClick={() => setCurrentStep(currentStep + 1)}
          />
        </>
      ) : currentStep === 5 ? (
        <>
          <Title id="title">
            {space === '아니오' ? '설치 시 추천 받고 싶은 곳은 어디인가요?' : '설치할 공간의 위치는 어디인가요?'}
          </Title>
          <Content>
            {storeRegion === '' ? (
              <RegionRadioButtonContainer>
                {Object.keys(regionObj).map((region) => (
                  <RadioButton
                    key={region}
                    active={false}
                    onClick={() => {
                      setStoreRegion(region)
                      setStoreSigungu([])
                    }}
                    label={region}
                  />
                ))}
              </RegionRadioButtonContainer>
            ) : (
              <>
                <RegionWithButton>
                  <RadioButton active={true} onClick={() => setStoreRegion('')} label={storeRegion} />

                  <NextButton
                    disabled={!storeRegion.includes('세종') && storeSigungu.length === 0}
                    onClick={() => setCurrentStep(currentStep + 1)}
                  />
                </RegionWithButton>

                {!storeRegion.includes('세종') && (
                  <SigunguCheckButtonContainer>
                    {regionObj[storeRegion].map((value) => (
                      <CheckButton
                        active={storeSigungu.includes(value)}
                        onClick={() => {
                          const index = storeSigungu.findIndex((findingValue) => findingValue === value)

                          if (index === -1) {
                            storeSigungu.push(value)
                          } else {
                            storeSigungu.splice(index, 1)
                          }

                          setStoreSigungu([...storeSigungu])
                        }}
                        label={value}
                      />
                    ))}
                  </SigunguCheckButtonContainer>
                )}
              </>
            )}
          </Content>
        </>
      ) : currentStep === 6 ? (
        <>
          <Title id="title">현재 거주지는 어디신가요?</Title>
          <Description>
            만일의 경우에 무인 자판기 설치를
            <br />
            거주지 근처로 추천드리기 위한 목적이에요
          </Description>
          <Content>
            {livingRegion === '' ? (
              <RegionRadioButtonContainer>
                {Object.keys(regionObj).map((region) => (
                  <RadioButton
                    key={region}
                    active={false}
                    onClick={() => {
                      setLivingRegion(region)
                      setLivingSigungu('')
                    }}
                    label={region}
                  />
                ))}
              </RegionRadioButtonContainer>
            ) : (
              <>
                <RegionWithButton>
                  <RadioButton active={true} onClick={() => setLivingRegion('')} label={livingRegion} />

                  <NextButton
                    disabled={!livingRegion.includes('세종') && livingSigungu === ''}
                    onClick={() => setCurrentStep(currentStep + 1)}
                  />
                </RegionWithButton>

                {!livingRegion.includes('세종') && (
                  <SigunguCheckButtonContainer>
                    {regionObj[livingRegion].map((value) => (
                      <RadioButton
                        active={livingSigungu === value}
                        onClick={() => {
                          setLivingSigungu(value)
                        }}
                        label={value}
                        forRegion
                      />
                    ))}
                  </SigunguCheckButtonContainer>
                )}
              </>
            )}
          </Content>
        </>
      ) : currentStep === 7 ? (
        <>
          <Title id="title">
            거의 다 왔어요!
            <br />
            {nameValue}님의 연령대는 어떻게 되시나요?
          </Title>
          <Description>{nameValue}님에게 딱 맞는 맞춤 상담을 위한 질문이에요</Description>
          <Content>
            <AgeRadioButtonContainer>
              {ageRadioValues.map((value) => (
                <RadioButton key={value} active={value === age} onClick={() => setAge(value)} label={value} />
              ))}
            </AgeRadioButtonContainer>
          </Content>

          <NextButton onClick={() => setCurrentStep(currentStep + 1)} />
        </>
      ) : currentStep === 8 ? (
        <>
          <Title id="title">
            마지막으로 {nameValue}님의 직업은
            <br />
            어떻게 되시나요?
          </Title>
          <Description>{nameValue}님에게 딱 맞는 맞춤 상담을 위한 질문이에요</Description>
          <Content>
            <JobRadioButtonContainer>
              {jobRadioValues.map((value) => (
                <RadioButton key={value} active={value === job} onClick={() => setJob(value)} label={value}>
                  {job === value && value.includes('기타') && (
                    <RadioButtonTextInput
                      type="text"
                      autoFocus
                      placeholder="직접 입력해주세요"
                      value={jobTextValue}
                      onChange={(e) => setJobTextValue(e.currentTarget.value)}
                    />
                  )}
                </RadioButton>
              ))}
            </JobRadioButtonContainer>
          </Content>

          <NextButton
            disabled={job.includes('기타') && jobTextValue === ''}
            onClick={() => setCurrentStep(currentStep + 1)}
          />
        </>
      ) : (
        <>
          <Title id="title">
            연락처와 원하시는 상담 시간을
            <br />
            남겨주세요
          </Title>
          <Description>남겨주신 소중한 답변을 기반으로 상담 요청드리겠습니다</Description>
          <Content>
            <PhoneInputContainer>
              <TextInput
                autoFocus
                value={phoneValue}
                onChange={(e) => {
                  setPhoneValue(e.currentTarget.value)
                }}
              />

              <PersonalPolicyCheck check={policyCheck} onClick={() => setPolicyCheck(!policyCheck)} />
            </PhoneInputContainer>

            <TimeRadioButtonContainer>
              {timeRadioValues.map((value) => (
                <RadioButton key={value} active={value === time} onClick={() => setTime(value)} label={value} />
              ))}
            </TimeRadioButtonContainer>
          </Content>

          <NextButton
            disabled={policyCheck === false || phoneValue === '' || time === ''}
            last
            loading={submitLoading}
            onClick={async () => {
              await handleSubmit()
            }}
          />
        </>
      )}
    </Container>
  )
}

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 72px;

  & > :nth-child(2) {
    margin: 0 8px;
  }

  & > :nth-child(4),
  & > :nth-child(5),
  & > :nth-child(6) {
    display: none;
  }

  ${({ theme }) => theme.media.mobile`
      margin-top: 40px;

      & > :nth-child(1),
      & > :nth-child(2),
      & > :nth-child(3) {
        width: 0;
      }

      & > :nth-child(4),
      & > :nth-child(5),
      & > :nth-child(6) {
        display: block;
      }

      & > :nth-child(5) {
        margin: 0 4px;
      }
  `}
`

const RadioButtonTextInput = styled.input`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.072px;
  color: #212121;
  background: transparent;
  border: none;
  width: 100%;

  &::placeholder {
    color: #8e8e8e;
  }

  ${({ theme }) => theme.media.mobile`
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.056px;
  `}
`

const MuinFirmTitle = styled.h1`
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.034px;
  color: #f5d741;
  display: inline;

  ${({ theme }) => theme.media.mobile`
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.022px;
  `}
`

const FunnelRadioButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 200px);
  gap: 16px 12px;

  & > :last-child {
    grid-column: 1 / span 2;
  }

  ${({ theme }) => theme.media.mobile`
    grid-template-columns: repeat(2, 160px);
  `}
`

const MuinVendingTitle = styled.h1`
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.034px;
  color: #083994;
  display: inline;

  ${({ theme }) => theme.media.mobile`
    font-size: 22px;
    line-height: 20px;
    letter-spacing: -0.022px;
  `}
`

const InvestmentRadioButtonContainer = styled.div`
  width: 320px;

  & > button + button {
    margin-top: 16px;
  }

  ${({ theme }) => theme.media.mobile`
    width: 260px;
  `}
`

const SpaceRadioButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 200px);
  gap: 16px 12px;

  & > :last-child {
    grid-column: 1 / span 2;
  }

  ${({ theme }) => theme.media.mobile`
    grid-template-columns: repeat(2, 160px);
  `}
`

const RegionRadioButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 200px);
  gap: 16px 12px;

  ${({ theme }) => theme.media.mobile`
    grid-template-columns: repeat(2, 160px);
  `}
`

const SigunguCheckButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 184px);
  gap: 12px 8px;

  ${({ theme }) => theme.media.mobile`
    grid-template-columns: repeat(2, 160px);
  `}
`

const AgeRadioButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 200px);
  gap: 16px 12px;

  & > :last-child {
    grid-column: 1 / span 2;
  }

  ${({ theme }) => theme.media.mobile`
    grid-template-columns: repeat(2, 160px);
  `}
`

const JobRadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > button {
    width: 320px;
  }

  & > button + button {
    margin-top: 16px;
  }

  ${({ theme }) => theme.media.mobile`
    & > button {
      width: 260px;
    }
  `}
`

const TimeRadioButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 200px);
  gap: 16px 12px;

  ${({ theme }) => theme.media.mobile`
    grid-template-columns: repeat(2, 160px);
  `}
`

const PhoneInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
`

const RegionWithButton = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;

  & > button:first-child {
    width: 200px;
    margin-right: 120px;
    margin-left: auto;
  }

  ${({ theme }) => theme.media.mobile`
    justify-content: center;
    margin-bottom: 32px;

    & > button:first-child {
      width: 160px;
      margin-left: unset;
      margin-right: 12px;
    }
  `}
`
