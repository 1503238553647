import styled, { css } from 'styled-components'
import { ReactComponent as CheckIcon } from './interface-check.svg'

interface PersonalPolicyCheckProps {
  check: boolean
  onClick: () => void
}

export default function PersonalPolicyCheck({ check, onClick }: PersonalPolicyCheckProps) {
  return (
    <>
      <Container onClick={onClick}>
        <Checkbox check={check}>{check && <CheckIcon />}</Checkbox>
        <Label>계속하시면 개인정보처리방침에 동의하게 됩니다.</Label>
      </Container>

      <Policy>
        개인정보의 수집 및 이용에 대한 동의를 거부할 권리가 있습니다.
        <br />
        다만, 이러한 개인정보 수집 이용에 동의하지 않을 경우
        <br />
        상담 서비스 제공이 불가능합니다.
        <br />
        <br />
        수집항목 : 고객명, 전화번호
        <br />
        목적 : 서비스 상담 등 거래관계의 설정
        <br />
        이용 및 보관기간 : 수집된 개인 정보는 정보 기입 후 6개월 간 보관됩니다.
      </Policy>
    </>
  )
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;

  ${({ theme }) => theme.media.mobile`
    margin-top: 8px;
  `}
`

const Checkbox = styled.span<{ check: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid #e1e1e1;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ check }) =>
    check &&
    css`
      border: none;
      background-color: #083994;
    `}
`

const Label = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.056px;
  color: #212121;

  ${({ theme }) => theme.media.mobile`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.036px;
  `}
`

const Policy = styled.span`
  margin-top: 8px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.033px;
  color: #585858;
`
