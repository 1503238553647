import styled from 'styled-components'

const Textarea = styled.textarea`
  width: 412px;
  height: 160px;
  border-radius: 16px;
  background-color: #f6f6f6;
  padding: 12px 16px;
  border: none;
  resize: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.072px;
  color: #212121;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #8e8e8e;
  }

  ${({ theme }) => theme.media.mobile`
    height: 80px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.056px;
    width: 332px;
  `}
`

export default Textarea
