import { Button } from 'ohds-web'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ROUTES } from 'utils/common/routes'
import { UTM_URL } from 'utils/common/utmUrl'

export default function ServiceApplyTriggerSection() {
  const history = useHistory()

  return (
    <SectionWrapper>
      <ContentWrapper>
        <h2>귀찮았던 매장 관리,</h2>
        <h1>
          브라우니에 맡기고
          <br />
          매출만 관리하세요
        </h1>
        <div>
          <CustomButton
            size="large"
            label="지금 바로 상담 시작"
            fill="brand"
            rightIconName="LineArrowRight"
            style={{ height: 60 }}
            onClick={() => history.push(ROUTES.EASY_CONSULTING + UTM_URL.LANDING_CONSULTING_TRIGGER)}
          />
        </div>
      </ContentWrapper>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.section`
  width: 100%;
  height: 440px;
  background-color: var(--brand100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 768px) {
    height: 382px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;

  & > h2 {
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 16px;
  }

  & > h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--gray800);
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px) {
    & > h1 {
      width: 240px;
      font-size: 26px;
      line-height: 36px;
      word-break: keep-all;
    }

    & > h2 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }
`

const CustomButton = styled(Button)`
  margin-top: 52px;
  width: 328px;

  &:hover {
    background-color: var(--brand500);
  }

  @media only screen and (max-width: 768px) {
    width: calc(100% - 32px);
    margin-top: 32px;
    margin-bottom: 8px;
  }
`
