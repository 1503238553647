import styled from 'styled-components'
import { useState } from 'react'

import Header from 'components/common/Header'
import Footer from 'components/common/Footer'
import Paginator from 'components/Paginator'

import useServicesPaymentsQuery from 'hooks/query/service/useServicesPaymentsQuery'
import useAuthQuery from 'hooks/query/auth/useAuthQuery'
import { useScrollGet } from 'hooks/useScrollGet'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'utils/common/routes'
import PaymentHistoryLoading from 'components/PaymentHistoryPage/PaymentHistoryLoading/PaymentHistoryLoading'
import PaymentHistoryCard from 'components/PaymentHistoryPage/PaymentHistoryCard/PaymentHistoryCard'
import ServiceApplyBanner from 'components/PaymentHistoryPage/ServiceApplyBanner/ServiceApplyBanner'
import PaymentHistoryFilter from 'components/PaymentHistoryPage/FilterComponent/PaymentHistoryFilter'

export default function PaymentHistoryPage() {
  const history = useHistory()
  const [period, setPeriod] = useState('')
  const [totalWeekCount, setTotalWeekCount] = useState('')
  const [state, setState] = useState('')
  const [page, setPage] = useState(1)

  const { totalOffsetY } = useScrollGet({})

  const { data: auth } = useAuthQuery({}, { retry: 0 })
  const { data: services, isLoading: isServicesLoading } = useServicesPaymentsQuery({
    userId: auth?.id as number,
    period: period.trim(),
    product: '',
    totalWeekCount: totalWeekCount === '5' ? '4' : totalWeekCount.trim(),
    state: state.trim(),
    page,
    isRegularPayment: totalWeekCount === '5' ? true : totalWeekCount === '4' ? false : undefined,
    history: true,
  })

  const perPage = 10
  const maxPage = Math.ceil((services?.count || 0) / perPage)

  const goServiceApply = () => {
    history.push(ROUTES.STORE)
  }

  const handleChangeWeekCount = (totalWeekCount: string) => {
    setTotalWeekCount(totalWeekCount)
    setPage(1)
  }

  const handleChangeState = (state: string) => {
    setState(state)
    setPage(1)
  }

  const handleChangePeriod = (totalWeekCount: string) => {
    setPeriod(totalWeekCount)
    setPage(1)
  }

  return (
    <>
      <Header title="결제 내역" offsetY={totalOffsetY} />
      <Container>
        <ServiceApplyBanner />
        <PaymentHistoryFilter
          product={totalWeekCount}
          setProduct={handleChangeWeekCount}
          period={period}
          setPeriod={handleChangePeriod}
          state={state}
          setState={handleChangeState}
        />
        <ServiceHistoryListContainer>
          {isServicesLoading && <PaymentHistoryLoading />}
          {!isServicesLoading && (services?.data || []).length === 0 && (
            <NoServicesText>결제 내역이 없어요.</NoServicesText>
          )}
          {!isServicesLoading && (services?.data || []).length > 0 && (
            <HistoryList>
              {(services?.data || []).slice(0, 10).map((service, index) => (
                <PaymentHistoryCard key={service.id + index} service={service} />
              ))}
            </HistoryList>
          )}
        </ServiceHistoryListContainer>

        <MobileCTAWrap>
          <InfoText>브라우니를 부르고 싶다면?</InfoText>
          <MobileCTAButton onClick={goServiceApply}>서비스 신청하기</MobileCTAButton>
        </MobileCTAWrap>

        {maxPage > 1 ? <Paginator maxPage={maxPage} onChanged={(page: number) => setPage(page)} page={page} /> : <></>}
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.main`
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 0px;

  display: flex;
  flex-direction: column;
`

const ServiceHistoryListContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`

const HistoryList = styled.div`
  margin-top: 24px;

  ${({ theme }) => theme.media.mobile`
    padding: 16px;
  `}

  & > div {
    margin-bottom: 8px;
  }
`

const NoServicesText = styled.span`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  opacity: 0.5;
`

const MobileCTAWrap = styled.div`
  position: fixed;
  bottom: 32px;
  right: 16px;

  visibility: hidden;
  ${({ theme }) => theme.media.mobile`
    visibility: visible
  `}
`

const InfoText = styled.span`
  border-radius: 9999px;
  background: var(--gray-gray-800, #212121);
  backdrop-filter: blur(4px);
  color: var(--white, #fff);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  padding: 0px 16px;

  width: max-content;
  position: absolute;
  top: -10px;
  right: 18%;
  z-index: 2;
`

const MobileCTAButton = styled.button`
  width: 246px;
  height: 56px;
  padding: 0px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 28px;
  background: var(--navy-navy-300, #3d5afe);

  box-shadow: 0px 16px 16px -8px rgba(61, 90, 254, 0.24);

  color: var(--white, #fff);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  cursor: pointer;
`
