import client from '../client'
import { ServiceType } from './types'

export async function postService(req: PostServiceReq) {
  const response = await client.post<ServiceType>(`/service-api/v1/service`, req)

  return response.data
}

export type PostServiceReq = {
  weekly_count: number
  total_week_count: number
  bonus_count: number
  service_price: number
  end_date: string
  bvs_id: number
  store_id: string
  cash_management_price: number
  service_time_minute: number | null
  day_of_week: string
  service_start_time: string
  is_regular_payment: boolean
}
