import React from 'react'
import styled from 'styled-components'

export type CardProp = {
  // header?: React.ReactNode,
  // content?: React.ReactNode
  className?: string
  children?: React.ReactNode
}

export default function Card({ className, children }: CardProp) {
  return <CardWrapper className={className}>{children}</CardWrapper>
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-radius: 1.6rem;
  overflow: hidden;
`

export const CardHeader = styled.div<{ alignItems?: string; justifyContent?: string }>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.alignItems ?? 'center'};
  justify-content: ${(props) => props.justifyContent ?? 'left'};
  width: 100%;
  padding: ${({ theme }) => theme.spacing(3, 4)};
  background-color: ${({ theme }) => theme.colors.lightGray};

  h2 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.8rem;
    margin-left: 1.6rem;
  }
  .count {
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 4rem;
    margin-left: 1.6rem;
  }

  ${({ theme }) => theme.media.mobile`
    padding: ${theme.spacing(2, 3)}
  `}
`

export const CardContent = styled.div<{ direction?: string; padding?: number; wrap?: string }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'row'};
  flex-wrap: ${(props) => props.wrap ?? 'wrap'};
  width: 100%;
  padding: ${({ theme, padding }) => theme.spacing(padding ?? 4)};

  .no-store {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 21.2rem;
    border: 0.1rem solid ${({ theme }) => theme.colors.lightGrayishBlue};
    border-radius: 1.6rem;
    margin: ${({ theme }) => theme.spacing(2)};
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 2.4rem;
    opacity: 0.5;
  }

  ${({ theme }) => theme.media.mobile`
    padding: ${theme.spacing(2)}
  `}
`
