import { MapStoreListType } from 'lib/api/map/types'
import { mapStoreData } from './storeType'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'

/**
 * @description 클러스터 스타일 생성 함수
 * @param cvs 캔버스
 * @param radius 반지름
 * @param count 마커 개수
 * @param name 마커 이름
 * @returns 마커
 */
export function changeMarker(cvs: HTMLCanvasElement, radius: number, count: number) {
  let fontSize
  if (isMobile) {
    fontSize = '14px Arial'
  } else {
    fontSize = '18px Arial'
  }
  cvs.width = radius * 2
  cvs.height = radius * 2
  let ctx = cvs.getContext('2d')
  let y: number

  if (ctx) {
    // 원 표시
    ctx.beginPath()
    ctx.arc(radius, radius, radius, 0, Math.PI * 2)
    ctx.fillStyle = 'rgba(0, 51, 153, 0.8)' //#0385ff
    ctx.fill()

    ctx.closePath()

    // 텍스트 표시
    ctx.textAlign = 'center'
    ctx.fillStyle = 'white' // text color
    ctx.textBaseline = 'middle' // 텍스트가 1줄일 경우 사용
    if (isMobile) {
      y = radius
    } else {
      y = radius + 2
    }

    if (count != null) {
      ctx.font = fontSize
      ctx.fillText(String(count), radius, y)
    }
  }

  return {
    content: cvs,
    anchor: new naver.maps.Point(radius, radius),
  }
}

//
/**
 * @description 마커 생성 함수
 * @param radius 반지름
 * @param count 마커 개수
 * @param name 마커 이름
 * @returns 클러스터 최종 반지름
 */
export function newMarker(radius: number, count: number) {
  let cvs = document.createElement('canvas')

  return changeMarker(cvs, radius, count)
}

export function getRadius(count: number) {
  let r = 50

  switch (true) {
    case count < 10:
      r = 30
      break
    case count < 50:
      r = 35
      break
    case count < 100:
      r = 40
      break
    case count < 200:
      r = 45
      break
    case count < 500:
      r = 50
      break
    case count < 1000:
      r = 55
      break
    default:
      r = 55
  }

  if (isMobile) {
    r = 30
  }

  return r
}

/**
 * @description 업종별 마커 이미지파일명 설정
 * @param category
 * @param brwnieYn
 * @returns 이미지 이름
 */
export function checkStoreType(category: string) {
  switch (category) {
    case '프린트카페':
      return 'self-print-marker'
    case '셀프빨래방':
      return 'self-laundry-marker'
    case '아이스크림할인점':
      return 'icecream-marker'
    case '스터디카페':
      return 'study-cafe-marker'
    case '무인편의점':
      return 'convenience-store-marker'
    case '셀프스튜디오':
      return 'self-studio-marker'
    case '무인카페':
      return 'cafe-marker'
    case '스마트자판기':
      return 'machine-shop-marker'
    case '밀키트':
      return 'meal-kit-marker'
    case '무인문방구':
    case '무인라면':
    case '무인과일':
    case '무인펫샵':
    case '기타':
      return 'etc-marker'
    default:
      return 'brwnie-marker'
  }
}

/**
 * @description 일반매장 마커 배열 생성 함수
 * @param data 마커 데이터
 * @returns 마커 배열
 */
export function getMarkerList(data: MapStoreListType[]) {
  let markers: naver.maps.Marker[] = []

  data.forEach((item) => {
    let spot = item,
      latlng = new naver.maps.LatLng(spot.latitude, spot.longitude)
    // 브라우니 사용매장이면 마커생성 취소
    if (spot.brwnie_yn === true) {
      return
    }
    let marker = new naver.maps.Marker({
      position: latlng,
      draggable: false,
      icon: {
        content: '<img src="/icon/' + checkStoreType(spot.category) + '.png" alt = "" width = "30px" height = "39px"/>',
        size: new naver.maps.Size(30, 39),
        scaledSize: new naver.maps.Size(30, 39),
      },
      title: spot.category,
    })

    markers.push(marker)
  })

  return markers
}

/**
 * @description 매장목록 필터링 함수
 * @param data 매장 정보 데이터 배열
 * @param filterState 필터상태 객체
 * @returns 필터링된 결과 배열
 */
export function getStoreListFilter(data: MapStoreListType[], filterState: any) {
  let cloneState = _.cloneDeep(filterState)
  delete cloneState.brwnieState

  //경우 1) 브라우니 이용매장만 보기 X + 나머지 상태 모두 체크(디폴트)
  if (!filterState.brwnieState && Object.keys(cloneState).every((key) => cloneState[key] === true)) {
    return data
  }
  //경우 2) 브라우니 이용매장만 보기 O + 나머지 상태 모두 체크
  if (filterState.brwnieState && Object.keys(filterState).every((key) => filterState[key] === true)) {
    return data.filter((item) => item.brwnie_yn === true)
  }

  //경우 3) 브라우니만 보기 + 나머지 모드 체크 해제
  if (filterState.brwnieState && Object.keys(cloneState).every((key) => cloneState[key] === false)) {
    return []
  }

  //경우 4) 전체 안보기
  if (Object.keys(filterState).every((key) => filterState[key] === false)) {
    return []
  }

  // 경우 5) 브라우니 매장만 보기 O + 일부체크
  if (filterState.brwnieState && Object.keys(cloneState).filter((key) => cloneState[key] === true)) {
    let filteringArr: string[] = []
    let resultDataList: MapStoreListType[] = []

    // 선택하지 않은 업종항목
    const getStoreTypeStatus = Object.keys(cloneState).filter((key) => cloneState[key] === true)

    getStoreTypeStatus.forEach((item) => {
      const sameCategoryName = mapStoreData.find((data) => data.enCategory === item)
      if (sameCategoryName) {
        filteringArr.push(sameCategoryName.category)
      }
    })

    data.forEach((item) => {
      if (filteringArr.includes(item.category)) {
        resultDataList.push(item)
      }
    })

    // 브라우니 매장만 보기 필터링
    resultDataList = resultDataList.filter((item) => item.brwnie_yn === true)

    return resultDataList
  }

  // 경우 6) 브라우니 매장만 보기 X + 일부체크
  if (!filterState.brwnieState && Object.keys(cloneState).filter((key) => cloneState[key] === true)) {
    let filteringArr: string[] = []
    let resultDataList: MapStoreListType[] = []

    // 선택하지 않은 업종항목
    const getStoreTypeStatus = Object.keys(cloneState).filter((key) => cloneState[key] === true)

    getStoreTypeStatus.forEach((item) => {
      const sameCategoryName = mapStoreData.find((data) => data.enCategory === item)
      if (sameCategoryName) {
        filteringArr.push(sameCategoryName.category)
      }
    })

    data.forEach((item) => {
      if (filteringArr.includes(item.category)) {
        resultDataList.push(item)
      }
    })

    return resultDataList
  }

  return []
}

/**
 * @description 일반 매장 마커 배열들을 매장 유형에 따라 필터링해주는 함수
 * @param markerWhole 일반 매장 마커 배열
 * @param cloneData 매장 유형 필터링
 * @returns 필터링 결과 마커 배열
 */
export function filterGeneralMarker(markerWhole: naver.maps.Marker[], cloneData: any) {
  // 전체선택
  if (Object.keys(cloneData).every((key) => cloneData[key] === true)) {
    return markerWhole
  }

  let filteringArr: string[] = []
  let resultMarkerList: naver.maps.Marker[] = []

  const getStoreTypeStatus = Object.keys(cloneData).filter((key) => cloneData[key] === true)

  getStoreTypeStatus.forEach((item) => {
    const sameCategoryName = mapStoreData.find((data) => data.enCategory === item)
    if (sameCategoryName) {
      filteringArr.push(sameCategoryName.category)
    }
  })

  markerWhole.forEach((item) => {
    if (filteringArr.includes(item.getTitle())) {
      resultMarkerList.push(item)
    }
  })

  return resultMarkerList
}

/**
 * @description 브라우니 이용매장만 표시해주는 함수
 * @param data 마커 데이터
 * @returns 마커 배열
 */
export function getBrwnieMarkerList(data: MapStoreListType[], myMap: naver.maps.Map) {
  let markerWidth = 60,
    markerHeight = 36
  if (isMobile) {
    markerWidth = 63
    markerHeight = 38
  }
  let markerList: naver.maps.Marker[] = []

  data.forEach((item) => {
    const latlng = new naver.maps.LatLng(item.latitude, item.longitude)
    // 브라우니 사용매장이면 마커생성 취소

    let marker = new naver.maps.Marker({
      position: latlng,
      map: myMap,
      icon: {
        content: `<img src="/icon/brwnie-marker.png" alt = "" width="${markerWidth}px" height="${markerHeight}px"/>`,
        size: new naver.maps.Size(markerWidth, markerHeight),
        scaledSize: new naver.maps.Size(markerWidth, markerHeight),
      },
      zIndex: 2,
      title: item.category,
    })
    markerList.push(marker)
  })

  return markerList
}

export function getClickMarkerEvent(storeList: MapStoreListType[], lat: number, lng: number) {
  const result = storeList.find((item) => lat === item.latitude && lng === item.longitude)
  return result
}

/**
 * @description 클러스터 이벤트 함수
 * @param clusterMarker 클러스터 된 마커 집합
 * @param count 클러스터 개수
 * @param name  클러스터 이름
 */
export function clusterStylingFunction(clusterMarker: any, count: number, name: string) {
  const radius = getRadius(count)
  // 클러스터 마커 표시
  clusterMarker.setIcon(newMarker(radius, count))

  // 클러스터 마커 이벤트 설정
  naver.maps.Event.addListener(clusterMarker, 'mouseover', () => {
    const cvs = clusterMarker.getIcon().content
    clusterMarker.setIcon(changeMarker(cvs, radius + 10, count))
  })

  naver.maps.Event.addListener(clusterMarker, 'mouseout', () => {
    const cvs = clusterMarker.getIcon().content
    clusterMarker.setIcon(changeMarker(cvs, radius, count))
  })

  naver.maps.Event.addListener(clusterMarker, 'click', () => {
    const cvs = clusterMarker.getIcon().content
    clusterMarker.setIcon(changeMarker(cvs, radius, count))
  })
}

/**
 * @description 지도 영역에 맞는 데이터만 추출해주는 함수
 * @param data 지도 정보 데이터 배열
 * @param sw 현재 지도 남서쪽 좌표
 * @param ne 현재 지도 북동쪽 좌표
 * @returns 선택된 데이터
 */
export function getNeSwRangeData(data: MapStoreListType[], sw: naver.maps.LatLng, ne: naver.maps.LatLng) {
  let selectedData: MapStoreListType[] = []

  data.forEach((item) => {
    const { latitude, longitude } = item
    if (sw.y < latitude && latitude < ne.y && sw.x < longitude && longitude < ne.x) {
      selectedData.push(item)
    }
  })
  return selectedData
}

/**
 * @description 매장유형 필터에 맞는 브라우니 이용 매장 필터링
 * @param brwnieMarkers 브라우니 이용매장 전체 마커 배열
 * @param filterState 매장유형 필터
 */
export function brwnieMarkerVisible(brwnieMarkers: naver.maps.Marker[], filterState: any) {
  let filteringArr: string[] = []

  const getStoreTypeStatus = Object.keys(filterState).filter((key) => filterState[key] === true)

  getStoreTypeStatus.forEach((item) => {
    const sameCategoryName = mapStoreData.find((data) => data.enCategory === item)
    if (sameCategoryName) {
      filteringArr.push(sameCategoryName.category)
    }
  })

  brwnieMarkers.forEach((item) => {
    let visibie
    if (filteringArr.includes(item.getTitle())) {
      visibie = true
    } else {
      visibie = false
    }
    item.setVisible(visibie)
  })
}
