import styled from 'styled-components'

const TextInput = styled.input`
  display: flex;
  align-items: center;
  height: 48px;
  width: 280px;
  padding: 0px 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.072px;
  color: #212121;
  background-color: #f6f6f6;
  border-radius: 16px;
  border: none;

  &::placeholder {
    color: #8e8e8e;
  }
`

export default TextInput
