import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'modules'
import { checkIsHoliday } from 'utils/common/calendar'
import twoDigitsNumConverter from 'utils/common/twoDigitsNumConverter'
import serviceData from 'data/serviceData.json'
import { findSchedulesIndex } from 'utils/common/service'

export const serviceWeekOptions = [1, 4, 12, 24] as const

export const serviceDayValueOptions = [0, 1, 2, 3, 4, 5, 6] as const
export const dayArr = ['월', '화', '수', '목', '금', '토', '일'] as const

export const serviceHourOptions = [9, 10, 11, 19, 20, 21] as const
export const serviceTimeMinuteOptions = [60, 90, 120] as const

export type ServiceWeekType = (typeof serviceWeekOptions)[number]
export type ServiceDayValueType = (typeof serviceDayValueOptions)[number]
export type DayArrType = (typeof dayArr)[number]
export type ServiceHourType = (typeof serviceHourOptions)[number]
// ? 40은 옵션으로써는 노출 되면 안되고 payload 전달만 가능해야해서 이런 식으로 처리함
export type ServiceTimeMinuteType = (typeof serviceTimeMinuteOptions)[number] | 40
export type ScheduleType = {
  year: number
  month: number
  date: number
  hour: ServiceHourType
}
export type SchedulesType = ScheduleType[]
export type ScheduleForModifyType = {
  year: number
  month: number
  date: number
  hour: ServiceHourType
  type: 'default' | 'fixedDay' | 'fixedTime'
  fixedIndex?: number
}
export type ServiceState = {
  storeAddressType: 'seoul' | 'other'
  serviceWeek?: ServiceWeekType
  serviceDayValues: ServiceDayValueType[]
  serviceHour?: ServiceHourType
  serviceTimeMinute?: ServiceTimeMinuteType
  servicePrice?: number
  schedules: SchedulesType
  contractedSchedules: SchedulesType
  invalidDayFixSchedules: SchedulesType
  invalidTimeFixSchedules: SchedulesType
  scheduleForModify?: ScheduleForModifyType
  modifiedSchedule?: ScheduleType
}

const initialState: ServiceState = {
  storeAddressType: 'seoul',
  serviceWeek: undefined,
  serviceDayValues: [],
  serviceHour: undefined,
  serviceTimeMinute: undefined,
  servicePrice: undefined,
  schedules: [],
  contractedSchedules: [],
  invalidDayFixSchedules: [],
  invalidTimeFixSchedules: [],
  scheduleForModify: undefined,
  modifiedSchedule: undefined,
}

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    initServiceState: (state) => {
      state.storeAddressType = 'seoul'
      state.serviceWeek = undefined
      state.serviceDayValues = []
      state.serviceHour = undefined
      state.serviceTimeMinute = undefined
      state.schedules = []
      state.contractedSchedules = []
      state.invalidDayFixSchedules = []
      state.invalidTimeFixSchedules = []
      state.scheduleForModify = undefined
      state.modifiedSchedule = undefined
    },
    setStoreAddressType: (state, action: PayloadAction<'seoul' | 'other'>) => {
      state.storeAddressType = action.payload
    },
    setServiceWeek: (state, action: PayloadAction<ServiceWeekType>) => {
      state.serviceWeek = action.payload
    },
    setServiceDayValues: (state, action: PayloadAction<ServiceDayValueType[]>) => {
      state.serviceDayValues = [...action.payload]
    },
    setServiceHour: (state, action: PayloadAction<ServiceHourType>) => {
      state.serviceHour = action.payload
    },
    setServiceTimeMinute: (state, action: PayloadAction<ServiceTimeMinuteType>) => {
      state.serviceTimeMinute = action.payload
    },
    setServicePrice: (state, action: PayloadAction<number>) => {
      state.servicePrice = action.payload
    },
    setSchedules: (state, action: PayloadAction<SchedulesType>) => {
      state.schedules = [...action.payload]
    },
    setContractedSchedules: (state, action: PayloadAction<SchedulesType>) => {
      state.contractedSchedules = [...action.payload]
    },
    setInvalidDayFixSchedules: (state, action: PayloadAction<SchedulesType>) => {
      state.invalidDayFixSchedules = [...action.payload]
    },
    setInvalidTimeFixSchedules: (state, action: PayloadAction<SchedulesType>) => {
      state.invalidTimeFixSchedules = [...action.payload]
    },
    setScheduleForModify: (state, action: PayloadAction<ScheduleForModifyType>) => {
      state.scheduleForModify = action.payload
    },
    modifySchedule: (state, action: PayloadAction<ScheduleForModifyType>) => {
      if (!state.scheduleForModify) return

      const { year, month, date, hour, type, fixedIndex } = action.payload

      state.modifiedSchedule = { year, month, date, hour }

      if (fixedIndex !== undefined) {
        if (type === 'fixedDay') {
          state.invalidDayFixSchedules.splice(fixedIndex, 1, {
            year,
            month,
            date,
            hour,
          })
        } else if (type === 'fixedTime') {
          state.invalidTimeFixSchedules.splice(fixedIndex, 1, {
            year,
            month,
            date,
            hour,
          })
        }
      } else {
        const { year: modifyYear, month: modifyMonth, date: modifyDate } = state.scheduleForModify

        const index = findSchedulesIndex(state.schedules, modifyYear, modifyMonth, modifyDate)

        state.schedules.splice(index, 1, {
          year,
          month,
          date,
          hour,
        })
        state.schedules = state.schedules.sort((a, b) => (a.month === b.month ? a.date - b.date : a.month - b.month))
      }
    },
    resetModifiedSchedule: (state) => {
      state.modifiedSchedule = undefined
    },
  },
})

const selectServiceWeek = (state: RootState) => state.service.serviceWeek
const selectServiceDayValues = (state: RootState) => state.service.serviceDayValues
const selectSchedules = (state: RootState) => state.service.schedules
const selectContractedSchedules = (state: RootState) => state.service.contractedSchedules
const selectInvalidDayFixSchedules = (state: RootState) => state.service.invalidDayFixSchedules
const selectInvalidTimeFixSchedules = (state: RootState) => state.service.invalidTimeFixSchedules

export const selectServiceDayDisplayString = createSelector(selectServiceDayValues, (serviceDayValues) => {
  return serviceDayValues.map((day) => dayArr[day]).join(', ')
})

export const selectHolidaySchedules = createSelector(selectSchedules, (schedule) => {
  return schedule.filter(({ year, month, date }) => checkIsHoliday(year, month, date))
})

export const selectCloseDay = createSelector(selectContractedSchedules, (contractedSchedule) => {
  const dateCount: {
    [key: string]: number
  } = {}

  contractedSchedule.forEach(({ year, month, date }) => {
    const dateString = `${year}-${twoDigitsNumConverter(month)}-${twoDigitsNumConverter(date)}`

    if (dateCount[dateString]) {
      dateCount[dateString] = dateCount[dateString] + 1
    } else {
      dateCount[dateString] = 1
    }
  })

  const serviceHourOptionCount = serviceHourOptions.length

  const contractLimitCount = serviceHourOptionCount * serviceData.dayContractLimit
  const weekendContractLimitCount = serviceHourOptionCount * serviceData.weekendContractLimit
  const closeDateStringArr = []

  for (const [dateString, contractCount] of Object.entries(dateCount)) {
    const day = new Date(dateString).getDay()
    const isWeekend = day === 0 || day === 6

    if (isWeekend) {
      if (contractCount === weekendContractLimitCount) {
        closeDateStringArr.push(dateString)
      }
    } else {
      if (contractCount === contractLimitCount) {
        closeDateStringArr.push(dateString)
      }
    }
  }

  return closeDateStringArr.map((dateString) => {
    const DateClass = new Date(dateString)

    return {
      year: DateClass.getFullYear(),
      month: DateClass.getMonth() + 1,
      date: DateClass.getDate(),
    }
  })
})

export const selectCloseDaySchedules = createSelector(selectSchedules, selectCloseDay, (schedule, closeDay) => {
  return schedule.filter(
    ({ year, month, date }) => !!closeDay.find((day) => day.year === year && day.month === month && day.date === date)
  )
})

export const selectInvalidDaySchedules = createSelector(
  selectHolidaySchedules,
  selectCloseDaySchedules,
  (holidaySchedule, closeDaySchedule) => {
    return [...holidaySchedule, ...closeDaySchedule]
  }
)

export const selectCloseTime = createSelector(
  selectContractedSchedules,
  selectCloseDay,
  (contractedSchedule, closeDay) => {
    const timeCount: {
      [key: string]: number
    } = {}

    contractedSchedule.forEach(({ year, month, date, hour }) => {
      const timeString = `${year}-${twoDigitsNumConverter(month)}-${twoDigitsNumConverter(date)}_${hour}`

      if (timeCount[timeString]) {
        timeCount[timeString] += 1
      } else {
        timeCount[timeString] = 1
      }
    })

    const contractTimeLimitCount = serviceData.dayContractLimit
    const weekendContractTimeLimitCount = serviceData.weekendContractLimit
    const closeTimeStringArr = []

    for (const [timeString, contractCount] of Object.entries(timeCount)) {
      const day = new Date(timeString.split('_')[0]).getDay()
      const isWeekend = day === 0 || day === 6

      if (isWeekend) {
        if (contractCount === weekendContractTimeLimitCount) {
          closeTimeStringArr.push(timeString)
        }
      } else {
        if (contractCount === contractTimeLimitCount) {
          closeTimeStringArr.push(timeString)
        }
      }
    }

    const closeTimeSchedule = closeTimeStringArr.map((timeString) => {
      const splitedString = timeString.split('_')
      const dateString = splitedString[0]
      const hour = Number(splitedString[1])

      const DateClass = new Date(dateString)

      return {
        year: DateClass.getFullYear(),
        month: DateClass.getMonth() + 1,
        date: DateClass.getDate(),
        hour,
      }
    })

    return closeTimeSchedule.filter(
      ({ year, month, date }) => !closeDay.find((day) => day.year === year && day.month === month && day.date === date)
    )
  }
)

export const selectInvalidTimeSchedules = createSelector(selectSchedules, selectCloseTime, (schedule, closeTime) => {
  return schedule.filter(
    ({ year, month, date, hour }) =>
      !!closeTime.find((time) => time.year === year && time.month === month && time.date === date && time.hour === hour)
  )
})

export const selectExceptInvalidTimeSchedules = createSelector(
  selectSchedules,
  selectInvalidDaySchedules,
  selectInvalidDayFixSchedules,
  (schedules, invalidDaySchedules, invalidDayFixSchedules) => {
    const exceptInvalidTimeSchedules: SchedulesType = [...schedules]

    invalidDaySchedules.forEach(({ year, month, date }) => {
      const index = findSchedulesIndex(exceptInvalidTimeSchedules, year, month, date)

      if (index !== -1) exceptInvalidTimeSchedules.splice(index, 1)
    })

    exceptInvalidTimeSchedules.push(...invalidDayFixSchedules)
    return exceptInvalidTimeSchedules.sort((a, b) => (a.month === b.month ? a.date - b.date : a.month - b.month))
  }
)

export const selectCompletedSchedules = createSelector(
  selectExceptInvalidTimeSchedules,
  selectInvalidTimeSchedules,
  selectInvalidTimeFixSchedules,
  (exceptInvalidTimeSchedules, invalidTimeSchedule, invalidTimeFixSchedule) => {
    const completedSchedule: SchedulesType = [...exceptInvalidTimeSchedules]

    invalidTimeSchedule.forEach(({ year, month, date }) => {
      const index = findSchedulesIndex(completedSchedule, year, month, date)

      if (index !== -1) completedSchedule.splice(index, 1)
    })

    completedSchedule.push(...invalidTimeFixSchedule)
    return completedSchedule.sort((a, b) => (a.month === b.month ? a.date - b.date : a.month - b.month))
  }
)

export const selectServiceWeeklyCount = createSelector(selectServiceDayValues, (serviceDayValues) => {
  return serviceDayValues.length
})

export const selectTotalServiceCount = createSelector(
  selectServiceWeek,
  selectServiceWeeklyCount,
  (serviceWeek, serviceWeeklyCount) => {
    return serviceWeek ? serviceWeek * serviceWeeklyCount : 0
  }
)

export const selectholidaySchedulesCount = createSelector(selectHolidaySchedules, (holidaySchedule) => {
  return holidaySchedule.length
})

export const selectInvalidDaySchedulesCount = createSelector(selectInvalidDaySchedules, (invalidDaySchedule) => {
  return invalidDaySchedule.length
})

export const selectInvalidTimeSchedulesCount = createSelector(selectInvalidTimeSchedules, (invalidTimeSchedule) => {
  return invalidTimeSchedule.length
})

export const selectServiceDayCount = createSelector(selectServiceDayValues, (serviceDayValues) => {
  const weekdayCount = serviceDayValues.filter((dayNum) => dayNum < 5).length
  const weekendCount = serviceDayValues.filter((dayNum) => dayNum >= 5).length

  return {
    weekdayCount,
    weekendCount,
  }
})

export const {
  initServiceState,
  setStoreAddressType,
  setServiceWeek,
  setServiceDayValues,
  setServiceHour,
  setServiceTimeMinute,
  setServicePrice,
  setSchedules,
  setContractedSchedules,
  setInvalidDayFixSchedules,
  setInvalidTimeFixSchedules,
  setScheduleForModify,
  modifySchedule,
  resetModifiedSchedule,
} = serviceSlice.actions

export default serviceSlice.reducer
