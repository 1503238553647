import styled from 'styled-components'

export function ManageBeforeAfterSection() {
  return (
    <Section>
      <TitleText>
        브라우니가
        <br />
        관리하면 다릅니다!
      </TitleText>

      <Tag>셀프빨래방</Tag>
      <img
        className="before-after-img"
        src="/image/wooribank/brwnie-manage-before-after_01.webp"
        alt="셀프 빨래방 관리 전, 후 사진"
      />

      <Tag>아이스크림 할인점</Tag>
      <img
        className="before-after-img"
        src="/image/wooribank/brwnie-manage-before-after_02.webp"
        alt="아이스크림 할인점 관리 전, 후 사진"
      />
    </Section>
  )
}

const Section = styled.section`
  width: 100vw;
  padding: 40px 0px;
  background: linear-gradient(90deg, #e1f5fe 22.4%, rgba(225, 245, 254, 0) 100%),
    url(<path-to-image>) lightgray -786.461px 0px / 409.24% 100% no-repeat;
  backdrop-filter: blur(10px);

  display: flex;
  flex-direction: column;
  align-items: center;

  & > .before-after-img {
    width: 300px;
    margin: 0px 30px;

    &:not(:last-child) {
      margin-bottom: 44px;
    }
  }
`

const TitleText = styled.h1`
  color: #212121;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;

  margin-bottom: 44px;
`

const Tag = styled.span`
  width: fit-content;

  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #3d5afe;

  color: #fff;
  font-size: 16px;
  font-weight: 700;

  margin-bottom: 10px;
`
