import CustomCheckbox from 'components/common/CustomCheckbox'
import Icon from 'components/Icon'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import Spinner from 'components/common/Spinner'
import { useAppDispatch } from 'hooks/useReduxHook'
import { openSnackBar } from 'modules/features/snackBarStatus/snackBarStatusSlice'
import { fadeIn } from 'assets/styles/animation'
import { isMobile } from 'react-device-detect'
import usePostSlackMessageSend from 'hooks/query/slackMessageSend/usePostSlackMessageSend'
import Divider from 'components/common/Divider'

const modalEl: HTMLElement = document.getElementById('modal') as HTMLElement

export type TesterEmailSendModalProps = {
  open: boolean
  onClose: () => void
}

export default function ServiceIntroduceModal({ open, onClose }: TesterEmailSendModalProps) {
  const [checkBoxStatus, setCheckBoxStatus] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<boolean>(false)
  const [checkBoxError, setCheckBoxError] = useState<boolean>(false)
  const { mutateAsync: mutatePostEasyConsultingSlack, isLoading } = usePostSlackMessageSend()
  const dispatch = useAppDispatch()

  const handleCheckBoxClick = () => {
    if (checkBoxStatus) {
      setCheckBoxError(false)
    }
    setCheckBoxStatus(!checkBoxStatus)
  }

  const handleEmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail(value)
  }

  const handleNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setName(value)
  }

  const handleSubmitClick = async () => {
    const reqEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/.test(email)
    const reqName = name.length === 0 || name.length > 20

    !checkBoxStatus && setCheckBoxError(true)
    !reqEmail && setEmailError(true)
    reqName && setNameError(true)

    if (isLoading) return

    if (checkBoxStatus && reqEmail && !reqName) {
      // 테스트때 임시로 주석처리 배포 때 풀 예정
      await mutatePostEasyConsultingSlack({
        template: 'SERVICE_INTRODUCE',
        data: {
          name: name,
          email: email,
        },
      })

      onClose()
      dispatch(openSnackBar(`입력하신 메일주소로${isMobile ? '\n' : ' '}서비스 소개서를 보내드릴게요!`))
    }
  }

  return createPortal(
    <>
      {open ? (
        <Dimmer onClick={onClose}>
          <ModalWrapper onClick={(e) => e.stopPropagation()}>
            <StyledModal>
              <TitleWrapper>
                <span>소개서 받기</span>
              </TitleWrapper>
              <SubTitleWrapper>소개서를 전달 받으실 간단한 정보를 입력해 주세요.</SubTitleWrapper>
              <FormWrapper>
                <FormInput
                  placeholder="브랜드 또는 매장명"
                  type="text"
                  onChange={handleNameOnChange}
                  value={name}
                  onFocus={() => setNameError(false)}
                />
                {name.length > 0 && (
                  <span>
                    <Icon name="SmallClose" onClick={() => setName('')} />
                  </span>
                )}
              </FormWrapper>
              {nameError && (
                <WarningText>
                  <Icon name="redError" />
                  {name.length === 0
                    ? '필수 입력 항목입니다.'
                    : name.length > 20
                    ? '최대 20자까지만 입력 가능해요.'
                    : ''}
                </WarningText>
              )}
              <FormWrapper>
                <FormInput
                  placeholder="이메일"
                  type="email"
                  onChange={handleEmailOnChange}
                  value={email}
                  onFocus={() => setEmailError(false)}
                />
                {email.length > 0 && (
                  <span>
                    <Icon name="SmallClose" onClick={() => setEmail('')} />
                  </span>
                )}
              </FormWrapper>
              {emailError && (
                <WarningText>
                  <Icon name="redError" />
                  {email.length === 0 ? '필수 입력 항목입니다.' : '이메일 형식이 맞지 않습니다.'}
                </WarningText>
              )}

              <CheckBoxWrapper>
                <CustomCheckbox>
                  <input
                    type="checkbox"
                    id={'marketing_id'}
                    onChange={handleCheckBoxClick}
                    checked={checkBoxStatus}
                    onFocus={() => setCheckBoxError(false)}
                  />
                </CustomCheckbox>

                <label htmlFor={'marketing_id'}>
                  <h5>개인정보 처리방침에 동의합니다.</h5>
                </label>
              </CheckBoxWrapper>
              {checkBoxError && (
                <WarningText>
                  <Icon name="redError" /> 필수 선택 항목입니다.
                </WarningText>
              )}
              <Divider marginVertical="16" mobileMarginVertical="16" />
              <PolicyText>
                개인정보의 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 다만, 이러한 개인정보 수집 이용에 동의하지
                않을 경우 소개서 제공이 불가능합니다. 수집항목 : 브랜드명(또는 매장명), 이메일 목적 : 고객 정보 파악 및
                서비스 정보 제공 이용 및 보관기간 : 수집된 개인 정보는 정보 기입 후 6개월 간 보관됩니다.
              </PolicyText>
              <FooterWrapper>
                <div onClick={onClose}>취소</div>
                <SubmitText onClick={handleSubmitClick} ready={checkBoxStatus}>
                  {isLoading ? <Spinner color="main" /> : '소개서 받기'}
                </SubmitText>
              </FooterWrapper>
            </StyledModal>
          </ModalWrapper>
        </Dimmer>
      ) : null}
    </>,
    modalEl
  )
}

const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(30, 30, 44, 0.4);
  z-index: 10000003 !important;
  animation: ${fadeIn} 0.3s ease-in-out;
`

const ModalWrapper = styled.div`
  width: 480px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  z-index: 10000004 !important;

  @media only screen and (max-width: 768px) {
    width: 328px;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  padding-right: 4px;
  height: 52px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.white};

  & > span {
    display: flex;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};

    & > svg {
      width: 36px;
      height: 36px;
    }
  }

  button {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.main};
    cursor: pointer;
  }

  :focus-within {
    ime-mode: disabled;
    -webkit-ime-mode: disabled;
    -moz-ime-mode: disabled;
    -ms-ime-mode: disabled;
    border: solid 1px ${({ theme }) => theme.colors.indigoA700};
  }
`

const FormInput = styled.input`
  width: 100%;
  border: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 24px;
  padding: 0;

  :disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray500};
  }
`

const StyledModal = styled.div`
  width: 100%;
  padding: 32px;
  padding-bottom: 32px;
  box-sizing: border-box;
  position: relative;
`

const WarningText = styled.div`
  margin-top: 4px;
  display: flex;
  height: 28px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.warning};

  & > svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
`

const CheckBoxWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;

  & > label {
    &:nth-of-type(2) {
      cursor: pointer;
      width: 342px;
      margin-left: 10px;
    }

    & > h5 {
      font-size: 14px;
      line-height: 24px;

      display: flex;
      align-items: center;
      color: var(--gray800);
      font-weight: bold;
    }
  }

  & > span {
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.gray500};
  }

  @media only screen and (max-width: 768px) {
    & > label {
      &:nth-of-type(2) {
        width: 200px;
      }
    }

    & > span {
      align-items: flex-start;
    }
  }
`

const PolicyText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--gray600);
  display: flex;
  position: relative;
  height: 80px;
  overflow: scroll;
`

const TitleWrapper = styled.div`
  width: 284px;
  margin-bottom: 8px;

  display: flex;
  align-items: end;

  color: ${({ theme }) => theme.colors.black};

  & > span {
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    display: flex;
    align-items: center;
  }

  & > svg {
    margin-left: 24px;
    width: 80px;
    height: 56px;
  }

  @media only screen and (max-width: 768px) {
    & > span {
      font-size: 20px;
      line-height: 28px;
    }

    & > svg {
      width: 40px;
      height: 26px;
    }
  }
`

const SubTitleWrapper = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  word-break: keep-all;

  color: var(--gray600);
`

const FooterWrapper = styled.div`
  position: absolute;
  z-index: 10000004 !important;
  border-top: 1px solid #e0e0e0;
  left: 0px;
  bottom: -49px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 49px;
  border-radius: 0px 0px 16px 16px;

  & > div {
    border-radius: inherit;
    width: 240px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.black};
    height: 48px;
  }
`

const SubmitText = styled.span<{ ready: boolean }>`
  transition: all 0.3 ease-in-out;
  border-radius: inherit;
  width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 28px;
  height: 48px;
  color: ${({ theme, ready }) => (ready ? theme.colors.indigoA700 : theme.colors.gray)};
  font-weight: 700;
`
