import { useParams } from 'react-router'
import useGetResetEmailVerify from 'hooks/query/authVerify/useGetResetEmailVerify'
import { useEffect } from 'react'
import { useCallback } from 'react'

export default function EmailResetInfo() {
  const { email, token } = useParams<{ email: string; token: string }>()
  const { mutateAsync: mutateResetEmailVerify } = useGetResetEmailVerify()

  const checkFunc = useCallback(async () => {
    await mutateResetEmailVerify({ email, token })
  }, [email, token, mutateResetEmailVerify])

  useEffect(() => {
    checkFunc()
  }, [checkFunc])
  return <></>
}
