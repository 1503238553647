import { patchMarketingAgree, MarketingAgreeReq } from 'lib/api/auth/patchMarketingAgree'
import { useMutation, useQueryClient } from 'react-query'

export default function usePatchMarketingAgree() {
  const queryClient = useQueryClient()

  return useMutation((value: MarketingAgreeReq) => patchMarketingAgree(value), {
    onSuccess: async () => {
      queryClient.refetchQueries('authCheck')
    },
  })
}
