import ILogger from "../ILogger";
import { LOGGER } from "../types";
import { GTMEventArguments, GTMEventName } from "./type";

class GTMLogger implements ILogger {
  type: LOGGER = LOGGER.GTM
  sender?: any[]

  onAttached(): boolean {
    try {
      // @ts-ignore
      this.sender = window.dataLayer
      return true
    } catch(error) {
      console.error('GTM 초기화가 실패했습니다.:', error)
      return false
    }
  }
  
  onDetached(): boolean {
    this.sender = undefined
    return !this.sender
  }

  onLog<Name extends GTMEventName = GTMEventName>(name: Name, args: GTMEventArguments<Name>): void {
    // LoggerManager에 Attach가 되어있지 않을 경우
    if (!this.sender) {
      console.error('GTM Logger의 초기화가 필요합니다.')
      return
    }
    // https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data 참고
    this.sender.push({
      'event': name,
      ...args
    })
  }
}

export default GTMLogger