import { getService, GetServiceParam } from 'lib/api/service/getService'
import { ServiceType } from 'lib/api/service/types'
import { QueryOptionsOf } from 'lib/utils/types'
import { useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'

export default function useServiceQuery(value: GetServiceParam, options: QueryOptionsOf<typeof getService> = {}) {
  return useQuery(createKey(value.id), () => getService(value), options)
}

const createKey = (id: string) => ['service', id]
useServiceQuery.createKey = createKey

export function useServiceQueryGetter() {
  const queryClient = useQueryClient()

  const get = useCallback(
    (id: string) => {
      return queryClient.getQueryData<ServiceType>(createKey(id))
    },
    [queryClient]
  )

  return get
}
