import client from '../client'
import { PhoneModifyRes } from './types'

export async function patchPhoneModify({ user_input_code, phone }: PhoneModifyReq) {
  const response = await client.patch<PhoneModifyRes>('auth-api/v1/auth/modify/phone', { user_input_code, phone })

  return response.data
}

export type PhoneModifyReq = {
  user_input_code: string
  phone: string
}
