import styled from 'styled-components'
import Icon from 'components/Icon'

// 악 졸려서 더 이상 생각하기 어렵다..
// 나중에 리펙토링 하기! 개못짰네..
export default function Paginator({
  page,
  onChanged,
  maxPage,
}: {
  page: number
  onChanged: (page: number) => void
  maxPage: number
}) {
  const visiblePage = maxPage > 5 ? 5 : maxPage
  const amount = [-2, -1, 0, 1, 2]

  function getCorrection(): number {
    if (page <= 3) {
      return 2
    } else if (-1 <= page - maxPage) {
      if (page - maxPage === 0) {
        return -2
      }
      return page - maxPage > -2 ? page - maxPage : -2
    }
    return 0
  }
  function isEnable(index: number) {
    if (page <= 3) {
      return index + 1 === page
    }
    return getCorrection() + amount[index] + page === page
  }
  if (maxPage > 5) {
    return (
      <PaginationContainer>
        <PaginationCell onClick={() => onChanged(page > 0 ? page - 1 : 0)}>
          <Icon name="blackLeftArrow" />
        </PaginationCell>
        {[...Array(visiblePage)].map((_, index) => {
          return (
            <PaginationCell
              key={index}
              className={isEnable(index) ? 'enable' : 'disable'}
              onClick={() => {
                if (page > 3) {
                  onChanged(getCorrection() + amount[index] + page)
                } else {
                  onChanged(index + 1)
                }
              }}
            >
              {page > 3 ? getCorrection() + amount[index] + page : index + 1}
            </PaginationCell>
          )
        })}
        <PaginationCell onClick={() => onChanged(page < maxPage - 1 ? page + 1 : maxPage)}>
          <Icon name="blackRightArrow" />
        </PaginationCell>
      </PaginationContainer>
    )
  }
  return (
    <PaginationContainer>
      <PaginationCell onClick={() => onChanged(page > 0 ? page - 1 : 0)}>
        <Icon name="blackLeftArrow" />
      </PaginationCell>
      {[...Array(maxPage)].map((_, index) => {
        return (
          <PaginationCell
            key={index}
            className={index + 1 === page ? 'enable' : 'disable'}
            onClick={() => {
              onChanged(index + 1)
            }}
          >
            {index + 1}
          </PaginationCell>
        )
      })}
      <PaginationCell className="right_arrow" onClick={() => onChanged(page < maxPage - 1 ? page + 1 : maxPage)}>
        <Icon name="blackRightArrow" />
      </PaginationCell>
    </PaginationContainer>
  )
}

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 5.6rem;
  margin-top: 2.4rem;

  ${({ theme }) => theme.media.mobile`
  margin-bottom: 4.8rem;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  `}
`

const PaginationCell = styled.button`
  min-width: 2.4rem;
  height: 2.4rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  background: transparent;
  color: ${({ theme }) => theme.colors.gray};

  ${({ theme }) => theme.media.mobile`
  margin-left: 0px;
  margin-right: 0px;
  `}

  &.enable {
    color: ${({ theme }) => theme.colors.black};
  }
`
