import Icon from 'components/Icon'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export default function LandingMuinStore() {
  const history = useHistory()

  const handleGoMuinStore = () => {
    window.open('https://www.xn--v92bo5hlpggqr.com', '_blank')
  }

  const handleGoConsultation = () => {
    history.push('/muin-consulting?fromBrwnie=true')
  }

  return (
    <LandingMapSectionWrapper>
      <ContentContainer>
        <TextWrapper>
          <h2>
            소자본 <br className="pc_br" />
            350만원으로
            <br />
            무인매장 창업을
          </h2>
          <h4>
            0.1평만 있다면 창업 준비 완료!
            <br />
            임대료 X 인건비 X 공간만 있다면 어디든지 OK
          </h4>

          <ApplyPcButtonWrapper>
            <div className="service-apply" onClick={handleGoMuinStore}>
              <Icon name="Monitor" />
              바로가기
            </div>
            <div className="easy-consulting" onClick={handleGoConsultation}>
              <Icon name="Document" />
              상담 신청
            </div>
          </ApplyPcButtonWrapper>
        </TextWrapper>
      </ContentContainer>
      <img src="/image/loading_partner_service/muin-store.png" className="background" alt="무인상회 이미지" />
    </LandingMapSectionWrapper>
  )
}

const LandingMapSectionWrapper = styled.section`
  width: 100%;
  padding: 180px 0px;

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  z-index: 0;

  .background {
    position: absolute;
    z-index: 0;
    width: 65%;
    height: 100%;
    top: 0px;
    right: 0px;
    object-fit: cover;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #a69d8e 45%, rgba(167, 157, 144, 0) 60.6%);
    z-index: 0;
  }

  ${({ theme }) => theme.media.mobile`
      height: 300px;
      padding: 40px 30px;

      // background: linear-gradient(90deg,#a69d8e 50%,rgba(167,157,144,0) 90%), url('/image/loading_partner_service/muin-store.png') lightgray 0px -70px / 200% 135% no-repeat
  `}
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  height: 446px;
  box-sizing: border-box;
  z-index: 1;

  ${({ theme }) => theme.media.mobile`
      height: inherit;
      position: relative;
      flex-direction: column;
      justify-content: unset;
  `}
`

const TextWrapper = styled.div`
  color: #fff;

  & .pc_br {
    display: block;
  }

  & > h2 {
    font-weight: 700;
    font-size: 52px;
    line-height: 65px;
    margin-bottom: 20px;
  }

  & > h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 52px;
  }

  ${({ theme }) => theme.media.mobile`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 40px;

    & > h2 {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 12px;
    }

    & > h4 {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 64px;
    }
    
    & .pc_br {
      display: none;
    }
  `}
`

const ApplyPcButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 170px;
    height: 64px;

    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    padding: 0px 32px;
    color: #363636;

    box-sizing: border-box;
    border-radius: 12px;
  }

  .service-apply {
    background-color: #f5d741;
    margin-right: 16px;
  }

  .easy-consulting {
    background-color: #fff;
  }

  svg {
    margin-right: 8px;
  }

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;

    .service-apply,
    .easy-consulting {
      width: fit-content;
      height: 48px;
      padding: 0px 20px;
      font-size: 14px;
    }
  }

  margin-bottom: 32px;
`
