import { postResetPasswordComplete, ResetPasswordCompleteReq } from 'lib/api/authVerify/postResetPasswordComplete'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { ROUTES } from 'utils/common/routes'
import useAuthQuery from '../auth/useAuthQuery'

export default function usePostResetPasswordComplete() {
  const history = useHistory()
  const { data } = useAuthQuery(
    {},
    {
      retry: 0,
    }
  )

  return useMutation((value: ResetPasswordCompleteReq) => postResetPasswordComplete(value), {
    onSuccess: async () => {
      if (data) {
        history.push(ROUTES.STORE)
      } else {
        history.push(ROUTES.AUTH_LOGIN)
      }
    },
    onError: async () => {
      history.push(ROUTES.AUTH_ERROR)
    },
  })
}
