import styled, { css } from 'styled-components'

interface ProgressBarProps {
  totalStep: number
  currentStep: number
  onPreviousClick: () => void
}

export default function ProgressBar({ totalStep, currentStep, onPreviousClick }: ProgressBarProps) {
  return (
    <Container>
      <TopOfProgress>
        <PreviousButton onClick={onPreviousClick}>이전으로</PreviousButton>

        <StepLabel>
          {currentStep}/{totalStep}
        </StepLabel>
      </TopOfProgress>

      <Bar>
        <Progress currentStep={currentStep} totalStep={totalStep} />
      </Bar>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  margin-top: 40px;

  ${({ theme }) => theme.media.mobile`
    width: 270px;
    margin-top: 24px;
  `}
`

const TopOfProgress = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 12px;
  margin-bottom: 4px;

  ${({ theme }) => theme.media.mobile`
    padding: 0 8px;
  `}
`

const PreviousButton = styled.button`
  background: transparent;
  cursor: pointer;
  height: 32px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.056px;
  color: #8e8e8e;

  ${({ theme }) => theme.media.mobile`
    height: 24px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.036px;
  `}
`

const StepLabel = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.056px;
  text-align: right;
  color: #585858;

  ${({ theme }) => theme.media.mobile`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.036px;
  `}
`

const Bar = styled.div`
  height: 20px;
  width: 100%;
  border-radius: 9999px;
  background: #f6f6f6;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;

  ${({ theme }) => theme.media.mobile`
    height: 16px;
  `}
`

const Progress = styled.div<{ currentStep: number; totalStep: number }>`
  width: 100%;
  background-color: #083994;
  transition: transform 0.3s cubic-bezier(0.87, 0, 0.13, 1);

  ${({ currentStep, totalStep }) => `
    transform: translateX(calc(-450px + ${(450 / (totalStep - 1)) * (currentStep - 1)}px));
  `}

  ${({ theme, currentStep, totalStep }) => theme.media.mobile`
    ${css`
      transform: translateX(calc(-270px + ${(270 / (totalStep - 1)) * (currentStep - 1)}px));
    `}
  `}
`
