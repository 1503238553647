import styled from 'styled-components'

const NoDiscount = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
  opacity: 0.5;
  text-align: center;
  line-height: 178px;
  height: 180px;

  // mobile
  ${({ theme }) => theme.media.mobile`
    height: 130px;
    line-height: 128px;
  `}
`

export default NoDiscount
