import { getStores } from 'lib/api/store/getStores'
import { QueryOptionsOf } from 'lib/utils/types'
import { useQuery } from 'react-query'

export default function useStoresQuery(value?: any, options: QueryOptionsOf<typeof getStores> = {}) {
  return useQuery(createKey(), () => getStores(), options)
}

const createKey = () => ['stores']
useStoresQuery.createKey = createKey
