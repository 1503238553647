import { AlbagoSection } from 'components/WooribankPage/AlbagoSection'
import { BottomCTA } from 'components/WooribankPage/BottomCTA'
import { BrwnieBottomCtaSection } from 'components/WooribankPage/BrwnieBottomCtaSection'
import { CoworkCompanySection } from 'components/WooribankPage/CoworkCompanySection'
import { FreeCoupon } from 'components/WooribankPage/FreeCoupon'
import { HeroSection } from 'components/WooribankPage/HeroSection'
import { HowToSection } from 'components/WooribankPage/HowToSection'
import { ManageBeforeAfterSection } from 'components/WooribankPage/ManageBeforeAfterSection'
import { StoreTypeSection } from 'components/WooribankPage/StoreTypeSection'

export default function WooribankPage() {
  return (
    <>
      <HeroSection />
      <FreeCoupon />
      <StoreTypeSection />
      <ManageBeforeAfterSection />
      <CoworkCompanySection />
      <AlbagoSection />
      <HowToSection />
      <BrwnieBottomCtaSection />
      <BottomCTA />
    </>
  )
}
