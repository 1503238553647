import React from 'react'
import styled from 'styled-components'

export type ContentProps = {
  children: React.ReactNode
  mainRef?: React.RefObject<HTMLDivElement>
  sidePadding?: boolean
}

export default function Content({ children, mainRef, sidePadding = false }: ContentProps) {
  return (
    <StyledContent ref={mainRef} sidePadding={sidePadding}>
      {children}
    </StyledContent>
  )
}

const StyledContent = styled.div<{ sidePadding?: boolean }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(10, 0, 15)};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 90vh;

  ${({ theme, sidePadding }) => theme.media.mobile`
  padding: ${sidePadding ? theme.spacing(10, 3.2, 12.5, 3.2) : theme.spacing(10, 0, 12.5)}
  `}
`
