import Spinner from 'components/common/Spinner'
import Icon from 'components/Icon'
import useGetStoreDataQuery from 'hooks/query/map/useGetStoreDataQuery'
import { MapStoreListType } from 'lib/api/map/types'
import React, { useState, memo, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { getStoreTypeImageUrl } from 'utils/map/storeType'
const { naver } = window

/**
 * @description 매장 디테일 페이지
 * @param detailOpen 디테일 화면 열림 유무
 * @param setDetail 디테일 호면 열림 상태 함수
 * @param storeId 매장 정보를 가져오는 매장 id
 */
function MapStoreDetailSection() {
  const { storeId } = useParams<{ storeId: string }>()
  const history = useHistory()
  const { data, isLoading } = useGetStoreDataQuery({ id: parseInt(storeId) })
  const [storeData, setStoreData] = useState<MapStoreListType | null>(null)
  useEffect(() => {
    if (storeId && data) {
      setStoreData(data)
      const map = new naver.maps.Map('maperer', {
        mapDataControl: false,
        zoom: 17,
        zoomControl: true,
        zoomControlOptions: {
          position: naver.maps.Position.RIGHT_BOTTOM,
          style: 2,
          legendDisabled: true,
        },
        logoControlOptions: {
          position: naver.maps.Position.LEFT_BOTTOM,
        },
        scaleControl: false,
        logoControl: false,
        draggable: false,
        scrollWheel: false,
        tileTransition: true,
      })
      const location = new naver.maps.LatLng(data.latitude, data.longitude)
      map.setCenter(location)
      new naver.maps.Marker({
        position: location,
        map: map,
      })
    }
  }, [storeId, data])

  const handleCloseClick = () => {
    history.goBack()
  }

  return (
    <>
      {isLoading ? (
        <>
          <SpinnerWrapper>
            <Spinner size={28} color="main" />
          </SpinnerWrapper>
        </>
      ) : (
        <MainWrapper>
          <HeaderWrapper>
            <Icon name="blackLeftArrow" onClick={handleCloseClick} />
            <span>{storeData?.naver_category}</span>
          </HeaderWrapper>
          <BodyWrapper>
            <StoreImage url={storeData ? getStoreTypeImageUrl(storeData?.category) : ''} />
            <div className="title_container">
              <p>{storeData?.store_name}</p>
              <span>{storeData?.naver_category}</span>
            </div>
            <ul className="inform_container">
              <li>
                <Icon name="mobileNavHome" />
                <span className="content">{storeData?.address}</span>
              </li>
              <li>
                <Icon name="simplePhone" />
                <span className="content">
                  <a href={`tel:${storeData?.contact_number}`}>{storeData?.contact_number}</a>
                </span>
              </li>
              <li>
                <Icon name="simpleClock" />
                <span className="content">{storeData?.operation_time === null ? '-' : storeData?.operation_time}</span>
              </li>
              <li>
                <Icon name="simpleDescription" />
                <span className="content">
                  {storeData?.description === null
                    ? '-'
                    : storeData?.description !== null &&
                      storeData?.description.split('\\n').map((line, index) => (
                        <div key={index}>
                          {line}
                          <br />
                        </div>
                      ))}
                </span>
              </li>
            </ul>
            <Map id="maperer" />
          </BodyWrapper>
        </MainWrapper>
      )}
    </>
  )
}

const MainWrapper = styled.section`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: white;
  width: 400px;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  z-index: 10000010 !important;

  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    right: unset;
  }
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 186px;

  @media only screen and (max-width: 768px) {
    right: calc(50% - 14px);
  }
`

const Map = styled.section`
  margin: 16px;
  height: 280px;
  margin-bottom: 62px;
`

const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;

  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  padding: 0px 16px;
  justify-content: flex-start;
  position: fixed;
  width: 400px;
  height: 62px;
  outline: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};

  z-index: 25;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  span {
    display: inline-block;
    margin-left: 8px;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`

const BodyWrapper = styled.div`
  position: relative;
  width: 400px;
  overflow-y: scroll;
  height: calc(100vh - 80px);

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.darkGrayishBlue};
  }

  .title_container {
    padding: 16px;
    text-align: center;
    word-break: keep-all;
    height: 120px;
    display: flex;
    border-bottom: 6px solid ${({ theme }) => theme.colors.lightGrayishBlue};
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > p {
      letter-spacing: -1px;
      font-size: 22px;
      line-height: 34px;
      font-weight: bold;
    }
    & > span {
      font-size: 12px;
      line-height: 20px;
      font-weight: bold;
      opacity: 0.5;
    }
  }

  .inform_container {
    padding: 16px;
    box-sizing: border-box;
    height: 260px;
    border-bottom: 6px solid ${({ theme }) => theme.colors.lightGrayishBlue};
    li {
      display: flex;
      align-items: center;
      .content {
        margin-left: 12px;
        display: inline-flex;
        align-items: center;
        height: 40px;
        color: ${({ theme }) => theme.colors.darkGrayishBlue};
        font-size: 14px;
        line-height: 24px;
        word-break: keep-all;
      }

      &:last-child {
        .content {
          white-space: pre-line;
          height: 80px;
          flex-direction: column;
          display: flex;
          align-items: flex-start;
          justify-content: center;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  }
`

const StoreImage = styled.div<{ url?: string }>`
  margin-top: 62px;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
  background-image: url(${({ url }) => url && url});
  z-index: 30;
`

export default memo(MapStoreDetailSection)
