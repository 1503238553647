export type FaqDescriptionProps = {
  questionNumber: number
}

const DefaultQuestion = () => {
  return <p>준비중입니다.</p>
}

const Question1 = () => {
  return (
    <p>
      브라우니 서비스 웹사이트(
      <a href="https://www.brwnie.kr" target="_blank" rel="noreferrer">
        www.brwnie.kr
      </a>
      )에 접속한 뒤 회원가입을 통해 브라우니 멤버스가 되시면 서비스를 신청할 수 있습니다.
      <br />
      <br />
      서비스 신청은
      <br />
      <b>[로그인] - [서비스 신청・관리] - [매장 추가] - [서비스 신청] - [결제]</b>단계로 이루어지게 됩니다.
      <br />
      <br />
      서비스 이용료는 가장 인기있는 4주 패키지 기준, 아래와 같습니다.
      <br />
      주2회 관리 신청 시, 240,000원 (총 관리 횟수 : 8회, VAT 포함)
      <br />
      주5회 관리 신청 시, 550,000원 (총 관리 횟수 : 20회, VAT 포함)
      <br />
      <br />
      <b>
        총 관리 횟수가 많아질수록 1회당 이용료가 낮아지며, 실평수 50평대 이상의 무인매장과, 매장 특성상 관리 시간이
        추가로 필요한 경우 서비스 비용이 추가될 수 있습니다.
      </b>
      <br />
    </p>
  )
}

const Question2 = () => {
  return (
    <p>
      <b>
        브라우니 서비스의 회당 관리 시간은 <span style={{ color: 'red' }}>평균 60분</span>으로 진행됩니다.
        <br />
        <br />
        <span style={{ color: 'blue' }}>무인매장 유형별 3가지 특화 업무와 9가지 표준 업무</span>를 제공하고 있으며,
        기본적으로 매장 내/외부 청결관리 및 소모품 보충을 진행하고 있습니다.
        <br />
        <br />
        유형별 전용 업무는 홈페이지 상단
        {' [서비스 유형] → [업종별 상세페이지] → [업종별 맞춤 서비스]'}에서 별도로 확인 가능합니다.
      </b>
      <br />
      <br />
      <br />
      단, 아래와 같은 서비스는 제공하지 않고 있습니다.
      <br />
      <br />
      1. 바닥 물청소 또는 외벽 청소 등과 같이 특수청소전문업체에서 제공하는 서비스
      <br />
      (*바닥 물걸레 관리를 원하실 경우, <span style={{ color: 'blue' }}>일회용 청소포와 밀대 구비</span>를 요청드리고
      있습니다.)
      <br />
      <br />
      2. 화장실 청소는 미제공 항목이며, 사전 협의를 통해 휴지/소독제 등 소모품 보충 업무는 제공 가능합니다.
      <br />
      <br />
      3. 쓰레기 처리 시 분리수거는 분류되어 있는 휴지통에 한해 처리를 도와드리고 있습니다. (쓰레기통 하나에 모든 품목
      처리 시, 분리수거 처리가 어려울 수 있습니다.)
      <br />
      <br />
      4. 손이 닿지 않는 범위는 크루님의 낙상 위험이 있어 서비스 진행이 어렵습니다.
      <br />
      (ex. 실내등, 천장형 에어컨, 기타 기물 등)
    </p>
  )
}

const Question3 = () => {
  return (
    <p>
      표준 업무 외 관리가 필요한 부분 또는 과제가 있다면 브라우니 매니저가 상담을 통해 가능 여부를 확인해드리고
      있습니다.
      <br />
      <br />
      표준 업무 외 추가되는 업무는 일정 부분 요금이 추가될 수 있으며, 반드시
      <b> 브라우니 첫 방문일 전까지 브라우니 매니저와 상담</b>을 통해 과제를 확정해주셔야 합니다.
      <br />
      <br />첫 방문일 전에 브라우니 매니저가 매장 컨디션 및 추가 업무 확인을 위해 유선 연락 또는 채팅을 통해
      상담해드리며 그 때 자세히 상담해보시기를 추천드립니다.
    </p>
  )
}

const Question4 = () => {
  return (
    <p>
      네. 단, 아래와 같은 경우 <span style={{ color: 'blue' }}>추가 요금</span>이 발생할 수 있습니다
      <br />
      <br />
      1. 실평수 <span style={{ color: 'blue' }}>50평대</span> 이상의 매장
      <br />
      2. 매장 특성 상 표준 관리시간보다 추가 관리가 필요한 경우
      <br />
      3. 표준업무 외 요청하는 서비스에 따른 추가 업무가 필요한 경우
      <br />
      <br />
      브라우니 크루가 첫 방문 시 실제 관리를 통해 현장 파악을 한 뒤, 추가될 수 있는 요금을 브라우니 매니저가 별도
      안내해드립니다.
    </p>
  )
}

const Question5 = () => {
  return (
    <p>
      네. 얼마든지 가능합니다.
      <br />
      홈페이지 상에 나와있는 <span style={{ color: 'blue' }}>9가지</span> 무인매장 유형 외에 운영하고 계신 매장이 있다면
      편하게 웹사이트 하단의 [문의하기] 버튼을 눌러 상담을 요청해 주세요.
    </p>
  )
}

const Question6 = () => {
  return (
    <p>
      현재 브라우니 서비스는
      <br />
      <br />
      단기 관리인 1주 패키지,
      <br />
      <b>정기 관리인 4주 패키지,</b>
      <br />
      <b>장기 관리인 12주 또는 24주 패키지</b> 중에서
      <br />
      선택하여 진행하실 수 있습니다.
      <br />
      <br />
      원하시는 패키지로 선택해서 서비스 이용이 가능하시며, <b>4주 패키지의 경우 정기결제 방식 선택이 가능</b>해, 해당
      옵션을 이용하시면 마지막 관리일 5일전 최초 결제하신 수단으로 자동결제를 도와드리고 있습니다.
    </p>
  )
}

const Question7 = () => {
  return (
    <p>
      정기 결제란 <b>4주에 한 번씩 동일한 스케줄로 자동 결제되는 구독형 상품</b>을 의미합니다. <br />
      <br />
      정기 결제 선택 시, 마지막 관리일 7일 전 신규 생성된 스케줄을 확인하실 수 있으며, <br />
      <b>마지막 관리일 5일 전 최초 결제해주신 수단으로 자동으로 결제</b>가 이루어집니다.
      <br />
      <br />
      만일, 패키지 및 스케줄 변경을 원하시는 경우 신규 결제일 전에 담당 매니저 혹은 브라우니 채널톡으로 문의 주시면 변경
      도와드리고 있습니다.
    </p>
  )
}

const Question8 = () => {
  return (
    <p>
      마지막 관리일 5일 전 최초 결제가 실패했을 경우, <b>마지막 관리일 3일 전 자동 재결제가 진행</b>되고있습니다.
      <br />
      <br />
      결제 실패 시, 별도의 알림톡을 전송해드리고 있어 재결제 전 카드 사용 가능 여부 및 잔액, 한도 등을 체크 후
      재결제일에 자동결제 진행이 가능합니다. <br />
      <br />
      만일 재결제 시도 후에도 정상적으로 결제가 진행되지 않을 경우, 담당 매니저 혹은 브라우니 채널톡을 통해 문의주시면
      서비스 이용을 도와드리고있습니다.
    </p>
  )
}

const Question9 = () => {
  return (
    <p>
      <b>
        2024년 8월 기준 브라우니 서비스는 <br />
        서울시 전역과
        <br />
        경기 일부지역(수원, 성남, 인천, 김포, 일산, 부천, 부평, 하남, 광명, 과천, 안양)에서 서비스되고 있습니다.
      </b>
      <br />
      <br />
      서비스 이용 문의가 많은 남양주(별내, 다산 등 일부), 구리, 용인 등 수도권 지역으로 순차 확대해나갈 계획이며,{' '}
      <b>하반기 중으로 부산, 세종, 제주 확장</b>을 예정하고 있어 더 많은 지역에서 브라우니 서비스 이용이 가능하도록
      준비중입니다.
      <br />
      <br />
      현재 서비스되고 있지 않은 지역이더라도 매니저와 1:1 상담을 통해 일정과 지역을 예약할 수 있으니 편하게 상담을
      요청해 주세요.
    </p>
  )
}

const Question10 = () => {
  return (
    <p>
      무인매장 관리에 필요한 이론과 실무 교육을 이수하고 엄격한 채용 절차를 거친 신원이 확인된 크루들로 구성됩니다.
      <br />
    </p>
  )
}

const Question11 = () => {
  return (
    <p>
      실무를 기반으로 한 오프라인 이론 교육과 현장 실무 교육을 이수하게 됩니다. 실무 교육은 각 업종별로 전담 매니저,
      사수 동행 하에 표준화된 프로세스로 진행됩니다. 이론과 실무 교육을 통과한 크루에 한해서만 서비스 현장 투입이
      이뤄지며, 그렇지 못한 크루의 경우 재교육을 실시합니다.
      <br />
      <br />
      더불어, 정기적인 온-오프라인 행사를 통해 업데이트되는 현장 상황과 노하우를 크루 간 공유하며 현장감을 유지하기 위해
      노력하고 있으며, 월 1회 업종별 관리방법, 기기 사용방법, 서비스 마인드 등의 교육을 받게 됩니다.
    </p>
  )
}

const Question12 = () => {
  return (
    <p>
      직접적인 현장 업무 지시는 할 수 없습니다. <br />
      다만 담당 브라우니 매니저와 표준 업무 외 필요한 업무에 대해 논의할 수 있으며, 당일 발생한 간단한 요청의 경우
      서비스 시 커뮤니케이션 툴로 사용하는 '워키도키' 앱을 통해 '멤버스-매니저-크루' 간 소통할 수 있습니다.
    </p>
  )
}

const Question13 = () => {
  return (
    <p>
      저희 브라우니 서비스는 클리너, 행주, 물티슈 등 아래와 같은 <b>자체적인 관리 키트를 활용하고 있습니다.</b>
      <br />
      <br />
      <b>
        단, 바닥 관리는 <span style={{ color: 'blue' }}>청소기 또는 일회용 밀대</span>가 구비되어있는 매장에 한해서
        서비스를 진행하고 있습니다.
      </b>
      <br />
      <br />
      제로켐 다목적 멀티클리너
      <br />
      용도: 기름때, 찌든때, 오염/먼지 제거
      <br />
      성분: 알칼리이온수, 탄산칼륨
      <br />
      액성: 원액(알칼리성)
      <br />
      <br />
      제로켐 다목적 살균소독수
      <br />
      용도: 의자, 테이블, 매대, 손잡이, 살균, 소독
      <br />
      성분: 정제수, 치아염소산(HOCL)
      <br />
      액성: 원액(약산성, 중성 PH5.5-6.5)
      <br />
      <br />
      윈덱스 유리세정제
      <br />
      용도: 유리, 창문, 거울 얼룩제거
      <br />
      성분: 계면활성제 5%미만(아민옥사이드계, 직쇄알킬벤젠계음이온), 세정제(수산화암모늄 0.09%), 색소, 향료
      <br />
    </p>
  )
}

const Question14 = () => {
  return (
    <p>
      브라우니 서비스는 청소 그 자체에 방점을 두기보다는, 무인매장을 무인매장답게 운영하고자 하는 운영 점주님들의 시간을
      아껴드릴 수 있고, 무인매장 창업을 고려하고 계시는 예비 점주님들의 시작을 함께 할 수 있습니다.
      <br />
      <br />
      우리 매장을 방문하는 고객님들께서 <b>'아, 이 곳은 관리되고 있는 매장이구나'</b>를 느낄 수 있도록 사람의 손으로,
      기본적이지만 정기적으로 해야하는 <b>휴먼터치 관리에 집중합니다.</b>
      <br />
      <br />
      또한 <span style={{ color: 'blue' }}>주식회사 하이어엑스</span>의 비대면 업무관리 서비스 앱 '워키도키'를 통해
      확보한 약 <span style={{ color: 'blue' }}>900만</span> 건의 빅데이터를 분석해 무인매장 유형별로 꼭 필요한 업무만을
      골라 표준화했습니다.
      <br />
      <br />
      관리직원 또는 아르바이트 채용, 인력 섭외, 업무 인수인계, 계약사항 확인 검토, 기타 발생될 수 있는 노무 이슈 등 운영
      외적으로 신경 쓸 부분을 없애드립니다.
    </p>
  )
}

const Question15 = () => {
  return (
    <p>
      결제 완료 후 서비스 개시 전, 취소를 원하시는 고객님은 홈페이지 [마이페이지] 내에서 자유롭게 취소/해지 신청을 하실
      수 있습니다.
      <br />
      <br /> 서비스 개시 후, 해지를 원하실 경우에는 [마이페이지] 내에서 해지 신청을 해주시거나 1:1 상담을 요청해주시면
      신속하게 처리해드리고 있습니다.
    </p>
  )
}

const Question16 = () => {
  return (
    <p>
      결제 완료 후 서비스 개시 전, 취소를 원하시는 고객님은 홈페이지 [마이페이지] 내에서 자유롭게 취소/해지 신청을 하실
      수 있습니다.
      <br />
      서비스 개시 후, 해지를 원하실 경우에는 [마이페이지] 내에서 해지 신청을 해주시거나 1:1 상담을 요청해주시면 신속하게
      처리해드리고 있습니다.
    </p>
  )
}

const Question17 = () => {
  return (
    <p>
      현금영수증은 <b>[웹사이트접속] - [로그인] - [결제내역]</b> 을 통해 이용중인 서비스를 확인하신 뒤{' '}
      <span style={{ color: 'blue' }}>[영수증]</span> 버튼을 눌러 해당 정보를 입력한 후 발행할 수 있습니다. <br />
      <br />
      세금계산서의 경우, 브라우니 홈페이지를 통해 결제 시(계좌이체, 가상계좌)PG사를 통해 자동으로 결제내역이 신고되어
      별도의 계산서 발행이 필요하지 않습니다.
      <br />
      <br /> 단, 무통장입금 및 기타 사유로 세금계산서 발행이 필요하신 경우 웹사이트 하단 채팅 기능을 통해 문의주시면
      담당자를 통해 신속하게 처리해드리겠습니다.
    </p>
  )
}

export function FaqDescription({ questionNumber }: FaqDescriptionProps) {
  switch (questionNumber) {
    case 0:
      return <Question1 />
    case 1:
      return <Question2 />
    case 2:
      return <Question3 />
    case 3:
      return <Question4 />
    case 4:
      return <Question5 />
    case 5:
      return <Question6 />
    case 6:
      return <Question7 />
    case 7:
      return <Question8 />
    case 8:
      return <Question9 />
    case 9:
      return <Question10 />
    case 10:
      return <Question11 />
    case 11:
      return <Question12 />
    case 12:
      return <Question13 />
    case 13:
      return <Question14 />
    case 14:
      return <Question15 />
    case 15:
      return <Question17 />
    case 16:
      return <Question17 />
    default:
      return <DefaultQuestion />
  }
}
