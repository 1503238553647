import useHoverAwayListener from 'hooks/useHoverAwayListener'
import { useRef } from 'react'
import styled from 'styled-components'
import { Dispatch, SetStateAction } from 'react'
import { useAppSelector } from 'hooks/useReduxHook'
import { getEventBannerStatus } from 'modules/features/eventBannerStatus/eventBannerStatusSlice'
import { useHistory } from 'react-router'

export type NavMenuListProps = {
  right?: number
  children?: React.ReactNode
  setListOpen: Dispatch<SetStateAction<boolean>>
}

export default function NavMenuList({ right = 360, children, setListOpen }: NavMenuListProps) {
  const ref = useRef<HTMLUListElement>(null)
  const history = useHistory()
  const isEventBannerStatus = useAppSelector(getEventBannerStatus)
  const { pathname } = history.location

  useHoverAwayListener({ ref: ref, onOutsideHover: () => setListOpen(false) })

  return (
    <StyledNavMenuListWrapper right={right} ref={ref} verticalLocation={isEventBannerStatus && pathname === '/'}>
      {children}
    </StyledNavMenuListWrapper>
  )
}

const StyledNavMenuListWrapper = styled.ul<{ right: number; verticalLocation: boolean }>`
  width: 136px;
  position: fixed;
  z-index: 10;
  top: 64px;
  right: ${({ right }) => `${right}px`};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 12px 26px 0 rgb(0 0 0 / 20%);

  & > li {
    width: 100%;
    height: 40px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black};

    & > a {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black};
    }

    &:first-child {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: 10px;
    }
    :hover {
      color: ${({ theme }) => theme.colors.black};
      background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
    }
  }

  @media only screen and (max-width: 768px) {
    top: ${({ verticalLocation }) => (verticalLocation ? '112px' : '64px')};
  }
`
