import _ from 'lodash'

/**
 * @function paginate
 * @description 한 페이지에 보여지는 데이터를 나눠주는 함수
 * @param {any[]} items 아이템 배열
 * @param {number} perPageMaxLen 한 페이지당 최대 항목 수
 * @param {number} currentPage 현재 페이지 위치
 */

export type PaginateType = {
  items: any[]
  perPageMaxLen: number
  currentPage: number
}

export function paginate({ items, perPageMaxLen, currentPage }: PaginateType) {
  const startIndex = (currentPage - 1) * perPageMaxLen // 자를 배열 시작점

  return _(items)
    .slice(startIndex) // 시작점부터 배열을 자르되
    .take(perPageMaxLen) // pageSize만큼의 배열을 취함
    .value() // lodash wrapper 객체를 regular 배열로 변환
}
