import { sendEmailForResetPassword, SendEmailForResetPasswordReq } from 'lib/api/authVerify/sendEmailForResetPassword'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { ROUTES } from 'utils/common/routes'

export default function usePostSendEmailForResetPassword() {
  const history = useHistory()
  return useMutation((value: SendEmailForResetPasswordReq) => sendEmailForResetPassword(value), {
    onSuccess: async (data) => {
      history.push({ pathname: ROUTES.AUTH_RESET_PASSWORD_INFO, state: data.email })
    },
    onError: async (data: any) => {},
  })
}
