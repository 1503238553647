import Icon from 'components/Icon/Icon'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  calcServicePeriod,
  calcTotalDate,
  checkIsHoliday,
  checkIsWeekend,
  getDay,
  getServicePeriodFormatter2,
  weekArr,
} from 'utils/common/calendar'
import { SelectedDateType } from 'utils/common/time'

type ServiceCalendarProp = {
  firstDate: SelectedDateType
  totalReservationDate: SelectedDateType[]
}

export default function ServiceCalendar({ totalReservationDate, firstDate }: ServiceCalendarProp) {
  const today = new Date()
  const [year, setYear] = useState<number>(firstDate.year)
  const [month, setMonth] = useState<number>(firstDate.month)
  const [date, setDate] = useState<number>(firstDate.date)
  const [totalDate, setTotalDate] = useState<number>(0)

  useEffect(() => {
    setTotalDate(calcTotalDate(year, month))
  }, [month, year])

  const handleLeftClick = () => {
    if (month === 1) {
      setYear((value) => value - 1)
      setMonth(12)
    } else setMonth((value) => value - 1)
  }

  const handleRightClick = () => {
    if (month === 12) {
      setYear((value) => value + 1)
      setMonth(1)
    } else setMonth((value) => value + 1)
  }

  const checkIsCheckDate = (date: number) => {
    return !!totalReservationDate.find((day) => day.year === year && day.month === month && day.date === date)
  }

  if (!totalReservationDate || totalReservationDate.length === 0) return null

  // 1일의 getDay값
  const firstDayNum = getDay(year, month, 1)
  const firstWeekBlankNum = firstDayNum === 0 ? 6 : firstDayNum - 1

  const { year: firstYear, month: firstMonth, date: firstDay } = totalReservationDate[0]
  const { year: lastYear, month: lastMonth, date: lastDay } = totalReservationDate[totalReservationDate.length - 1]

  const firstDateFormat = `${firstYear}.${firstMonth}.${firstDay}`
  const lastDateFormat = `${lastYear}.${lastMonth}.${lastDay}`

  return (
    <Container>
      <Header>
        <TitleWrap>
          <SideButton onClick={handleLeftClick}>
            <Icon name="grayLeftArrowS" />
          </SideButton>
          <SelectedDateTitle>
            {year}년 {month}월
          </SelectedDateTitle>
          <SideButton onClick={handleRightClick}>
            <Icon name="grayRightArrowS" />
          </SideButton>
        </TitleWrap>
        <ServiceDate>
          서비스 기간&nbsp;&nbsp;:&nbsp;&nbsp;
          {getServicePeriodFormatter2(firstDateFormat, lastDateFormat)}
        </ServiceDate>
      </Header>
      <CalendarBody>
        {weekArr.map((day, i) => (
          <WeekText key={day}>{day}</WeekText>
        ))}
        {Array.from({ length: firstWeekBlankNum }).map((_, i) => (
          <div key={i} />
        ))}
        {Array.from({ length: totalDate }).map((_, i) => {
          const isClicked = checkIsCheckDate(i + 1)
          const isHoliday = checkIsHoliday(year, month, i + 1)
          const isWeekend = checkIsWeekend(year, month, i + 1)

          return (
            <DateText key={i} $isClicked={isClicked} $isHoliday={isHoliday} $isWeekend={isWeekend}>
              {i + 1}
            </DateText>
          )
        })}
      </CalendarBody>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 8px 16px;
`

const SideButton = styled.button`
  cursor: pointer;
  background-color: #ffffff;
`

const SelectedDateTitle = styled.h3`
  color: var(--gray-1, #333);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`

const ServiceDate = styled.p`
  color: var(--black, #1e1e2c);
  text-align: center;
  font-size: 12px;
`

const CalendarBody = styled.div`
  width: 100%;
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-row-gap: 6px;
  padding: 0px 50px;
`

const WeekText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--black, #1e1e2c);
  font-size: 12px;

  width: 28px;
  height: 28px;
`

const DateText = styled.span<{ $isClicked: boolean; $isHoliday?: boolean; $isWeekend?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--black, #1e1e2c);
  font-size: 12px;

  width: 28px;
  height: 28px;

  ${({ $isClicked }) =>
    $isClicked &&
    css`
      border-radius: 16px;
      background: var(--black, #1e1e2c);
      color: var(--white, #fff);
    `}
  ${({ $isHoliday }) =>
    $isHoliday &&
    css`
      color: #ff0000;
    `}
    ${({ $isWeekend }) =>
    $isWeekend &&
    css`
      opacity: 0.20000000298023224;
    `}
`
