import Icon from 'components/Icon'
import styled from 'styled-components'

type ProfileProps = {
  onClick?: (data: any) => void
}

export default function Profile({ onClick }: ProfileProps) {
  return <StyledProfile name="sheepProfile" onClick={onClick} />
}

const StyledProfile = styled(Icon)`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  margin-right: 0.8rem;
  border: 0.01rem solid #b1b1b5;
`
