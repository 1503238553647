import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { calcBlankOfWeek, calcNextMonth, calcNextYear, calcTotalDate } from 'utils/common/calendar'
import { RootState } from '../../index'

type ServiceType = {
  weeklyCount: number
  totalWeek: number
}

type DateType = {
  year: number
  month: number
  date: number
}

export type ServiceApplicationState = {
  firstVisitDate: DateType
  schedules: string[]
  extraSchedules: string[]
} & ServiceType

const initialState: ServiceApplicationState = {
  weeklyCount: 0,
  totalWeek: 0,
  firstVisitDate: { year: 0, month: 0, date: 0 },
  schedules: [],
  extraSchedules: [],
}

export const serviceApplicationSlice = createSlice({
  name: 'serviceApplication',
  initialState,
  reducers: {
    setService: (state, action: PayloadAction<ServiceType>) => {
      if (state.totalWeek === action.payload.totalWeek && state.weeklyCount === action.payload.weeklyCount) return

      state.totalWeek = action.payload.totalWeek
      state.weeklyCount = action.payload.weeklyCount
      state.firstVisitDate = { year: 0, month: 0, date: 0 }
      state.schedules = []
      state.extraSchedules = []
    },
    setFirstVisitDate: (state, action: PayloadAction<DateType>) => {
      const { year: firstYear, month: firstMonth, date: firstDate } = state.firstVisitDate
      const { year, month, date } = action.payload
      if (firstYear === year && firstMonth === month && firstDate === date) return

      state.firstVisitDate = { year, month, date }
      state.schedules = []
      state.extraSchedules = []
    },
    setSchedules: (state, action: PayloadAction<string[]>) => {
      if (JSON.stringify(state.schedules) === JSON.stringify(action.payload)) return

      state.schedules = [...action.payload]
      state.extraSchedules = []
    },
    setExtraSchedules: (state, action: PayloadAction<string[]>) => {
      state.extraSchedules = [...action.payload]
    },
    initServiceApplication: (state) => {
      state.weeklyCount = 0
      state.totalWeek = 0
      state.firstVisitDate = { year: 0, month: 0, date: 0 }
      state.schedules = []
      state.extraSchedules = []
    },
  },
})

export const calcBonusCount = (serviceCount: number) => {
  if (serviceCount === 10) return 1
  if (serviceCount === 12) return 2
  if (serviceCount === 15) return 3
  if (serviceCount === 16) return 4
  if (serviceCount === 20) return 5
  return 0
}

export const weeklyCountSelector = (state: RootState) => state.serviceApplication.weeklyCount
export const totalWeekSelector = (state: RootState) => state.serviceApplication.totalWeek
export const firstVisitDateSelector = (state: RootState) => state.serviceApplication.firstVisitDate
export const schedulesSelector = (state: RootState) => state.serviceApplication.schedules
export const extraSchedulesSelector = (state: RootState) => state.serviceApplication.extraSchedules

export const getBonusCount = createSelector(weeklyCountSelector, totalWeekSelector, (weeklyCount, totalWeek) => {
  const serviceCount = totalWeek * weeklyCount

  return calcBonusCount(serviceCount)
})

export const getIsServiceOverMonth = createSelector(
  firstVisitDateSelector,
  totalWeekSelector,
  (firstVisitDate, totalWeek) => {
    const { year, month, date } = firstVisitDate
    const totalDate = calcTotalDate(year, month)

    return date + 7 * totalWeek - 1 > totalDate
  }
)

export const getFirstVisitTotalDate = createSelector(firstVisitDateSelector, (firstVisitDate) => {
  const { year, month } = firstVisitDate

  return calcTotalDate(year, month)
})

export const getNextMonthData = createSelector(firstVisitDateSelector, (firstVisitDate) => {
  const { year: firstYear, month: firstMonth } = firstVisitDate

  const nextYear = calcNextYear(firstYear, firstMonth)
  const nextMonth = calcNextMonth(firstMonth)

  return { year: nextYear, month: nextMonth }
})

export const getFirstVisitWeekBlank = createSelector(firstVisitDateSelector, (firstVisitDate) => {
  const { year, month, date } = firstVisitDate

  return calcBlankOfWeek(year, month, date)
})

export const { setService, initServiceApplication, setFirstVisitDate, setSchedules, setExtraSchedules } =
  serviceApplicationSlice.actions
export default serviceApplicationSlice.reducer
