import { ServiceBadgeType } from 'components/StorePage/StoreList/StoreItem/ServiceBadge/ServiceBadge'
import { PaymentType } from 'lib/api/payment/types'
import { Payment, ServicePaymentType, ServiceType } from 'lib/api/service/types'

type GetServiceBadgeTypeProp = {
  service: ServiceType | ServicePaymentType
  payment?: PaymentType | null | Payment
  startDate: string
  endDate: string
}

export const getServiceBadgeType = ({
  service,
  payment,
  startDate,
  endDate,
}: GetServiceBadgeTypeProp): ServiceBadgeType => {
  const isServiceStart = new Date(startDate).getTime() <= new Date().getTime()
  const isServiceEnd = new Date(endDate).getTime() < new Date().getTime()

  const payment_id = 'payment_id' in service ? service.payment_id : service.payment ? service.payment.id : null
  const payment_state = 'payment_id' in service ? service.payment_state : payment ? payment.state : null

  if (!payment_id && !isServiceStart && !isServiceEnd) {
    return 'PAYMENT'
  }

  if (payment && payment.payment_method === 'VBANK' && payment_state === 'PENDING') {
    return 'WAIT_DEPOSIT'
  }

  if (service && !service.paid_at && payment_state === 'PENDING') {
    return 'WAIT_DEPOSIT'
  }

  if (
    !payment &&
    'payment' in service &&
    service.payment &&
    service.payment.refund &&
    (service.payment.refund.state === 'REFUSED' || service.payment.refund.state === 'ACCEPTED')
  ) {
    return 'CANCEL_APPLICATION_EXPIRATION'
  }

  // refund requestedAt => 계약해지 신청일
  // refund refundedAt => 환불일
  // 계약해지 신청하고 + 환불은 안한 상태
  if (
    !payment &&
    'payment' in service &&
    service.payment &&
    service.payment.refund &&
    !!service.payment.refund.requested_at &&
    !service.payment.refund.refunded_at &&
    service.payment.refund.state === 'PENDING'
  ) {
    return 'CANCEL_APPLICATION'
  }

  const isRequestRefund = !!payment?.refund?.requested_at
  const isRefunded = !!payment?.refund?.refunded_at

  if (
    payment &&
    payment.refund &&
    isRequestRefund &&
    (payment.refund.state === 'ACCEPTED' || payment.refund.state === 'REFUSED')
  ) {
    return 'CANCEL_APPLICATION_EXPIRATION'
  }

  if (payment && payment.refund && isRequestRefund && !isRefunded && payment.refund.state === 'PENDING') {
    return 'CANCEL_APPLICATION'
  }

  if (service.is_refund) {
    return 'CANCEL_APPLICATION'
  }

  if (service && payment_state && payment_state === 'FAILED') {
    return 'PAYMENT_FAIL'
  }

  if (isServiceStart && isServiceEnd) {
    return 'EXPIRATION'
  }

  if (isServiceStart && !isServiceEnd) {
    return 'USING'
  }

  if (payment_id && !isServiceStart && !isServiceEnd) {
    return 'CONTRACT'
  }

  return 'CONTRACT'
}
