export const Step = [
  {
    step: 1,
    title: '데이터 기반\n매장 현황 파악',
    pcImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_매장컨디션파악.webp',
    mobileImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_매장컨디션파악_m.png',
    description: (
      <p>
        관리 도입 전, <b>각 매장별 데이터 기반의 현황을 우선적으로 파악</b>합니다.
        <br /> 담당 매니저의 유선 상담을 통해 매장의 기본 정보 및 특이사항(일 방문수 등)을 체크하고 이를 토대로 매장 내
        업무 체크리스트를 준비합니다.
      </p>
    ),
  },
  {
    step: 2,
    title: '캡틴(본사 매니저)\n출동 및 점검',
    pcImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_매니저출동.webp',
    mobileImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_매니저출동_m.png',
    description: (
      <p>
        크루 배치 전, <b>본사 매니저가 직접 관리</b>를 진행하며 매장 현황 및 컨디션을 파악하여 <b>교육 메뉴얼을 준비</b>
        합니다. 전담 크루 배치 시 체계적인 업무 진행을 위함입니다.
      </p>
    ),
  },
  {
    step: 3,
    title: '매장별\n맞춤 메뉴얼 제공',
    pcImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_매장맞춤메뉴얼제작.webp',
    mobileImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_매장맞춤메뉴얼제작_m.png',
    description: (
      <p>
        캡틴(담당 본사 매니저)의 <b>매장 파악 후 멤버스(사장님) 미팅 후 피드백을 반영</b>하여 <b>메뉴얼을 확정</b>
        합니다. 이후 본격적인 크루 배치를 준비합니다.
      </p>
    ),
  },
  {
    step: 4,
    title: '매장별\n담당 크루 배치',
    pcImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_크루배치.webp',
    mobileImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_크루배치_m.png',
    description: (
      <p>
        맞춤 메뉴얼을 토대로 <b>매장 관리에 적합한 브라우니 크루를 배치</b>합니다. 본격적인 관리가 시작되며 약{' '}
        <b>2주간 캡틴(담당 본사 매니저)의 모니터링으로 적합도를 판단합니다.</b>
      </p>
    ),
  },
  {
    step: 5,
    title: '크루 모니터링 및\n퀄리티 관리',
    pcImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_전담크루확정.webp',
    mobileImgSrc: '/image/landing_brwnie_start/브라우니_무인매장_전담크루확정_m.png',
    description: (
      <p>
        <b>모니터링을 통과한 크루가 정기적인 관리를 담당</b>합니다.이후에도 캡틴(본사 담당 매니저)의 모니터링 및
        정기적인 매장 방문을 통해 퀄리티가 유지될 수 있도록 관리됩니다. 통과하지 못하거나, 반복적인 문제가 발생할 경우
        크루 재배치를 통해 매장 관리의 퀄리티를 유지합니다.
      </p>
    ),
  },
]
