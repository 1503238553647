// 상태 정의
// 1. 이용중 / -> 브라우니 계약 완료
// 2. 계약해지 신청 > 계약 해지 진행 중 https://www.figma.com/file/ONX0p4KoaDf36rvxt4zjzI/230612_%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88-%EC%A0%95%EA%B8%B0%EA%B2%B0%EC%A0%9C?type=design&node-id=80-15520&mode=dev
// 3. 결제 실패 + 자동 결제 > 자동 결제 실패

import Icon from 'components/Icon'
import { ServiceBadgeType } from 'components/StorePage/StoreList/StoreItem/ServiceBadge/ServiceBadge'
import Button from 'components/common/Button/Button'
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'
import useDeleteRefundRequestMutation from 'hooks/query/refundRequest/useDeleteRefundRequest'
import useServiceQuery from 'hooks/query/service/useServiceQuery'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ROUTES } from 'utils/common/routes'

const STATE_PROP = {
  COMPLETED: {
    title: '브라우니 계약 완료!',
    description: '아래의 계약 확인서를 꼼꼼히 검토해 주세요!',
  },
  CANCEL: {
    title: '계약 해지 진행 중..',
    description: '영업일 기준 3~5일 정도 소요될 수 있습니다.\n해지와 관련한 담당자 유선 연락을 취할 예정입니다.',
  },
  PAYMENT_FAIL: {
    title: '결제에 실패했어요!',
    description: '고객센터로 연락 주시면 빠른 결제 도와드릴게요',
  },
  SUBSCRIBE_PAYMENT_FAIL: {
    title: '자동 결제에 실패했어요!',
    description: '고객센터로 연락 주시면 빠른 결제 도와드릴게요',
  },
}

export type ServiceTopStateBoxProps = {
  stateProp: ServiceBadgeType
  isSubscribePayment: boolean
  serviceId: string
}

export default function ServiceTopStateBox({ stateProp, isSubscribePayment, serviceId }: ServiceTopStateBoxProps) {
  const history = useHistory()

  const { data: service, isLoading: isServiceLoading } = useServiceQuery({ id: serviceId })

  const [openRevokeCheckModal, setOpenRevokeCheckModal] = useState<boolean>(false)

  const { mutateAsync: deleteRefundRequestMutateAsync } = useDeleteRefundRequestMutation()

  const getStateContent = () => {
    if (stateProp === 'PAYMENT_FAIL') {
      if (isSubscribePayment) return 'SUBSCRIBE_PAYMENT_FAIL'
      return 'PAYMENT_FAIL'
    }
    if (stateProp === 'CANCEL_APPLICATION') return 'CANCEL'
    return 'COMPLETED'
  }

  const handleCustomerService = () => {
    window.open('https://brwnie.channel.io/')
  }

  const handleRevokeCancel = async () => {
    if (serviceId) {
      await deleteRefundRequestMutateAsync({
        service_id: serviceId,
      })

      history.push(ROUTES.STORE)
    }
    setOpenRevokeCheckModal(false)
  }

  const handleOpenRevokeCheckAlert = () => {
    setOpenRevokeCheckModal(true)
  }

  const state = getStateContent()
  const isFail = stateProp === 'PAYMENT_FAIL'
  const { title, description } = STATE_PROP[state]

  return (
    <Container isRevokeCancel={state === 'CANCEL'}>
      <Title>{title}</Title>
      <Description>
        {!isServiceLoading &&
          isFail &&
          service &&
          service.payment_state === 'FAILED' &&
          service?.payment_result_msg && (
            <b>
              결제 실패 사유 : {service?.payment_result_msg}
              <br />
            </b>
          )}
        {description}
      </Description>

      {state === 'COMPLETED' && <Icon name="emojiThumbUp" />}
      {isFail && (
        <Button buttonTheme="primary" width={400} onClick={handleCustomerService} className="centerBtn">
          고객센터 문의
        </Button>
      )}
      {state === 'CANCEL' && <RevokeCancelButton onClick={handleOpenRevokeCheckAlert}>해지 철회</RevokeCancelButton>}

      <ConfirmModal
        open={openRevokeCheckModal}
        title="해지를 철회하시겠어요?"
        subline="다시 서비스를 진행하고 싶다면 해지 철회를 눌러주세요. 환불 절차가 시작되었다면 취소할 수 없어요."
        confirmText="해지 철회"
        onClose={() => setOpenRevokeCheckModal(false)}
        closeText="다음에"
        onConfirm={handleRevokeCancel}
      />
    </Container>
  )
}

const Container = styled.div<{ isRevokeCancel: boolean }>`
  padding: 16px;
  margin: 0px 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 16px;
  background: #f4f7fb;

  ${({ isRevokeCancel }) =>
    isRevokeCancel &&
    css`
      border-radius: 16px;
      border: 1px solid var(--gray-gray-100, #f5f5f5);
      background: var(--white, #fff);
    `}

  ${({ theme }) => theme.media.mobile`
    max-width: calc(100% - 34px);

    & > .centerBtn {
      width: calc(100% - 34px);
      min-width: calc(100% - 34px);
      padding: 16px;
    }
  `}
`

const Title = styled.p`
  color: var(--black, #1e1e2c);
  text-align: center;

  font-size: 20px;
  font-weight: 700;
  line-height: 28px;

  margin-bottom: 4px;
`

const Description = styled.span`
  color: var(--gray-gray-600, #757575);
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
  white-space: pre;
`

const RevokeCancelButton = styled.button`
  width: 100%;
  border-radius: 16px;
  background: var(--gray-gray-200, #e8e8e8);
  color: #212121;
  padding: 0px 32px;
  height: 48px;

  font-size: 16px;
  font-weight: 500;

  cursor: pointer;
`
