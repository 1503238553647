import { DropdownProp } from 'components/Select'
import styled from 'styled-components'
import Dropdown from 'react-dropdown'

export default function SelectBox({ className, options, onChange, placeholder, value }: DropdownProp) {
  return (
    <Container>
      <Dropdown className={className} options={options} onChange={onChange} value={value} placeholder={placeholder} />
    </Container>
  )
}

const Container = styled.div`
  .Dropdown-control {
    width: 100%;
    height: 52px;
    padding: 8px 16px;
    border-radius: 16px;
    background: var(--gray-gray-50, #fafafa);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    color: var(--gray-gray-800, #212121);
  }

  .Dropdown-placeholder {
    color: var(--gray-gray-600, #757575);
    font-size: 16px;
    font-weight: 500;
  }

  .Dropdown-arrow-wrapper {
    width: 24px;
    height: 24px;
  }

  .Dropdown-arrow {
    border-color: #757575 transparent transparent;
  }

  .Dropdown-menu {
    background-color: white;
    border-radius: 20px;
    background: var(--white, #fff);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  }

  .Dropdown-option {
    color: var(--gray-gray-800, #212121);
    display: flex;
    justify-content: space-between;
  }

  .Dropdown-option.is-selected {
    background: var(--gray-gray-100, #f5f5f5);
  }

  .Dropdown-option:hover {
    background: var(--gray-gray-100, #f5f5f5);
  }
`
