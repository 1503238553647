export const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    height: 48,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#B1B1B5',
    borderStyle: 'solid',
    cursor: 'pointer',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: 'none',
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    fontSize: 16,
    color: '#1E1E2C',
    opacity: 0.3,
    right: 12,
    top: '45%',
    marginRight: 0,
  }),
  singleValue: (base: any) => ({
    ...base,
    fontSize: 16,
    color: '#121212',
    right: 12,
    top: '45%',
    marginRight: 0,
  }),
  menu: (base: any) => ({
    ...base,
    margin: 0,
    borderRadius: 8,
    boxShadow: 'none',
    borderWidth: 1,
    // Overwrittes the different states of border
    borderColor: '#B1B1B5',
    borderStyle: 'solid',
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    // borderWidth: 1,
    // Overwrittes the different states of border
    borderColor: '#B1B1B5',
    // borderStyle: 'solid',
    height: '24rem',

    '::-webkit-scrollbar': {
      width: '0.4rem',
      borderRadius: 8,
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 8,
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  option: (base: any, { isDisabled, isFocused }: any) => ({
    ...base,
    backgroundColor: isFocused && '#003399',
    color: isFocused ? 'white' : '#1e1e2c',
    height: 40,
    textAlign: 'center',
    fontSize: 14,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
}

export const DropdownIndicator = () => {
  return null
}
export const IndicatorSeparator = () => {
  return null
}

export function getDateYMD(dateType: 'year' | 'month' | 'day', monthType?: string, leapYear?: boolean) {
  let now = new Date()
  let year = now.getFullYear()
  let result: any[] = []
  let formatMonth: number = 1
  if (monthType) {
    formatMonth = parseInt(monthType.replace(' 일', ''))
  }
  if (dateType === 'year') {
    for (let i = year - 19; i >= year - 100; i--) {
      result.push({ value: `${i} 년`, label: `${i} 년` })
    }
  } else if (dateType === 'month') {
    for (let i = 1; i <= 12; i++) {
      let mm = i > 9 ? i : '0' + i
      result.push({ value: `${mm} 월`, label: `${mm} 월` })
    }
  } else {
    let endDay = 31
    if (formatMonth === 2) {
      endDay = 28
    }
    if (formatMonth === 2 && leapYear) {
      endDay = 29
    }
    if (formatMonth % 2 === 0 && formatMonth !== 2) {
      endDay = 30
    } else if (formatMonth % 2 === 1) {
      endDay = 31
    }
    for (let i = 1; i <= endDay; i++) {
      let dd = i > 9 ? i : '0' + i
      result.push({ value: `${dd} 일`, label: `${dd} 일` })
    }
  }
  return result
}

export type DateControllerProps = {
  dateType: 'year' | 'month' | 'day'
  control: any
  placeholder?: string
  register: any
}
