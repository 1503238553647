import { throttle } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

/**
 * @param throttleTime 스크롤 쓰로틀 시간
 * @returns {totalOffsetY: 스크롤 최상단 유무, scrollPosition: 현재 스크롤 위치}
 */

type useScrollGetProps = {
  throttleTime?: number
}

export function useScrollGet({ throttleTime = 20 }: useScrollGetProps) {
  const [totalOffsetY, setTotalOffsetY] = useState<boolean>(false)
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  const throttledScroll = useMemo(
    () =>
      throttle((e) => {
        if (e.target.scrollTop > 0 && document.body.style.overflowY !== 'hidden') {
          setTotalOffsetY((state) => (state = true))
        } else if (e.target.scrollTop === 0) setTotalOffsetY((state) => (state = false))
        setScrollPosition(e.target.scrollTop)
      }, throttleTime),
    [throttleTime]
  )

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll, {
      capture: true,
      passive: true,
    })

    return () => window.removeEventListener('scroll', throttledScroll)
  }, [throttledScroll])

  return { totalOffsetY, scrollPosition }
}
