import ModalLayout from 'components/Layout/ModalLayout/ModalLayout'
import styled from 'styled-components'
import ServiceCancelNotice from './ServiceCancelNotice'
import { useState } from 'react'
import SelectBox from 'components/SelectBox/SelectBox'
import useAuth from 'hooks/query/auth/useAuth'
import usePostRefundRequest from 'hooks/query/refundRequest/usePostRefundRequest'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'utils/common/routes'
import useToast from 'hooks/useToast'
import { bankOptions } from 'lib/utils/bankOption'
import useDeleteService from 'hooks/query/service/useDeleteService'

type ServiceCancelModalProp = {
  open: boolean
  loading?: boolean
  isVbank?: boolean
  isPaid?: boolean
  serviceId: string
  onClose: () => void
  onConfirm?: () => Promise<void>
}

export default function ServiceCancelModal({ open, onClose, isPaid, isVbank, serviceId }: ServiceCancelModalProp) {
  const history = useHistory()
  const toast = useToast()
  const { auth } = useAuth()
  const [bankType, setBankType] = useState<string>('')
  const [accountNumber, setAccountNumber] = useState<string>('')

  const { mutateAsync: mutateDeleteService } = useDeleteService()
  const { mutateAsync: mutatePostRefundRequest } = usePostRefundRequest()

  const handleClose = () => {
    onClose()
    setAccountNumber('')
    setBankType('')
  }

  const handleConfirm = async () => {
    if (!isPaid) {
      await mutateDeleteService({ id: serviceId })
    } else {
      await mutatePostRefundRequest({
        service_id: serviceId,
        refundAcctNum: accountNumber,
        refundBankCode: bankType,
        refundAcctName: auth?.real_name,
      })
    }

    toast.open('계약 해지 신청이 완료되었어요! 확인 후, 계약 해지가 완료될 거예요.')

    handleClose()

    history.push(ROUTES.STORE)
  }

  const isDisabled = isVbank && isPaid ? !bankType || !accountNumber || accountNumber.length < 10 : false

  return (
    <ModalLayout open={open} onClose={handleClose}>
      <ModalLayout.Header color="black">😢 브라우니 계약을 해지하시겠어요?</ModalLayout.Header>
      <ModalLayout.Body>
        {/* 무통장 계약 해지일 경우 */}
        {isVbank && isPaid && (
          <VbankInfoFormWrap>
            <SubTitle>환불 계좌</SubTitle>
            <VbankInfoForm>
              <SelectBox
                className={`bank-code-select ${bankType ? 'selected' : ''}`}
                placeholder="은행 선택"
                options={bankOptions}
                value={bankType}
                onChange={(option) => setBankType(option.value)}
              />
              <TextField
                placeholder="계좌번호 입력"
                onChange={(event) => setAccountNumber(event.target.value)}
                value={accountNumber}
              />
            </VbankInfoForm>
          </VbankInfoFormWrap>
        )}
        <StyledLine />
        <ServiceCancelNotice />
      </ModalLayout.Body>
      <ModalLayout.Footer>
        <CloseButton onClick={onClose}>취소</CloseButton>
        <ConfirmButton onClick={handleConfirm} disabled={isDisabled}>
          해지하기
        </ConfirmButton>
      </ModalLayout.Footer>
    </ModalLayout>
  )
}

const VbankInfoFormWrap = styled.div`
  margin-bottom: 16px;
`
const SubTitle = styled.h4`
  color: var(--gray-gray-800, #212121);
  font-size: 20px;
  font-weight: 700;
`

const VbankInfoForm = styled.div`
  margin-top: 16px;
`

const TextField = styled.input`
  display: flex;
  width: 100%;
  height: 52px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--gray-gray-50, #fafafa);

  font-size: 16px;
  font-weight: 500;

  border: none;
  &::placeholder {
    color: var(--gray-gray-600, #757575);
  }
`

const StyledLine = styled.hr`
  width: 100%;
  height: 1px;
  background: #edeff1;
  border: none;
`

const Button = styled.button`
  height: 48px;

  display: flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`

const CloseButton = styled(Button)`
  background: var(--gray-gray-200, #e8e8e8);
  color: var(--gray-gray-800, #212121);
`

const ConfirmButton = styled(Button)`
  background: var(--red-red-400, #d32f2f);
  color: var(--white, #fff);
`
