import { getAuth } from 'lib/api/auth/getAuth'
import { postSocialOption, SocialOptionReq } from 'lib/api/auth/postSocialOption'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { ROUTES } from 'utils/common/routes'

export default function usePostSocialSignUp() {
  const history = useHistory()
  const queryClient = useQueryClient()
  const authKey = 'authCheck'

  return useMutation((value: SocialOptionReq) => postSocialOption(value), {
    onSuccess: async () => {
      await queryClient.prefetchQuery(authKey, getAuth)
      history.push(ROUTES.STORE)
    },
  })
}
