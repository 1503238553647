import client from '../client'
import { StoreType } from './types'

export async function deleteStore({ id }: DeleteStoreParam) {
  const response = await client.delete<StoreType>(`/service-api/v1/store/${id}`)

  return response.data
}

export type DeleteStoreParam = {
  id: string
}
