import Icon from 'components/Icon/Icon'
import styled from 'styled-components'

export default function ServiceNotice() {
  return (
    <Container>
      <TitleWrap>
        <Icon name="grayInfoLine" />
        <span>유의사항</span>
      </TitleWrap>
      <NoticeList>
        <NoticeItem>1개의 매장에는 최대 2개의 서비스까지 신청 가능합니다.</NoticeItem>
        <NoticeItem>정기결제 매장의 경우 1개의 서비스만 신청 가능합니다.</NoticeItem>
        <NoticeItem>결제 단계의 서비스가 있을 경우에는 결제를 완료/취소 후에 서비스를 추가할 수 있습니다.</NoticeItem>
      </NoticeList>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  background: #fafafa;
  border-radius: 12px;
  margin-top: 16px;
  padding: 16px;

  color: #757575;
  font-size: 14px;

  ${({ theme }) => theme.media.mobile`margin-bottom: 60px`}
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  & > span {
    margin-left: 8px;
  }
`

const NoticeList = styled.ul`
  font-weight: 400;
  line-height: 24px;
  list-style: disc;
  margin-top: 8px;
  margin-inline-start: 20px;
`
const NoticeItem = styled.li`
  //   margin-bottom: 20px;
`
