import styled, { keyframes } from 'styled-components'

export type CustomRadioButtonProps = {
  children: React.ReactNode
  animationOn?: boolean
} & React.LabelHTMLAttributes<HTMLLabelElement>

/**
 * @description input을 children으로 내려보내고 htmlFor로 연결시키면 됩니다.
 * @param children
 */

export default function CustomRadioButton({ children, htmlFor, animationOn }: CustomRadioButtonProps) {
  return (
    <StyledCustomRadioButton htmlFor={htmlFor}>
      {children}
      <span />
      {animationOn && (
        <ExplodeBullet>
          {Array.from({ length: 8 }).map((_, i) => (
            <li key={i} />
          ))}
        </ExplodeBullet>
      )}
    </StyledCustomRadioButton>
  )
}

const bulletOne = keyframes`
  0% {
    opacity: 0;
    transform: rotate(90deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: translate(5px, -8px) rotate(90deg);
  }
`

const bulletTwo = keyframes`
  0% {
    opacity: 0;
    transform: rotate(-45deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: translate(10px, -7px) rotate(-45deg);
  }
`

const bulletThree = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: translate(10px, 0);
  }
`

const bulletFour = keyframes`
  0% {
    opacity: 0;
    transform: rotate(45deg); 
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: translate(10px, 7px) rotate(45deg);
  }
`

const bulletFive = keyframes`
  0% {
    opacity: 0;
    transform: rotate(90deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: rotate(90deg) translate(10px, -5px);
  }
`

const bulletSix = keyframes`
  0% {
    opacity: 0;
    transform: rotate(-45deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: rotate(-45deg) translate(-8px, 4px);
  }
`

const bulletSeven = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
  }
`

const bulletEight = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: rotate(45deg) translate(-4px, -4px);
  }
`

const mobileBulletOne = keyframes`
  0% {
    opacity: 0;
    transform: rotate(90deg) translate(0, 0);
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: rotate(90deg) translate(-3px, -5px);
  }
`

const mobileBulletTwo = keyframes`
  0% {
    opacity: 0;
    transform: rotate(-45deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: rotate(-45deg) translate(9px, 3px);
  }
`

const mobileBulletThree = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: translate(8px, 0);
  }
`

const mobileBulletFour = keyframes`
  0% {
    opacity: 0;
    transform: rotate(45deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: rotate(45deg) translate(9px, -3px);
  }
`

const mobileBulletFive = keyframes`
  0% {
    opacity: 0;
    transform: rotate(90deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: rotate(90deg) translate(5px, -5px);
  }
`

const mobileBulletSix = keyframes`
  0% {
    opacity: 0;
    transform: rotate(-45deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
    transform: rotate(-45deg) translate(-3px, 3px);
  }
`

const mobileBulletSeven = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100%{ 
    width: 0;
    opacity: 0;
    transform: translate(0, 0);
  }
`

const mobileBulletEight = keyframes`
  0% {
    opacity :0;
    transform: rotate(45deg);
  }
  20% {
    opacity :1;
  }
  100%{ 
    width: 0;
    opacity: 0;
    transform: rotate(45deg) translate(-3px, -3px);
  }
`

const StyledCustomRadioButton = styled.label`
  display: flex;
  cursor: pointer;
  position: relative;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      transition: all 0.3s ease-in-out;
      border-color: ${({ theme }) => theme.colors.main};
      background: radial-gradient(${({ theme }) => theme.colors.main} 35%, rgba(255, 0, 0, 0) 36%);
    }

    &:checked ~ ul {
      display: block;
    }
  }

  span {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    border: 0.2rem solid ${({ theme }) => theme.colors.gray};
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    span {
      width: 20px;
      height: 20px;
    }
  `}
`

const ExplodeBullet = styled.ul`
  display: none;
  position: absolute;
  top: 0;
  left: 0;

  li {
    width: 10px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.main};
    position: absolute;
    opacity: 0;

    &:nth-child(1) {
      top: -6px;
      left: 7px;
      transform: rotate(90deg);
      animation: ${bulletOne} 0.7s forwards;
    }

    &:nth-child(2) {
      top: -1px;
      left: 19px;
      transform: rotate(-45deg);
      animation: ${bulletTwo} 0.7s forwards;
    }

    &:nth-child(3) {
      top: 11px;
      left: 24px;
      animation: ${bulletThree} 0.7s forwards;
    }

    &:nth-child(4) {
      top: 23px;
      left: 19px;
      transform: rotate(45deg);
      animation: ${bulletFour} 0.7s forwards;
    }

    &:nth-child(5) {
      transform: rotate(90deg);
      top: 28px;
      left: 7px;
      animation: ${bulletFive} 0.7s forwards;
    }

    &:nth-child(6) {
      top: 23px;
      left: -5px;
      transform: rotate(-45deg);
      animation: ${bulletSix} 0.7s forwards;
    }

    &:nth-child(7) {
      top: 11px;
      left: -10px;
      animation: ${bulletSeven} 0.7s forwards;
    }

    &:nth-child(8) {
      top: -1px;
      left: -5px;
      transform: rotate(45deg);
      animation: ${bulletEight} 0.7s forwards;
    }
  }

  // mobile
  @media only screen and (max-width: 768px) {
    li {
      &:nth-child(1) {
        left: 7px;
        animation: ${mobileBulletOne} 0.7s forwards 0.0001s;
      }

      &:nth-child(2) {
        top: 0px;
        left: 18px;
        animation: ${mobileBulletTwo} 0.7s forwards 0.0001s;
      }

      &:nth-child(3) {
        top: 9px;
        left: 22px;
        animation: ${mobileBulletThree} 0.7s forwards 0.0001s;
      }

      &:nth-child(4) {
        top: 19px;
        left: 17px;
        animation: ${mobileBulletFour} 0.7s forwards 0.0001s;
      }

      &:nth-child(5) {
        top: 24px;
        animation: ${mobileBulletFive} 0.7s forwards 0.0001s;
      }

      &:nth-child(6) {
        top: 19px;
        left: -3px;
        animation: ${mobileBulletSix} 0.7s forwards 0.0001s;
      }

      &:nth-child(7) {
        top: 9px;
        left: -8px;
        animation: ${mobileBulletSeven} 0.7s forwards 0.0001s;
      }

      &:nth-child(8) {
        top: -1px;
        left: -3px;
        animation: ${mobileBulletEight} 0.7s forwards 0.0001s;
      }
    }
  }
`
