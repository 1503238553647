import { browserName } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'

export default function ClosePage() {
  const location = useLocation()
  const history = useHistory()
  const { service, oid } = queryString.parse(location.search)
  const isKakaoBrowser = browserName === 'WebKit' || browserName === 'Chrome WebView'

  if (isKakaoBrowser && service && oid) {
    history.push(`/service/${service}?oid=${oid}`)
  } else {
    window.opener = null
    window.open('', '_self')
    window.close()
  }
  return <div></div>
}
