import { BottomSheet, SelectBox } from 'ohds-web'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

interface ServiceCountSelectBoxProps {
  autoOpen: boolean
  label: string
  value?: number
  for1Week: boolean
  for탁구발전소24: boolean
  serviceCountOptions: number[]
  onOptionClick: (serviceCount: number) => void
  storeType: string
}

export function ServiceCountSelectBox({
  autoOpen,
  label,
  value,
  for1Week,
  for탁구발전소24,
  serviceCountOptions,
  onOptionClick,
  storeType,
}: ServiceCountSelectBoxProps) {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState<boolean>(false)

  useEffect(() => {
    if (autoOpen) {
      setIsBottomSheetOpen(true)
    }
  }, [autoOpen])

  const handleSelectBoxClick = () => {
    setIsBottomSheetOpen(true)
  }

  const handleBottomSheetClose = () => {
    setIsBottomSheetOpen(false)
  }

  return (
    <SelectBox
      label={label}
      value={value ? `${value}회` : undefined}
      onSelectBoxClick={handleSelectBoxClick}
      BottomSheet={
        <StyledBottomSheet
          open={isBottomSheetOpen}
          title="관리 주기를 선택해 주세요"
          subTitle={
            !(storeType === '스터디카페' || storeType === '전담게이트' || storeType === '전담플레이' || storeType === '탁구발전소24')
              ? '1주 패키지는 평일만 가능해요'
              : undefined
          }
          onClose={handleBottomSheetClose}
        >
          <StyledUl>
            {serviceCountOptions.map((serviceCount, index) => (
              <StyledLi
                key={index}
                onClick={() => {
                  onOptionClick(serviceCount)
                  handleBottomSheetClose()
                }}
              >
                <StyledP>
                  주 {serviceCount}회{' '}
                  {!for1Week ? (
                    for탁구발전소24 ? (
                      '(평일만 가능)'
                    ) : serviceCount === 1 || serviceCount === 2 ? (
                      '(평일만 가능)'
                    ) : (
                      <>
                        (평일 + <b>주말도 가능</b>)
                      </>
                    )
                  ) : (
                    ''
                  )}
                </StyledP>
              </StyledLi>
            ))}
          </StyledUl>
        </StyledBottomSheet>
      }
    />
  )
}

const StyledBottomSheet = styled(BottomSheet)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    position: fixed;
    left: 50%;
  }
`

const StyledUl = styled.ul``

const StyledLi = styled.li`
  width: 100%;
  padding: 16px;
  background-color: var(--gray0);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: var(--gray-gray-100, #f5f5f5);
  }
`

const StyledP = styled.p<{ disabled?: boolean }>`
  color: var(--gray800);
  display: block;
  font-weight: 400;
  font-size: var(--p1);
  line-height: var(--p1LineHeight);

  b {
    font-weight: 700;
    color: var(--brand300);
  }
`
