import { useHistory } from 'react-router'
import styled, { css } from 'styled-components'
import { ROUTES } from 'utils/common/routes'

export type ApplyBannerProps = {
  marginTop?: number
  mobileMarginTop?: number
  pointPage?: boolean
}

export default function ApplyBanner({ marginTop = 0, mobileMarginTop = 0, pointPage }: ApplyBannerProps) {
  const history = useHistory()

  const handleBannerClick = () => history.push(ROUTES.STORE)

  return (
    <StyledApplyBanner
      onClick={handleBannerClick}
      marginTop={marginTop}
      mobileMarginTop={mobileMarginTop}
      pointPage={pointPage}
    >
      <div className="text__container">
        <p>서비스 신청하기</p>
      </div>
      <div className="image__container">
        <img src="image/브라우니_무인매장_서비스신청.png" alt="Self Laundry Example" />
      </div>
    </StyledApplyBanner>
  )
}

const StyledApplyBanner = styled.article<{ marginTop: number; mobileMarginTop: number; pointPage?: boolean }>`
  width: ${({ pointPage }) => (pointPage ? 652 : 800)}px;
  height: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  margin-top: ${({ marginTop }) => marginTop}px;

  .text__container {
    width: ${({ pointPage }) => (pointPage ? 430 : 528)}px;
    height: 200px;
    background: linear-gradient(90.15deg, #0066fe 33%, #00c780 66%, #00d186 100%);
    display: flex;
    align-items: center;
    padding-left: ${({ pointPage }) => (pointPage ? 44 : 48)}px;

    p {
      font-size: 52px;
      font-weight: 700;
      color: white;
      line-height: 52px;
      letter-spacing: -1px;
    }
  }

  .image__container {
    overflow: hidden;
    width: ${({ pointPage }) => (pointPage ? 222 : 272)}px;
    height: 200px;
    position: relative;

    img {
      width: 272px;
      height: 200px;
      position: absolute;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 296px;
    height: 96px;

    .text__container {
      width: 196px;
      height: 96px;
      padding-left: 16px;

      p {
        font-size: 24px;
      }
    }

    .image__container {
      width: 100px;
      height: 96px;

      img {
        right: unset;
        height: 96px;
        object-fit: cover;
        width: 100px;
      }
    }

    ${({ mobileMarginTop }) =>
      css`
        margin-top: ${mobileMarginTop}px;
      `}
  }
`
