import {
  PatchServicePaymentParam,
  PatchServicePaymentReq,
  patchServicePayment,
} from 'lib/api/service/patchServicePayment'
import { useMutation, useQueryClient } from 'react-query'
import useServiceQuery from './useServiceQuery'

export default function usePatchServicePayment() {
  const queryClient = useQueryClient()
  const createServiceKey = useServiceQuery.createKey

  return useMutation((value: PatchServicePaymentReq & PatchServicePaymentParam) => patchServicePayment(value), {
    onSuccess: (res) => {
      const serviceKey = createServiceKey(res.id)
      queryClient.invalidateQueries(serviceKey)
    },
  })
}
