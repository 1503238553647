import Icon from 'components/Icon'
import ServiceIntroduceModal from 'components/ServiceIntroduceModal'
import { useState } from 'react'
import styled from 'styled-components'
import CoworkCompany from '../CoworkCompany'

export default function LandingCoworkSection() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <LandingCoworkSectionWrapper>
        <CoworkCompany />
        <PcCallWrapper>
          <div className="call">
            <span>전화 문의</span>
            <h2>
              <Icon name="Call" />
              070-7122-0913
            </h2>
          </div>
          <hr />
          <PcBtnWrapper>
            <QuestionWrapper>
              브라우니가 궁금해요
              <div className="rectangle" />
            </QuestionWrapper>
            <DownloadButton onClick={() => setIsOpen(true)}>
              서비스 소개서 다운
              <Icon name="Download" />
            </DownloadButton>
          </PcBtnWrapper>
        </PcCallWrapper>
        <MobileBtnWrapper>
          <QuestionWrapper>
            브라우니가 궁금해요
            <div className="rectangle" />
          </QuestionWrapper>
          <MobileDownloadButton onClick={() => setIsOpen(true)}>
            서비스 소개서 다운
            <Icon name="Download" />
          </MobileDownloadButton>
        </MobileBtnWrapper>
      </LandingCoworkSectionWrapper>
      {isOpen && <ServiceIntroduceModal open={isOpen} onClose={() => setIsOpen(false)} />}
    </>
  )
}

const LandingCoworkSectionWrapper = styled.section`
  width: 100%;
  padding: 150px 125px 30px;

  position: relative;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background-color: var(--gray100);

  ${({ theme }) => theme.media.mobile`
    display: flex;
    flex-direction: column;
    align-items: center;

    height: auto;
    padding: 60px 16px 12px 16px;
`}
`

const PcCallWrapper = styled.div`
  position: relative;

  width: calc(100% - 64px);
  max-width: 1280px;
  height: 137px;

  margin-bottom: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 40px;
  background-color: var(--gray100);

  & > hr {
    width: 1px;
    height: 28px;
    background-color: var(--gray400);
    border: none;
    margin: 0px 100px;

    @media only screen and (max-width: 768px) {
      margin-top: 40px;
      margin-bottom: 16px;
    }
  }

  .call {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    & > span {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: start;
      color: #1e1e2c;
    }

    & > h2 {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;

      color: var(--gray800);

      & > svg {
        width: 32px;
        height: 32px;
        margin-right: 12px;

        path {
          fill: var(--brand300);
        }
      }
    }
  }

  ${({ theme }) => theme.media.mobile`
      display: none;
  `}
`

const PcBtnWrapper = styled.div`
  position: relative;
`

const MobileBtnWrapper = styled.div`
  margin-top: 32px;
  position: relative;
  display: none;

  ${({ theme }) => theme.media.mobile`
    display: flex;
    margin-top: auto;
    margin-bottom: 52px;
  `}
`

const QuestionWrapper = styled.div`
  position: absolute;
  top: -16px;
  left: 12px;
  padding: 2px 8px;
  background-color: var(--brand300);
  color: var(--gray0);
  letter-spacing: -0.3px;

  border-radius: 99px;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .rectangle {
    position: absolute;
    left: 14px;
    bottom: -2px;
    width: 6px;
    height: 6px;
    background-color: var(--brand300);
    transform: rotate(-45deg);
    border-radius: 1px;
  }

  ${({ theme }) => theme.media.mobile`
    width: 116px;
    left: 50%;
    transform: translateX(-50%);

    .rectangle {
      left: 55px;
    }  
  `}
`

const DownloadButton = styled.div`
  height: 48px;
  padding: 0 32px;
  font-size: var(--p1);
  line-height: var(--p1LineHeight);
  border-radius: 16px;
  background-color: var(--gray200);
  color: var(--gray800);
  font-weight: 600;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  & > svg {
    margin-left: 8px;
  }

  &:hover {
    background-color: var(--gray300);
  }
`

const MobileDownloadButton = styled.div`
  display: flex;
  width: 182px;
  height: 40px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 16px;
  background-color: var(--gray200);
  color: var(--gray800);
  font-weight: 600;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  & > svg {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }

  &:active {
    background-color: var(--gray300);
  }
`
