import {
  patchContractedDate,
  PatchContractedDateParam,
  PatchContractedDateReq,
} from 'lib/api/contractedDate/patchContractedDate'
import { useMutation } from 'react-query'

export default function usePatchContractedDate() {
  return useMutation((value: PatchContractedDateParam & PatchContractedDateReq) => patchContractedDate(value))
}
