import { getResetPassword, ResetPasswordParams } from 'lib/api/authVerify/getResetPassword'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { ROUTES } from 'utils/common/routes'

export default function useGetResetPassword() {
  const history = useHistory()

  return useMutation((value: ResetPasswordParams) => getResetPassword(value), {
    onSuccess: async () => {},
    onError: async () => {
      history.push(ROUTES.AUTH_ERROR)
    },
  })
}
