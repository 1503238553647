import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import SocialForm from '../LoginForm/SocialForm'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { customStyles, getDateYMD } from './utils'
import Button from '../../common/Button'
import Icon from '../../Icon'
import usePostEmailSignUp from 'hooks/query/auth/usePostEmailSignUp'
import AuthTimer from '../AuthTimer'
import usePostSendPhone from 'hooks/query/authVerify/usePostSendPhone'
import usePatchPhoneVerify from 'hooks/query/authVerify/usePatchPhoneVerify'
import Spinner from 'components/common/Spinner'
import { Link, useHistory } from 'react-router-dom'
import useAuthQuery from 'hooks/query/auth/useAuthQuery'
import { ROUTES } from 'utils/common/routes'
import AlertModal from 'components/common/AlertModal'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import useTermData from '../../../data/useTermData.json'
import CustomCheckbox from 'components/common/CustomCheckbox'
import dayjs from 'dayjs'

export interface SignUpFormInput {
  name: string
  email: string
  password: string
  passwordRepeat: string
  phone: string
  referral_code: string
  year: { label: string; value: string }
  month: { label: string; value: string }
  day: { label: string; value: string }
  termCheck: boolean
}

export default function SignUpForm() {
  let date = new Date()
  const [withdrawalPhoneVerifyModal, setWithdrawalPhoneVerifyModal] = useState<boolean>(false)
  const [genderStatus, setGenderStatus] = useState<'U' | 'M' | 'F'>('U')
  const [termCheck, setTermCheck] = useState<boolean>(false)
  const [passwordShown, setPasswordShown] = useState<boolean>(false)
  const [phoneIndex, setPhoneIndex] = useState<string>('인증')
  const [sendStatus, setSendStatus] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [timerDate, setTimerDate] = useState<number>(date.getTime())
  const [verifyCode, setVerifyCode] = useState<string>('')
  const [phoneVerify, setPhoneVerify] = useState<boolean>(false)
  const [signUpLoading, setSignUpLoading] = useState<boolean>(false)
  const [inputFocusCheck, setInputFocusCheck] = useState<boolean>(false)
  const [phoneVerifyCheck, setPhoneVerifyCheck] = useState<boolean>(false)
  const [getRemainTime, setGetRemainTime] = useState<boolean>(false)
  const [submitState, setSubmitState] = useState<boolean>(false)
  const [clickSendButton, setClickSendButton] = useState<boolean>(false)

  const [sendButton, setSendButton] = useState<boolean>(false)

  const [checkLeapYear, setCheckLeapYear] = useState<boolean>(false)
  const [onChangeValueYear, setOnChangeValueYear] = useState<{ value: string | null; label: string | null }>({
    value: null,
    label: null,
  })
  const [onChangeValueDay, setOnChangeValueDay] = useState<{ value: string | null; label: string | null }>({
    value: null,
    label: null,
  })
  const [onChangeValueMonth, setOnChangeValueMonth] = useState<{ value: string | null; label: string | null }>({
    value: null,
    label: null,
  })

  const history = useHistory()
  const { data: auth } = useAuthQuery(
    {},
    {
      retry: 0,
      refetchOnWindowFocus: true,
    }
  )

  useEffect(() => {
    if (!!(auth && auth.opt_check)) history.push(ROUTES.STORE)
    else if (!!(auth && !auth.opt_check)) {
      history.push(ROUTES.AUTH_SOCIAL_OPTION)
    }
  })

  const { mutateAsync: mutatePostEmailSignUp, isError, error, isLoading: postEmailSignUpLoading } = usePostEmailSignUp()
  const {
    mutateAsync: mutatePostSendPhone,
    isLoading: postSendPhoneLoading,
    isError: sendIsError,
    error: sendError,
    data: sendData,
  } = usePostSendPhone()

  const {
    mutateAsync: mutatePatchPhoneVerify,
    data,
    isError: verifyIsError,
    error: verifyError,
    isLoading: patchPhoneVerifyLoading,
  } = usePatchPhoneVerify()
  let signUpErrorMessage = error as any
  let sendErrorMessage = sendError as any
  let verifyErrorMessage = verifyError as any

  useEffect(() => {
    if (data?.verify) {
      setPhoneVerify(true)
      setPhoneIndex('')
    }
    if (isError) {
      setSignUpLoading(false)
    }
    sendData && setSendStatus(true)

    sendData && setPhoneIndex('재전송')
  }, [data, sendIsError, isError, sendData])

  const handleClickSendPhone = async () => {
    if (postSendPhoneLoading) return
    setGetRemainTime(false)
    setInputFocusCheck(false)
    setClickSendButton(true)
    setTimerDate(new Date().setMinutes(new Date().getMinutes() + 10))
    if (!(sendIsError && sendErrorMessage.response.status === 410)) {
      await mutatePostSendPhone({ phone: phoneNumber })
    }
    setWithdrawalPhoneVerifyModal(true)
  }

  const handleClickPhoneVerify = async () => {
    if (patchPhoneVerifyLoading) return
    setInputFocusCheck(true)
    await mutatePatchPhoneVerify({ phone: phoneNumber, user_input_code: verifyCode })
    setInputFocusCheck(false)
  }

  const handleClickGender = (gender: 'M' | 'F') => {
    setGenderStatus(gender)
  }

  const getRemainTimer = () => {
    setGetRemainTime(true)
    setInputFocusCheck(false)
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const handleChangeVerifyCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setVerifyCode(value)
  }

  const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setPhoneNumber(value)
  }

  const handleFocusPhoneNumber = () => {
    setInputFocusCheck(true)
    setPhoneVerifyCheck(false)
  }

  const handleSubmitSignUp = () => {
    setSendButton(true)
    setSubmitState(true)
  }

  const DropdownIndicator = () => {
    return null
  }
  const IndicatorSeparator = () => {
    return null
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'all' })

  const onSubmit = async (data: SignUpFormInput) => {
    if (postEmailSignUpLoading) return
    setSubmitState(true)
    setInputFocusCheck(false)
    if (!clickSendButton) {
      setPhoneVerifyCheck(true)
    }

    if (phoneVerify && termCheck) {
      setSignUpLoading(true)
      let birthDate = ''
      if (data.month && data.day) {
        birthDate = data.month.value.replace(' 월', '') + data.day.value.replace(' 일', '')
      } else {
        birthDate = ''
      }
      await mutatePostEmailSignUp({
        real_name: data.name,
        email: data.email,
        password: data.password,
        phone: phoneNumber,
        referral_code: null,
        birth_year: data.year ? data.year.value.replace(' 년', '') : '',
        birth_day: birthDate,
        gender: genderStatus,
        marketing_agree: getValues(useTermData[3].subject),
      })
      // 카카오 회원가입 완료 로그 전송
      LogManager.Instance.sendLog('tag', '콘텐츠조회', LOGGER.KAKAO)
      LogManager.Instance.sendLog(
        'sign_up_complete',
        {
          method: 'email',
          user_id: 'NULL',
          gender: genderStatus,
          recommendation_check: data.referral_code?.length === 0,
          recommendation_code: data.referral_code,
          signup_date: dayjs().format('YYYY-MM-DD'),
          age: data.year ? dayjs().diff(dayjs(data.year.value.replace(' 년', '')), 'year') : 0,
          signup_mtk_agreement: getValues(useTermData[3].subject),
        },
        LOGGER.GTM
      )
    }
  }

  const password = useRef<any>(null)
  password.current = watch('password')

  const passwordRepeat = useRef<any>(null)
  passwordRepeat.current = watch('passwordRepeat')

  const checkSamePassword = () => {
    if (
      watch('password') !== watch('passwordRepeat') &&
      watch('password') &&
      watch('password').length !== 0 &&
      watch('passwordRepeat') &&
      watch('passwordRepeat').length !== 0
    ) {
      return true
    }
    return false
  }

  const handleChangeYear = (e: { value: string; label: string }) => {
    if (e.value) {
      setOnChangeValueYear({ label: e.label, value: e.value })
      setValue('year', { value: e.value, label: e.label })
    }
    const value = e.value
    let year = parseInt(value.replace(' 년', ''))
    let leapYearYn = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

    if (leapYearYn) {
      setCheckLeapYear(true)
    } else {
      setCheckLeapYear(false)
    }
    if (
      value &&
      onChangeValueDay.value !== null &&
      onChangeValueDay.label !== null &&
      onChangeValueMonth.label === '02 월'
    ) {
      let day = parseInt(onChangeValueDay.label.replace(' 일', ''))
      if (leapYearYn && (day === 28 || day === 30 || day === 31)) {
        setOnChangeValueDay({ value: '29 일', label: '29 일' })
        setValue('day', { value: '29 일', label: '29 일' })
      }
      //윤년이 아닐 때는 원래대로 변경
      if (!leapYearYn) {
        setOnChangeValueDay({ value: '28 일', label: '28 일' })
        setValue('day', { value: '28 일', label: '28 일' })
      }
    }
  }

  const handleChangeMonth = (e: { value: string; label: string }) => {
    if (e.value) {
      setOnChangeValueMonth({ label: e.label, value: e.value })
      setValue('month', { label: e.label, value: e.value })
    }
    const value = e.value
    let year = 5
    if (onChangeValueYear.label) {
      year = parseInt(onChangeValueYear.label.replace(' 년', ''))
    }
    let leapYearYn = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

    if (leapYearYn) {
      setCheckLeapYear(true)
    } else {
      setCheckLeapYear(false)
    }
    if (value && onChangeValueDay.value !== null && onChangeValueDay.label !== null) {
      let month = parseInt(value.replace(' 월', ''))
      let day = parseInt(onChangeValueDay.label.replace(' 일', ''))
      if (month === 2 && (day === 29 || day === 30 || day === 31)) {
        setOnChangeValueDay({ value: '28 일', label: '28 일' })
        setValue('day', { value: '28 일', label: '28 일' })
      } else if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
        setOnChangeValueDay({ value: '30 일', label: '30 일' })
        setValue('day', { value: '30 일', label: '30 일' })
      }
      if (leapYearYn && (day === 28 || day === 30 || day === 31)) {
        setOnChangeValueDay({ value: '29 일', label: '29 일' })
        setValue('day', { value: '29 일', label: '29 일' })
      }
    }
  }

  const handleChangeDay = (e: { value: string; label: string }) => {
    if (e.value) {
      setOnChangeValueDay({ label: e.label, value: e.value })
      setValue('day', { value: e.value, label: e.label })
    }
  }

  const handleCheckWholeTerm = () => {
    setSubmitState(false)
    //하나도 체크 안되있으면
    if (useTermData.every((item) => getValues(`${item.subject}`) === false)) {
      useTermData.map((item) => setValue(`${item.subject}`, true))
      setTermCheck(true)
      //모두 체크되어 있으면
    } else if (useTermData.every((item) => getValues(`${item.subject}`) === true)) {
      useTermData.map((item) => setValue(`${item.subject}`, false))
      setTermCheck(false)
      //하나라도 체크되어있으면
    } else {
      useTermData.map((item) => setValue(`${item.subject}`, true))
      setTermCheck(true)
    }
  }

  const handleCheckDetailTerm = (itemSubject: string) => {
    setSubmitState(false)
    setValue(itemSubject, !getValues(itemSubject))
    const essentialTermData = useTermData.slice(0, 3)
    if (essentialTermData.every((item) => getValues(`${item.subject}`) === false)) {
      setTermCheck(false)
    } else if (essentialTermData.every((item) => getValues(`${item.subject}`) === true)) {
      setTermCheck(true)
    } else {
      setTermCheck(false)
    }
  }

  return (
    <>
      <AlertModal
        title="인증 문자 발송"
        headline="발송된 문자의 인증 번호를 입력해 주세요."
        open={withdrawalPhoneVerifyModal}
        onConfirm={() => {
          setWithdrawalPhoneVerifyModal(false)
        }}
      />
      <SignUpWrapper>
        <ToLogin>
          이미 계정이 있나요? <Link to={ROUTES.AUTH_LOGIN}>로그인</Link>
        </ToLogin>
        <SocialForm label={'SNS 간편 회원가입'} />
        <Divider />
        <SignUpFormWrapper onSubmit={handleSubmit(onSubmit)}>
          <RequireTitle>필수</RequireTitle>
          <FormWrapper>
            <FormInput
              placeholder="이름"
              type="text"
              {...register('name', {
                required: true,
              })}
            />
          </FormWrapper>
          <FormWrapper>
            <FormInput
              placeholder="이메일 주소"
              type="email"
              {...register('email', {
                required: true,
                pattern: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
              })}
              onFocus={() => setInputFocusCheck(true)}
            />
            <span>
              {isError && !inputFocusCheck && signUpErrorMessage.response.status !== 405 && <Icon name="redError" />}
            </span>
          </FormWrapper>
          {isError && !inputFocusCheck && signUpErrorMessage.response.status !== 405 && (
            <WarningText>{signUpErrorMessage.response.data.message}</WarningText>
          )}

          {errors.email && errors.email.type === 'pattern' && (
            <WarningText>이메일 형식이 올바르지 않습니다.</WarningText>
          )}
          <FormWrapper>
            <FormInput
              placeholder="비밀번호 (8자 이상 영문/숫자/특수문자)"
              type={passwordShown ? 'text' : 'password'}
              {...register('password', {
                required: true,
                pattern: new RegExp('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[!#$%&()*+,-./:;<=>?@^_`{|}~\\[\\]]).{8,}$'),
                minLength: 8,
                validate: (value) => value === passwordRepeat.current,
              })}
            />
            {watch('password') && watch('password').length !== 0 && (
              <span onClick={togglePasswordVisiblity}>{passwordShown ? '숨기기' : '표시'}</span>
            )}
          </FormWrapper>
          {errors.password && (errors.password.type === 'pattern' || errors.password.type === 'minLength') && (
            <WarningText>비밀번호는 8자 이상, 영문/숫자/특수문자로 설정해야 합니다.</WarningText>
          )}
          <FormWrapper>
            <FormInput
              placeholder="비밀번호 확인"
              type="password"
              {...register('passwordRepeat', {
                required: true,
                validate: (value) => value === password.current,
                minLength: 8,
              })}
            />
          </FormWrapper>
          {checkSamePassword() && <WarningText>비밀번호가 일치하지 않습니다.</WarningText>}
          <FormWrapper block={phoneVerify}>
            <FormInput
              type="tel"
              placeholder="휴대폰 번호 (숫자만 입력)"
              onChange={handleChangePhoneNumber}
              value={phoneNumber}
              disabled={phoneVerify}
              onFocus={handleFocusPhoneNumber}
            />
            {!phoneVerify && (
              <span style={{ color: '#003399' }} onClick={handleClickSendPhone}>
                {sendIsError && sendErrorMessage.response.status === 410 ? (
                  <RetryButton style={{ backgroundColor: '#b1b1b5', cursor: 'not-allowed' }} disabled={sendIsError}>
                    확인
                  </RetryButton>
                ) : phoneIndex === '인증' ? (
                  '인증'
                ) : (
                  <RetryButton style={{ backgroundColor: '#1e1e2c' }}>재전송</RetryButton>
                )}
              </span>
            )}
            {phoneVerify && <VerifyCompleteInfo>인증완료</VerifyCompleteInfo>}
          </FormWrapper>
          {!phoneVerify && phoneVerifyCheck && !inputFocusCheck && (
            <WarningText>휴대폰인증을 완료해 주세요.</WarningText>
          )}
          {sendData &&
            !phoneVerify &&
            phoneNumber.length !== 0 &&
            (sendData.remaining_count === 5 ? (
              ''
            ) : (
              <WarningText>{`남은 인증횟수: ${sendData.remaining_count - 1}회 (하루 5회)`}</WarningText>
            ))}
          {sendIsError && !inputFocusCheck && !(sendErrorMessage.response.status === 410) && (
            <WarningText>{sendErrorMessage.response.data.message}</WarningText>
          )}

          {sendIsError && sendErrorMessage.response.status === 410 && (
            <WarningText>
              {`인증횟수를 초과했습니다.`}
              <br />
              {'내일 다시 시도하시거나, '}
              <LinkToCs>
                <a href="https://brwnie.channel.io/" target="_blank" rel="noreferrer">
                  고객센터
                </a>
              </LinkToCs>
              {'로 문의 주세요.'}
            </WarningText>
          )}
          {!sendIsError && !phoneVerify && sendStatus && phoneNumber.length !== 0 && (
            <FormWrapper>
              <FormInput
                placeholder="인증번호 입력"
                type="text"
                onChange={handleChangeVerifyCode}
                value={verifyCode}
                onFocus={() => setInputFocusCheck(true)}
              />
              <AuthTimer startDate={timerDate} getTimer={getRemainTimer} />
              <button onClick={handleClickPhoneVerify} disabled={getRemainTime}>
                인증확인
              </button>
            </FormWrapper>
          )}
          {getRemainTime && !inputFocusCheck && (
            <WarningText>
              {`입력시간이 초과되었습니다.`}
              <br />
              {'재전송하고 다시 입력해주세요.'}
            </WarningText>
          )}
          {verifyIsError && !inputFocusCheck && verifyErrorMessage.response.status === 409 && (
            <WarningText>{verifyErrorMessage.response.data.message}</WarningText>
          )}
          <OptionTitle>선택</OptionTitle>

          <DateWrapper>
            <Controller
              name="year"
              control={control}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  onChange={handleChangeYear}
                  value={onChangeValueYear.label === null ? '' : onChangeValueYear}
                  isSearchable={false}
                  styles={customStyles}
                  options={getDateYMD('year')}
                  placeholder={' 생일 / 년'}
                  components={{ DropdownIndicator, IndicatorSeparator }}
                />
              )}
            />
            <Controller
              name="month"
              control={control}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  onChange={handleChangeMonth}
                  value={onChangeValueMonth.label === null ? '' : onChangeValueMonth}
                  isSearchable={false}
                  styles={customStyles}
                  placeholder={'월'}
                  options={getDateYMD('month')}
                  components={{ DropdownIndicator, IndicatorSeparator }}
                />
              )}
            />
            <Controller
              name="day"
              control={control}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  onChange={handleChangeDay}
                  value={onChangeValueDay.label === null ? '' : onChangeValueDay}
                  isSearchable={false}
                  styles={customStyles}
                  placeholder={'일'}
                  options={
                    onChangeValueMonth.value !== null
                      ? getDateYMD('day', onChangeValueMonth.value, checkLeapYear)
                      : getDateYMD('day')
                  }
                  components={{ DropdownIndicator, IndicatorSeparator }}
                />
              )}
            />
          </DateWrapper>
          <ButtonWrapper>
            <Button
              width={156}
              buttonTheme={genderStatus === 'M' ? 'primary' : 'third'}
              type="button"
              onClick={() => handleClickGender('M')}
            >
              남
            </Button>
            <Button
              width={156}
              buttonTheme={genderStatus === 'F' ? 'primary' : 'third'}
              type="button"
              onClick={() => handleClickGender('F')}
            >
              여
            </Button>
          </ButtonWrapper>
          <WarningText color="gray">선택 항목은 이벤트, 혜택을 드리는데 이용됩니다.</WarningText>
          <TermWrapper>
            <WholeAgreeLi>
              <CustomCheckbox>
                <input
                  type="checkbox"
                  id={'whole_check'}
                  onClick={handleCheckWholeTerm}
                  checked={useTermData.every((item) => getValues(`${item.subject}`) === true)}
                />
              </CustomCheckbox>
              <label htmlFor={`whole_check`}>
                <h5>모든 약관에 동의합니다.</h5>
              </label>
            </WholeAgreeLi>
            <hr />
            {useTermData.map((item, index) => (
              <DetailAgreeLi key={index}>
                <CustomCheckbox>
                  <input
                    type="checkbox"
                    id={`${item.subject}_id`}
                    {...register(item.subject, { required: index <= 2 ? true : false })}
                    onClick={() => handleCheckDetailTerm(item.subject)}
                  />
                </CustomCheckbox>
                <label htmlFor={`${item.subject}_id`}>
                  <h5>{item.termName}</h5>
                </label>

                {item.subject !== 'adultTerm' && (
                  <a href={item.link} target="_blank" rel="noreferrer">
                    보기
                  </a>
                )}
              </DetailAgreeLi>
            ))}
          </TermWrapper>
          {!termCheck && submitState && phoneVerify && sendButton && (
            <WarningText style={{ textAlign: 'center' }}>약관에 동의해 주세요.</WarningText>
          )}
          <SignUpButton type="submit" disabled={!phoneVerify} onClick={handleSubmitSignUp}>
            {signUpLoading ? <Spinner color="white" /> : '가입하기'}
          </SignUpButton>
        </SignUpFormWrapper>
      </SignUpWrapper>
    </>
  )
}

const FormWrapper = styled.div<{ block?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.1rem 1.6rem;
  margin-top: 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.gray};
  border-radius: 0.8rem;
  background-color: white;

  span {
    flex: 0 0 auto;
    font-size: 1.6rem;
    cursor: pointer;
    background-color: white;
    color: ${({ theme }) => theme.colors.black};
  }

  button {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.main};
    cursor: pointer;
  }

  ${({ block }) =>
    block &&
    `
  background-color: #f8f8f8;
  `}

  :focus-within {
    ime-mode: disabled;
    -webkit-ime-mode: disabled;
    -moz-ime-mode: disabled;
    -ms-ime-mode: disabled;
    border: solid 0.1rem #003399;
  }
`

const RetryButton = styled.button`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-radius: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green600};
  cursor: pointer;
`

const OptionTitle = styled.p`
  text-align: left;
  color: ${({ theme }) => `${theme.colors.black}`};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-bottom: 0.8rem;
  padding-top: 3.2rem;
`

const VerifyCompleteInfo = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-radius: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gray};
  cursor: default;
`
const WarningText = styled.div<{ color?: string }>`
  margin-top: 0.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => `${theme.colors.warning}`};

  ${({ color }) =>
    color === 'gray' &&
    `
  color: #1e1e2c;
  opacity: 0.5;
  `}

  ${({ theme }) => theme.media.mobile`
  letter-spacing: -0.05rem;
  `}
`

const LinkToCs = styled.span`
  margin-top: 0.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => `${theme.colors.warning}`};
  border-bottom: 0.1rem solid ${({ theme }) => `${theme.colors.warning}`};

  a {
    text-decoration: none;
    color: ${({ theme }) => `${theme.colors.warning}`};
  }
`

const FormInput = styled.input`
  width: 100%;
  border: 0;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2.4rem;
  padding: 0;

  :disabled {
    background-color: #f8f8f8;
  }

  ::placeholder {
    opacity: 0.3;
  }
`
const SignUpWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(6)};
  overflow-y: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Divider = styled.hr`
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => `${theme.colors.lightGrayishBlue}`};
  border: none;
  margin: ${({ theme }) => theme.spacing(6, 0)};
`

const SignUpFormWrapper = styled.form`
  width: 100%;
  position: relative;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  button {
    ${({ theme }) => theme.media.mobile`
  width: 15.2rem;
  min-width: 15.2rem;
  height: 4.8rem;
  `}
  }
`

const RequireTitle = styled.p`
  text-align: left;
  color: ${({ theme }) => `${theme.colors.black}`};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-bottom: 0.8rem;
`

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.6rem;
  & > div {
    & {
      width: 32%;
      height: 4.8rem;
    }
  }
`

const SignUpButton = styled.button<{ disabled?: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 4.8rem;
  margin-top: 4.8rem;
  border: none;
  border-radius: 0.8rem;
  -webkit-appearance: none;
  background-color: ${({ theme }) => `${theme.colors.main}`};
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => `${theme.colors.white}`};

  ${({ disabled }) =>
    disabled === true &&
    `
    background-color: #edeff1;
    color: #1e1e2c;
    opacity: 0.5;
    cursor: no-drop;
  `}
`

const CustomSelect = styled(Select)`
  [class*='menu'] {
    & > div {
      &:first-child {
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
      }
      &:last-child {
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
      }
    }
  }
`

const TermWrapper = styled.ul`
  width: 312px;
  height: 240px;
  margin-top: 48px;

  hr {
    border: none;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
    width: 312px;
    margin-top: 16px;
  }
  label {
    cursor: pointer;
  }
`

const WholeAgreeLi = styled.li`
  display: flex;
  align-items: center;

  h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    margin-left: 8px;
    width: 270px;
    color: ${({ theme }) => theme.colors.black};
  }
`

const DetailAgreeLi = styled.li`
  display: flex;
  align-items: flex-start;
  padding-top: 16px;
  h5 {
    width: 248px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.gray};
  }
  a {
    text-decoration: underline;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.gray};
  }
`

const ToLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  width: fit-content;
  height: 40px;
  align-self: center;
  border: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  opacity: 0.5;
  margin-bottom: 48px;

  a {
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.black};
  }
`
