import client from '../client'

export async function patchModifyBirthDate({ birth_year, birth_day }: ModifyBirthDateReq) {
  const response = await client.patch<undefined>(`auth-api/v1/auth/modify/birthdate`, { birth_year, birth_day })

  return response.data
}

export type ModifyBirthDateReq = {
  birth_year: string
  birth_day: string
}
