import AlertModal from 'components/common/AlertModal'
import Button from 'components/common/Button'
import ConfirmModal from 'components/common/ConfirmModal'
import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import InfoBox from 'components/common/InfoBox'
import Information from 'components/common/Information'
import Spinner from 'components/common/Spinner'
import Icon from 'components/Icon'
import DiscountInfo from 'components/MyPage/DiscountInfo'
import MyInfo from 'components/MyPage/MyInfo'
import NoDiscount from 'components/MyPage/NoDiscount'
import useAuth from 'hooks/query/auth/useAuth'
import useDropUser from 'hooks/query/auth/useDropUser'
import useLogout from 'hooks/query/auth/useLogout'
import useGetCoupons from 'hooks/query/coupon/useGetCoupons'
import useServicesQuery from 'hooks/query/service/useServicesQuery'
import useStoresQuery from 'hooks/query/store/useStoresQuery'
import { useScrollGet } from 'hooks/useScrollGet'
import { COUPON_STATE } from 'lib/api/coupon/getCoupons'
import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import addComma from 'utils/common/addComma'
import buildStoreSummary from 'utils/common/buildStoreSummary'
import { ROUTES } from 'utils/common/routes'
import { timezoneToSelectedDate } from 'utils/common/time'

export default function MyPage() {
  const queryClient = useQueryClient()
  const { auth } = useAuth()
  const history = useHistory()
  const { data: coupons } = useGetCoupons({ page: 1, state: COUPON_STATE.UN_USED })
  const { data: stores } = useStoresQuery()
  const { data: services } = useServicesQuery({ userId: auth?.id as number })
  const mainRef = useRef<HTMLDivElement>(null)
  const { mutateAsync: mutateLogout, isLoading: isLogoutLoading } = useLogout()
  const { mutateAsync: mutateDropUser } = useDropUser()
  const [noPointOpen, setNoPointOpen] = useState<boolean>(false)
  const [outWarningOpen, setOutWarningOpen] = useState<boolean>(false)
  const [outSuccessOpen, setOutSuccessOpen] = useState<boolean>(false)
  const { totalOffsetY } = useScrollGet({})

  const handleLogout = async () => {
    await mutateLogout()
    history.push(ROUTES.ROOT)
  }

  const handleOut = async () => {
    await mutateDropUser()
    setOutWarningOpen(false)
    setOutSuccessOpen(true)
  }

  const handleOutConfirm = () => {
    setOutSuccessOpen(false)
    history.push(ROUTES.ROOT)
    queryClient.removeQueries('authCheck')
  }

  return (
    <>
      <Header title="마이페이지" offsetY={totalOffsetY} />
      <StyledMain ref={mainRef}>
        <div>
          <InfoBox title="나의 정보" iconName="blueProfile">
            <MyInfo />
          </InfoBox>
        </div>
        <div className="benefit__container">
          <Link to="/coupon" className="box_link">
            <InfoBox title="쿠폰" iconName="greenTicket" rightIconName="blackAdd">
              {!coupons?.count ? (
                <NoDiscount>보유 쿠폰이 없어요</NoDiscount>
              ) : (
                <DiscountInfo remainText={`${coupons?.count}`} />
              )}
            </InfoBox>
          </Link>

          <div>
            <Link
              onClick={!auth?.point ? () => setNoPointOpen(true) : undefined}
              to={!auth?.point ? '/mypage' : '/point'}
              className="box_link"
            >
              <InfoBox title="포인트" iconName="purpleDiscount">
                {!auth?.point ? (
                  <NoDiscount>보유 포인트가 없어요</NoDiscount>
                ) : (
                  <DiscountInfo remainText={`${addComma(auth?.point as number)} P`} />
                )}
              </InfoBox>
            </Link>
          </div>
        </div>
        <div className="out_button_container">
          <Button buttonTheme="black_secondary" width={160} mobileWidth={120} onClick={handleLogout}>
            {isLogoutLoading ? <Spinner color="main" /> : '로그아웃'}
          </Button>
          <Button buttonTheme="black_secondary" width={160} mobileWidth={120} onClick={() => setOutWarningOpen(true)}>
            회원탈퇴
          </Button>
        </div>
      </StyledMain>
      <Footer />

      {/* MODAL */}
      <AlertModal open={noPointOpen} title="포인트 내역이 없습니다." onConfirm={() => setNoPointOpen(false)} />

      <ConfirmModal
        open={outWarningOpen}
        title="회원탈퇴 안내"
        onConfirm={handleOut}
        onClose={() => setOutWarningOpen(false)}
        confirmText="탈퇴"
        closeText="취소"
      >
        <OutWarningContent>
          <Information>
            <Icon name="emojiAttention" />
            <p>
              탈퇴하시면 회원님의 정보와 <br />
              혜택(쿠폰/포인트)은 모두 삭제됩니다.
            </p>
          </Information>
          {!!services && services.length !== 0 && (
            <section>
              <div>
                <Icon name="redError" />
              </div>
              <div>
                <p>
                  사용 중인 서비스가 있습니다.
                  <br />
                  탈퇴하더라도 서비스는 유지됩니다.
                </p>
                <ul>
                  {services.map((service) => (
                    <li key={service.id}>
                      <span>•</span>
                      <div>
                        <p>{buildStoreSummary(service)}</p>
                        <p>적용매장 : {stores?.find((store) => store.id === service.store_id)?.store_name}</p>
                        <p>
                          기한 : {timezoneToSelectedDate(service.end_date).year}.
                          {timezoneToSelectedDate(service.end_date).month}.
                          {timezoneToSelectedDate(service.end_date).date}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          )}
          <a
            href="https://higherx.notion.site/2021-06-10-42f4b3c848ee4182978736e1aace21fd"
            target="_blank"
            rel="noreferrer"
          >
            탈퇴 약관 보기
          </a>
        </OutWarningContent>
      </ConfirmModal>

      <AlertModal open={outSuccessOpen} onConfirm={handleOutConfirm}>
        <OutSuccessTitle>
          탈퇴 신청 완료 <Icon name="emojiSad" />
        </OutSuccessTitle>
        <OutSuccessDesc>
          우리 다시 만나요.
          <br />더 나은 서비스로 성장하여 기다리겠습니다.
        </OutSuccessDesc>
      </AlertModal>
    </>
  )
}

const StyledMain = styled.main`
  margin: 0 auto;
  padding: 80px 0 104px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 48px;
  width: 832px;
  gap: 64px 64px;

  .box_link {
    width: fit-content;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.lightGrayishBlue};
  }

  .benefit__container {
    & > div {
      margin-top: 40px;
    }
  }

  div.out_button_container {
    grid-column: 1 / span 2;
    display: flex;
    justify-content: center;

    button ~ button {
      margin-left: 16px;
    }
  }

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    padding-top: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0 0;

    div ~ div {
      margin-top: 40px;
    }

    width: ${theme.width.mobile}
  `}
`

const OutWarningContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > section {
    margin-top: 32px;
    width: 340px;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.warning};
    display: flex;
    align-items: flex-start;

    & > div:first-child {
      min-width: 24px;
      margin-right: 8px;
    }

    & > div:last-child {
      display: flex;
      flex-direction: column;

      & > p {
        font-size: 16px;
        line-height: 24px;
        color: ${({ theme }) => theme.colors.warning};
        font-weight: 400;
      }

      & > ul {
        display: flex;
        flex-direction: column;
        max-height: 288px;
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera*/
        }

        li {
          display: flex;
          margin-top: 24px;

          span {
            margin-right: 8px;
          }

          span,
          p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.black};
          }

          p {
            width: 246px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  & > a {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
    line-height: 24px;
    height: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    margin-top: 32px;
  }

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    & > div {
      width: 264px;
    }

    & > section {
      margin-top: 24px;
      width: 264px;
      padding-right: 0;

      & > div:last-child {
        & > p {
          font-size: 14px;
          line-height: 20px;
          word-break: keep-all;
          width: 170px;
        }
        
        & > ul {
          li {
            span,
            p {
              font-size: 14px;
              line-height: 20px;
            }
  
            p {
              width: 175px;
            }
          }
        }
      }
    }

    & > a {
      margin-top: 24px;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
    }
  `}
`

const OutSuccessTitle = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  margin-top: -24px;

  svg {
    margin-left: 8px;
  }

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    font-size: 20px;
    line-height: 28px;
    margin-top: -20px;
  `}
`

const OutSuccessDesc = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  word-break: keep-all;
  margin-top: 24px;

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    margin-top: 16px;
  `}
`
