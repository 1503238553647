import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'

import Icon from 'components/Icon'
import { ConsultFormLabel } from './hooks/useEasyConsulting'

export type EasyConsultingFormProps = {
  type: ConsultFormLabel
  label: string
  isError: boolean
  errorText: string
  value: string
  isSubmit: boolean
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  resetSubmit: () => void
}

function EasyConsultingForm({
  type,
  label,
  isError,
  errorText,
  value,
  onChange,
  isSubmit,
  resetSubmit,
  placeholder = '',
}: EasyConsultingFormProps) {
  const [errorStatus, setErrorStatus] = useState<boolean>(false)

  useEffect(() => {
    if (isSubmit) {
      setErrorStatus(isError)
    }
  }, [isError, isSubmit])

  return (
    <FormWrapper warning={errorStatus}>
      <label>{label}</label>
      <input
        onFocus={() => {
          setErrorStatus(false)
          resetSubmit()
        }}
        placeholder={errorStatus ? '' : placeholder}
        value={value}
        onChange={onChange}
        type={type === 'phone' ? 'tel' : 'text'}
      />
      {errorStatus && (
        <ErrorText>
          <Icon name="redError" />
          {errorText}
        </ErrorText>
      )}
    </FormWrapper>
  )
}

export default memo(EasyConsultingForm)

const FormWrapper = styled.div<{ warning: boolean }>`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  z-index: 1000;
  margin-bottom: 16px;

  & > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray600);
    padding-bottom: 4px;
  }

  & > input {
    border: 0;
    font-size: 16px;
    line-height: 28px;
    background-color: ${({ warning }) => (warning ? 'var(--red100);' : 'var(--gray50);')};
    color: ${({ theme }) => theme.colors.black};
    border-radius: 16px;
    padding: 12px 16px;

    ::placeholder {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;

      color: var(--gray500);
    }
  }
`

const ErrorText = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: var(--red400);

  & > svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
`
