import styled from 'styled-components'

interface EmptyServiceProp {
  handleApplyService: () => void
}

export default function EmptyService({ handleApplyService }: EmptyServiceProp) {
  return (
    <Container>
      <Info>
        브라우니 서비스를 <br className="media-default" />
        신청해 볼까요?
      </Info>
      <ServiceApplyButton onClick={handleApplyService}>서비스 신청</ServiceApplyButton>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  line-height: 28px;

  .media-default {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    .media-default {
      display: none;
    }
  }
`
const Info = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  margin-right: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`

const ServiceApplyButton = styled.button`
  width: 126px;
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;

  background: #003399;
  border-radius: 12px;

  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  line-height: 40px;

  cursor: pointer;

  @media only screen and (max-width: 768px) {
    margin-top: 8px;
  }
`
