import Icon from 'components/Icon'
import React, { memo } from 'react'
import { useHistory } from 'react-router'
import styled, { keyframes } from 'styled-components'
import { ROUTES } from 'utils/common/routes'
import { UTM_URL } from 'utils/common/utmUrl'

function LandingStoreTypeSelectSection() {
  const history = useHistory()
  const { push } = history

  return (
    <LandingStoreTypeSelectSectionWrapper>
      <TitleText>
        어떤 업종도
        <br />
        무인매장 관리 OK
      </TitleText>
      <SubTitleText>전문가의 노하우로 업종별 맞춤 관리를 제공합니다</SubTitleText>
      <StoreTypeUl>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_ICE_CREAM + UTM_URL.LANDING_STORE_TYPE_ICE)}>
          <div className="icon">🍦</div>
          아이스크림
          <br />
          할인점
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_SELF_LAUNDRY + UTM_URL.LANDING_STORE_TYPE_LAUNDRY)}>
          <img className="img_icon" src="/image/브라우니_무인매장_셀프빨래방_이모지.png" alt="laundry" />
          셀프 빨래방
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.STUDIO)}>
          <div className="icon">📷</div>
          무인 사진관
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_CONVENIENCE + UTM_URL.LANDING_STORE_TYPE_CONVENIENCE)}>
          <div className="icon">🧃</div>
          무인 편의점
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_CAFE + UTM_URL.LANDING_STORE_TYPE_CAFE)}>
          <div className="icon">☕️</div>
          무인 카페
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_STUDY_CAFE + UTM_URL.LANDING_STORE_TYPE_STUDY_CAFE)}>
          <div className="icon">📖</div>
          스터디 카페
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_SMART_MACHINE + UTM_URL.LANDING_STORE_TYPE_VENDING)}>
          <img className="img_icon" src="/image/브라우니_무인매장_무인자판기_이모지.png" alt="vendingmachine" />
          무인 자판기·
          <br />
          밀키트
        </StoreTypeLi>
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_STATIONERY)}>
          <div className="icon" style={{ transform: 'scaleX(-1)' }}>
            ✏️
          </div>
          무인 문구점
        </StoreTypeLi>

        {/* 무인 프린트 페이지 생성 */}
        <StoreTypeLi onClick={() => push(ROUTES.SERVICE_PRINTER_MACHINE)}>
          <img className="img_icon" src="/image/브라우니_무인매장_무인프린트_이모지.png" alt="printermachine" />
          무인 프린트
        </StoreTypeLi>

        <StoreTypeLi>
          <a href="https://brwnie.channel.io/" target="_blank" rel="noreferrer">
            <Icon name="GrayPlus" />
            <p>
              기타 업종
              <br />
              문의하기
            </p>
          </a>
        </StoreTypeLi>
      </StoreTypeUl>
      <ClickInduceText>클릭 시 업종별 상세 페이지로 이동해요 👆</ClickInduceText>
    </LandingStoreTypeSelectSectionWrapper>
  )
}

const LandingStoreTypeSelectSectionWrapper = styled.section`
  position: relative;

  width: 100%;
  padding: 180px 0px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--gray0);

  @media only screen and (max-width: 768px) {
    padding-top: 80px;
    height: auto;
    padding-bottom: 60px;
  }
`

const TitleText = styled.p`
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  text-align: center;
  color: var(--gray800);

  & > b {
    color: var(--brand400);
  }

  @media only screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 40px;
  }
`

const SubTitleText = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--gray600);

  margin-top: 20px;
  margin-bottom: 48px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    width: 220px;
    text-align: center;
    word-break: keep-all;

    margin-top: 8px;
    margin-bottom: 32px;
  }
`

const StoreTypeUl = styled.ul`
  padding: 0px 200px;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;

  & > li:last-of-type {
    background-color: var(--gray50);
    border: 2px dashed var(--gray200);
    box-shadow: none;

    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: var(--gray600);
    }

    & > a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 100%;
      height: 100%;

      color: var(--gray600);
      border-radius: 16px;

      & > p {
        margin-top: 8px;
        font-size: 16px;
        line-height: 28px;
      }
    }

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 6px;
      column-gap: 6px;
      &:hover {
        background-color: var(--gray600);
        border: none;

        & > p,
        & > a {
          & > svg path {
            stroke: var(--gray0);
          }

          color: var(--gray0);
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: calc(100% - 32px);
    padding: 0px 40px;
    height: unset;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 6px;

    & > li:last-of-type {
      & > a {
        border-radius: 12px;

        & > svg {
          width: 28px;
          height: 28px;
        }

        & > p {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
`

const StoreTypeLi = styled.li`
  width: 150px;
  aspect-ratio: 1/1;

  cursor: pointer;
  border-radius: 16px;
  box-sizing: border-box;

  background-color: var(--gray0);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray700);
  text-align: center;

  .icon {
    font-size: 56px;
    line-height: 56px;
    margin-bottom: 12px;
  }

  .img_icon {
    width: 56px;
    height: 56px;
    margin-bottom: 12px;
  }

  &:hover {
    box-shadow: 0px 16px 16px -8px rgba(0, 0, 0, 0.12);
    transform: translateY(-12px);
  }

  @media only screen and (max-width: 768px) {
    width: unset;
    height: unset;
    min-width: 90px;
    min-height: 90px;

    border-radius: 12px;
    font-size: 13px;
    line-height: 16px;

    .icon {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 8px;
    }

    .img_icon {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;
    }
  }
`

const cursorAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const ClickInduceText = styled.p`
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--gray600);

  margin-top: 32px;

  border-radius: 8px;
  border: 1px solid var(---gray100);
  background: #f5f9fe;

  padding: 4px 16px;

  & > svg {
    margin-left: 4px;
    animation: ${cursorAnimation} 2s ease-in-out infinite;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 20px;

    font-size: 12px;
    line-height: 21px; /* 175% */
  }
`

export default memo(LandingStoreTypeSelectSection)
