import { ServiceWeekType } from 'modules/features/service/serviceSlice'
import { useGetBrwnieBasePriceQuery } from './query/admin/useGetBrwnieBasePriceQuery'

export function useServicePrice() {
  const { data: brwniePrice } = useGetBrwnieBasePriceQuery()

  const getServicePrice = ({
    serviceWeek,
    weekdayCount,
    weekendCount,
    storeType,
    serviceMinute,
  }: {
    serviceWeek: ServiceWeekType | undefined
    weekdayCount: number
    weekendCount: number
    storeType?: '전담게이트' | '전담플레이'
    serviceMinute: number
  }): number => {
    if (brwniePrice === undefined) return 0

    const storeTypeCode = storeType === '전담게이트'
                            ? 'C0310'
                            : storeType === '전담플레이'
                              ? 'C0314'
                              : null

    const findedPrice = brwniePrice.find(
      ({ totalWeekCount, countWeekday, countWeekend, storeTypeCd, serviceTimeMinute }) =>
        totalWeekCount === serviceWeek &&
        countWeekday === weekdayCount &&
        countWeekend === weekendCount &&
        serviceMinute === serviceTimeMinute &&
        storeTypeCode === storeTypeCd
    )

    if (!findedPrice) {
      throw new Error('해당하는 요금제가 없습니다')
    } else {
      const { servicePrice } = findedPrice

      return servicePrice
    }
  }

  return {
    getServicePrice,
  }
}
