import { useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { getServices, GetServicesParam } from '../../../lib/api/service/getServices'
import { ServiceType } from '../../../lib/api/service/types'
import { QueryOptionsOf } from '../../../lib/utils/types'

export default function useServicesQuery(value: GetServicesParam, options: QueryOptionsOf<typeof getServices> = {}) {
  return useQuery(createKey(value.userId), () => getServices(value), options)
}

const createKey = (userId: number) => ['services', userId]
useServicesQuery.createKey = createKey

export function useServicesQueryGetter() {
  const queryClient = useQueryClient()

  const get = useCallback(
    (userId) => {
      return queryClient.getQueryData<ServiceType[]>(createKey(userId))
    },
    [queryClient]
  )

  return get
}
