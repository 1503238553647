import { dayArr } from 'modules/features/service/serviceSlice'
import { BottomCTA, BottomSheet, TextButton, TypoC1, TypoP1 } from 'ohds-web'
import { useState } from 'react'
import styled from 'styled-components'

export default function ActiveDateInfoButton() {
  const [isInfoBottomSheetOpen, setIsInfoBottomSheetOpen] = useState<boolean>(false)

  const openInfo = () => setIsInfoBottomSheetOpen(true)
  const closeInfo = () => setIsInfoBottomSheetOpen(false)

  return (
    <>
      <StyledActiveDateInfoButton>
        <TextButton
          label="활성화 된 날 중 선택할 수 있어요"
          fontSize="c1"
          fontWeight="regular"
          color="gray600"
          onClick={openInfo}
        />
      </StyledActiveDateInfoButton>

      <StyledBottomSheet
        open={isInfoBottomSheetOpen}
        title="활성화된 날이 뭔가요?"
        onClose={closeInfo}
        BottomCTA={
          <StyledBottomCTA
            // linear
            primaryProps={{
              label: '네, 확인했어요',
              onClick: closeInfo,
            }}
          />
        }
      >
        <InfoContainer>
          <TypoP1
            color="gray600"
            text="브라우니는 선택하신 서비스 시작일의 요일을 기준으로 한 주씩 계산하고 있습니다. 그래서 서비스 일정을 변경할 경우 해당 날짜가 속한 주를 활성화시켜드립니다."
          />

          <ExampleBox>
            <Days>
              {dayArr.map((day) => (
                <ItemBox key={day}>
                  <TypoC1 text={day} />
                </ItemBox>
              ))}
            </Days>
            <IndicatorBar>
              {Array.from({ length: 7 }).map((_, i) => (
                <ItemBox key={i}>{i >= 2 && <Indicator />}</ItemBox>
              ))}
            </IndicatorBar>
            <IndicatorBar>
              {Array.from({ length: 7 }).map((_, i) => (
                <ItemBox key={i}>{i < 2 && <Indicator />}</ItemBox>
              ))}
            </IndicatorBar>
          </ExampleBox>

          <TypoP1 color="gray600" text="예) 수요일부터 서비스를 시작한다면 다음 주 화요일까지가 한 주입니다." />

          <TypoP1
            color="gray600"
            text="단, 마감 혹은 공휴일의 문제가 발생했을 경우는 선택하신 기간에 한 주를 더 활성화시켜드립니다."
            style={{ marginTop: 24 }}
          />
        </InfoContainer>
      </StyledBottomSheet>
    </>
  )
}

const StyledActiveDateInfoButton = styled.div`
  width: 100%;
  padding-left: 16px;
  height: 40px;
`

const InfoContainer = styled.div`
  padding: 16px;
`

const ExampleBox = styled.div`
  background-color: var(--brand100);
  border-radius: 24px;
  padding: 8px 16px;
  margin: 16px 0;
`

const Days = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray0);
`

const ItemBox = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const IndicatorBar = styled.div`
  display: flex;
  justify-content: space-between;
`

const Indicator = styled.div`
  width: 20px;
  height: 20px;
  background-color: var(--brand400);
  border-radius: 50%;
`

const StyledBottomCTA = styled(BottomCTA)`
  @media only screen and (min-width: 768px) {
    width: 50vw !important;
    left: 50%;
  }
`

const StyledBottomSheet = styled(BottomSheet)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    position: fixed;
    left: 50%;
  }
`
