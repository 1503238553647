import client from '../client'
import { ServicePaymentResponseType } from './types'

export async function getServicesPayments({
  userId,
  state,
  period,
  product,
  totalWeekCount,
  page,
  perPage,
  history,
  isRegularPayment,
}: GetServicesPayments) {
  const response = await client.get<ServicePaymentResponseType>(`/service-api/v1/service/payment/user/${userId}`, {
    params: {
      state,
      period,
      product,
      totalWeekCount,
      page,
      perPage,
      history,
      isRegularPayment,
    },
  })

  return response.data
}

export type GetServicesPayments = {
  userId: number
  state?: string
  period?: string
  product?: string
  totalWeekCount?: string
  page?: number
  perPage?: number
  history?: boolean
  isRegularPayment?: boolean
}
