import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Route, Switch, useHistory } from 'react-router-dom'

import LandingNavBar from 'components/Landing/LandingNavBar'
import useGetListMap from 'hooks/query/map/useGetListMap'
import { MarkerClustering } from '../../utils/common/markerClustering'
import {
  filterGeneralMarker,
  getBrwnieMarkerList,
  getMarkerList,
  clusterStylingFunction,
  getNeSwRangeData,
  getStoreListFilter,
  getClickMarkerEvent,
  brwnieMarkerVisible,
} from 'utils/map/marker'
import Spinner from 'components/common/Spinner'
import useGetListBrwnie from 'hooks/query/map/useGetListBrwnie'
import Icon from 'components/Icon'
import FilterView from 'components/Map'
import { CategoryStatusType, initialCategoryStatus } from 'components/Map/FilterViewSection'
import AddressSearchMap from 'components/Map/AddressSearchMap'
import ScrollBody from 'components/Map/ScrollBody'
import { MapStoreListType } from 'lib/api/map/types'
import MapStoreDetailSection from 'components/Map/MapStoreDetailSection'
import { isMobile } from 'react-device-detect'

const { naver } = window

export type LocationType = {
  latitude: number
  longitude: number
}

export type FilterStateType = CategoryStatusType & { brwnieState: boolean }

export default function MapPage() {
  const { mutateAsync: mutateGetListMap, isLoading } = useGetListMap()
  const { mutateAsync: mutateGetListBrwnie } = useGetListBrwnie()
  const [myMap, setMyMap] = useState<naver.maps.Map | null>(null)
  const [clustering, setClustering] = useState<any>(null)
  const [globalStoreData, setGlobalStoreData] = useState<MapStoreListType[]>([])
  const [storeListData, setStoreListData] = useState<MapStoreListType[]>([])
  const [checkedSearchOption, setCheckedSearchOption] = useState<'address' | 'storeName'>('address')
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [brwnieMarkers, setBrwnieMarkers] = useState<naver.maps.Marker[]>([])
  const [markerWhole, setMarkerWhole] = useState<naver.maps.Marker[]>([])
  const [addressSearchResult, setAddressSearchResult] = useState<LocationType | null>(null)
  const [mobileSwipeOpen, setMobileSwipeOpen] = useState<boolean>(false)
  const [filterState, setFilterState] = useState<FilterStateType>({
    ...initialCategoryStatus,
    brwnieState: false,
  })
  const history = useHistory()

  const handleFilterClick = () => {
    setFilterOpen(true)
  }

  const handleStoreDataChange = () => {
    const resultData = getStoreListFilter(storeListData, filterState)
    return resultData
  }

  const handleSearchOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedSearchOption(e.target.value as 'address' | 'storeName')
  }

  const handleMapCenterChange = (locationData: LocationType, singleOption?: boolean) => {
    if (myMap) {
      const nowLocation = new naver.maps.LatLng(locationData.latitude, locationData.longitude)
      myMap.setCenter(nowLocation)
      myMap.setZoom(16)
      if (singleOption) {
        const findData = getClickMarkerEvent(globalStoreData, locationData.latitude, locationData.longitude)
        if (findData) {
          setStoreListData([findData])
        }
      }
    }
  }

  const createMarkerCluster = (map: naver.maps.Map, markers: naver.maps.Marker[]) => {
    return new MarkerClustering({
      minClusterSize: 7,
      maxZoom: 15,
      map: map,
      markers: markers,
      disableClickZoom: false,
      gridSize: 250,
      stylingFunction: clusterStylingFunction,
    })
  }

  useEffect(() => {
    async function callMapListApi() {
      const getRes = await mutateGetListMap()

      return getRes
    }

    // 내 위치 버튼
    const myLocationBtnHtml =
      '<img src="/icon/get-now-location.png" width = "40px" height = "40px" style="position: absolute; top:20px; right: 20px; cursor: pointer;"/>'

    // 첫 렌더링 시, 지도설정
    if (myMap === null) {
      const map = new naver.maps.Map('map', {
        center: new naver.maps.LatLng(37.56340098565951, 126.97788620200828),
        mapDataControl: false,
        zoom: isMobile ? 11 : 12,
        tileTransition: true,
      })
      setMyMap(map)

      callMapListApi().then((data) => {
        const markerList = getMarkerList(data)
        setMarkerWhole([...markerList])
        setGlobalStoreData(data)
        setStoreListData(data)
        setClustering(createMarkerCluster(map, markerList))
      })
    } else {
      // 지도 위치 변경시 마다
      naver.maps.Event.addListener(myMap, 'idle', async () => {
        const getNE = myMap.getBounds().getNE(),
          getSW = myMap.getBounds().getSW()
        setStoreListData(getNeSwRangeData(globalStoreData, getSW, getNE))
      })

      // 내 위치 아이콘 넣기
      naver.maps.Event.once(myMap, 'init_stylemap', () => {
        const myLocationButton = new naver.maps.CustomControl(myLocationBtnHtml, {
          position: naver.maps.Position.RIGHT_TOP,
        })

        myLocationButton.setMap(myMap)

        let nowLocationMarkerYn: naver.maps.Marker | undefined
        naver.maps.Event.addDOMListener(myLocationButton.getElement(), 'click', () => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const nowLocation = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude)
                myMap.setCenter(nowLocation)
                myMap.setZoom(17)
                if (nowLocationMarkerYn) {
                  nowLocationMarkerYn.setMap(null)
                }
                nowLocationMarkerYn = new naver.maps.Marker({
                  position: nowLocation,
                  map: myMap,
                  icon: {
                    content: '<img src="/icon/now-location.png" alt = "" width = "30px" height = "30px"/>',
                  },
                })
              },
              (error) => {
                console.log(error)
              }
            )
          }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myMap, mutateGetListMap, clustering, markerWhole])

  useEffect(() => {
    // 첫 렌더링 시, 브라우니 이용 매장 마커 가져오기
    if (myMap && brwnieMarkers.length === 0) {
      mutateGetListBrwnie().then((data) => {
        const markerList = getBrwnieMarkerList(data, myMap)
        setBrwnieMarkers(markerList)

        markerList.forEach((item) => {
          naver.maps.Event.addListener(item, 'click', () => {
            const nowLocation = new naver.maps.LatLng(item.getPosition().y, item.getPosition().x)
            myMap.setCenter(nowLocation)
            myMap.setZoom(17)
            const { x, y } = item.getPosition()

            item.setAnimation(naver.maps.Animation.DROP)
            const returnData = getClickMarkerEvent(data, y, x)
            if (returnData) {
              setStoreListData([returnData])
            }
          })
        })
      })
    }
  }, [myMap, mutateGetListBrwnie, storeListData, brwnieMarkers.length])

  useEffect(() => {
    // 필터에 따른 마커 보여주기
    if (myMap) {
      let cloneData = _.cloneDeep(filterState)

      brwnieMarkerVisible(brwnieMarkers, cloneData)

      if (filterState.brwnieState === true) {
        clustering.setMap(null)
      }

      if (filterState.brwnieState === false && clustering) {
        clustering.setMap(null)
        setClustering(createMarkerCluster(myMap, filterGeneralMarker(markerWhole, cloneData)))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, markerWhole, myMap, setClustering])

  useEffect(() => {
    // 주소 결과에 따라 지도 이동
    if (myMap)
      if (addressSearchResult) {
        const nowLocation = new naver.maps.LatLng(addressSearchResult.latitude, addressSearchResult.longitude)
        myMap.setCenter(nowLocation)
        myMap.setZoom(16)
      }
  }, [addressSearchResult, myMap])

  useEffect(() => {
    // 마커 클릭 이벤트
    if (myMap) {
      markerWhole.forEach((item) => {
        naver.maps.Event.addListener(item, 'click', () => {
          const nowLocation = new naver.maps.LatLng(item.getPosition().y, item.getPosition().x)
          myMap.setCenter(nowLocation)
          myMap.setZoom(16)
          const { x, y } = item.getPosition()

          item.setAnimation(naver.maps.Animation.DROP)
          const returnData = getClickMarkerEvent(storeListData, y, x)
          if (returnData) {
            setStoreListData([returnData])
          }
        })
      })
    }
  }, [markerWhole, myMap, storeListData])

  return (
    <>
      <LandingNavBarCustom>
        <LandingNavBar offsetY={1} />
      </LandingNavBarCustom>
      <MainWrapper>
        <Map id="map" />
        <Switch>
          <Route exact path="/map">
            <InfoWrapper>
              <InfoHeader>
                <OptionWrapper>
                  <SearchOptions>
                    {isMobile && <Icon name="blackLeftArrow" onClick={() => history.goBack()} />}
                    <label>
                      <input
                        type="radio"
                        value={'address'}
                        checked={checkedSearchOption === 'address'}
                        onChange={handleSearchOptionChange}
                      />
                      <span>주소 검색</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value={'storeName'}
                        checked={checkedSearchOption === 'storeName'}
                        onChange={handleSearchOptionChange}
                      />
                      <span>매장명 검색</span>
                    </label>
                  </SearchOptions>
                  <FilterButton onClick={handleFilterClick} hover={true}>
                    <div>
                      <Icon name="mobileOption" />
                      필터
                    </div>
                  </FilterButton>
                </OptionWrapper>
                <AddressSearchMap
                  searchOption={checkedSearchOption}
                  setAddressSearch={setAddressSearchResult}
                  setMapCenter={handleMapCenterChange}
                />
              </InfoHeader>
              <SearchResultWrapper mobileSwipeOpen={mobileSwipeOpen}>
                <StoreCountWrapper onClick={() => setMobileSwipeOpen((mobileSwipeOpen) => !mobileSwipeOpen)}>
                  <p>
                    <span>{isLoading ? '0' : handleStoreDataChange().length}</span>개 매장
                  </p>
                  <Icon name={mobileSwipeOpen ? 'CircleDownArrow' : 'CircleUpperArrow'} />
                </StoreCountWrapper>
                {isLoading ? (
                  <>
                    <SpinnerWrapper>
                      <Spinner size={28} color="main" />
                    </SpinnerWrapper>
                  </>
                ) : (
                  <ScrollBody storeList={handleStoreDataChange()} setMapCenter={handleMapCenterChange} />
                )}
              </SearchResultWrapper>
            </InfoWrapper>
          </Route>
          <Route path="/map/:storeId">
            <MapStoreDetailSection />
          </Route>
        </Switch>
      </MainWrapper>

      <FilterView
        filterOpen={filterOpen}
        setOpen={setFilterOpen}
        setFilterState={setFilterState}
        filterState={filterState}
      />
    </>
  )
}

const LandingNavBarCustom = styled.header`
  nav {
    background-color: ${({ theme }) => theme.colors.white};
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const MainWrapper = styled.main`
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 80px;
  z-index: 10000001 !important;

  @media only screen and (max-width: 768px) {
    top: 0px;
    height: 100%;
  }
`

const Map = styled.section`
  width: calc(100vw - 400px);
  height: calc(100vh - 80px);

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: calc(100vh - 190px);
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
    margin-top: 128px;
    margin-bottom: 62px;
  }
`

const InfoWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  width: 400px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  z-index: 10000001 !important;

  @media only screen and (max-width: 768px) {
    position: fixed;
    border-top: none;
  }
`

const InfoHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrayishBlue};

  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 0px;
    z-index: 10000001 !important;
    width: 100vw;
  }
`

const OptionWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

const SearchOptions = styled.div`
  display: flex;

  svg {
    width: 28px;
    height: 28px;
    margin-top: 3px;
    margin-right: 16px;
  }

  label:first-child {
    margin-right: 16px;
  }

  label {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black};

    & > span {
      padding: 6px;
      cursor: pointer;

      &:hover {
        border-bottom: 1.6px solid ${({ theme }) => theme.colors.gray};
      }
    }
    input {
      display: none;

      &:checked + span {
        display: inline-block;
        font-weight: bold;
        border-bottom: 2px solid ${({ theme }) => theme.colors.black};
      }
    }
  }
`

const SearchResultWrapper = styled.div<{ mobileSwipeOpen: boolean }>`
  @media only screen and (max-width: 768px) {
    background-color: white;
    width: 100vw;
    height: calc(100% - 208px);
    position: fixed;
    bottom: 0px;
    @media only screen and (max-width: 768px) {
      transition: transform 0.3s ease;
      border-radius: 24px 24px 0px 0px;
      transform: translateY(${({ mobileSwipeOpen }) => (mobileSwipeOpen ? '20px' : 'calc(100% - 62px)')});
    }
  }
`

const StoreCountWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
  padding: 16px 8px;
  width: 400px;
  box-sizing: border-box;
  position: fixed;
  display: flex;
  top: 212px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.black};
  align-items: center;
  justify-content: center;
  & > p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 20px;
    font-weight: bold;
    color: black;

    span {
      color: ${({ theme }) => theme.colors.indigoA700};
    }
  }

  svg {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.white};
    width: 100vw;
    justify-content: space-between;
    top: unset;
    padding: 12px 20px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray};
    border-radius: 24px 24px 0px 0px;

    & > p {
      line-height: 36px;

      span {
        color: ${({ theme }) => theme.colors.black};
      }
    }

    svg {
      cursor: pointer;
      display: flex;
      width: 36px;
      height: 36px;
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
    }
  }
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 186px;

  @media only screen and (max-width: 768px) {
    right: calc(50% - 14px);
    top: -50%;
  }
`

export const FilterButton = styled.button<{ hover: boolean }>`
  background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
  padding: 6px;
  width: 60px;
  height: 34px;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    background-color: ${({ hover, theme }) => (hover ? theme.colors.white : theme.colors.lightGrayishBlue)};
  }

  & > div {
    display: flex;
    align-items: center;

    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black};

    svg {
      width: 14px;
      height: 14px;
    }
  }
`
