import { TypoH3 } from 'ohds-web'
import styled from 'styled-components'

export type YearAndMonthProps = {
  year: number
  month: number
}

export default function YearAndMonth({ year, month }: YearAndMonthProps) {
  return (
    <StyledYearAndMonth>
      <TypoH3 text={`${year}년 ${month}월`} fontWeight="bold" />
    </StyledYearAndMonth>
  )
}

const StyledYearAndMonth = styled.div`
  width: 100%;
  padding: 24px 12px;
  text-align: right;
`
