import { BottomCTA } from 'ohds-web'
import {
  SchedulesType,
  serviceHourOptions,
  setContractedSchedules,
  setServicePrice,
} from 'modules/features/service/serviceSlice'
import { getContractedDate } from 'lib/api/contractedDate/getContractedDate'
import { calcNextMonth, calcNextYear, getNow } from 'utils/common/calendar'
import { convertDateStringToSchedule } from 'utils/common/service'
import { useAppDispatch } from 'hooks/useReduxHook'
import useServicePostPush from 'hooks/service/useServicePostPush'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import styled from 'styled-components'
import serviceData from 'data/serviceData.json'

const { presentYear, presentMonth } = getNow()

export type BasicInfoBottomCTAProps = {
  linear: boolean
  label: string
  servicePrice: number
  disabled: boolean
}

export default function BasicInfoBottomCTA({ linear, label, servicePrice, disabled }: BasicInfoBottomCTAProps) {
  const { pushToFirstVisit } = useServicePostPush()

  const dispatch = useAppDispatch()

  const handleNextButtonClick = async () => {
    const contractedSchedules = await createContractedSchedule()

    dispatch(setContractedSchedules(contractedSchedules))
    pushToFirstVisit()
    LogManager.Instance.sendLog(
      'service_post_grade',
      {
        service_post_grade: '1_서비스일정',
      },
      LOGGER.GTM
    )

    dispatch(setServicePrice(servicePrice))
  }

  return (
    <StyledBottomCTA
      linear={linear}
      primaryProps={{
        label,
        disabled,
        onClick: handleNextButtonClick,
      }}
    />
  )
}

const StyledBottomCTA = styled(BottomCTA)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    left: 50%;
  }
`

// 임시
const createTempContractedSchedule = () => {
  const temp = []

  const tempBlockYear = 2023
  const tempBlockMonth = 12
  const tempBlockStartDate = 26
  // tempBlockStartDate로 부터 얼마동안 막을 것인지
  // ex) tempBlockStartDate = 21 => 21, 22일 막음
  const tempBlockPeriod = 4

  for (let i = 0; i < tempBlockPeriod; i++) {
    for (let j = 0; j < serviceHourOptions.length; j++) {
      for (let k = 0; k < serviceData.dayContractLimit; k++) {
        temp.push({
          year: tempBlockYear,
          month: tempBlockMonth,
          date: tempBlockStartDate + i,
          hour: serviceHourOptions[j],
        })
      }
    }
  }

  return temp
}

const createContractedSchedule = async () => {
  let year = presentYear
  let month = presentMonth

  const contractedSchedule: SchedulesType = createTempContractedSchedule()

  // 5번 반복인 이유
  // 달력은 3개월치 씩 제공 되며, 첫 시작일을 3번째 달에서 선택 시
  // 다음 화면들에서는 첫 시작일의 달인 3번째 달을 기준으로 3개월치씩 달력이 제공됩니다 (ex: 오늘 5월, 유저가 7월에 첫 시작일 선택 => 다음 화면 부턴 7, 8, 9월 달력 필요)
  // 그렇다면 유저는 서비스 신청 시 최대 5개월치의 달력까지만 볼 수 있기에 5번만 반복합니다
  // 물론 첫 시작일에 따라 유저가 몇개월치의 달력을 확인 하는지 알 수 있어 3, 4, 5로 경우에 따라 불러올 계약 개월을 조금이라도 효율적으로 조절 할 수는 있지만
  // 그 대응은 현재 필요해 보이지 않아 최대인 5개월로 반복 횟수를 고정합니다.
  for (let i = 0; i < 12; i++) {
    if (i !== 0) {
      year = calcNextYear(year, month)
      month = calcNextMonth(month)
    }

    const contractedDate = await getContractedDate({ year, month })

    if (contractedDate) {
      const convertedContractedSchedule = contractedDate.dates.map((date) => {
        return convertDateStringToSchedule(date)
      })

      contractedSchedule.push(...convertedContractedSchedule)
    }
  }

  return contractedSchedule
}
