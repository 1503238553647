export enum UTM_URL {
  LANDING_LOGIN_SERVICE_START_FIRST_SECTION = '?utm_source=br_landing&utm_medium=cta&utm_campaign=브라우니 랜딩 페이지&utm_content=main&utm_term=서비스신청&utm_id=login',
  LANDING_LOGOUT_SERVICE_START_FIRST_SECTION = '?utm_source=br_landing&utm_medium=cta&utm_campaign=브라우니 랜딩 페이지&utm_content=main&utm_term=서비스신청&utm_id=logout',
  LANDING_CONSULTING_FIRST_SECTION = '?utm_source=br_landing&utm_medium=cta&utm_campaign=브라우니 랜딩 페이지&utm_content=main&utm_term=상담신청',
  LANDING_STORE_TYPE_ICE = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=%EC%95%84%EC%9D%B4%EC%8A%A4%ED%81%AC%EB%A6%BC',
  LANDING_STORE_TYPE_LAUNDRY = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=%EC%85%80%ED%94%84%EB%B9%A8%EB%9E%98%EB%B0%A9',
  LANDING_STORE_TYPE_VENDING = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=%EB%AC%B4%EC%9D%B8%EC%9E%90%ED%8C%90%EA%B8%B0',
  LANDING_STORE_TYPE_CONVENIENCE = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=%EB%AC%B4%EC%9D%B8%ED%8E%B8%EC%9D%98%EC%A0%90',
  LANDING_STORE_TYPE_CAFE = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=%EB%AC%B4%EC%9D%B8%EC%B9%B4%ED%8E%98',
  LANDING_STORE_TYPE_STUDY_CAFE = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=%EC%8A%A4%ED%84%B0%EB%94%94%EC%B9%B4%ED%8E%98',
  LANDING_CONSULTING_QUESTION_SECTION = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=middle_%EA%B4%80%EB%A6%AC%EB%AC%B8%EC%9D%98%ED%95%98%EA%B8%B0',
  LANDING_GO_TO_WKDK = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=%EC%9B%8C%ED%82%A4%EB%8F%84%ED%82%A4',
  LANDING_GO_TO_MAP = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=%EA%B4%80%EB%A6%AC%EB%A7%A4%EC%9E%A5%EC%A7%80%EB%8F%84',
  LANDING_CONSULTING_TRIGGER = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=bottom_%EC%83%81%EB%8B%B4%EC%8B%A0%EC%B2%AD',
  LANDING_SERVICE_FEE_LOGIN = '?utm_source=br_landing&utm_medium=cta&utm_campaign=%EB%B8%8C%EB%9D%BC%EC%9A%B0%EB%8B%88%20%EB%9E%9C%EB%94%A9%20%ED%8E%98%EC%9D%B4%EC%A7%80&utm_content=main&utm_term=middle_login_%EC%84%9C%EB%B9%84%EC%8A%A4%EC%8B%A0%EC%B2%AD',
  LANDING_SERVICE_FEE_LOGOUT = '?utm_source=br_landing&utm_medium=cta&utm_campaign=브라우니 랜딩 페이지&utm_content=main&utm_term=middle_logout_서비스신청',
  LANDING_FLOATING_SERVICE_LOGIN_START = '?utm_source=br_landing&utm_medium=cta&utm_campaign=브라우니 랜딩 페이지&utm_content=main&utm_term=유령_login_서비스신청',
  LANDING_FLOATING_SERVICE_LOGOUT_START = '?utm_source=br_landing&utm_medium=cta&utm_campaign=브라우니 랜딩 페이지&utm_content=main&utm_term=유령_logout_서비스신청',
  FLOATING_CONSULTING = '?utm_source=br_landing&utm_medium=cta&utm_campaign=브라우니 랜딩&utm_content=type&utm_term=유령_consulting',
  DETAIL_FLOATING_SERVICE_LOGIN_START = '?utm_source=br_landing&utm_medium=cta&utm_campaign=브라우니 랜딩&utm_content=type&utm_term=유령_login_서비스신청',
  DETAIL_FLOATING_SERVICE_LOGOUT_START = '?utm_source=br_landing&utm_medium=cta&utm_campaign=브라우니 랜딩&utm_content=type&utm_term=유령_logout_서비스신청',
}
