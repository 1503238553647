import { forwardRef, memo } from 'react'
import styled from 'styled-components'

type SelectProps = React.HTMLAttributes<HTMLSelectElement> & {
  name: string
  value: string
  options: {
    value: string
    label: string
  }[]
  placeholder: string
}

export const Select = memo(
  forwardRef<HTMLSelectElement, SelectProps>(({ name, id, value, options, placeholder, ...props }, ref) => {
    return (
      <StyledSelect id={id} name={name} value={value} ref={ref} placeholder={placeholder} {...props}>
        {!value && placeholder && (
          <option selected value={undefined}>
            {placeholder}
          </option>
        )}
        {options.map(({ value, label }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </StyledSelect>
    )
  })
)

const StyledSelect = styled.select`
  width: 100%;

  padding: 7px 12px;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dde0e4;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.88);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`
