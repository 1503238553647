import styled from 'styled-components'

export type SelectionLabelProps = {
  label: string
} & React.LabelHTMLAttributes<HTMLLabelElement>

export default function SelectionLabel({ label, htmlFor }: SelectionLabelProps) {
  return <StyledSelectionLabel htmlFor={htmlFor}>{label}</StyledSelectionLabel>
}

const StyledSelectionLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  margin-left: 8px;
  word-break: keep-all;

  // mobile
  ${({ theme }) => theme.media.mobile`
    font-size: 12px;
    line-height: 16px;
  `}
`
