import client from '../client'

export async function postCoupon(req: PostCouponReq) {
  const response = await client.post(`/payment-api/v1/coupon/code`, req)
  return response.data
}

export type PostCouponReq = {
  code: string
}
