import client from '../client'

export async function getResetEmail({ email, token }: ResetEmailParams) {
  const response = await client.get<undefined>(`auth-api/v1/auth/reset/email/${email}/${token}`)

  return response.data
}

export type ResetEmailParams = {
  email: string
  token: string
}
