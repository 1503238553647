import ILogger from './ILogger'
import { LOGGER, LogName, LogPayload } from './types'

class LogManager {
  private static instance?: LogManager
  private loggers: ILogger[] = []

  public static get Instance(): LogManager {
    if (!LogManager.instance) {
      LogManager.instance = new LogManager()
    }
    return LogManager.instance
  }

  public didLoggerAttached(type: LOGGER) {
    return this.loggers.some((logger: ILogger) => {
      return logger.type === type
    })
  }

  public attachLogger(logger: ILogger) {
    if (this.didLoggerAttached(logger.type)) {
      console.warn(`[LOG MANAGER]: LOGGER (${logger.type}) ALREADY ATTACHED`)
      return
    }
    if (!logger.onAttached()) {
      console.warn(`[LOG MANAGER]: LOGGER (${logger.type}) ATTACH FAILED`)
      return
    }
    this.loggers.push(logger)
  }
  
  public detachLogger(type: LOGGER) {
    if (this.didLoggerAttached(type)) {
      console.warn(`[LOG MANAGER]: CAN NOT FOUND ATTACHED LOGGER (${type})`)
      return
    }
    this.loggers = this.loggers.filter((logger) => {
      if (logger.type === type && !logger.onDetached()) {
        console.warn(`[LOG MANAGER]: LOGGER (${logger.type}) DETACH FAILED`)
      }
      return logger.type !== type
    })
  }

  public sendLog<T extends LogName = LogName>(name: T, data: LogPayload<T>, type?: LOGGER) {
    if (process.env.REACT_APP_ENV !== 'production') {
      return
    }

    this.loggers.forEach((logger: ILogger) => {
      if (type && logger.type === type) {
        logger.onLog(name, data)
      } else if (!type) {
        logger.onLog(name, data)
      }
    })
  }
}

export default LogManager