import SmallButton from 'components/common/SmallButton'
import usePatchModifyRealName from 'hooks/query/auth/usePatchModifyRealName'
import useClickAwayListener from 'hooks/useClickAwayListener'
import { useRef, useState } from 'react'
import styled from 'styled-components'

export type RealNameChangeInputProps = {
  realName: string
}

export default function RealNameChangeInput({ realName: initRealName }: RealNameChangeInputProps) {
  const [realName, setRealName] = useState<string>(initRealName)
  const ref = useRef<HTMLLIElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const { mutateAsync: mutateModifyRealName } = usePatchModifyRealName()

  useClickAwayListener({ ref, onOutsideClick: () => setRealName(initRealName) })

  const handleSaveRealName = async () => {
    if (realName === initRealName) return
    await mutateModifyRealName({ new_name: realName })
    inputRef.current?.blur()
  }

  const handleInputKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await handleSaveRealName()
    }
  }

  return (
    <StyledContainer ref={ref}>
      <h5>성명</h5>
      <span>:</span>
      <input
        ref={inputRef}
        type="text"
        value={realName}
        placeholder={initRealName}
        onChange={(e) => setRealName(e.currentTarget.value)}
        onKeyDown={handleInputKeyDown}
      />
      <SmallButton buttonTheme={realName !== initRealName ? 'primary' : 'disabled'} onClick={handleSaveRealName}>
        저장
      </SmallButton>
    </StyledContainer>
  )
}

const StyledContainer = styled.li`
  width: 320px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};

  h5,
  & > span {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    line-height: 24px;
    opacity: 0.5;
  }

  h5 {
    width: 86px;
    height: 24px;
  }

  input {
    margin-left: 8px;
    width: 149px;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    line-height: 24px;
    padding-right: 8px;
    border: none;
  }

  input::placeholder {
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.5;
  }

  /* mobile */
  ${({ theme }) => theme.media.mobile`
    width: 280px;

    input {
      width: 114px;
    }
  `}
`
