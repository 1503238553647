import styled, { css } from 'styled-components'

export type ServiceBadgeType =
  | 'PAYMENT'
  | 'CONTRACT'
  | 'USING'
  | 'WAIT_DEPOSIT'
  | 'CANCEL_APPLICATION'
  | 'SUBSCRIBE'
  | 'EXPIRATION'
  | 'PAYMENT_FAIL'
  | 'CANCEL_APPLICATION_EXPIRATION'

export const getBadgeName = (type: ServiceBadgeType) => {
  // 아직 결제를 완료하지 않은 경우
  if (type === 'PAYMENT') return '결제 & 계약'
  // 결제 완료 후 스케줄 시작 전까지의 상태
  if (type === 'CONTRACT') return '계약 완료'
  // 서비스 스케줄이 시작 되고 스케줄이 끝나지 않았을 때까지의 상태
  if (type === 'USING') return '이용 중'
  // 무통장 입금 확인 전
  if (type === 'WAIT_DEPOSIT') return '입금 대기'
  // 계약 중도 해지 신청 상태
  // 해지 된 상태 X
  if (type === 'CANCEL_APPLICATION') return '계약해지 신청'
  // 정기결제는 상품 종류!!
  if (type === 'SUBSCRIBE') return '📣 자동 결제'
  if (type === 'EXPIRATION') return '이용 만료'
  if (type === 'PAYMENT_FAIL') return '결제 실패'
  // 계약 해지 완료 = 계약 해지 승인
  if (type === 'CANCEL_APPLICATION_EXPIRATION') return '해지 만료'
}

export const getBadgeColor = (type: ServiceBadgeType) => {
  if (type === 'PAYMENT')
    return {
      background: '#CCDDFF',
      color: '#003399',
    }
  if (type === 'CONTRACT')
    return {
      background: '#1A202C',
      color: '#FFFFFF',
    }
  if (type === 'USING')
    return {
      background: '#00A6B7',
      color: '#FFFFFF',
    }
  if (type === 'WAIT_DEPOSIT')
    return {
      background: '#FB8C00',
      color: '#FFFFFF',
    }
  if (type === 'CANCEL_APPLICATION')
    return {
      background: '#F7D7D7',
      color: '#D32F2F',
    }
  if (type === 'SUBSCRIBE')
    return {
      background: '#4A5568',
      color: '#FFFFFF',
    }
  if (type === 'EXPIRATION' || type === 'CANCEL_APPLICATION_EXPIRATION')
    return {
      background: '#FFFFFF',
      color: '#757575',
    }
  if (type === 'PAYMENT_FAIL')
    return {
      background: '#D32F2F',
      color: '#FFFFFF',
    }

  return {
    background: '#FFFFFF',
    color: '#757575',
  }
}

interface ServiceBadgeProp {
  badgeType: ServiceBadgeType
}

export default function ServiceBadge({ badgeType }: ServiceBadgeProp) {
  return (
    <Container
      $color={getBadgeColor(badgeType)?.color}
      $bgColor={getBadgeColor(badgeType)?.background}
      $isBorder={badgeType === 'EXPIRATION' || badgeType === 'CANCEL_APPLICATION_EXPIRATION'}
    >
      {getBadgeName(badgeType)}
    </Container>
  )
}

interface CustomServiceBadgeProp {
  name: string
  badgeType: ServiceBadgeType | { color: string; bgColor: string }
}
export function CustomServiceBadge({ name, badgeType }: CustomServiceBadgeProp) {
  const color = typeof badgeType === 'string' ? getBadgeColor(badgeType).color : badgeType.color
  const bgColor = typeof badgeType === 'string' ? getBadgeColor(badgeType).background : badgeType.bgColor

  return (
    <Container $color={color} $bgColor={bgColor}>
      {name}
    </Container>
  )
}

const Container = styled.div<{ $color?: string; $bgColor?: string; $isBorder?: boolean }>`
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$bgColor};
  ${(props) =>
    props.$isBorder &&
    css`
      border: 1px solid var(--gray-gray-100, #f5f5f5);
    `}

  width: max-content;
  font-weight: 500;
  font-size: 12px;
  padding: 6px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
