import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function RedirectPage() {
  const history = useHistory()
  const search = useLocation().search
  useEffect(() => {
    const query = new URLSearchParams(search).get('uri') ?? '/'
    history.replace(query.replace('/index.html', ''))
  }, [history, search])
  return <div></div>
}
