import React from 'react'
import Button from '../Button'
import ModalLayout from '../../Layout/ModalLayout'
import { ModalHeaderColorType } from '../../Layout/ModalLayout/ModalLayout'
import Spinner from '../Spinner'

export type ConfirmModalProps = {
  open: boolean
  title: string
  headline?: string
  subline?: string
  confirmText: string
  closeText: string
  onConfirm: () => void | Promise<void>
  onClose: () => void
  onSecondary?: () => void
  children?: React.ReactNode
  headerColor?: ModalHeaderColorType
  isConfirmLoading?: boolean
}

export default function ConfirmModal({
  open,
  title,
  headline,
  subline,
  confirmText,
  closeText,
  onConfirm,
  onClose,
  onSecondary,
  children,
  headerColor = 'black',
  isConfirmLoading,
}: ConfirmModalProps) {
  return (
    <ModalLayout onClose={isConfirmLoading ? undefined : onClose} open={open}>
      <ModalLayout.Header color={headerColor}>{title}</ModalLayout.Header>
      <ModalLayout.Body>
        {headline && (
          <ModalLayout.Headline>
            {headline.split('\\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </ModalLayout.Headline>
        )}
        {subline && (
          <ModalLayout.Subline>
            {subline.split('\\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </ModalLayout.Subline>
        )}
        {children}
      </ModalLayout.Body>
      <ModalLayout.Footer>
        <Button
          width={120}
          onClick={isConfirmLoading ? undefined : onSecondary ? onSecondary : onClose}
          buttonTheme="secondary"
        >
          {closeText}
        </Button>
        <Button width={120} onClick={isConfirmLoading ? undefined : onConfirm} buttonTheme="primary">
          {isConfirmLoading ? <Spinner color="white" /> : confirmText}
        </Button>
      </ModalLayout.Footer>
    </ModalLayout>
  )
}
