import React, { useState } from 'react'

export type ConsultFormLabel = 'name' | 'storeName' | 'phone'

export type ErrorObj = { isError: boolean; errorText: string }

export function useEasyConsulting() {
  const [name, setName] = useState<string>('')
  const [storeName, setStoreName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [isCheck, setIsCheck] = useState<boolean>(false)
  const [hopeTime, setHopeTime] = useState<string>('')
  const [isHopeTimeOpen, setIsHopeTimeOpen] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const hopeTimeList = [
    '9시~10시',
    '10시~11시',
    '11시~12시',
    '12시~13시',
    '14시~15시',
    '15시~16시',
    '16시~17시',
  ] as const

  const handleOptionClick = (selectHopeTime: string) => {
    setHopeTime(selectHopeTime)
    setIsHopeTimeOpen(false)
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setName((name) => (name = value))
  }

  const handleStoreNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setStoreName((storeName) => (storeName = value))
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setPhone((phone) => (phone = value))
  }

  const handleCheckChange = () => {
    setIsCheck(!isCheck)
  }

  const handleErrorCheck = (type: ConsultFormLabel) => {
    let errorObj: ErrorObj = { isError: false, errorText: '' }

    if (type === 'name') {
      const nameValidCheck = /[`~!@#$%^&*|\\\\'\\";:\\/?]/gi.test(name)
      if (name.length < 2 || nameValidCheck) {
        errorObj.isError = true
      }
      if (name.length === 0) {
        errorObj.errorText = '성함을 입력해주세요.'
      } else if (name.length === 1) {
        errorObj.errorText = '성함은 2자 이상 입력해주세요.'
      } else if (nameValidCheck) {
        errorObj.errorText = '사용할 수 없는 문자가 포함되어 있습니다.'
      }
    } else if (type === 'storeName') {
      if (storeName.length < 2) {
        errorObj.isError = true
      }
      if (storeName.length === 0) errorObj.errorText = '매장명을 입력해주세요.'
      else if (storeName.length === 1) errorObj.errorText = '매장명은 2자 이상 입력해주세요.'
    } else if (type === 'phone') {
      const phoneValidCheck = /^[0-9]+$/.test(phone)
      if (phone.length === 0 || phone.length > 11 || !phoneValidCheck) {
        errorObj.isError = true
      }
      if (phone.length === 0) {
        errorObj.errorText = '전화번호를 입력해주세요.'
      } else if (phone.length > 11) {
        errorObj.errorText = '전화번호는 11자 이하로 입력해주세요.'
      } else if (!phoneValidCheck) {
        errorObj.errorText = '옳지 않은 번호 양식입니다.'
      }
    }

    return errorObj
  }

  return {
    name,
    storeName,
    phone,
    isCheck,
    hopeTime,
    isModalOpen,
    hopeTimeList,
    isHopeTimeOpen,
    setIsModalOpen,
    setIsHopeTimeOpen,
    handleOptionClick,
    handleNameChange,
    handleStoreNameChange,
    handlePhoneChange,
    handleCheckChange,
    handleErrorCheck,
  }
}
