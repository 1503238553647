import { useParams } from 'react-router'
import styled from 'styled-components'
import Icon from 'components/Icon'
import serviceTypeData from 'data/serviceTypeData.json'

type ReviewStoreList = {
  tag: string[]
  src: string
  title_word: string
  full_title: string
  link: string
  is_interview?: boolean
}

export default function ServiceDetailFourthSection() {
  const { type } = useParams<{ type: string }>()
  const typeData = serviceTypeData.find(({ param }) => param === type)
  const dataList = typeData?.review_store as ReviewStoreList[]
  const isInterview = !!dataList[0].is_interview

  const isLifeFourCut = type === 'studio'

  return (
    <FourthSectionWrapper>
      <ContentWrapper>
        <h3>아직 고민중이라면?</h3>
        <h2>
          브라우니 안심매장은
          <br />
          이렇게 관리됩니다!
        </h2>
        <ServiceUl>
          {!isInterview && <Icon className="rabbit" name="Rabbit" />}
          {dataList &&
            dataList.map(({ src, title_word, full_title, tag, link, is_interview }, index) => (
              <ServiceLi key={index}>
                <div className="tagWrapper">
                  {tag.map((tag_content, j) => (
                    <div className="tag" key={j}>
                      <span>{tag_content}</span>
                    </div>
                  ))}
                </div>
                <ServiceContentWrap
                  key={index}
                  onClick={() => window.open(link, '_blank')}
                  isLifeFourCut={isLifeFourCut}
                >
                  <img src={src} alt="무인매장 브라우니" />
                  <div className={`informWrapper ${!!is_interview && 'interview'}`}>
                    {!!is_interview ? (
                      <>
                        <span className="full_title_wrap">{full_title}</span>
                        <button className="interview_button">
                          <span>인터뷰 바로가기</span>
                          <Icon name="blackRightArrow" />
                        </button>
                      </>
                    ) : (
                      <button className="blog_button">
                        브라우니 안심매장 &nbsp;<span>'{title_word}'</span> &nbsp;바로가기
                        <Icon name="blackRightArrow" />
                      </button>
                    )}
                  </div>
                </ServiceContentWrap>
              </ServiceLi>
            ))}
          {!isInterview && <Icon name="Sheep" className="sheep" />}
        </ServiceUl>
      </ContentWrapper>
    </FourthSectionWrapper>
  )
}

const FourthSectionWrapper = styled.section`
  width: 100%;
  height: 990px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 768px) {
    height: 1100px;
    justify-content: center;
  }
`

const ContentWrapper = styled.div`
  width: 1030px;
  height: 630px;
  margin: 0 auto;
  position: relative;

  h3 {
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.indigoA700};
  }

  h2 {
    position: absolute;
    top: 42px;
    left: 0px;
    font-weight: 700;
    font-size: 64px;
    line-height: 90px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
  }

  @media only screen and (max-width: 768px) {
    position: relative;
    width: 360px;
    height: 100%;
    margin: 0px 16px;

    h2 {
      position: absolute;
      top: 108px;
      left: 0px;
      font-size: 30px;
      line-height: 40px;
    }

    h3 {
      position: absolute;
      top: 80px;
      left: 0px;
      font-size: 14px;
      line-height: 24px;
    }
  }
`

const ServiceUl = styled.ul`
  position: absolute;
  top: 302px;
  left: 0px;
  width: 100%;
  height: 688px;
  display: grid;
  grid-template-columns: 506px 506px;
  grid-template-rows: 360px;
  justify-content: space-between;

  & > svg {
    position: absolute;
    &:first-child {
      top: 302px;
      left: 0px;
      z-index: 1;
    }
    &:last-child {
      top: 0;
      right: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 690px;
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, 1fr);
    top: 220px;

    & > svg {
      position: absolute;
      &:first-child {
        bottom: -140px;
        left: -35px;
        z-index: 1;
        top: initial;
      }
      &:last-child {
        top: 10px;
        right: 0;
      }
    }
  }
`

const ServiceLi = styled.li`
  .tagWrapper {
    display: flex;
    .tag {
      width: fit-content;
      padding: 4px 10px;
      border-radius: 999px;
      background: var(--blue-blue-100, #cce5ff);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
      & > span {
        color: var(--blue-blue-500, #0063cc);
        font-size: 12px;
        font-weight: 500;
      }
      margin-bottom: 12px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: fit-content;
    margin-top: 30px;

    .tagWrapper {
      justify-content: center;
      align-items: center;
      gap: 4px;
      .tag:last-child {
        margin-bottom: 12px;
      }
    }
  }
`

const ArrowCircleButton = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 24px;
    height: 24px;

    path {
      stroke: ${({ theme }) => theme.colors.black};
    }
  }

  @media only screen and (max-width: 768px) {
    bottom: 116px;
    width: 32px;
    height: 32px;
  }
`

const ServiceContentWrap = styled.div<{ isLifeFourCut?: boolean }>`
  cursor: pointer;
  width: 506px;
  height: 328px;
  border-radius: 40px;
  background-size: cover;
  position: relative;

  & > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    border-radius: 40px;
  }

  & > svg {
    position: absolute;
    bottom: 92px;
    left: 24px;
    z-index: 1;
    transition: 0.3s ease-in-out;
  }

  .interview {
    width: 100%;
    padding: 24px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .interview_button {
      align-self: flex-end;
    }

    .full_title_wrap {
      width: 58%;
      min-height: 56px;
      font-size: 18px;
      line-height: 28px;
      color: var(--White, #fff);
      align-self: stretch;
      font-style: normal;
      font-weight: 500;
    }
  }

  .informWrapper {
    position: absolute;
    width: 100%;
    height: 104px;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    border-radius: 0px 0px 40px 40px;
    transition: all 0.3s ease-in-out;

    & > button {
      background: var(--White, #fff);
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      width: fit-content;
      color: var(--gray-gray-700, #333);
      font-size: 16px;
      font-weight: 700;
      margin: 0 auto;
      cursor: pointer;

      &:is(.blog_button) {
        color: var(--gray-gray-600, #757575);
        padding: ${({ isLifeFourCut }) => isLifeFourCut && '10px 10px'};
        & > span {
          color: var(--gray--gray800, #212121);
        }
      }
    }

    & > svg {
      width: 24px;
      height: 24px;
    }

    .textWrapper {
      padding: 24px;
      width: 100%;
      height: 104px;
      box-sizing: border-box;
      position: relative;

      p {
        max-width: 393px;
        height: 56px;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        word-break: keep-all;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  &:hover {
    .interview {
      .interview_button {
        align-self: flex-end !important;
      }
    }
    .informWrapper {
      height: 100%;
      bottom: 0px;
      background: linear-gradient(0deg, rgba(61, 90, 254, 0.72), rgba(61, 90, 254, 0.72));
      backdrop-filter: none;
      border-radius: 40px;
      .textWrapper {
        margin: auto;
      }
    }

    .full_title_wrap {
      transform: translateY(40%);
    }
  }

  & > svg {
    bottom: 204px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 328px;

    & > svg {
      bottom: 120px;
    }

    .interview {
      display: flex;
      justify-content: space-between !important;
      padding: 20px !important;

      .full_title_wrap {
        width: 75%;
        min-width: 75%;
        display: flex;
        align-items: center;
        min-height: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .informWrapper {
      padding: 20px 0px;

      & > button {
        font-size: 14px;
      }

      & > button::before {
        content: '' !important;
      }

      .interview_button {
        margin: 0px 0px 10px !important;
        padding: 10px;

        span {
          display: none;
        }
      }

      .textWrapper {
        height: inherit;

        p {
          height: 84px;
          -webkit-line-clamp: 3;
        }
      }
    }

    &:hover {
      .interview {
        align-items: center !important;
        .full_title_wrap {
          transform: none;
        }
      }
      .informWrapper {
        height: 104px !important;
        bottom: 0px !important;
        background: rgba(0, 0, 0, 0.5) !important;
        backdrop-filter: blur(4px) !important;
        border-radius: 0px 0px 40px 40px !important;
        .textWrapper {
          margin: auto;
        }
        .textWrapper {
          margin: auto;
        }
      }

      & > svg {
        bottom: 120px;
      }
    }
  }
`
