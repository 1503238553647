import Icon from 'components/Icon'
import useGetGeocoding from 'hooks/query/map/useGetGeocoding'
import { LocationType } from 'pages/MapPage/MapPage'
import React, { memo, useEffect, useRef, useState } from 'react'
import DaumPostcode, { Address } from 'react-daum-postcode'
import styled, { css } from 'styled-components'
import { debounce } from 'lodash'
import useGetSearchMap from 'hooks/query/map/useGetSearchMap'
import { GetSearchMapRes } from 'lib/api/map/types'
import { isMobile } from 'react-device-detect'

export type AddressSearchMapProps = {
  searchOption: 'address' | 'storeName'
  setAddressSearch: React.Dispatch<React.SetStateAction<LocationType | null>>
  setMapCenter: (location: LocationType, singleOption?: boolean) => void
}

/**
 * @description 주소 검색 기능 컴포넌트
 * @param searchOption 주소 검색 옵션 2가지 선택
 * @param setAddressSearch 주소 검색 핸들 함수
 * @param setMapCenter 지도 위치를 옮겨주는 함수
 */

function AddressSearchMap({ searchOption, setAddressSearch, setMapCenter }: AddressSearchMapProps) {
  const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const { mutateAsync: mutateGetGeocoding } = useGetGeocoding()
  const { mutateAsync: mutateGetSearchMap } = useGetSearchMap()
  const [searchResult, setSearchResult] = useState<GetSearchMapRes[]>([])
  const [searchListBox, setSearchListBox] = useState<boolean>(false)

  useEffect(() => {
    setOpenPopUp(false)
    setSearchListBox(false)
    setSearchKeyword('')
    setSearchResult([])
  }, [searchOption])

  useEffect(() => {
    // PC에서 검색어가 없을때
    if (searchKeyword.length === 0 && !isMobile) setSearchListBox(false)
  }, [searchKeyword])

  const handleCompleteAddressSearch = async (data: Address) => {
    if (data) {
      setSearchKeyword(data.address)

      const searchResultLocation = await mutateGetGeocoding({
        address: data.address,
      })

      setAddressSearch(searchResultLocation)
      setOpenPopUp(false)
    }
  }

  const handleCloseIconClick = () => {
    setSearchResult([])
    setSearchKeyword('')
  }

  const handleMobileBoxCloseClick = () => {
    setSearchResult([])
    setSearchListBox(false)
    setSearchKeyword('')
  }

  const handleItemClick = (data: GetSearchMapRes) => {
    setSearchListBox(false)
    setMapCenter({ latitude: data.latitude, longitude: data.longitude }, true)
    setSearchKeyword(data.store_name)
  }
  const sendQuery = async (query: string) => {
    if (query.length === 0) return

    //맨 마지막 글자에 띄어쓰기가 있을 때 지워줌
    if (query.charAt(query.length - 1) === ' ') {
      query = query.substr(0, query.length - 1)
    }
    const data = await mutateGetSearchMap({ keyword: query })
    setSearchResult(data)
  }

  const delayDebounceCall = useRef(
    debounce((q) => {
      setSearchResult([])
      sendQuery(q)
    }, 150)
  ).current

  const handleStoreMapSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchOption === 'storeName') {
      const { value } = e.target
      setSearchListBox(true)
      setSearchKeyword(value)
      delayDebounceCall(value)
    }
  }

  return (
    <SectionWrapper>
      <InputWrapper>
        {searchKeyword.length > 0 && (
          <ClearIcon onClick={handleCloseIconClick} mobilePosition={searchListBox}>
            <Icon name="blackClose" />
          </ClearIcon>
        )}
        <input
          onFocus={() => {
            isMobile && searchOption === 'storeName' && setSearchListBox(true)
          }}
          spellCheck={false}
          placeholder={searchOption === 'address' ? '주소를 입력해주세요.' : '매장명을 입력해주세요.'}
          onClick={() => setOpenPopUp(true)}
          value={searchKeyword}
          onChange={handleStoreMapSearchChange}
        />
        {isMobile && searchListBox && <MobileCloseButton onClick={handleMobileBoxCloseClick}>취소</MobileCloseButton>}
      </InputWrapper>
      <BoxWrapper open={searchListBox}>
        {searchResult.length !== 0 && <hr />}
        {searchResult.map((item, index) => (
          <li key={index} onClick={() => handleItemClick(item)}>
            <p>{item.store_name}</p>
            <span>{item.address}</span>
          </li>
        ))}
      </BoxWrapper>

      {searchOption === 'address' && openPopUp && (
        <ModalWrapper>
          <CloseButton onClick={() => setOpenPopUp(false)}>
            <Icon name="mobileOptionClose" />
          </CloseButton>
          <StyledDaumPostcode onComplete={handleCompleteAddressSearch} />
        </ModalWrapper>
      )}
    </SectionWrapper>
  )
}

const SectionWrapper = styled.section`
  padding: 0px 16px 16px 16px;
  width: 400px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`

const ModalWrapper = styled.div`
  flex-direction: column;
  position: absolute;
  left: 0px;
  width: 100vw;
`

const InputWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px 16px;
  position: relative;
  width: inherit;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;

  input {
    background-color: ${({ theme }) => theme.colors.white};
    min-width: 300px;
    height: 100%;
    border: 0;
    font-size: 16px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.black};

    &::placeholder {
      opacity: 0.4;
    }
  }

  svg {
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    height: 46px;
    padding: 8px;

    input {
      font-size: 14px;
      line-height: 24px;
    }
  }
`

const MobileCloseButton = styled.button`
  background: none;
  padding: 2px 2px 2px 8px;
  border-left: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};
  width: 40px;
  display: flex;
  align-items: center;
  height: 26px;
  box-sizing: border-box;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
`

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  left: 364px;

  display: flex;
  cursor: pointer;
  z-index: 10;

  svg {
    background-color: ${({ theme }) => theme.colors.gray};
    width: 20px;
    height: 20px;
  }

  @media only screen and (max-width: 768px) {
    right: 16px;
    left: unset;
  }
`

const StyledDaumPostcode = styled(DaumPostcode)`
  padding: 0 16px 0px 16px;
  width: 400px !important;
  min-height: 468px !important;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 9;

  @media only screen and (max-width: 768px) {
    width: 100vw !important;
  }
`

const ClearIcon = styled.div<{ mobilePosition: boolean }>`
  position: absolute;
  right: 16px;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;

  svg {
    width: 18px;
    height: 18px;
    background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
    border-radius: 16px;
  }

  @media only screen and (max-width: 768px) {
    right: ${({ mobilePosition }) => (mobilePosition ? '60px' : '16px')};
  }
`

const BoxWrapper = styled.ul<{ open: boolean }>`
  display: none;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 44px;
  height: 403px;
  width: 368px;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 11%);
  overflow-y: scroll;
  z-index: 50;

  hr {
    border: none;
    margin: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
  }

  li {
    cursor: pointer;
    width: inherit;
    height: 56px;
    padding: 0px 16px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-weight: bold;

    & > p {
      font-size: 14px;
      line-height: 22px;
    }

    & > span {
      font-size: 10px;
      line-height: 18px;
      opacity: 0.4;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* ellipsis line */
      -webkit-box-orient: vertical;
    }

    &:last-child {
      border: none;
      height: 60px;
      border-radius: 0px 0px 8px 8px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGrayishBlue};
    }
  }

  ${({ open }) =>
    open &&
    css`
      display: block;
    `}

  @media only screen and (max-width: 768px) {
    top: 60px;
    width: 100vw;
    height: 100vh;
  }
`

export default memo(AddressSearchMap)
