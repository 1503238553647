import client from '../client'

export async function patchModifyRealName({ new_name }: ModifyRealNameReq) {
  // 과거에는 realname이 아닌 nickname 이였어서, api URL은 여전히 nickname 입니다
  const response = await client.patch<undefined>(`auth-api/v1/auth/modify/nickname`, { new_name })

  return response.data
}

export type ModifyRealNameReq = {
  new_name: string
}
