import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { BottomCTA, BottomSheet, SelectBox } from 'ohds-web'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import { DayArrType, ServiceDayValueType, setServiceDayValues } from 'modules/features/service/serviceSlice'

export type ServiceDaySelectBoxProps = {
  autoOpen: boolean
  label: string
  value?: string
  for1Week: boolean
  serviceCount: number
  dayOptions: DayArrType[]
  serviceWeek?: number
}

export default function ServiceDaySelectBox({
  autoOpen,
  value,
  label,
  for1Week,
  serviceCount,
  serviceWeek,
  dayOptions,
}: ServiceDaySelectBoxProps) {
  const dispatch = useAppDispatch()
  const { storeAddressType, serviceDayValues } = useAppSelector((state) => state.service)

  const [isDaySelectOpen, setIsDaySelectOpen] = useState<boolean>(false)
  const [selectedDayValues, setSelectedDayValues] = useState<ServiceDayValueType[]>([])

  const openOption = () => setIsDaySelectOpen(true)
  const closeOption = () => setIsDaySelectOpen(false)
  const initSelected = () => setSelectedDayValues([])
  const selectAllWeekday = () => setSelectedDayValues([0, 1, 2, 3, 4])

  useEffect(() => {
    if (autoOpen) openOption()
  }, [autoOpen])

  useEffect(() => {
    setSelectedDayValues([...serviceDayValues])
  }, [serviceDayValues])

  const handleSelectBoxClick = () => {
    openOption()
  }

  const handleBottomSheetClose = () => {
    closeOption()
  }

  const handleSelectCompleteClick = () => {
    closeOption()
    dispatch(setServiceDayValues(selectedDayValues))
    initSelected()
  }

  const handleSelectAllWeekdayClick = () => {
    selectAllWeekday()
  }

  const handleDayButtonClick = (index: number) => {
    const findedIndex = selectedDayValues.findIndex((day) => day === index)
    const isNotFound = findedIndex === -1

    if (isNotFound) {
      selectedDayValues.push(index as ServiceDayValueType)
      selectedDayValues.sort()
    } else {
      selectedDayValues.splice(findedIndex, 1)
    }

    setSelectedDayValues([...selectedDayValues])
  }

  // 주 3회 패키지는 주말 2일 + 평일 1일 선택 가능
  const isTwoWeekendSelectedPossible = serviceWeek === 4 && serviceCount === 3

  return (
    <SelectBox
      label={label}
      value={value}
      onSelectBoxClick={handleSelectBoxClick}
      BottomSheet={
        <StyledBottomSheet
          title="관리 받을 요일을 선택해 주세요"
          subTitle={
            for1Week
              ? '1주 패키지는 평일만 가능해요'
              : isTwoWeekendSelectedPossible
              ? ''
              : serviceCount >= 3
              ? '주말은 하루만 가능해요'
              : undefined
          }
          open={isDaySelectOpen}
          onClose={handleBottomSheetClose}
          BottomCTA={
            <StyledBottomCTA
              primaryProps={{
                label: '선택 완료',
                onClick: handleSelectCompleteClick,
                disabled: selectedDayValues.length !== serviceCount,
              }}
            />
          }
        >
          <DaySelectContainer>
            {dayOptions.map((day, dayValue) => {
              const isDayButtonSelected = selectedDayValues.includes(dayValue as ServiceDayValueType)
              const isDayButtonDisabled = isTwoWeekendSelectedPossible
                ? selectedDayValues.length === serviceCount && !isDayButtonSelected
                : (selectedDayValues.includes(5) && day === '일') ||
                  (selectedDayValues.includes(6) && day === '토') ||
                  (selectedDayValues.length === serviceCount && !isDayButtonSelected)

              return (
                <DayButton
                  key={dayValue}
                  selected={isDayButtonSelected}
                  onClick={() => handleDayButtonClick(dayValue)}
                  disabled={isDayButtonDisabled}
                  withWeekDay={dayOptions.length === 7}
                >
                  {day}
                </DayButton>
              )
            })}
          </DaySelectContainer>
          <SelectableCount>선택해야 할 요일 수 : {serviceCount - selectedDayValues.length}</SelectableCount>
        </StyledBottomSheet>
      }
    />
  )
}

const DaySelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  button ~ button {
    margin-left: 8px;
  }
`

const DayButton = styled.button<{ selected?: boolean; disabled?: boolean; withWeekDay: boolean }>`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--h3);
  font-weight: 400;
  background-color: var(--gray100);
  color: var(--gray500);
  cursor: pointer;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: var(--gray200);
      color: var(--gray400);
      cursor: not-allowed;
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--brand300);
      color: var(--onBrand300);
    `}

  border-radius: 12px;
`

const StyledBottomCTA = styled(BottomCTA)`
  @media only screen and (min-width: 768px) {
    width: 50% !important;
    left: 50%;
  }
`

const StyledBottomSheet = styled(BottomSheet)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    position: fixed;
    left: 50%;
  }
`

const SelectableCount = styled.span`
  width: '100%';
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--red400);
  line-height: 24px;
  display: block;
`
