import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 160px;
`

export const Title = styled.h2`
  margin-top: 60px;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: -0.034px;
  color: #212121;
  text-align: center;

  ${({ theme }) => theme.media.mobile`
    margin-top: 40px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.022px;
  `}
`

export const Description = styled.p`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.022px;
  color: #585858;
  text-align: center;
  margin-top: 32px;

  ${({ theme }) => theme.media.mobile`
    margin-top: 24px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.056px;
  `}
`

export const Content = styled.div`
  margin-top: 72px;
  margin-bottom: 60px;

  ${({ theme }) => theme.media.mobile`
    margin-top: 48px;
    margin-bottom: 32px;
  `}
`
