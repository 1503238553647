import Icon from 'components/Icon/Icon'
import ModalLayout from 'components/Layout/ModalLayout'
import { ServicePaymentType } from 'lib/api/service/types'
import styled from 'styled-components'
import addComma from 'utils/common/addComma'
import { bankCodes, cardCodes } from '../types'
import dayjs from 'dayjs'

type ReceiptModalProp = {
  open: boolean
  service: ServicePaymentType
  onClose: () => void
}
export default function ReceiptModal2({ open, onClose, service }: ReceiptModalProp) {
  if (!service || !service.payment) return null

  const servicePriceBeforeLongTermDiscount =
    service?.total_week_count === 12 || service?.total_week_count === 24
      ? Math.floor(((service?.service_price ?? 0) * 100) / (service?.total_week_count === 12 ? 95 : 90) / 1000) * 1000
      : service?.service_price ?? 0

  // 쿠폰 할인 금액
  const getCouponDiscount = () => {
    if (service?.payment?.coupon?.discount_price) {
      return service.payment.coupon.discount_price
    }

    if (service?.payment?.coupon?.discount_rate) {
      const price = service.service_price + service.cash_management_price ?? 0
      const rate = service.payment.coupon.discount_rate
      return (price * rate) / 100
    }

    return 0
  }

  // 결제 방식
  const getPaymentInfo = () => {
    const methods: { [index: string]: any } = {
      Card: '카드',
      VBank: '무통장입금',
      DirectBank: '계좌이체',
    }

    const { payment } = service

    const returnPaymentInfo = {
      method: '',
      methodDetail: '',
      company: '',
      paidTitle: '구매자',
      refundCompany: '',
      refundMethodDetail: '',
      amount: payment?.price ?? 0,
      pointsAmount: payment?.point ?? 0,
      paidDate: dayjs(payment?.paid_at).format('YYYY[.]MM[.]DD') ?? '',
      paidTime: dayjs(payment?.paid_at).format('HH[:]mm[:]ss') ?? '',
      paidBy: payment?.buyer_name ?? '',
      isRefund: !!payment?.refund?.id ?? false,
      refundMethod: payment?.refund?.refund_account_number ? '계좌 이체' : '환불',
      refundedDate: dayjs(payment?.refund?.refunded_at).format('YYYY[.]MM[.]DD') ?? '',
      refundedTime: dayjs(payment?.refund?.refunded_at).format('HH[:]mm[:]ss') ?? '',
      refundAmount: payment?.refund?.total_money ?? 0,
    }

    if (!service || !payment) return returnPaymentInfo

    const { payment_method, vbank, card, direct_bank, refund } = payment

    if ((payment_method === 'Card' || payment_method === 'Auth') && card) {
      returnPaymentInfo.company = cardCodes[card.card_code]
      returnPaymentInfo.methodDetail = card?.card_number
      returnPaymentInfo.paidTitle = '구매자'
      returnPaymentInfo.refundCompany = cardCodes[card.card_code]
      returnPaymentInfo.refundMethodDetail = cardCodes[card.card_code]
    }

    if (payment_method === 'VBank' && vbank) {
      returnPaymentInfo.company = vbank.bank_name
      returnPaymentInfo.methodDetail = vbank?.account_number
      returnPaymentInfo.paidTitle = '입금자'
      returnPaymentInfo.refundCompany = bankCodes[refund?.refund_bank_code || '']
      returnPaymentInfo.refundMethodDetail = refund?.refund_account_number ?? ''
    }

    if (payment_method === 'DirectBank' && direct_bank) {
      returnPaymentInfo.company = bankCodes[direct_bank.bank_code]
      returnPaymentInfo.methodDetail = ' '
      returnPaymentInfo.paidTitle = '송금자'
      returnPaymentInfo.refundCompany = bankCodes[direct_bank.bank_code]
      returnPaymentInfo.refundMethodDetail = refund?.refund_account_number ?? ''
    }

    return returnPaymentInfo
  }

  const handleReceiptSlipiApply = () => {
    const tid = service.payment?.tid
    if (!!tid) {
      window.open(`https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=${tid}&noMethod=1`)
    }
  }

  return (
    <ModalLayout open={open} onClose={onClose}>
      <ModalHeader>
        <Title>영수증</Title>
        <Icon name="blackClose" onClick={onClose} />
      </ModalHeader>

      <Container>
        <ServiceTitleWrap>
          <ServiceTitle>
            {`[${service?.total_week_count}주] 하루 ${service?.daily_count}회, 주 ${service?.weekly_count}회 관리`}
          </ServiceTitle>
          <ServicePrice>{addComma(service?.service_price ?? 0)}원</ServicePrice>
          <ServicePayedAt>
            결제 일시 {getPaymentInfo().paidDate} {getPaymentInfo().paidTime}
          </ServicePayedAt>
        </ServiceTitleWrap>

        <ServicePriceHistoryWrap>
          <PriceWrap>
            <PriceTitle>서비스 금액</PriceTitle>
            <PriceContent>{addComma(getPaymentInfo().amount)} 원</PriceContent>
          </PriceWrap>

          {(service?.total_week_count === 12 || service?.total_week_count === 24) && (
            <PriceWrap>
              <PriceTitle>장기 결제 할인</PriceTitle>
              <PriceContent>
                -
                {service.total_week_count === 12
                  ? addComma(Math.floor((servicePriceBeforeLongTermDiscount * 5) / 100 / 1000) * 1000)
                  : addComma(Math.floor((servicePriceBeforeLongTermDiscount * 10) / 100 / 1000) * 1000)}{' '}
                원
              </PriceContent>
            </PriceWrap>
          )}

          <PriceWrap>
            <PriceTitle>쿠폰 할인</PriceTitle>
            <PriceContent>
              {getCouponDiscount() > 0 ? '-' : ''}
              {addComma(getCouponDiscount())} 원
            </PriceContent>
          </PriceWrap>

          <PriceWrap>
            <PriceTitle>포인트 할인</PriceTitle>
            <PriceContent>
              {(service.payment?.point ?? 0) > 0 ? '-' : ''}
              {addComma(service.payment?.point ?? 0)} 원
            </PriceContent>
          </PriceWrap>
        </ServicePriceHistoryWrap>

        <TotalPriceWrap>
          <TotalPriceContent>총 결제 금액</TotalPriceContent>
          <TotalPriceContent>{addComma(service.payment?.price ?? 0)} 원</TotalPriceContent>
        </TotalPriceWrap>

        <StyledDivider />

        {!!service.is_refund && service.payment?.refund?.refunded_at && (
          <>
            <ServicePriceHistoryWrap>
              <PriceWrap>
                <PriceTitle>환불 금액</PriceTitle>
                <PriceContent>{addComma(service.payment?.refund?.refunded_money ?? 0)} 원</PriceContent>
              </PriceWrap>

              <PriceWrap>
                <PriceTitle>포인트 환불 금액</PriceTitle>
                <PriceContent>{addComma(service.payment.point ?? 0)} 원</PriceContent>
              </PriceWrap>
            </ServicePriceHistoryWrap>

            <TotalPriceWrap>
              <RefundPriceContent>총 환불 금액</RefundPriceContent>
              <RefundPriceContent>{addComma(service.payment?.refund?.total_money ?? 0)} 원</RefundPriceContent>
            </TotalPriceWrap>

            <StyledDivider />
          </>
        )}

        <UserInfoWrap>
          <UserInfoItemWrap>
            <UserInfoTitle>{getPaymentInfo().paidTitle}</UserInfoTitle>
            <UserInfoContent>{getPaymentInfo().paidBy} 님</UserInfoContent>
          </UserInfoItemWrap>

          {/* 
            TODO
              차후 소득공제 데이터 노출
          */}
          {/* <UserInfoItemWrap>
            <UserInfoTitle>소득 공제</UserInfoTitle>
            <UserInfoContent>개인 010-****-1234</UserInfoContent>
          </UserInfoItemWrap> */}

          <UserInfoItemWrap>
            <UserInfoTitle>결제 방법</UserInfoTitle>
            <UserInfoContent>{getPaymentInfo().method}</UserInfoContent>
            <UserInfoContentDetail>
              {getPaymentInfo().company} {getPaymentInfo().methodDetail}
            </UserInfoContentDetail>
          </UserInfoItemWrap>

          {getPaymentInfo().isRefund && (
            <>
              <UserInfoItemWrap>
                <UserInfoTitle>환불 수단</UserInfoTitle>
                <UserInfoContent>{getPaymentInfo().refundMethod}</UserInfoContent>
                <UserInfoContentDetail>
                  {getPaymentInfo().company} {getPaymentInfo().methodDetail}
                </UserInfoContentDetail>
              </UserInfoItemWrap>

              <UserInfoItemWrap>
                <UserInfoTitle>예금주</UserInfoTitle>
                <UserInfoContent>(주) 하이어엑스</UserInfoContent>
              </UserInfoItemWrap>
            </>
          )}
        </UserInfoWrap>

        <ModalFooter>
          <ReceiptSlipApplyButton onClick={handleReceiptSlipiApply}>영수증 전표 발행하기</ReceiptSlipApplyButton>
        </ModalFooter>
      </Container>
    </ModalLayout>
  )
}

const Container = styled.div`
  max-height: 80vh;
  overflow: auto;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-left: auto;
    margin-right: 16px;
    cursor: pointer;
    position: absolute;
    right: 0px;
  }
`

const Title = styled.h5`
  width: 100%;
  color: var(--gray-gray-800, #212121);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`

const ServiceTitleWrap = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
`

const ServiceTitle = styled.h5`
  color: var(--gray-gray-700, #333);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`
const ServicePrice = styled.p`
  color: var(--gray-gray-800, #212121);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`

const ServicePayedAt = styled.p`
  margin-top: 8px;
  color: var(--gray-gray-600, #757575);
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
`

const ServicePriceHistoryWrap = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PriceWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const PriceTitle = styled.span`
  color: var(--gray-gray-700, #333);
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
`

const PriceContent = styled.span`
  color: var(--gray-gray-800, #212121);
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
`

const TotalPriceWrap = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f5f5f5;
`

const TotalPriceContent = styled.span`
  color: var(--gray-gray-800, #212121);
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
`

const StyledDivider = styled.div`
  height: 8px;
  width: 100%;
  background-color: #f5f5f5;
`

const UserInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const UserInfoItemWrap = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f5f5f5;
`

const UserInfoTitle = styled.span`
  color: var(--gray-gray-500, #a8a8a8);
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
`

const UserInfoContent = styled.span`
  color: var(--gray-gray-800, #212121);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

const UserInfoContentDetail = styled.span`
  color: var(--gray-gray-600, #757575);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

const ModalFooter = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
`

const ReceiptSlipApplyButton = styled.button`
  color: var(--gray-gray-600, #757575);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  text-decoration-color: #757575;
  background-color: white;
  cursor: pointer;
`

const RefundPriceContent = styled(TotalPriceContent)`
  color: var(--red-red-400, #d32f2f);
`
