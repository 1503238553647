import { GoogleSpreadsheet } from 'google-spreadsheet'

import credential from '../../pages/ConsultingPage/brwnieandmuinfirmconsulting-d3214a6bd824.json'

const SPREADSHEET_ID = '1HgFYCnzU15V0XtuG5-koui1QQFpkXViDBtwLRfUc5yw'

export async function loadGoogleSheet() {
  try {
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID)

    await doc.useServiceAccountAuth({
      client_email: credential.client_email,
      private_key: credential.private_key,
    })

    await doc.loadInfo()

    return doc
  } catch (error) {
    console.error('Sheet Load Rows Error:', error)
    throw new Error('Failed to Load Rows data.')
  }
}

export async function setRow(sheetType: '무인상회' | 'cs 서비스', orderData: any) {
  try {
    const doc = await loadGoogleSheet()

    if (sheetType === '무인상회') {
      const sheet = doc.sheetsByTitle['브라우니x무인상회']

      await sheet.setHeaderRow(
        [
          '이름',
          '전화번호',
          '원하는 상담시간대',
          '연령대',
          '직업',
          '거주지',
          '설치 공간 보유 유무',
          '설치 가능한지 문의한 공간',
          '설치 공간 위치',
          '추천 받고 싶은 위치',
          '투자 금액',
          '무인상회 접한 경로',
          '신청 시간',
          '사이트 유입 경로',
        ],
        2
      )

      await sheet.addRow(orderData)
    } else if (sheetType === 'cs 서비스') {
      const sheet = doc.sheetsByTitle['CS 사전 신청']

      await sheet.setHeaderRow(
        ['이름', '연락처', '매장명', 'CS 가장 어려운 점', '추가적으로 원하는 부분', '신청 시간'],
        2
      )

      await sheet.addRow(orderData)
    }
  } catch (error) {
    console.error('Sheet save Row Error:', error)
    throw new Error('Failed to save Row data.')
  }
}
