import styled from 'styled-components'
import Icon from '../../../Icon'

export function AirconService(): JSX.Element {
  return (
    <Container>
      <Image src="image/loading_partner_service/partner-aircon.jpg" />
      <Mask>
        <Content>
          <Title>
            주기적인 <MobileHiddenBr />
            에어컨 관리로
            <br />
            매장을 쾌적하게
          </Title>
          <Description>
            연 4회 정기관리 or 완전 분해관리로
            <br />
            매장을 더욱 쾌적하게 운영해보세요!
          </Description>
        </Content>
        <BottomButton href="https://bit.ly/airconclean" target="_blank">
          <span>에어컨 클린케어 바로가기</span>
          <Icon name="ArrowRight" />
        </BottomButton>
      </Mask>
    </Container>
  )
}

const Container = styled.article`
  position: relative;

  width: 312px;
  height: 420px;

  border-radius: 24px;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 240px;
    border-radius: 20px;
  }
`

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 26px 28px;

  background: linear-gradient(125deg, #169dff 8.52%, rgba(6, 152, 255, 0) 116.26%);
`

// 모바일에서만 없어지는 br element
const MobileHiddenBr = styled.br`
  display: flex;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const Title = styled.p`
  color: #ffffff;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 135.294% */

  @media only screen and (max-width: 768px) {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 136.364% */
  }

  padding: 0;
`

const Description = styled.p`
  color: #ffffff;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const BottomButton = styled.a`
  color: #1a9efd;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;

  width: fit-content;
  display: flex;
  padding: 10px 16px 10px 18px;
  justify-content: flex-start;
  align-items: center;

  border-radius: 688px;
  background: #ffffff;
  cursor: pointer;

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media only screen and (max-width: 768px) {
    color: #1a9efd;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 8px 10px 8px 12px;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
