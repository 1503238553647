import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export function CoworkCompanySection() {
  const history = useHistory()
  const { push } = history

  const handleConsulting = () => {
    push('/easy-consulting')
  }

  return (
    <Section>
      <SectionWrapper>
        <h1>이미 브라우니로 관리중인</h1>
        <MobileCoworkLogoImg src="/image/브라우니_무인매장_함께하는_기업들_m.png" alt="브라우니 무인매장" />
      </SectionWrapper>
      <MobileBtnWrapper>
        <QuestionWrapper>
          브라우니가 궁금해요
          <div className="rectangle" />
        </QuestionWrapper>
        <MobileDownloadButton onClick={handleConsulting}>간편상담 신청하기</MobileDownloadButton>
      </MobileBtnWrapper>
    </Section>
  )
}

const Section = styled.section`
  width: 100vw;
  background-color: var(--gray100);
  padding: 40px 0px 30px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SectionWrapper = styled.div`
  width: 328px;

  background-color: var(--gray0);
  margin: 0px 16px;
  padding-top: 32px;
  padding-bottom: 52px;

  border-radius: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  & > h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    word-break: keep-all;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--gray800);

    width: 100%;
  }
`

const MobileCoworkLogoImg = styled.img`
  width: 100%;

  display: flex;
  height: auto;

  box-sizing: border-box;
  margin-top: 32px;

  object-fit: contain;
`

const MobileBtnWrapper = styled.div`
  display: flex;
  position: relative;
  margin-top: 32px;
`

const QuestionWrapper = styled.div`
  position: absolute;
  top: -16px;
  left: 12px;
  padding: 2px 8px;
  background-color: var(--brand300);
  color: var(--gray0);
  letter-spacing: -0.3px;

  border-radius: 99px;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 116px;
  left: 50%;
  transform: translateX(-50%);

  .rectangle {
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 6px;
    height: 6px;
    background-color: var(--brand300);
    transform: translateX(-50%) rotate(-45deg);
    border-radius: 1px;
  }
`

const MobileDownloadButton = styled.div`
  display: flex;
  width: 182px;
  height: 40px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 16px;
  background-color: var(--gray200);
  color: var(--gray800);
  font-weight: 600;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0 auto;

  & > svg {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }

  &:active {
    background-color: var(--gray300);
  }
`
