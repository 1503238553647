import ReactSlider from 'react-slider'
import styled from 'styled-components'
import addComma from 'utils/common/addComma'
import { useGetBrwnieBasePriceQuery } from 'hooks/query/admin/useGetBrwnieBasePriceQuery'

export type ServiceFeeTextWithSliderType = {
  weekCountData: number[]
  currentWeekCount?: number
  onWeekChange: (index: any) => void
  weekType: 'one' | 'four'
}

const DEFAULT_SERVICE_TIME_MINUTE = 60

export default function ServiceFeeTextWithSlider({
  weekCountData,
  currentWeekCount = 1,
  onWeekChange,
  weekType,
}: ServiceFeeTextWithSliderType) {
  const { data: basePriceList = [] } = useGetBrwnieBasePriceQuery()
  const isFourWeek = weekType === 'four'

  const filteredBasePriceList = basePriceList.filter((basePrice) => {
    const currentWeekType = isFourWeek ? 4 : 1
    const currentWeeklyCount = isFourWeek ? currentWeekCount + 1 : currentWeekCount + 2

    const isSameTotalCount = basePrice.totalWeekCount === currentWeekType
    const isSameWeeklyCount = basePrice.weeklyCount === currentWeeklyCount
    const isSameDefaultTimeMinute = basePrice.serviceTimeMinute === DEFAULT_SERVICE_TIME_MINUTE

    if (
      isSameTotalCount &&
      isSameWeeklyCount &&
      isSameDefaultTimeMinute &&
      basePrice.storeTypeCd === null &&
      // 그 외의 데이터들은 (주말이 1일 포함된 데이터는 가져오지 않기 위함)
      // 서비스 일 수가 6-7 일 경우에는 주말이 1일인 데이터도 필요하기 때문에 무조건 true
      (currentWeeklyCount < 6 ? basePrice.countWeekend === 1 || basePrice.countWeekend === 0 : true)
    ) {
      // 4주 1회
      const isOneWeekOneService = isFourWeek && currentWeeklyCount === 1
      // 4주 2회
      const isOneWeekTwiceService = isFourWeek && currentWeeklyCount === 2

      // 4주 2회 이하일 경우는 주말 포함 X
      if ((isOneWeekOneService || isOneWeekTwiceService) && basePrice.countWeekend > 0) {
        return false
      }

      return basePrice
    }

    return false
  })

  return (
    <Wrapper>
      <SliderWrapper>
        <CustomSlider
          className="horizontal-slider"
          markClassName="mark"
          onChange={onWeekChange}
          trackClassName="track"
          defaultValue={1}
          value={currentWeekCount}
          min={0}
          max={weekCountData.length - 1}
          thumbClassName="thumb"
          marks
        />
      </SliderWrapper>

      <StepWrapper>
        {weekCountData.map((step, index) => {
          return (
            <LabelText
              key={index}
              $active={currentWeekCount === index}
              onClick={() => onWeekChange(isFourWeek ? step - 1 : step - 2)}
            >{`주 ${step}회`}</LabelText>
          )
        })}
      </StepWrapper>

      <FeeWrapper weekType={weekType}>
        <div className="tagDiscount">
          <FeeChangeWrapper className="changeWrapper">
            <FeeUl currentWeekCount={currentWeekCount}>
              {/* {weekCountData.map((weeklyCount) => ( */}
              <FeeLi>
                <PriceListWrap>
                  {filteredBasePriceList && filteredBasePriceList[0] && (
                    <div className="price_wrap">
                      <p className="account">{addComma(filteredBasePriceList[0].servicePrice)}원</p>

                      {/* 6일 7일 제외 */}
                      {currentWeekCount + 1 < 6 && (
                        <div className="description_tag">평일만 {filteredBasePriceList[0].countWeekday}일</div>
                      )}

                      {/* 6일 7일 일 경우 */}
                      {currentWeekCount + 1 > 5 && (
                        <div className="description_tag">
                          평일 {filteredBasePriceList[0].countWeekday}일 + 주말 {filteredBasePriceList[0].countWeekend}
                          일
                        </div>
                      )}
                    </div>
                  )}
                  {filteredBasePriceList && filteredBasePriceList.length > 1 && (
                    <>
                      <span>OR</span>
                      <div className="price_wrap">
                        <p className="account">{addComma(filteredBasePriceList[1].servicePrice)}원</p>
                        <div className="description_tag">
                          평일 {filteredBasePriceList[1].countWeekday}일 + 주말 {filteredBasePriceList[1].countWeekend}
                          일
                        </div>
                      </div>
                    </>
                  )}
                </PriceListWrap>
              </FeeLi>
              {/* ))} */}
            </FeeUl>
          </FeeChangeWrapper>
        </div>
      </FeeWrapper>
    </Wrapper>
  )
}

const PriceListWrap = styled.div`
  display: flex;
  align-items: center;

  & > span {
    color: var(--gray400);
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 140%;
    margin: 0px 16px;
  }

  .price_wrap {
    padding: 12px 24px 16px 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 12px;
    border: 1px solid var(--SlateGray-SlateGray100, #e2e8f0);
    background: #f5f9fe;

    & > .account {
      color: #000;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      line-height: 140%;
    }

    & > .description_tag {
      display: flex;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;

      border-radius: 8px;
      background: #cdf;

      color: #026;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;

      margin-top: 4px;
    }
  }

  ${({ theme }) => theme.media.mobile`
      flex-direction: column;
      gap: 4px;

      & > span {
        font-size: 26px;
      }

      .price_wrap {
        padding: 12px 20px;

        & >.account {
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
        }

        & > .description_tag {
          font-size: 12px; 
          font-weight: 500;
          line-height: 175%
        }
      }
  
  `}
`

const Wrapper = styled.div`
  width: 100%;
  padding: 24px 40px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background: var(--gray50);
  border-radius: 24px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 12px 16px 16px 16px;
  }
`

const FeeWrapper = styled.div<{ weekType: 'one' | 'four' }>`
  width: 100%;

  & > h4 {
    height: 24px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: line-through;
    color: var(--gray600);
  }

  .tagDiscount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .tag {
      padding: 0px 12px;
      background-color: var(--gray100);
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--brand400);
      margin-right: 12px;
    }

    & > .changeWrapper {
      font-weight: 700;
      font-size: 36px;
      line-height: 50px;
      color: var(--gray800);
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 24px auto 12px;
    & > h4 {
      height: 21px;
      font-size: 12px;
      line-height: 21px;
    }

    .tagDiscount {
      .tag {
        padding: 0px 8px;
        background-color: var(--gray100);
        border-radius: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--brand400);
        margin-right: 8px;
      }
      & > .changeWrapper {
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
`

const FeeChangeWrapper = styled.div`
  width: fit-content;
  position: relative;
  overflow: hidden;
`

const FeeUl = styled.ul<{ currentWeekCount: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;

  /* transform: ${({ currentWeekCount }) => `translateY(calc(${currentWeekCount} * -150px))`};

  @media only screen and (max-width: 768px) {
    transform: ${({ currentWeekCount }) => `translateY(calc(${currentWeekCount} * -150px))`};
  } */
`

const FeeLi = styled.li`
  font-size: 36px;
  color: var(--gray800);
  font-weight: 700;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 35px;
  }
`

const SliderWrapper = styled.div`
  width: 100%;
  height: 48px;
  padding: 12px 0px;

  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: 42px;
  }
`

const CustomSlider = styled(ReactSlider)`
  width: 100%;
  position: relative;

  .track,
  .thumb {
    transition: right 0.1s ease-in-out, left 0.1s ease-in-out;
  }

  .track {
    border-radius: 8px;
    cursor: pointer;
  }

  .track-0 {
    height: 10px;
    background-color: var(--brand300);
  }

  .track-1 {
    height: 10px;
    background-color: var(--gray200);
  }

  .thumb {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-color: var(--gray0);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    top: -7px;

    &:focus-visible {
      outline: none;
    }
  }
`

const StepWrapper = styled.div`
  padding-top: 4px;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    padding-top: 0px;
  }
`

const LabelText = styled.h3<{ $active: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-weight: ${({ $active }) => ($active ? 700 : 400)};
  color: ${({ $active }) => ($active ? 'var(--brand300)' : 'var(--gray600)')};
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 21px;
  }
`
